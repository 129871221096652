import { DocumentService } from './../../../../service/document.service';
import { debounceTime } from 'rxjs/operators';
/**
 * sous-composant pour la messagerie.
 */

import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentService } from '../../../../service/student.service';
import { TeacherService } from '../../../../service/teacher.service';
import { ConversationService } from '../../../../service/conversation.service';
import { AuthenticationService } from '../../../../service/authentification.service';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { FormationService } from '../../../../service/formation.service';
import { StaffService } from '../../../../service/staff.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators'
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  conversation_id = null;
  persona_id = null;
  formation_id = null;
  conversationWith: any
  persona: any;
  student: any;
  teacher: any;
  staff: any;
  formation = null;
  persona_role: any;
  messageLoader = false;
  persona_url = null;
  text_new_message = '';
  messages = [];
  myMessagesByDates = [];
  his_avatar;
  posting_message = false;
  page = 1;
  has_next = false;
  my_timeout = null;
  currentTeacher: any;
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.messages';
  conversationInTraining = false;
  doScroll = true;
  count_messages = 0;
  callServiceFormationId = null;
  uploadingDocument = false;

  public Editor = DecoupledEditor;
  ckEditorConfig = {
    link: {
      // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
      addTargetToExternalLinks: true,
    },
    toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'fontsize', '|', 'alignment', 'indent', 'outdent', '|', 'bulletedList', 'numberedList', '|', 'link', 'insertTable', '|', 'undo', 'redo'],
  }

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
    private formationService: FormationService,
    private teacherService: TeacherService,
    private staffService: StaffService,
    private conversationService: ConversationService,
    private documentService: DocumentService,
  ) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.persona = user;
          this.conversation_id = this.route.snapshot.paramMap.get('id_conversation');
          this.route.data.subscribe(data => {
            this.conversationService.getConversationById(this.conversation_id)
              .subscribe(conversation => {
                this.persona_role = data.persona;
                switch (this.persona_role) {
                  case 'student':

                    this.currentTeacher = this.persona.url;

                    this.studentService.getObservableStudentById(conversation['student_id'])
                      .subscribe(_student => {
                        this.student = _student;
                        if (this.student) {
                          this.his_avatar = this.student.user.avatar ? this.student.user.avatar : this.defaultAvatar;
                          this.conversationWith = _student.user;
                          this.getMessages2(this.conversation_id);
                          this.polling(this.student.url, true);
                        }
                      })
                    break;
                  case 'teacher':
                    if (this.persona_id) {
                      this.teacherService.getObservableTeacherById(this.persona_id)
                        .subscribe(_teacher => {
                          this.teacher = _teacher;
                          this.his_avatar = this.teacher.user.avatar ? this.teacher.user.avatar : this.defaultAvatar;
                          this.conversationWith = _teacher.user;
                          this.getMessages(null, this.teacher.url);
                          this.polling(this.teacher.url, true);
                        })
                    }
                    break;
                  case 'staff':
                    if (this.persona_id) {
                      this.staffService.getObservableStaffById(this.persona_id)
                        .subscribe(_staff => {
                          this.staff = _staff;
                          this.his_avatar = this.staff.user.avatar ? this.staff.user.avatar : this.defaultAvatar;
                          this.conversationWith = this.staff.user;
                          this.getMessages(null, this.staff.url);
                          this.polling(this.staff.url, true);
                        })
                    }
                    break;
                  default:
                    break;
                }
              })
          })
        }
      })
  }

  ngOnDestroy() {
    if (this.my_timeout) {
      this.my_timeout.unsubscribe()
    }
    if (this.callServiceFormationId) {
      this.callServiceFormationId.unsubscribe();
    }
  }
  scrollToBottom(): void {
    try {
      if (this.doScroll && this.myScrollContainer) {
        setTimeout(() => {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        }, 500);
      }
    } catch (err) { }
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  getMessages(_formationUrl, _toUrl) {
    this.conversationService.getUserMessages(_formationUrl, this.persona_role, _toUrl, 1)
      .subscribe(_messages => {
        if (_messages) {
          this.messages = _messages['results'];
          this.count_messages = _messages['count'];
          this.messages.forEach(_message => {
            this.conversationService.isRead(_message, this.persona.url)
          });
          this.scrollToBottom();
          this.updateByDay()
        }
      })
  }

  getMoreMessages() {
    this.page = this.page + 1;
    this.conversationService.getUserMessagesByConversationId(this.conversation_id, this.page)
      .subscribe(_messages => {
        if (_messages) {
          _messages['next'] ? this.has_next = true : this.has_next = false;
          for (const _mess of _messages['results']) {
            this.pushMessage(_mess);
          }
          this.count_messages = _messages['count'];
          this.messages.forEach(_message => {
            this.conversationService.isRead(_message, this.persona.url)
          });
          this.scrollToTop();
          this.updateByDay()
        }
      })
  }

  pushMessage(msg) {
    let exist = false;
    for (const _mess of this.messages) {
      if (_mess.id === msg.id) {
        exist = true;
      }
    }
    if (!exist) {
      this.messages.unshift(msg);
    }
  }

  scrollToTop(): void {
    try {
      if (this.doScroll && this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = 0;
      }
    } catch (err) { }
  }

  getMessages2(conversationId) {
    this.conversationService.getUserMessagesByConversationId(conversationId, 1)
      .subscribe(_messages => {
        if (_messages) {
          _messages['next'] ? this.has_next = true : this.has_next = false;
          this.messages = _messages['results'];
          this.count_messages = _messages['count'];
          this.messages.forEach(_message => {
            this.conversationService.isRead(_message, this.persona.url)
          });
          this.scrollToBottom();
          this.updateByDay()
        }
      })
  }

  checkFrom(message) {
    return (message.from_user === this.persona.url);
  }

  checkFromVsTo(message) {
    if (this.persona_role === 'student') {
      return (message.from_user === this.student.url);
    } else if (this.persona_role === 'teacher') {
      return (message.from_user === this.teacher.url);
    } else {
      return (message.from_user === this.staff.url);
    }
  }

  previousStep() {
    this.router.navigate(['./messages']);
  }

  goToDocs() {
    this.router.navigate(['messages', 'documents']);
  }

  /**
   * Envoi du message
   */
  sendMessage() {
    switch (this.persona_role) {
      case 'student':
        this.postNewMessage(this.persona.url, this.student, null, this.text_new_message)
        break;
      case 'teacher':
        this.postNewMessage(this.persona.url, this.teacher, null, this.text_new_message)
        break;
      case 'staff':
        this.postNewMessage(this.persona.url, this.staff, null, this.text_new_message)
        break;
      default:
        break;
    }
  }

  postNewMessage(_from, _to, docs, text_new_message) {
    this.posting_message = true;
    let conversationRequest;
    conversationRequest = this.conversationService.postNewMessage(
      this.conversation_id,
      text_new_message,
      _from,
      _to.url,
      docs
    )
    conversationRequest.subscribe(message => {
      if (message) {
        this.messages.push(message);
        this.updateByDay();
        this.text_new_message = '';
        this.posting_message = false;
        this.scrollToBottom();
      }
    });
  }

  /**
   * polling method to handle upcoming message
   */
  polling(_personaUrl, _init) {
    let requestMessage;
    requestMessage = this.conversationService.getUserMessagesByConversationId(this.conversation_id, 1);
    requestMessage.subscribe(messages => {
      if (messages) {
        // store count
        if (messages['count'] !== this.count_messages) {
          this.doScroll = true;
          this.count_messages = messages['count'];
        } else {
          this.doScroll = _init;
        }
        for (const msg of messages['results']) {
          // on ajoute chaque messages au debut du tableau
          if (this.messages.map(val => val.id).indexOf(msg.id) < 0) {
            // this.messages.unshift(msg);
            this.messages.push(msg);
          }
        }
        this.messages.forEach(_message => {
          this.conversationService.isRead(_message, this.persona.url)
        });
        this.updateByDay();
        this.scrollToBottom();
        this.my_timeout = Observable.interval(300000)
          .pipe(take(1))
          .subscribe(i => {
            this.polling(_personaUrl, false);
          })
      }
    })
  }

  /**
   * aggregate message by day
   */
  updateByDay() {
    this.myMessagesByDates.forEach(_myMessagesByDates => {
      _myMessagesByDates.messages = [];
    });
    let messagesDate = null;
    let idx = -1;
    // check order by date
    this.messages.sort(function (a, b) {
      if (a.created_at < b.created_at) {
        return -1;
      }
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    });
    for (const msg of this.messages) {
      if (msg) {
        messagesDate = this.getCompleteDay(msg.created_at);
        idx = this.myMessagesByDates.map(value => value.format).indexOf(messagesDate)
        if (idx < 0) {
          this.myMessagesByDates.push({
            format: messagesDate,
            messages: [msg]
          });
        } else {
          if (this.myMessagesByDates[idx].messages.indexOf(msg) < 0) {
            this.myMessagesByDates[idx].messages.push(msg)
          }
        }
      }
    }
    this.myMessagesByDates.sort(function (a, b) {
      return new Date(a.messages[0].created_at).getTime() - new Date(b.messages[0].created_at).getTime();
    });
  }

  /* helper method */
  getCompleteDay(_date) {
    return moment(_date).format('DD-MM-YYYY');
  }

  uploadFile($event) {
    if (this.persona && this.persona.user) {
      this.uploadingDocument = true;
      const myDocservice = this.documentService.sendDocuments($event.target.files, this.persona.user.url, null)
        .subscribe(_docs => {
          if (_docs[0]) {
            this.postNewMessage(this.persona.url, this.student, _docs, 'Doc en pj')
          }
          this.scrollToBottom();
          this.uploadingDocument = false;
        })
    }
  }

  enter() {
    if (this.has_next) {
      this.page = this.page + 1;
      switch (this.persona_role) {
        case 'student':
          this.getMessages(this.formation.url, this.student.url);
          break;
        case 'teacher':
          this.getMessages(null, this.teacher.url);
          break;
        case 'staff':
          this.getMessages(null, this.staff.url);
          break;
        default:
          break;
      }
    }
  }


  leave() {
  }

}
