import { combineLatest } from 'rxjs';
import { GroupService } from './../../../service/group.service';
import { RoutePermissionService } from './../../../service/routePermission.service';
import { AuthenticationService } from './../../../service/authentification.service';
/**
 * Composant correspondant au menu de navigation de gauche
 */

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

// models
import { NavElement } from '../../../model/navelement.model';
import { Staff } from '../../../model/staff.model';

// services
import { StaffService } from '../../../service/staff.service';
import { UserService } from '../../../service/user.service';
import { KiwixiGlobals } from '../../../kiwixi/kiwixi.globals';
import { KiwixiService } from '../../../kiwixi/kiwixi.service';

@Component({
  selector: 'app-leftnavbar',
  templateUrl: './leftnavbar.component.html',
  styleUrls: ['./leftnavbar.component.scss', './leftnavbar.ux.scss'],
  providers: [StaffService, UserService]
})
export class LeftnavbarComponent implements OnInit {
  @Input() navElements: NavElement[];
  @Input() currentStaff: Staff;
  showNavBar: boolean;
  originalStaff: any;
  defaultAvatar = environment.static + '/avatar.png';
  avatar: any;

  constructor(
    private route: ActivatedRoute,
    private kiwixiGlobals: KiwixiGlobals,
    private staffService: StaffService,
    private userService: UserService,
    private kiwixiService: KiwixiService,
    private authenticationService: AuthenticationService,
    private routePermissionService: RoutePermissionService,
    private groupService: GroupService,) {
    kiwixiGlobals.isUserLoggedIn.subscribe(value => this.showNavBar = value)
  }

  ngOnInit() {
    combineLatest([this.routePermissionService.routeFeaturePermissions, this.groupService.appGroups]).subscribe(results => {
      if (results[0] && results[1]) {
        this.init();
      }
    })
  }

  init() {
    if (this.navElements) {
      this.activeNav(this.navElements[1]);
    }

    // On active l'item de navigation en fonction de la page en cours.
    if (this.navElements && this.route.snapshot.children[0] && this.route.snapshot.children[0].url[0]) {
      const _path = this.route.snapshot.children[0].url[0].path
      switch (_path) {
        case 'upcomings-classes':
          this.activeNav(this.navElements[0]);
          break;
        case 'students':
          this.activeNav(this.navElements[1]);
          break;
        case 'formations':
          this.activeNav(this.navElements[2]);
          break;
        case 'teachers':
          this.activeNav(this.navElements[3]);
          break;
        case 'invoices':
          this.activeNav(this.navElements[4]);
          break;
        // case 'messages':
        //   this.activeNav(this.navElements[4]);
        //   break;
        case 'exercices':
          this.activeNav(this.navElements[4]);
          break;
        case 'formations-suivi':
          this.activeNav(this.navElements[5]);
          break;
        case 'reports':
          this.activeNav(this.navElements[6]);
          break;
        case 'export':
          this.activeNav(this.navElements[7]);
          break;
        default:
          this.activeNav(this.navElements[1]);
          break;
      }
    }
    this.filterNav();
    this.originalStaff = {
      poste: this.currentStaff.poste,
      user: this.currentStaff.user
    }
    if (this.currentStaff.user.avatar) {
      this.avatar = this.currentStaff.user.avatar;
    } else {
      this.avatar = this.defaultAvatar;
    }
  }

  /**
   * upload l'avatar sur le user.
   */
  uploadAvatar($event) {
    for (const file of $event.target.files) {
      this.userService.patchFile(this.currentStaff.user.url, this.currentStaff.user, file)
        .then(user => {
          this.currentStaff.avatar = user.avatar;
          this.originalStaff.avatar = user.avatar;
          this.currentStaff.user = user;
          this.avatar = user.avatar;
        });
    }
  }

  /**
   * lancer la deconnexion.
   */
  logout() {
    this.kiwixiService.logout();
  }

  /**
   * Methode pour activer un item de navigation.
   * @param navSelect
   */
  activeNav(navSelect) {
    for (const nav of this.navElements) {
      if (nav.id === navSelect.id) {
        nav['isActive'] = true;
      } else {
        nav['isActive'] = false;
      }
    }
    this.setIsAllowed();
  }

  setIsAllowed() {
    for (const nav of this.navElements) {
      nav['isAllowed'] = this.authenticationService.checkFeaturePermission(nav.group);
    }
  }

  checkFeaturePermission(groupName) {
    return this.authenticationService.checkFeaturePermission(groupName);
  }

  filterNav() {
    this.navElements = this.navElements.filter(element => element['isAllowed']);
  }

  /**
   * Méthode pour initialiser le staff courant.
   */
  initStaff() {
    this.currentStaff.poste = this.originalStaff.poste;
    this.currentStaff.user.first_name = this.originalStaff.user.first_name;
    this.currentStaff.user.last_name = this.originalStaff.user.last_name;
    this.currentStaff.user.email = this.originalStaff.user.email;
    this.currentStaff.avatar = this.originalStaff.user.avatar;
    this.currentStaff.user = this.originalStaff.user;
    if (this.currentStaff.user.avatar) {
      this.avatar = this.currentStaff.user.avatar;
    }
  }

  /**
   * Méthode pour sauvegarder un staff.
   */
  saveStaff() {
    const _paramsStaff = {
      poste: this.currentStaff.poste,
    }
    const _paramsUser = {
      first_name: this.currentStaff.user.first_name,
      last_name: this.currentStaff.user.last_name,
      email: this.currentStaff.user.email
    }
    this.userService.patchUser(this.currentStaff.user.url, _paramsUser)
      .then(res => {
        this.staffService.patchStaff(this.currentStaff.url, _paramsStaff)
      })
  }

}
