/**
 * Composant pour l'affichage des cours passés.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { OccurrenceService } from '../../../../service/occurrence.service';
import { AuthenticationService } from '../../../../service/authentification.service';
import { FormationService } from '../../../../service/formation.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { InitAppService } from '../../../../service/initapp.service';


@Component({
  selector: 'app-past',
  templateUrl: './past.component.html',
  styleUrls: ['./past.component.scss']
})
export class PastComponent implements OnInit, OnDestroy {

  translatePath = 'lingueopro.dashboard.past_sessions';
  countOccurencesPages = 0;
  occurrences = [];
  pastOccurrences = [];
  formation_id = null;
  totalOccurrences = 0;
  student = null;
  teacher = null;
  showPast = false;
  now = null;
  occurrencesResult = null;
  formation: any;
  getFormationSubcription: ISubscription;
  getOccurence_service: ISubscription;

  constructor(
    private route: ActivatedRoute,
    private occurrenceService: OccurrenceService,
    private formationService: FormationService,
    private authenticationService: AuthenticationService,
    private initAppService: InitAppService,

  ) { }

  ngOnInit() {
    this.now = moment();
    this.occurrences = [];
    this.formation_id = this.route.parent.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, this.formation_id);
    this.formationService.currentFormationSubject.subscribe(formation => {
      this.formation = formation;
      if (formation) {
        this.formation = formation;
        this.student = this.authenticationService.getCurrentPersona();
        this.getOccurences();
      }
    });
  }

  ngOnDestroy() {
    if (this.getFormationSubcription) {
      this.getFormationSubcription.unsubscribe()
    }
    if (this.getOccurence_service) {
      this.getOccurence_service.unsubscribe()
    }
  }

  /**
   * Methode pour recuperer les occurrences
   */
  getOccurences(morePage = null) {
    this.countOccurencesPages++;
    const params = {
      'formation': this.formation_id.toString(),
      'start__lte': moment(this.now).toISOString(),
      'ordering': '-start'
    }
    let url = this.formationService.setUrlWithParams('occurrences', params);
    if (morePage && this.occurrencesResult['next']) {
      url = this.occurrencesResult['next'];
    }
    this.getOccurence_service = this.occurrenceService.getOccurencesByUrl(url)
      .subscribe(_occurrences => {
        this.occurrencesResult = _occurrences;
        if (_occurrences['results']) {
          _occurrences['results'].forEach(_occurrence => {
            this.occurrences.push(_occurrence);
          });
          this.getPastOccurrences();
          this.countOccurencesPages = 0;
          this.totalOccurrences = this.occurrences.length;
          this.showPast = true;
        }
      }
      );
  }

  /**
   * Methode pour filtrer sur les occurrences passées.
   */
  getPastOccurrences() {
    this.pastOccurrences = this.occurrences.filter(_occurrence =>
      _occurrence.status === 'done'                                      // Cours pris
      || _occurrence.status === 'abs_teacher_without_justification'         // Prof Absent
      || _occurrence.status === 'abs_student_without_justification')        // Cours Manqué
    // this.pastOccurrences.sort(function (a, b) {
    //   return new Date(b.start).getTime() - new Date(a.start).getTime()
    // });
  }

  /**
   * Methode pour verifier si le cours a été manqué.
   * @param _status
   */
  isMissing(_status) {
    let isMissing = false;
    switch (_status) {
      case 'abs_teacher_without_justification':
        isMissing = true
        break;
      case 'abs_student_without_justification':
        isMissing = true
        break;
      default:
        break;
    }
    return isMissing;
  }
}
