import { FormationList } from './../../../../model/formationList.model';
/**
 * sous-composant pour l'affichage de la liste des formations/trainings.
 */

import { Component, OnInit } from '@angular/core';
import { FormationService } from '../../../../service/formation.service';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../../../../service/authentification.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  myTrainings: any = [];
  myPastTrainings: any = [];
  myUser: any;
  countTrainings: Number;
  countPages = 1;
  countPagesPastTrainings = 1;
  translatePath = 'teacher.trainings';
  loadOngoingTrainings = false;
  loadPastTrainings = false
  showCurrent = true;
  showPast = true;
  myTerm = '';
  ongoingSearchResult: FormationList;
  doneSearchResult: FormationList;
  searchForm: FormControl;
  constructor(
    private formationService: FormationService,
    private authenticationService: AuthenticationService) { }

  // Push a search term into the observable stream.
  setSearch(term: string): void {
    this.myTrainings = [];
    this.myPastTrainings = [];
    this.loadOngoingTrainings = true;
    this.loadPastTrainings = true;
    this.searchForm.setValue(term);
  }

  searchFormations(typeOfTrainings) {

    let status = '';
    if (typeOfTrainings === 'done') {
      status = 'done';
      this.loadPastTrainings = true;
    } else {
      status = 'active,suspended';
      this.loadOngoingTrainings = true;
    }

    const params = {
      'teachers': this.myUser.id,
      'status__in': status.toString(),
      'student_name': this.searchForm.value,
      'ordering': 'student__user__last_name'
    }
    let url = this.formationService.setUrlWithParams('formations', params);
    if (this.myTrainings.length > 0 && this.myPastTrainings.length > 0) {
      if (this.ongoingSearchResult.next || this.doneSearchResult.next) {
        if (typeOfTrainings === 'ongoing') {
          url = this.ongoingSearchResult.next;
        } else {
          url = this.doneSearchResult.next;
        }
      }
    }

    this.formationService.getFormationsByUrl(url)
      .subscribe(res => {
        if (res) {
          if (res['count']) {
            this.countTrainings = res['count']
          }
          if (res['results']) {
            res['results'].forEach(element => {
              if (typeOfTrainings === 'done') {
                this.myPastTrainings.push(element);
              } else {
                this.myTrainings.push(element)
              }
            });
          }
          if (typeOfTrainings === 'done') {
            this.loadPastTrainings = false;
            this.doneSearchResult = res;
          } else {
            this.loadOngoingTrainings = false;
            this.ongoingSearchResult = res;
          }
        }
      });

  }
  ngOnInit() {
    this.searchForm = new FormControl();
    this.myUser = this.authenticationService.getCurrentPersona();
    if (this.myUser && this.myUser.id) {
      // **** Trainings ***** //
      this.searchForm.valueChanges
        .debounceTime(1500)
        .subscribe(newValue => {
          this.searchFormations('ongoing');
          this.searchFormations('done');

        });
    }
    this.searchForm.setValue('');
  }

}
