<div class="calendar-wrapper">
  <div class="valign-wrapper">
      <button class="changemonth-btn action-button" mz-button [flat]=true (click)="setPreviousMonth()"
        [disabled]="loadDisponibilities">
        <<
      </button>
    <div class="monthlabel">
      <span>{{monthNames[month - 1]}}  {{year}}</span>
    </div>
      <button class="changemonth-btn action-button" mz-button [flat]=true (click)="setNextMonth()"
        [disabled]="loadDisponibilities">
        >>
      </button>
  </div>
  <div class="" *ngIf="!loadDisponibilities">
    <table class='calendar'>
      <tr class='weekdays-list'>
        <td class="center"><b>Dim</b></td>
        <td class="center"><b>Lun</b></td>
        <td class="center"><b>Mar</b></td>
        <td class="center"><b>Mer</b></td>
        <td class="center"><b>Jeu</b></td>
        <td class="center"><b>Ven</b></td>
        <td class="center"><b>Sam</b></td>
      </tr>
      <tr class="calendar-boxes" *ngFor="let line of calendarData">
        <td *ngFor="let item of line.cols">
          <div class='day-off' *ngIf="item === 0"></div>
          <div *ngIf="item > 0">
            <div class='day-enable' [ngClass]="{'selected': selectedDay==item}"*ngIf="isValidateDate(item)" (click)="selectDay(item)">{{item}}</div>
            <div class='day-unavail' *ngIf="!isValidateDate(item)">{{item}}</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="row center" *ngIf="loadDisponibilities">
    <mat-progress-spinner class="calendar-loader"
      [color]="'warn'"
      [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</div>
<!-- <div id="calendar"></div> -->
