import * as CryptoJS from 'crypto-js';
import { UUID } from 'angular2-uuid';

export class Auth {
  session_id: number;
  site: string;
  language: string;
  device: string;
  sign: string;
  persona: string;
  role: string;
  hash: string;

  constructor(site: string, language: string, device: string, persona: string, role: string) {
    this.site = site;
    this.language = language;
    this.device = device;
    this.persona = persona;
    this.role = role;
  }

  getHash(): string {
    return this.hash;
  }

  setLoginHash() {
    const _str = this.persona + this.site + this.language + this.device + this.sign;
    const _hash = CryptoJS.SHA256(_str);
    this.hash = _hash.toString();
  }

  getSign(): string {
    return this.sign;
  }

  setSign() {
    const myString: string = UUID.UUID();
    const _sign = CryptoJS.SHA256(myString);
    this.sign = _sign.toString();
  }

}
