import { Router } from '@angular/router';
import { Teacher } from './../../model/teacher.model';
import { ConversationService } from './../../service/conversation.service';
import { Formation } from './../../model/formation.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-teachers',
  templateUrl: './my-teachers.component.html',
  styleUrls: ['./my-teachers.component.scss']
})
export class MyTeachersComponent implements OnInit {
  @Input() formation: Formation;
  componentToLoad: string;
  modalParams: { teacher: Teacher; showReviews: boolean; };

  constructor(
    private conversationService: ConversationService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goToConversation(teacher: Teacher) {
    let url = '/formation/' + this.formation.id + '/messages/';
    const conversationId = this.getConversationId(teacher);
    if (conversationId) {
      url += 'conversation/' + conversationId + '/'
    }
    this.router.navigate([url])
  }

  getConversationId(teacher: Teacher) {
    const conversationTeacher = this.conversationService.conversationList.value.filter(conversation => conversation.teacher_id === teacher.id);
    if (conversationTeacher.length > 0) {
      return conversationTeacher[0].id;
    } else {
      return null;
    }
  }

  showModalTeacherProfil(teacher) {
    this.componentToLoad = 'teacherProfil';
    this.modalParams = { teacher: teacher, showReviews: false }
  }
}
