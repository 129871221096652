import { ExerciceSpecificContentService } from './../../../../../service/exerciceSpecificContent.service';
import { ExerciceSpecificContent } from './../../../../../model/exerciceSpecificContent.model';
/**
 * Sous composant pour l'edition des informations générales.
 */

import { Component, OnInit, Input, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Formation } from '../../../../../model/formation.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';


import { InformationsService } from './informations.service';
import { FormationService } from '../../../../../service/formation.service';
import { Staff } from '../../../../../model/staff.model';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { SiteService } from '../../../../../service/site.service';
@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss', './informations.ux.scss', '../formation.ux.scss', '../../mainscreen.ux.scss'],
  providers: [FormationService, InformationsService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ]
})
export class InformationsComponent implements OnInit, OnDestroy {
  @Input() formation: Formation; // input du composant parent
  @Output() handleUpdateFormation = new EventEmitter<any>(); // event pour le composant parent.

  // --- variables à update :
  statusSelected: string = null;
  listStatus: any;
  listModeFinancement: any;
  listBookingMode: any;
  startDate: Date;
  startFrom: Date;
  endDate: Date;
  hours_remaining: number;
  old_hoursRemaining: number;
  hours_total: number;
  old_hoursTotal: number;
  mySite;
  mySiteService;
  queryResponsable: Staff;
  old_queryResponsable: Staff;
  responsables: Staff[];
  filteredResponsablesList = [];
  elementRef: ElementRef;
  editResponsable = false;
  informationService: InformationsService;
  formationService: FormationService;

  groupSpecificList: ExerciceSpecificContent[] = [];
  selectedGroupSpecificList: ExerciceSpecificContent[] = [];
  filteredGroupSpecific: Observable<ExerciceSpecificContent[]>;

  // manager
  managerControl: FormControl = new FormControl();
  filteredManager: Observable<Staff[]>;
  myManager: {};

  constructor(
    private kiwixiGlobals: KiwixiGlobals,
    myElement: ElementRef,
    private siteService: SiteService,
    _informationService: InformationsService,
    _formationService: FormationService,
    private exerciceSpecificContentService: ExerciceSpecificContentService,
    private adapter: DateAdapter<any>) {
    this.elementRef = myElement;
    this.informationService = _informationService
    this.formationService = _formationService;
    this.adapter.setLocale('fr');
  }

  ngOnInit() {
    // On initalise à l'affichage des informations.
    this.startDate = new Date(this.formation.validity_period_from);
    this.endDate = new Date(this.formation.validity_period_to);
    if (this.formation.start_from) {
      this.startFrom = new Date(this.formation.start_from);
    }
    this.hours_remaining = this.formation.hours_remaining;
    this.old_hoursRemaining = this.formation.hours_remaining;
    this.hours_total = this.formation.hours_total;
    this.formation.hours_total = this.kiwixiGlobals.formatNumber(this.formation.hours_total, 1);
    this.old_hoursTotal = this.formation.hours_total;
    this.listStatus = this.formationService.getStatus();
    this.listModeFinancement = this.formationService.getModeFinancement();
    this.listBookingMode = this.formationService.getBookingMode();
    this.queryResponsable = this.formation.manager;
    this.old_queryResponsable = this.formation.manager;
    this.selectedGroupSpecificList = this.formation.specific_content;
    this.mySiteService = this.siteService.getObservableSite(this.formation.site).subscribe(_site => {
      this.mySite = _site;
      this.mySite.domain = 'https://' + this.mySite.domain
    })
    this.exerciceSpecificContentService.getExercicesSpecificContent(null).subscribe(data => {
      if (data['results']) {
        this.groupSpecificList = data['results'];
      }
    });


    this.computeResponsables();

    // init manager combo
    this.filteredManager = this.managerControl.valueChanges
      .startWith(null)
      .map(val => val ? this.filterManager(val) : this.responsables);
  }

  ngOnDestroy() {
    if (this.mySiteService) {
      this.mySiteService.unsubscribe();
    }
  }

  // On recupère la formation pour update le parent
  updateFormation() {
    this.formationService.getFormation(this.formation.id).then(
      _formation => {
        this.formation = _formation;
        this.handleUpdateFormation.emit(this.formation);
      }
    )
  }

  /**
   * mise à jour de la formation - Patch api
   * @param _param
   */
  patchFormation(_param) {
    this.formationService.patchFormation(this.formation.url, _param)
      .then(_formation => {
        this.formation = _formation;
        this.handleUpdateFormation.emit(this.formation)
      });
  }

  // filtrer la liste des responsables
  filterManager(val: string): any[] {
    if (typeof (val) === 'object') {
      return this.responsables;
    }
    return this.responsables.filter(option => {
      if (option.user.first_name) {
        return (option.user.first_name.toLowerCase().indexOf(val.toLowerCase()) >= 0 ||
          option.user.last_name.toLowerCase().indexOf(val.toLowerCase()) >= 0
        )
      } else {
        return option.user.email.toLowerCase().indexOf(val.toLowerCase()) >= 0;
      }
    })
  }

  // formater l'affichage du responsable dans l'input.
  displayManagerFn(manager) {
    if (manager) {
      return manager.user.first_name ? manager.user.first_name + ' ' + manager.user.last_name : manager.user.email;
    }
    return '';
  }

  /**
   * Méthode pour récupérer la liste des responsables - get api.
   */
  computeResponsables() {
    this.informationService.getManagers()
      .then(
        responsables => {
          this.responsables = responsables['results'];
        }
      );
  }

  // mise à jour du responsable pour la formation.
  checkInputManager() {
    if (!this.formation.manager.url) {
      this.formation.manager = null;
    } else {
      const _formation = this.formationServerMirror();
      const param = {
        manager: this.formation.manager
      }
      this.informationService.updateFormationVariable(_formation.url, param)
        .then(
          response => {
            this.editResponsable = false;
          }
        );
    }
  }

  // annuler le choix du responsable
  cancelResponsable() {
    this.editResponsable = false;
    this.formation.manager = this.old_queryResponsable;
    this.queryResponsable = this.old_queryResponsable;
  }

  // Etat de la formation avant modification.
  formationServerMirror() {
    const _teachers = [];
    for (const teacher of this.formation.teachers) {
      _teachers.push(teacher.url);
    }
    return {
      url: this.formation.url,
      updated_at: this.formation.updated_at,
      created_at: this.formation.created_at,
      name: this.formation.name,
      status: this.formation.status,
      hours_remaining: this.hours_remaining,
      hours_total: this.hours_total,
      validity_period_from: this.startDate,
      validity_period_to: this.endDate,
      start_from: this.startFrom,
      notes_to_teachers: this.formation.notes_to_teachers,
      site: this.formation.site.url,
      student: this.formation.student.url,
      manager: this.formation.manager,
      language: this.formation.language,
      teachers: _teachers,
      mode_financement: this.formation.mode_financement,
      booking_mode: this.formation.booking_mode,
      sf_id: this.formation.sf_id,
    }
  }

  /**
   * mettre à jour la date de commencement de la formation - patch api.
   * @param _newDate
   */
  updateStartDate(_newDate) {
    if (this.startDate !== _newDate) {
      this.startDate = _newDate;
      const _formation = this.formationServerMirror();
      const _param = {
        validity_period_from: _formation.validity_period_from
      }
      this.patchFormation(_param);
    }
  }

  /**
   * mettre à jour la date de commencement de la formation - patch api.
   * @param _newDate
   */
  updateStartFromDate(_newDate) {
    if (this.startFrom !== _newDate) {
      this.startFrom = _newDate;
      const _formation = this.formationServerMirror();
      const _param = {
        start_from: _formation.start_from,
      }
      this.patchFormation(_param);
    }
  }

  /**
   * mettre à jour la date de fin de la formation - patch api.
   * @param _newDate
   */
  updateEndDate(_newDate) {
    if (this.endDate !== _newDate) {
      this.endDate = _newDate;
      const _formation = this.formationServerMirror();
      const _param = {
        validity_period_to: _formation.validity_period_to
      }
      this.patchFormation(_param);
    }
  }

  updateOpportunityId() {
    const _formation = this.formationServerMirror();
    const param = {
      sf_id: _formation.sf_id
    }
    this.informationService.updateFormationVariable(_formation.url, param)
      .then(
        response => {
        }
      );
  }
  /**
   * mettre à jour le status de la formation - patch api
   */
  selectStatus() {
    const _formation = this.formationServerMirror();
    const param = {
      status: _formation.status
    }
    this.informationService.updateFormationVariable(_formation.url, param)
      .then(
        response => {
        }
      );
  }

  /**
  * mettre à jour le status de la formation - patch api
  */
  selectModeFinancement() {
    const _formation = this.formationServerMirror();
    const param = {
      mode_financement: _formation.mode_financement
    }
    this.informationService.updateFormationVariable(_formation.url, param)
      .then(
        response => {
        }
      );
  }

  /**
 * mettre à jour le status de la formation - patch api
 */
  selectBookingMode() {
    const _formation = this.formationServerMirror();
    const param = {
      booking_mode: _formation.booking_mode
    }
    this.informationService.updateFormationVariable(_formation.url, param)
      .then(
        response => {
        }
      );
  }
  /**
   * evenement au focus out des notes. patch api
   */
  focusOutNotes() {
    const _formation = this.formationServerMirror();
    const param = {
      notes_to_teachers: _formation.notes_to_teachers
    }
    this.informationService.updateFormationVariable(_formation.url, param)
  }

  /**
   * evenement au focus out du totl heures. patch api
   */
  focusOutTotalHours() {
    // Total ne peut pas être inferieur
    // a la somme pris + planned
    const _formation = this.formation;
    let min_hours_total = _formation.hours_total;
    if (_formation.hours_total - (_formation.hours_planned) < 0) {
      min_hours_total = _formation.hours_planned;
      this.formationService.toastService.show(
        `Heures totales de la formation ne peuvent être inférieurs à la somme des heures consommées et des heures plannifiées`,
        4000,
        'red'
      )
      this.formation.hours_total = min_hours_total;
    } else {
      const param = {
        hours_total: min_hours_total
      }
      this.informationService.updateFormationVariable(_formation.url, param)
        .then(
          response => {
            this.formation.hours_total = this.kiwixiGlobals.formatNumber(this.formation.hours_total, 1);
            this.formation.hours_consummed = response.hours_consummed;
            this.formation.hours_to_plan = response.hours_to_plan;
            this.formation.hours_planned = response.hours_planned;
          }
        );
    }
  }

  updateFromChips(_data, myObject) {
    const listToUpdate = [];
    _data.map(chip => listToUpdate.push(chip.url))
    const _params = {
      'specific_content': listToUpdate
    };
    this.formationService.patchFormation(this.formation.url, _params).then(formation => {

    })
  }

}
