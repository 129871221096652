/**
 * Composant pour l'item concernant le prochain cours.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UpcomingService } from '../../../../../service/upcoming.service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormationService } from '../../../../../service/formation.service';
import { AuthenticationService } from '../../../../../service/authentification.service';

@Component({
  selector: 'app-next',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss']
})
export class NextComponent implements OnInit, OnDestroy {
  formation: any;
  formation_id: any;
  myUser: any;
  upcomings: any[] = [];
  teachersByEvents: any[] = [];
  upcomingBooked = null;
  upcomingWaiting = null;
  lastUpcomingDate = null;
  countPages = 1;
  countPagesCancelledByStudent = 1;
  countPagesDelayedByTeacher = 1;
  searchInput = '';
  countUpcomingsLoaded = 0;
  loadAllUpcomings = false;
  callServiceFormationId = null;
  constructor(
    private upcomingService: UpcomingService,
    private formationService: FormationService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
          this.formation_id = this.route.parent.snapshot.params.id_formation;
          this.callServiceFormationId = this.formationService.getObservableFormationById(this.formation_id)
            .subscribe(_formation => {
              this.formation = _formation;
              this.initList();
            })
        }
      }
    )
  }

  ngOnDestroy() {
    if (this.callServiceFormationId) {
      this.callServiceFormationId.unsubscribe();
    }
  }

  initList() {
    this.upcomings = [];
    this.setUpcomings();
  }

  setUpcomings() {
    this.loadAllUpcomings = true;
    this.countUpcomingsLoaded = 0;
    const params = new HttpParams()
      .set('page', this.countPages.toString())
      .set('formation', this.formation_id)
    this.upcomingService.getUpcomings(params)
      .subscribe(_upcomings => {
        if (_upcomings) {
          this.lastUpcomingDate = _upcomings['last'];
          _upcomings['results'].forEach(_upcoming => {
            this.upcomings.push(_upcoming);
          });
          if (_upcomings['next']) {
            this.countPages++;
            this.setUpcomings();
          } else {
            // set lists
            this.upcomingBooked = this.upcomings.filter(myUpcomings => myUpcomings.status === 'booked');
            if (_upcomings['count'] === 0) {
              this.loadAllUpcomings = false;
            }
          }
        }
      });
  }

  sumWaiting(a, b) {
    return a + b
  }

  loadUpcomings() {
    this.countUpcomingsLoaded++;
    if (this.countUpcomingsLoaded === this.upcomingBooked.length) {
      this.loadAllUpcomings = false;
    }
  }

}
