<p *ngIf="loading; else teacher">
  Chargement ...
</p>
<ng-template #teacher>
  <div *ngIf="my_teacher.user.photo_profile === null && my_teacher.user.first_name === null && my_teacher.user.last_name === null; else infoStudent">--</div>
  <ng-template #infoStudent>
    <div class="valign-wrapper">
      <div class="col datatable-avatar-block">
          <ng-container *ngIf="my_teacher.user.photo_profile; else elseTemplate">
              <img class="user-avatar" src='{{ my_teacher.user.photo_profile.thumbnail}}' (click)="handleClicked($event)" (error)="this.teacherService.errorImgHandler($event)">
          </ng-container>
          <ng-template #elseTemplate>
              <img class="user-avatar" src='{{defaultAvatar}}' (click)="handleClicked($event)">
          </ng-template>
      </div>
      <div class="col truncate">
        {{ my_teacher.user.first_name }}<br/>{{ my_teacher.user.last_name }}
      </div>
    </div>
  </ng-template>
</ng-template>
