import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  @Input() chipsList; // liste des éléments à populer
  @Input() choices;
  @Input() data; // liste de tous les éléments
  @Input() removableCondition; // indique un object à ne pas supprimer
  @Input() parent; // composant parent
  @Input() myObject; // Nom de l'object que le parent doit mettre à jour
  @Input() label; // champ à afficher
  selectable = true;
  chipControl: FormControl = new FormControl();
  tags = [];
  addChip = false;

  constructor() { }

  ngOnInit() {
    this.choices = this.chipControl.valueChanges
      .startWith('')
      .map(val => this.data.filter(function (el) {
        return el[this.label].toLowerCase().indexOf(val.toLowerCase()) > -1;
      }.bind(this)));
  }

  removable(_chip) {
    if (this.removableCondition) {
      if (_chip[this.label] === this.removableCondition) {
        return false;
      }
    }
    return true;
  }

  selectChoice(choice) {
    if (!this.selectExist(choice[this.label], this.tags)) {
      this.tags.push(choice[this.label]);
      this.chipsList.push(choice);
      this.parent.updateFromChips(this.chipsList, this.myObject);
    }
    this.chipControl.setValue('');
    this.addChip = false;
  }

  removeChip(choice): void {
    const index = this.chipsList.indexOf(choice);
    if (index >= 0) {
      this.chipsList.splice(index, 1);
    }
    this.parent.updateFromChips(this.chipsList, this.myObject);
  }

  selectExist(select, arr) {
    return arr.some(function (el) {
      return el === select;
    });
  }

  isDisabled(_choice) {
    if (_choice) {
      for (const chip of this.chipsList) {
        if (chip[this.label] === _choice[this.label]) {
          return true
        }
      }
    }
    return false;
  }

}
