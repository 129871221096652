import { PlanService } from './../../service/plan.service';
import { Formation } from './../../model/formation.model';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Event } from './../../model/event.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-time-selector-no-dispo',
  templateUrl: './time-selector-no-dispo.component.html',
  styleUrls: ['./time-selector-no-dispo.component.scss']
})
export class TimeSelectorNoDispoComponent implements OnInit {
  @Input() formation: Formation;
  @Input() open_to_all_teacher;
  @Input() force_teachers: number[];
  @Input() exclude_teachers: number[];
  @Input() loaderSearchSolution: boolean;
  @Output() removeTimeSlot: EventEmitter<any> = new EventEmitter();
  @Output() changedSlotValue: EventEmitter<any> = new EventEmitter();

  event: Event = new Event;
  translatePath = 'lingueopro.reschedule';
  _daysList = [
    { str: this.translatePath + '.monday', moment_id: 1, data_id: 0, enable: false },
    { str: this.translatePath + '.tuesday', moment_id: 2, data_id: 1, enable: false },
    { str: this.translatePath + '.wednesday', moment_id: 3, data_id: 2, enable: false },
    { str: this.translatePath + '.thursday', moment_id: 4, data_id: 3, enable: false },
    { str: this.translatePath + '.friday', moment_id: 5, data_id: 4, enable: false },
    { str: this.translatePath + '.saturday', moment_id: 6, data_id: 5, enable: false },
    { str: this.translatePath + '.sunday', moment_id: 0, data_id: 6, enable: false }
  ];
  startList = [];
  endList = [];
  daysList = [];

  day_FormControl = new FormControl('', [Validators.required]);
  start_FormControl = new FormControl('', [Validators.required]);
  end_FormControl = new FormControl('', [Validators.required]);

  slotFormGroup: FormGroup = this.builder.group({
    day: this.day_FormControl,
    start: this.start_FormControl,
    end: this.end_FormControl,
  });

  showDays = false;
  showStart = false;
  showEnd = false;

  constructor(private planService: PlanService, private builder: FormBuilder) { }

  ngOnInit() {
    if (!this.open_to_all_teacher) {
      this.open_to_all_teacher = false;
    }
    this.initDays();
    this.slotFormGroup.valueChanges.subscribe(element => {
      this.emitChangedSlotValue();
    })
  }

  initDays() {
    const param = {
      algorithm: 'availabilityteachers',
      formation: this.formation.id,
      open_to_all_teacher: this.open_to_all_teacher,
      force_teachers: [],
      exclude_teachers: [],
    }
    if (this.force_teachers) {
      if (this.force_teachers.length > 0) {
        param.force_teachers = this.force_teachers;
      }
    }
    if (this.exclude_teachers) {
      if (this.exclude_teachers.length > 0) {
        param.exclude_teachers = this.exclude_teachers;
      }
    }
    const that = this;
    this.planService.postPlan(param)
      .then(data => {
        if (data) {
          this._daysList.forEach(function (_day) {
            const isEnable = data.filter(day => day === _day.data_id)
            if (isEnable.length > 0) {
              _day.enable = true;
              that.daysList.push(_day);
            }
          });
          this.showDays = true;
        }
      })
  }

  emitRemoveTimeSlot() {
    this.removeTimeSlot.emit()
  }

  emitChangedSlotValue() {
    this.changedSlotValue.emit()
  }

  initStart() {
    this.event = new Event;
    this.start_FormControl.reset()
    this.startList = [];
    const param = {
      algorithm: 'availabilityteachers',
      formation: this.formation.id,
      open_to_all_teacher: this.open_to_all_teacher,
      force_teachers: [],
      exclude_teachers: [],
      day: this.day_FormControl.value.data_id,
    }
    if (this.force_teachers) {
      if (this.force_teachers.length > 0) {
        param.force_teachers = this.force_teachers;
      }
    }
    if (this.exclude_teachers) {
      if (this.exclude_teachers.length > 0) {
        param.exclude_teachers = this.exclude_teachers;
      }
    }
    this.planService.postPlan(param)
      .then(data => {
        if (data) {
          let slot = this.getNextDay();
          for (let i = 0; i < 48; i++) {
            const isEnable = this.findSlotInAvailabilities(slot, data)
            const my_obj = {
              date: slot.toISOString(),
              str: slot.format('HH:mm'),
              enable: isEnable,
            }
            if (isEnable) {
              this.startList.push(my_obj);
            }
            slot = slot.add(30, 'minutes');
          }
        }
      })
  }

  initEnd() {
    this.event = new Event;
    this.end_FormControl.reset()
    this.endList = [];
    const param = {
      algorithm: 'availabilityteachers',
      formation: this.formation.id,
      open_to_all_teacher: this.open_to_all_teacher,
      force_teachers: [],
      exclude_teachers: [],
      day: this.day_FormControl.value.data_id,
      start: this.start_FormControl.value,
    }
    if (this.force_teachers) {
      if (this.force_teachers.length > 0) {
        param.force_teachers = this.force_teachers;
      }
    }
    if (this.exclude_teachers) {
      if (this.exclude_teachers.length > 0) {
        param.exclude_teachers = this.exclude_teachers;
      }
    }
    const moment_start_value = moment(this.start_FormControl.value);
    this.planService.postPlan(param)
      .then(data => {
        if (data) {
          let slot = this.getNextDay();
          slot = slot.add(30, 'minutes');
          for (let i = 0; i <= 47; i++) {
            let isEnable = this.findSlotInAvailabilities(slot, data)
            const duration = moment.duration(slot.diff(moment_start_value));
            const diff_start_hours = duration.asHours();
            if (slot < moment_start_value || diff_start_hours > 2) {
              isEnable = false;
            }
            const my_obj = {
              date: slot.toISOString(),
              str: slot.format('HH:mm'),
              enable: isEnable,
            }
            if (isEnable) {
              this.endList.push(my_obj);
            }
            slot = slot.add(30, 'minutes');
          }
          let initEndValue = moment(this.start_FormControl.value).add(60, 'minutes');
          const isDispoInOneHour = this.endList.filter(end => moment(end.date).toISOString() === initEndValue.toISOString())
          if (isDispoInOneHour.length === 0) {
            initEndValue = moment(this.start_FormControl.value).add(30, 'minutes');
          }
          this.end_FormControl.setValue(initEndValue.toISOString());
          this.setEvent();
        }
      })
  }

  findSlotInAvailabilities(slot, availabilities) {
    const findSlotEnable = availabilities.filter(availabilies => moment(availabilies).isSame(slot));
    // const findSlotEnable = availabilities.filter(availabilies => moment(availabilies).day() === slot.day()); // fix à tester

    let isEnable = false;
    if (findSlotEnable.length > 0) {
      isEnable = true;
    }
    return isEnable;
  }

  setEvent() {
    this.event.id = Math.random()
    this.event.start = this.start_FormControl.value;
    this.event.end = this.end_FormControl.value;
  }

  getNextDay() {
    const d = moment();
    const moment_id = this.day_FormControl.value.moment_id;
    // On verifie si le jour sélectionné est antérieur à la date du jour
    if (moment_id === d.day()) {
      d.add(1, 'weeks')
    } else if (moment_id < d.day() && moment_id > 0) {
      d.add(1, 'weeks')
    } else if (moment_id > d.day() && d.day() === 0) {
      d.add(1, 'weeks')
    }
    let _weekday = d.weekday(this.day_FormControl.value.data_id);

    const start_from = this.formation.start_from ? this.formation.start_from : this.formation.validity_period_from;
    // On vérifie si le jour sélectionné est antérieur à la date du début de formation
    if (moment(start_from).isSameOrAfter(_weekday)) {
      const validity_period_from = moment(start_from);
      _weekday = validity_period_from.weekday(this.day_FormControl.value.data_id);
      if (moment(start_from).isAfter(_weekday)) {
        _weekday = _weekday.add(1, 'weeks')
      }
    }
    const nextDay = moment([_weekday.year(), _weekday.month(), _weekday.date(), 0, 0, 0, 0]);
    return nextDay;
  }

}
