
<div class="information-container">
  <div class="row form-row">
    <div class="col l3">
      <h6 class="center grey darken-1 white-text title-information">Évaluations</h6>
    </div>
  </div>
  <div class="container">
    <span class="teacher-score">Score Lingueo</span>
    <div class="teacher-rank">{{myTeacher.rank}}<span *ngIf="myTeacher.rank === 1">er </span><span *ngIf="myTeacher.rank > 1">ème </span> 
      <span *ngIf="countTeachers === 1">sur {{countTeachers}} professeur d'Anglais</span>
      <span *ngIf="countTeachers > 1">sur {{countTeachers}} professeurs d'Anglais</span>
    </div>
    <div class="row">
      Derniers commentaires d'élèves :
    </div>
    <div class="bloc-eval"
            infiniteScroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollThrottle]="throttle"
            [scrollWindow]="false"
            (scrolled)="onScrollDown()">
        <div class="row rate-border" *ngFor="let rating of ratings">
            <div>
              <mat-select [(ngModel)]="rating.status" (ngModelChange)="updateRating(rating, 'status')">
                <mat-option *ngFor="let item of ratingsStatus" [value]="item.code">
                  {{ item.label }}
                </mat-option>
              </mat-select>
              <div>
                <span class="short-description" *ngIf="rating.short_description">{{rating.short_description}}</span>
                <span *ngIf="rating.short_description"> - </span>
                <span class="rating-info">{{rating.student_first_name}} le {{rating.updated_at | amLocal | amDateFormat: 'DD/MM/YY'}}</span>
              </div>
              <div class="starbar">
                <div class="star-icon" *ngFor="let item of [1,2,3,4,5]">
                  <div *ngIf="item <= rating.value">
                    <i class="material-icons tiny">star</i>
                  </div>
                  <div *ngIf="item > rating.value">
                    <i class="material-icons tiny grey-text text-lighten-2">star_border</i>
                  </div>
                </div>
              </div>
              <div class="rating-message">
                  {{rating.message}}
              </div>
            </div>
          </div>
    </div>
  </div>
</div>
