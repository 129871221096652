 <div id="assign-sheets" >
    <!--<div class="assign-header">
        <span class="page-title">Study Sheets</span>
        <a routerLink="{{ previousUrl }}">Back</a>
    </div>-->

    <div class="assign-mainwrap">

        <div class="fullpage-sheet-viewer">
            <app-sheet-view [sheet]="sheet" [showExercices]="false" [showExamination]="false" [filterByStudent]="" *ngIf="sheet"></app-sheet-view>
            <i class="material-icons back-btn" routerLink="../../../../..">arrow_back</i>
        </div>

    </div>

</div>