import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsComponent } from './trainings.component';
import { DetailComponent } from './detail/detail.component';
import { NextComponent } from './detail/next/next.component';
import { PastComponent } from './detail/past/past.component';
import { MessageComponent } from './detail/message/message.component';
import { InfoComponent } from './detail/info/info.component';
import { TrainingsRoutingModule } from './trainings-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureModule } from '../../../feature/feature.module';
import { MomentModule } from 'ngx-moment';
import { TrainingComponent } from './training/training.component';
import { ListComponent } from './list/list.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { TeacherProfilComponent } from './teacher-profil/teacher-profil.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UpcomingClassesModule } from '../upcoming-classes/upcoming-classes.module';
import { UpcomingEventsComponent } from './detail/next/upcoming-events/upcoming-events.component';
import { ReportsModule } from '../reports/reports.module';
import { MaterialModule } from '../../../material.module';
import { AssignSheetComponent } from './detail/next/assign-sheet/assign-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TrainingsRoutingModule,
    FeatureModule,
    MomentModule,
    MaterialModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
    UpcomingClassesModule,
    ReportsModule
  ],
  declarations: [
    TrainingsComponent,
    DetailComponent,
    NextComponent,
    PastComponent,
    MessageComponent,
    InfoComponent,
    TrainingComponent,
    ListComponent,
    TeacherProfilComponent,
    UpcomingEventsComponent,
    AssignSheetComponent
  ],
  exports: [AssignSheetComponent]
})
export class TrainingsModule { }
