<ng-container *ngIf="teacher">
  <div class="teachprofile-header">
    <i class="material-icons back-btn" (click)="back()">arrow_back</i>
    <b class="page-title"><span translate>{{translatePath}}.Teacher-profile</span><span> {{teacher.user.first_name}}</span></b>
  </div>
<div class="teachprofile-wrapper">
  <div class="decorative-border">
   
    <div class="teachintro-wrapper">
      <div class="teachintro-pic">
        <div class="avatar-inset-shadow">
        </div>
        <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="teacher.user.photo_profile === null">
        <img class="user-avatar" src="{{teacher.user.photo_profile.thumbnail}}" *ngIf="teacher.user.photo_profile !== null">
      </div>
      <div class="teachintro-copy">
        {{teacher.description_vo}}
      </div>
    </div>
    
    <!--<button class="action-btn"><b translate>{{translatePath}}.send-message-btn</b></button>-->
    <button class="action-btn" (click)="back()"><b translate>{{translatePath}}.close-btn</b></button>
  </div>
</div>
</ng-container>

