import { TranslateService } from '@ngx-translate/core';
/**
 * Service local dédié au radar.
 */

import { Injectable } from '@angular/core';
import { Progression } from '../../model/progression.model';
import { SessionService } from '../../service/session.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import 'rxjs/';
import * as moment from 'moment';

@Injectable()
export class RadarService extends KiwixiService {
  radarChartLabels = [];
  chartColors = [
    {
      backgroundColor: 'rgba(0, 151, 167, 1)',
      borderColor: '#0097a7',
      pointBackgroundColor: '#276073',
      pointBorderColor: '#276073',
      pointHoverBackgroundColor: '#276073',
      pointHoverBorderColor: '#276073',
      pointRadius: 0
    },
    {
      backgroundColor: 'rgba(211, 211, 211, 0.5)',
      borderColor: 'rgba(211, 211, 211, 0)',
      pointBackgroundColor: '#276073',
      pointBorderColor: '#276073',
      pointHoverBackgroundColor: '#276073',
      pointHoverBorderColor: '#276073',
      pointRadius: 0
    }
  ];
  radarOptions = {
    maintainAspectRatio:false,
    responsive:true,
    layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
      }
    },
    scale: {
      display: true,
      ticks: {
        display: false,
        min: 0,
        max: 100,
        stepSize: 20,
        maxTicksLimit: 7
      },
      angleLines: {
        display: true,
        color: '#0097a7'
      },
      gridLines: {
        display: false,

      }
    }
  }

  grammaireLabel;
  expressionLabel;
  comprehensionLabel;
  vocabulaireLabel;
  interactionProLabel;

  evaluation_connection = [
    {
      label: 'A1',
      value: 10
    },
    {
      label: 'A2',
      value: 20
    },
    {
      label: 'B1',
      value: 40
    },
    {
      label: 'B2',
      value: 60
    },
    {
      label: 'C1',
      value: 80
    },
    {
      label: 'C2',
      value: 100
    }
  ]

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
    private translateService: TranslateService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
    this.getTranslateLabel()
  }

  getTranslateLabel() {
    this.translateService.get('RATE.LEVEL_GRAMARY').subscribe((res: string) => { this.grammaireLabel = res; });
    this.translateService.get('RATE.LEVEL_VOCABULARY').subscribe((res: string) => { this.vocabulaireLabel = res; });
    this.translateService.get('RATE.LEVEL_EXPRESSION').subscribe((res: string) => { this.expressionLabel = res; });
    this.translateService.get('RATE.LEVEL_COMPREHENSION').subscribe((res: string) => { this.comprehensionLabel = res; });
    this.translateService.get('RATE.LEVEL_ACCENT').subscribe((res: string) => { this.interactionProLabel = res; });
  }

  radarIsAvailable(_occ) {
    if (_occ.status === 'done' && _occ.session && _occ.session.rate_teacher) {
      return true
    }
    return false;
  }
  setDataRadar(_currentOccurrence, _radarChartData) {
    return this.buildProgressionData(_currentOccurrence.session, _currentOccurrence, _radarChartData);
  }

  buildProgressionData(_session, _currentOccurrence, _radarChartData) {
    const progression = new Progression();
    const dataProgression = [];
    _radarChartData[0]['labels'] = [];
    _radarChartData[1]['labels'] = [];
    if (_session.rate_teacher) {
      dataProgression.push(this.computeEvaluation(_session.rate_teacher.level_grammar));
      dataProgression.push(this.computeEvaluation(_session.rate_teacher.level_expression));
      dataProgression.push(this.computeEvaluation(_session.rate_teacher.level_comprehension));
      dataProgression.push(this.computeEvaluation(_session.rate_teacher.level_vocabulary));
      dataProgression.push(this.computeEvaluation(_session.rate_teacher.level_accent));
      progression.url = _session.rate_teacher.url;
      progression.labels = [
        this.grammaireLabel + ' - ' + _session.rate_teacher.level_grammar + ' : ' + Math.round(_session.rate_teacher.grammar) + '%',
        this.expressionLabel + ' - ' + _session.rate_teacher.level_expression + ' : ' + Math.round(_session.rate_teacher.expression) + '%',
        this.comprehensionLabel + ' - ' + _session.rate_teacher.level_comprehension + ' : ' + Math.round(_session.rate_teacher.comprehension) + '%',
        this.vocabulaireLabel + ' - ' + _session.rate_teacher.level_vocabulary + ' : ' + Math.round(_session.rate_teacher.vocabulary) + '%',
        this.interactionProLabel + ' - ' + _session.rate_teacher.level_accent + ' : ' + Math.round(_session.rate_teacher.accent) + '%',
      ];
    }

    progression.current_date = _currentOccurrence.start;
    progression.data = dataProgression;
    this.radarChartLabels = progression.labels;
    _radarChartData[0]['data'] = progression.data;
    _radarChartData[0]['labels'] = progression.labels;
    _radarChartData[1]['data'] = [100, 100, 100, 100, 100];
    _radarChartData[1]['labels'] = progression.labels;
  }

  computeEvaluation(_level) {
    return this.evaluation_connection.filter(_eval => _eval.label === _level)[0].value;
  }


  downloadPdf(_myLilate, formation, lastOccurrenceWithReport) {
    const header = this.createHttpClientAuthorizationHeader();
    this._http.get(_myLilate, { headers: header, responseType: 'arraybuffer' })
      .subscribe((file: ArrayBuffer) => {
        const pdfSrc = new Uint8Array(file);
        const blob = new Blob([pdfSrc], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const _date = moment(lastOccurrenceWithReport.start).format('DD_MM_YYYY');
        const _name = '_' + formation.student.user.first_name + '_' + formation.student.user.last_name;
        const filename = 'certificat_' + _date + _name;
        const $a = $('<a />', {
          'href': url,
          'download': filename + '.pdf',
          'text': 'click'
        }).hide().appendTo('body')[0].click();
      });
  }
}
