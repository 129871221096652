import { environment } from '../../environments/environment';
export class Language {
  id: number;
  url: string;
  code: string;
  flag: string;
  label: string;
  constructor(url: string, code: string, flag: string, label: string) {
    this.url = url;
    this.code = code;
    this.flag = flag;
    this.label = label;
  }
  urlFlag() {
    return this.flag ? this.flag : environment.static + 'flags/' + this.code + '.png';
  }
}
