<nav>
  <div class="nav-wrapper header-teacher white">
    <div class="row valign-wrapper">
      <div routerLink="/" class="teacher-header-logo"></div>
      <div class="item menu-icon" (click)="showMenu = !showMenu">
        <i class="material-icons small black-text">menu</i>
      </div>
      <div class="item teacher-avatar" routerLink="/myaccount">
        <div class="avatar-inset-shadow"></div>
        <img class="user-avatar" src="{{defaultAvatar}}">
        <!-- Replace above img tag with conditional User / Default Avatar tags-->
      </div>
    </div>
  </div>
</nav>
<div *ngIf="showMenu" class="menu">
  <!-- nav -->
  <div class="valign-wrapper mobile-navrow nav-dashboard" routerLink="/" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.dashobard</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-upcoming" routerLink="/upcoming" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.upcoming-class</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-schedule" routerLink="/schedule" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.schedule-avaibilities</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-reports" routerLink="/reports" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.class-reports</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-trainings" routerLink="/trainings" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.student-trainings</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-messages" routerLink="/messages" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.messages</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <!--<div class="valign-wrapper mobile-navrow" routerLink="/documents" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.library</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>-->
  <div class="valign-wrapper mobile-navrow nav-sheets" routerLink="/sheets" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.sheets</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-billing" routerLink="/billing" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.billing</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>
  <div class="valign-wrapper mobile-navrow nav-account" routerLink="/myaccount" (click)="showMenu = !showMenu">
    <p translate>
      <span class="sidenav-icon"></span>teacher.nav.myaccount</p>
    <div class="right">
      <i class="material-icons small right">keyboard_arrow_right</i>
    </div>
  </div>

  <!-- log out -->
  <div class="logout-navrow" routerLink="/login" (click)="showMenu = !showMenu">
    <p translate>teacher.nav.logout</p>
  </div>
  <div class="opacity-cover" (click)="showMenu = false"></div>
</div>
<div class="popup-overlay " *ngIf="showMenu" (click)="showMenu = false"></div>