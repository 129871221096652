/**
 * Composant principal pour l'affichage des formations/trainings.
 */

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Formation } from '../../../model/formation.model';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent implements OnInit {
  trainings: Observable<Formation[]>;
  myUser: any;
  currentTrainings: any = [];
  pastTrainings: any = [];
  searchInput = '';
  countPages = 1;
  translatePath = 'teacher.trainings';
  showCurrent = true;
  showPast = true;

  constructor() { }

  ngOnInit() { }

}
