<ng-container *ngIf="teacher && showReport; else elseTemplate">
  <div class="page-subtitle feedback-subtitle"><b translate>{{translatePath}}.student-feedback-lbl</b> :</div>
  <div class="bloc-eval" *ngIf="ratings.length > 0" 
            infiniteScroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollThrottle]="throttle"
            [scrollWindow]="false"
            (scrolled)="onScrollDown()">
    <div class="row rate-border" *ngFor="let rating of ratings">
      <div>
        <div>
          <span class="short-description">{{rating.short_description}}</span>
          <span class="rating-info">- {{rating.student_first_name}} le {{rating.updated_at | amLocal | amLocale: 'en' | amDateFormat: 'DD/MM/YY'}}</span>
        </div>
        <div class="starbar">
          <div class="star-icon" *ngFor="let item of [1,2,3,4,5]">
            <div *ngIf="item <= rating.value">
              <i class="material-icons tiny">star</i>
            </div>
            <div *ngIf="item > rating.value">
              <i class="material-icons tiny grey-text text-lighten-2">star_border</i>
            </div>
          </div>
        </div>
        <div class="rating-message">
            {{rating.message}}
        </div>
      </div>
    </div>
  </div>
  <div class="nofeedback-msg" *ngIf="ratings.length == 0">{{translatePath}}.no-feedback</div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center loading pageload-message">
    <b translate>teacher.account.feedback-loading</b>
  </div>
</ng-template>

