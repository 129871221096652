import { FormationService } from './../../../../service/formation.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainscreenGlobals } from '../../../../kiwixi/mainscreen.globals';
import { Formation } from '../../../../model/formation.model';
import * as moment from 'moment';
import { InitAppService } from '../../../../service/initapp.service';

/**
 * Etape 1 plan-formation : présentation/intro.
 */
@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss', '../plan-formation.component.scss']
})
export class IntroductionComponent implements OnInit {

  formation: Formation;
  isFormationExpired = false;
  translatePath = 'lingueopro.plan-formation.introduction';
  loading = true;
  constructor(
    private mainscreenGlobals: MainscreenGlobals,
    private formationService: FormationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initAppService: InitAppService,

  ) {
    mainscreenGlobals.currentFormation.subscribe(value => this.formation = value)
  }

  ngOnInit() {

    const currentFormationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, currentFormationId);
    this.formationService.currentFormationSubject.subscribe(formation => {
      this.formation = formation;
      if (formation) {
        this.checkFormation();
        this.loading = false;
      }
    });
  }

  checkFormation() {
    if (moment().isAfter(moment(this.formation.validity_period_to))) {
      this.isFormationExpired = true;
    }
  }

  next() {
    this.router.navigate(['plan-formation/birthdate', this.formation.id]);
  }

}
