import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './messages.component';
import { ConversationComponent } from './conversation/conversation.component';
import { MessagesRoutingModule } from './messages-routing.module';
import { FeatureModule } from '../../../feature/feature.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { ContactsComponent } from './contacts/contacts.component';
import { ConversationService } from '../../../service/conversation.service';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { ContactComponent } from './contacts/contact/contact.component';
import { DocumentsModule } from '../documents/documents.module';
import { DirectiveModule } from '../../../directive/directive.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FeatureModule,
    MomentModule,
    MessagesRoutingModule,
    DocumentsModule,
    DirectiveModule,
    TranslateModule.forChild(),
    CKEditorModule
  ],
  declarations: [MessagesComponent, ConversationComponent, ContactsComponent, ContactComponent],
  providers: [ConversationService]
})
export class MessagesModule { }
