import { environment } from './../../../../environments/environment';
import { ExerciceResponse } from './../../../model/exerciceResponse.model';
import { ExerciceSession } from './../../../model/exerciceSession.model';
import { Student } from './../../../model/student.model';
import { AuthenticationService } from './../../../service/authentification.service';
import { ExerciceAnswerChoice } from './../../../model/exerciceAnswerChoice.model';
import { HttpParams } from '@angular/common/http';
import { ExerciceResponseService } from './../../../service/exerciceResponse.service';
import { FormControl, Validators } from '@angular/forms';
import { EmbedVideoService } from 'ngx-embed-video';
import { ExerciceQuestion } from './../../../model/exerciceQuestion.model';
import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-sheet-exercice-qcm',
  templateUrl: './sheet-exercice-qcm.component.html',
  styleUrls: ['./sheet-exercice-qcm.component.scss']
})
export class SheetExerciceQcmComponent implements OnChanges {
  @Input() question: ExerciceQuestion;
  @Input() exerciceSession: ExerciceSession;
  @Input() index: number;
  @Input() lastQuestion: Boolean;
  @Input() modeBilan;
  @Input() responses: ExerciceResponse[];
  @Output() emitNextQuestion = new EventEmitter();
  @Output() emitIsCompleted = new EventEmitter();
  currentQuestionVideo;
  student: Student;
  formQuestion = new FormControl('', Validators.required);
  editResult = true;
  showResult = false;
  selectedAnswer: ExerciceAnswerChoice = new ExerciceAnswerChoice;
  environment = environment.envName;

  constructor(
    private embedVideoService: EmbedVideoService,
    private exerciceResponseService: ExerciceResponseService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnChanges() {
    this.student = this.authenticationService.getCurrentPersona();
    this.initVideo();
    this.formQuestion.setValue('');
    this.formQuestion.enable();
    this.formQuestion.markAsPristine();
    this.showResult = false;
    this.editResult = true;
    if (this.responses) {
      this.loadResponse();
    }
    if (this.modeBilan) {
      this.editResult = false;
      this.formQuestion.disable();
    }
  }

  initVideo() {
    if (this.question) {
      if (this.question.media) {
        if (this.question.media.media_type === 'video' && this.question.media.source_type === 'url') {
          this.currentQuestionVideo = this.embedVideoService.embed(this.question.media.url);
        }
      }
    }
  }

  loadResponse() {
    const response: ExerciceResponse[] = this.responses.filter(r => r.question.id === this.question.id)
    if (response.length > 0) {
      this.formQuestion.setValue(response[0].answer);
      this.selectedAnswer = response[0].answer;
      this.showResult = true;
      this.formQuestion.markAsDirty();
    };

  }

  postResponse(params) {
    if (!this.modeBilan) {
      this.exerciceResponseService.postExerciceResponse(params).subscribe(result => {
        if (result) {
          //this.responses.push(result);
          this.question.response = result;
          this.editResult = false;
          this.showResult = true;
          this.formQuestion.disable();
          if (this.lastQuestion) {
            this.emitIsCompleted.emit(true);
          }
        }
      });
    }
  }

  saveResponse(choice: ExerciceAnswerChoice, position) {

    if (choice.text === '' || choice.text === null) {
      this.exerciceResponseService.toastService.show('Impossible de sauvegarde votre réponse', 4000, 'red');
      return
    }
    if (this.editResult) {
      const params = {
        value: choice.text,
        position: position,
        question: this.question.url,
        student: this.student.url,
        answer: choice.url,
        session: this.exerciceSession.url,
      };
      this.postResponse(params);
    }
  }

  nextQuestion() {
    this.emitNextQuestion.emit();
  }

}
