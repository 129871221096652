import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { Exercice } from '../model/exercice.model';
import { ExerciceList } from '../model/exerciceList.model';
import { Student } from '../model/student.model';

@Injectable()
export class ExerciceService extends KiwixiService {

    apiExercice = environment.server + 'exercices/';
    apiCurrent = this.apiExercice + 'current/';

    dificultyList = [
        { 'data': 'easy', 'label': 'Facile' },
        { 'data': 'medium', 'label': 'Moyen' },
        { 'data': 'hard', 'label': 'Difficle' },
    ];

    validatePercentStep = 80;
    practicePercentStep = 100;

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }
    getDificultyList() {
        return this.dificultyList;
    }
    getExerciceById(_id): Observable<Exercice> {
        const url = this.apiExercice + _id + '/';
        return this.getExerciceByUrl(url);
    }

    getExerciceByUrl(_url): Observable<Exercice> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<Exercice>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getExercice`))
            );
    }

    getExercices(params: HttpParams): Observable<ExerciceList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceList>(this.apiExercice, {
            headers: header,
            params: params
        })
            .map(_Exercices => _Exercices)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceList>(`getObservableExercices`))
            );
    }
    patchFile(ExerciceUrl, param, field, file): Promise<Exercice> {
        const self = this;
        const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
        const _formData = new FormData();
        _formData.append('enctype', 'multipart/form-data');
        self.headers.delete('Content-Type');
        _formData.append(field, file);
        _formData.append('name', file.name);
        return this._http.patch(ExerciceUrl, _formData, { headers: headers })
            .toPromise()
            .then((res: any) => res)
    }

    postExercice(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<Exercice>(this.apiExercice, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Exercice>(`Création d'une fiche`)),
            );
    }

    patchExercice(_id, params) {
        const url = this.apiExercice + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<Exercice>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Exercice>(`Création d'une fiche`)),
            );
    }
}
