import { Teacher } from './../../../model/teacher.model';
import { AuthenticationService } from './../../../service/authentification.service';
import { InvoiceService } from './../../../service/invoice.service';
import { Component, OnInit } from '@angular/core';
import { Invoice } from '../../../model/invoice.model';

@Component({
  selector: 'app-dashboard-teacher-billing',
  templateUrl: './dashboard-teacher-billing.component.html',
  styleUrls: ['./dashboard-teacher-billing.component.scss']
})
export class DashboardTeacherBillingComponent implements OnInit {

  currentInvoice: Invoice
  teacher: Teacher;
  isLoaded = false;
  constructor(
    private invoiceService: InvoiceService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.teacher = this.authenticationService.currentUserSubject.value;
    this.invoiceService.getCurrentInvoice()
      .subscribe(_current => {
        if (_current) {
          this.currentInvoice = _current;
          this.isLoaded = true;
        }
      })
  }


}
