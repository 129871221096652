/**
 * Composant pour afficher le détail d'un invoice.
 * Intègrant un composant listant les occurences par invoice.
 */

import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../../../../service/invoice.service';
import * as moment from 'moment';
import { AuthenticationService } from '../../../../service/authentification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  invoice_id
  invoice;
  myTeacher;
  showPage: boolean;
  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    this.showPage = false;
  }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.route.paramMap.subscribe(_ => {
            this.invoice_id = this.route.snapshot.params.id;
            if (this.invoice_id) {
              this.getDetail()
            }
          })
          this.myTeacher = user;
        }
      }
    )
  }

  getDetail() {
    this.invoiceService.getInvoiceById(this.invoice_id)
      .subscribe(_invoice => {
        if (_invoice) {
          this.invoice = _invoice;
        }
        this.showPage = true;
      })
  }

  /**
   * upload invoice
   */
  uploadInvoice($event) {
    for (const file of $event.target.files) {
      const param = {};
      param['invoice'] = this.invoice['invoice'];
      this.invoiceService.patchFile(this.invoice.url, param, 'invoice', file)
        .then(_invoice => {
          const _param = {
            status: 'received',
            uploaded_at: moment()
          };
          this.invoiceService.patchInvoice(_invoice.url, _param)
            .then(res => {
              this.invoice = res
            })
        })
        .catch(error => {
          const _error = error;
          this.invoiceService.handleError(_error['invoice'][0]);
        })
    }
  }

}
