<p *ngIf="loading; else formation">
  Chargement ...
</p>
<ng-template #formation>
  <div *ngIf="my_formation && my_formation!== null">
    <div class="valign-wrapper" *ngIf="my_language">
      <div class="col l2">
        <app-lang-flag [language]="my_language">
        </app-lang-flag>
      </div>
      <div class="col l10 truncate formname">
        {{ my_formation.name }}
      </div>
    </div>
  </div>
</ng-template>
