import { Router, ActivatedRoute } from '@angular/router';
import { CarouselComponent } from '../../../../../feature/carousel/carousel.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AnimationType } from '../../../../../feature/carousel/carousel.animations';
import { Slide } from '../../../../../feature/carousel/carousel.interface';

@Component({
  selector: 'app-init-ponctual-demo-tour',
  templateUrl: './init-ponctual-demo-tour.component.html',
  styleUrls: ['./init-ponctual-demo-tour.component.scss']
})
export class InitPonctualDemoTourComponent implements OnInit {

  @ViewChild(CarouselComponent) carousel: CarouselComponent;

  animationType = AnimationType.Fade;
  slides: Slide[] = [];
  formationId;
  constructor(
    private elem: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // you'll get your through 'elements' below code
    this.formationId = this.route.snapshot.paramMap.get('id');
    const elements = this.elem.nativeElement.querySelectorAll('.slideshow');
    this.slides = [];
    elements.forEach(element => {
      this.slides.push({ html: element.innerHTML })
    });
  }

  closeCarousel() {
    this.router.navigate(['/formation/' + this.formationId])
  }

}
