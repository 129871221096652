<div class="traindetail-wrapper">
  
    <ng-container *ngIf="teachers && formation; else elseTemplate">
      <div class="info-container">
          <div *ngIf="formation.student.user.video_provider=='skype'" class="info-row"><b>Skype ID :</b> {{formation.student.user.id_skype}}</div>
          <div class="info-row"><b translate>{{translatePath}}.total-progress</b><span> {{formation.hours_remaining}}/{{formation.hours_total}}h</span></div>

          <div class="info-row" *ngIf="formation.hours_to_plan>0">
            <b translate>{{translatePath}}.remaining-title</b>
            <span translate
              [translateParams]="{ student_first_name: formation.student.user.first_name, hours_to_plan: formation.hours_to_plan, validity_period_to: formation.validity_period_to | amDateFormat:'DD/MM/YYYY'}">
              teacher.trainings.remaining</span>
          </div>

            <!-- Teachers -->
          <div class="teachers-wrapper">
            <b translate>{{translatePath}}.teachers</b>
            <div class="teacher-instance" *ngFor="let teacher of teachers; let idx = index" (click)="gotoProfil(idx, teacher)">
                <div class="teacher-avatar">
                  <div class="avatar-inset-shadow"></div>
                  <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="teacher.user.photo_profile === null">
                  <img class="user-avatar" src="{{teacher.user.photo_profile.thumbnail}}" *ngIf="teacher.user.photo_profile !== null">
                </div>
                <div class="teacher-label">
                  <div *ngIf="idx > 0">{{teacher.user.first_name}}</div>
                  <div *ngIf="idx === 0"><span translate>{{translatePath}}.you</span></div>
                </div>
            </div>
          </div>

          <!-- Student's picture -->
          <div class="student-bigpic">
              <div class="avatar-inset-shadow"></div>
              <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="formation.student.user.photo_profile === null">
              <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" *ngIf="formation.student.user.photo_profile !== null">
          </div>

          <!-- Notes -->
          
          <div class="notes-wrapper">  
          <b translate>{{translatePath}}.notes</b> :<span class="edit-detail" translate>teacher.trainings.only-teacher</span>
            <ng-container *ngIf="editNotes; else elseEditNotes">
              <form [formGroup]="notesForm" (ngSubmit)="updateNotes('notes_to_teachers')">            
                <div>
                  <textarea class="comment-field" matInput [formControl]="notesCtrl"></textarea>
                </div>
                <button type="submit" class="savecontent-btn">
                  <span translate>{{translatePath}}.save-btn</span>
                </button>
              </form>
            </ng-container>
            <ng-template #elseEditNotes>
              <div class="teachermsg-content">
                {{formation.notes_to_teachers}}
              </div>
              <div>
                <button (click)="editNotes = true" class="editcontent-btn">
                  <span translate>{{translatePath}}.edit-btn</span>
                </button>
                
              </div>
            </ng-template>
          </div>
          <!-- Level -->
          <ng-container *ngIf="currentReport; else elseRate">
        
            <b>{{formation.student.user.first_name}}</b><b translate>{{translatePath}}.level</b><b> {{currentSession.rate_teacher.level}}</b>

            <!-- Radar -->
            <div class="level-radar" style="display: block">
              <canvas baseChart
                height="200" width="400"
                [options]="radarOptions"
                [datasets]="radarChartData"
                [labels]="radarChartData[0]['labels']"
                [colors]="chartColors"
                [chartType]="'radar'"
                [legend]="false">
              </canvas>
            </div>

            <!-- Gauge -->
            <div class="leveldetail-togglerow" (click)="showDetails = !showDetails">
              <b translate>{{translatePath}}.See-detail</b>
              <ng-container *ngIf="!showDetails; else elseShowDetails">
                  <i class="material-icons">arrow_drop_down</i>
                </ng-container>
                <ng-template #elseShowDetails>
                  <i class="material-icons">arrow_drop_up</i>
                </ng-template>
            </div>
            <div class="gauges-wrapper" [hidden]="!showDetails">
              <div *ngFor="let subject of currentReport; let idx = index">
                <app-level [editMode]=false [subject]="subject" [isFirst]=false></app-level>
              </div>
            </div>
          </ng-container>
          <ng-template #elseRate>
            <div translate>teacher.trainings.not-rated</div>
          </ng-template>

      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="row center loading pageload-message">
        <b translate>{{translatePath}}.info-loading</b>
      </div>
    </ng-template>
  
</div>

