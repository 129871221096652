<ng-container *ngIf="showSession; else elseTemplate">
  <div class="classdetail-row">
    <div class="classdetail-content">
      {{occurrence.duration}}
      <span translate>teacher.invoices.class-with</span>
      <span>{{occurrence.student.user.first_name}} {{occurrence.student.user.last_name}}</span>
    </div>
    <div class="classdetail-date">
      {{occurrence.start | amLocale: 'en' | amDateFormat:'MMM DD YYYY, HH:mm'}}-{{occurrence.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <i>Chargement...</i>
</ng-template>