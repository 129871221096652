<div class="report-wrapper">
  <ng-container *ngIf="formation; else elseTemplate">
    <!-- Header student identity -->
    <div class="report-header">
      <div class="report-icon"></div>
      <div class="avatar-block mobile-nodisplay">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="formation.student.user.photo_profile === null"  (error)="this.formationService.errorImgHandler($event)">
            <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" *ngIf="formation.student.user.photo_profile !== null">
      </div>
      <div class="user-label">
        <div><b translate>{{translatePath}}.report-title</b> : {{pending.student_first_name}} {{pending.student_last_name}}</div>
        <div>
            {{pending.start | amLocale: 'en' | amDateFormat:'ddd DD MMM, YYYY'}}  
            {{pending.start | amLocale: 'en' | amDateFormat:'HH:mm'}} - {{pending.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
        </div>
      </div>
    </div>

    <!-- Lesson take place ? -->
    <div class="report-takeplace">
      <span translate>{{translatePath}}.lesson-take</span>
      <div class="takeplace-btn-wrapper">
        <mat-button-toggle-group [formControl]="lessonTakePlaceCtrl">
          <mat-button-toggle class="toggle-true" [value]=true>
              <span translate>{{translatePath}}.yes-btn</span>
          </mat-button-toggle>
          <mat-button-toggle class="toggle-false" [value]=false>
              <span translate>{{translatePath}}.no-btn</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
      
    <!-- *************** LESSON TAKE PLACE : NO *************** -->
    <ng-container *ngIf="lessonTakePlace === false; else elseLessonTakePlace">
        <app-cancel-session [pending]="pending"></app-cancel-session>
      <!-- <app-no-take-place [pending]="pending"></app-no-take-place> -->
    </ng-container>
    
    <!-- *************** LESSON TAKE PLACE : YES *************** -->
    <ng-template #elseLessonTakePlace>
        <app-valid-session [pending]="pending" [formation]="formation" [lessonTakePlace]="lessonTakePlace"></app-valid-session>
        <!-- <app-take-place [pending]="pending" [formation]="formation"  [occurrenceId]="occurrence_id" [lessonTakePlace]="lessonTakePlace"></app-take-place> -->
    </ng-template>

  </ng-container>
  <ng-template #elseTemplate>
    <div class="row center loading pageload-message">
      <b translate>{{translatePath}}.edit-report-loading</b>
    </div>
  </ng-template>
</div>
  
  
  
