import { ExerciceMedia } from './exerciceMedia.model';
import { Exercice } from './exercice.model';
import { Language } from './language.model';
import { ExerciceCategory } from './exerciceCategory.model';
import { ExerciceTag } from './exerciceTag.model';
import { ExerciceSpecificContent } from './exerciceSpecificContent.model';
export class ExerciceSheet {
    id: number;
    url: string;
    title_fr: string;
    content: string;
    to_remember: string;
    show_to_remember: boolean;
    cecrl_level: string;
    category: ExerciceCategory;
    exercices: Exercice[];
    media: ExerciceMedia;
    language: Language;
    sheets: ExerciceSheet[];
    tags: ExerciceTag[];
    specific_content: ExerciceSpecificContent[];
    status: string;
    nb_exercices: number;
    nb_hard_exercices: number;
    nb_medium_exercices: number;
    nb_easy_exercices: number;
    duration: number;
    is_validate_by_student: boolean;
    is_todo_by_student: boolean;
    pdf: File;
    published_at: Date;
    updated_at: Date;
    created_at: Date;
}
