<!-- Title -->
<div translate class="page-title mobile-nodisplay">{{translatePath}}.account-title</div>
<div class="myaccount-wrapper">
  <ng-container *ngIf="myTeacher; else elseTemplate">
    <div translate class="mobile-page-title">{{translatePath}}.account-title</div>
    <div class="publicprofile-btn" (click)="showModalTeacherProfil()">
      <b translate>{{translatePath}}.public-profil-btn</b>
    </div>

    <!-- General Information -->
    <div class="generalinfo-wrapper">
        <div class="page-subtitle" translate>{{translatePath}}.general-information-lbl</div>
        <div class="generalinfo-subwrapper">
          <div class="teacher-picblock">
            <div class="teacher-pic">
              <div class="avatar-inset-shadow"></div>
              <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="myUser.user.photo_profile === null">
              <img class="user-avatar" src="{{myUser.user.photo_profile.thumbnail}}" *ngIf="myUser.user.photo_profile !== null">
            </div>
            <div class="changepic-btn">
              <span #upload (click)="file.click()" translate>Edit</span>
              <input #file type="file" (change)="uploadAvatar($event)" [hidden]="true" />
            </div>
          </div>
          <div class="teacher-nameblock">
            <div class="teacher-name">
              {{ myUser.user.first_name }}
              {{ myUser.user.last_name }}
            </div>
            <div class="mailinput-wrapper">
              <input class="mail-input" type="text" [(ngModel)]="myUser.user.email" (focusout)="updateUser('email')">
            </div>
          </div>
        </div>
        <div class="skypeinput-wrapper">
          <span translate>{{translatePath}}.perso-skype-lbl</span>: {{ myUser.user.id_skype }}
        </div>
        <app-timezone-autocomplete [myUser]="myUser.user"></app-timezone-autocomplete>
    </div>
    <!-- Professionnal Info -->

    <div class="proinfo-wrapper">
      <div class="section-header" (click)="showProfessional = !showProfessional">
        <b class="page-subtitle" translate>{{translatePath}}.professional-info-lbl</b>
        <i *ngIf="!showProfessional" class="toggle-icon material-icons">arrow_drop_down</i>
        <i *ngIf="showProfessional" class="toggle-icon material-icons">arrow_drop_up</i>
      </div>

      <app-pro-info *ngIf="showProfessional" [myTeacher]="myTeacher"></app-pro-info>
    </div>
    <!-- Student Comments -->
    <div class="studcom-wrapper">
      <div class="section-header" (click)="showReport = !showReport">
        <b class="page-subtitle">
          Latest Student Feedback
        </b>
        <i *ngIf="!showReport" class="toggle-icon material-icons">arrow_drop_down</i>
        <i *ngIf="showReport" class="toggle-icon material-icons">arrow_drop_up</i>
      </div>
      <app-student-feedback *ngIf="showReport" [teacher]="myTeacher"></app-student-feedback>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="row center loading pageload-message">
      <b translate>{{translatePath}}.information-loading</b>
    </div>
  </ng-template>
</div>
<app-modal [componentToLoad]="componentToLoad" [modalParams]="modalParams"></app-modal>