import { Formation } from './../../../../../../model/formation.model';
import { ExerciceSheetService } from './../../../../../../service/exerciceSheet.service';
import { RateStudentService } from './../../../../../../service/rate-student.service';
import { SessionService } from './../../../../../../service/session.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Occurrence } from './../../../../../../model/occurrence.model';
import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-edit-report-progression',
  templateUrl: './edit-report-progression.component.html',
  styleUrls: ['./edit-report-progression.component.scss', '../progression.ux.scss', '../../formation.ux.scss', '../../../mainscreen.ux.scss']
})
export class EditReportProgressionComponent implements OnInit, OnChanges {
  @Input() occurrence: Occurrence;
  @Input() formation: Formation;
  @Output() updateOccurence = new EventEmitter();

  cecrl_LEVEL = []
  percentList = [0, 25, 50, 75, 100]
  loadedSession = false;
  loadedRating = false;

  grammarLevelCtrl = new FormControl('', [Validators.required]);
  grammarPercentCtrl = new FormControl('', [Validators.required]);
  expressionLevelCtrl = new FormControl('', [Validators.required]);
  expressionPercentCtrl = new FormControl('', [Validators.required]);
  comprehensionLevelCtrl = new FormControl('', [Validators.required]);
  comprehensionPercentCtrl = new FormControl('', [Validators.required]);
  vocabularyLevelCtrl = new FormControl('', [Validators.required]);
  vocabularyPercentCtrl = new FormControl('', [Validators.required]);
  accentLevelCtrl = new FormControl('', [Validators.required]);
  accentPercentCtrl = new FormControl('', [Validators.required]);
  oralLevelCtrl = new FormControl('', [Validators.required]);
  writtenLevelCtrl = new FormControl('', [Validators.required]);

  reportForm: FormGroup = this.builder.group({
    grammarLevel: this.grammarLevelCtrl,
    grammarPercent: this.grammarPercentCtrl,
    expressionLevel: this.expressionLevelCtrl,
    expressionPercent: this.expressionPercentCtrl,
    comprehensionLevel: this.comprehensionLevelCtrl,
    comprehensionPercent: this.comprehensionPercentCtrl,
    vocabularyLevel: this.vocabularyLevelCtrl,
    vocabularyPercent: this.vocabularyPercentCtrl,
    accentLevel: this.accentLevelCtrl,
    accentPercent: this.accentPercentCtrl,
    oralLevel: this.oralLevelCtrl,
    writtenLevel: this.writtenLevelCtrl,
  });

  constructor(
    private builder: FormBuilder,
    private sessionService: SessionService,
    private ratingStudentService: RateStudentService,
    private exerciceSheetService: ExerciceSheetService,

  ) { }

  ngOnInit() {
    this.cecrl_LEVEL = this.exerciceSheetService.getcecrlLevel()
  }


  ngOnChanges() {
    if (this.occurrence) {
      this.getOrCreateSession();
    }
  }

  getOrCreateSession() {
    if (!this.occurrence.session) {
      const body = {
        formation: this.formation.url,
        teacher: this.occurrence.teacher.url,
        occurrence: this.occurrence.url
      }
      // on crée la session manquante
      this.sessionService.postNewSession(this.formation.url, body)
        .subscribe(session => {
          this.occurrence.session = session;
          this.updateParentOccurence();
          this.loadedSession = true;
        })
    } else {
      this.loadReportData();
    }
  }

  normalizePercentValue(value) {
    const _percentList = this.percentList.map(x => x); // clone percent list
    _percentList.sort(function (a, b) {
      return b - a;
    });
    let percentVal = value;
    _percentList.forEach(percent => {
      if (value <= percent) {
        percentVal = percent;
      }
    });
    return percentVal;
  }

  loadReportData() {
    if (this.occurrence.session.rate_teacher) {
      this.grammarLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_grammar);
      this.grammarPercentCtrl.setValue(this.normalizePercentValue(this.occurrence.session.rate_teacher.grammar));
      this.expressionLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_expression);
      this.expressionPercentCtrl.setValue(this.normalizePercentValue(this.occurrence.session.rate_teacher.expression));
      this.comprehensionLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_comprehension);
      this.comprehensionPercentCtrl.setValue(this.normalizePercentValue(this.occurrence.session.rate_teacher.comprehension));
      this.vocabularyLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_vocabulary);
      this.vocabularyPercentCtrl.setValue(this.normalizePercentValue(this.occurrence.session.rate_teacher.vocabulary));
      this.accentLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_accent);
      this.accentPercentCtrl.setValue(this.normalizePercentValue(this.occurrence.session.rate_teacher.accent));
      this.oralLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_oral);
      this.writtenLevelCtrl.setValue(this.occurrence.session.rate_teacher.level_written);
      this.loadedRating = true;
    }
  }

  postRating() {
    const _param = {
      session: this.occurrence.session.url,
      level_grammar: this.grammarLevelCtrl.value,
      grammar: this.grammarPercentCtrl.value,
      level_expression: this.expressionLevelCtrl.value,
      expression: this.expressionPercentCtrl.value,
      level_comprehension: this.comprehensionLevelCtrl.value,
      comprehension: this.comprehensionPercentCtrl.value,
      level_vocabulary: this.vocabularyLevelCtrl.value,
      vocabulary: this.vocabularyPercentCtrl.value,
      level_accent: this.accentLevelCtrl.value,
      accent: this.accentPercentCtrl.value,
      level_oral: this.oralLevelCtrl.value,
      level_written: this.writtenLevelCtrl.value,
    };
    if (!this.occurrence.session.rate_teacher) {
      this.ratingStudentService.postRatingStudent(_param)
        .subscribe(rate_teacher => {
          this.occurrence.session.rate_teacher = rate_teacher;
          this.sessionService.toastService.show('Report completed !', 4000, 'green');
          this.updateParentOccurence();
        })
    } else {
      this.ratingStudentService.patchRatingStudent(this.occurrence.session.rate_teacher.url, _param)
        .then(rate_teacher => {
          this.occurrence.session.rate_teacher = rate_teacher;
          this.sessionService.toastService.show('Report completed !', 4000, 'green');
          this.updateParentOccurence();
        })
    }
  }

  updateParentOccurence() {
    this.updateOccurence.emit(this.occurrence);
  }



}
