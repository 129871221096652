import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Event } from '../../model/event.model';

@Component({
  selector: 'app-booking-time-row',
  templateUrl: './booking-time-row.component.html',
  styleUrls: ['./booking-time-row.component.scss']
})
export class BookingTimeRowComponent implements OnChanges, OnInit {
  @Input() availableDay;
  @Input() dateSelect;
  @Input() booking;
  @Input() formation;
  @Output() schedulesSelectedOutput = new EventEmitter();
  @Output() addEventOutput = new EventEmitter();
  @Output() updateEventOutput = new EventEmitter();
  @Output() reinitsEventsOutput = new EventEmitter();
  rows: any[];
  occurrences;
  my_event = null;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dateSelect']) {
      this.displayAvailablesRows();
    }
    if (changes.dateSelect && changes.dateSelect.currentValue) {
      this.initPonctualReservation();
    }
  }

  displayAvailablesRows() {
    this.rows = [];
    this.createTimePlanner();
    this.setAvailablesRows();
  }

  createTimePlanner() {
    const _myTime = moment();
    for (let _i = 0; _i < 25; _i++) {
      if (_i < 24) {
        const _myTime1 = moment({ years: _myTime.year(), months: _myTime.month(), date: _myTime.date(), hours: _i, minutes: 0, seconds: 0, milliseconds: 0 });
        const _row1 = { open: false, select: false, time: _myTime1, label: '' };
        this.rows.push(_row1);
        const _myTime2 = moment({ years: _myTime.year(), months: _myTime.month(), date: _myTime.date(), hours: _i, minutes: 30, seconds: 0, milliseconds: 0 });
        const _row2 = { open: false, select: false, time: _myTime2, label: '' };
        this.rows.push(_row2);
      } else {
        const _myTime3 = moment({ years: _myTime.year(), months: _myTime.month(), date: _myTime.date(), hours: _i, minutes: 0, seconds: 0, milliseconds: 0 });
        const _row3 = { open: false, select: false, time: _myTime3, label: '' };
        this.rows.push(_row3);
      }
    }
  }

  setAvailablesRows() {
    if (this.availableDay) {
      for (let _j = 0; _j < this.availableDay['dispo'].length; _j++) {
        const ms = moment(moment(this.availableDay['dispo'][_j]['end']), 'HH:mm').diff(moment(moment(this.availableDay['dispo'][_j]['start']), 'HH:mm'));
        if (moment.duration(ms).asMinutes() >= 30) {
          if (moment(this.availableDay['dispo'][_j]['start']).day() === moment(this.availableDay['day']).day()) {
            const myAvailableStart = moment({
              years: moment().year(), months: moment().month(), date: moment().date(),
              hours: moment(this.availableDay['dispo'][_j]['start']).hour(),
              minutes: moment(this.availableDay['dispo'][_j]['start']).minute(),
              seconds: 0, milliseconds: 0
            });
            let myAvailableEnd = moment({
              years: moment().year(), months: moment().month(), date: moment().date(),
              hours: moment(this.availableDay['dispo'][_j]['end']).hour(),
              minutes: moment(this.availableDay['dispo'][_j]['end']).minute(),
              seconds: 0, milliseconds: 0
            });
            if (myAvailableEnd.format('HH:mm') === '00:00') {
              myAvailableEnd = moment({
                years: moment().year(), months: moment().month(), date: moment().date(),
                hours: 23, minutes: 59,
                seconds: 59, milliseconds: 59
              });
            }
            let isFirst = true;
            for (let _k = 0; _k < this.rows.length; _k++) {
              const _row = this.rows[_k];
              if (this.isBetween(myAvailableStart, myAvailableEnd, _row.time)) {
                _row.open = true;
                if (isFirst) {
                  if (!_row.select) {
                    _row.label = this.setLabel('Disponible de ', myAvailableStart, myAvailableEnd);
                  }
                }
                isFirst = false;
              }
            }
          }
        }
      }
    }
  }

  initPonctualReservation() {
    for (let _k = 0; _k < this.rows.length; _k++) {
      const _row = this.rows[_k];
      if (this.booking.start && this.booking.end) {
        if (this.isBetween(this.booking.start.datetime, this.booking.end.datetime, _row.time)) {
          _row.select = true;
        }
      }
    }
    const _result = this.rows.filter(res => res.select === true);
    if (_result.length > 0) {
      const _row = _result[0];
      const idx = this.rows.indexOf(_result[0]);
      _row.select = true;
      const _myStart = moment(
        {
          years: moment(this.dateSelect).year(),
          months: moment(this.dateSelect).month(),
          date: moment(this.dateSelect).date(),
          hours: _row.time.local().hour(),
          minutes: _row.time.local().minute(),
          seconds: 0,
          milliseconds: 0
        });

      const _myEnd = moment(
        {
          years: moment(this.dateSelect).year(),
          months: moment(this.dateSelect).month(),
          date: moment(this.dateSelect).date(),
          hours: this.booking.end.datetime.local().hour(),
          minutes: this.booking.end.datetime.local().minute(),
          seconds: 0,
          milliseconds: 0
        });

      if (_myEnd.format('HH:mm') === '00:00') {
        _myEnd.set({ 'hours': 23, 'minute': 59, 'second': 59 })
      }
      this.my_event = this.setNewEvent(_myStart, _myEnd);
      _row.label = this.setLabel('Sélectionné de ', this.my_event.start, this.my_event.end);
      this.addEventOutput.emit(this.my_event);
    }
  }

  selectRow(_row, idx) {
    if (!_row.select) {
      if (this.formation.hours_remaining > 0) {
        // _row.select = true;
        this.addSelection(_row, idx);
      } else {
        _row.select = false;
      }
    } else {
      _row.select = false;
      this.removeSelection(_row, idx);
    }

  }

  addSelection(_row, idx) {
    const isAfter = this.rows[idx - 1].select;
    const isBefore = this.rows[idx + 1].select;
    const _myEnd = moment(
      {
        years: moment(this.dateSelect).year(),
        months: moment(this.dateSelect).month(),
        date: moment(this.dateSelect).date(),
        hours: this.rows[idx + 1].time.local().hour(),
        minutes: this.rows[idx + 1].time.local().minute(),
        seconds: 0,
        milliseconds: 0
      });
    if (_myEnd.format('HH:mm') === '00:00') {
      _myEnd.set({ 'hours': 23, 'minute': 59, 'second': 59 })
    }
    const _myStart = moment(
      {
        years: moment(this.dateSelect).year(),
        months: moment(this.dateSelect).month(),
        date: moment(this.dateSelect).date(),
        hours: _row.time.local().hour(),
        minutes: _row.time.local().minute(),
        seconds: 0,
        milliseconds: 0
      });
    if (isAfter && !isBefore) {
      _row.select = true;
      this.my_event.end = _myEnd;
      this.rows.filter(res => res.select === true)[0].label = this.setLabel('Sélectionné de ', this.my_event.start, this.my_event.end);
      this.updateEventOutput.emit(this.my_event);
      this.schedulesSelectedOutput.emit(this.my_event);
    }
    if (isBefore && !isAfter) {
      _row.select = true
      this.rows[idx + 1].label = '';
      this.my_event.start = _myStart;
      _row.label = this.setLabel('Sélectionné de ', this.my_event.start, this.my_event.end);
      this.updateEventOutput.emit(this.my_event);
      this.schedulesSelectedOutput.emit(this.my_event);
    }
    if (!isAfter && !isBefore) {
      if (!this.rows.filter(res => res.select)[0]) {
        _row.select = true;
        this.my_event = this.setNewEvent(_myStart, _myEnd);
        _row.label = this.setLabel('Sélectionné de ', this.my_event.start, this.my_event.end);
        this.addEventOutput.emit(this.my_event);
        this.schedulesSelectedOutput.emit(this.my_event);
      } else {
        _row.select = false;
      }
    }
  }

  removeSelection(_row, idx) {
    const isAfter = this.rows[idx - 1].select;
    const isBefore = this.rows[idx + 1].select;
    if (isAfter && !isBefore) {
      const _myEnd = moment(
        {
          years: moment(this.dateSelect).year(),
          months: moment(this.dateSelect).month(),
          date: moment(this.dateSelect).date(),
          hours: _row.time.local().hour(),
          minutes: _row.time.local().minute(),
          seconds: 0,
          milliseconds: 0
        });
      this.my_event.end = _myEnd;
      // this.my_event.end = _row.time.local();
      this.rows.filter(res => res.select === true)[0].label = this.setLabel('Sélectionné de ', this.my_event.start, this.my_event.end);
      this.updateEventOutput.emit(this.my_event);
      this.schedulesSelectedOutput.emit(this.my_event);
    }
    if (isBefore && !isAfter) {
      _row.label = '';
      const _myStart = moment(
        {
          years: moment(this.dateSelect).year(),
          months: moment(this.dateSelect).month(),
          date: moment(this.dateSelect).date(),
          hours: this.rows.filter(res => res.select === true)[0].time.local().hour(),
          minutes: this.rows.filter(res => res.select === true)[0].time.local().minute(),
          seconds: 0,
          milliseconds: 0
        });
      this.my_event.start = _myStart;
      this.rows.filter(res => res.select === true)[0].label = this.setLabel('Sélectionné de ', this.my_event.start, this.my_event.end);
      this.updateEventOutput.emit(this.my_event);
      this.schedulesSelectedOutput.emit(this.my_event);
    }
    if (!isAfter && !isBefore) {
      _row.label = '';
      this.reinitsEventsOutput.emit();
      this.schedulesSelectedOutput.emit(this.my_event);
    }
    // is c'est la premiere ligne des dispos, on remet le label
    this.setAvailablesRows();
  }

  setNewEvent(_start, _end) {
    const newEvent = new Event();
    newEvent.rule = null;
    newEvent.calendar = this.formation.calendar;
    newEvent.teacher = this.booking.teacher.url;
    newEvent.title = 'ponctual booking';

    // set time from row
    newEvent.start = _start;
    newEvent.end = _end;
    return newEvent
  }

  setLabel(_text, _start, _end) {
    return _text +
      moment(_start).format('HH') + 'h' + moment(_start).format('mm') + ' à '
      + moment(_end).format('HH') + 'h' + moment(_end).format('mm');
  }

  isBetween(a, b, value) {
    if (a <= value && value < b) {
      return true;
    }
    return false;
  }

}
