<div id="sheet-editor">
<div class="row">
  <app-manage-exercices-medias [mediaType]="'exercicesheet'" [object]="exerciceSheet"></app-manage-exercices-medias>
</div>

<div class="row">
  <div >
    <ckeditor class="main-editor" [editor]="Editor" [(ngModel)]="exerciceSheet.content" [config]="ckEditorConfig" (ready)="onReady($event)" (focusout)="updateWysywigSheet('content')"></ckeditor>

  </div>
</div>
<div class="row">
  <div class="tipsblock-wrap">
    <b>Bloc "À Retenir"</b>
    <mat-checkbox [formControl]="show_to_remember">Afficher dans la fiche</mat-checkbox>
    <ckeditor class="tips-editor" [editor]="Editor" [config]="ckEditorConfig" [(ngModel)]="exerciceSheet.to_remember" (ready)="onReady($event)" (focusout)="updateWysywigSheet('to_remember')"></ckeditor>
  </div>
</div>
</div>