/**
 * Etape 2 plan-formation : définition du level.
 */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainscreenGlobals } from '../../../../kiwixi/mainscreen.globals';
import { Formation } from '../../../../model/formation.model';
import { FormationService } from '../../../../service/formation.service';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss', '../plan-formation.component.scss']
})
export class LevelComponent implements OnInit {

  newFormation: Formation;
  create_formation_level: string;
  current_formation_id: any;
  levelSelected: Object;
  step_button: string;
  translatePath = 'lingueopro.plan-formation.level';
  levels = [
    { label: 'Grand débutant', value: '1' },
    { label: 'Débutant', value: '2' },
    { label: 'Intermédiaire', value: '3' },
    { label: 'Avancé', value: '4' },
    { label: 'Maitrise', value: '5' },
    { label: 'Je ne sais pas', value: '0' }
  ]

  constructor(
    private router: Router,
    private formationService: FormationService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.current_formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    // On récupère la formation à editer.
    this.formationService.getObservableFormationById(this.current_formation_id)
      .subscribe(_formation => {
        this.newFormation = _formation;
      })
  }

  // page suivante : schedule
  next() {
    this.router.navigate(['plan-formation/schedule', this.current_formation_id]);
  }
}
