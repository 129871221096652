<div *ngIf="!showTable">
  Chargement du tableau...
</div>
<div class="row">
  <div class="left"><span class="total-results">Total : {{ totalData }}</span> - <button class="clear-btn" (click)="clearFilterOrder()"> Vider les filtres </button></div>
</div>
<div *ngIf="showTable">
  <div class="search-results">

    <table class="responsive-table bordered card datatable" [ngClass]="cssclass">
      <thead class="teal lighten-5">
        <tr class="fields">
          <th class="input-head" id="col-{{column.name}}" *ngFor="let column of header ; let index=index" (click)="ordering(column)"
            style="cursor:pointer;">
            {{column.title}}
          </th>
        </tr>
        <tr>
          <th id="input-{{column.name}}" *ngFor="let column of header ; let in = index">
            <div *ngIf="column.filter_type == 'select'">
              <mat-select [(ngModel)]="column.filter" (ngModelChange)="filterData(column.filter, column)">
                <mat-option *ngFor="let item of column.list" [value]="item.code" [disabled]='!item.valid' class="left circle grey-text ">
                  {{ item.label }}
                </mat-option>
              </mat-select>
            </div>
            <div class="" *ngIf="column.filter_type == 'interval'">
              <input class="col l5 filter-col-alternative left" name="filter-min-{{in}}" [(ngModel)]="column.filter_min" (keyup)="debounceInput(column.filter_min, column)">
              <input class="col l5 push-l1 filter-col-alternative left" name="filter-max-{{in}}" [(ngModel)]="column.filter_max" (keyup)="debounceInput(column.filter_max, column)">
            </div>
            <input *ngIf="column.filter_type == 'text'" class="col l10 filter-col left" name="filter-{{in}}" [(ngModel)]="column.filter"
              (keyup)="debounceInput(column.filter, column)">
            <input *ngIf="column.filter_type == 'calendar'" [matDatepicker]="pickerStart" (click)="pickerStart.open()" [(ngModel)]="column.filter"
              class="col l10 filter-col left" (ngModelChange)="filterData(column.filter, column)">
            <mat-datepicker #pickerStart [startAt]="startDate"></mat-datepicker>

            <div class="row" *ngIf="column.filter_type == 'calendar-month'" class="calendar-month-position">
              <div class="col l5 month-filter" >
                <mat-select [(ngModel)]="myMonth" (ngModelChange)="filterPeriod(column, myMonth, myYear)">
                  <mat-option *ngFor="let month of months" [value]="month.month" class="left circle grey-text ">
                    {{ month.prefix }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col l5 year-filter">
                <mat-select [(ngModel)]="myYear" (ngModelChange)="filterPeriod(column, myMonth, myYear)">
                  <mat-option *ngFor="let year of years" [value]="year" class="left circle grey-text ">
                    {{ year }}
                  </mat-option>
                </mat-select>
              </div>
            </div>

          </th>
        </tr>
      </thead>

      <div class="scroll-bloc"
            infiniteScroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollThrottle]="throttle"
            [scrollWindow]="false"
            (scrolled)="onScrollDown()">
        <tbody>
          <tr *ngFor="let row of data ; let inTr = index" [ngClass]="setDemarcation(row)" (click)="goto(row)">
            <td id="cell-{{column.name}}" *ngFor="let column of header ; let inTd = index" [style.width]="setCellWidth(column.name)"
              [style.min-width]="setCellWidth(column.name)">

              <!-- string column -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'string' && !column.cell_edit">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptyString" class="truncate" translate>
                  {{row[column.name]}}
                </div>
                <ng-template #emptyString>
                  <div>--</div>
                </ng-template>
              </div>

              <!-- status_string column -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'status_string' && !column.cell_edit">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptyString" class="truncate" mz-tooltip [tooltip]="row[column.name]"
                  [position]="'bottom'" translate>
                  {{row[column.name]}}
                </div>
                <ng-template #emptyString>
                  <div>--</div>
                </ng-template>
              </div>

              <!-- email column -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'email'">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptyEmail">
                  {{row[column.name]}}
                </div>
                <ng-template #emptyEmail>
                  <div>--</div>
                </ng-template>
              </div>

              <!-- split column -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'split' && row[column.name];">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptySplit">
                  <div class="col l4" *ngIf="row[column.name].avatar !== null">
                    <img class="circle responsive-img img-size" src='{{row[column.name].avatar}}'>
                  </div>
                  <div class="col l4" *ngIf="row[column.name].avatar === null">
                    <img class="circle responsive-img img-size" src='{{defaultAvatar}}'>
                  </div>
                  <div class="col l8 truncate" *ngIf="row[column.name].label !== null">{{row[column.name].label}}</div>
                  <div *ngIf="row[column.name].label === null">---</div>
                </div>
                <ng-template #emptySplit>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- Student column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'student'">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptyStudent">
                  <app-datatable-cell-student [student]="row[column.name]">
                  </app-datatable-cell-student>
                </div>
                <ng-template #emptyStudent>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- User column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'user' && row[column.name]; else empty">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptyUser">
                  <app-datatable-cell-user [user]="row[column.name]">
                  </app-datatable-cell-user>
                </div>
                <ng-template #emptyUser>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- Site column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'site'">
                <div *ngIf="row[column.name] && row[column.name] !== null; else emptySite">
                  <app-datatable-cell-site [site]="row[column.name]">
                  </app-datatable-cell-site>
                </div>
                <ng-template #emptySite>
                  <div>---</div>
                </ng-template>
              </div>
              <!-- Sites column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'sites'; else empty">
                <div *ngIf="row[column.name] && row[column.name].length > 0; else emptySites">
                  <app-datatable-cell-sites [sites]="row[column.name]">
                  </app-datatable-cell-sites>
                </div>
                <ng-template #emptySites>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- Student_email column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'student_email'">
                <div *ngIf="row[column.name] && row[column.name]!== null; else emptyStudentEmail">
                  <app-datatable-cell-student-email [student]="row[column.name]">
                  </app-datatable-cell-student-email>
                </div>
                <ng-template #emptyStudentEmail>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- Formation column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'formation'">
                <div *ngIf="row[column.name] && row[column.name]!== null; else emptyFormation">
                  <app-datatable-cell-formation [formation]="row[column.name]">
                  </app-datatable-cell-formation>
                </div>
                <ng-template #emptyFormation>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- next_occurrence column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'next_occurrence'">
                <div *ngIf="row[column.name] && row[column.name]!== null; else emptyNextOccurrence">
                  <app-datatable-cell-next-occurrence [occurrence]="row[column.name]">
                  </app-datatable-cell-next-occurrence>
                </div>
                <ng-template #emptyNextOccurrence>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- Teacher column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'teacher'">
                <div *ngIf="row[column.name] && row[column.name]!== null; else emptyTeacher">
                  <app-datatable-cell-teacher [teacher]="row[column.name]">
                  </app-datatable-cell-teacher>
                </div>
                <ng-template #emptyTeacher>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- teachers column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'teachers'">
                <div *ngIf="row[column.name] && row[column.name].length > 0; else emptyTeachers">
                  <app-datatable-cell-teachers [teachers]="row[column.name]">
                  </app-datatable-cell-teachers>
                </div>
                <ng-template #emptyTeachers>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- date-range column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'date_range'">
                <div *ngIf="row[column.name] && row[column.name]!== null; else emptyDateRange">
                  <app-datatable-cell-date-range [date1]="row['validity_period_from']" [date2]="row['validity_period_to']">
                  </app-datatable-cell-date-range>
                </div>
                <ng-template #emptyDateRange>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- language column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'language'">
                <div *ngIf="row[column.name] && row[column.name]!== null; else emptyLanguage">
                  <app-datatable-cell-language [language]="row[column.name]">
                  </app-datatable-cell-language>
                </div>
                <ng-template #emptyLanguage>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- date-range column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'languages' && row[column.name]; else empty">
                <div *ngIf="row[column.name] && row[column.name].length > 0; else emptyLanguages">
                  <app-datatable-cell-languages [languages]="row[column.name]">
                  </app-datatable-cell-languages>
                </div>
                <ng-template #emptyLanguages>
                  <div>---</div>
                </ng-template>
              </div>

              <!-- date-range column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'hour' && row[column.name]; else empty">
                {{row[column.name] | amDateFormat:'HH:mm'}}
                <br /> {{row['enddate'] | amDateFormat:'HH:mm'}}
              </div>

              <!-- uploaded_at column type -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'uploaded_at'; else empty">
                <ng-container *ngIf="row[column.name] && row[column.name].length > 0; else emptyUploaded">
                  {{row[column.name] | amDateFormat:'DD MMM YYYY'}}
                </ng-container>
                <ng-template #emptyUploaded>
                  <div>---</div>
                </ng-template>
              </div>


              <!-- splitList column -->
              <div class="truncate identity" [style.width]="setCellMaxWidth(column.name)" *ngIf="column.type === 'splitList' && row[column.name]; else emptySplitList">
                <div class="row" *ngFor="let item of row[column.name]">
                  <div class="col l4" *ngIf="item.avatar !== null">
                    <img class="circle responsive-img img-size" src='{{item.avatar}}'>
                  </div>
                  <div class="col l4" *ngIf="item.avatar === null">
                    <img class="circle responsive-img img-size" src='{{defaultAvatar}}'>
                  </div>
                  <div class="col l8 truncate">{{item.label}}</div>
                </div>
                <!--  Empty split -->
                <div *ngIf="!row[column.name]?.length">
                  Pas d'item.
                </div>
              </div>

              <!-- Next session column -->
              <div *ngIf="row[column.name]">
                <div *ngIf="column.name === 'nextSession' && row[column.name] !== null" [style.width]="setCellMaxWidth(column.name)">
                  {{row[column.name].start | amDateFormat:'DD MMM YYYY'}}
                  <br> {{row[column.name].start | amDateFormat:'hh:mm'}} - {{row[column.name].end | amDateFormat:'hh:mm'}}
                </div>
              </div>

              <!-- calendar column -->
              <div *ngIf="column.type === 'calendar' && column.filter_type === 'calendar'" [innerHTML]="row[column.name]" [style.width]="setCellMaxWidth(column.name)">
              </div>

              <!-- link column -->
              <a *ngIf="column.type ==='link' && row[column.name] !== null " href="{{row[column.name].domaine}}" target="_blank" [style.width]="setCellMaxWidth(column.name)"
                class="truncate">
                {{row[column.name].name}}
              </a>

              <!-- session status column -->
              <div class="status-col" *ngIf="column.cell_edit" [style.width]="setCellMaxWidth(column.name)">
                <b *ngIf="row[column.name] && row[column.name] !==null" mz-tooltip [tooltip]="row[column.name].label" [position]="'bottom'"
                  [hidden]="row[column.isEdit]" class="col l9 truncate">{{row[column.name].label}}</b>
                <i *ngIf="!row[column.isEdit]" class="waves-effect" (click)="row[column.isEdit] ? row[column.isEdit] = false : row[column.isEdit] = true;"
                  mz-icon [icon]="'edit'" [size]="'tiny'"></i>

                <mat-select *ngIf="row[column.isEdit]" class="input-status col l8" [(ngModel)]="row[column.name]" (ngModelChange)="handleSelectStatus(row)">
                  <mat-option *ngFor="let status of column.list" [value]="status" [disabled]='!status.valid'>
                    {{ status.label }}
                  </mat-option>
                </mat-select>

                <i class="waves-effect hide-edit-status" *ngIf="row[column.isEdit]" (click)="row[column.isEdit] ? row[column.isEdit] = false : row[column.isEdit] = true;"
                  mz-icon [icon]="'cancel'" [align]="'right'" [size]="'tiny'">
                </i>
              </div>

            </td>
          </tr>
        </tbody>
      </div>
    </table>
  </div>
</div>