import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
// from module
import { UnavailabilityComponent } from './unavailability/unavailability.component';
import { ScheduleComponent } from './schedule.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';
import { PonctualAvailibilityComponent } from './ponctual-availibility/ponctual-availibility.component';
import { RecurringAvailibilityComponent } from './recurring-availibility/recurring-availibility.component';
import { ScheduleGuard } from '../../../guard/schedule.guard';

export const routes: Routes = [
    {
        path: 'schedule',
        component: ScheduleComponent,
        canActivate: [MaintenanceGuard],
        children: [
            { path: '', component: ScheduleComponent, canActivate: [ScheduleGuard] },
            { path: 'ponctual', component: PonctualAvailibilityComponent, canActivate: [AuthGuard] },
            { path: 'availability', component: RecurringAvailibilityComponent, canActivate: [AuthGuard] },
            { path: 'unavailability', component: UnavailabilityComponent, canActivate: [AuthGuard] },
        ]
    },
]


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class ScheduleRoutingModule { }
 