import { Injectable } from '@angular/core';
import { RatingTeacher } from '../model/rating-teacher.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable()
export class RatingTeachersService extends KiwixiService {

  apiRatingTeachers = environment.server + 'rating_teachers/';

  constructor(private _http: HttpClient, private _router: Router, kiwixiGlobals: KiwixiGlobals, public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getStatus() {
    return [
      {
        label: 'Activé',
        code: 'active',
      },
      {
        label: 'Désactivé',
        code: 'disabled',
      }]
  }


  getRatingTeachers(_filterParams): Promise<RatingTeacher[]> {
    let params = new HttpParams();
    for (const filterParam of _filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    this.checkCredential();
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiRatingTeachers, { headers: headers, params: params })
      .toPromise()
      .then(
        res => {
          const body = res;
          return body;
        }
      )
      .catch(this.handleError);
  }

  postRatingTeacher(ratingTeacher): Promise<RatingTeacher> {
    const body = ratingTeacher;
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.post(this.apiRatingTeachers, body, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
        console.error('error postRatingTeacher service', error);
      })
  }

  patchRatingTeacher(ratingTeacherUrl, param): Promise<RatingTeacher> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(ratingTeacherUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
        console.error('error patch RatingTeacher service', error);
      })
  }

  getObservableRatingTeachers(params: HttpParams): Observable<RatingTeacher[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<RatingTeacher[]>(this.apiRatingTeachers, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<RatingTeacher[]>(`getRatingTeachers`))
      );
  }

  getObservableRatingTeacher(id): Observable<RatingTeacher> {
    const header = this.createHttpClientAuthorizationHeader();
    const _urlRatingTeacher = this.apiRatingTeachers + id + '/';
    return this._http.get<RatingTeacher>(_urlRatingTeacher, { headers: header }).
      pipe(
        catchError(this.handleErrorObservable<RatingTeacher>(`getRatingTeacher`))
      );
  }

  patchObservableRatingTeacher(ratingTeacherUrl, params): Observable<RatingTeacher> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch<RatingTeacher>(ratingTeacherUrl, params, { headers: headers }).
      pipe(
        catchError(this.handleErrorObservable<RatingTeacher>(`getRatingTeacher`))
      );
  }
}
