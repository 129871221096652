import { UserService } from './../../../../service/user.service';
import { AuthenticationService } from './../../../../service/authentification.service';
import { FormControl, Validators } from '@angular/forms';
import { InitAppService } from './../../../../service/initapp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormationService } from './../../../../service/formation.service';
import { Formation } from './../../../../model/formation.model';
import { Component, OnInit } from '@angular/core';
import { Student } from '../../../../model/student.model';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: [
    '../schedule/schedule.component.scss',
    '../plan-formation.component.scss',
    '../schedule/schedule.ux.scss',
    '../plan-formation.ux.scss']
})
export class InformationComponent implements OnInit {
  formation_id;
  formation: Formation
  student: Student = null;
  showScreen = false;
  skypeFormControl = new FormControl('', [
    Validators.required, this.noWhitespaceValidator
  ]);
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initAppService: InitAppService,
    private userService: UserService,
    private formationService: FormationService,
  ) { }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit() {
    this.formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, this.formation_id);
    this.authenticationService.currentUserSubject.subscribe(student => {
      this.student = student;
      this.skypeFormControl.setValue(this.student.user.id_skype)
      this.formationService.currentFormationSubject.subscribe(formation => {
        if (formation) {
          this.formation = formation;
          this.showScreen = true;
        }
      });
    });
    this.skypeFormControl.valueChanges.debounceTime(500).subscribe((value: string) => {
      value = value.trim();
      if (value) {
        const _paramsUser = {
          id_skype: value,
        }
        this.userService.patchObservableUser(this.student.user.url, _paramsUser).subscribe(res => {
          this.student.user = res;
          this.authenticationService.setLocalPersonaObj(this.student);
        })
      }
    })

  }

  goToIdentity() {
    this.router.navigate(['plan-formation/identity', this.formation_id]);
  }

  goToSchedule() {
    if (this.formation.booking_mode === 'rec') {
      this.router.navigate(['plan-formation/schedule', this.formation_id]);
    } else if (this.formation.booking_mode === 'ponctual') {
      this.router.navigate(['plan-formation/init-ponctual-booking', this.formation_id]);
    } else {
      this.router.navigate(['plan-formation/booking-mode', this.formation_id]);
    }
  }

  next() {
    if (!this.student.user.photo_profile && !this.student.user.photo_profile_validation_requested) {
      this.goToIdentity();
    } else {
      this.goToSchedule();
    }
  }



}
