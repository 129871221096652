<div class="pagecontent-flexwrap">
  <div class="content-main">
    <div class="sheet-viewer empty-viewer" *ngIf="!sheet?.id">
      <span translate>sheet-view.select_sheet_to_display</span>
    </div>
    <div class="sheet-viewer" *ngIf="sheet?.id">
      <div class="sheet-viewer-header" [ngClass]="{'sheet-validated': sheet.is_validate_by_student, 'sheet-todo':  sheet.is_todo_by_student, 'sheet-default': !sheet.is_validate_by_student && !sheet.is_todo_by_student }">
        <div class="header-constrain">
          
          <a class="sheet-return-btn" routerLink="../.."><span translate>common.back</span></a>
          <div class="sheet-pagename">Fiche d'apprentissage</div>
          <div class="sheet-title">{{ sheet.title_fr }}</div>
          <div class="sheet-tags-wrap">
            <div class="sheet-cat sheet-tag">{{ sheet.category.name }}</div>
            <div class="sheet-level sheet-tag">{{ sheet.cecrl_level }}</div>
            <div class="sheet-group sheet-tag" *ngFor='let group of sheet.specific_content'>{{ group.name }}</div>
            <div class="sheet-status sheet-tag"></div>
          </div>
        </div>
        <div class="header-status-sticker">
        </div>
        <a class="downloadpdf" href="{{sheet.pdf}}" *ngIf="sheet?.pdf" target="_blank"></a>
      </div>
      <div class="sheet-viewer-shortcuts" *ngIf="student">
        <a (click)="scrollToElement(explainblockmobile)" class="shortcut-explain">Explication</a>
        <a (click)="scrollToElement(exoblockmobile)" class="shortcut-exo">S'entrainer</a>
        <a (click)="scrollToElement(examblockmobile)" class="shortcut-exam" *ngIf="sheet.is_validate_by_student!=true">Valider la fiche</a>
      </div>
      <div class="sheet-viewer-body">
          <div id="explain-block-mobile" #explainblockmobile class="sheet-viewer-subblock">
            <div class="sheet-viewer-media" *ngIf="sheet.media?.media_type=='image'">
              <img class="sheet-viewer-img" src="{{ sheet.media.url }}" />
            </div>
            <div class="sheet-viewer-media" *ngIf="sheet.media?.media_type=='video'">
                <div class="sheet-viewer-vid" [innerHtml]="iframe_video"></div>
            </div>
            <div class="sheet-viewer-content" [innerHTML]="content">
            </div>
            <div class="sheet-viewer-tips-wrap" *ngIf="sheet.show_to_remember">
              <div class="sheet-viewer-tips-title"><span translate>sheet-view.to_remember</span></div>
              <div class="sheet-viewer-tips-content" [innerHTML]="to_remember"></div>
            </div> 
          </div>
          <div class="mobile-contents">
            <div id="related-block-mobile" class="sheet-viewer-subblock related-block" *ngIf="sheet.sheets?.length > 0">
              <feature-sheet-related [sheet]="sheet" (sheetToShow)="changeSheet($event)" ></feature-sheet-related>
            </div>
            <div id="exoblockmobile" #exoblockmobile> 
              <div id="exo-block-mobile" class="sheet-viewer-subblock exo-block" *ngIf="showExamination && examinationExercices.length>2 && sheet.is_validate_by_student!=true">  
                <app-sheet-practice  [easyExercice]="easyExercice" [mediumExercice]="mediumExercice" [hardExercice]="hardExercice"></app-sheet-practice>
              </div>
            </div>
            <div id="examblockmobile" #examblockmobile> 
              <div id="exam-block-mobile" class="sheet-viewer-subblock exam-block" *ngIf="showExercices">
                <app-validate-sheet  [examinationDuration]="examinationDuration" ></app-validate-sheet>
              </div>
            </div>
          </div>
      </div>
      
    </div>
  </div>
    <div class="content-side">
      <div id="exam-block" class="sheet-viewer-subblock exam-block" *ngIf="showExamination && examinationExercices.length>2 && sheet.is_validate_by_student!=true">  
        <app-sheet-practice  [easyExercice]="easyExercice" [mediumExercice]="mediumExercice" [hardExercice]="hardExercice"></app-sheet-practice>
      </div>

      <div id="exo-block" class="sheet-viewer-subblock exo-block" *ngIf="showExercices">
        <app-validate-sheet  [examinationDuration]="examinationDuration" ></app-validate-sheet>
      </div>

      <div id="related-block" class="sheet-viewer-subblock related-block" *ngIf="sheet?.sheets?.length > 0">
          <feature-sheet-related [sheet]="sheet" (sheetToShow)="changeSheet($event)" ></feature-sheet-related>
      </div> 
    </div>
</div>
