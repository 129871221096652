import { environment } from './../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormationService } from './../../../../../service/formation.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Occurrence } from '../../../../../model/occurrence.model';

@Component({
  selector: 'app-init-ponctual-booking-conclusion',
  templateUrl: './init-ponctual-booking-conclusion.component.html',
  styleUrls: ['./init-ponctual-booking-conclusion.component.scss']
})
export class InitPonctualBookingConclusionComponent implements OnInit {
  @Input() classCreated: Occurrence[] = [];
  @Input() mode_formation;
  @Output() showDemoEmiter = new EventEmitter();
  occurrence: Occurrence;
  formationId;
  showComponent = false;
  server = environment.base_server;

  constructor(
    private formationService: FormationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.classCreated.length > 0) {
      this.occurrence = this.classCreated[0];
      console.log(this.occurrence)
      this.formationId = this.activatedRoute.snapshot.paramMap.get('id');
      this.showComponent = true;
    }
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

  goToNextClasses() {
    this.router.navigate(['/formation/' + this.formationId])
  }

  emitShowDemo() {
    this.showDemoEmiter.emit();
  }

}
