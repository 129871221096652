import { FormationService } from './../service/formation.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/authentification.service';
import * as moment from 'moment';

@Injectable()
export class ProfileGuard implements CanActivate {
    environment

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private formationService: FormationService,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const dateProfileFeature = moment({ y: 2021, M: 3, d: 19, h: 0, m: 0, s: 0 });
        this.formationService.currentFormationSubject.subscribe(formation => {
            if (formation) {
                if (moment(formation.validity_period_from).isSameOrAfter(dateProfileFeature) && !formation.student.user.photo_profile) {
                    this.router.navigateByUrl('/formation/' + formation.id + '/my-photo-profile', {
                        state: {
                            photoProfileRequired: true,
                        }
                    });
                    return false;
                }
            }
        });
        return true;
    }
}
