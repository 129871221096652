import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../service/user.service';
import { AuthenticationService } from '../../../../service/authentification.service';
import { environment } from '../../../../../environments/environment';
import { TeacherService } from '../../../../service/teacher.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MzToastService } from 'ng2-materialize';
@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.account';
  myUser: any;
  myTeacher: any;
  showProfessional = false;
  showReport = false;
  componentToLoad;
  modalParams;

  constructor(
    private teacherService: TeacherService,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    public toastService: MzToastService,
    private userService: UserService) { }

  ngOnInit() {
    // check if desktop vs mobile.
    this.showProfessional = this.deviceService.isDesktop();
    this.showReport = this.deviceService.isDesktop();
    this.myUser = this.authenticationService.getCurrentPersona();
    if (this.myUser) {
      this.teacherService.getObservableTeacherByUrl(this.myUser.user.teachers[0])
        .subscribe(_teacher => {
          this.myTeacher = _teacher;
        })
    }
  }

  showModalTeacherProfil() {
    this.componentToLoad = 'teacherProfil';
    this.modalParams = { teacher: this.myTeacher, showReviews: true }
  }

  updateUser(field) {
    const param = {};
    param[field] = this.myUser.user[field];
    this.userService.patchUser(this.myUser.user.url, param);
  }

  uploadAvatar($event) {
    for (const file of $event.target.files) {
      this.userService.patchFile(this.myUser.user.url, this.myUser.user, file)
        .then(user => {
          this.myUser.user.avatar = user.avatar;
        });
    }
  }

}
