import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Message } from '../model/message.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class MessageService extends KiwixiService {

  apiMessages = environment.server + 'messages/';

  constructor(private _http: HttpClient, private _router: Router, kiwixiGlobals: KiwixiGlobals, public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getMessages(filterParams): Promise<Message[]> {
    let params = new HttpParams();
    for (const filterParam of filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiMessages, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Messages', error);
        this.logout();
      })
  }

  postMessage(message: Message): Promise<Message> {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = message;
    return this._http.post(this.apiMessages, body, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show('Erreur à la création de message', 2000, 'red');
        console.error('error Message service', error);
      })
  }

}
