import { AchievementManagerService } from './achievementManager.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConversationService } from './conversation.service';
import { Group } from './../model/group.model';
import { RoutePermission } from './../model/routePermission.model';
import { RoutePermissionService } from './routePermission.service';
import { GroupService } from './group.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, empty } from 'rxjs';
import { share } from 'rxjs/operators'


import { Router, ActivatedRoute } from '@angular/router';
import { Login } from '../model/login.model';
import { Auth } from '../model/auth.model';
import { environment } from '../../environments/environment';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { MzToastService } from 'ng2-materialize';
import * as CryptoJS from 'crypto-js';
import { catchError, tap } from 'rxjs/operators';


@Injectable()
export class AuthenticationService extends KiwixiService {
  auth: Auth;
  headers: HttpHeaders;
  responseheaders: any;
  router: Router;
  activatedRoute: ActivatedRoute;
  kiwixiGlobals: KiwixiGlobals
  private errorMessage: any = '';
  apiMe = environment.me;
  environment = environment;
  apiCalendar = environment.server + 'calendar/export/oauth/token/';
  currentUser: any = null; // persona
  public site;
  public currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentUserAndFormation: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    _router: Router,
    private _http: HttpClient,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
    private groupService: GroupService,
    private routePermissionService: RoutePermissionService,
    private conversationService: ConversationService,
    private deviceDetectorService: DeviceDetectorService,
    private achievementManagerService: AchievementManagerService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
    this.router = _router
    this.activatedRoute = _activatedRoute
    this.headers = new HttpHeaders();
    this.kiwixiGlobals = kiwixiGlobals;
    this.checkCredentialObservable();
    this.site = localStorage.getItem('site');
  }

  get getHeaders() {
    return this.headers;
  }

  callMe(): Observable<HttpResponse<any>> {
    return this._http.get(
      this.apiMe, { observe: 'response', headers: this.createHttpClientAuthorizationHeader() });
  }

  callMe2(newBundle): Observable<HttpResponse<any>> {
    let _httpHeader = new HttpHeaders()
    _httpHeader = _httpHeader.append('x-bundle-recurring', JSON.stringify(newBundle));
    _httpHeader = _httpHeader.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    _httpHeader = _httpHeader.append('Content-Type', 'application/json; charset="utf-8";');
    _httpHeader = _httpHeader.append('Access-Control-Allow-Origin', '*');
    return this._http.get(
      this.apiMe, { observe: 'response', headers: _httpHeader })
  }

  /**
   *
   * @returns {Observable<T>}
   */
  isLoggedIn(): Observable<boolean> {
    return this.currentUserSubject.asObservable().pipe(share());
  }

  // **************************** IMPERSONATE ****************************
  /**
   * Méthode pour définir le type d'initialisation en fonction de l'authentification : impersonate vs reload page
   * @param role // student ou teacher
   * @param site // lingueopro ou teacher
   */
  initAuthPage(role, site): Observable<any> {
    // #1 - check path
    if (fromImpersonate(this, site)) {
      this.initFromImpersonate(role, site); // chargement de la page loggé via impersonate.
      return this.currentUserAndFormation.asObservable().pipe(share());
    } else if (fromReload(this, role)) {
      this.initFromReload(role);
      return this.currentUserAndFormation.asObservable().pipe(share());
    }

    function fromImpersonate(_self, _site) {
      if (_site === 'teacher' && _self.activatedRoute.children[0]) {
        if (_self.activatedRoute.children[0] &&
          _self.activatedRoute.children[0].snapshot.queryParams &&
          _self.activatedRoute.children[0].snapshot.queryParams['persona']) {
          return true;
        }
      } else {
        if (_self.activatedRoute.children[0] &&
          _self.activatedRoute.children[0].snapshot.queryParams &&
          _self.activatedRoute.children[0].snapshot.queryParams['persona']) {
          return true;
        }
      }
      return false;
    }

    function fromReload(_self, _role) {
      if (_role === 'teacher') {
        return true;
      } else if (_role === 'student') {
        if (_self.activatedRoute.children[0] &&
          _self.activatedRoute.children[0].snapshot.params &&
          _self.activatedRoute.children[0].snapshot.params.id) {
          return true;
        }
      }
      return false;
    }

  }

  getRoutesPermissions() {
    let params = new HttpParams();
    params = params.append('salt', Math.random().toString());
    return this.routePermissionService.getRoutePermissions(params);
  }

  getGroups() {
    let params = new HttpParams();
    params = params.append('salt', Math.random().toString());
    return this.groupService.getGroups(params);
  }

  normalizeRoutePath(routePath: string) {
    const re = /\/[0-9]+/
    return routePath.replace(re, '/%s');
  }
  getRoutesAllowed(routePath: string): RoutePermission[] {
    const path = this.normalizeRoutePath(routePath);
    return this.routePermissionService.routeFeaturePermissions.value.filter(e => e.name === path);
  }
  resetFeaturePermissions() {
    this.groupService.appGroups.next(null);
    this.routePermissionService.routeFeaturePermissions.next(null);
  }

  checkGroupPermission(group: Group) {
    if (group.all_user) {
      return true;
    } else {
      const userLogged = this.currentUserSubject.value;
      if (!userLogged) {
        return false;
      }
      const findGroup = userLogged.user.groups.filter(_group => _group.name === group.name);
      if (findGroup.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkAvailableLanguageSheets(languageId) {
    return this.availableSheetsLanguage.indexOf(languageId) === -1 ? false : true;
  }

  checkFeaturePermission(groupName) {
    if (!this.groupService.appGroups.value) {
      return false;
    }
    const findGroup = this.groupService.appGroups.value.filter(group => group.name === groupName);
    if (findGroup.length > 0) {
      return this.checkGroupPermission(findGroup[0]);
    } else {
      return false;
    }
  }


  initFromReload(personaType) {
    let _formation_id = null
    if (personaType === 'student') {
      _formation_id = this.activatedRoute.children[0].snapshot.params.id;
    }
    const _obj = { user: this.getCurrentPersona(), formation: _formation_id };
    this.currentUserAndFormation.next(_obj);
  }

  /**
   * Construction du bundle en mode Impersonate
   * @param _role
   * @param _site
   */
  initFromImpersonate(_role, _site) {
    // #1 - on clean le local storage.
    const current_bundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
    if (this.getCurrentPersona() && current_bundle) {
      localStorage.removeItem('persona');
      localStorage.removeItem('persona_ob');
      localStorage.removeItem('x-bundle-recurring');
      localStorage.removeItem('access_token');
      localStorage.removeItem('sign');
    }

    // #2 - on créée un nouveau bundle avec le nouveau persona.
    const _params = this.activatedRoute.children[0].snapshot.queryParams;
    const newBundle = {
      site: _site,
      language: localStorage.getItem('language'),
      device: this.getDeviceMode(),
      role: _role,
      persona: _params['persona'],
      session_id: _params['session_id'],
      hash: null
    }
    const _str = newBundle.persona +
      newBundle.site + newBundle.language +
      newBundle.device + newBundle.session_id + _params['_sign'];
    const _hash = CryptoJS.SHA256(_str);
    newBundle.hash = _hash.toString();
    localStorage.setItem('x-bundle-recurring', JSON.stringify(newBundle));
    localStorage.setItem('device', this.getDeviceMode());
    localStorage.setItem('access_token', _params['access_token']);

    // #3 - on récupère le nouveau avec le nouveau bundle.
    this.callMe2(newBundle).subscribe(res => {
      localStorage.setItem('sign', _params['_sign']);
      localStorage.setItem('persona', _params['persona']);
      localStorage.setItem('persona_obj', JSON.stringify(res.body));
      localStorage.setItem('impersonate', 'true'); // variable definissant le mode impersonate
      localStorage.setItem('formation_id', _params['formation_id']);
      const _obj = { user: res.body, formation: _params['formation_id'] }
      this.currentUserSubject.next(res.body);
      this.currentUserAndFormation.next(_obj);
      const _url = '/formation/' + _params['formation_id'];
      this.router.navigate([_url]);
    })
  }
  // **************************** IMPERSONATE - end ****************************

  setLocalPersonaObj(personna) {
    localStorage.setItem('persona_obj', JSON.stringify(personna));
  }

  _createAuthorizationHeader() {
    const xBundleRecurring = {
      site: this.auth.site,
      language: this.auth.language,
      device: this.auth.device,
      persona: this.auth.persona,
      role: this.auth.role,
      hash: this.auth.hash
    }
    let headers = new HttpHeaders();
    headers = headers.set('x-bundle-recurring', JSON.stringify(xBundleRecurring))
      .set('Authorization', 'Basic ' + btoa(environment.client_id + ':' + environment.application_id))
      .set('Content-Type', 'application/json; charset="utf-8";')
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Expose-Headers', 'x-bundle-recurring')
      ;
    return headers;
  }

  /**
   * Logout
   */
  logout() {
    // #1 - vide le local storage
    localStorage.removeItem('device');
    localStorage.removeItem('persona');
    localStorage.removeItem('sign');
    localStorage.removeItem('access_token');
    localStorage.removeItem('impersonate');
    localStorage.removeItem('persona_obj');
    localStorage.removeItem('formation_id');
    localStorage.removeItem('x-bundle-recurring');
    // #2 - on change le statut global du login
    this.kiwixiGlobals.setLoginStatus(false);
    this.currentUserSubject.next(null);
    this.conversationService.conversationList.next([]);
    this.achievementManagerService.achievementToFire.next([]);
  }

  /**
   * Methode pour verifier l'authentification de l'utilisateur.
  **/
  public checkCredentialObservable() {
    const current_time = Date.now();
    this.kiwixiGlobals.last_check_time = current_time;
    const newBundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
    if (newBundle === null) {
      this.currentUserSubject.next(null);
      return empty();
    }
    const _str = newBundle.persona +
      newBundle.site + newBundle.language +
      newBundle.device + newBundle.session_id + localStorage.getItem('sign');
    const _hash = CryptoJS.SHA256(_str);
    newBundle.hash = _hash.toString();
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get(this.apiMe, { headers: headers })
      .map(res => {
        const body = res;
        this.extractCredential(body);
        this.currentUser = body;
        this.currentUserSubject.next(body);
        return body;
      })
      .pipe(
        catchError(
          error => {
            this.currentUserSubject.next(null);
            this.kiwixiGlobals.setLoginStatus(false);
            return this.router.navigate(['/login']);
            // return Observable.throw(error);
          }
        )
      );
  }

  login(login: Login) {
    this.computeAuth(login);
    const headers = this.createHttpClientAuthorizationHeader();

    const params = {
      grant_type: 'password',
      username: login.username,
      password: login.password,
      signkey: this.auth.sign
    };
    return this._http.post(environment.auth, params, { observe: 'response', headers: headers })
      .toPromise()
      .then(
        res => {
          this.responseheaders = res.headers;
          const body = res.body;
          localStorage.setItem('x-bundle-recurring', this.responseheaders.get('x-bundle-recurring'));
          localStorage.setItem('access_token', '');
          this.kiwixiGlobals.last_check_time = -1;
          return this.checkCredential();
        },
        error => this.toastService.show('Erreur login !!!', 4000, 'red'))
  }

  newLogin(login: Login) {
    this.computeAuth(login);
    const headers = this._createAuthorizationHeader();
    const params = {
      grant_type: 'password',
      username: login.username,
      password: login.password,
      signkey: this.auth.sign
    };

    return this._http.post(environment.auth, params, { observe: 'response', headers: headers })
      .map(
        res => {
          this.responseheaders = res.headers;
          const access_token = res.body['access_token']
          localStorage.setItem('x-bundle-recurring', this.responseheaders.get('x-bundle-recurring'));
          localStorage.setItem('access_token', access_token);
          this.kiwixiGlobals.last_check_time = -1;
        },
        error => this.toastService.show('Erreur login !!!', 4000, 'red'))
  }

  checkIfIsAdmin(login: Login) {
    this.computeAuth(login);
    const headers = this._createAuthorizationHeader();
    const params = {
      grant_type: 'password',
      username: login.username,
      password: login.password,
      signkey: this.auth.sign
    };
    return this._http.post(environment.auth, params, { observe: 'response', headers: headers })
      .toPromise()
      .then(
        res => {
          this.responseheaders = res.headers;
          const access_token = res.body['access_token']
          localStorage.setItem('x-bundle-recurring', this.responseheaders.get('x-bundle-recurring'));
          localStorage.setItem('access_token', access_token);
        },
        error => this.toastService.show('Erreur login !!!', 4000, 'red'))
  }

  // private extractAuth(res: Response) {
  //   const body = res.json();
  //   localStorage.setItem('access_token', body.access_token);
  //   // this.router.navigate(['/'])
  //   // this.router.navigate(['/dashboard'])
  // }

  public getDeviceMode() {
    if (this.deviceDetectorService.isDesktop() || this.deviceDetectorService.isTablet()) {
      return 'desktop';
    } else {
      return 'mobile';
    }
  }

  private computeAuth(login: Login) {
    const _site = localStorage.getItem('site');
    const _language = localStorage.getItem('language');
    const _device = this.getDeviceMode();
    const _role = localStorage.getItem('default_role');
    const _persona = login.username;
    this.auth = new Auth(_site, _language, _device, _persona, _role);
    this.auth.setSign();
    localStorage.setItem('sign', this.auth.sign);
    localStorage.setItem('device', this.auth.device);
    localStorage.setItem('persona', this.auth.persona);
    localStorage.setItem('role', this.auth.role);
    this.auth.setLoginHash();
  }

  get getAuth() {
    return this.auth;
  }

  getCurrentPersona() {
    return JSON.parse(localStorage.getItem('persona_obj'));
  }

  getCurrentFormationId() {
    return JSON.parse(localStorage.getItem('formation_id'));
  }

  checkPersona() {
    const _user = this.currentUserSubject.value;
    const _persona = JSON.parse(localStorage.getItem('persona_obj'));
    if (_user && _persona) {
      if (_user && _persona && _user.url && _persona.url && _user.url !== _persona.url) {
        return false;
      }
      return true;
    }
    return true;
  }

  newPassword(email, site) {
    // site
    return this._http.post(environment.server + 'user_reset_password/',
      {
        'email': email,
        'site': site
      }
    ).pipe(
      catchError(this.handleErrorObservable('getTemplates', []))
    )
  }

  sendNewPassword(uid, token, password1, password2) {
    // site
    return this._http.post(environment.server + 'new_password/',
      {
        'uid': uid,
        'token': token,
        'password1': password1,
        'password2': password2
      }
    )
      .pipe(
        catchError(this.handleErrorObservable('sendNewPassword', []))
      )
  }

  writeGoogleCalendar(param: any) {
    let params = new HttpParams();
    for (const key of Object.keys(param)) {
      params = params.set(key, param[key]);
    }
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post(this.apiCalendar, param, {
      headers: headers
    })
  }
}
