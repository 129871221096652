import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { ExerciceSheet } from '../../../../../../model/exerciceSheet.model';
import { ExerciceSheetService } from '../../../../../../service/exerciceSheet.service';

@Component({
  selector: 'app-sheet-related',
  templateUrl: './sheet-related.component.html',
  styleUrls: ['./sheet-related.component.scss', '../../../formation/informations/informations.ux.scss', '../../../formation/formation.ux.scss', '../../../mainscreen.ux.scss']
})
export class SheetRelatedComponent implements OnInit {
  @Input() exerciceSheet: ExerciceSheet;
  showSearchSheet = false;
  searchSheetInputControl: FormControl = new FormControl();
  searchSheets: ExerciceSheet[] = [];
  constructor(
    private exerciceSheetService: ExerciceSheetService,
  ) { }

  ngOnInit() {
    this.searchSheetInputControl.valueChanges.debounceTime(1000).subscribe(val => {
      if (val) {
        this.searchSheet(val);
      }
    });
    this.searchSheet('');
  }

  showSearchInput() {
    this.searchSheet('');
    this.showSearchSheet = true;
  }

  displaySheetNameFn(sheet?: ExerciceSheet): String | undefined {
    return sheet ? sheet.title_fr : undefined;
  }

  searchSheet(val: string) {
    let params = new HttpParams()
    params = params.append('title_fr__icontains', val)
    this.exerciceSheetService.getObservableExerciceSheets(params).subscribe(data => {
      if (data.results) {
        // on exclut la fiche courante
        const filteredResults = data.results.filter(sheet => sheet.id !== this.exerciceSheet.id)
        this.searchSheets = filteredResults;
      }
    });
  }

  deleteRelated(id) {
    this.exerciceSheet.sheets = this.exerciceSheet.sheets.filter(sheet => sheet.id !== id);
    this.patchRelation();
  }

  addRelation() {
    this.exerciceSheet.sheets.push(this.searchSheetInputControl.value);
    this.patchRelation();

  }
  patchRelation() {
    const selectedRelatedSheet = this.exerciceSheet.sheets.map(sheet => sheet.url)
    const _params = {
      sheets: selectedRelatedSheet
    };
    this.exerciceSheetService.patchExerciceSheet(this.exerciceSheet.id, _params).subscribe(result => {
      this.resetSearchInput();
    });

  }

  resetSearchInput() {
    this.searchSheetInputControl.reset();
    this.showSearchSheet = false;
  }
}
