/**
 * Composant pour l'edition d un rapport - step 1.
 * Contient un sous composant pour valider la session & annuler.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PendingService } from '../../../../../service/pending.service';
import { FormControl } from '@angular/forms';
import { FormationService } from '../../../../../service/formation.service';
import { environment } from '../../../../../../environments/environment';
@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit {
  occurrence_id: any;
  pending: any;
  formation: any;
  translatePath = 'teacher.reports';
  defaultAvatar = environment.static + '/avatar.png';
  lessonTakePlaceCtrl = new FormControl(true);
  lessonTakePlace = true;
  constructor(
    private route: ActivatedRoute,
    private pendingService: PendingService,
    private formationService: FormationService
  ) { }

  ngOnInit() {
    this.occurrence_id = this.route.snapshot.params.id;
    this.pendingService.getPending(this.occurrence_id)
      .subscribe(_pending => {
        if (_pending) {
          this.pending = _pending;
          this.formationService.getFormationWithUrl(this.pending.formation)
            .then(
              _formation => {
                this.formation = _formation;
                if (this.pending.status === 'done') {
                  this.lessonTakePlace = true;
                  this.lessonTakePlaceCtrl.setValue(true)
                }
              }
            );
        }
      });

    this.lessonTakePlaceCtrl.valueChanges.subscribe(val => {
      this.lessonTakePlace = val;
    })
  }

}

