import { DeviceDetectorService } from 'ngx-device-detector';
import { AchievementDoneservice } from './../../service/achievementDone.service';
import { AchievementService } from './../../service/achievement.service';
import { RateStudentService } from './../../service/rate-student.service';
import { ProfilePhotosService } from './../../service/profilePhotos.service';
import { RoutePermissionService } from './../../service/routePermission.service';
import { GroupService } from './../../service/group.service';
import { ExerciceSessionService } from './../../service/exerciceSession.service';
import { ExerciceResponseService } from './../../service/exerciceResponse.service';
import { ExerciceAnswerChoiceService } from './../../service/exerciceAnswerChoice.service';
import { ExerciceQuestionService } from './../../service/exerciceQuestion.service';
import { ExerciceService } from './../../service/exercice.service';
import { PreviousRouteService } from './../../service/previous-route.service';
import { EmbedVideo } from 'ngx-embed-video';
import { TaskService } from './../../service/task.service';
import { ExerciceCategoryService } from './../../service/exerciceCategory.service';
import { ExerciceSpecificContentService } from './../../service/exerciceSpecificContent.service';
import { ExerciceSheetService } from './../../service/exerciceSheet.service';
import { DownloadLingueoService } from './../../directive/download-lingueo/download-lingueo.service';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterializeModule, MzToastService } from 'ng2-materialize';
import { MomentModule } from 'ngx-moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LingueoproComponent } from './lingueopro.component';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LingueoproRoutingModule } from './lingueopro.routing.module';
import { AuthGuard } from '../../guard/auth.guard';
import { ProfileGuard } from './../../guard/profile.guard';

// service
import { AuthenticationService } from '../../service/authentification.service';
import { ComponentModule } from './component/component.module';
import { FormationModule } from './formation/formation.module';
import { FeatureModule } from '../../feature/feature.module';
import { DictionnaryService } from '../../service/dictionnary.service';
import { TranslateStore } from '@ngx-translate/core';
import { DirectiveModule } from '../../directive/directive.module';
import { MaterialModule } from '../../material.module';
import { PlanFormationModule } from './plan-formation/plan-formation.module';
import { ConversationService } from '../../service/conversation.service';
import { RatingTeachersService } from '../../service/rating-teachers.service';
import { DocumentService } from '../../service/document.service';
import { InitAppService } from '../../service/initapp.service';

import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from './../../kiwixi/error.exception';
import { HeaderRoutingModule } from './component/header-menu/header-routing.module';

// interceptor
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CachingInterceptor } from '../../interceptors/cache.interceptor';
import { CacheService } from '../../interceptors/cache.service';
import { NativeDateAdapter, DateAdapter } from '@angular/material';

export class LingueoProDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

import 'moment/locale/it';
import 'moment/locale/fr';
import { HelpNotifService } from '../../service/helpNotif.service';
import { AchievementManagerService } from '../../service/achievementManager.service';
import { MaintenanceGuard } from '../../guard/maintenance.guard';
@NgModule({
  declarations: [
    LingueoproComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterializeModule.forRoot(),
    BrowserAnimationsModule,
    MomentModule,
    ComponentModule,
    FeatureModule,
    InfiniteScrollModule,
    FormationModule,
    PlanFormationModule,
    TranslateModule.forChild(),
    MaterialModule,
    DirectiveModule,
    FeatureModule,
    LingueoproRoutingModule,
    HeaderRoutingModule,
    EmbedVideo.forRoot(),
  ],
  providers: [
    KiwixiService,
    InitAppService,
    KiwixiGlobals,
    AuthGuard,
    MaintenanceGuard,
    ProfileGuard,
    AuthenticationService,
    MzToastService,
    TranslateService,
    TranslateStore,
    DictionnaryService,
    ConversationService,
    RatingTeachersService,
    RateStudentService,
    DocumentService,
    DownloadLingueoService,
    CacheService,
    ExerciceService,
    ExerciceSheetService,
    ExerciceSessionService,
    ExerciceSpecificContentService,
    ExerciceCategoryService,
    ExerciceQuestionService,
    ExerciceResponseService,
    ExerciceAnswerChoiceService,
    TaskService,
    PreviousRouteService,
    GroupService,
    RoutePermissionService,
    ProfilePhotosService,
    AchievementService,
    AchievementDoneservice,
    AchievementManagerService,
    HelpNotifService,
    DeviceDetectorService,
    { provide: DateAdapter, useClass: LingueoProDateAdapter },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true }
  ],
  exports: [
    ComponentModule,
    TranslateModule,
    InfiniteScrollModule,
    HeaderRoutingModule
  ],
  bootstrap: [LingueoproComponent]
})
export class LingueoproModule { }
