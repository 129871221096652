<ng-container *ngIf="showPage; else elseTemplate">
  <div class="billingdetail-wrapper">
    <!-- Header -->
    <div class="billingdetail-header">
      <i class="material-icons back-btn" [routerLink]="['../../../billing']">arrow_back</i>
      <div class="billing-label">
        {{invoice.clean_date}} <span translate>teacher.invoices.invoice-detail</span>
        <!-- Invoice Detail -->
      </div>
    </div>

    <!-- Main -->
    <div class="billingdetail-mainwrapper">
      <div class="invoice-information">
        
          <div class="data-row">
            <span class="data-title"  translate="">teacher.invoices.detail-status-lbl</span>
            <span>: {{invoice.status}}</span>
          </div>
          <div class="data-row">
            <span class="data-title" translate="">teacher.invoices.detail-total-lbl</span>
            <span>: {{invoice.amount}}€</span>
          </div>
       
        
          <button class="add-invoice-btn" (click)="uploadInvoices.click();" *ngIf="!invoice.invoice">
            <span translate>teacher.invoices.upload-invoice-btn</span>
          </button>
          <input #uploadInvoices type="file" (change)="uploadInvoice($event)" [hidden]="true" />
        </div>
      
      <div class="class-container invoice-information">
        <div *ngFor="let occurrence of invoice.occurrences">
            <app-class-details [occurrence]="occurrence"></app-class-details>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center loading pageload-message">
    <b translate>teacher.billing.detail-loading</b>
  </div>
</ng-template>