<div class="pagecontent-flexwrap">
  <div class="content-main">
      <app-next-session *ngIf="myNextOccurrence" [nextSession]="myNextOccurrence" [formation]="formation"></app-next-session>

      <div *ngIf="showPast; else elseTemplate">
        <app-previous-occurences *ngIf="pastOccurrences && occurrencesResult"  [pastOccurrences]="pastOccurrences" [occurrencesResult]="occurrencesResult" [formation]="formation"></app-previous-occurences>
      </div>
      <ng-template #elseTemplate>
        <div class="pageload-message">
          <b translate>lingueopro.chargement.past</b>
        </div>
      </ng-template>
  </div>
  <div class="content-side" #planningSession>
    <div class="content-side-subflex">
      <app-comprate [formation]="formation"></app-comprate>
      <app-lilate [pastOccurrences]="pastOccurrences" [formation]="formation"></app-lilate>
    </div>
    <app-my-teachers [formation]="formation"></app-my-teachers>
  </div>
</div>
