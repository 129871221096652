<div class="row table-time" *ngIf="loader ">
   <div class="col l2">
    <div class="row">
      <div><mat-spinner *ngIf="loader"></mat-spinner></div>
    </div>
  </div>
</div>
<div class="row table-time" *ngIf="!loader">
  <div class="col l2">
    <div class="row planner-row" *ngFor="let step of rows">
        <div class="time-planner">{{step.time | amLocal | amDateFormat: 'HH:mm'}}</div>
    </div>
  </div>
  <div class="col l10">
    <div class="select-time-row" *ngFor="let step of rows; let idx=index">
      <div *ngIf="step.resume === null || !step.resume; else tooltip">
          <div class="{{step.state}}">{{step.label}}</div>
      </div>
      <ng-template #tooltip>
        <div mz-tooltip
          [tooltip]="step.resume"
          [position]="'bottom'"
          [html]="true">
          <div class="{{step.state}}">{{step.label}}</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
