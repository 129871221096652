import { Teacher } from './teacher.model';
// import { Rate } from '../model/rate.model';

export class Event {
  id: number;
  event_type: string;
  url: string;
  start: Date;
  end: Date;
  title: string;
  description: string;
  created_on: Date;
  updated_on: Date;
  ghost: boolean;
  end_recurring_period: Date;
  color_event: string;
  teacher: string;
  teachers: Teacher[];
  creator: string;
  rule: string;
  calendar: string;
  show_day: boolean;
  edit_extend: boolean;
  edit_delete: boolean;
  deleted: boolean;
  constructor() { }
}
