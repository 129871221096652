<div class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Ennoncé global</h6>
    </div>
  </div>
  <div class="row information-subcontainer">
    <div class="col l12">
      <div class="row flexparent">
        <span class="quizz-fieldname flexchild nogrow">Texte :</span>
        <textarea class="flexchild"
            [formControl]="instructionForm"
            (focusout)="patchInstruction()"
            rows="8" cols="80">
        </textarea>  
        </div>
      </div>
    </div>

</div>


<div class="row" *ngFor="let question of exercice.questions; let i=index">
  <app-edit-question-qcm *ngIf="question.typology=='qcm-unique-choice'" [position]="i" [parent]="this" [exercice]="exercice" [question]="question"></app-edit-question-qcm>
</div>

<div class="row addquizz-wrapper">
  <div class="exo-addquizz">
    <button class="action-button addnew-button" (click)="addQuestion()" mz-button [disabled]="!typology.valid">
      Ajouter une question
      <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'"></i>
    </button>
  </div>
  <div class="exo-typeselect">
      <mat-select [formControl]="typology" class="typology" placeholder="Type de question">
        <mat-option *ngFor="let typology of typologyList"  [value]="typology.code" [disabled]="!typology.enable">
          {{typology.label}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="typology.touched && typology.hasError('required')">Ce champs est requis</mat-error>
  </div>
</div>
