import { User } from './../model/user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { KiwixiService } from '../kiwixi/kiwixi.service';
import { catchError } from 'rxjs/operators';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { ProfilePhotos } from '../model/profilePhotos.model';

@Injectable()
export class ProfilePhotosService extends KiwixiService {

    apiProfilePhotos = environment.server + 'profile_photos/';
    public authorizedExtension = ['jpg', 'png', 'jpeg']

    constructor(private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService,
    ) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    patchProfilePhoto(id, params) {
        const url = this.apiProfilePhotos + id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<ProfilePhotos>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ProfilePhotos>(`Patch d'un profil photo`)),
            );
    }

    postProfilePhoto(user: User, file: File, status) {
        const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
        headers.delete('Content-Type');
        const _formData: FormData = new FormData();
        _formData.append('enctype', 'multipart/form-data');
        _formData.append('photo_identity', file);
        _formData.append('name', file.name);
        _formData.append('user', user.url);
        if (status) {
            _formData.append('status', status);
        }
        return this._http.post<ProfilePhotos>(this.apiProfilePhotos, _formData, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ProfilePhotos>(`Création d'une photo de profile`)),
            );
    }

}
