<div class="row flexparent">
  <span class="quizz-fieldname quizz-correct flexchild nogrow" *ngIf="answer.is_right">Bonne Réponse :</span>
  <span class="quizz-fieldname quizz-false flexchild nogrow" *ngIf="!answer.is_right">Mauvaise Réponse :</span>
  <textarea class="quizz-correct-field flexchild" [ngClass]="{'quizz-correct-field': answer.is_right, 'quizz-false-field': !answer.is_right}"
      [formControl]="text"
      (focusout)="patchAnswer()"
      rows="8" cols="80">
  </textarea>  
  <div  class="quizz-deletebtn" (click)="emitRemoveAnswer()" mz-button>
  </div>
  <mat-error *ngIf="text.touched && text.hasError('required')">Ce champs est requis</mat-error>
</div>