import { ProfilePhotos } from './profilePhotos.model';
import { Group } from './group.model';
export class User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  avatar: string;
  fonction: string;
  email: string;
  birthdate: Date;
  video_provider: string;
  id_skype: string;
  url: string;
  timezone: string;
  main_language: string;
  gender: string;
  password: string;
  auto_pwd: boolean;
  sites: Object[];
  students: Object[];
  staffs: Object[];
  teachers: Object[];
  groups: Group[];
  photo_profile: ProfilePhotos;
  photo_profile_validation_requested: boolean;
  sf_id: string;
  last_login: Date;
  constructor(_username, _url) {
    this.username = _username;
    this.url = _url;
  }
}
