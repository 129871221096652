/**
 * Sous-composant pour les évaluations
 */

import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { RatingTeacher } from '../../../../../../../model/rating-teacher.model';
import { StudentService } from '../../../../../../../service/student.service';
import { NewTeacher } from '../../../../../../../model/new-teacher.model';
import { EditTeacherService } from '../../edit-teacher.service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { RatingTeachersService } from '../../../../../../../service/rating-teachers.service';
import { TeacherService } from '../../../../../../../service/teacher.service';

@Component({
  selector: 'app-evaluations',
  templateUrl: './evaluations.component.html',
  styleUrls: [
    './evaluations.component.scss',
    './evaluations.ux.scss',
    '../../edit-teacher.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [EditTeacherService, StudentService]
})
export class EvaluationsComponent implements OnInit {
  @Input() myTeacher: NewTeacher;
  countTeachers: number;
  ratings: RatingTeacher[];
  countPages = 1;
  totalData = 0;
  teacher_id: any;
  throttle = 1000; // temps accordé avant de fire l'event de scoll
  scrollDistance = 1; // % restant à partir duquel on fire l'event 1 = 10 %.
  ratingsStatus = this.ratingTeacherService.getStatus();

  constructor(
    private teacherService: TeacherService,
    private route: ActivatedRoute,
    private ratingTeacherService: RatingTeachersService) { }

  ngOnInit() {
    this.ratings = [];
    this.route.params.subscribe(params => {
      this.teacher_id = params['id'];
      this.getRatingTeachers();
      this.getCountTeachers();
    });
  }

  /**
   * récupérer le nombre de teachers
   */
  getCountTeachers() {
    let _param = new HttpParams();
    _param = _param.append('teach_language__code', 'language.en');
    this.teacherService.getObservableTeachers(_param)
      .subscribe(res => {
        this.countTeachers = res['count'];
      });
  }

  /**
   * méthode pour récupérer les ratings.
   */
  getRatingTeachers() {
    let params = new HttpParams();
    params = params.append('teacher', this.teacher_id);
    params = params.append('page', this.countPages.toString());
    this.ratingTeacherService.getObservableRatingTeachers(params)
      .subscribe(_ratings => {
        if (_ratings) {
          this.totalData = _ratings['count'];
          _ratings['results'].forEach(_rate => {
            this.ratings.push(_rate)
          });
        }
      })
  }

  /**
   * méthode pour mettre à jour le teacher.
   * @param rating
   * @param field
   */
   updateRating(rating, field) {
    const param = {};
    param[field] = rating[field];
    return this.ratingTeacherService.patchObservableRatingTeacher(rating.url, param).subscribe(
      newRating => {
        rating.status = newRating.status;
      }
    )
  }

  onScrollDown() {
    if (this.lazyIsAvailable()) {
      this.countPages++;
      this.getRatingTeachers();
    }
  }

  /**
   * méthode pour vérifier si le lazy est possible.
   */
  lazyIsAvailable() {
    if ((this.countPages * 10) < this.totalData) {
      return true;
    }
    return false;
  }

}
