import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from './../../service/authentification.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ENTER_KEY = 13
}

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss', '../login/login.ux.scss']
})
export class LostPasswordComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  translatePath = 'lostpassword'
  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER_KEY) {
      this.sendMail();
    }
  }

  ngOnInit() { }

  sendMail() {
    const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);
    this.authenticationService.newPassword(this.emailFormControl.value, environment.urls[idx].url).subscribe(res => {
      this.router.navigate(['login']);
      this.authenticationService.toastService.show('Message envoyé', 4500, 'green');
    });
  }
}
