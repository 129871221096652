import { environment } from './../../../environments/environment.staging';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-teacher-profil',
  templateUrl: './teacher-profil.component.html',
  styleUrls: ['./teacher-profil.component.scss']
})
export class TeacherProfilComponent implements OnInit {
  @Input() teacher;
  @Input() showReviews;
  translatePath = 'teacher.account';
  defaultAvatar = environment.static + '/avatar.png';
  constructor() { }

  ngOnInit() {
  }

}
