import { User } from './../../../../../model/user.model';
import { ExerciceSheetService } from './../../../../../service/exerciceSheet.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ExerciceSheet } from '../../../../../model/exerciceSheet.model';

@Component({
  selector: 'app-sheet-create',
  templateUrl: './sheet-create.component.html',
  styleUrls: ['./sheet-create.component.scss', '../../formation/formation.ux.scss', '../../mainscreen.ux.scss']
})
export class SheetCreateComponent implements OnInit {
  editMode = false;
  exerciceSheet: ExerciceSheet = null;
  sheetStatus;
  currentUser: User;
  isAdmin: boolean;
  isDrillsManager: boolean;
  constructor(
    private route: ActivatedRoute,
    private exerciceSheetService: ExerciceSheetService,
  ) { }

  ngOnInit() {
    this.currentUser = this.exerciceSheetService.kiwixiGlobals.currentStaff.value.user;
    this.isAdmin = this.currentUser.groups.filter(group => group.name === 'admin-exercices').length > 0 ? true : false;
    this.isDrillsManager = this.currentUser.groups.filter(group => group.name === 'drills-content-manager').length > 0 ? true : false;

    const sheetId = this.route.snapshot.paramMap.get('id');
    if (sheetId) {
      this.editMode = true;
      this.exerciceSheetService.getExerciceSheetById(sheetId).subscribe(sheet => {
        this.exerciceSheet = sheet;
        this.initStatus(sheet);
      })
    }
  }

  initStatus(sheet) {
    this.sheetStatus = this.exerciceSheetService.getExerciceSheetsStatus().filter(status => status.code === sheet.status)[0];
  }

  is_publishable() {
    if (this.exerciceSheet.nb_easy_exercices > 0 && this.exerciceSheet.nb_medium_exercices > 0 && this.exerciceSheet.nb_hard_exercices > 2) {
      return true;
    } else {
      return false;
    }
  }

  updateStatus(status) {
    if (!this.is_publishable()) {
      this.exerciceSheetService.toastService.show('Le nombre d\'exercice est insuffisant pour publier cette fiche', 2000, 'red');
      return;
    }
    if (this.exerciceSheet) {
      const _params = {
        status: status,
      };
      this.exerciceSheetService.patchExerciceSheet(this.exerciceSheet.id, _params).subscribe(sheet => {
        if (sheet) {
          this.exerciceSheet.status = status;
          this.exerciceSheet.published_at = sheet.published_at
          this.initStatus(sheet);
        }
      });
    }
  }

}
