<div class="row">
  <div class="col l5">
    <app-admin [myTeacher]="myTeacher" [parentComponent]="parentComponent"></app-admin>
  </div>
  <div class="col l7">
    <app-invoices [myTeacher]="myTeacher"></app-invoices>
  </div>
</div>
<!-- <div class="col l8 changehistory-block" *ngIf="!refresh">
  <app-historical-admin-teacher [myTeacher]="myTeacher"></app-historical-admin-teacher>
</div>
<div class="col l8 center changehistory-block" *ngIf="refresh">
  <b>Chargement de l'historique...</b>
</div> -->
