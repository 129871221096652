import { ProfilePhotosService } from './../../../../../../../service/profilePhotos.service';
/**
 * Sous-composant pour l'édition des informations personnelles d'un teacher.
 */

import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';






import { ActivatedRoute, Router } from '@angular/router';
import { MzModalService } from 'ng2-materialize';

// Model
import { Teacher } from '../../../../../../../model/teacher.model';
import { Site } from '../../../../../../../model/site.model';
import { Timezone } from '../../../../../../../model/timezone.model';

// Service
import { TimezoneService } from '../../../../../../../service/timezone.service';
import { UserService } from '../../../../../../../service/user.service';
import { Language } from '../../../../../../../model/language.model';
import { environment } from '../../../../../../../../environments/environment';
import { KiwixiGlobals } from '../../../../../../../kiwixi/kiwixi.globals';
import { EditTeacherService } from '../../edit-teacher.service';

@Component({
  selector: 'app-personal-informations',
  templateUrl: './personal-informations.component.html',
  styleUrls: [
    './personal-informations.component.scss',
    './personal-informations.ux.scss',
    '../../edit-teacher.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [MzModalService, EditTeacherService]
})
export class PersonalInformationsComponent implements OnInit {

  @Input() myTeacher: Teacher;
  @Input() parentComponent
  apiTeachers = environment.server + 'teachers/';
  defaultAvatar = environment.static + '/avatar.png';
  sites: Site[];
  languages: Language[];
  currentIdTeacher: number;
  dataFormations: Object;
  pageSize: number;
  source: string;
  countPages = 1;
  countPagesLanguages = 1;
  showStudent = false;
  now: any;
  zones: Timezone[];
  timezoneControl: FormControl = new FormControl();
  filteredTimezones: Observable<Timezone[]>;
  myTimezone: string;
  myDate: Date;
  validModal = false;

  constructor(
    private kiwixiGlobals: KiwixiGlobals,
    router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private editTeacherService: EditTeacherService,
    private timezoneService: TimezoneService,
    private profilePhotosService: ProfilePhotosService,
  ) { }

  ngOnInit() {
    this.myDate = new Date();
    this.now = new Date(Date.now());
    this.zones = [];
    this.route.params.subscribe(params => {
      this.currentIdTeacher = params['id'];
      this.languages = [];
      this.getAllTimezones(1);
      // init timezones combo
      this.filteredTimezones = this.timezoneControl.valueChanges
        .startWith(null)
        .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
        .map(val => val ? this.filterTimezone(val) : this.zones);
    });
  }

  /**
   * Méthode pour filtrer les timezones
   * @param val
   */
  filterTimezone(val: string): Timezone[] {
    return this.zones.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) > - 1);
  }

  /**
   * méthode pour mettre à jour un teacher
   * @param teacher
   * @param field
   */
  updateTeacher(teacher, field) {
    const param = {};
    param[field] = teacher[field];
    this.editTeacherService.updateTeacher(teacher.url, param)
    // this.parentComponent.refreshLogs();
  }

  /**
   * méthode pour mettre à jour un user
   * @param user
   * @param field
   */
  updateUser(user, field) {
    const param = {};
    param[field] = user[field];
    this.editTeacherService.updateUser(user.url, param)
    // this.parentComponent.refreshLogs();
  }

  /**
   * méthode pour récupérer toutes les timezones
   * @param _countPagesSites
   */
  getAllTimezones(_countPagesSites) {
    this.timezoneService.getTimezones({})
      .then(_timezonesData => {
        for (const timezone of _timezonesData) {
          this.zones.push(timezone)
        }
      });
  }

  /**
   * méthode pour mettre à jour l'email du teacher si valid.
   * @param user
   * @param field
   */
  checkValidEmailToUpdate(user, field) {
    if (document.getElementById('email').className.match('invalid')) {
      this.myTeacher.user.email = null
    } else {
      this.updateUser(user, field)
    }
  }

  /**
   * méthode pour mettre à jour le timezone au select.
   * @param timezone
   */
  selectTimezone(timezone) {
    this.myTeacher.user.timezone = timezone.label;
    this.myTimezone = this.kiwixiGlobals.formatTimezone(timezone.label);
    this.updateUser(this.myTeacher.user, 'timezone');
    // this.parentComponent.refreshLogs();
  }

  /**
   * méthode pour mettre à jour l'avatar.
   * @param $event
   */
  uploadAvatar($event) {
    // on upload l'avatar sur le user.
    for (const file of $event.target.files) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      if (this.profilePhotosService.authorizedExtension.indexOf(ext.toLowerCase()) !== -1) {
        this.sendFile(file);
      } else {
        this.profilePhotosService.toastService.show('Fomat de fichier invalide, JPG ou PNG autorisé !', 4000, 'red');
      }
    }
  }

  sendFile(file) {
    this.profilePhotosService.postProfilePhoto(this.myTeacher.user, file, 'validated').subscribe(photoProfile => {
      this.myTeacher.user.photo_profile = photoProfile;
      this.myTeacher.user.photo_profile_validation_requested = true;
    })
  }

  /**
   * méthode pour afficher la modal de validation.
   * @param value
   */
  handleShowValidModal(value) {
    this.validModal = value;
  }

}
