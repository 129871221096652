import { UpcomingService } from './../../../../service/upcoming.service';
import { Component, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import { environment } from '../../../../../environments/environment';
import { Formation } from '../../../../model/formation.model';
import { Occurrence } from '../../../../model/occurrence.model';
import { Session } from '../../../../model/session.model';
import { FormationService } from '../../../../service/formation.service';
import { OccurrenceService } from '../../../../service/occurrence.service';
import { SessionService } from '../../../../service/session.service';
import * as moment from 'moment-timezone';
import { AuthenticationService } from '../../../../service/authentification.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, OnChanges {
  currentFormation: ISubscription;
  formation: Formation;
  myNextOccurrence: Occurrence;
  nextSession: any;
  getOccurence_service: ISubscription;
  occurrences = [];
  showPast = false;
  pastOccurrences = [];
  now = null;
  upcomingOccurrences: ISubscription;



  constructor(
    private authenticationService: AuthenticationService,
    private formationService: FormationService,
    private occurrenceService: OccurrenceService,
    private sessionService: SessionService,
    private upcomingService: UpcomingService,
  ) { }

  ngOnInit() {
    this.init();
  }
  ngOnChanges() {
    this.init();


  }

  init() {
    this.now = moment();
    this.currentFormation = this.formationService.currentFormationSubject.subscribe((_formation: Formation) => {
      if (_formation) {
        this.formation = _formation;
        this.getNextOccurrence();
        this.getPastOccurences();
      }
    });
  }

  ngOnDestroy() {
    this.currentFormation.unsubscribe();
    if (this.getOccurence_service) {
      this.getOccurence_service.unsubscribe()
    }
    this.upcomingOccurrences.unsubscribe();

  }

  getNextOccurrence(morePage = null) {
    let url = environment.server + 'upcomings/?salt=' + Math.random().toString() + '&page_size=1&formation=' + this.formation.id;

    this.upcomingOccurrences = this.upcomingService.getUpcomingsWithUrl(url)
      .subscribe(_upcomings => {
        if (_upcomings['results']) {
          this.setNextClasses(_upcomings['results']);
        }
      });
  }


  setNextClasses(_occurrences: Occurrence[]) {
    if (_occurrences.length > 0) {
      const findNextOcc: Occurrence[] = _occurrences.filter(_occurrence => _occurrence.status === 'booked');
      if (findNextOcc.length > 0) {
        this.myNextOccurrence = findNextOcc[0];
        this.checkSessionForNextOccurrence();
      } else {
        this.myNextOccurrence = null;
      }
    }
  }

  checkSessionForNextOccurrence() {
    this.nextSession = null;
    // on check si une session existe pour la prochaine occurrence
    if (this.myNextOccurrence.session) {
      this.nextSession = this.myNextOccurrence;
    } else {
      const body = {
        formation: this.formation.url,
        teacher: this.myNextOccurrence.teacher.url,
        occurrence: this.myNextOccurrence.url
      }
      // on crée la session manquante
      this.sessionService.postNewSession(this.formation.url, body)
        .subscribe((_newSession: Session) => {
          this.nextSession = this.myNextOccurrence;
        })
    }
  }

  /**
 * Methode pour recuperer les occurrences
 */
  getPastOccurences(morePage = null) {
    this.occurrences = [];
    this.pastOccurrences = [];
    const params = {
      'formation': this.formation.id.toString(),
      'start__lte': moment(this.now).toISOString(),
      'ordering': '-start',
      'status__in': 'done,abs_teacher_without_justification,abs_student_without_justification',
      'page_size': '1'
    }
    let url = this.formationService.setUrlWithParams('occurrences', params);
    this.getOccurence_service = this.occurrenceService.getOccurencesByUrl(url)
      .subscribe(_occurrences => {
        if (_occurrences['results']) {
          _occurrences['results'].forEach(_occurrence => {
            this.occurrences.push(_occurrence);
          });
          this.filterPastOccurrences();
          this.showPast = true;
        }
      }
      );
  }

  /**
   * Methode pour filtrer sur les occurrences passées.
   */
  filterPastOccurrences() {
    this.pastOccurrences = this.occurrences.filter(_occurrence =>
      _occurrence.status === 'done'                                      // Cours pris
      || _occurrence.status === 'abs_teacher_without_justification'         // Prof Absent
      || _occurrence.status === 'abs_student_without_justification')        // Cours Manqué
  }
}
