import { GroupFormations } from './../../../../model/group_formations.model';
import { LanguageService } from './../../../../service/language.service';
import { Language } from './../../../../model/language.model';
import { MzToastService } from 'ng2-materialize';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { FormationSuiviService } from './../../../../service/formationSuivi.service';
import { FormationSuivi } from './../../../../model/formationSuivi.model';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import localeFr from '@angular/common/locales/fr';
import { HttpParams } from '@angular/common/http';
import { GroupFormationsService } from '../../../../service/groupFormations.service';
registerLocaleData(localeFr);

@Component({
  selector: 'app-formation-suivi',
  templateUrl: './formation-suivi.component.html',
  styleUrls: ['./formation-suivi.component.scss']
})
export class FormationSuiviComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'select',
    'id',
    'company',
    'language',
    'first_name',
    'last_name',
    'email',
    'hours_total',
    'hours_to_plan',
    'hours_to_plan_percent',
    'last_occ',
    'validity_period_to',
    'status',
    'is_stall',
  ];
  formations: FormationSuivi[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  formationSuiviStatus = this.formationSuiviService.getFormationSuivisStatus();
  total: Number = 0;
  totalActive: Number = 0;
  totalPending: Number = 0;
  totalSuspended: Number = 0;
  totalCancelled: Number = 0;
  totalDone: Number = 0;
  planification_moyennne = 0;
  budget_contrat: Number = 0;
  totalAmountEngaged: Number = 0;
  totalAmountConsumed: Number = 0;
  selection = new SelectionModel(true, []);
  modeAllPage = false;
  allIds;
  page_size = 10;
  languageList: Language[] = [];
  groupFormationsList: GroupFormations[] = [];
  dateLastUpdate: Date;

  filterFormGroup = new FormGroup({
    id: new FormControl(),
    company: new FormControl(),
    language: new FormControl(),
    first_name: new FormControl(),
    last_name: new FormControl(),
    email: new FormControl(),
    hours_total: new FormControl(),
    hours_to_plan: new FormControl(),
    hours_to_plan_percent: new FormControl(),
    last_occ: new FormControl(),
    validity_period_to: new FormControl(),
    status: new FormControl(),
    is_stall: new FormControl(),
    group_formations: new FormControl(),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private formationSuiviService: FormationSuiviService,
    private languageService: LanguageService,
    private groupFormationsService: GroupFormationsService,
    private toastService: MzToastService,
  ) { }

  ngAfterViewInit() {
    this.getLanguageList();
    this.getGroupFormationsList();
    this.formationSuiviService.setFilterListing(this.filterFormGroup.value);
    this.filterFormGroup.valueChanges.debounceTime(1000).subscribe(data => {
      this.formationSuiviService.setFilterListing(data);
      this.applyFilter();
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.getInvoices();
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          this.totalActive = data.total_active;
          this.totalPending = data.total_pending;
          this.totalSuspended = data.total_suspended;
          this.totalCancelled = data.total_cancelled;
          this.totalDone = data.total_done;
          let planifMoyenne = data.planification_moyennne;
          if (data.planification_moyennne) {
            planifMoyenne = Math.round(data.planification_moyennne)
          }
          this.planification_moyennne = planifMoyenne;
          this.budget_contrat = data.amount_contrat;
          this.totalAmountEngaged = data.amount_engaged;
          this.totalAmountConsumed = data.amount_consumed;
          if (data.results.length > 0) {
            this.dateLastUpdate = data.results[0].created_at;
          } else {
            this.dateLastUpdate = null;
          }
          this.allIds = data.allids;

          return data.results;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.formations = data;
      });
  }

  getLanguageList() {
    this.languageService.getObservableLanguages().subscribe(data => {
      if (data['results']) {
        this.languageList = data['results'];
      }
    })
  }

  getGroupFormationsList() {
    this.groupFormationsService.getGroups(new HttpParams()).subscribe(data => {
      if (data.results) {
        this.groupFormationsList = data.results;
      }
    })
  }

  getInvoices() {
    return this.formationSuiviService.getFormationSuivisByFilter(this.paginator.pageSize, this.paginator.pageIndex, this.sort.active, this.sort.direction)
  }

  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getInvoices().subscribe(
      _formation => {
        this.selection.clear();
        this.modeAllPage = false;
        this.resultsLength = _formation.count;
        this.formations = _formation.results;
        this.totalActive = _formation.total_active;
        this.totalPending = _formation.total_pending;
        this.totalSuspended = _formation.total_suspended;
        this.totalCancelled = _formation.total_cancelled;
        this.totalDone = _formation.total_done;
        let planifMoyenne = _formation.planification_moyennne;
        if (_formation.planification_moyennne) {
          planifMoyenne = Math.round(_formation.planification_moyennne)
        }
        this.planification_moyennne = planifMoyenne;
        this.budget_contrat = _formation.amount_contrat;
        this.totalAmountEngaged = _formation.amount_engaged;
        this.totalAmountConsumed = _formation.amount_consumed;
        if (_formation.results.length > 0) {
          this.dateLastUpdate = _formation.results[0].created_at;
        } else {
          this.dateLastUpdate = null;
        }
        this.allIds = _formation.allids;
      });
  }

  clearFilter() {
    this.filterFormGroup.reset();
  }

  getTotalCaEngaged() {
    return 0
  }
  getTotalCaConsumed() {
    return 0
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isPageSelected() {
    let pageSelected = true;
    this.formations.forEach(invoice => {
      if (!this.selection.isSelected(invoice.id)) {
        pageSelected = false;
      }
    })
    return pageSelected;
  }

  clearPage() {
    this.formations.forEach(invoice => {
      if (this.selection.isSelected(invoice.id)) {
        this.selection.toggle(invoice.id);
      }
    })
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isPageSelected() ?
      this.clearPage() :
      this.formations.forEach(invoice => this.selection.select(invoice.id));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isPageSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected((row.id)) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  selectAllPage() {
    this.modeAllPage = !this.modeAllPage;
    if (this.modeAllPage) {
      this.allIds.forEach(id => this.selection.select(id));
    } else {
      this.selection.clear()
    }
  }

  getSelected() {
    return this.selection.selected.map(invoice_id => invoice_id);
  }


  checkIfSelected() {
    if (this.selection.selected.length === 0) {
      this.toastService.show('Veuillez selectionnez au moins une ligne', 4000, 'red');
      return false;
    } else {
      return true;
    }
  }

  /**
   * méthode pour download le fichier d'export.
   * @param _type
   */
  export() {
    if (this.checkIfSelected() === false) {
      return;
    }
    const _url = environment.server + 'formation_suivi/export/';
    const selected = this.getSelected();
    if (selected) {
      this.formationSuiviService.postExport(_url, selected, 'export_suivi_formation')
        .then(res => {
          this.toastService.show('Téléchargement terminé', 4000, 'green');
        })
    }
  }

}
