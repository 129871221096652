<div class="video-container" *ngIf="!error">
	<video [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" [width]="width" [height]="height"
		autoplay></video>
	<canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="width"
		[height]="height"></canvas>
</div>
<div>
	<button class="takepic-btn" *ngIf="!isCaptured" (click)="capture()" translate>feature.webcam.Prendre une photo</button>
	<button class="takepic-btn btn-danger" *ngIf="isCaptured" (click)="isCaptured=!isCaptured" translate>feature.webcam.Réessayer ?</button>

	<div *ngIf="error" class="text-danger mt-4">
		{{ error }}
	</div>

	<ul class="list-unstyled">
		<li *ngFor="let src of captures; let idx = index" [ngClass]="{'selected' : idx==selectedIdx}" (click)="setPhoto(idx)">
			<img [src]="src" class="snapPicture" />
  		</li>
	</ul>
	<button class="nextstep-btn"  *ngIf="isCaptured"  (click)="choosePhoto()" >
		<span translate>feature.webcam.Valider votre photo</span>
	</button>
</div>