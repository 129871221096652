import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PlanService } from '../../service/plan.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import * as moment from 'moment';
import 'moment/locale/fr';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [PlanService]
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() myTeacher;
  @Input() availablesDays;
  @Input() myStart;
  @Input() myEnd;
  @Input() filterDay;
  @Input() loadDisponibilities;
  @Output() updateDateSelect = new EventEmitter();
  @Output() updateAvailables = new EventEmitter();
  filterDateList = [];
  dateNow = new Date();
  month = this.dateNow.getMonth() + 1;
  day = this.dateNow.getDate();
  year = this.dateNow.getFullYear();

  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];
  FebNumberOfDays = '28';
  dayPerMonth = ['31', '' + this.FebNumberOfDays + '', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
  calendarData = [];
  selectedDay = null;
  constructor(private planService: PlanService, private kiwixiGlobals: KiwixiGlobals) { }

  ngOnInit() {
    this.displayAvailablesDays();
    if (this.getCurrentLanguage() === 'fr') {
      this.monthNames = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
      this.dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    }
    this.setCalendarData()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.availablesDays.length > 0) {
      this.displayAvailablesDays();
    } else {
      this.filterDateList = [];
      this.setCalendarData();
    }
  }

  displayAvailablesDays() {
    this.filterDateList = [];
    if (this.availablesDays) {
      this.availablesDays.forEach(_availableDay => {
        _availableDay.dispo.forEach(_dispo => {
          const obj = {
            day: _availableDay.day,
            start: _dispo.start,
            end: _dispo.end
          }
          if (this.myStart && this.myEnd) {
            this.setFilterListToCalendar(obj);
          } else {
            this.addToFilterList(moment(obj['day']))
          }
          this.setCalendarData();
        });
      });
    }
  }

  /* méthode pour construire le calendar */
  setCalendarData() {
    const isLeapYear = moment([this.year]).isLeapYear();
    let numOfDays = Number(this.dayPerMonth[this.month - 1]);
    if (isLeapYear && this.month === 2) { // Si le mois courant est février dans une année bissexctile
      numOfDays = 29;
    } this.calendarData = []
    const myFirstDay = new Date(this.year, this.month - 1, 1);
    let countDay = 1;
    for (let k = 0; k < 6; k++) {
      this.calendarData.push({ cols: [] });
      for (let i = 0; i < 7; i++) {
        if (k === 0) {
          if (i < myFirstDay.getDay()) {
            this.calendarData[0]['cols'].push(0);
          } else {
            this.calendarData[0]['cols'].push(countDay);
            countDay++;
          }
        } else {
          if (countDay <= numOfDays) {
            this.calendarData[k]['cols'].push(countDay);
            countDay++;
          } else {
            this.calendarData[k]['cols'].push(0);
          }
        }
      }
    }
  }

  /* méthode pour vérifier si la date est dispo */
  isValidateDate(_counter) {
    if (this.filterDateList.length === 0) {
      return false
    }
    //  debugger;
    return this.disponilities(moment(new Date(this.year, this.month - 1, _counter)));
  }

  /* methode pour appliquer le filtre des dipos sur le calendar */
  disponilities = (d): boolean => {
    let isValid = false;
    if (d && d.date() && d.month() !== undefined) {
      for (const filter of this.filterDateList) {
        const dateFilter = moment(filter);
        if (dateFilter && dateFilter.date() && dateFilter.month() !== undefined) {
          if (dateFilter.date() === d.date() && dateFilter.month() === d.month()) {
            isValid = true
          }
        }
      }
    }
    return isValid
  }

  /* affichage du mois precedent */
  setPreviousMonth() {
    // this.myStart = null;
    // this.myEnd = null;
    if (this.month === 1) {
      this.month = 12;
      this.year--;
    } else {
      this.month--;
    }
    this.getTeacherDisponibilities();
    // if (this.myTeacher) {
    //   this.getTeacherDisponibilities();
    // } else {
    //   this.setCalendarData();
    //}
  }

  /* affichage du mois suivant */
  setNextMonth() {
    if (this.month === 12) {
      this.month = 1;
      this.year++;
    } else {
      this.month++;
    }
    this.getTeacherDisponibilities();
    // if (this.myTeacher) {
    //   this.getTeacherDisponibilities();
    // } else {
    //   this.setCalendarData();
    //}
  }

  /* Methode pour récupérer les heures disponibles pour un prof */
  getTeacherDisponibilities() {
    this.selectedDay = null;
    this.updateAvailables.emit({ month: this.month, year: this.year });
  }

  /**
   * Methode pour definir les jours selectionnables sur le calendrier
   * en fonction des dispos du prof au jour et/ou heures selectionnées
   * @param obj
   */
  private setFilterListToCalendar(obj) {
    // #1 - end & start ont le même jour de dispo (meme fuseau horaire)
    if (moment(obj['start']).date() === moment(obj['day']).date()) {

      // On check la coherence des données, si le start est bien avant le end
      if (moment(obj['end']).isAfter(moment(obj['start']))) {
        const _startJ0 = moment([moment(this.myStart.datetime).year(), moment(this.myStart.datetime).month(),
        moment(this.myStart.datetime).date(), moment(obj['start']).hour(), moment(obj['start']).minute(), 0, 0]);
        let _endJ0 = moment([moment(this.myStart.datetime).year(), moment(this.myStart.datetime).month(),
        moment(this.myStart.datetime).date(), moment(obj['end']).hour(), moment(obj['end']).minute(), 0, 0]);
        if (_endJ0.format('HH:mm') === '00:00') {
          _endJ0 = moment([moment(this.myStart.datetime).year(), moment(this.myStart.datetime).month(),
          moment(this.myStart.datetime).date(), 23, 59, 59, 0]);
        }
        if (this.checkIfSelectIsAvailable(_startJ0, _endJ0)) {
          this.addToFilterList(moment(obj['day']))
        }
      }
    }

  }

  addToFilterList(myday) {
    if (myday) {
      if (this.filterDay) {
        if (moment(myday).day() === this.filterDay.day()) {
          this.filterDateList.push(moment(myday));
        }
      } else {
        this.filterDateList.push(moment(myday));
      }
    }
  }

  checkIfSelectIsAvailable(_start, _end) {
    let myEnd = moment(this.myEnd.datetime)
    if (myEnd.format('HH:mm') === '00:00') {
      myEnd = moment([moment(this.myStart.datetime).year(), moment(this.myStart.datetime).month(),
      moment(this.myStart.datetime).date(), 23, 59, 59, 0]);
    }
    if (this.myStart.datetime.isSameOrAfter(_start) && myEnd.isSameOrBefore(_end)) {
      return true
    }
    return false
  }

  /* output du jour selectionnée vers le component parent */
  selectDay(_counter) {
    this.selectedDay = _counter
    const myMonth = this.month - 1;
    const myDate = new Date(this.year, myMonth, _counter);
    this.updateDateSelect.emit(myDate);
  }

  getCurrentLanguage() {
    return this.planService.getCurrentLanguage();
  }

}
