<ng-container *ngIf="formation">
  <div class="report-wrapper">
    <!-- Header student identity -->
    <div class="report-header">
      <div class="report-icon"></div>
      <div class="avatar-block mobile-nodisplay">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="formation.student.user.photo_profile === null">
            <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" *ngIf="formation.student.user.photo_profile !== null">
      </div>
      <div class="user-label">
        <div><b translate>{{translatePath}}.report-title</b> : {{pending.student_first_name}} {{pending.student_last_name}}</div>
        <div>
            {{pending.start | amLocale: 'en' | amDateFormat:'ddd DD MMM, YYYY'}}  
            {{pending.start | amLocale: 'en' | amDateFormat:'HH:mm'}} - {{pending.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
        </div>
      </div>
    </div>
    <!-- Evaluation Explanation -->
    <div class="pastrating-wrapper">
      <ng-container *ngIf="!existingReport; else elseExistingReport">
        <div class="nopastrating-copy">
          <span>{{pending.student_first_name}}</span>
          <span *ngIf="myLanguage">{{myLanguage.label}}</span>
          <span translate>{{translatePath}}.no-evaluation-explanation</span>
          <i *ngIf="selfEvaluated">"<span translate>{{translatePath}}.{{selfEvaluated}}</span>"</i>
        </div>
      </ng-container>
      <ng-template #elseExistingReport>
        <div class="pastrating-copy">
          <span>{{pending.student_first_name}}</span>
          <span *ngIf="myLanguage">{{myLanguage.label}}</span>
          <span translate>{{translatePath}}.evaluation-explanation</span>
          <span>(on {{existingReport_date | amLocale: 'en' | amDateFormat:'DD, MMM YYYY'}} )</span>
        </div>
        <div class="pastrating-data" *ngFor="let subject of existingReport">
            <app-level [editMode]=false [subject]="subject"></app-level>
        </div>
      </ng-template>
    </div>

    <!-- Levels -->
    <div class="info-row">
        <b>
          <span translate>{{translatePath}}.rate-level-label-start</span>
          <span>{{pending.student_first_name}}</span>'s 
          <span *ngIf="myLanguage">{{myLanguage.label}}</span> 
          <span translate>{{translatePath}}.rate-level-label-end</span>
        </b>
      <!-- INFOS bloc -->

        <a (click)="showInfo = true">
          <i class="material-icons helpicon">help</i>
        </a>
        <button class="action-btn suboption reset-btn" type="submit" [disabled]="!levelFormChange" (click)="initLevelForm()">
            <span translate>{{translatePath}}.reset-btn</span>
        </button>
        <div class="popup-overlay" *ngIf="showInfo"></div>
        <ng-container *ngIf="showInfo">
          <div class="border-info" >
            <i class="material-icons help-closeicon" (click)="showInfo = false">close</i>
            <div class="summary">
              [Information a propos du baremene cecrl]
            </div>
          </div>
        </ng-container>

    </div>

      <ng-container *ngIf="showLevel; else elseLoadLevel">
        <div *ngFor="let subject of subjects">
          <app-level [editMode]=true [subject]="subject" [newValue]=10 [isFirst]=true (updateLevel)="handleSubject($event)"></app-level>
        </div>
      </ng-container>
      <!-- <ng-template #elseLoadLevel>
        <div class="row center loading pageload-message">
          <b translate>{{translatePath}}.level-loading</b>
        </div>
      </ng-template> -->

    <div class="report-buttons-row">
        <button class="action-btn suboption" (click)="completeLater()">
            <span translate>{{translatePath}}.complete-later-btn</span>
        </button>
        <button class="action-btn" (click)="register('completed')" [disabled]="!levelForm.valid">
          <span translate>{{translatePath}}.send-btn</span>
        </button>
    </div>
  </div>
</ng-container>
