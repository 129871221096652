import { HttpParams } from '@angular/common/http';
/**
 * composant pour l'affichage des détails d'une formation.
 * avec des sous-composants par items.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FormationService } from '../../../../service/formation.service';
import { ConversationService } from '../../../../service/conversation.service';
import { AuthenticationService } from '../../../../service/authentification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  translatePath = 'teacher.trainings';
  defaultAvatar = environment.static + '/avatar.png';
  formation_id: any;
  formation: any;
  student_id: any;
  myUser: any;
  conversation_id: any;
  callServiceFormationId = null;
  constructor(private formationService: FormationService,
    private conversationService: ConversationService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
          this.route.paramMap.subscribe(param => {
            this.formation_id = this.route.snapshot.params.id_formation;
            this.callServiceFormationId = this.formationService.getObservableFormationById(this.formation_id)
              .subscribe(_formation => {
                this.formation = _formation;
                this.student_id = this.formation.student.id;
                let params = new HttpParams();
                params = params.append('teacher', this.myUser.id)
                params = params.append('student', this.student_id)
                this.conversationService.getConversations(params)
                  .subscribe(_conversation => {
                    if (_conversation['results'].length > 0) {
                      this.conversation_id = _conversation['results'][0]['id'];
                    }
                  })
              })
          })
        }
      })
  }

  ngOnDestroy() {
    if (this.callServiceFormationId) {
      this.callServiceFormationId.unsubscribe();
    }
  }

}
