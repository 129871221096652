export class Site {
  url: string;
  name: string;
  domaine: string;
  available_formation: boolean;
  constructor(url: string, name: string, domaine: string) {
    this.url = url;
    this.name = name;
    this.domaine = domaine;
  }
}
