import { ProfilePhotosService } from './../../../../../../service/profilePhotos.service';
import { Teacher } from './../../../../../../model/teacher.model';
import { Occurrence } from './../../../../../../model/occurrence.model';
import { RatingStudent } from './../../../../../../model/rating-student.model';
import { ReportService } from './../../../report.service';
import { ExerciceSheetService } from './../../../../../../service/exerciceSheet.service';
import { Formation } from './../../../../../../model/formation.model';
import { Session } from './../../../../../../model/session.model';
/**
 * Composant editer et valider la creation d'un rapport.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PendingService } from '../../../../../../service/pending.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SessionService } from '../../../../../../service/session.service';
import { RateStudentService } from '../../../../../../service/rate-student.service';
import { OccurrenceService } from '../../../../../../service/occurrence.service';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { AuthenticationService } from '../../../../../../service/authentification.service';

@Component({
  selector: 'app-valid-session',
  templateUrl: './valid-session.component.html',
  styleUrls: ['./valid-session.component.scss']
})
export class ValidSessionComponent implements OnInit {
  @Input() pending: Occurrence;
  @Input() formation: Formation;
  @Input() lessonTakePlace;
  myUser: Teacher;
  nextOccurrence: Occurrence;
  sheetPermission = false;
  isSheetLanguageAvailable = false;
  loadedSession = false;
  loadedNextSession = false;
  loadedRating = false;
  saveLoading = false;
  cecrl_LEVEL = []
  percentList = [0, 25, 50, 75, 100]
  lastReport: RatingStudent = null;
  translatePath = 'teacher.reports';
  feedbackCtrl = new FormControl('', [Validators.required, Validators.minLength(100)]);
  nextTimeMessageCtrl = new FormControl();
  grammarLevelCtrl = new FormControl('', [Validators.required]);
  grammarPercentCtrl = new FormControl('', [Validators.required]);
  expressionLevelCtrl = new FormControl('', [Validators.required]);
  expressionPercentCtrl = new FormControl('', [Validators.required]);
  comprehensionLevelCtrl = new FormControl('', [Validators.required]);
  comprehensionPercentCtrl = new FormControl('', [Validators.required]);
  vocabularyLevelCtrl = new FormControl('', [Validators.required]);
  vocabularyPercentCtrl = new FormControl('', [Validators.required]);
  accentLevelCtrl = new FormControl('', [Validators.required]);
  accentPercentCtrl = new FormControl('', [Validators.required]);
  oralLevelCtrl = new FormControl('', [Validators.required]);
  writtenLevelCtrl = new FormControl('', [Validators.required]);
  reportForm: FormGroup = this.builder.group({
    feedback: this.feedbackCtrl,
    nextTime: this.nextTimeMessageCtrl,
    grammarLevel: this.grammarLevelCtrl,
    grammarPercent: this.grammarPercentCtrl,
    expressionLevel: this.expressionLevelCtrl,
    expressionPercent: this.expressionPercentCtrl,
    comprehensionLevel: this.comprehensionLevelCtrl,
    comprehensionPercent: this.comprehensionPercentCtrl,
    vocabularyLevel: this.vocabularyLevelCtrl,
    vocabularyPercent: this.vocabularyPercentCtrl,
    accentLevel: this.accentLevelCtrl,
    accentPercent: this.accentPercentCtrl,
    oralLevel: this.oralLevelCtrl,
    writtenLevel: this.writtenLevelCtrl,
  });

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private sessionService: SessionService,
    private ratingStudentService: RateStudentService,
    private occurrenceService: OccurrenceService,
    private exerciceSheetService: ExerciceSheetService,
    private builder: FormBuilder,
    private profilePhotosService: ProfilePhotosService,
  ) { }

  ngOnInit() {
    this.cecrl_LEVEL = this.exerciceSheetService.getcecrlLevel()
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.checkSheetsPermission();
          this.myUser = user;
          this.checkAvailableLanguageSheets();
          this.getOrCreateSession();
          this.getNextOccurrence()
          this.getLastReport();
        }
      }
    )
  }

  getOrCreateSession() {
    if (this.pending.session) {
      this.feedbackCtrl.setValue(this.pending.session.message);
      this.loadedSession = true;
    } else {
      const body = {
        formation: this.formation.url,
        teacher: this.myUser.url,
        occurrence: this.pending.url
      }
      // on crée la session manquante
      this.sessionService.postNewSession(this.formation.url, body)
        .subscribe(session => {
          this.pending.session = session;
          this.loadedSession = true;
        })
    }
  }

  getNextOccurrence() {
    let params = new HttpParams();
    params = params.append('formation', this.formation.id.toString());
    params = params.append('teacher', this.myUser.id.toString());
    params = params.append('upcoming', 'true');
    // On recherche les prochaines occurences pour cette formation et ce professuer
    this.occurrenceService.getObservableOccurences(params)
      .subscribe(_occurrence => {
        if (_occurrence['count'] > 0) {
          if (_occurrence['results'][0]) {
            this.nextOccurrence = _occurrence['results'][0]
            this.nextTimeMessageCtrl = new FormControl('', [Validators.required, Validators.minLength(100)]);
            this.checkSessionForNextOccurrence()
          }
        } else {
          this.loadedNextSession = true;
        }
      })
  }


  checkSessionForNextOccurrence() {
    // on check si une session existe pour la prochaine occurrence
    if (!this.nextOccurrence.session) {
      const body = {
        formation: this.formation.url,
        teacher: this.myUser.url,
        occurrence: this.nextOccurrence.url
      }
      // on crée la session manquante
      this.sessionService.postNewSession(this.formation.url, body)
        .subscribe(session => {
          this.nextOccurrence.session = session;
          this.loadedNextSession = true;
        })
    } else {
      this.nextTimeMessageCtrl.setValue(this.nextOccurrence.session.message);
      this.loadedNextSession = true
    }
  }



  normalizePercentValue(value) {
    const _percentList = this.percentList.map(x => x); // clone percent list
    _percentList.sort(function (a, b) {
      return b - a;
    });
    let percentVal = value;
    _percentList.forEach(percent => {
      if (value <= percent) {
        percentVal = percent;
      }
    });
    return percentVal;
  }

  loadReportData() {
    if (this.lastReport) {
      this.grammarLevelCtrl.setValue(this.lastReport.level_grammar);
      this.grammarPercentCtrl.setValue(this.normalizePercentValue(this.lastReport.grammar));
      this.expressionLevelCtrl.setValue(this.lastReport.level_expression);
      this.expressionPercentCtrl.setValue(this.normalizePercentValue(this.lastReport.expression));
      this.comprehensionLevelCtrl.setValue(this.lastReport.level_comprehension);
      this.comprehensionPercentCtrl.setValue(this.normalizePercentValue(this.lastReport.comprehension));
      this.vocabularyLevelCtrl.setValue(this.lastReport.level_vocabulary);
      this.vocabularyPercentCtrl.setValue(this.normalizePercentValue(this.lastReport.vocabulary));
      this.accentLevelCtrl.setValue(this.lastReport.level_accent);
      this.accentPercentCtrl.setValue(this.normalizePercentValue(this.lastReport.accent));
      this.oralLevelCtrl.setValue(this.lastReport.level_oral);
      this.writtenLevelCtrl.setValue(this.lastReport.level_written);
    }
    this.loadedRating = true;
  }

  getLastReport() {
    if (this.formation) {
      let params = new HttpParams();
      params = params.append('status', 'completed');
      params = params.append('session__formation__student', this.formation.student.id.toString());
      params = params.append('session__formation__language', this.formation.language_detail.id.toString());
      params = params.append('ordering', '-session__occurrence__start');
      params = params.append('page_size', '1');
      this.ratingStudentService.getRatingStudents(params).subscribe(rates => {
        if (rates['results'].length > 0) {
          this.lastReport = rates['results'][0];
          this.loadReportData();
        } else {
          this.loadedRating = true;
        }
      });
    }
  }

  save() {
    this.saveLoading = true;
    this.occurrenceService.patchObservableOccurrence(this.pending.url, { status: 'done' }).subscribe(occurrence => {
      this.postRating();
    });
    // sauvegarde du message de feedback de cours
    this.sessionService.patchSession(this.pending.session.url, { message: this.feedbackCtrl.value })
    /** save next session message */
    if (this.nextOccurrence) {
      this.sessionService.patchSession(this.nextOccurrence.session.url, { message: this.nextTimeMessageCtrl.value })
    }

  }

  postRating() {
    const _param = {
      message: this.feedbackCtrl.value,
      session: this.pending.session['url'],
      level_grammar: this.grammarLevelCtrl.value,
      grammar: this.grammarPercentCtrl.value,
      level_expression: this.expressionLevelCtrl.value,
      expression: this.expressionPercentCtrl.value,
      level_comprehension: this.comprehensionLevelCtrl.value,
      comprehension: this.comprehensionPercentCtrl.value,
      level_vocabulary: this.vocabularyLevelCtrl.value,
      vocabulary: this.vocabularyPercentCtrl.value,
      level_accent: this.accentLevelCtrl.value,
      accent: this.accentPercentCtrl.value,
      level_oral: this.oralLevelCtrl.value,
      level_written: this.writtenLevelCtrl.value,
      status: 'completed',
    };
    if (!this.pending.session.rate_teacher) {
      this.ratingStudentService.postRatingStudent(_param)
        .subscribe(_ratingStudent => {
          this.sessionService.toastService.show('Report completed !', 4000, 'green');
          this.saveLoading = true;
          this.goToReportList();
        })
    } else {
      this.ratingStudentService.patchRatingStudent(this.pending.session.rate_teacher.url, _param)
        .then(_ratingStudent => {
          this.sessionService.toastService.show('Report completed !', 4000, 'green');
          this.saveLoading = true;
          this.goToReportList();
        })
    }
  }

  goToReportList() {
    this.router.navigate(['reports']);

  }

  checkAvailableLanguageSheets() {
    this.isSheetLanguageAvailable = this.authenticationService.checkAvailableLanguageSheets(this.formation.language_detail.id);
  }

  checkSheetsPermission() {
    this.sheetPermission = this.authenticationService.checkFeaturePermission('sheets');
  }
}
