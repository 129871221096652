<!-- Mode Lecture -->
<ng-container *ngIf="!editMode; else elseEditMode">
  <div class="viewrating-row">
    <b class="criteria-label">{{subject.label}}:</b>
    <div class="viewlevel-mainwrapper">
      <div class="viewlevel-label">{{subject.level.start_label}}</div>
      <div class="viewlevel-levelbarwrapper">
        <div class="levelbar-background">
            <div class="levelbar-empty">
              <div class="levelbar-current" [ngClass]="styleGauge()" [style.width.%]="getGaugeWidth()" [style.height.%]="100"><span><b>{{gaugeValue()}}%</b></span></div> 
              <div class="levelbar-progress" *ngIf="subject.level.value > initValue" [style.width.%]="subject.level.value - initValue"></div>
              <div class="levelbar-regress" *ngIf="initValue > subject.level.value" [style.width.%]="initValue - subject.level.value"></div>
            </div>
        </div>
      </div>
      <div class="viewlevel-label">{{subject.level.end_label}}</div>
    </div>
  </div>
</ng-container>

<!-- Mode Edition -->
<ng-template #elseEditMode>
  <div class="rating-row">
    <b class="criteria-label">{{subject.label}}:</b>
    <ng-container *ngIf="!showGauge; else elseGauge">
      <div class="level-button" *ngFor="let level of levels">
          <button (click)="selectLevel(level)">
          <b>{{level.label}}</b>
        </button>
      </div>
    </ng-container>
    <ng-template #elseGauge>
      <div class="editlevel-mainwrapper">
        <div class="editlevel-label">{{subject.level.start_label}}</div>
        <div class="editlevel-tweakbtn">
          <button (click)="subjectLevelDown()" [disabled]="subject.level.value === 0"><b>-</b></button>
        </div>
        <div class="editlevel-levelbarwrapper">

          <ng-container *ngIf="isFirst; else elseFirst">
            <div class="levelbar-background">
              <div class="levelbar-empty">
                <div class="levelbar-full" [style.width.%]="subject.level.value" [style.height.%]="100"><span><b>{{gaugeValue()}}%</b></span></div> 
              </div>
            </div>
          </ng-container>
          <ng-template #elseFirst>            
            <div class="levelbar-background">
              <div class="levelbar-empty">
                <div class="levelbar-current" [ngClass]="styleGauge()" [style.width.%]="getGaugeWidth()" [style.height.%]="100"><span><b>{{gaugeValue()}}%</b></span></div> 
                <div class="levelbar-progress" *ngIf="subject.level.value > initValue" [style.width.%]="subject.level.value - initValue"></div>
                <div class="levelbar-regress" *ngIf="initValue > subject.level.value" [style.width.%]="initValue - subject.level.value"></div>
              </div>
            </div>
          </ng-template>
          
        </div>
        <div class="editlevel-tweakbtn">
          <button (click)="subjectLevelUp()" [disabled]="subject.level.value === 100"><b>+</b></button>
        </div>
        <div class="editlevel-label">{{subject.level.end_label}}</div>
      </div>
    </ng-template>
  </div>
</ng-template>
