<div class="center" *ngIf="planningLoader && !errorPlanning">
  <b translate>{{translatePath}}.load-planning</b>
</div>

<div class="container usual-container" *ngIf="newFormation && !planningLoader">
  <div class="decorative-border">
    <ng-container *ngIf="!errorPlanning; else elseErrorPlanning">   
      <div class="step-title" translate>
        {{translatePath}}.formation-planning
      </div>
      <div class="row" translate>
        {{translatePath}}.next-session
      </div>
      <div class="timeslots-wrapper">
        <div *ngFor="let event of newFormation.events; let i = index">
          <ng-container *ngIf="!event.ghost">            
            <div class="timeslot-row" *ngIf="i === 0; else elseEvent">
              Tous les {{event.start | amLocale: getCurrentLanguage() | amDateFormat:'dddd'}}s de {{event.start | amLocale: getCurrentLanguage() | amDateFormat:'HH:mm'}} à {{event.end | amLocale: getCurrentLanguage() | amDateFormat:'HH:mm'}}
            </div>
            <ng-template #elseEvent>
              <div class="timeslot-row">
              Et les {{event.start | amLocale: getCurrentLanguage() | amDateFormat:'dddd'}}s de {{event.start | amLocale: getCurrentLanguage() | amDateFormat:'HH:mm'}} à {{event.end | amLocale: getCurrentLanguage() | amDateFormat:'HH:mm'}}
              </div>
            </ng-template>
          </ng-container> 
        </div>
      </div>
      <div class="duration-row">
        Du {{newFormation.validity_period_from | amLocale: getCurrentLanguage() | amDateFormat:'DD MMM YYYY'}}
        au {{newFormation.validity_period_to | amLocale: getCurrentLanguage() | amDateFormat:'DD MMM YYYY'}}
      </div>
      <div class="dualbtn-wrapper">
        <button class="waves-effect waves-light back-btn"
          (click)="goBack()">
          <b translate>{{translatePath}}.back-button</b>
        </button>
        <button class="waves-effect waves-light confirm-btn"
          (click)="validPlanning()">
          <b translate>{{translatePath}}.valid-planning</b>
        </button>
      </div>
    </ng-container>
    <ng-template #elseErrorPlanning>
      <div class="center">
        <b translate>{{translatePath}}.failure-planning</b>
      </div>
      <div class="center">
        <p translate>{{detailErrorPlanning}}</p>
      </div>
      <div class="center">
        <button class="waves-effect waves-light back-btn"
          (click)="goBack()">
          <b translate>{{translatePath}}.back-button</b>
        </button>
      </div>
    </ng-template>
  </div>
</div>
