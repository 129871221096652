<div class="row"></div>
<div class="container" *ngIf="!showInvoice">
  <div class="row center valign-wrapper">
    Chargement de la page d'information d'une facture ...
  </div>
</div>
<div class="" *ngIf="teacher">
  <div class="row invoice-header" (click)="showDetail(teacher.id)">
    <div class="col l5 left">
      <div class="row valign-wrapper">
        <div class="header-avatar-block">
          <img class="user-avatar" src="{{teacher.user.photo_profile.thumbnail}}" *ngIf="teacher.user.photo_profile !==null; else elseStudentAvatar1" (error)="this.formationService.errorImgHandler($event)">
          <ng-template #elseStudentAvatar1>
            <img class="user-avatar" src="{{defaultAvatar}}">
          </ng-template>
        </div>
        <div class="header-data">
          <div class="left">
            <h6 class="teacher-name">{{teacher.user.first_name}} {{teacher.user.last_name}} pour {{invoice.clean_date}}</h6>
            <h6 class="currentID">ref : {{invoice.id}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- Left panel -->
    <div class="col l4">
      <div class="information-container">
        <div class="row">
          <div class="left">
            <h6 class="center grey darken-1 white-text title-information">Facture</h6>
          </div>
        </div>
        <div class="row valign-wrapper breathing-row breathing-row">
          <div class="col l6 left">
            Statut :
          </div>
          <div class="col l6 left">
            <mat-select [placeholder]="'Choisir'" [(ngModel)]="invoice.status" (ngModelChange)="updateInvoice(invoice, 'status')">
              <mat-option *ngFor="let status of statusList" [value]="status.code" class="left circle grey-text ">{{status.label}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row valign-wrapper breathing-row form-row">
          <div class="col l3 left">
            Montant :
          </div>
          <div class="col l2 left">
            <input type="text" [(ngModel)]="invoice.amount" class="filter-col left textfield" (focusout)="updateInvoice(invoice, 'amount')">
          </div>
          <div class="col l7 center">
            <button class="action-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="recalculatation()"
              [disabled]="recalculate">
              Recalculer
            </button>
          </div>
        </div>
        <ng-container *ngIf="invoice.invoice; else elseInvoice">
          <div class="row valign-wrapper breathing-row form-row">
            <div class="col l3 left">
              Facture :
            </div>
            <div class="col l2">
              <i class="material-icons blue-text text-lighten-1 file-icon left">description</i>
            </div>
            <ng-container *ngIf="!loadUpload; else elseUpload">
              <div class="col l3 center">
                <button class="action-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="open.click()" [disabled]="invoice.status === 'pending'">
                  Ouvrir
                </button>
                <a #open [hidden]="true" appDownloadLingueo [invoice]="invoice" [isInvoice]=true></a>
              </div>
              <div class="col l3 center">
                <button class="action-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="changeInvoices.click()">
                  <ng-container *ngIf="invoice.status === 'pending'; else elseChange">
                    Upload
                  </ng-container>
                  <ng-template #elseChange>
                    Changer
                  </ng-template>
                </button>
                <input #changeInvoices type="file" (change)="uploadInvoice($event)" [hidden]="true" />
              </div>
            </ng-container>
            <ng-template #elseUpload>
              <div class="col l6 center">
                <i>Uploading</i>
              </div>
            </ng-template>
          </div>
          <div class="row breathing-row form-row">
            <div class="right">Uploadée le {{invoice.uploaded_at | amDateFormat:'DD, MMM, YYYY à HH:mm'}}</div>
          </div>
        </ng-container>
        <ng-template #elseInvoice>
          <div class="row valign-wrapper breathing-row form-row">
            <div class="col l3 left">
              Facture :
            </div>
            <div class="col l2 center">
              <i class="material-icons grey-text text-lighten-1 file-icon">description</i>
            </div>
            <div class="col l7 center">
              <button class="action-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="uploadInvoices.click()">
                Uploader
              </button>
              <input #uploadInvoices type="file" (change)="uploadInvoice($event)" [hidden]="true" />
            </div>
          </div>
        </ng-template>
        <div class="row breathing-row form-row">
          <div class="divider separate"></div>
        </div>
        <div class="row valign-wrapper breathing-row form-row">
          <div class="col l3 left">
            Paiement :
          </div>
          <div class="col l8 left">
            {{invoice.payment_method}}
          </div>
        </div>

        <!-- PAYPAL INFOS -->
        <ng-container *ngIf="invoice.payment_method === 'paypal'">

          <div class="row valign-wrapper">
            <div class="col l4 left">
              Numéro fiscal :
            </div>
            <div class="col l8 left">
              {{teacher.tax_status_number}}
            </div>
          </div>
          <div class="row valign-wrapper">
            <div class="col l3 left">
              Email Paypal :
            </div>
            <div class="col l6 left">
              {{teacher.paypal_email}}
            </div>
            <div class="col l3 right">
              <button class="action-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="copyEmail(teacher.paypal_email)">
                Copier
              </button>
            </div>
          </div>
        </ng-container>

      </div>
    </div>

    <div class="col l8">
      <div class="row information-container prestation-container">
        <div class="left">
          <h6 class="center grey darken-1 white-text title-information">Prestation</h6>
        </div>
        <ng-container *ngIf="showDataInvoice; else elseDataInvoice">
          <div class="hourly_volume">
            Volume horaire total : {{hourly_volume}}
          </div>
          <div id="table-invoices" class="row datatable-position">
            <app-datatable class="col l12" [data]="dataInvoices['data']" [header]="dataInvoices['headers']" [pageSize]="pageSize" [totalData]="totalData"
              [component]=this [updateMethod]=this.getData>
            </app-datatable>
          </div>
        </ng-container>
        <ng-template #elseDataInvoice>
          <div class="row">
            <ng-container *ngIf="isLoading; else elseLoading">
              <div class="col l12 center">
                Pas de prestations trouvées
              </div>
            </ng-container>
            <ng-template #elseLoading>
              <div class="col l12 center">
                Chargement des prestations...
              </div>
            </ng-template>

          </div>
        </ng-template>
      </div>
    </div>

  </div>

</div>