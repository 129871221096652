import { registerLocaleData } from '@angular/common';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import localeFr from '@angular/common/locales/fr';
import { Formation } from '../../../../model/formation.model';
import { FormationService } from '../../../../service/formation.service';
import { Router } from '@angular/router';
import { FormationList } from '../../../../model/formationList.model';
import { HttpParams } from '@angular/common/http';

registerLocaleData(localeFr);
@Component({
  selector: 'app-export-formation',
  templateUrl: './export-formation.component.html',
  styleUrls: ['./export-formation.component.scss', '../mainscreen.ux.scss']
})
export class ExportCdcCertificationComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'select',
    'export_status',
    'id',
    'status',
    'student__user__last_name',
    'student__user__email',
    'name',
    'site__name',
  ];
  formations: Formation[] = [];
  formationsStatus = this.foramtionService.getStatus();
  formationsExportStatus = this.foramtionService.getExportStatus();
  resultsLength = 0;
  isLoadingResults = true;
  selection = new SelectionModel(true, []);
  modeAllPage = false;
  allIds;
  page_size = 10;

  filterFormGroup = new FormGroup({
    status_export_certificat_cdc: new FormControl(),
    id: new FormControl(),
    status: new FormControl(),
    student_name: new FormControl(),
    student__user__email__icontains: new FormControl(),
    name__icontains: new FormControl(),
    site__name__icontains: new FormControl(),
  });

  bulkActionFormGroup = new FormGroup({
    statusBulkUpdate: new FormControl(),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public dialog: MatDialog,
    private foramtionService: FormationService,
    private router: Router,
  ) { }


  ngAfterViewInit() {
    this.selection.clear();
    if (localStorage.getItem('exportFormation')) {
      this.filterFormGroup.setValue(JSON.parse(localStorage.getItem('exportFormation')));
    }
    this.getFormations();
    this.filterFormGroup.valueChanges.debounceTime(1000).subscribe(
      () => {
        localStorage.setItem('exportFormation', JSON.stringify(this.filterFormGroup.value));
        this.paginator.pageIndex = 0;
        this.getFormations();
      }
    )
    this.sort.sortChange.debounceTime(1000).subscribe(
      () => {
        localStorage.setItem('exportFormation', JSON.stringify(this.filterFormGroup.value));
        this.paginator.pageIndex = 0;
        this.getFormations();
      }
    )
    this.paginator.page.debounceTime(1000).subscribe(
      () => {
        localStorage.setItem('exportFormation', JSON.stringify(this.filterFormGroup.value));
        this.getFormations();
      }
    )
  }

  getFormations() {

    let params = new HttpParams();
    Object.keys(this.filterFormGroup.controls).forEach(key => {
      if (this.filterFormGroup.get(key).value)
        params = params.append(key, this.filterFormGroup.get(key).value);
    });
    if (this.paginator.pageIndex > 0) {
      params = params.append('page', (this.paginator.pageIndex + 1).toString());
    }
    params = params.append('salt', Math.random().toString());
    params = params.append('page_size', this.paginator.pageSize.toString());
    params = params.append('ordering', this.sort.direction === 'desc' ? '-' + this.sort.active : this.sort.active);
    this.foramtionService.getObservableFormations(params).subscribe(
      (formations: any) => {
        this.isLoadingResults = false;
        this.modeAllPage = false;
        this.resultsLength = formations.count;
        this.formations = formations.results;
        this.allIds = formations.allids;
      });
  }

  clearFilter() {
    this.filterFormGroup.reset();
    localStorage.removeItem('exportFormations')
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isPageSelected() {
    let pageSelected = true;
    this.formations.forEach(formation => {
      if (!this.selection.isSelected(formation.id)) {
        pageSelected = false;
      }
    })
    return pageSelected;
  }

  clearPage() {
    this.formations.forEach(formation => {
      if (this.selection.isSelected(formation.id)) {
        this.selection.toggle(formation.id);
      }
    })
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isPageSelected() ?
      this.clearPage() :
      this.formations.forEach(formation => this.selection.select(formation.id));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isPageSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected((row.id)) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  selectAllPage() {
    this.modeAllPage = !this.modeAllPage;
    if (this.modeAllPage) {
      this.allIds.forEach(id => this.selection.select(id));
    } else {
      this.selection.clear()
    }
  }

  getSelectedFormations() {
    return this.selection.selected.map(id => id);
  }

  export() {
    if (this.getSelectedFormations()) {
      const params = {
        selected_formations: this.getSelectedFormations(),
      };
      this.foramtionService.postExport(params).subscribe(() => {
        this.router.navigate(['export/cdc/list'])
      })
    }
  }
}
