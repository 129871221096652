<ng-container *ngIf="formation; else elseTemplate">
  <div class="traindetail-wrapper">
    <!-- Header -->
    <div class="traindetail-header">
        <i class="material-icons back-btn" routerLink="../../">arrow_back</i>
        <div class="avatar-block">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="formation.student.user.photo_profile === null">
            <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" *ngIf="formation.student.user.photo_profile !== null">
        </div>
        <div class="training-label">
           <div class="desktop-trainingtitle" translate>teacher.traingings.detail</div>
           <span class="student-name">{{formation.student.user.first_name}} {{formation.student.user.last_name}}</span><span class="training-name">({{formation.name}})</span> <span class="training-suspended" *ngIf="formation.status=='suspended'"> - Suspended</span>
        </div>
    </div>

    <!-- Main -->
    <div class="traindetail-mainwrapper">
      <router-outlet></router-outlet>
    </div>

    <!-- Nav -->
    <div class="train-tab-bar nav-position">
        <a class="training-tab" routerLink="./info" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}" translate>
          {{translatePath}}.general-info
        </a>
        <a class="training-tab" routerLink="./next" routerLinkActive="router-link-active"[routerLinkActiveOptions]="{exact: true}" translate>
          {{translatePath}}.next-classes
        </a>
        <a class="training-tab" routerLink="./past" routerLinkActive="router-link-active"[routerLinkActiveOptions]="{exact: true}" translate>
          {{translatePath}}.past-classes
        </a>
        <a *ngIf="conversation_id && student_id" class="training-tab" routerLink="./conversation/{{conversation_id}}/student/{{student_id}}" routerLinkActive="router-link-active"[routerLinkActiveOptions]="{exact: true}" translate>
          {{translatePath}}.messages
        </a>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center loading pageload-message">
    <b translate>{{translatePath}}.detail-loading</b>
  </div>
</ng-template>
