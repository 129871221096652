import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, DoCheck } from '@angular/core';
// import 'angular-calendar/css/angular-calendar.css';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
// models
import { Teacher } from '../../../../../model/teacher.model';
import { Formation } from '../../../../../model/formation.model';
import { TimePicker } from '../../../../../model/timepicker.model';
// services
import { PlanService } from '../../../../../service/plan.service';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { FormationService } from '../../../../../service/formation.service';

// Objet local
export class PonctualBooking {
  start: TimePicker;
  end: TimePicker;
  date: Date;
  teacher: Teacher;
  constructor() { }
}
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss', './booking.ux.scss', '../formation.ux.scss', '../../mainscreen.ux.scss'],
  providers: [PlanService]
})
export class BookingComponent implements OnInit, DoCheck {
  @Input() teachers: Teacher[];
  @Input() formation: Formation;
  @Input() cacheLoader: false;
  // Availabilities
  availablesDays: any[] = [];
  availableDay;
  // events to post
  booking: PonctualBooking;
  listNewEvents: any[];
  bookingIsValid = false;
  // count remaining
  countRemaining = 0;
  countDuration = 0;
  warningMessage = 'Pas assez d’heures restantes';
  // loaders
  loaderWritePlanning: boolean;
  loadDisponibilities: boolean;
  // TimePicker
  myStart: any;
  myEnd: any;
  // Date pickers
  dateSelect: any;
  filterDateList: any[];
  current_day: any
  month_select = moment().month() + 1;
  year_select = moment().year();
  @Output() handleUpdateFormation = new EventEmitter<any>();

  constructor(
    private planService: PlanService,
    private formationService: FormationService,
    private kiwixiGlobals: KiwixiGlobals,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.initComponent();
  }

  public ngDoCheck(): void {
    this.cdRef.detectChanges();
  }

  // initialisation des variables du composant.
  initComponent() {
    this.booking = new PonctualBooking();
    this.booking.teacher = null;
    this.filterDateList = [];
    this.listNewEvents = [];
    this.countRemaining = this.formation.hours_to_plan;
    this.current_day = moment();
  }

  selectTeacher() {
    this.filterDateList = [];
    this.reinitEvent();
    this.getTeacherDisponibilities();
  }

  /* Methode pour récupérer les heures disponibles */
  getTeacherDisponibilities() {
    this.availablesDays = [];
    this.loadDisponibilities = true;
    const param = {
      algorithm: 'availabilityteacher',
      teacher: this.kiwixiGlobals.getIdFromUrl(this.booking.teacher.url),
      formation: this.formation.id,
      month: this.month_select,
      year: this.year_select,
      // formation: this.formation.id,
      timezone: momenttz.tz.guess()
    }
    this.planService.postPlan(param)
      .then(_res => {
        if (_res) {
          // on recupère les données >= à la date du jour
          _res.forEach(_available => {
            if (moment(_available.day).isSameOrAfter(this.current_day, 'day')) {
              this.availablesDays.push(_available);
            }
          });
          this.loadDisponibilities = false;
        }
      })
  }

  /**
   * Methode pour définir la base des jours disponibles.
   * @param _start
   * @param _end
   */
  setDaysDisponibilities(_start, _end) {
    this.filterDateList = [];
    for (let _i = 0; _i < this.availablesDays.length; _i++) {
      for (let _j = 0; _j < this.availablesDays[_i]['dispo'].length; _j++) {
        const myAvailableStart = moment({
          years: moment().year(), months: moment().month(), date: moment().date(),
          hours: moment(this.availablesDays[_i]['dispo'][_j]['start']).hour(), minutes: moment(this.availablesDays[_i]['dispo'][_j]['start']).minute(),
          seconds: 0, milliseconds: 0
        });
        const myAvailableEnd = moment({
          years: moment().year(), months: moment().month(), date: moment().date(),
          hours: moment(this.availablesDays[_i]['dispo'][_j]['end']).hour(), minutes: moment(this.availablesDays[_i]['dispo'][_j]['end']).minute(),
          seconds: 0, milliseconds: 0
        });
        if (_start && _end) {
          const myStartTime = moment({
            years: moment().year(), months: moment().month(), date: moment().date(),
            hours: moment(_start.datetime).hour(), minutes: moment(_start.datetime).minute(),
            seconds: 0, milliseconds: 0
          });
          const myEndTime = moment({
            years: moment().year(), months: moment().month(), date: moment().date(),
            hours: moment(_end.datetime).hour(), minutes: moment(_end.datetime).minute(),
            seconds: 0, milliseconds: 0
          });
          if (this.checkIfHourIsBetween(myAvailableStart, myAvailableEnd, myStartTime)
            && this.checkIfHourIsBetween(myAvailableStart, myAvailableEnd, myEndTime)) {
            const myday = new Date(this.availablesDays[_i]['day']);
            this.filterDateList.push(myday);
          }
        }
      }
    }
  }

  // on récupère les dispo sur la date choisie.
  changeDateSelect() {
    for (const _availableDay of this.availablesDays) {
      const _myAvailableDay = moment(
        {
          years: moment(_availableDay.day).local().year(),
          months: moment(_availableDay.day).local().month(),
          date: moment(_availableDay.day).local().date(),
          hours: 0, minutes: 0, seconds: 0, milliseconds: 0
        }
      );
      if (_myAvailableDay.isSame(moment(this.dateSelect))) {
        this.availableDay = _availableDay
        break;
      }
    }
  }

  /* **** POST BOOKING **** */
  postBooking() {
    this.loadDisponibilities = true;
    this.planService.generatePonctualBooking(this.formation, this.listNewEvents[0])
      .then(_res => {
        this.formationService.getFormation(this.formation.id).then(
          _formation => {
            this.formation = _formation
            this.handleUpdateFormation.emit(this.formation);
          }
        )
        this.availablesDays = [];
        this.booking =
          this.myStart = null;
        this.myEnd = null;
        this.dateSelect = null;
        this.availableDay = null;
        this.booking = new PonctualBooking();
        this.booking.teacher = null;
        this.filterDateList = [];
        this.listNewEvents = [];
        this.checkIsValid();
        this.loadDisponibilities = false;
      })
  }

  /* **** Handle from Planner **** */
  handleSchedulesSelected(value) {
    if (
      this.myStart && moment(this.myStart.datetime).format('HH:mm') !== moment(value.start).local().format('HH:mm') ||
      this.myEnd && moment(this.myEnd.datetime).format('HH:mm') !== moment(value.end).local().format('HH:mm')
    ) {
      const _myDate1 = moment([
        moment(this.myStart.datetime).year(),
        moment(this.myStart.datetime).month(),
        moment(this.myStart.datetime).date(),
        moment(value.start).local().hour(),
        moment(value.start).local().minute(), 0, 0]);
      this.myStart = { enable: false, datetime: _myDate1, str: _myDate1.toISOString(), local_str: _myDate1.format('HH:mm') }
      const _myDate2 = moment([
        moment(this.myStart.datetime).year(),
        moment(this.myStart.datetime).month(),
        moment(this.myStart.datetime).date(),
        moment(value.end).local().hour(),
        moment(value.end).local().minute(), 0, 0]);
      this.myEnd = { enable: false, datetime: _myDate2, str: _myDate2.toISOString(), local_str: _myDate2.format('HH:mm') }
    }
    this.booking.start = this.myStart;
    this.booking.end = this.myEnd;
    this.setDaysDisponibilities(this.myStart, this.myEnd);
  }

  /* **** Handle from Planner **** */
  handleAddEvent(_event) {
    _event.event_type = 'ponctual_session';
    this.listNewEvents = [];
    if (_event.end.format('mm:ss') === '59:59') {
      _event.end = _event.end.add(1, "second");
    }
    this.listNewEvents.push(_event)
    this.checkHoursToPlan();
    this.checkIsValid();
  }

  /* **** Handle from Planner **** */
  handleUpdateEvent(_event) {
    let _myEvent = this.listNewEvents[this.listNewEvents.indexOf(_event)]
    _myEvent = _event;
    this.checkHoursToPlan();
    this.checkIsValid();
  }

  // affichage des compteurs de temps
  checkHoursToPlan() {
    let compteur = 0
    this.listNewEvents.forEach(_event => {
      const ms = moment(_event.end).diff(moment(_event.start));
      const d = Number(moment.duration(ms)) / 3600000;
      compteur += d;
    });
    this.countDuration = compteur;
    this.countRemaining = this.formation.hours_to_plan - Number(this.countDuration.toFixed(2));
  }

  // Validation du bouton pour génerer le booking.
  checkIsValid() {
    this.bookingIsValid = this.listNewEvents.length > 0;
  }

  handleReinitEvents() {
    this.listNewEvents = [];
    this.checkHoursToPlan();
    this.checkIsValid();
  }

  /* **** Handle from Select time **** */
  handleFilterCalendar(value) {
    this.myStart = value.start;
    this.myEnd = value.end;
    this.loadDisponibilities = true;
    this.booking.start = value.start;
    this.booking.end = value.end;
    this.dateSelect = null;
    this.availableDay = null;
    this.reinitEvent();
    this.loadDisponibilities = false;
  }

  // reinitialiser l'event.
  reinitEvent() {
    this.listNewEvents = [];
    this.countRemaining = this.formation.hours_to_plan;
    this.bookingIsValid = false;
  }

  // ecouteur pour mettre à jour la date du booking
  handleUpdateDateSelect(value) {
    this.dateSelect = value;
    this.changeDateSelect();
  }

  // ecouteur du sous-composant pour mettre à jour le booking
  handleUpdateAvailables(_date) {
    this.reinitEvent();
    this.month_select = _date.month;
    this.year_select = _date.year;
    this.getTeacherDisponibilities();
  }

  checkIfHourIsBetween(start, end, value) {
    if (start <= value && value <= end) {
      return true;
    }
    return false;
  }

}
