/**
 * Variables Globales aux differents sites/modules.
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
// Model
import { Occurrence } from '../model/occurrence.model'
import { Site } from '../model/site.model';
import { Staff } from '../model/staff.model';
import { Student } from '../model/student.model';
import { Teacher } from '../model/teacher.model';
import { Timezone } from '../model/timezone.model';
import { User } from '../model/user.model';

@Injectable()
export class KiwixiGlobals {
  // use this property for property binding
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentStaff: BehaviorSubject<Staff> = new BehaviorSubject<Staff>(null);
  public currentStudent: BehaviorSubject<Student> = new BehaviorSubject<Student>(null);
  public currentStudentIdToNewFormation: BehaviorSubject<number> = new BehaviorSubject<number>(null)
  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public currentTeacher: BehaviorSubject<Teacher> = new BehaviorSubject<Teacher>(null);
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentNavIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalDataTable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public formationOccurrences: BehaviorSubject<Occurrence[]> = new BehaviorSubject<Occurrence[]>(null);
  public sites: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>(null);
  public timezones: BehaviorSubject<Timezone[]> = new BehaviorSubject<Timezone[]>(null);
  public last_check_time: any;
  public levels = [
    {
      label: 'Grand débutant',
      value: '1'
    },
    {
      label: 'Débutant',
      value: '2'
    },
    {
      label: 'Intermédiaire',
      value: '3'
    },
    {
      label: 'Avancé',
      value: '4'
    },
    {
      label: 'Maitrise',
      value: '5'
    },
    {
      label: 'Je ne sais pas',
      value: '0'
    }
  ]

  public liliate_empty = 'La première évaluation LILATE n’est pas encore disponible.' +
    'Après que vous ayez pris votre premier cours, votre professeur vous évaluera et vous pourrez trouver ici votre évaluation LILATE et télécharger votre attestation'

  public liliate_empty_1 = 'La première évaluation LILATE de'

  public liliate_empty_2 = 'n’est pas encore disponible.' +
    'Après que vous ayez pris votre premier cours, votre professeur vous évaluera et vous pourrez trouver ici votre évaluation LILATE et télécharger votre attestation'

  public liliate_explaination = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,' +
    'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' +
    'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' +
    'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

  setLoginStatus(isLoggedIn) {
    this.isUserLoggedIn.next(isLoggedIn);
  }

  setCurrentStaff(staff) {
    this.currentStaff.next(staff);
  }

  setCurrentStudent(student) {
    this.currentStudent.next(student);
  }

  setCurrentStudentIdToNewFormation(id) {
    this.currentStudentIdToNewFormation.next(id);
  }

  setCurrentUser(user) {
    this.currentUser.next(user);
  }

  setCurrentTeacher(teacher) {
    this.currentTeacher.next(teacher);
  }

  setCurrentNavIndex(index) {
    this.currentNavIndex.next(index);
  }
  setTotalDataTable(total) {
    this.totalDataTable.next(total);
  }

  setLoading(isLoading) {
    this.isLoading.next(isLoading);
  }

  setFormationOccurrences(occurences: Occurrence[]) {
    this.formationOccurrences.next(occurences);
  }

  setSites(sites: Site[]) {
    this.sites.next(sites);
  }

  setTimezones(timezones: Timezone[]) {
    this.timezones.next(timezones);
  }

  toggleButton(active) {
    if (!active) {
      return 'grey-text grey lighten-2';
    }
    return 'btn-toggle-disabled'
  }

  formatTimezone(label) {
    return label;
    // return label + ' (UTC' + moment.tz(label).format('Z') + ')';
  }

  getIdFromUrl(url) {
    return url.split('/')[6];
  }

  formatNumber(value, count) {
    const _roundup = Math.round(value * 100) / 100
    return parseFloat(String(_roundup)).toFixed(1);
  }

  getDuration(_start, _end) {
    const ms = moment(moment(_end), 'HH:mm').diff(moment(moment(_start), 'HH:mm'));
    return ms;
  }
}
