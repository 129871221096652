<ng-container *ngIf="isLoaded">
<div class="currentinvoice-wrap">
    <div class="current-textwrap">
        <div>This month’s invoice</div>
        <div>(<span class="current-color">current</span> / <span class="projected-color">projected</span>)</div>
    </div>  
    <div class="current-valuewrap">
        <span class="current-color">{{ currentInvoice.amount | number : '1.0-1' }}€</span> / <span class="projected-color">{{ currentInvoice.projected_amount | number : '1.0-1'}}€</span>
    </div>
</div>
<div >
    <div class="missing-warning" *ngIf="teacher.missing_invoices.length>0">
        <div>
        We’re still missing your invoice for 
        <span *ngFor="let invoice of teacher.missing_invoices">
            {{ invoice.periodicity  | amLocale: 'en' | amDateFormat:'MMMM YYYY'}}
        </span>
        </div>
    </div>
</div>
<div class="gotobilling-btn" routerLink="/billing"><span translate>Go to Billing & Invoices</span></div>
</ng-container>

<ng-container *ngIf="!isLoaded">
Loading
</ng-container>

<!-- Put this back line 11   *ngIf="teacher.missing_invoices.length"  -->