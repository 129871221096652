import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../material.module';
import { FeatureModule } from '../../../feature/feature.module';
import { DocumentService } from '../../../service/document.service';
import { MomentModule } from 'ngx-moment';
import { DirectiveModule } from '../../../directive/directive.module';

@NgModule({
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    MaterialModule,
    FeatureModule,
    MomentModule,
    DirectiveModule,
    TranslateModule.forRoot()
  ],
  declarations: [DocumentsComponent],
  providers: [DocumentService]
})
export class DocumentsModule { }
