import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';

@Component({
  selector: 'app-ponctual-availibility',
  templateUrl: './ponctual-availibility.component.html',
  styleUrls: ['./ponctual-availibility.component.scss']
})
export class PonctualAvailibilityComponent implements OnInit {
  translatePath = 'teacher.schedule';
  teacherId: number;
  calendarId: number;
  calendarUnavailabilityId: number;
  calendarUrl: number;
  calendarUnavailabilityUrl: number;
  teacher;

  constructor(
    private authenticationService: AuthenticationService){}

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(_current_user => {
      if (_current_user) {
        this.calendarId = this.authenticationService.extractIdFromUrl(_current_user.calendar);
        this.calendarUnavailabilityId = this.authenticationService.extractIdFromUrl(_current_user.unavaibility_calendar);
        this.calendarUrl = _current_user.calendar;
        this.calendarUnavailabilityUrl = _current_user.unavaibility_calendar;
        this.teacherId = _current_user.id;
        this.teacher = _current_user;
      }
    })
  }

}
