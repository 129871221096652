import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Timezone } from '../model/timezone.model';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class TimezoneService extends KiwixiService {

  apiTimezones = environment.server + 'timezones/';

  constructor(private _http: HttpClient, private _router: Router, kiwixiGlobals: KiwixiGlobals, public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getTimezones(filterParams): Promise<Timezone[]> {
    let params = new HttpParams();
    for (const filterParam of filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiTimezones, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }


}
