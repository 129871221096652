/**
 * Sous-composant pour la gestion des recurrences.
 * Intégrant des composants directives pour l'affichage des events et teachers.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KeyValueDiffers, OnChanges, SimpleChanges } from '@angular/core';
import { DoCheck } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../../../../../environments/environment';
import * as moment from 'moment';

// models
import { Formation } from '../../../../../model/formation.model';
import { Event } from '../../../../../model/event.model';

// services
import { PlanService } from '../../../../../service/plan.service';
import { EventService } from '../../../../../service/event.service';
import { FormationService } from '../../../../../service/formation.service';
import { TeacherService } from '../../../../../service/teacher.service';

function arraysEqual(a, b) {
  if (a === b) { return true; }
  if (a == null || b == null) { return false; }
  if (a.length !== b.length) { return false; }

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) { return false; }
  }
  return true;
}

@Component({
  selector: 'app-new-formation-recurrences',
  templateUrl: './new-formation-recurrences.component.html',
  styleUrls: [
    './new-formation-recurrences.component.scss',
    '../../mainscreen.component.scss',
    './new-formation-recurrences.ux.scss',
    '../../mainscreen.ux.scss'],
  providers: [PlanService, EventService]
})
export class NewFormationRecurrencesComponent implements OnInit, DoCheck, OnChanges {

  @Input() newFormation: Formation;
  @Output() newFormationOutput = new EventEmitter();

  has_errors = false;
  dirty = false;
  loadingAvailableHour = false; // boolean de chargement des heures dispo pour les select
  avaibilityHour; // dispo des profs pour la formation
  loaderCreatePlanning = false; // loader de la generation du planning
  loaderSolution = false; // loader pour les solutions
  check_compute_dispo = false;
  selected_solution = {};
  // differs
  differ: any;

  // teachers - events
  assignedTeachers = [];
  all_events_assigned = false;

  // solutions de dispo
  minutesAssigned = 0;
  minutesByWeek = 0;
  generateIsValid = false; // le planning peut il etre genere

  result_teacher = [];

  // search teacher
  teacherSolution: any[] = [];
  teacher_name_control = new FormControl();
  filteredSolution = [];
  teacher_list_available = [];

  getDaysService;
  extendChoices = true;
  days: any[] = [];

  noTeacherSolution = false;
  teachersAssigned = false;

  constructor(
    private planService: PlanService,
    private router: Router,
    private differs: KeyValueDiffers,
    private formationService: FormationService,
    private teacherService: TeacherService
  ) {
    this.differ = differs.find({}).create();
  }

  ngOnInit() {
    this.result_teacher = [];

    this.teacher_name_control.valueChanges.subscribe(
      search_str => {
        if (search_str) {
          const filters = { 'filtering': {} }
          filters['filtering']['name'] = search_str;
          // ajout des profs issus des solutions de plannif
          if (this.teacher_list_available.length > 0) {
            filters['filtering']['id__in'] = this.teacher_list_available.join(',');
            this.teacherService.getTeachers(filters).then(
              teachers => {
                // on filtre les solutions avec les profs contenus dans les reponses de recherche
                // on commence par se contruire un tableau de ref
                let my_teachers = [];
                my_teachers = teachers['results'].map(t => {
                  return '/' + t['url'].split('/').splice(3).join('/')
                });
                this.filteredSolution = [];
                my_teachers.forEach(t => {
                  this.teacherSolution.forEach(sol => {
                    if ((<any>Object).values(sol).indexOf(t) >= 0) {
                      // a dedoublonner
                      this.filteredSolution.push(sol)
                    }
                  });
                });
              }
            )
          }
        } else {
          this.filteredSolution = this.teacherSolution;
        }
        return this.teacherSolution;
      }
    )

  }

  ngOnChanges(changes: SimpleChanges) {
    // recuperation des heures de dispo pour cette formation
    this.loadingAvailableHour = true;
    this.initAvailabilities()
    for (const propName in changes) {
      if (propName === 'newFormation') {
        this.compute_final_state();
      }
    }
  }

  /**
   * Compute if we can generate a calendar
   */
  compute_final_state() {
    this.has_errors = false;
    this.teachersAssigned = false;
    // est ce que tout les events ont un prof associé
    const a = this.newFormation.events.filter(obj => obj.teacher !== null);
    this.all_events_assigned = a.length === this.newFormation.events.length;
    // check_compute_dispo update, si au moins 1 event
    if (this.newFormation.events.length > 0) {
      this.check_compute_dispo = true;
      this.newFormation.events.forEach(event => {
        this.check_compute_dispo = this.check_compute_dispo && Boolean(
          event.start &&
          event.end
        );
      });
    } else {
      this.check_compute_dispo = false;
    }
    // mise à jour des quantités
    this.minutesByWeek = this.computeMinutesRemainingByWeek();
    this.minutesAssigned = this.computeCountHourAsssignedByWeek();
    // peut on generer le planning
    if (this.newFormation.events.length > 0) {
      this.generateIsValid = true;
      this.newFormation.events.forEach(event => {
        this.generateIsValid = this.generateIsValid && Boolean(
          event.start &&
          event.end &&
          event.teacher
        );
      });
    } else {
      this.generateIsValid = false;
    }
    if (this.minutesByWeek - this.minutesAssigned > 0) {
      this.has_errors = true;
    }
    if (!this.all_events_assigned) {
      this.has_errors = true;
    }
  }

  /**
   * compute how many minutes are required weekly to accomplish the formation before validity_period_to
   */
  computeMinutesRemainingByWeek() {
    let from;

    if (moment().isAfter(moment(this.newFormation.validity_period_from))) {
      from = moment(moment(), 'DD/MM/YYYY');
    } else {
      from = moment(moment(this.newFormation.validity_period_from), 'DD/MM/YYYY');
    }
    const to = moment(moment(this.newFormation.validity_period_to), 'DD/MM/YYYY');

    const weeks = to.diff(from, 'weeks');
    const hours_total = Number(this.newFormation.hours_to_plan);
    return Math.ceil(hours_total / weeks) * 60;
  }

  /**
   * compute how many minutes are assigned by week
   */
  computeCountHourAsssignedByWeek() {
    let duration = 0;
    this.newFormation.events.forEach(element => {
      const m_end = moment(element.end);
      const m_start = moment(element.start);
      duration = duration + (m_end.get('hours') - m_start.get('hours')) * 60 + (m_end.get('minutes') - m_start.get('minutes'))
    });
    return duration;
  }

  /**
   * creation d'un nouvel event
   */
  addEvent() {
    const e = new Event();
    e.calendar = this.newFormation.calendar;
    e.end_recurring_period = this.newFormation.validity_period_to;
    e.rule = environment.default_event_rule;
    // e.open = open;
    // e.uuid = guid();
    e.ghost = true;
    this.newFormation.events.push(e);
    this.dirty = true;
    this.clean_selected_infos();
  }

  clean_selected_infos() {
    // on vide les teachers solution
    this.teacherSolution = [];
    // on nettoie les profs affectés
    this.newFormation.events.forEach(event => {
      event.teacher = null;
    });
    this.selected_solution = {};
    this.teacher_name_control.setValue('')
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.newFormation.events);
    if (changes) {
      let cpt = 0;
      changes.forEachRemovedItem(res => {
        cpt = cpt + 1;
      });
      // if (cpt > 0) {
      this.clean_selected_infos();
      // }

      this.compute_final_state();
    }
  }

  /**
   * getAvailableTeacher => calcul des solutions en fonction des creneaux saisis
   */
  getAvailableTeacher() {
    // on affiche le loader des teachers
    this.loaderSolution = true;
    this.teachersAssigned = false;
    // on sauvegarde les events

    this.formationService.saveEvents2(this.newFormation)
      .subscribe(_events => {
        const result = this.newFormation.events.filter(e => {
          _events.find(a => {
            if (moment(e.start).isSame(moment(a.start)) && moment(e.end).isSame(a.end)) {
              e.url = a.url;
            }
          })
        });

        if (this.newFormation.url) {
          this.planService.getTeachersAvaibilities(this.newFormation.url, true).then(
            res => {
              this.loaderSolution = false;
              this.teachersAssigned = true;
              if (res) {
                if (typeof (res) === 'boolean') {
                  this.teacherSolution = [];
                } else {
                  // on commence par dedoublonner les solutions
                  const tmp = []
                  const tmp_sol = []
                  res.forEach(element => {
                    const elmt = {};
                    const extra = element.extra;
                    let keys = Object.keys(element);
                    keys = keys.filter(val => val !== 'extra');
                    keys.forEach(key => {
                      elmt[key] = element[key];
                    })

                    const teachers = (<any>Object).values(elmt);
                    if (!tmp.find(function (e) {
                      return arraysEqual(e, teachers.sort())
                    })) {

                      tmp.push(teachers.sort());
                      tmp_sol.push(element)
                    }
                  });
                  this.teacherSolution = tmp_sol;
                  this.filteredSolution = this.teacherSolution;
                  // on deroule pour recuperer la liste des teachers
                  this.teacher_list_available = [];
                  res.forEach(solution => {
                    for (const propName in solution) {
                      if (propName !== 'extra') {
                        this.teacher_list_available.push(solution[propName].split('/')[solution[propName].split('/').length - 2])
                      }
                    }
                  })
                }
              }
              // si pas de solution on affiche un message
              if (this.filteredSolution.length < 1) {
                this.noTeacherSolution = true;
              } else {
                this.noTeacherSolution = false;
              }
              ;
            }
          )
        } else {
          this.loaderSolution = false;
        }
      },
        error => {
          console.log('error', error);
        }
      )
    // on demande les dispos
    // on masque le loader des teachers
    this.dirty = false;
  }

  /**
   * Mise a jour d'un event sous jacent
   */
  handle_update_event(event) {
    this.clean_selected_infos();
    this.compute_final_state();
  }

  /**
   * Select and assign a solution
   */
  selectSolution(solution) {
    this.selected_solution = solution;
    for (const event of Object.keys(solution)) {
      const my_event = this.newFormation.events.filter(res => res.url.includes(event))[0];
      if (my_event) {
        const my_teacher_url = solution[event];
        // find in resolved list
        // and affect
        my_event.teacher = environment.base_server + solution[event];
        this.compute_final_state();
      }
    }
  }

  /*
   * Validation du planning.
   */
  generatePlanning() {
    this.loaderCreatePlanning = true;
    this.planService.generateSimulation(this.newFormation, this.loaderCreatePlanning)
      .subscribe(a => {
        a.subscribe(() => {
          setTimeout(() => {
            const _id = this.planService.extractIdFromUrl(this.newFormation.url);
            this.router.navigate(['/formations', _id]);
            this.loaderCreatePlanning = false;
          }, 1000);
        })
      })
  }

  refresh() {
    this.newFormation.events = [];
    this.newFormationOutput.emit(true);
  }

  /**
   * methode pour initialiser les jours dispos
   */
  initAvailabilities() {
    if (this.newFormation.id) {
      this.getDaysService = this.planService.getAvailableDays(this.newFormation.id, this.extendChoices)
        .subscribe(_days => {
          if (_days) {
            this.days = _days;
            this.loadingAvailableHour = false;
          }
        })
    }
  }

}
