import { Formation } from './../../../../model/formation.model';
import { FormationService } from './../../../../service/formation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-mode',
  templateUrl: './booking-mode.component.html',
  styleUrls: ['./booking-mode.component.scss',
    '../plan-formation.component.scss']
})
export class BookingModeComponent implements OnInit {
  formation: Formation;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formationService: FormationService,
    private router: Router,
  ) { }

  ngOnInit() {
    //this.formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.formation = this.formationService.currentFormationSubject.value;
  }

  goToSchedule(mode) {
    if (mode === 'rec') {
      this.router.navigate(['plan-formation/schedule', this.formation.id]);
    } else {
      this.router.navigate(['plan-formation/init-ponctual-booking', this.formation.id]);
    }
  }
}
