import { Teacher } from './../model/teacher.model';
import { Student } from './../model/student.model';
import { Injectable, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MzToastService } from 'ng2-materialize';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment-timezone';

declare function setCrispEnv(email, nickname, studentUrl);



// Models
import { Auth } from '../model/auth.model';

// Service
import { KiwixiGlobals } from './kiwixi.globals';

@Injectable()
export class KiwixiService {

  headers: HttpHeaders;
  auth: Auth;
  apiMe = environment.me;
  apiFormation = environment.server + 'formations/';
  apiSites = environment.server + 'sites/';
  apiTimezones = environment.server + 'timezones/';
  http: HttpClient
  router: Router
  kiwixiGlobals: KiwixiGlobals;
  last_check_time: any;
  zone_name: any;
  availableSheetsLanguage = [2, 5, 4, 3, 23, 12]; // anglais
  public currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('fr');

  constructor(
    _http: HttpClient, _router: Router,
    private _kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService
  ) {
    this.http = _http;
    this.router = _router;
    this.kiwixiGlobals = _kiwixiGlobals;
    this.headers = new HttpHeaders();
    this.zone_name = moment.tz.guess(); // recupere la current timezone
    if (this.kiwixiGlobals.last_check_time === undefined) {
      this.kiwixiGlobals.last_check_time = -1;
    }
  }

  /**
   * Methode pour créer l'header pour les echanges serveurs.
  **/
  createAuthorizationHeader(headers: Headers, newBundle) {

    headers.set('x-bundle-recurring', JSON.stringify(newBundle))
    headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers.set('Content-Type', 'application/json; charset="utf-8";');
    headers.set('Access-Control-Allow-Origin', '*');
  }
  getCurrentLanguage() {
    return localStorage.getItem('language')
  }
  /**
   * Methode pour verifier l'authentification de l'utilisateur.
  **/
  public checkCredential() {
    const current_time = Date.now();
    if (moment.duration(current_time - this.kiwixiGlobals.last_check_time).as('seconds') > 60 ||
      this.kiwixiGlobals.last_check_time === -1
    ) {
      this.kiwixiGlobals.last_check_time = current_time;
      const newBundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
      if (newBundle) {
        const _str = newBundle.persona +
          newBundle.site + newBundle.language +
          newBundle.device + newBundle.session_id + localStorage.getItem('sign');
        const _hash = CryptoJS.SHA256(_str);
        newBundle.hash = _hash.toString();
        const headers = this.createHttpClientAuthorizationHeader();

        return this.http.get(this.apiMe, { headers: headers }).toPromise()
          .then(res => {
            const body = res;
            const self = this;
            return new Promise(function () {
              return self.extractCredential(body);
            })
          })
          .catch(error => {
            console.error('authError', error);
            this.logout();
          })
      }
    } else {
      this.kiwixiGlobals.last_check_time = current_time;
      return new Promise(function () { });
    }
  }

  public extractCredential(body) {
    let _isLogged = false;
    if (body.url.includes('staffs')) {
      this.kiwixiGlobals.setCurrentStaff(body);
      localStorage.setItem('persona_obj', JSON.stringify(body));
      _isLogged = true;
    }
    if (body.url.includes('students')) {
      this.kiwixiGlobals.setCurrentStudent(body);
      localStorage.setItem('persona_obj', JSON.stringify(body));
      _isLogged = true;
    }
    if (body.url.includes('teachers')) {
      this.kiwixiGlobals.setCurrentTeacher(body);
      localStorage.setItem('persona_obj', JSON.stringify(body));
      _isLogged = true;
    }
    if (_isLogged === true) {
      this.kiwixiGlobals.setLoginStatus(true);
    }
    return _isLogged;
  }

  /**
   * Methode pour verifier l'authentification de l'utilisateur.
  **/
  public setHeader() {
    this.headers = new HttpHeaders();
    const newBundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
    if (newBundle) {
      const _str = newBundle.persona +
        newBundle.site + newBundle.language +
        newBundle.device + newBundle.session_id + localStorage.getItem('sign');
      const _hash = CryptoJS.SHA256(_str);
      newBundle.hash = _hash.toString();
      this.headers = this.createHttpClientAuthorizationHeader();
    } else {
      // console.error('error Bundle')
      // this.handleError('error Bundle');
    }
    return this.headers;
  }

  public createHttpClientAuthorizationHeader() {
    const newBundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
    if (!newBundle) {
      this.logout();
    } else {
      const _str = newBundle.persona +
        newBundle.site + newBundle.language +
        newBundle.device + newBundle.session_id + localStorage.getItem('sign');
      const _hash = CryptoJS.SHA256(_str);
      newBundle.hash = _hash.toString();
      let httpHeader = new HttpHeaders()
      httpHeader = httpHeader.append('x-bundle-recurring', JSON.stringify(newBundle));
      httpHeader = httpHeader.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
      httpHeader = httpHeader.append('Content-Type', 'application/json; charset="utf-8";');
      httpHeader = httpHeader.append('Access-Control-Allow-Origin', '*');
      return httpHeader;
    }
  }

  public createHttpClientAuthorizationHeaderNoEnctype() {
    const newBundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
    if (!newBundle) {
      this.logout();
    } else {
      const _str = newBundle.persona +
        newBundle.site + newBundle.language +
        newBundle.device + newBundle.session_id + localStorage.getItem('sign');
      const _hash = CryptoJS.SHA256(_str);
      newBundle.hash = _hash.toString();
      const httpHeader = new HttpHeaders()
        .set('x-bundle-recurring', JSON.stringify(newBundle))
        .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
        // .set('Content-Type', 'application/json; charset="utf-8";')
        .set('Access-Control-Allow-Origin', '*');
      return httpHeader;
    }
  }

  public createPdfAuthorizationHeader() {
    this.headers = new HttpHeaders();
    const newBundle = JSON.parse(localStorage.getItem('x-bundle-recurring'));
    const _str = newBundle.persona +
      newBundle.site + newBundle.language +
      newBundle.device + newBundle.session_id + localStorage.getItem('sign');
    const _hash = CryptoJS.SHA256(_str);
    newBundle.hash = _hash.toString();
    this.headers.set('x-bundle-recurring', JSON.stringify(newBundle))
    this.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.headers.set('dataType', 'binary')
    this.headers.set('Access-Control-Allow-Origin', '*');
    return this.headers
  }

  /**
   * Methode pour gerer la deconnexion.
  **/
  logout() {
    // #1 - vide le local storage
    localStorage.removeItem('device');
    localStorage.removeItem('persona');
    localStorage.removeItem('sign');
    localStorage.removeItem('access_token');
    localStorage.removeItem('exportFormation');
    localStorage.removeItem('upcomingClasses');
    localStorage.removeItem('studentListFilter');
    localStorage.removeItem('formationListFilter');
    localStorage.removeItem('teacherListFilter');
    // remove obj personna !!! TODO
    localStorage.removeItem('persona_obj');
    // #2 - on change le statut global du login
    this.kiwixiGlobals.setLoginStatus(false);
    // ajouter un next sur le user pour subscribe/nettoyage
    this.currentUserSubject.next(null);

    // #3 - route vers la page login
    this.router.navigate(['/login']);
  }

  /**
   * Methode pour gerer les erreurs.
  **/
  handleError(error: any): Promise<any> {
    if (this) {
      this.toastService.show('Erreur ' + error, 4000, 'red');
    }
    // debugger TODO: logout on 403
    return Promise.reject(error.message || error);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleErrorObservable<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      if (this) {
        if (error.status === 403) {
          this.logout();
        } else if (error.status === 404) {
          if (window.location.pathname !== '/login' && !environment.production) {
            this.toastService.show(
              `${operation} failed: ${error.message}`,
              2000,
              'orange darken-4'
            )
          }
        } else {
          if (window.location.pathname !== '/login') {
            //this.router.navigate(['/login']);
            this.toastService.show(
              `${operation} failed: ${error.message}`,
              4000,
              'red'
            )
          }
        }
      }
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /**
   * Methode pour gerer les retours services
  **/
  extractData(res: Response) {
    const body = res;
    return body || {};
  }

  extractDataList(res: Response) {
    // const body = res;
    return this.extractData(res)
  }

  getImg(url) {
    const headers = this.createHttpClientAuthorizationHeader();

    return this.http.get(url, { headers: headers })
      .toPromise()
    // .then(this.extractDataList)
  }

  errorImgHandler(event) {
    event.target.src = environment.static + 'avatar.png';
  }

  errorLanguageImgHandler(event) {
    // event.target.src = '../../../../assets/images/adminnav-trainings.svg';
  }

  /*
   * interface
   * Event apres selection de day
   */
  setStartPicker(start, end, schedule) {
    let isTrue = false
    for (let _i = 0; _i < schedule.startPicker.length; _i++) {
      if (start === schedule.startPicker[_i].id) {
        isTrue = true;
      }
      if (end === schedule.startPicker[_i].id) {
        isTrue = false;
      }
      schedule.startPicker[_i].show = isTrue;
    }
    schedule.start = null;
    schedule.end = null;
  }

  public extractIdFromUrl(url) {
    if (url) {
      return parseInt(url.split('/')[url.split('/').length - 2], 0);
    }
    return null;
  }

  /**
 * Methode pour trier un tableau du plus récent au plus tard.
 * @param list
 */
  public ascendingSortWithDate(list) {
    list.sort(function (a, b) {
      return new Date(a.start).getTime() - new Date(b.start).getTime()
    });
  }

  public updateListAfterPolling(_displayingList, _updateList) {
    const itemsToAdd = [];
    const itemsToRemove = [];
    checkIfItemExist(_updateList, _displayingList, itemsToAdd);
    checkIfItemExist(_displayingList, _updateList, itemsToRemove);

    // 1 On ajoute les nouvelles notifs...
    itemsToAdd.forEach(element => {
      _displayingList.push(element)
    });

    // 2 On Supprime sur les notif...
    itemsToRemove.forEach(_a => {
      for (const _b of _displayingList) {
        if (_a.url === _b.url) {
          _displayingList.splice(_displayingList.indexOf(_b), 1)
          break;
        }
      }
    });

    function checkIfItemExist(_array1, _array2, _recap) {
      _array1.forEach(_a => {
        let notExist = true
        _array2.forEach(_b => {
          if (_a.url === _b.url) {
            notExist = false;
          }
        })
        if (notExist) {
          _recap.push(_a)
        }
      });
    }
  }

  getUrlParams(url, prop) {
    if (url) {
      const params = {};
      const search = decodeURIComponent(url.slice(url.indexOf('?') + 1));
      const definitions = search.split('&');

      definitions.forEach(function (val, key) {
        const parts = val.split('=', 2);
        params[parts[0]] = parts[1];
      });

      return (prop && prop in params) ? params[prop] : params;
    } else {
      return null;
    }
  }

  setUrlWithParams(endpoint, params) {
    let index = 0;
    let concatenatedParams = '/?';
    for (const element in params) {
      if (element) {
        if (index !== 0) {
          concatenatedParams += '&'
        }
        concatenatedParams += element + '=' + params[element];
        index++;
      }
    }
    return environment.server + endpoint + concatenatedParams;
  }

  setStudentCrispEnv(student: Student) {
    const studentUrl = environment.urls.filter((item) => item.module === 'backoffice')[0].url + '/students/' + student.user.id;
    setCrispEnv(student.user.email, student.user.first_name + ' ' + student.user.last_name, studentUrl);
  }

  setTeacherCrispEnv(teacher: Teacher) {
    const teacherUrl = environment.urls.filter((item) => item.module === 'backoffice')[0].url + '/teachers/admin/' + teacher.id;
    setCrispEnv(teacher.user.email, teacher.user.first_name + ' ' + teacher.user.last_name, teacherUrl);
  }


}
