import { RouterModule } from '@angular/router';
import { TeacherCacheStatusComponent } from './teacher-cache-status/teacher-cache-status.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterListComponent } from './filter-list/filter-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Radar2Component } from './radar2/radar2.component';
import { OccurrenceTeacherFirstnameComponent } from './occurrence-teacher-firstname/occurrence-teacher-firstname.component';
import { MaterialModule } from '../material.module';
import { ChartsModule } from 'ng2-charts';
import { EventTeacherFirstnameComponent } from './event-teacher-firstname/event-teacher-firstname.component';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { TimelineComponent } from './timeline/timeline.component';
import { MomentModule } from 'ngx-moment';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LevelComponent } from './level/level.component';
import { RadarComponent } from './radar/radar.component';
import { DirectiveModule } from '../directive/directive.module';
import { DatatableComponent } from './datatable/datatable.component';
import { DatatableCellStudentComponent } from './datatable/datatable-cell-student/datatable-cell-student.component';
import { DatatableCellStudentEmailComponent } from './datatable/datatable-cell-student-email/datatable-cell-student-email.component';
import { DatatableCellTeacherComponent } from './datatable/datatable-cell-teacher/datatable-cell-teacher.component';
import { DatatableCellSiteComponent } from './datatable/datatable-cell-site/datatable-cell-site.component';
import { DatatableCellTeachersComponent } from './datatable/datatable-cell-teachers/datatable-cell-teachers.component';
import { DatatableCellSitesComponent } from './datatable/datatable-cell-sites/datatable-cell-sites.component';
import { DatatableCellFormationComponent } from './datatable/datatable-cell-formation/datatable-cell-formation.component';
import { DatatableCellDateRangeComponent } from './datatable/datatable-cell-date-range/datatable-cell-date-range.component';
import { DatatableCellLanguageComponent } from './datatable/datatable-cell-language/datatable-cell-language.component';
import { DatatableCellLanguagesComponent } from './datatable/datatable-cell-languages/datatable-cell-languages.component';
import { DatatableCellUserComponent } from './datatable/datatable-cell-user/datatable-cell-user.component';
import { DatatableCellNextOccurrenceComponent } from './datatable/datatable-cell-next-occurrence/datatable-cell-next-occurrence.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';
import { ChatComponent } from './chat/chat.component';
import { HeaderLangFlagComponent } from './header-lang-flag/header-lang-flag.component';
import { TranslateModule } from '@ngx-translate/core';
import { LilateProgressionComponent } from './lilate-progression/lilate-progression.component';
import { RadarLilateComponent } from './radar-lilate/radar-lilate.component';
import { EditPlanningV2Component } from './edit-planning-v2/edit-planning-v2.component';
import { EditEventComponent } from './edit-planning-v2/edit-event/edit-event.component';
import { ItemEventComponent } from './edit-planning-v2/item-event/item-event.component';
import { TimeSelectorsComponent } from './time-selectors/time-selectors.component';
import { TimezoneAutocompleteComponent } from './timezone-autocomplete/timezone-autocomplete.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimeSelectorNoDispoComponent } from './time-selector-no-dispo/time-selector-no-dispo.component';
import { SheetAssignComponent } from './sheet-assign/sheet-assign.component';
import { SheetAssignedComponent } from './sheet-assigned/sheet-assigned.component';
import { SheetViewComponent } from './sheet-view/sheet-view.component';
import { ExerciceQuestionStudentComponent } from './exercice-question-student/exercice-question-student.component';
import { ExerciceQuestionStudentQcmComponent } from './exercice-question-student/exercice-question-student-qcm/exercice-question-student-qcm.component';
import { SheetExercicesComponent } from './sheet-exercices/sheet-exercices.component';
import { SheetExerciceQcmComponent } from './sheet-exercices/sheet-exercice-qcm/sheet-exercice-qcm.component';
import { SheetExercicesBilanComponent } from './sheet-exercices-bilan/sheet-exercices-bilan.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TeacherProfilComponent } from './teacher-profil/teacher-profil.component';
import { ModalComponent } from './modal/modal.component';
import { StudentFeedbackComponent } from './student-feedback/student-feedback.component';
import { PonctualScheduleComponent } from './ponctual-schedule/ponctual-schedule.component';
import { RecScheduleComponent } from './rec-schedule/rec-schedule.component';
import { ReplaceTeacherSelectComponent } from './replace-teacher-select/replace-teacher-select.component';
import { CarouselComponent } from './carousel/carousel.component';
import { RadioAvailabilitiesComponent } from './radio-availabilities/radio-availabilities.component';
import { WebcamComponent } from './webcam/webcam.component';
import { NextSessionComponent } from './next-session/next-session.component';
import { PreviousOccurencesComponent } from './previous-occurences/previous-occurences.component';
import { ComprateComponent } from './comprate/comprate.component';
import { MyTeachersComponent } from './my-teachers/my-teachers.component';
import { LilateComponent } from './lilate/lilate.component';
import { ValidateSheetComponent } from './validate-sheet/validate-sheet.component';
import { SheetPracticeComponent } from './sheet-practice/sheet-practice.component';
import { SheetRelatedComponent } from './sheet-related/sheet-related.component';
import { DashboardTeacherNotificationComponent } from './dashboard-teacher/dashboard-teacher-notification/dashboard-teacher-notification.component';
import { DashboardTeacherNextSessionComponent } from './dashboard-teacher/dashboard-teacher-next-session/dashboard-teacher-next-session.component';
import { DashboardTeacherBillingComponent } from './dashboard-teacher/dashboard-teacher-billing/dashboard-teacher-billing.component';
import { NextSessionAllFormationsComponent } from './next-session-all-formations/next-session-all-formations.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { AchievementModalComponent } from './achievement-modal/achievement-modal.component';

@NgModule({
  imports: [
    MaterialModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    CommonModule,
    RouterModule,
    InfiniteScrollModule,
    DirectiveModule,
    TranslateModule.forChild(),
    ScrollToModule.forRoot(),
  ],
  declarations: [
    PageNotFoundComponent,
    LoginComponent,
    FilterListComponent,
    Radar2Component,
    RadarLilateComponent,
    TimelineComponent,
    OccurrenceTeacherFirstnameComponent,
    LogoutComponent,
    ResetPasswordComponent,
    SearchBarComponent,
    LevelComponent,
    // DocumentItemComponent
    DatatableComponent,
    DatatableCellStudentComponent,
    DatatableCellStudentEmailComponent,
    DatatableCellTeacherComponent,
    DatatableCellSiteComponent,
    DatatableCellTeachersComponent,
    DatatableCellSitesComponent,
    DatatableCellFormationComponent,
    DatatableCellDateRangeComponent,
    DatatableCellLanguageComponent,
    DatatableCellUserComponent,
    DatatableCellNextOccurrenceComponent,
    DatatableCellLanguagesComponent,
    DocumentsListComponent,
    SplashscreenComponent,
    RadarComponent,
    EventTeacherFirstnameComponent,
    ChatComponent,
    HeaderLangFlagComponent,
    LilateProgressionComponent,
    EditPlanningV2Component,
    EditEventComponent,
    ItemEventComponent,
    TimeSelectorsComponent,
    TimezoneAutocompleteComponent,
    ConfirmationDialogComponent,
    TimeSelectorNoDispoComponent,
    TeacherCacheStatusComponent,
    SheetAssignComponent,
    SheetAssignedComponent,
    SheetViewComponent,
    ExerciceQuestionStudentComponent,
    ExerciceQuestionStudentQcmComponent,
    SheetExercicesComponent,
    SheetExerciceQcmComponent,
    SheetExercicesBilanComponent,
    LostPasswordComponent,
    TeacherProfilComponent,
    ModalComponent,
    StudentFeedbackComponent,
    PonctualScheduleComponent,
    RecScheduleComponent,
    ReplaceTeacherSelectComponent,
    CarouselComponent,
    RadioAvailabilitiesComponent,
    WebcamComponent,
    NextSessionComponent,
    PreviousOccurencesComponent,
    ComprateComponent,
    MyTeachersComponent,
    LilateComponent,
    ValidateSheetComponent,
    SheetPracticeComponent,
    SheetRelatedComponent,
    DashboardTeacherNotificationComponent,
    DashboardTeacherNextSessionComponent,
    DashboardTeacherBillingComponent,
    NextSessionAllFormationsComponent,
    MaintenanceComponent,
    TooltipComponent,
    AchievementModalComponent,
  ],
  exports: [
    ChartsModule,
    FilterListComponent,
    LilateProgressionComponent,
    RadarComponent,
    Radar2Component,
    RadarLilateComponent,
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    OccurrenceTeacherFirstnameComponent,
    SearchBarComponent,
    LevelComponent,
    DatatableComponent,
    PageNotFoundComponent,
    DocumentsListComponent,
    SplashscreenComponent,
    EventTeacherFirstnameComponent,
    HeaderLangFlagComponent,
    EditPlanningV2Component,
    TimeSelectorsComponent,
    TimezoneAutocompleteComponent,
    InfiniteScrollModule,
    ConfirmationDialogComponent,
    TimeSelectorNoDispoComponent,
    TeacherCacheStatusComponent,
    SheetAssignComponent,
    SheetAssignedComponent,
    SheetViewComponent,
    ExerciceQuestionStudentComponent,
    ExerciceQuestionStudentQcmComponent,
    SheetExercicesComponent,
    SheetExerciceQcmComponent,
    SheetExercicesBilanComponent,
    TeacherProfilComponent,
    ModalComponent,
    StudentFeedbackComponent,
    PonctualScheduleComponent,
    RecScheduleComponent,
    ReplaceTeacherSelectComponent,
    CarouselComponent,
    WebcamComponent,
    NextSessionComponent,
    PreviousOccurencesComponent,
    ComprateComponent,
    MyTeachersComponent,
    LilateComponent,
    ValidateSheetComponent,
    SheetPracticeComponent,
    SheetRelatedComponent,
    DashboardTeacherNotificationComponent,
    DashboardTeacherNextSessionComponent,
    DashboardTeacherBillingComponent,
    NextSessionAllFormationsComponent,
    DatatableCellStudentComponent,
    DatatableCellSiteComponent,
    DatatableCellStudentEmailComponent,
    DatatableCellTeacherComponent,
    DatatableCellFormationComponent,
    TooltipComponent,
    AchievementModalComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    SheetExercicesComponent,
    TeacherProfilComponent,
    AchievementModalComponent
  ]
})
export class FeatureModule { }
