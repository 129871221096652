<div class="page-title">My Contacts</div>
<div class="contactlist-wrapper">
<!-- Search bar -->
<div class="search-wrapper">
  <app-search-bar (setFiltering)="search($event)"></app-search-bar>
</div>

<ng-container *ngIf="showConversations;">
  <div [ngSwitch]="filterSelect" >
    <!-- All -->
      <div class="contact-row" *ngFor = "let conversation of conversationList">
          <app-contact [conversationId]="conversation.id" [contactId]="conversation.student_id" [nb_unread_message]="conversation.unread_teacher"></app-contact>
      </div>
  </div>
      <div>
      <button class="loadmore-btn" *ngIf="nextPages" (click)="loadMore()">Load More</button>
    </div>
</ng-container>
</div>
<ng-container *ngIf="isLoaderLazy;">
<div class="row center pageload-message">
    <b translate>{{translatePath}}.contacts-loading</b>
</div>
</ng-container>
