<!-- <div class="white" *ngIf="pending && lastReport"> -->
<div class="report-wrapper" *ngIf="formation">
  <!-- Header student identity -->
  <div class="report-header">
    <div class="report-icon"></div>
    <div class="avatar-block mobile-nodisplay">
          <div class="avatar-inset-shadow"></div>
          <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="formation.student.user.photo_profile === null">
          <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" *ngIf="formation.student.user.photo_profile !== null">
    </div>
    <div class="user-label">
      <div><b translate>{{translatePath}}.report-title</b> : {{pending.student_first_name}} {{pending.student_last_name}}</div>
      <div>
          {{pending.start | amLocale: 'en' | amDateFormat:'ddd DD MMM, YYYY'}}  
          {{pending.start | amLocale: 'en' | amDateFormat:'HH:mm'}} - {{pending.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
      </div>
    </div>
  </div>
  <div class="info-row">
    <b translate>{{translatePath}}.rate</b>
    <b> {{formation.student.user.first_name}} </b>
    <b translate>{{translatePath}}.progress-text</b>
  </div>
  <div class="improv-wrapper" [ngClass]="disableStyle()">
    <div class="improv-label-row">
      <div class="improv-label">None</div>
      <div class="improv-label">V.Slight</div>
      <div class="improv-label">Slight</div>
      <div class="improv-label">Visible</div>
    </div>
    <div *ngFor="let item of items; let idx = index">
      <mat-radio-group (change)="addProgression(idx, $event)">
        <div class="improv-radio-row">
          <div class="criteria-label"><b>{{item}}</b></div>
          <div class="improv-radio"><mat-radio-button value="0" [disabled]="editMode" [checked]=true></mat-radio-button></div>
          <div class="improv-radio"><mat-radio-button value="2" [disabled]="editMode"></mat-radio-button></div>
          <div class="improv-radio"><mat-radio-button value="4" [disabled]="editMode"></mat-radio-button></div>
          <div class="improv-radio"><mat-radio-button value="8" [disabled]="editMode"></mat-radio-button></div>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <!-- Levels -->
  <div class="viewlevel-wrapper">
      <div class="info-row">
        <b>{{pending.student_first_name}} Level : </b>
        <ng-container *ngIf="pending.session.rate_teacher && pending.session.rate_teacher.level && !editMode">
            <b>{{pending.session.rate_teacher.level}}</b>
        </ng-container>
        <button class="free-edit-btn action-btn minibtn" *ngIf="!editMode" (click)="editMode = true">Free Edit</button>
      </div>
    <div *ngFor="let subject of subjects">
        <app-level [editMode]=editMode [subject]="subject" [newValue]=2 [isFirst]=false [previousReport]="lastReport" (updateLevel)="handleSubject($event)"></app-level>
        <ng-container *ngIf="subject.level.value === 100"> 
          <div class="confirmlevelup-row">
                <mat-checkbox class="example-margin" [(ngModel)]="subject.level.levelUp" (change)="handleSubject(subject)">
                  <span translate>{{translatePath}}.confirm</span>
                  <span>{{pending.student_first_name}}</span>
                  <span translate>{{translatePath}}.confirm-rdb-label</span>
                   {{subject.level.end_label}} ?
                </mat-checkbox>
          </div>
        </ng-container>
      </div>
  </div>

  <div class="report-buttons-row">
      <button class="action-btn suboption" (click)="completeLater()">
        <span translate>{{translatePath}}.complete-later-btn</span>
      </button>
      <button class="action-btn" (click)="sendReport('completed')" [disabled]="!sendReportIsActive">
        <span translate>{{translatePath}}.send-report-btn</span>
      </button>
  </div>
</div>
