import { ExerciceSheetService } from './../../../../../../../service/exerciceSheet.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ExerciceService } from './../../../../../../../service/exercice.service';
import { Component, OnInit, Input } from '@angular/core';
import { Exercice } from '../../../../../../../model/exercice.model';
import { Router } from '@angular/router';
import { ExerciceSheet } from '../../../../../../../model/exerciceSheet.model';

@Component({
  selector: 'app-sheet-exercices-information',
  templateUrl: './sheet-exercices-information.component.html',
  styleUrls: ['./sheet-exercices-information.component.scss', '../../../../formation/informations/informations.ux.scss', '../../../../formation/formation.ux.scss', '../../../../mainscreen.ux.scss']
})
export class SheetExercicesInformationComponent implements OnInit {
  @Input() exercice: Exercice;
  @Input() exerciceSheet: ExerciceSheet;
  difficultyList = [];

  exerciceFormGroup = new FormGroup({
    difficulty: new FormControl('', Validators.required),
    duration: new FormControl('', Validators.required),
  });

  constructor(
    private router: Router,
    private exerciceSheetService: ExerciceSheetService,
    private exerciceService: ExerciceService,
  ) { }

  ngOnInit() {
    this.difficultyList = this.exerciceService.getDificultyList();
    if (this.exercice) {
      this.loadData();
    }
  }
  loadData() {
    this.exerciceFormGroup.controls.difficulty.setValue(this.exercice.difficulty);
    this.exerciceFormGroup.controls.duration.setValue(this.exercice.duration);
  }
  createExercice() {
    const forms = this.exerciceFormGroup.controls;
    const _params = {
      difficulty: forms.difficulty.value,
      duration: forms.duration.value,
      sheets: [this.exerciceSheet.url],
      status: 'published'
    };

    this.exerciceService.postExercice(_params).subscribe(exerciceCreated => {
      if (exerciceCreated.id) {
        this.router.navigate(['/exercices/sheet-edit/' + this.exerciceSheet.id + '/exercice-edit', exerciceCreated.id]);
      }
    });
  }

  updateExercice(field) {
    if (this.exercice) {
      const fieldControl = this.exerciceFormGroup.get(field);
      const _params = {
        [field]: fieldControl.value
      };
      this.exerciceService.patchExercice(this.exercice.id, _params).subscribe(result => {
      });
    }
  }

}
