import { FormationService } from './../../service/formation.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-radio-availabilities',
  templateUrl: './radio-availabilities.component.html',
  styleUrls: ['./radio-availabilities.component.scss']
})
export class RadioAvailabilitiesComponent implements OnInit {

  @Input() availablesDays;
  @Output() updateDateSelect = new EventEmitter()
  dayAvailibilities = new FormControl('', Validators.required)
  constructor(
    private formationService: FormationService,
  ) { }

  ngOnInit() {
    this.dayAvailibilities.valueChanges.subscribe(date => this.updateSelectedDate(date.day))
  }

  updateSelectedDate(date) {
    const _date = moment(date);
    this.updateDateSelect.emit(_date.toDate());
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

}
