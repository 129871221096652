import { ExerciceSessionList } from './../model/exerciceSessionList.model';
import { ExerciceSession } from './../model/exerciceSession.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class ExerciceSessionService extends KiwixiService {

    apiExerciceSession = environment.server + 'exercices_sessions/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getExerciceSessionById(_id): Observable<ExerciceSession> {
        const url = this.apiExerciceSession + _id + '/?salt=' + Math.random();
        return this.getExerciceSessionByUrl(url);
    }

    getExeciceSheetByIdWithStudentsResults(sheetId, studentId): Observable<ExerciceSession> {
        const url = this.apiExerciceSession + sheetId + '/?is_validate_by_student=' + studentId + '&salt=' + Math.random();
        return this.getExerciceSessionByUrl(url);
    }

    getExerciceSessionByUrl(_url): Observable<ExerciceSession> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceSession>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getExerciceSession`))
            );
    }

    getObservableExerciceSessions(params: HttpParams): Observable<ExerciceSessionList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceSessionList>(this.apiExerciceSession, {
            headers: header,
            params: params
        })
            .map(_ExerciceSessions => _ExerciceSessions)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSessionList>(`getObservableExerciceSessions`))
            );
    }

    postExerciceSession(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<ExerciceSession>(this.apiExerciceSession, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSession>(`Création d'une fiche`)),
            );
    }

    patchExerciceSession(_id, params) {
        const url = this.apiExerciceSession + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<ExerciceSession>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSession>(`Création d'une fiche`)),
            );
    }
}
