import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
import { MessagesComponent } from './messages.component';
import { ConversationComponent } from './conversation/conversation.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DocumentsComponent } from '../documents/documents.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';
// from module

export const routes: Routes = [
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: '', component: ContactsComponent, canActivate: [AuthGuard] },
      // { path: ':id', component: ConversationComponent, canActivate: [AuthGuard]},
      { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard], data: { 'sharing': true } },
    ]
  },
  { path: 'messages/formation/:id_formation/student/:id', component: ConversationComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'student' } },
  { path: 'messages/conversation/:id_conversation/student/:id_student', component: ConversationComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'student' } },
  { path: 'messages/teacher/:id', component: ConversationComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'teacher' } },
  { path: 'messages/staff/:id', component: ConversationComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'staff' } },

  { path: 'messages/conversation/:id_conversation/student/:id_student/documents', component: DocumentsComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'student', 'sharing': true } },
  { path: 'messages/teacher/:id/documents', component: DocumentsComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'teacher', 'sharing': true } },
  { path: 'messages/staff/:id/documents', component: DocumentsComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { 'persona': 'staff', 'sharing': true } }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MessagesRoutingModule { }
