import { AchievementManagerService } from './../../service/achievementManager.service';
/**
 * Component principal de notre site teacher.
 */

import { Component, OnInit, DoCheck, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Model
import { Teacher } from '../../model/teacher.model';
// Service
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DictionnaryService } from '../../service/dictionnary.service';
import { AuthenticationService } from '../../service/authentification.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss', './teacher.ux.scss'],
  providers: []
})
export class TeacherComponent implements OnInit, DoCheck {
  translatePath = 'TEACHER';
  teacher: Teacher;
  deviceInfo = null;
  isLogged = false;
  isLoggedIn = false;
  showTeacherApp = false;
  currentPersona;
  initOnProgress = true;
  reloadOnProgress = true;

  setTeacher;

  constructor(
    private dictionnaryService: DictionnaryService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private kiwixiGlobals: KiwixiGlobals,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private achievementManagerService: AchievementManagerService,

  ) {
    kiwixiGlobals.isUserLoggedIn.subscribe(islogged => {
      if (islogged) {
        setTimeout(() => {
          if (this.authenticationService.currentUserSubject.value) {
            this.teacher = this.authenticationService.currentUserSubject.value;
            if (this.achievementManagerService.achievementToFire.value.length == 0) {
              this.achievementManagerService.setAchievementsToFire(this.teacher);
            }
          }
          this.isLoggedIn = true;
          this.showTeacherApp = true;
          this.reloadOnProgress = false;
          this.initOnProgress = false;
        }, 1000);
      }
    })
  }

  ngOnInit() {
    this.initOnProgress = true;
    setTimeout(() => {
      // verifier si on est en mode impersonate ou non.
      const my_observable = this.authenticationService.initAuthPage('teacher', 'teacher');
      if (my_observable) {
        my_observable.subscribe((_teacher) => {
          if (_teacher && _teacher.user) {
            this.authenticationService.currentUserSubject.next(_teacher.user);
            this.initPage(_teacher.user);
            this.setTranslation();
            this.initOnProgress = false;
          } else {
            this.isLoggedIn = false;
            this.showTeacherApp = true;
            this.initOnProgress = false;
          }
        });
      } else {
        this.isLoggedIn = false;
        this.showTeacherApp = true;
        this.initOnProgress = false;
      }
      this.deviceInfo = this.deviceService.getDeviceInfo();
    }, 800);
  }

  // si authentification via Impersonate.
  haveImpersonateParams() {
    if (this.activatedRoute.children[0] &&
      this.activatedRoute.children[0].snapshot.queryParams &&
      this.activatedRoute.children[0].snapshot.queryParams['persona']) {
      return true;
    }
    return false;
  }

  /**
   * On vérifie si le persona change, si oui on reload
   */
  ngDoCheck() {
    this.testTranslate();
    if (this.authenticationService.getCurrentPersona()) {
      if (!this.authenticationService.checkPersona() && !window.location.href.includes('login')) {
        this.reloadOnProgress = true;
        setTimeout(() => {
          this.logout();
          this.authenticationService.currentUserSubject.next(this.authenticationService.getCurrentPersona());
          this.showTeacherApp = false;
          setTimeout(() => {
            this.initPage(this.authenticationService.getCurrentPersona());
          }, 500);
        }, 500);
      } else {
        if (window.location.href.includes('login')) {
          this.logout();
          this.showTeacherApp = true;
        }
      }
    } else {
      if (!window.location.href.includes('login') && !this.initOnProgress) {
        this.logout();
        this.showTeacherApp = true;
        if (!window.location.href.includes('lost-password') && !window.location.href.includes('reset-password') && !window.location.href.includes('maintenance')) {
          this.router.navigate(['/login']);
        }
      } else if (!this.authenticationService.getCurrentPersona() && window.location.href.includes('login') && !this.initOnProgress && !this.reloadOnProgress) {
        this.logout();
      }
    }
  }

  /**
   * Méthode pour pour masquer le menu au logout
   */
  logout() {
    this.isLoggedIn = false;
    this.cdRef.detectChanges();
    this.teacher = null;
  }

  /**
   * On vérifie si la traduction est prete.
   */
  testTranslate() {
    if (this.translate.getLangs().length === 0) {
      this.setTranslation();
    }
  }

  /**
   * initialisation de la page
   * @param _teacher
   */
  initPage(_teacher) {
    this.kiwixiGlobals.setLoginStatus(true);
    if (this.router.url === '/' || this.router.url === '/login' || this.haveImpersonateParams()) {
      const _url = '/upcoming'
      this.router.navigate([_url]);
    }
    this.isLoggedIn = true;
    this.showTeacherApp = true;
    this.reloadOnProgress = false;
  }

  /**
   * on affiche le menu qd on affiche la page upcoming ou schedule
   * @param e
   */
  componentAdded(e) {
    if ((e.isUpcoming || e.isSchedule) && !this.isLoggedIn) {
      this.isLoggedIn = true;
      this.setTranslation();
    }
  }

  /**
   * Methode pour appliquer les traductions
   */
  setTranslation() {
    const _lang = 'en';
    this.translate.setDefaultLang(_lang);
    this.translate.use(_lang);
    this.dictionnaryService.getDictionnary(_lang)
      .then(res => {
        if (res) {
          this.translate.setTranslation(_lang, res);
        }
      });
  }

}
