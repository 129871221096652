// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'staging',
  urls: [
    {
      source: 'https://teacher.recurring.staging.lingueo.fr',
      url: 'https://teacher.recurring.staging.lingueo.fr',
      module: 'teacher',
      default_role: 'teacher',
      gtagID: 'UA-3750617-17',
      crispID: '60473231-0966-4e89-be44-dfdbf928aa48',
      sentryDsn: 'https://c6d9d8d0becc4e3db157a3b60bc493ad@o315478.ingest.sentry.io/1796050',
      languages: ['en'],
      defaultLanguage: 'en',
    },
    {
      source: 'https://backoffice.recurring.staging.lingueo.fr',
      url: 'https://backoffice.recurring.staging.lingueo.fr',
      module: 'backoffice',
      default_role: 'staff',
      gtagID: 'UA-3750617-20',
      crispID: '',
      sentryDsn: 'https://380cfcc50b1f4c93a4b1e93a8786ef84@o315478.ingest.sentry.io/1795986',
      languages: ['fr'],
      defaultLanguage: 'fr',
    },
    {
      source: 'https://student.recurring.staging.lingueo.fr',
      url: 'https://student.recurring.staging.lingueo.fr',
      module: 'lingueopro',
      default_role: 'student',
      gtagID: 'UA-3750617-18',
      crispID: '0a0a994a-9e0e-49e7-b8f9-50d916bd81d5',
      sentryDsn: 'https://d0f948a56e8e45e883557f81c6d17dc4@o315478.ingest.sentry.io/1795973',
      languages: ['fr', 'it'],
      defaultLanguage: 'fr',
    },
    {
      source: 'https://teenisio.recurring.staging.lingueo.fr',
      url: 'https://teenisio.recurring.staging.lingueo.fr',
      module: 'teenisio',
      default_role: 'student',
      gtagID: '',
      sentryDsn: 'https://d0f948a56e8e45e883557f81c6d17dc4@sentry.io/1796053',
      languages: ['fr', 'it'],
      defaultLanguage: 'fr',
    }
  ],
  base_server: 'https://api.recurring.staging.lingueo.fr',
  server: 'https://api.recurring.staging.lingueo.fr/api/v1/',
  auth: 'https://api.recurring.staging.lingueo.fr/api/v1/auth/',
  me: 'https://api.recurring.staging.lingueo.fr/api/v1/me/',
  media: 'https://api.recurring.staging.lingueo.fr/media/',
  static: 'https://api.recurring.staging.lingueo.fr/static/',
  application_id: 'MX6xlCV4G5at4r31biAN9UG7VyrHggCrUHigyBY1TnrXhqak1xZWLSHETIoAiG9SYDZQqkwFRuUgM39zedn34Kh0itVCX3bfdnJFTjwH0ThUOdbjZqjl6709gXskESpD',
  client_id: 'fHJJ2cPFjzgRkb6ei3mFCLuC6KaLSuySWJbN1abz',
  google_app_id: '922385396360-s3i1nceli1bk0ovjd57molbg5mk78i4p.apps.googleusercontent.com',
  google_app_secret: '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',
  test: 'fake-staging',
  default_teacher_site: 'https://api.recurring.staging.lingueo.fr/api/v1/sites/2/',
  default_admin_site: 'https://api.recurring.staging.lingueo.fr/api/v1/sites/1/',
  default_student_site: 'https://api.recurring.staging.lingueo.fr/api/v1/sites/4/',
  default_event_rule: 'https://api.recurring.staging.lingueo.fr/api/v1/rules/3/',
  documents: '/api/v1/documents/',
  calendar_export: '/api/v1/calendar/export/',
  maxFileSize: 262144000,
  fileSizeAlert: 'Merci de bien vouloir télécharger un document de moins de 26 mo'
};
