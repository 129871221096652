<div class="login-screen" id="myCheck" *ngIf="!auth">
  <div class="login-wrapper">
    <form [formGroup]="userForm" (ngSubmit)="checkAuth()">
      <div class="row">
        <div class="login-logo"></div>
      </div>

      <mat-form-field class="form-field-position">
        <input matInput id="email-input" emailInput type="email" name="email" placeholder="Email"
          [formControl]="emailFormControl" >
          <mat-error *ngIf="emailFormControl.dirty &&  emailFormControl.hasError('email')" transalte>{{translatePath}}.Email is invalid</mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-position">
        <input matInput id="password-input" #pwdInput type="password" name="password" placeholder="Password" autocomplete="off"
          [formControl]="passwordFormControl" >
          <mat-error *ngIf="passwordFormControl.dirty && !passwordFormControl.valid" translate>{{translatePath}}.Password is invalid</mat-error>
      </mat-form-field>

      <div>
        <button *ngIf="!loading" [disabled]="!userForm.valid" type="submit" class="login-btn" translate>login.connexion</button>
      </div>
    </form>
    <div class="row">
      <button *ngIf="!loading" (click)="lostPassword()" class="forgot-btn" translate>{{translatePath}}.Mot de passe oublié ?</button>
    </div>
    <div *ngIf="loading" class="row center">
      <span translate>teacher.login.loading</span>
    </div>
  </div>
</div>
