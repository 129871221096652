import { FormationSuiviList } from './../model/formationSuiviList.model';
import { FormationSuivi } from './../model/formationSuivi.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { FormationSuiviListFilter } from '../model/formationSuiviListFilter.model';

@Injectable()
export class FormationSuiviService extends KiwixiService {

    apiFormationSuivi = environment.server + 'formations_suivi/';
    apiCurrent = this.apiFormationSuivi + 'current/';
    public filterFormationSuiviListing: BehaviorSubject<FormationSuiviListFilter> = new BehaviorSubject<FormationSuiviListFilter>(null);


    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    setFilterListing(data) {
        const suiviFormationsFilter: FormationSuiviListFilter = new FormationSuiviListFilter;
        suiviFormationsFilter.id = data.id;
        suiviFormationsFilter.corporate = data.corporate;
        suiviFormationsFilter.company = data.company;
        suiviFormationsFilter.language = data.language;
        suiviFormationsFilter.first_name = data.first_name;
        suiviFormationsFilter.last_name = data.last_name;
        suiviFormationsFilter.email = data.email;
        suiviFormationsFilter.hours_total = data.hours_total;
        suiviFormationsFilter.hours_to_plan = data.hours_to_plan;
        suiviFormationsFilter.hours_to_plan_percent = data.hours_to_plan_percent;
        suiviFormationsFilter.status = data.status;
        suiviFormationsFilter.is_stall = data.is_stall;
        suiviFormationsFilter.group_formations = data.group_formations;
        this.filterFormationSuiviListing.next(suiviFormationsFilter);
    }


    getFormationSuivi(_filterParams): Promise<FormationSuivi[]> {
        let params = new HttpParams();
        // ordering:
        if (_filterParams['ordering']) {
            const f = _filterParams['ordering'];
            params = params.set(f.key, f.value);
        }
        if (_filterParams['filtering']) {
            for (const filterParam of Object.keys(_filterParams['filtering'])) {
                params = params.set(filterParam, _filterParams['filtering'][filterParam]);
            }
        }
        if (_filterParams['page']) {
            const _page = _filterParams['page'];
            params = params.set(_page.key, _page.value);
        }
        if (_filterParams['teacher']) {
            const _page = _filterParams['teacher'];
            params = params.set(_page.key, _page.value);
        }
        const headers = this.createHttpClientAuthorizationHeader();

        return this._http.get(this.apiFormationSuivi, { headers: headers, params: params })
            .toPromise()
            .then((res: any) => res)
            .catch(this.handleError);
    }

    getObservableFormationSuivis(params: HttpParams): Observable<FormationSuiviList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<FormationSuiviList>(this.apiFormationSuivi, {
            headers: header,
            params: params
        })
            .map(_FormationSuivis => _FormationSuivis)
            .pipe(
                catchError(this.handleErrorObservable<FormationSuiviList>(`getObservableFormationSuivis`))
            );
    }

    getActivatedFilter(page_size, page, orderingField, orderingDirection) {
        let _params = new HttpParams();

        if (this.filterFormationSuiviListing.value) {
            if (this.filterFormationSuiviListing.value.id) {
                _params = _params.set('id', this.filterFormationSuiviListing.value.id.toString());
            }
            if (this.filterFormationSuiviListing.value.corporate) {
                _params = _params.set('company__corporate__in', this.filterFormationSuiviListing.value.corporate.map(c => c.id).join(','));
            }
            if (this.filterFormationSuiviListing.value.company) {
                _params = _params.set('company__name__icontains', this.filterFormationSuiviListing.value.company.toString());
            }
            if (this.filterFormationSuiviListing.value.group_formations) {
                _params = _params.set('group_formations', this.filterFormationSuiviListing.value.group_formations.toString());
            }
            if (this.filterFormationSuiviListing.value.language) {
                _params = _params.set('language__id', this.filterFormationSuiviListing.value.language.id.toString());
            }
            if (this.filterFormationSuiviListing.value.first_name) {
                _params = _params.set('student__user__first_name__icontains', this.filterFormationSuiviListing.value.first_name);
            }
            if (this.filterFormationSuiviListing.value.last_name) {
                _params = _params.set('student__user__last_name__icontains', this.filterFormationSuiviListing.value.last_name);
            }
            if (this.filterFormationSuiviListing.value.email) {
                _params = _params.set('student__user__email__icontains', this.filterFormationSuiviListing.value.email);
            }
            if (this.filterFormationSuiviListing.value.hours_total) {
                _params = _params.set('hours_total', this.filterFormationSuiviListing.value.hours_total.toString());
            }
            if (this.filterFormationSuiviListing.value.hours_to_plan) {
                _params = _params.set('hours_to_plan', this.filterFormationSuiviListing.value.hours_to_plan.toString());
            }
            if (this.filterFormationSuiviListing.value.hours_to_plan_percent) {
                _params = _params.set('hours_to_plan_percent', this.filterFormationSuiviListing.value.hours_to_plan_percent.toString());
            }
            if (this.filterFormationSuiviListing.value.status) {
                _params = _params.set('status__in', this.filterFormationSuiviListing.value.status);
            }
            if (this.filterFormationSuiviListing.value.is_stall) {
                _params = _params.set('is_stall', this.filterFormationSuiviListing.value.is_stall);
            }
        }
        _params = _params.set('page', page + 1);
        if (orderingDirection === 'desc') {
            orderingField = '-' + orderingField;
        }
        _params = _params.set('ordering', orderingField);

        _params = _params.set('page_size', page_size);
        _params = _params.set('salt', Math.random().toString());
        return _params;
    }

    getFormationSuivisByFilter(page_size, page, orderingField, orderingDirection): Observable<FormationSuiviList> {
        const _params = this.getActivatedFilter(page_size, page, orderingField, orderingDirection);
        return this.getObservableFormationSuivis(_params);
    }

    getFormationSuivisStatus() {
        return [
            {
                label: 'En cours',
                code: 'active',
                valid: true
            },
            {
                label: 'En attente',
                code: 'pending',
                valid: true
            },
            {
                label: 'Terminée',
                code: 'done',
                valid: true
            },
            {
                label: 'Suspendue',
                code: 'suspended',
                valid: true
            },
            {
                label: 'Annulée',
                code: 'cancelled',
                valid: true
            }
        ];
    }

    postExport<Object>(_url, _selectedIds, _filename) {
        const headers = this.createHttpClientAuthorizationHeader();
        const params = {
            selected: _selectedIds,
        };
        return this._http.post(_url, params, { headers: headers, responseType: 'arraybuffer' })
            .map((file) => {
                const myFile = new Uint8Array(file);
                //const blob = new Blob([myFile], { type: _contentType });

                const blob = new Blob([myFile], { type: 'application/vnd.ms-excel' });
                // const file = new File([blob], 'report.xlsx',
                //     { type: 'application/vnd.ms-excel' });

                // FileSaver.saveAs(file);


                const url = window.URL.createObjectURL(blob);
                const $a = $('<a />', {
                    'href': url,
                    'download': _filename,
                    'text': 'click'
                }).hide().appendTo('body')[0].click();
            },
                error => this.toastService.show('Erreur postExport !!!', 4000, 'red'))
            .toPromise();
    }
}
