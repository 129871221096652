import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import * as moment from 'moment';


// models
import { Formation } from '../../../../../model/formation.model';

// services
import { PlanService } from '../../../../../service/plan.service';
import { FormationService } from '../../../../../service/formation.service';
import { Event } from '../../../../../model/event.model';
import { EventService } from './../../../../../service/event.service';
import { SearchPlanSolution } from '../../../../../model/searchPlanSolution.model';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DebugMatriceComponent } from '../debug-matrice/debug-matrice.component';
import { TimeSelectorNoDispoComponent } from './../../../../../feature/time-selector-no-dispo/time-selector-no-dispo.component';
/**
 * Sous-composant pour l'affichage et l'edition des récurrences.
 * Intègre le sous-composant permettant l'edition de la plannification.
 */

@Component({
  selector: 'app-recurrences',
  templateUrl: './recurrences.component.html',
  styleUrls: ['./recurrences.component.scss', './recurrences.ux.scss', '../formation.ux.scss', '../../mainscreen.ux.scss'],
  providers: [PlanService, EventService, FormationService]
})

export class RecurrencesComponent implements OnInit {
  @ViewChild('timeslotcontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  events: Event[] = [];
  show_event_list;
  @Input() formation: Formation;
  timeSlotList: any = [];
  searchPlanResult: SearchPlanSolution;
  loaderSearchSolution = false;
  nextPosition = 0;
  overlayRef;
  allocationMode = 'linear_date_around';
  @Output() handleUpdateOccurenceList = new EventEmitter<boolean>();
  @Output() handleUpdateFormation = new EventEmitter<any>();

  constructor(
    private eventService: EventService,
    private planService: PlanService,
    private resolver: ComponentFactoryResolver,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,) { }

  ngOnInit() {
    this.loaderSearchSolution = true;
    this.getEventList();
  }

  getEventList() {
    const params = [
      {
        key: 'calendar',
        value: this.eventService.extractIdFromUrl(this.formation.calendar)
      },
      {
        key: 'ghost',
        value: false
      },
      {
        key: 'deleted',
        value: false
      },
      {
        key: 'event_type',
        value: 'session'
      },
      {
        key: 'salt',
        value: Math.random()
      },
    ]
    this.eventService.getEvents(params).subscribe(data => {
      this.events = data['results'];
      this.initEventsOptions();
      this.show_event_list = true;
      this.loaderSearchSolution = false;
    })
  }

  initEventsOptions() {
    this.events.forEach(event => {
      event.edit_extend = false;
      event.edit_delete = false;
    });
  }

  refresh_component() {
    this.timeSlotList.map(timeslot => timeslot.destroy());
    this.timeSlotList = [];
    this.refreshSearchResult();
    this.events = [];
    this.getEventList();
  }

  refreshSearchResult() {
    this.searchPlanResult = null;
  }

  addTimeSlot() {
    const factory = this.resolver.resolveComponentFactory(TimeSelectorNoDispoComponent);
    const timeslot = this.entry.createComponent(factory)
    timeslot.instance.formation = this.formation;
    timeslot.instance.open_to_all_teacher = true;
    this.timeSlotList.push(timeslot);
    timeslot.instance.removeTimeSlot.subscribe(event => this.destroyComponent(timeslot));
    timeslot.instance.changedSlotValue.subscribe(event => this.refreshSearchResult());
    this.refreshSearchResult();
  }

  destroyComponent(timeslot) {
    timeslot.destroy();
    this.timeSlotList = this.timeSlotList.filter(component => component !== timeslot);
    this.refreshSearchResult();
  }

  getNewEvents() {
    return this.timeSlotList
      .filter(slot => slot.instance.event.start && slot.instance.event.end) // on ignore les events dont le start et le end ne sont pas définis
      .map(slot => slot.instance.event)
  }

  getEventsToDelete() {
    return this.events
      .filter(event => event.edit_delete)
  }

  getEventsToExtend() {
    if (this.allocationMode === 'best') {
      return [];
    } else {
      return this.events.filter(event => event.edit_extend)
    }
  }

  checkIfEventExist() {
    let isExist = false;
    this.timeSlotList.forEach(liste => {
      if (liste.instance.event.start) {
        const new_id = liste.instance.event.id
        const new_start = moment(liste.instance.event.start)
        const new_end = moment(liste.instance.event.end)
        this.events.forEach(event => {
          const start = moment(event.start)
          const end = moment(event.end)
          const event_start = moment(new_start.format('YYYY') + '-' + new_start.format('MM') + '-' + new_start.format('DD') + ' ' + start.format('HH') + ':' + start.format('mm'))
          const event_end = moment(new_end.format('YYYY') + '-' + new_end.format('MM') + '-' + new_end.format('DD') + ' ' + end.format('HH') + ':' + end.format('mm'))

          if (event_start.weekday() === start.weekday() &&
            (event_start.isSameOrAfter(new_start) && event_start.isBefore(new_end) || event_end.isAfter(new_start) && event_end.isSameOrBefore(new_end))
            && isExist === false
          ) {
            isExist = true;
            this.eventService.toastService.show('Un créneau existe déjà à cette heure !', 4000, 'red');
          }
        });

        for (let i = 0; i < this.timeSlotList.length; i++) {
          const event = this.timeSlotList[i].instance.event;
          if (new_id === event.id) {
            continue;
          }
          const start = moment(event.start)
          const end = moment(event.end)

          if (new_start.weekday() === start.weekday() &&
            (start.isSameOrAfter(new_start) && start.isBefore(new_end) || end.isAfter(new_start) && end.isSameOrBefore(new_end))
            && isExist === false
          ) {
            isExist = true;
            this.eventService.toastService.show('Vous essayez d\'ajouter deux fois le meme créneau !', 4000, 'red');
          }
        }
      }
    });
    return isExist;
  }

  getTeachersToDeleteByEvent() {
    let teachersToReplace = [];
    this.events.forEach(event => {
      let teacher_list = []
      event.teachers.forEach(teacher => {

        const isFormationDelete = this.formation.teachers.filter(_teacher => _teacher.edit_delete && _teacher.id === teacher.id)
        if (teacher.edit_delete === true || isFormationDelete.length > 0) {
          teacher_list.push(teacher);
        }
      })
      teachersToReplace.push({ 'id': event.id, 'teacher_list': teacher_list });
    });
    return teachersToReplace;
  }

  toggleTeacherDelete(teacher) {
    teacher.edit_delete = !teacher.edit_delete;
    this.refreshSearchResult();
  }

  toggleEventDelete(event) {
    event.edit_delete = !event.edit_delete;
    this.refreshSearchResult();
  }

  toggleEventExtend(event) {
    event.edit_extend = !event.edit_extend;
    this.refreshSearchResult();
  }

  searchSolution(simulation) {
    const isEventExist = this.checkIfEventExist();
    if (isEventExist) {
      return;
    }
    this.searchPlanResult = null;
    this.loaderSearchSolution = true;
    const param = {
      formation: this.formation.id,
      algorithm: 'searchsolution',
      allocation_mode: this.allocationMode,
      new_events: this.getNewEvents(),
      events_to_delete: this.getEventsToDelete(),
      events_to_extend: this.getEventsToExtend(),
      teachers_to_replace: this.getTeachersToDeleteByEvent(),
      simulation: simulation,
    }

    this.planService.postPlan(param)
      .then(data => {
        if (data) {
          this.searchPlanResult = data;
          this.loaderSearchSolution = false;

          if (this.searchPlanResult.simulation && this.searchPlanResult.status === 'ok') {
            this.eventService.toastService.show('Recherche d\'une solution terminée', 4000, 'green');
          }
          if (this.searchPlanResult.simulation && this.searchPlanResult.status !== 'ok') {
            this.eventService.toastService.show('Erreur lors de la recherche d\'une solution', 4000, 'red');
          }
          if (this.searchPlanResult.write_result && this.searchPlanResult.simulation === false && this.searchPlanResult.status !== 'ok') {
            this.eventService.toastService.show('Erreur lors de l\'écriture du plan', 4000, 'red');
          }

          if (this.searchPlanResult.write_result && this.searchPlanResult.simulation === false && this.searchPlanResult.status === 'ok') {
            this.formation = this.searchPlanResult.formation;
            this.handleGeneratePlanning(this.formation);
            this.updateOccurenceList();
            this.eventService.toastService.show('Ecriture du plan terminée', 4000, 'green');
            this.refresh_component();

          }
        }
      });

  }

  opentDebugPanel(matrice) {
    let config = new OverlayConfig();

    config.positionStrategy = this.overlay.position()
      .global()
      .left(`${this.nextPosition}px`)
      .top(`${this.nextPosition}px`);

    config.hasBackdrop = true;

    this.overlayRef = this.overlay.create(config);

    this.overlayRef.backdropClick().subscribe(() => {
      this.closeOverlay();
    });

    const overlay = this.overlayRef.attach(new ComponentPortal(DebugMatriceComponent, this.viewContainerRef));
    overlay.instance.matrice = matrice;
  }

  closeOverlay() {
    this.overlayRef.dispose();
  }

  getNumberHoursByTeacher(occs) {
    let result = [];
    occs.reduce(function (res, value) {
      if (!res[value.teacher.id]) {
        res[value.teacher.id] = { teacher: value.teacher, duration: 0 };
        result.push(res[value.teacher.id])
      }
      res[value.teacher.id].duration += value.duration;
      return res;
    }, {});
    return result;
  }

  /**
   * a appeler pour lancer la mise à jour des autres vues et composants
   * @param value
   */
  handleGeneratePlanning(_formation) {
    this.handleUpdateFormation.emit(_formation);
    this.handleUpdateOccurenceList.emit();
  }

  updateOccurenceList() {
    this.handleUpdateOccurenceList.emit();
  }

  handleUpdateAllocationMode(mode) {
    this.allocationMode = mode;
  }
}
