import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';





@Injectable()
export class DatatableService {

  constructor(private _http: HttpClient) { }

  private extractData(res: Response) {
    const body = res.json();
    return body || [];
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
