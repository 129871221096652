/**
 * Component pour l'affichage des upcomings.
 * Intègre son propre routings avec des sous-composants.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upcoming-classes',
  templateUrl: './upcoming-classes.component.html',
  styleUrls: ['./upcoming-classes.component.scss']
})
export class UpcomingClassesComponent implements OnInit {
  isUpcoming = true;
  constructor() { }

  ngOnInit() {
  }

}
