<div class="dashboard-pagetitle page-title">Dashboard</div>

<!-- Prochain cours -->
<div class="nextclass-wrapper">
		<div>
			<app-dashboard-teacher-next-session></app-dashboard-teacher-next-session>
		</div>	
</div>

<!-- Billing -->
<div class="billing-wrapper">
	<div class="dashboard-spacetitle">
		<span translate>{{translatePath}}.billing</span>
	</div>
	<div>
		<app-dashboard-teacher-billing></app-dashboard-teacher-billing>
	</div>
</div>


<!-- Notifications -->
<div class="notifications-wrapper">
	<div class="dashboard-spacetitle">
		<span translate>{{translatePath}}.news</span>
	</div>
	<app-dashboard-teacher-notification></app-dashboard-teacher-notification>
</div>