<div id="listing-recurring">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
            <h5 class="page-title">Liste des Fiches d'apprentissage</h5>
        </div>

        <div class="col right">
            <button class="action-button addnew-button" mz-button routerLink="/exercices/sheet-create/">
                Nouvelle Fiche
                <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'"></i>
            </button>
        </div>
    </div>

    <div class="row">
        <h6 class="results-counter">{{ resultsLength }} fiches trouvées</h6>
    </div>

    <div class="row"></div>

    <div class="row">
        <div class="col l6 left"> 
            <div class="row">
                <div>
                    <button class="select-all-btn" (click)="clearFilter()"> Vider les filtres </button>
                    <button class="select-all-btn" (click)="selectAllPage()">
                        <span *ngIf="modeAllPage==true; else unSelectAll">Déséléctionner toutes les pages</span>
                        <ng-template #unSelectAll>Séléctionner toutes les pages</ng-template>
                    </button>  
                    ligne(s) selectionée(s) : <ng-container *ngIf="modeAllPage==false; else countAll"> <span>{{ selection.selected.length}}</span></ng-container>
                    <ng-template #countAll><span>{{ selection.selected.length }}</span></ng-template>
                </div>
            </div>      
        </div>
        <div class="col l2 right">
                <div [formGroup]="bulkActionFormGroup">
                    <mat-select formControlName="statusBulkUpdate" class="bulk-select" placeholder="Action par lot">
                        <mat-option value=""> </mat-option>
                        <mat-option *ngFor="let status of sheetsStatus"  (click)="bulkUpdate(status.label)" [value]="status.code"> 
                        {{status.label}}
                        </mat-option>
                    </mat-select>
                </div>
        </div>
    </div>


    <div class="row datatable-position">
    <!-- CONTENT PAGE -->
                    <div>
                        <div class="example-loading-shade" *ngIf="isLoadingResults">
                            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        </div>

                        <div [formGroup]="filterFormGroup">
                            
                            <table mat-table [dataSource]="exercicesSheets" class="reccurring-table"
                                matSort matSortActive="id" matSortDisableClear matSortDirection="desc">
                                
                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                                        [checked]="selection.hasValue() && isPageSelected()"
                                                        [indeterminate]="selection.hasValue() && !isPageSelected()"
                                                        [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                                        (change)="$event ? selection.toggle(row.id) : null"
                                                        [checked]="selection.isSelected(row.id)"
                                                        [aria-label]="checkboxLabel(row.id)">
                                        </mat-checkbox>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- Checkbox Description Column -->
                                <ng-container matColumnDef="select-description">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                </ng-container>

                                <!-- ID Column -->
                                <ng-container matColumnDef="id">
                                    <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.id}}</td>
                                    <td mat-footer-cell *matFooterCellDef> Total Page</td>
                                </ng-container>

                                <!-- ID Description Column -->
                                <ng-container matColumnDef="id-description">
                                    <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="id"/> </th>
                                </ng-container>

                                <!-- title_fr Column -->
                                <ng-container matColumnDef="title_fr">
                                    <th class="title_fr-header" mat-header-cell *matHeaderCellDef mat-sort-header>Titre</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.title_fr}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- title_fr Description Column -->
                                <ng-container matColumnDef="title_fr-description">
                                    <th mat-header-cell *matHeaderCellDef> <input name="filter-title_fr" formControlName="title_fr"/> </th>
                                </ng-container>
                                
                                <!-- language Column -->
                                <ng-container matColumnDef="language">
                                    <th class="language-header" mat-header-cell *matHeaderCellDef mat-sort-header>Langue</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">
                                    <app-lang-flag [language]="row.language"></app-lang-flag>
                                        {{row.language.label}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- language Description Column -->
                                <ng-container matColumnDef="language-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="language" class="header-select">
                                            <mat-option *ngFor="let language of languageList"  [value]="language.id">
                                            {{language.label}}
                                            </mat-option>
                                        </mat-select>
                                    </th>
                                </ng-container>

                                <!-- cecrl_level Column -->
                                <ng-container matColumnDef="cecrl_level">
                                    <th class="cecrl_level-header" mat-header-cell *matHeaderCellDef mat-sort-header>Niveau</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.cecrl_level}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- cecrl_level Description Column -->
                                <ng-container matColumnDef="cecrl_level-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="cecrl_level" class="header-select">
                                            <mat-option *ngFor="let level of cecrlLevelList"  [value]="level">
                                            {{level}}
                                            </mat-option>
                                        </mat-select>
                                     </th>
                                </ng-container>

                                <!-- category Column -->
                                <ng-container matColumnDef="category">
                                    <th class="category-header" mat-header-cell *matHeaderCellDef mat-sort-header>Catégorie</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.category.name}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- category Description Column -->
                                <ng-container matColumnDef="category-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="category" class="header-select">
                                            <mat-option *ngFor="let category of categoryList"  [value]="category.id">
                                            {{category.name}}
                                            </mat-option>
                                        </mat-select>
                                     </th>
                                </ng-container>

                                <!-- specific_content Column -->
                                <ng-container matColumnDef="specific_content">
                                    <th class="specific_content-header" mat-header-cell *matHeaderCellDef mat-sort-header>Groupes exclusifs</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{getGroupList(row.specific_content)}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- specific_content Description Column -->
                                <ng-container matColumnDef="specific_content-description">
                                    <th mat-header-cell *matHeaderCellDef> <input name="filter-specific_content" formControlName="specific_content"/> </th>
                                </ng-container>

                                <!-- status Column -->
                                <ng-container matColumnDef="status">
                                    <th class="status-header" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.status}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- status Description Column -->
                                <ng-container matColumnDef="status-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="status" class="header-select">
                                            <mat-option *ngFor="let status of sheetsStatus"  [value]="status.code">
                                            {{status.label}}
                                            </mat-option>
                                        </mat-select>
                                    </th>
                                </ng-container>

                                <!-- nb_exercices Column -->
                                <ng-container matColumnDef="nb_exercices">
                                    <th class="nb_exercices-header" mat-header-cell *matHeaderCellDef mat-sort-header>Exercices (Total)</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.nb_exercices}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- nb_exercices Description Column -->
                                <ng-container matColumnDef="nb_exercices-description">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                </ng-container>

                                <!-- nb_hard_exercices Column -->
                                <ng-container matColumnDef="nb_hard_exercices">
                                    <th class="nb_hard_exercices-header" mat-header-cell *matHeaderCellDef mat-sort-header>Exercices (Difficile)</th>
                                    <td class="sheet-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{ row.nb_hard_exercices }}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- nb_hard_exercices Description Column -->
                                <ng-container matColumnDef="nb_hard_exercices-description">
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                </ng-container>
                                

                            <tr mat-header-row class="first-line-header" *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-header-row class="second-line-header" *matHeaderRowDef="['select-description',
                                'id-description',
                                'title_fr-description',
                                'language-description',
                                'cecrl_level-description',
                                'category-description',
                                'specific_content-description',
                                'status-description',
                                'nb_exercices-description',
                                'nb_hard_exercices-description']">
                            </tr>
                            <tr mat-row  *matRowDef="let row; columns: displayedColumns;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

                            </table>
                        </div>

                        <div>
                            <mat-paginator [length]="resultsLength" class="paginator" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
                        </div>
                    </div>
                    <!-- FIN CONTENT PAGE -->
    </div>
</div>