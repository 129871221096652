/**
 * Composant les sous-composants de la page d'édition de l'administration.
 */

import { Component, OnInit, Input } from '@angular/core';
import { NewTeacher } from '../../../../../../model/new-teacher.model';

@Component({
  selector: 'app-office-space',
  templateUrl: './office-space.component.html',
  styleUrls: ['./office-space.component.scss', '../edit-teacher.ux.scss', '../../../mainscreen.ux.scss']
})
export class OfficeSpaceComponent implements OnInit {
  @Input() myTeacher: NewTeacher;
  refresh: boolean;
  @Input() parentComponent;
  constructor() { }

  ngOnInit() {
  }

}
