<div class="flexparent media-wrap">
  <div class="media-preview-wrap flexchild flexparent">
      <div *ngIf="object.media">
        <div class="media-preview" *ngIf="object.media?.media_type=='image'">
          <img src="{{ object.media.url }}" width="150px" />
          <div class="media-info flexparent">
            <span class="media-url flexchild">Image selectionnée : {{ object.media.filename }}</span>
            <a class="media-delete flexchild" (click)="deleteMedia()">Supprimer</a>
          </div>
        </div>
        <div class="media-preview" *ngIf="object.media?.media_type=='video'">
          <div [innerHtml]="iframe_video"></div>
          <div class="media-info flexparent">
            <span class="media-url flexchild">Video {{ object.media.plateformType }} : {{ object.media.url }}</span>
            <a class="media-delete flexchild" (click)="deleteMedia()">Supprimer</a>
          </div>
        </div>
      </div>
  </div>
    <div class="media-select-wrap flexchild">
      <b>Insérer :</b>
      <div class="addmedia-button" #upload mz-button (click)="image.click()">
        <span class="addimg-button"></span>Image
      </div>
      <input #image type="file" (change)="uploadFile($event, 'image')" [hidden]="true" />
      <div class="addmedia-button" #upload mz-button (click)="object.media.media_type = 'video'">
          <span class="addvid-button"></span>Video
      </div>
      
      <div *ngIf="object.media">
        <div *ngIf="object.media.media_type=='video'">
          <div class="platform-pick flexparent centeralign">
            <span class="noshrink flexchild">Plateforme : </span>
            <mat-select [formControl]="plateformType" class="header-select">
              <mat-option *ngFor="let plateform of plateformTypeList"  [value]="plateform">
                {{plateform}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="plateformType.touched && plateformType.hasError('required')">Ce champs est requis</mat-error>
          </div>
          <div class="url-row flexparent">
              <span class="noshrink flexchild">Url : </span>
            <input class="flexchild url-field" name="videoUrl" type="text"  [formControl]="videoUrl"  />
            <mat-error *ngIf="videoUrl.touched && videoUrl.hasError('required')">Ce champs est requis</mat-error>
          </div>
          <div class="savemedia-btn" mz-button  (click)="saveVideoEmbeded()">
            Save
          </div>
        </div>
      </div>
    </div>
</div>