<div id="browse-sheets" >
    <div class="assign-header">
        <span class="page-title" translate>teacher.sheets.title</span>
    </div>

    <div class="assign-mainwrap">

        <div class="sheetslists-wrap">
            <app-sheet-assign 
                [formation]="null"
                [occurrence]="null" 
                [validatedByStudent]="null"
                [reportAddedSheet]="false" 
                (emitSheetToView)="showSheet($event)" 
                (isSheetAdded)="null" >
            </app-sheet-assign>
        </div>

        <div class="desktop-sheet-viewer">
            <app-sheet-view [sheet]="sheet" [showExercices]="false" [showExamination]="false" [filterByStudent]=""></app-sheet-view>
        </div>

    </div>

</div>