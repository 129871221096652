import { FormControl } from '@angular/forms';
import { SearchPlanSolution } from './../../../../model/searchPlanSolution.model';
import { TimeSelectorNoDispoComponent } from './../../../../feature/time-selector-no-dispo/time-selector-no-dispo.component';
import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Formation } from '../../../../model/formation.model';
import { EventService } from '../../../../service/event.service';
import { PlanService } from '../../../../service/plan.service';
import { FormationService } from '../../../../service/formation.service';
import * as moment from 'moment';
import { InitAppService } from '../../../../service/initapp.service';
import { MatDatepickerInputEvent, DateAdapter } from '@angular/material';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { sortBy, last, head } from "lodash";
import { Occurrence } from '../../../../model/occurrence.model';

/**
 * Etape 3 plan-formation : définition des créneaux.
 * Intègre sous composant local pour l'édition des créneaux.
 */
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: [
    './schedule.component.scss',
    '../plan-formation.component.scss',
    './schedule.ux.scss',
    '../plan-formation.ux.scss'
  ],
})
export class ScheduleComponent implements OnInit, AfterViewInit {
  @ViewChild('timeslotcontainer', { read: ViewContainerRef }) entry: ViewContainerRef;

  translatePath = 'lingueopro.plan-formation.schedule';
  formation: Formation;
  timeSlotList: any = [];
  searchPlanResult: SearchPlanSolution;
  loaderSearchSolution = false;
  loadComponent = false;
  nbHoursByWeek;
  countFailureSearchSolution = 0;
  startTrainingDate = new FormControl();
  minStartTraningDate = null;
  firstOccurrence: Occurrence;
  lastOccurrence: Occurrence;

  constructor(
    private formationService: FormationService,
    private eventService: EventService,
    private planService: PlanService,
    private resolver: ComponentFactoryResolver,
    public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private initAppService: InitAppService,
    private scrollToService: ScrollToService,
    private adapter: DateAdapter<any>
  ) { this.adapter.setLocale('fr'); }

  ngOnInit() {
    const currentFormationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, currentFormationId);
    this.formationService.currentFormationSubject.subscribe(formation => {
      this.formation = formation;
      if (formation) {
        this.initFormation();
        this.initMinStartTraningDate();
        this.initDefaultValueStartFormation();
      }
    });
  }

  initMinStartTraningDate() {
    const validityStartFrom = moment(this.formation.validity_period_from);
    const now = moment();
    if (validityStartFrom.isBefore(now)) {
      this.minStartTraningDate = now.add(1, 'day').format();
    } else {
      this.minStartTraningDate = validityStartFrom.format();
      if (validityStartFrom.startOf('day') === now.startOf('day')) {
        this.minStartTraningDate = now.add(1, 'day').format();
      }
    }
  }

  initDefaultValueStartFormation() {
    if (!this.formation.start_from) {
      const validityStartFrom = moment(this.formation.validity_period_from);
      const now = moment();
      if (validityStartFrom.isBefore(now)) {
        this.startTrainingDate.setValue(now.add('1', 'day').format());
      } else {
        if (validityStartFrom.startOf('day') === now.startOf('day')) {
          this.startTrainingDate.setValue(validityStartFrom.add('1', 'day').format());
        } else {
          this.startTrainingDate.setValue(validityStartFrom.format());
        }
      }
    } else {
      this.startTrainingDate.setValue(this.formation.start_from);
    }
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

  ngAfterViewInit() {
    this.formationService.currentFormationSubject.subscribe(formation => {
      this.formation = formation;
      if (formation) {
        this.initSlot();
      }
    });
  }

  initSlot() {
    if (this.formation.mode_financement === 'fne') {
      this.addTimeSlot(2);
    } else {
      this.addTimeSlot(1);
    }
  }

  updateStartDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const params = {
        start_from: event.value,
      }
      this.formationService.patchObservableFormation(this.formation.url, params).subscribe(result => {
        this.formation.start_from = result.start_from;
        this.initFormation();
      });
    }
  }

  initFormation() {
    const startFormation = this.formation.start_from ? this.formation.start_from : this.formation.validity_period_from;
    const nbWeek = moment(this.formation.validity_period_to).diff(moment(startFormation), 'week');
    this.nbHoursByWeek = (Math.ceil((this.formation.hours_total / nbWeek) * 2)) / 2;
  }

  addTimeSlot(numberOfSlot) {
    if (this.entry) {
      for (let index = 1; index <= numberOfSlot; index++) {
        const factory = this.resolver.resolveComponentFactory(TimeSelectorNoDispoComponent);
        const timeslot = this.entry.createComponent(factory)
        timeslot.instance.formation = this.formation;
        timeslot.instance.open_to_all_teacher = true;
        timeslot.instance.loaderSearchSolution = this.loaderSearchSolution;
        this.timeSlotList.push(timeslot);
        timeslot.instance.removeTimeSlot.subscribe(event => this.destroyComponent(timeslot));
        timeslot.instance.changedSlotValue.subscribe(event => this.refreshSearchResult());
        // On lance la detection de changement après le set des variables et déclencher l'init du composant dynamique.
        timeslot.changeDetectorRef.detectChanges();
        this.refreshSearchResult();
      }
    }
  }

  refreshSearchResult() {
    this.searchPlanResult = null;
  }

  destroyComponent(timeslot) {
    timeslot.destroy();
    this.timeSlotList = this.timeSlotList.filter(component => component !== timeslot);
    this.refreshSearchResult();
  }

  getNewEvents() {
    return this.timeSlotList
      .filter(slot => slot.instance.event.start && slot.instance.event.end) // on ignore les events dont le start et le end ne sont pas définis
      .map(slot => slot.instance.event)
  }

  checkIfEventExist() {
    let isExist = false;
    const format = 'hh:mm';
    this.timeSlotList.forEach(liste => {
      if (liste.instance.event.start) {
        const new_id = liste.instance.event.id
        const new_start = moment(liste.instance.event.start)
        const new_end = moment(liste.instance.event.end)
        const new_start_time = moment(new_start.utc().format('HH:mm'), format);
        const new_end_time = moment(new_end.utc().format('HH:mm'), format);
        for (let i = 0; i < this.timeSlotList.length; i++) {
          const event = this.timeSlotList[i].instance.event;
          if (new_id === event.id) {
            continue;
          }
          const start = moment(event.start)
          const end = moment(event.end)
          const start_time = moment(start.utc().format('HH:mm'), format)
          const end_time = moment(end.utc().format('HH:mm'), format)
          const isBetween = new_start_time.isBetween(start_time, end_time, undefined, '[)');
          const isSame = new_start_time.isSame(start_time) && new_end_time.isSame(end_time);
          if (new_start.weekday() === start.weekday() &&
            (start.isSameOrAfter(new_start) && start.isBefore(new_end) || end.isAfter(new_start) && end.isSameOrBefore(new_end))
            //(isBetween || isSame)
            && isExist === false
          ) {
            isExist = true;
            this.eventService.toastService.show('Vous essayez d\'ajouter deux fois le meme créneau !', 4000, 'red');
          }
        }
      }
    });
    return isExist;
  }

  setFirstAndLastOccurrence(events_added) {
    if (!events_added.length) {
      return;
    }
    const plannedOccurrences = events_added
      .map(events => {
        return events.occurrences
      })
      .reduce((acc, next) => acc.concat(next));
    const orderedOccurrences = sortBy(plannedOccurrences, ['start']);
    this.firstOccurrence = head(orderedOccurrences).start;
    this.lastOccurrence = last(orderedOccurrences).end;
  }

  setStateTimeSlot(state) {
    this.timeSlotList.map(slot => {
      slot.instance.loaderSearchSolution = state
    })
  }

  searchSolution(simulation) {
    const isEventExist = this.checkIfEventExist();
    if (isEventExist) {
      return;
    }
    this.searchPlanResult = null;
    this.loaderSearchSolution = true;
    this.setStateTimeSlot(true);
    const param = {
      formation: this.formation.id,
      algorithm: 'searchsolution',
      allocation_mode: 'linear_date_around',
      open_to_all_teacher: true,
      new_events: this.getNewEvents(),
      events_to_delete: [],
      events_to_extend: [],
      teachers_to_replace: [],
      simulation: simulation,
      completion_control: false,
    }
    if (this.formation.booking_mode === 'rec') {
      param.completion_control = true;
    }
    this.planService.postPlan(param)
      .then(data => {
        if (data) {
          this.setFirstAndLastOccurrence(data.events_added);

          this.searchPlanResult = data;
          this.loaderSearchSolution = false;
          this.setStateTimeSlot(false);
          // compteur d'echec, si une simulation n'est pas valide
          if (this.searchPlanResult.simulation && this.searchPlanResult.is_valid.status === false) {
            this.countFailureSearchSolution += 1;
          }
          // compteur d'echec, si une simulation est valide, alors on reset le compteur
          if (this.searchPlanResult.simulation && this.searchPlanResult.is_valid.status === true) {
            this.countFailureSearchSolution = 0;
          }
          if (this.searchPlanResult.simulation && this.searchPlanResult.status === 'ok') {
            this.eventService.toastService.show('Recherche d\'une solution terminée', 4000, 'green');
          }
          if (this.searchPlanResult.simulation && this.searchPlanResult.status !== 'ok') {
            this.eventService.toastService.show('Erreur lors de la recherche d\'une solution', 4000, 'red');
          }
          if (this.searchPlanResult.write_result && this.searchPlanResult.simulation === false && this.searchPlanResult.status !== 'ok') {
            this.eventService.toastService.show('Erreur lors de l\'écriture du plan', 4000, 'red');
          }
          const config: ScrollToConfigOptions = {
            target: 'bottom-page'
          };

          this.scrollToService.scrollTo(config);

          // si tout est ok, on passe à l'ecran suivant
          if (this.searchPlanResult.write_result && this.searchPlanResult.simulation === false && this.searchPlanResult.status === 'ok') {
            this.formationService.currentFormationSubject.next(this.searchPlanResult.formation);
            this.router.navigate(['plan-formation/conclusion', this.formation.id]);
          }
        }
      }).catch(error => {
        this.refreshSearchResult();
        this.eventService.toastService.show('Erreur lors de la recherche', 4000, 'red');
      });

  }

}
