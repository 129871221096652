/**
 * Sous-composant pour la liste des formations d'un teacher.
 */

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KiwixiGlobals } from '../../../../../../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Teacher } from '../../../../../../../model/teacher.model';
import { Formation } from '../../../../../../../model/formation.model';
import { FormationService } from '../../../../../../../service/formation.service';
import { EditTeacherService } from '../../edit-teacher.service';

@Component({
  selector: 'app-formations-and-students',
  templateUrl: './formations-and-students.component.html',
  styleUrls: [
    './formations-and-students.component.scss',
    './formations-and-students.ux.scss',
    '../../edit-teacher.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [FormationService, EditTeacherService]
})
export class FormationsAndStudentsComponent implements OnInit {
  @Input() myTeacher: Teacher;
  currentIdTeacher: number;
  dataFormations: Object;
  pageSize: number;
  source: string;
  countPages = 1;
  formations: Formation[];
  showTableFormations = false;
  isAcceptStudents: boolean;
  isDisable: boolean;
  totalData: number;

  /**
   * liste des colonnes du tableau des formations
   */
  public dataHeadersEditTeacher = [
    {
      name: 'status',
      title: 'Statut',
      type: 'string',
      ascending: false,
      filter_key: 'status__icontains',
      ordering_key: 'status',
      filter_type: 'select',
      list: this.formationService.getStatus(),
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'student',
      title: 'Élèves',
      type: 'student',
      ascending: false,
      filter_key: 'student_name',
      ordering_key: 'student__user__last_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'myFormation',
      title: 'Formation',
      type: 'formation',
      ascending: false,
      filter_key: 'name__icontains',
      ordering_key: 'name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'next_occurrence',
      title: 'Prochain cours',
      type: 'next_occurrence',
      ascending: false,
      filter_key: 'validity_date',
      ordering_key: 'start',
      filter_type: 'hour',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'site',
      title: 'Site',
      type: 'site',
      ascending: false,
      filter_key: 'site__name__icontains',
      ordering_key: 'site__name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    }
  ]

  constructor(private route: ActivatedRoute,
    private kiwixiGlobals: KiwixiGlobals,
    private router: Router,
    private editTeacherService: EditTeacherService,
    private formationService: FormationService) { }

  ngOnInit() {
    this.isAcceptStudents = this.myTeacher.accept_students;
    if (this.myTeacher.status !== 'accepted') {
      this.isDisable = true;
    }
    this.route.params.subscribe(params => {
      this.currentIdTeacher = params['id'];
    });
    this.initDataTable();
  }

  /**
   * méthode pour mettre à jour un prof.
   * @param teacher
   * @param field
   */
  updateTeacher(teacher, field) {
    if (this.isAcceptStudents !== this.myTeacher.accept_students) {
      this.myTeacher.accept_students = this.isAcceptStudents;
      const param = {};
      param[field] = teacher[field];
      this.editTeacherService.updateTeacher(teacher.url, param).catch(error => {
        //
      });
    }
  }

  /**
   * méthode pour initaliser les variables du tableau.
   */
  initDataTable() {
    this.pageSize = 100;
    this.formations = [];
    this.dataFormations = {
      data: [],
      headers: this.dataHeadersEditTeacher
    }
    const _filterParams = [
      {
        key: 'page',
        value: this.countPages.toString()
      }
    ]
    _filterParams['filtering'] = { 'teachers': this.currentIdTeacher };
  }

  /**
   * méthode pour récupérer les formations d'un teacher.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = [];
    }
    _filterParams['filtering']['teachers'] = this.currentIdTeacher;
    this.formationService.getFormations(_filterParams)
      .then(
        res => {
          this.totalData = res['count'];
          if (_countPages === 1) {
            this.dataFormations['data'] = [];
          }
          const _formations = res['results']
          for (const _formation of _formations) {
            _formation.myFormation = _formation;
            this.dataFormations['data'].push(_formation);
          }
          this.showTableFormations = true;
        }
      );
    return this.dataFormations['data'];
  }

  /**
   * méthode pour naviguer vers une formation.
   * @param row
   */
  public showDetail(row) {
    const _id = this.kiwixiGlobals.getIdFromUrl(row.url);
    this.router.navigate(['/formations', _id]);
  }

}
