<div class="row">
  <div class="col l2">
    <div class="row planner-row" *ngFor="let step of rows" style="min-height: 1.55rem; max-height: 1.55rem;">
        <div class="time-planner">{{step.time | amLocal | amDateFormat: 'HH:mm'}}</div>
    </div>
  </div>
  <div class="col l10" style="margin-top:0.6rem">
    <div class="select-time-row" *ngFor="let step of rows; let idx=index">
      <div>
          <div class="isOpen" *ngIf="step.open" [hidden]="step.select" (click)="selectRow(step, idx)">{{step.label}}</div>
          <div class="isSelect" *ngIf="step.select" (click)="selectRow(step, idx)">{{step.label}}</div>
          <div class="" *ngIf="!step.open"></div>
      </div>
    </div>
  </div>
</div>
<!-- <p appDownloadFile>Highlighted in orange</p> -->
