/**
 * Service local pour la liste des teachers
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';



import { KiwixiService } from '../../../../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { TeacherService } from '../../../../../service/teacher.service';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class TeachersListService extends KiwixiService {
  apiTeachers = environment.server + 'teachers/';

  // data pour les colonnes
  public dataHeaders = [
    {
      name: 'id',
      title: 'ID',
      type: 'string',
      ascending: false,
      filter_key: 'id',
      ordering_key: 'user__id',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'teacher',
      title: 'Professeur',
      type: 'teacher',
      ascending: false,
      filter_key: 'name',
      ordering_key: 'user__last_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'teacher_email',
      title: 'Email du Professeur',
      type: 'string',
      ascending: false,
      filter_key: 'user__email__icontains',
      ordering_key: 'user__email',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'teach_language',
      title: 'Langue enseignée',
      type: 'language',
      ascending: false,
      filter_key: 'teach_language__label__icontains',
      ordering_key: 'teach_language__label',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'languages',
      title: 'Langues parlées',
      type: 'string',
      ascending: false,
      filter_key: 'languages__label__icontains',
      ordering_key: 'languages__label',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'status',
      title: 'Statut',
      type: 'string',
      ascending: false,
      filter_key: 'status__icontains',
      ordering_key: 'status',
      filter_type: 'select',
      list: this.getStatus(),
      cell_edit: false,
      isEdit: false
    }
  ]

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private teacherService: TeacherService,
    public toastService: MzToastService,
    kiwixiGlobals: KiwixiGlobals) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  /**
   * Appel API pour récupérer les teachers
   * @param filterParams
   */
  getTeachers(filterParams) {
    return this.teacherService.getTeachers(filterParams);
  }

  /**
   * Methode pour récuperer les status des teachers.
   */
  getStatus() {
    return this.teacherService.getStatus();
  }

}
