<div class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Fiches Parents</h6>
    </div>
  </div>
  <div class="row information-subcontainer">
     <div class="row sheet-row" *ngFor="let related of exercice.sheets">
        <div class="col l12 flexparent centeralign">
          <div class="flexchild nogrow sheet-icon"></div>
          <div class="flexchild sheet-title"> {{ related.title_fr}}</div>
          <div class="flexchild nogrow sheet-seebtn action-button"> <a target="_blank" routerLink="/exercices/sheet-edit/{{ related.id }}"> Voir fiche</a></div>
          <div class="flexchild nogrow sheet-deletebtn" (click)="deleteRelated(related.id)"></div>
        </div>
     </div>
    <div class="row" *ngIf="exercice.sheets.length==0">
      Aucune fiche connexe
    </div>
    <div class="row" >
      <button class="addsheet-btn action-button" mz-button (click)="showSearchInput()">
          <i class="material-icons left tiny">control_point</i>Utiliser dans une autre fiche
      </button>


      <input *ngIf="showSearchSheet" matInput type="text" name ="search" class="dropdown" [matAutocomplete]="autoTemplate" [formControl]="searchSheetInputControl" />
        <mat-autocomplete #autoTemplate="matAutocomplete" id="searchsheets" [class]="'test'" [displayWith]="displaySheetNameFn" [style.width]="'auto'">
         <mat-option *ngFor="let sheet of searchSheets" [value]="sheet" (click)="addRelation()">
          {{sheet.title_fr}}
         </mat-option>
        </mat-autocomplete>
    </div>


  </div>


</div>

