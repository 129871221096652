/**
 * Service local pour les Rapports.
 */

import { Injectable } from '@angular/core';
import { RateStudentService } from '../../../service/rate-student.service';

@Injectable()
export class ReportService {
  public subjectsItem = ['Grammar', 'Expression', 'Comprehension', 'Vocabulary', 'Accent'];

  constructor(
    private ratingStudentService: RateStudentService
  ) { }

  /**
   * Methode pour construire l'objet subjects.
   */
  getSubjects(_rate_teacher) {
    let _subjects = [];
    if (_rate_teacher) {
      _subjects = this.getLastReportData(_rate_teacher)
    } else {
      _subjects = this.initSubjects()

    }
    return _subjects;
  }

  /**
   * Methode pour verifier si le report existant est vierge
   */
  reportIsEmpty(_rate_teacher) {
    let isEmpty = true;
    this.subjectsItem.forEach(_item => {
      const name = _item.toLocaleLowerCase();
      if (Number(_rate_teacher[name]) > 0) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  /**
   * Methode initialiser le formBuilder du report
   */
  initFormBuider(_myForm, _rate_teacher) {
    Object.keys(_myForm.controls).forEach(field => {
      if (Number(_rate_teacher[field]) > 0) {
        const myObj = {}
        myObj[field] = {
          label: _rate_teacher['level_' + field],
          value: Number(_rate_teacher[field])
        }
        _myForm.patchValue(myObj);
      }
    });
  }

  /**
   * Methode pour construire un report vierge
   */
  initSubjects() {
    const _subjects = [];
    this.subjectsItem.forEach(_item => {
      const myObj = {
        label: _item,
        level: {
          start_label: null,
          end_label: null,
          value: 0
        },
        name: _item.toLowerCase()
      }
      _subjects.push(myObj);
    });
    return _subjects;
  }

  /**
   * Methode pour construire un report existant
   * @param _rate_teacher
   */
  getLastReportData(_rate_teacher) {
    const myLastReportData = [];
    this.subjectsItem.forEach(_item => {
      const name = _item.toLocaleLowerCase();
      const obj = {
        label: _item,
        level: {
          start_label: this.setStartLabel(_rate_teacher['level_' + name]),
          end_label: this.setEndLabel(_rate_teacher['level_' + name]),
          value: Number(_rate_teacher[name]),
          levelUp: false
        },
        name: name
      }
      myLastReportData.push(obj);
    });
    return myLastReportData
  }

  /**
   * Methode pour definir le start label
   * @param _start_label
   */
  setStartLabel(_start_label) {
    if (_start_label === 'C2') {
      _start_label = 'C1';
    }
    return _start_label;
  }

  /**
   * Methode pour definir le end label
   * @param _label
   */
  setEndLabel(_label) {
    let _end_label = null;
    switch (_label) {
      case 'A1':
        _end_label = 'A2'
        break;
      case 'A2':
        _end_label = 'B1'
        break;
      case 'B1':
        _end_label = 'B2'
        break;
      case 'B2':
        _end_label = 'C1'
        break;
      case 'C1':
        _end_label = 'C2'
        break;
      case 'C2':
        _end_label = 'C2'
        break;
      default:
        break;
    }
    return _end_label
  }

  /**
   * Methode pour enregistrer le report
   */
  register(_myForm, _pending, _status) {
    const myCompletedEvaluation = {
      status: _status,
    }
    Object.keys(_myForm.controls).forEach(field => {
      const control = _myForm.get(field);
      myCompletedEvaluation['level_' + field] = control.value.start_label;
      myCompletedEvaluation[field] = control.value.value;
    });
    return this.ratingStudentService.patchRatingStudent(_pending.session.rate_teacher.url, myCompletedEvaluation)
  }

}
