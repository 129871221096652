import { ExerciceMedia } from './exerciceMedia.model';
import { Exercice } from './exercice.model';
import { ExerciceAnswerChoice } from './exerciceAnswerChoice.model';
import { ExerciceAnswerText } from './exerciceAnswerText.model';
import { ExerciceResponse } from './exerciceResponse.model';

export class ExerciceQuestion {
    id: number;
    url: string;
    label: string;
    examination: boolean;
    exercice: Exercice;
    answers_choices: ExerciceAnswerChoice[];
    answers_texts: ExerciceAnswerText[];
    position: number;
    typology: string;
    duration: number;
    media: ExerciceMedia;
    response: ExerciceResponse;
    status: string;
    updated_at: Date;
    created_at: Date;
}
