import { Exercice } from './exercice.model';
export class ExerciceSession {
    id: number;
    url: string;
    student: URL;
    sheet: URL;
    session_type: string;
    exercices: Exercice[];
    is_validated: boolean;
    updated_at: Date;
    created_at: Date;
}
