export class RatingTeacher {
  url: string;
  student: string;
  teacher: string;
  value: number;
  short_description: string;
  message: string;
  reason: 2;
  session: string;
  date: Date;
  status: string;
  constructor() { }
}
