import { ExerciceSheet } from './../../../../../../../model/exerciceSheet.model';
import { Component, Input, AfterViewInit } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';

import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-sheet-view-content',
  templateUrl: './sheet-view-content.component.html',
  styleUrls: ['./sheet-view-content.component.scss']
})
export class SheetViewContentComponent implements AfterViewInit {
  @Input() exerciceSheet: ExerciceSheet;
  iframe_video;
  content;
  to_remember;

  constructor(
    private embedService: EmbedVideoService,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterViewInit() {
    if (this.exerciceSheet.media) {
      if (this.exerciceSheet.media.media_type === 'video' && this.exerciceSheet.media.source_type === 'url') {
        this.loadVideo();
      }
    }
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.exerciceSheet.content);
    this.to_remember = this.sanitizer.bypassSecurityTrustHtml(this.exerciceSheet.to_remember);

  }

  loadVideo() {
    this.iframe_video = this.embedService.embed(this.exerciceSheet.media.url);
  }
}
