/**
 * Point d'entree de l'application qui permet d'amocer les modules de chaques sites (Backoffice, Lingueopro, Teacher)
 */

import './polyfills.ts';
import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser'; import { environment } from './environments/environment';
import { BackofficeModuleNgFactory } from './app/application/backoffice/backoffice.module.ngfactory';
import { LingueoproModuleNgFactory } from './app/application/lingueopro/lingueopro.module.ngfactory';
import { TeacherModuleNgFactory } from './app/application/teacher/teacher.module.ngfactory';

enableProdMode();

declare function gtag(a: string, b: string, c: any): void;
declare function initCrisp(crispID);

// Index de location
const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);

/**
 * Function pour identifier le domaine/module actif.
 * @param environment
 */
function AnalyzeDomain(env: any) {
    let module = null;
    if (idx >= 0) {
        module = env.urls[idx].module;
    }
    return module;
}

// On recupère le domaine actif
const module_actif = AnalyzeDomain(environment);

// On initialise le token
if (localStorage.getItem('language') === null || localStorage.getItem('language') === undefined) {
    localStorage.setItem('language', 'fr');
}
localStorage.setItem('site', environment.urls[idx].module);
localStorage.setItem('domaine', environment.urls[idx].module);
localStorage.setItem('default_role', environment.urls[idx].default_role);

// Boostrap le module correpondant au domaine
gtag('config', environment.urls[idx].gtagID, { 'app_name': environment.urls[idx].module });
switch (module_actif) {
    case 'backoffice':
        platformBrowser().bootstrapModuleFactory(BackofficeModuleNgFactory);
        break;
    case 'teacher':
        platformBrowser().bootstrapModuleFactory(TeacherModuleNgFactory);
        initCrisp(environment.urls[idx].crispID)
        break;
    case 'lingueopro':
        platformBrowser().bootstrapModuleFactory(LingueoproModuleNgFactory);
        initCrisp(environment.urls[idx].crispID)
        break;
    default:
}
