import { Directive, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { DownloadLingueoService } from './download-lingueo.service';

@Directive({
  selector: '[appDownloadLingueo]',
  providers: [DownloadLingueoService]
})
export class DownloadLingueoDirective {
  @Input() document;
  @Input() invoice;
  @Input() canUpdateDownloaded;
  @Input() isInvoice;
  @Input() templateVersion;
  @Output() updateDownloaded = new EventEmitter();

  constructor(private downloadLingueoService: DownloadLingueoService) { }

  @HostListener('click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (this.isInvoice) {
      const _url = this.invoice.url + 'download/';
      this.downloadLingueoService.downloadDoc(_url, this.invoice['filename'])
        .subscribe((file: ArrayBuffer) => {
          const myFile = new Uint8Array(file);
          const blob = new Blob([myFile], { type: 'application/force-download' });
          const url = window.URL.createObjectURL(blob);
          const $a = $('<a />', {
            'href': url,
            'download': this.invoice['filename'],
            'text': 'click'
          }).hide().appendTo('body')[0].click();
        },
          error => {
            this.downloadLingueoService.handleError(error.statusText).catch(_res => {
              console.error(`error downloadDoc invoice ` + this.invoice['filename'], error);
            })
          });
    } else {
      this.downloadLingueoService.downloadDoc(this.document.download_url, this.document.name)
        .subscribe((file: ArrayBuffer) => {
          const myFile = new Uint8Array(file);
          const blob = new Blob([myFile], { type: 'application/force-download' });
          const url = window.URL.createObjectURL(blob);
          const $a = $('<a />', {
            'href': url,
            'download': this.document.name,
            'text': 'click'
          }).hide().appendTo('body')[0].click();
        },
          error => {
            this.downloadLingueoService.handleError(error.statusText).catch(_res => {
              console.error(`error downloadDoc document ` + this.document.name, error);
            })
          });
      if (this.updateDownloaded) {
        document['downloaded'] = true;
        this.updateDownloaded.emit(true);
      }
    }
  }
}
