<div class="chips-position">
  <mat-chip-list mdPrefix #chipList *ngIf="chipsList.length > 0">
    <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
      [removable]="true" (removed)="removeChip(chip)">
      {{ chip[label] | translate }}
      <mat-icon matChipRemove *ngIf="removable(chip)">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>
<div class="input-chips">
    <button class="addchip-button"
      [float]="true"
      [large]="false"
      mz-button
      (click)="addChip = !addChip">
      +
    </button>
  <div class="chip-field">
    <form [hidden]="!addChip">
      <input type="text"
      aria-label="Number" mdInput [formControl]="chipControl"
      [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let choice of choices | async"
          [value]="choice[label]"
          [disabled]="isDisabled(choice)"
          (focusout)="selectChoice(choice)">
          {{ choice[label] | translate }}
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</div>
