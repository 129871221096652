import { EventEmitter, Injectable } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import { AuthenticationService } from './authentification.service';
import { EventService } from './event.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { OccurrenceService } from './occurrence.service';
import { ModalEvent } from '../feature/modal-event/modal-event';

export enum eventType {
  availability = 'availability',
  ponctual_availability = 'ponctual_availability',
  unavailability = 'unavailability',
  ponctual_unavailability = 'ponctual_unavailability',
  occurrence = 'occurrence',
  occurrence_suspended = 'occurrence_suspended'
}

enum eventBackgColor {
  daily_availability = '#f0f8ff',
  daily_ponctual_availability = '#e5f2c2',
  daily_unavailability = '#fbedd7',
  daily_ponctual_unavailability = '#f2d5a9',
  daily_occurrence = '#005194',
  daily_occurrence_suspended = '#6d6d6d',
  general_availability = '#a9cdeb',
}

enum eventBorderColor {
  daily_availability = '#c9d9e6',
  daily_ponctual_availability = '#9cbaab',
  daily_unavailability = '#e3d9c9',
  daily_ponctual_unavailability = '#ba934a',
  daily_occurrence = '#0476bf',
  daily_occurrence_suspended = '#b6b6b6',
  general_availability = '#87abc9',
}

enum eventTextColor {
  daily_availability = '#293942',
  daily_ponctual_availability = '#2d5242',
  daily_unavailability = '#6e4941',
  daily_ponctual_unavailability = '#5e3934',
  daily_occurrence = '#FFF',
  daily_occurrence_suspended = '#FFF',
  general_availability = '#466880',
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  calendarComponent: FullCalendarComponent = null;
  device = this.authentification.getDeviceMode();

  constructor(
    private eventService: EventService,
    private authentification: AuthenticationService,
    private dialog: MatDialog,
    private occurrenceService: OccurrenceService
  ) { }

  setCalendar(calendar: FullCalendarComponent) {
    this.calendarComponent = calendar;
  }

  goToDate(date) {
    this.calendarComponent.getApi().gotoDate(date);
  }

  /**
   * Callback function au resize dans le calendar
   */
  handleEventResize(eventResizeInfo) {
    this.selectableCalendar(false);

    const newEvent = {
      start: moment(eventResizeInfo.event.start).toISOString(),
      end: moment(eventResizeInfo.event.end).toISOString(),
      url: eventResizeInfo.event.extendedProps.url
    }

    eventResizeInfo.event.remove();
    this.saveEvent(newEvent);
  }

  /**
   * Callback function au drag an drop dans le calendar
   */
  handleEventDrop(eventDropInfo) {
    this.selectableCalendar(false);

    const newEvent = {
      start: moment(eventDropInfo.event.start).toISOString(),
      end: moment(eventDropInfo.event.end).toISOString(),
      url: eventDropInfo.event.extendedProps.url
    }

    eventDropInfo.event.remove();
    this.saveEvent(newEvent);
  }

  /**
   * Renvoi true si l'event n'est pas dans le passé
   */
  isPastEvent(event): boolean {
    return (moment().diff(moment(event), 'minutes') < 0)
  }

  /**
   * Renvoi true si les deux events sont le meme jour 
   */
  isSameDay(start, end): boolean {
    return (moment(start).startOf('day').toDate().getTime() === moment(end).subtract(1, 'minute').startOf('day').toDate().getTime());
  }

  getAvailabilityEvents() {
    return this.calendarComponent.getApi().getEvents().filter(event =>
      event.extendedProps.type === eventType.availability
    )
  }

  getPonctualEvents() {
    return this.calendarComponent.getApi().getEvents().filter(event =>
      event.extendedProps.type === eventType.ponctual_availability || event.extendedProps.type === eventType.ponctual_unavailability
    )
  }

  getOccurenceEvents() {
    return this.calendarComponent.getApi().getEvents().filter(event =>
      event.extendedProps.type === eventType.occurrence || event.extendedProps.type === eventType.occurrence_suspended
    )
  }

  leadingZero = (num) => `0${num}`.slice(-2);

  formatTime = (date) => [date.getHours(), date.getMinutes(), date.getSeconds()].map(this.leadingZero).join(':');

  /**
   * Retourne les horraires des events au bon format
   */
   eventTimeFormat = (date):string  => {
    if (date.start && date.end)
      return moment(date.start).format('HH:mm') + '-' + moment(date.end).format('HH:mm');
    else if (date.start)
      return moment(date.start).format('HH:mm');
  }

  /**
   * Créé les events api vers le calendar
   */
  mapEventapiToCalendar(events, editable, backgroungColor, textColor, borderColor, recurring, type: eventType, classNames) {
    events.forEach((element) => {
      /**
       * Gestion de la fin de l'event pour eviter les soucis de timezone
       */
      if ((moment(element.end).format('mm') === '59' || moment(element.end).format('mm') === '29') && moment(element.end).format('HH:mm') !== '23:59') {
        element.end = moment(element.end).add(1, 'second');
      } else if (moment(element.end).format('HH:mm') === '00:00') {
        element.end = moment(element.end).subtract(1, 'second');
      }
      if (recurring) {
        const event: EventInput = {
          rrule:{
            freq: 'weekly',
            dtstart: element.start
          },
          duration :  this.leadingZero(moment(element.end).diff(moment(element.start), 'hours')).toString() + ':' + this.leadingZero((moment(element.end).diff(moment(element.start), 'minutes') % 60)).toString() + ':' + this.leadingZero(moment(element.end).seconds()).toString(),
          title: this.getTitle(element, type),
          classNames: classNames,
          color: backgroungColor,
          borderColor: borderColor,
          textColor: textColor,
          editable: editable,
          extendedProps: {
            type: type,
            url: element.url
          }
        };
        this.calendarComponent.getApi().addEvent(event);
      } else {
        const event: EventInput = {
          start: element.start,
          end: element.end,
          title: this.getTitle(element, type),
          color: backgroungColor,
          borderColor: borderColor,
          classNames: classNames,
          textColor: textColor,
          editable: moment().diff(moment(element.start), 'minutes') > 0 ? false : editable,
          extendedProps: {
            type: type,
            url: element.url
          }
        };
        this.calendarComponent.getApi().addEvent(event);
      }
    });
  }

  getTitle(event, type): string {
    switch (type) {
      case eventType.availability:
        return 'Weekly availability';
      case eventType.occurrence:
        return 'Lesson with ' + event.first_name + ' ' + event.last_name;
      case eventType.occurrence_suspended:
        return 'Lesson suspended with ' + event.first_name + ' ' + event.last_name;
      case eventType.ponctual_availability:
        return 'Extra availability'
      case eventType.ponctual_unavailability:
        return 'Unavailability'
      case eventType.unavailability:
        return 'Unavailability'
      default:
        return ''
    }
  }

  /**
   * Patch or Post Events
   */
  saveEvent(newEvent) {
    /**
     * Gestion de la fin de journée pour envoyé 23:59:59
    */
    if (moment(newEvent.end).format('HH:mm') === '00:00') {
      newEvent.end = moment(newEvent.end).subtract(1, "second").toISOString();
    }  else if ((moment(newEvent.end).format('mm') === '59' || moment(newEvent.end).format('mm') === '29') && moment(newEvent.end).format('HH:mm') !== '23:59') {
      newEvent.end = moment(newEvent.end).add(1, "second").toISOString();
    }
    
    this.eventService.saveEvent(newEvent).subscribe(event => {
      if (!event.deleted) {
        this.dispatchApiEvent(event);
      }
      this.calendarComponent.getApi().unselect();
      this.selectableCalendar(true);
    })
  }

  /**
   * Delete Event
   */
  deleteEvent(newEvent) {
    this.eventService.deleteOneEvent(newEvent).subscribe(() => {
      this.selectableCalendar(true);
    })
  }

  /**
   * récupérer la liste des Events 
   */
  getEvents(type, ponctual, calendarUnavailabilityId, calendarId, recurringCalendar, loadingFct, loadingData) {
    const filterParams = [
      { key: 'calendar__in', value: calendarUnavailabilityId ? calendarUnavailabilityId + ',' + calendarId : calendarId },
      { key: 'event_type__in', value: type },
      { key: 'salt', value: Math.random() },
    ]
    if (ponctual) {
      this.device === "desktop"
        ? filterParams.push({ key: 'start__range', value: moment(this.calendarComponent.getApi().getDate()).startOf('isoWeek').format('YYYY-MM-DD') + ',' + moment(this.calendarComponent.getApi().getDate()).endOf('isoWeek').format('YYYY-MM-DD') })
        : filterParams.push({ key: 'start__range', value: moment(this.calendarComponent.getApi().getDate()).format('YYYY-MM-DD') + ',' + moment(this.calendarComponent.getApi().getDate()).format('YYYY-MM-DD') })
    }
    this.eventService.getEvents(filterParams).subscribe(_events => {
      if (_events) {
        this.dispatchApiEvents(_events, ponctual, recurringCalendar);
        if (loadingFct) {
          loadingFct(loadingData);
        }
      }
    }, _error => {
      this.eventService.handleError(_error);
    })
  }

  /**
   * Dispash les events vers le calendar
   */
  dispatchApiEvents(_events, ponctual, recurringCalendar) {
    if (ponctual) {
      this.mapEventapiToCalendar(_events['results'].filter((event) => event.event_type === eventType.ponctual_unavailability)
        , this.device === 'desktop' ? true : false
        , eventBackgColor.daily_ponctual_unavailability, eventTextColor.daily_ponctual_unavailability, eventBorderColor.daily_ponctual_unavailability
        , false, eventType.ponctual_unavailability
        , ['fc-event-daily', 'fc-ponctual_unavailability']);

      this.mapEventapiToCalendar(_events['results'].filter((event) => event.event_type === eventType.ponctual_availability)
        , this.device === 'desktop' ? true : false
        , eventBackgColor.daily_ponctual_availability, eventTextColor.daily_ponctual_availability, eventBorderColor.daily_ponctual_availability
        , false, eventType.ponctual_availability
        , ['fc-event-daily', 'fc-ponctual_availability']);
    } else {
      if (recurringCalendar) {
        this.mapEventapiToCalendar(_events['results'].filter((event) => event.event_type === eventType.availability)
          , this.device === 'desktop' ? true : false
          , eventBackgColor.general_availability, eventTextColor.general_availability, eventBorderColor.general_availability
          , true, eventType.availability
          , ['fc-event-daily', 'fc-availability']);
      } else {
        this.mapEventapiToCalendar(_events['results'].filter((event) => event.event_type === eventType.availability), false
          , eventBackgColor.daily_availability, eventTextColor.daily_availability, eventBorderColor.daily_availability
          , true, eventType.availability
          , ['fc-event-daily', 'fc-time-grid-event-not-selectable', 'fc-availability']);

        this.mapEventapiToCalendar(_events['results'].filter((event) => event.event_type === eventType.unavailability)
          , false
          , eventBackgColor.daily_unavailability, eventTextColor.daily_unavailability, eventBorderColor.daily_unavailability
          , false, eventType.unavailability
          , ['fc-event-daily', 'fc-unavailability']);
      }
    }
  }

  /**
   * Rajoute un event créer au calendar
   */
  dispatchApiEvent(event) {
    if (event.event_type === eventType.ponctual_unavailability) {
      this.mapEventapiToCalendar([event]
        , this.device === 'desktop' ? true : false
        , eventBackgColor.daily_ponctual_unavailability, eventTextColor.daily_ponctual_unavailability, eventBorderColor.daily_ponctual_unavailability
        , false, eventType.ponctual_unavailability
        , ['fc-event-daily', 'fc-ponctual_unavailability']);
    } else if (event.event_type === eventType.ponctual_availability) {
      this.mapEventapiToCalendar([event]
        , this.device === 'desktop' ? true : false
        , eventBackgColor.daily_ponctual_availability, eventTextColor.daily_ponctual_availability, eventBorderColor.daily_ponctual_availability
        , false, eventType.ponctual_availability
        , ['fc-event-daily', 'fc-ponctual_availability']);
    } else if (event.event_type === eventType.availability) {
      this.mapEventapiToCalendar([event]
        , this.device === 'desktop' ? true : false
        , eventBackgColor.general_availability, eventTextColor.general_availability, eventBorderColor.general_availability
        , true, eventType.availability
        , ['fc-event-daily', 'fc-availability']);
    }
  }

  /**
   * Liste des cours
   */
  getLessonForDate(teacherId, loadingFct, loadingData) {
    let params = new HttpParams;
    this.device === "desktop"
      ? params = params.append('start__range', moment(this.calendarComponent.getApi().getDate()).startOf('isoWeek').format('YYYY-MM-DD') + ',' + moment(this.calendarComponent.getApi().getDate()).endOf('isoWeek').format('YYYY-MM-DD'))
      : params = params.append('start__range', moment(this.calendarComponent.getApi().getDate()).format('YYYY-MM-DD') + ',' + moment(this.calendarComponent.getApi().getDate()).format('YYYY-MM-DD'));
    params = params.append('teacher', teacherId);
    params = params.append('page_size', '100');
    params = params.append('status__in', 'booked,done,suspended');
    params = params.append('calendar', 'true');
    params = params.append('salt', Math.random().toString());
    this.occurrenceService.getObservableOccurences(params)
      .subscribe(_occurrences => {
        if (_occurrences) {
          this.mapEventapiToCalendar(_occurrences['results'].filter((event) => event.status === 'booked' || event.status === 'done'), false
            , eventBackgColor.daily_occurrence, eventTextColor.daily_occurrence, eventBorderColor.daily_occurrence
            , false, eventType.occurrence, ['fc-event-daily', 'fc-occurrence']);
          this.mapEventapiToCalendar(_occurrences['results'].filter((event) => event.status === 'suspended'), false
            , eventBackgColor.daily_occurrence_suspended, eventTextColor.daily_occurrence_suspended, eventBorderColor.daily_occurrence_suspended
            , false, eventType.occurrence_suspended, ['fc-event-daily', 'fc-occurrence']);
          if (loadingFct) {
            loadingFct(loadingData);
          }
        }
      })
  }

  /**
   * Enable ou disable la selection et l'édition du calendar 
   */
  selectableCalendar(value) {
    this.calendarComponent.getApi().setOption("selectable", value);
    this.calendarComponent.getApi().setOption("editable", value);
    if (this.device === 'desktop') {
      this.getPonctualEvents().filter(event => moment().diff(moment(event.start), 'minutes') < 0).forEach(event => event.setProp('editable', value));
    }
  }

  openModal(data, create) {
    const dialogRef = this.dialog.open(ModalEvent, {
      width: '500px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!create) {
          data.eventToRemove.remove();
        }
        result.deleted ? this.deleteEvent(result) : this.saveEvent(result);
      }
      this.selectableCalendar(true);
    });
  }
}