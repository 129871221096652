import { ExerciceAnswerChoice } from './../../../../../../../../model/exerciceAnswerChoice.model';
import { ExerciceAnswerChoiceService } from './../../../../../../../../service/exerciceAnswerChoice.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Exercice } from '../../../../../../../../model/exercice.model';
import { ExerciceQuestion } from '../../../../../../../../model/exerciceQuestion.model';
import { ManageExercicesAnswersChoiceComponent } from '../../../../../../../../directive/manage-exercices-answers-choice/manage-exercices-answers-choice.component';
import { ExerciceQuestionService } from '../../../../../../../../service/exerciceQuestion.service';

@Component({
  selector: 'app-edit-question-qcm',
  templateUrl: './edit-question-qcm.component.html',
  styleUrls: ['./edit-question-qcm.component.scss', '../../../../../formation/informations/informations.ux.scss', '../../../../../formation/formation.ux.scss', '../../../../../mainscreen.ux.scss']
})
export class EditQuestionComponentQCM implements OnInit {

  @Input() exercice: Exercice;
  @Input() question: ExerciceQuestion;
  @Input() position: number;
  @Input() parent;
  @ViewChild('goodanswercontainer', { read: ViewContainerRef }) goodEntry: ViewContainerRef;
  @ViewChild('badanswercontainer', { read: ViewContainerRef }) badEntry: ViewContainerRef;

  goodAnswerList: ExerciceAnswerChoice[];
  badAnswerList: ExerciceAnswerChoice[];

  label = new FormControl('', Validators.required);
  formGroup = new FormGroup({
    label: this.label,
  });
  constructor(
    private resolver: ComponentFactoryResolver,
    public viewContainerRef: ViewContainerRef,
    private exerciceAnswerChoiceService: ExerciceAnswerChoiceService,
    private exerciceQuestionService: ExerciceQuestionService,
  ) { }

  ngOnInit() {
    if (!this.question.answers_choices) {
      this.question.answers_choices = [];
    }

    if (this.question.answers_choices.length > 0) {
      // on s'assure d'avoir au moins une bonne réponse sinon on la crée
      if (this.question.answers_choices.filter(anwser => anwser.is_right === true).length === 0) {
        this.addAnwser(true);
      }
      this.initAnswers();
      this.loadData();
    } else {
      this.addAnwser(true);
    }
  }

  loadData() {
    this.label.setValue(this.question.label);
  }

  initAnswers() {
    this.question.answers_choices.map(answer => this.addAnswerComponent(answer));
  }

  addAnwser(good) {
    const _params = {
      question: this.question.url,
      position: 1,
      is_right: good,
    };
    const that = this;
    this.exerciceAnswerChoiceService.postExerciceAnswerChoice(_params).subscribe(answerCreated => {
      if (answerCreated.id) {
        this.addAnswerComponent(answerCreated);
      }
    })
  }

  patchAnswer(field) {
    const _params = {
      [field]: this.formGroup.get(field).value,
    };
    this.exerciceQuestionService.patchExerciceQuestion(this.question.id, _params).subscribe(question => {

    });
  }

  addAnswerComponent(answerCreated) {
    const factory = this.resolver.resolveComponentFactory(ManageExercicesAnswersChoiceComponent);
    let anwser = null
    if (answerCreated.is_right) {
      anwser = this.goodEntry.createComponent(factory);
    } else {
      anwser = this.badEntry.createComponent(factory);
    }
    anwser.instance.question = this.question;
    anwser.instance.parentComponent = this;
    anwser.instance.answer = answerCreated;
    anwser.instance.removeAnswer.subscribe(event => this.destroyAnswerComponent(anwser));
  }

  deleteAnswer(component) {
    const _params = {
      status: 'deleted',
    };
    this.exerciceAnswerChoiceService.patchExerciceAnswerChoice(component.instance.answer.id, _params).subscribe(answer => {
      component.destroy();
    });
  }

  destroyAnswerComponent(component) {
    this.deleteAnswer(component)
  }

  deleteQuestion(questionId) {
    this.parent.deleteQuestion(questionId);
  }

}
