import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';

@Component({
  selector: 'app-formation-item-menu',
  templateUrl: './formation-item-menu.component.html',
  styleUrls: ['./formation-item-menu.component.scss']
})
export class FormationItemMenuComponent implements OnInit {
  @Input() formation;
  @Input() current_formation;
  @Input() translatePath;
  @Output() updateInitShowFormationsList = new EventEmitter();
  @Output() updateInitCurrentFormation = new EventEmitter();
  constructor(private router: Router, public kiwixiGlobals: KiwixiGlobals) { }

  ngOnInit() { }

  displayFormation() {
    this.updateInitShowFormationsList.emit(false);
    this.updateInitCurrentFormation.emit(this.formation);
  }

}
