import { Component, OnInit, Input } from '@angular/core';
import { Exercice } from '../../../../../../../model/exercice.model';

@Component({
  selector: 'app-sheet-exercices-media',
  templateUrl: './sheet-exercices-media.component.html',
  styleUrls: ['./sheet-exercices-media.component.scss', '../../../../formation/informations/informations.ux.scss', '../../../../formation/formation.ux.scss', '../../../../mainscreen.ux.scss']
})
export class SheetExercicesMediaComponent implements OnInit {
  @Input() exercice: Exercice;
  constructor() { }

  ngOnInit() {
  }

}
