import { OnDestroy } from '@angular/core';
/**
 * Commposant pour l'affichage de la liste des upcomings (composant upcoming).
 */

import { Component, OnInit } from '@angular/core';
import { UpcomingService } from '../../../../service/upcoming.service';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../../../../service/authentification.service';
import { FormControl } from '@angular/forms';
import { groupBy } from 'lodash';
import * as moment from 'moment';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  upcomingBooked = null;
  upcomingWaiting = null;
  upcomings: any[] = [];
  lastUpcomingDate = null;
  translatePath = '.upcoming';
  nextPages = 1;
  searchInput = '';
  countUpcomingsLoaded = 0;
  loadAllUpcomings = false;
  showInfos: boolean;
  myUser: any;
  userTeacher: any;
  totalData = 0;
  isEmpty = false;

  // form Control
  searchUpcomingControl: FormControl;

  constructor(
    private upcomingService: UpcomingService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    if (this.authenticationService.currentUserSubject.value) {
      this.initList();
    }
  }

  ngOnDestroy() {
    this.nextPages = 1;
  }

  // Construction de la liste
  initList() {
    this.searchUpcomingControl = new FormControl();
    // initialisation du debounce de recherche.
    this.searchUpcomingControl.valueChanges
      .debounceTime(300)
      .subscribe(newValue => {
        this.nextPages = 1;
        this.countUpcomingsLoaded = 1;
        this.upcomings = [];
        this.searchInput = newValue;
        this.loadAllUpcomings = true;
        this.setFilterUpcomingList(this.searchInput);
      });
    this.loadAllUpcomings = true;
    this.setFilterUpcomingList(this.searchInput);
  }

  setFilterUpcomingList(_searchInput) {
    if (this.nextPages) {
      let params = new HttpParams();
      params = params.append('student_name', _searchInput);
      params = params.append('page', this.nextPages.toString());
      params = params.append('page_size', '8');
      params = params.append('teacher', this.authenticationService.currentUserSubject.value.id.toString());

      this.upcomingService.getUpcomings(params)
        .subscribe(_upcomings => {
          if (_upcomings) {
            if (_upcomings['next']) {
              this.nextPages = this.upcomingService.getUrlParams(_upcomings['next'], 'page');
            } else {
              this.nextPages = null;
            }
            this.lastUpcomingDate = _upcomings['last'];
            _upcomings['results'].forEach(_upcoming => {
              this.upcomings.push(_upcoming);
            });
            // set lists
            const toOrderByDate = this.upcomings.filter(myUpcomings => myUpcomings.status === 'booked');
            this.upcomingBooked = this.groupBydate(toOrderByDate);
            this.totalData = _upcomings['count'];
            if (this.totalData === 0) {
              this.isEmpty = true;
              this.loadAllUpcomings = false;
            }
          }
        });
    }
  }

  groupBydate(upcomings) {
    const dayMonth = upcomings => moment(upcomings.start, 'YYYY-MM-DD');
    const filterBookedUpcomings = upcomings.filter(myUpcomings => myUpcomings.status === 'booked');
    const grouped = groupBy(filterBookedUpcomings, dayMonth);
    const result = Object.keys(grouped).map(key => ({ date: key, items: grouped[key] }));

    return result;
  }

  search(term: string): void {
    this.upcomings = [];
    this.nextPages = 1;
    this.searchUpcomingControl.patchValue(term);
    this.setFilterUpcomingList(this.searchInput);
  }

  sumWaiting(a, b) {
    return a + b
  }

  loadUpcomings() {
    this.countUpcomingsLoaded++;
    if (this.countUpcomingsLoaded === this.upcomingBooked.length) {
      this.loadAllUpcomings = false;
    }
  }

  loadMore() {
    this.setFilterUpcomingList(this.searchInput);
  }
}
