import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


import * as moment from 'moment';
import { Formation } from '../model/formation.model';
import { Student } from '../model/student.model';
import { Occurrence } from '../model/occurrence.model';
import { Event } from '../model/event.model';

@Injectable()
export class MainscreenGlobals {
  public currentFormation: BehaviorSubject<Formation> = new BehaviorSubject<Formation>(null);
  public currentStudent: BehaviorSubject<Student> = new BehaviorSubject<Student>(null);
  /*** WARNING: Mock Student*/
  public currentStudentId: BehaviorSubject<number> = new BehaviorSubject<number>(1); // TODO: get ID Student
  /* -----------------------*/
  public schedulesSelected: ISchedule[];
  public reloadComponent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public schedulesAreReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public planningLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCreateSchedule: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCancel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pastOccurrences: BehaviorSubject<Occurrence[]> = new BehaviorSubject<Occurrence[]>(null);
  public radarChartData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public radarChartLabels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public currentOccurrence: BehaviorSubject<Occurrence> = new BehaviorSubject<Occurrence>(null);

  setCurrentFormation(formation) {
    this.currentFormation.next(formation);
  }

  setPastOccurrences(pastOccurrences) {
    this.pastOccurrences.next(pastOccurrences);
  }

  setRadarChartLabels(radarChartLabels) {
    this.radarChartLabels.next(radarChartLabels);
  }

  setRadarChartData(radarChartData) {
    this.radarChartData.next(radarChartData);
  }

  setCurrentOccurrence(currentOccurrence) {
    this.currentOccurrence.next(currentOccurrence);
  }

  setCurrentStudent(student) {
    this.currentStudent.next(student);
  }

  setPlanningLoader(isLoading) {
    this.planningLoader.next(isLoading);
  }

  setReloadComponent(isLoading) {
    this.reloadComponent.next(isLoading);
  }

  getIdFromUrl(url) {
    return url.split('/')[6];
  }

  getSchedule(_id) {
    if (this.schedulesSelected.length > 0) {
      for (let _i = 0; _i < this.schedulesSelected.length; _i++) {
        if (this.schedulesSelected[_i].id === _id) {
          return this.schedulesSelected[_i];
        }
      }
    }
    return null;
  }

  addToSchedulesSelected(schedule: ISchedule) {
    this.schedulesSelected.push(schedule)
  }

  removeSchedulesSelected(_index) {
    for (let _i = 0; _i < this.schedulesSelected.length; _i++) {
      if (this.schedulesSelected[_i].index_event === _index) {
        this.schedulesSelected.splice(_i, 1);
      }
    }
  }

  setSchedulesAreReady(isReady) {
    this.schedulesAreReady.next(isReady);
  }

  setIsCreateSchedule(isCreate) {
    this.isCreateSchedule.next(isCreate);
  }

  setShowCancel(isCancel) {
    this.showCancel.next(isCancel);
  }

  checkIfSchedulesAreReady() {
    let isReady = true;
    if (this.schedulesSelected.length === 0) {
      isReady = false;
    } else {
      for (const schedule of this.schedulesSelected) {
        if (schedule.end === null) {
          isReady = false;
        }
      }
    }
    return this.setSchedulesAreReady(isReady);
  }

}

export class ISchedule {
  id: number
  index_event: number
  event: Event
  day: Object
  start: Object
  end: Object
  constructor(_index_event, _event, myDay, myStartTime, myEndTime) {
    this.index_event = _index_event
    this.event = _event;
    this.day = myDay;
    this.start = myStartTime;
    this.end = myEndTime;
  }
}
