import { RateStudentService } from './../../service/rate-student.service';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Formation } from '../../model/formation.model';
import { Occurrence } from '../../model/occurrence.model';
import { RadarService } from '../radar2/radar2.service';

@Component({
  selector: 'app-lilate',
  templateUrl: './lilate.component.html',
  styleUrls: ['./lilate.component.scss'],
  providers: [RadarService]
})
export class LilateComponent implements OnInit, OnChanges {
  @Input() formation: Formation;
  @Input() pastOccurrences: Occurrence[];

  levelLilateImage: string;
  myLilate: string;
  isReportExist = false;

  constructor(
    private radarService: RadarService,
    private rateStudentService: RateStudentService,
  ) { }

  ngOnInit() {
    this.levelLilateImage = '/assets/images/lilate-level-' + this.formation.global_rate + '.png';
    this.checkIfReportExist();
  }

  ngOnChanges() {
    this.myLilate = environment.server + 'lilate/generatepdf/' + this.formation.id + '/?salt=' + Math.random();
  }

  getLastOccurrenceWithReport(): Occurrence {
    for (let i = this.pastOccurrences.length - 1; i >= 0; i--) {
      if (this.radarService.radarIsAvailable(this.pastOccurrences[i])) {
        return this.pastOccurrences[i];
      }
    }
  }

  generateLilate(): void {
    this.radarService.downloadPdf(this.myLilate, this.formation, this.getLastOccurrenceWithReport());
  }

  checkIfReportExist() {
    let params = new HttpParams();
    params = params.append('session__formation', this.formation.id.toString());
    params = params.append('page_size', '1');
    this.rateStudentService.getRatingStudents(params).subscribe(res => {
      if (res['count'] > 0) {
        this.isReportExist = true;
      } else {
        this.isReportExist = false;
      }
    })
  }
}
