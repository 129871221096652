import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Teacher } from '../model/teacher.model';
import { NewTeacher } from '../model/new-teacher.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { catchError } from 'rxjs/operators';
import { DashboardNotification } from '../model/dashboard-notification.model';

@Injectable()
export class TeacherService extends KiwixiService {

  queue: any;
  apiteachers = environment.server + 'teachers/';
  apiusers = environment.server + 'users/';
  myCurrentTeacher: Teacher;
  public currentTeacher: BehaviorSubject<Teacher> = new BehaviorSubject<Teacher>(null);
  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getTeacher2(id, nocache = false): Observable<Teacher> {
    const header = this.createHttpClientAuthorizationHeader();
    let salt = '';
    if (nocache) {
      salt = '?nocachesalt=' + Math.random().toString();
    }
    const url = this.apiteachers + id + '/' + salt;
    return this._http.get<Teacher>(url, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Teacher>(`getTeacher2 id=${id}`))
    );
  }

  getCountTeachers() {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get(this.apiteachers, { headers: headers })
      .toPromise()
      .then(
        res => {
          const body = res;
          return body['count'];
        }
      )
      .catch(this.handleError);
  }

  getTeachers(_filterParams): Promise<Object[]> {
    let params = new HttpParams();
    // ordering:
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params = params.set(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params = params.set(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      params = params.set('page', _filterParams['page']);
    }
    const headers = this.createHttpClientAuthorizationHeader();


    return this._http.get(this.apiteachers, { headers: headers, params: params })
      .toPromise()
      .then(
        res => {
          const body = res;
          return body;
        }
      )
      .catch(this.handleError);
  }

  getTeacher(id): Promise<Teacher> {
    const urlTeacher = this.apiteachers + id + '/';
    return this.getTeacherWithUrl(urlTeacher);
  }

  getTeacherWithUrl(teacherUrl): Promise<Teacher> {
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(teacherUrl, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Teacher service', error);
        this.logout();
      })
  }

  postTeacher(teacher): Promise<Teacher> {
    const headers = this.createHttpClientAuthorizationHeader();

    const data = teacher;
    // creation de l'utilisateur
    const user = teacher.user;
    user.auto_pwd = data.user.auto_pwd;
    user.site = data.site;
    return this._http.post(this.apiusers, user, {
      headers: headers
    })
      .toPromise()
      .then(response => {
        // creation du teacher associe
        const res = response;
        data.user = res;
        return this._http.post(this.apiteachers, data, {
          headers: headers
        })
          .toPromise()
          .then((_res: any) => _res)
          .catch(error => {
            this.toastService.show(`La création a échouée`, 2000, 'red');
            console.error('error Teacher service', error);
          })
      })
    // .catch(error => {
    //   console.error('error Teacher service', error);
    // })
  }

  /**
   * Mise à jour d'un teacher en method PATCH
   * @param teacherUrl url de l'objet
   * @param param parametre à mettre à jour, {parametre: nouvelle valeur}
   */
  patchTeacher(teacherUrl, param): Promise<Teacher> {
    const field = Object.getOwnPropertyNames(param)[0]
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(teacherUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        const errors = error;
        this.toastService.show('Erreur sauvegarde professeur <br +>' + errors[field], 4000, 'red');
        return Promise.reject(error.message || error);
      });
  }

  patchFile(teacherUrl, param, field, file): Promise<Teacher> {
    const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    _formData.append(field, file);
    _formData.append('name', file.name);
    _formData.append('teacher', param);
    return this._http.patch(teacherUrl, _formData, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Teacher service', error);
      })
  }

  getStatus() {
    return [
      {
        label: 'Accepté',
        code: 'accepted',
        valid: true
      },
      {
        label: 'En attente',
        code: 'pending',
        valid: true
      },
      {
        label: 'Refusé',
        code: 'refused',
        valid: true
      }
    ];
  }

  getObservableTeachers(params: HttpParams): Observable<Teacher[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Teacher[]>(this.apiteachers, {
      headers: header,
      params: params
    })
      .pipe(
        catchError(this.handleErrorObservable<Teacher[]>(`getObservableTeachers`))
      );
  }

  getTeachersWithUrl(_url): Observable<Teacher[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Teacher[]>(_url, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Teacher[]>(`getTeachersWithUrl`))
      );
  }

  getObservableTeacherById(id): Observable<Teacher> {
    const url = this.apiteachers + id + '/';
    return this.getObservableTeacherByUrl(url);
  }

  getObservableTeacherByUrl(_url): Observable<Teacher> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Teacher>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Teacher>(`getObservableTeacherByUrl`))
      );
  }

  getMultipleTeachers(teachersUrl) {
    const obx = [];
    for (const url of teachersUrl) {
      obx.push(this.getObservableTeacherByUrl(url))
    }
    return forkJoin<Teacher[]>(obx)
  }

  getMultipleTeachers2(teachersUrl) {
    const obx = [];
    for (const url of teachersUrl) {
      obx.push(this.getObservableTeacherByUrl(url))
    }
    return forkJoin<Teacher>(obx)
  }

  getMultipleTeachersById(teachersId) {
    const obx = [];
    for (const _id of teachersId) {
      obx.push(this.getObservableTeacherById(_id))
    }
    return forkJoin<Teacher[]>(obx)
  }

  public getTeachersFromEvents(events) {
    const obx = [];

    const teachers = []
    for (const event of events) {
      if (event.ghost === false && event.deleted === false) {
        for (const teacher of event.teachers) {
          teachers.push(teacher.url);
        }
      }
    }
    teachers.filter(function (value, index) { return teachers.indexOf(value) === index });
    for (const teacher of teachers) {
      obx.push(this.getObservableTeacherByUrl(teacher))
    }
    return forkJoin<Teacher[]>(obx)
  }

  getDashboard(_teacherId): Observable<DashboardNotification> {
    const header = this.createHttpClientAuthorizationHeader();
    let params = new HttpParams();
    params = params.append('codes', '14,17,18,19,20,21,22,23,25,56,68,71');

    const dashboardUrl = this.apiteachers + _teacherId + '/dashboard/';
    return this._http.get<DashboardNotification>(dashboardUrl, {
      headers: header,
      params: params
    })
      .pipe(
        catchError(this.handleErrorObservable<DashboardNotification>(`getDashboard`))
      );
  }
}
