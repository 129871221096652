<ng-container *ngIf="isLoaded && notifications.length>0">
  <div *ngFor="let notification of notifications">
    <div class="notification-bloc">

      <div class="cancel-student-row">
        <div class="dashboard-maininfo">
          <span class="notif-title">Notification title</span>
          <span class="notif-content" [innerHtml]="notification.web_content"></span>
        </div>

        <div class="btn-row">
          <div class="dashboard-btn suboption" (click)="gotoAndDeleteNotification(notification)" *ngIf="notification.has_action_button">
            <span translate>{{translatePath}}.go-to</span>

            <ng-container *ngIf="notification.action_object_url">
              <span translate>{{translatePath}}.see-training</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="deletenotif-btn" (click)="deleteNotification(notification)" *ngIf="notification.has_deleted_button">       
      </div>
    </div>
	</div>
</ng-container>

<ng-container *ngIf="!isLoaded">
  <div class="white-text row center">
		<span translate>{{translatePath}}.notifications-loading</span>
	</div>
</ng-container>

<ng-container *ngIf="isLoaded && notifications.length==0">
  <div class="nonotif-message">
    <span >You're up to date!</span>
  </div>
</ng-container>
