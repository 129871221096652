import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../service/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-datatable-cell-user',
  templateUrl: './datatable-cell-user.component.html',
  styleUrls: ['./datatable-cell-user.component.scss']
})
export class DatatableCellUserComponent implements OnInit {

  @Input() user;
  my_user: Object;
  defaultAvatar = environment.static + '/avatar.png';
  errorMessage = '';
  loading = true;

  constructor(private userService: UserService) { }

  ngOnInit() {
    if (!(typeof (this.user) === 'object')) {
      this.userService.getObservableUserByUrl(this.user)
        .subscribe(_user => {
          this.my_user = _user;
          this.loading = false;
        },
          error => {
            this.errorMessage = <any>error;
          })
    } else {
      this.my_user = this.user;
      this.loading = false;
    }
  }

}
