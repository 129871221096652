<div class="row information-container training-recurrencies" *ngIf="newFormation.url">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Récurrences hebdomadaires & Professeurs</h6>
    </div>
    <div class="right">
      <i class="pointer" mz-icon [icon]="'autorenew'" [align]="'center'" (click)="refresh()"></i>
    </div>
  </div>
  <div class="col l12 left">
    <div class="row information-subcontainer">
      <!--  Avertissement(s) -->
      <div class="todo-copy" *ngIf="has_errors">
        Reste à choisir:
          <span *ngIf="minutesByWeek - minutesAssigned > 0">{{ (minutesByWeek - minutesAssigned) / 60}} h/semaine</span>
          <span *ngIf="!all_events_assigned && minutesByWeek - minutesAssigned > 0"> ,</span>
          <span *ngIf="!all_events_assigned"> Professeurs </span>
          <!-- {{ dirty }} <br />
          {{ generateIsValid }} <br />
          nb heures assignees: {{ minutesAssigned }} <br />
          nb heures requises: {{ minutesByWeek }} <br />
          Tous profs assignes: {{ all_events_assigned }} <br />
          Calcul des dispos : {{ check_compute_dispo }} <br /> -->
      </div>

      <!-- Liste des events -->
      <div class="row" *ngIf="loadingAvailableHour">
        Chargement des disponibilités en cours ...
      </div>

      <div class="row" *ngIf="!loadingAvailableHour">
          <div class="timeslot-row" *ngFor="let event of newFormation.events; let idx=index">
              <app-time-selectors
                [create_mode]=true
                [event]="event"
                [indexItem]="idx"
                [formation]="newFormation"
                [extendChoices]="extendChoices"
                [days]="days"
                (updateEvent)="handle_update_event($event)">
              </app-time-selectors>
      </div>

      <!-- Boutons -->
      <div class="buttons-row" *ngIf="!loadingAvailableHour">
        <button class="action-button addslot-btn" mz-button [flat]=true (click)="addEvent()">
          <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'"></i> Ajouter un créneau horaire
        </button>
        <button class="action-button showteach-btn" mz-button [flat]=true (click)="getAvailableTeacher()" [disabled]="!check_compute_dispo">
          <i mz-icon [icon]="'face'" [align]="'left'" [size]="'tiny'"></i> Voir les enseignants disponibles
        </button>
      </div>
      <hr />
      <b>Professeurs Choisis :</b>
      <!-- Creneaux assignés -->
      <div class="row">
          <app-item-teacher-assigned
            [event]="event"
            [teacher]="event.teacher"
            *ngFor="let event of newFormation.events"
          >
          </app-item-teacher-assigned>
        </div>
      <hr />
      <ng-container *ngIf="noTeacherSolution && teachersAssigned; else elseSolution">
        <b><i>Pas de professeurs disponibles</i></b>
      </ng-container>
      <ng-template #elseSolution>        
        <b>Professeurs Disponibles :</b>
      </ng-template>
      
      <input class="form-control" [formControl]="teacher_name_control" />
      <!-- Solutions disponibles -->
      <div class="row" *ngIf="!loaderSolution">
        <app-item-teacher-available
          [solution]="solution"
          [selected_solution]="selected_solution"
          (select_solution)="selectSolution($event)"
          *ngFor="let solution of filteredSolution"
        >
        </app-item-teacher-available>
      </div>
      <div class="row" *ngIf="loaderSolution">
        Chargement des solutions
      </div>

      <!-- Generation du planning -->
      <div class="row center" *ngIf="generateIsValid && !loaderCreatePlanning; else elseCreatePlanning">
        <button class="newsched-btn" (click)="generatePlanning()">
          Créer le planning et assigner professeur(s)
        </button>
      </div>
      <ng-template #elseCreatePlanning>
        <div class="fake-newsched-btn">
          <span class="grey-text">Créer le planning et assigner professeur(s)</span>
        </div>
      </ng-template>

      <div class="row center clean-row" *ngIf="loaderCreatePlanning">
        <b>Création du planning...</b>
      </div>
      <div class="row center clean-row" *ngIf="failurePlanning">
        <b>La génération du planning a échoué</b>
      </div>
    </div>
  </div>
</div>
