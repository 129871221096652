/**
 * Composant Directive pour l'affichage d'un teacher disponible.
 */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TeacherService } from '../../../../../../service/teacher.service';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-item-teacher-available',
  templateUrl: './item-teacher-available.component.html',
  styleUrls: ['./item-teacher-available.component.scss']
})
export class ItemTeacherAvailableComponent implements OnInit, OnChanges {

  // Inputs/output du composant parent.
  @Input() solution;
  @Input() selected_solution;
  @Output() select_solution = new EventEmitter(); // event au parent à la selecion du prof.
  defaultAvatar = environment.static + 'avatar.png';
  teachers = [];  // liste des teachers disponibles.
  choosen = false; // boolean pour l'icone de validation du choix d'un teacher.
  extra = {}; // debugg
  noTeachersExists = false; // boolean si dispo existe.

  constructor(private teacherService: TeacherService) { }

  ngOnInit() { this.initTeacher(); }

  ngOnChanges() { this.choosen = this.solution === this.selected_solution; }

  // Crée la liste des teachers disponible.
  initTeacher() {
    // #1 - formatage de la liste des solutions possibles.
    const listValues = [];
    let keys = Object.keys(this.solution);
    if (this.solution.hasOwnProperty('extra')) {
      this.extra = this.solution['extra'];
    }
    keys = keys.filter(val => val !== 'extra');
    keys.forEach(key => {
      if (listValues.indexOf(this.solution[key]) < 0) {
        listValues.push(this.solution[key])
      }
    })
    // #2 - Pour chaque solution on recupère l'objet teacher.
    listValues.forEach(value => {
      const myId = value.split('/')[4];
      this.teacherService.getTeacher(myId)
        .then(_teacher => {
          this.teachers.push(_teacher);
          if (this.teachers.length < 1) {
            this.noTeachersExists = true;
          } else {
            this.noTeachersExists = false;
          }
        })
    })
    if (this.teachers.length < 1) {
      this.noTeachersExists = true;
    } else {
      this.noTeachersExists = false;
    }
  }

  /**
   * navigation vers la page d'admin du teacher.
   * @param _teacher
   */
  displayProfilTeacher(_teacher) {
    const myUrl = '/teachers/admin/' + _teacher.id;
    window.open(myUrl, '_blank')
  }

  // selectionner le prof pour la plannification.
  selectSolution() {
    this.choosen = true;
    this.select_solution.emit(this.solution);
  }

}
