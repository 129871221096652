import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Formation } from '../../model/formation.model';

@Component({
  selector: 'app-comprate',
  templateUrl: './comprate.component.html',
  styleUrls: ['./comprate.component.scss']
})
export class ComprateComponent implements OnInit {
  @Input() formation: Formation;

  totalHours: number;
  hoursPlanned: number;
  hoursConsummed: number;
  nbEventSession: number;

  constructor(
    private router: Router,) { }

  ngOnInit() {
    this.nbEventSession = this.formation.events.filter(e => e.event_type === 'session').length;
    this.totalHours = 100;
    this.hoursPlanned = Math.round((this.formation.hours_planned * 100) / this.formation.hours_total);
    this.hoursConsummed = Math.round((this.formation.hours_consummed * 100) / this.formation.hours_total);
  }

  goToClassPonctualBooking(): void {
    this.router.navigate(['/formation/' + this.formation.id + '/edit-schedule/ponctual-booking']);
  }

  goToRecurrentBooking(): void {
    this.router.navigate(['/formation/' + this.formation.id + '/edit-schedule/update-slots']);
  }

}
