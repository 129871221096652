<div class="decorative-border">
  <!-- Title -->
  <div class="subheader-row">
    <!-- INFOS bloc -->
    <div class="infoblock">
      <div class="popup-overlay" *ngIf="showInfo" (click)="showInfo = false"></div>
      <ng-container *ngIf="showInfo">
        <div class="border-info">
          <i class="material-icons help-closeicon" (click)="showInfo = false">close</i>
          <p translate class="help-title">{{translatePath}}.weekly-availabilities-title</p>
          <div class="summary">
            <div>
              <p translate>{{translatePath}}.weekly-info.bloc-1</p>
            </div>
            <div>
              <p translate class="help-subtitle">{{translatePath}}.weekly-info.bloc-2</p>
            </div>
            <div>
              <p translate>{{translatePath}}.weekly-info.bloc-3</p>
              <p translate>{{translatePath}}.weekly-info.bloc-4</p>
              <p translate>{{translatePath}}.weekly-info.bloc-4</p>
              <p translate>{{translatePath}}.weekly-info.bloc-5</p>
              <p translate>{{translatePath}}.weekly-info.bloc-6</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <p class="explanation-text"><b translate>{{translatePath}}.weekly-schedule-explanation-1</b> <span translate>{{translatePath}}.weekly-schedule-explanation-2</span> <a class="learnmore-btn" (click)="showInfo = true">Learn more</a></p>
  </div>

  <app-availability-calendar
    [calendarId]="calendarId"
    [calendarUrl]="calendarUrl"
  ></app-availability-calendar>

</div>