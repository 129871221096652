<mz-modal [fixedFooter]="true" [options]="modalOptions" class="modal-size">
  <mz-modal-content>
    <ng-container *ngIf="user.is_active; else elseTemplate">
      <div class="center"><b>Validation de l'activation</b></div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="center"><b>Validation de la desactivation</b></div>
    </ng-template>
    
  </mz-modal-content>
  <mz-modal-footer>
    <div class="row">
      <div class="left"><button mz-button [flat]="true" (click)="cancel()">Annuler</button></div>
      <div class="right"><button mz-button [flat]="true" (click)="valid()">Valider</button></div>
    </div>
  </mz-modal-footer>
</mz-modal>
