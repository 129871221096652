import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../service/authentification.service';
import { Router } from '@angular/router';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  inter1;
  inter2;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.inter1 = setInterval(() => {
      this.authenticationService.logout();
      this.authenticationService.currentUserSubject.next(null);
    }, 100);

    this.inter2 = setInterval(() => {
      this.router.navigate(['/login']);
    }, 5000);
  }

  ngOnDestroy() {
    clearInterval(this.inter1);
    clearInterval(this.inter2);
  }

}
