<div class="row information-container training-recurrencies">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Récurrences hebdomadaires & Professeurs</h6>
    </div>
  </div>

  <div class="row event-list">
    <div class="col l6">
      <mat-accordion>
        <mat-expansion-panel *ngFor=" let event of events">
          <mat-expansion-panel-header>
            <mat-panel-title matTooltip="{{ event.id }}">
             {{ event.start | date :'EEEE' }} : {{ event.start | date :'HH' }} h {{ event.start | date :'mm' }} - {{ event.end | date :'HH' }} h {{ event.end | date :'mm' }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="option_event">
            <div>Options créneau :</div>
            <div class="col l6">
              <mat-checkbox *ngIf="allocationMode=='balanced'" matTooltip="Ajout des cours sur ce créneau dans la limite de la date de fin de validité." (click)="toggleEventExtend(event)" labelPosition="before">Etendre</mat-checkbox>
            </div>
            <div>
              <mat-checkbox matTooltip="Supprime ce créneau et libère les heures de cours à venir." (click)="toggleEventDelete(event)" labelPosition="before">A supprimer</mat-checkbox>
            </div>
          </div>

          <div class="row">
            <table class="table-listing-teacher">
              <tr>
                <th></th>
                <th class="right">Remplacer ?</th>
              </tr>
              <tr *ngFor="let teacher of event.teachers">
                <td><a matTooltip="Id : {{ teacher.id}}" routerLink="/teachers/admin/{{ teacher.id}}" target="_blank">{{ teacher.user.first_name}} {{ teacher.user.last_name}}</a></td>
                <td class="right"><mat-checkbox (click)="toggleTeacherDelete(teacher)"></mat-checkbox></td>
              </tr>
            </table>
          </div>
          
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="col l6">
      <div class="row">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
              Tous les professeurs
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <table class="table-listing-teacher">
              <tr>
                <th></th>
                <th class="right">Remplacer ?</th>
              </tr>
              <tr *ngFor="let teacher of formation.active_teachers">
                <td><a matTooltip="Id : {{ teacher.id}}" routerLink="/teachers/admin/{{ teacher.id}}" target="_blank">{{ teacher.user.first_name}} {{ teacher.user.last_name}}</a></td>
                <td class="right"><mat-checkbox (click)="toggleTeacherDelete(teacher)" ></mat-checkbox></td>
              </tr>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="row">
        <app-options (handleUpdateAllocationMode)="handleUpdateAllocationMode($event)" [formation]="formation"></app-options> 
      </div>

    </div>
  </div>

  <div class="row">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
            Ajouter des créneaux 
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <button class="action-button add-slot-button" mz-button (click)="addTimeSlot()"> Ajouter un créneau</button>
          </div>
          <div class="row">
            <ng-template #timeslotcontainer></ng-template>
          </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="row center">
      <button class="action-button search-solution-button" mz-button (click)="searchSolution(true)">Recherchez une solution</button>
  </div>

  <div class="row center search-on-going" *ngIf="loaderSearchSolution">
   Calcul en cours, veuillez patienter !
  </div>

  <div class="row">
  
  <div *ngIf="searchPlanResult">
      <div *ngIf="searchPlanResult.event_deleted.length > 0 || searchPlanResult.events_teachers_replaced.length > 0 || searchPlanResult.events_extended.length > 0 || searchPlanResult.events_added.length > 0; else EmptySolution">

        <div class="row" *ngIf="searchPlanResult.event_deleted.length > 0">
          <div class="title-search-result">Liste des créneaux à supprimer :</div>
          <ul>
            <li *ngFor="let event of searchPlanResult.event_deleted">
              Le {{ event.details.start | date :'EEEE' }} de  {{ event.details.start | date :'HH' }} h {{ event.details.start | date :'mm' }} à {{ event.details.end | date :'HH' }} h {{ event.details.end | date :'mm' }}
              soit {{ event.hours_deleted | number}} heures à libérer <a *ngIf="event.matrice_html" (click)="opentDebugPanel(event.matrice_html)">Détails</a>
            </li>
          </ul>
        </div>

        <div  class="row" *ngIf="searchPlanResult.events_teachers_replaced.length > 0">
          <div class="title-search-result">Liste des professeurs à remplacer :</div>
          <ul>
            <li *ngFor="let event of searchPlanResult.events_teachers_replaced">
              Créneau du {{ event.details.start | date :'EEEE' }} de  {{ event.details.start | date :'HH' }} h {{ event.details.start | date :'mm' }} à {{ event.details.end | date :'HH' }} h {{ event.details.end | date :'mm' }}
              <a *ngIf="event.matrice_html" (click)="opentDebugPanel(event.matrice_html)">Détails</a>
              <ul>
                <li *ngFor="let replacement of event.teachers_replaced">
                - {{ replacement.occ.start | date :'dd/MM/yyyy' }}  
                <span *ngIf="replacement.new_teacher.id>0;else noSubstitute">{{ replacement.old_teacher.user.first_name }} {{ replacement.old_teacher.user.last_name }} remplacé par  {{ replacement.new_teacher.user.first_name }} {{ replacement.new_teacher.user.last_name }}</span>
                <ng-template #noSubstitute>
                  Aucun de remplaçent identifié
                </ng-template>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div  class="row" *ngIf="searchPlanResult.events_extended.length > 0">
          <div class="title-search-result">Liste des créneaux à étendre :</div>
          <ul>
            <li *ngFor="let event of searchPlanResult.events_extended">
              Créneau du {{ event.details.start | date :'EEEE' }} de  {{ event.details.start | date :'HH' }} h {{ event.details.start | date :'mm' }} à {{ event.details.end | date :'HH' }} h {{ event.details.end | date :'mm' }}
              soit {{ event.total_hours | number }} heures à planifier. <a *ngIf="event.matrice_html" (click)="opentDebugPanel(event.matrice_html)">Détails</a>
                <div *ngIf="event.occurrences.length>0;else noExtendedOccs">
                  <ul>
                  <li *ngFor="let teacher of getNumberHoursByTeacher(event.occurrences)">
                        {{ teacher.teacher.user.first_name }} {{ teacher.teacher.user.last_name }} : {{ teacher.duration | number}} heures
                  </li>
                  </ul>
                </div>
                <ng-template #noExtendedOccs>
                  <div>Aucun cours étendus </div>
                </ng-template>
            </li>
          </ul>
        </div>

        <div  class="row" *ngIf="searchPlanResult.events_added.length > 0">
          <div class="title-search-result">Liste des créneaux à ajouter :</div>
          <ul>
            <li *ngFor="let event of searchPlanResult.events_added">
              Créneau du {{ event.details.start | date :'EEEE' }} de  {{ event.details.start | date :'HH' }} h {{ event.details.start | date :'mm' }} à {{ event.details.end | date :'HH' }} h {{ event.details.end | date :'mm' }}
              soit {{ event.total_hours | number}} heures à planifier. <a *ngIf="event.matrice_html" (click)="opentDebugPanel(event.matrice_html)">Détails</a>
                <ul>
                <li *ngFor="let teacher of getNumberHoursByTeacher(event.occurrences)">
                      {{ teacher.teacher.user.first_name }} {{ teacher.teacher.user.last_name }} - {{ teacher.duration | number }} heures
                </li>
                </ul>
            </li>
          </ul>
        </div>
        <div class="row center">
          <button id="write-solution-button" class="action-button" mz-button (click)="searchSolution(false)" [disabled]="!searchPlanResult?.status==ok">Valider la solution</button>
        </div>
      </div>
      <ng-template #EmptySolution>
                  <div>Aucune solution identifié </div>
      </ng-template>
    </div>
  </div>

</div>  