import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { eventType } from '../service/calendar.service';
import { EventService } from '../service/event.service';

@Injectable()
export class ScheduleGuard implements CanActivate {

    constructor(
        private eventService: EventService,
        private router: Router,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const filterParams = [
            { key: 'event_type__in', value: eventType.availability },
            { key: 'page_size', value: 1 },
        ]
        return this.eventService.getEvents(filterParams)
            .pipe(
                map(_events => {
                    if (_events['count'] === 0) {
                        this.router.navigate(['/schedule/availability']);
                        return true;
                    } else {
                        this.router.navigate(['/schedule/ponctual']);
                        return false;
                    }
                }),
            );
    }
}
