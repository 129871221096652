import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpcomingClassesComponent } from './upcoming-classes.component';
import { ListComponent } from './list/list.component';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { UpcomingClassesRoutingModule } from './upcoming-classes-routing.module';
import { MatProgressSpinnerModule, MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { UpcomingComponent } from './upcoming/upcoming.component';
import { MomentModule } from 'ngx-moment';
import { MaterializeModule } from 'ng2-materialize';
import { ReactiveFormsModule } from '@angular/forms';
import { DocumentsListComponent } from '../../../feature/documents-list/documents-list.component';
import { FeatureModule } from '../../../feature/feature.module';
import { ReschedulePendingComponent } from './reschedule-pending/reschedule-pending.component';
import { AssignSheetComponent } from './assign-sheet/assign-sheet.component';
import { SheetDetailComponent } from './sheet-detail/sheet-detail.component';
import { AssignedSheetDetailComponent } from './assign-sheet/assigned-sheet-detail/assigned-sheet-detail.component';
import { MaterialModule } from '../../../material.module';

@NgModule({
  imports: [
    CommonModule,
    UpcomingClassesRoutingModule,
    MomentModule,
    ReactiveFormsModule,
    MaterialModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    FeatureModule,
    MaterializeModule.forRoot(),
    TranslateModule.forChild(),
    FeatureModule
  ],
  declarations: [
    UpcomingClassesComponent,
    ListComponent,
    RescheduleComponent,
    UpcomingComponent,
    ReschedulePendingComponent,
    AssignSheetComponent,
    SheetDetailComponent,
    AssignedSheetDetailComponent,
  ],
  exports: [
    UpcomingComponent,
    MatFormFieldModule,
    MatInputModule,
    ReschedulePendingComponent,
    AssignSheetComponent,
  ]
})
export class UpcomingClassesModule { }
