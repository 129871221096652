import { Directive, HostListener, Input } from '@angular/core';
import { OutlookLingueoService } from './outlook-lingueo.service';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appOutlookLingueo]',
  providers: [OutlookLingueoService]
})
export class OutlookLingueoDirective {
  @Input() formation;
  @Input() teacher;

  constructor(
    private outlookLingueoService: OutlookLingueoService) { }

  @HostListener('click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    let _url = environment.server + 'calendar/export/';
    const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);
    let _filename = 'outlook_' + environment.urls[idx].module + '_';
    if (this.formation) {
      _url = _url + this.formation.id + '/';
      _filename = _filename + 'formation_' + this.formation.id + '.ics';
    } else if (this.teacher) {
      _filename = _filename + this.teacher.id + '.ics';
    }
    this.outlookLingueoService.downloadIcs(_url, _filename);
  }
}
