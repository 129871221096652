import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from './../confirmation-dialog/confirmation-dialog.component';
import { AuthenticationService } from './../../service/authentification.service';
import { TeacherService } from './../../service/teacher.service';
import { Teacher } from '../../model/teacher.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-teacher-cache-status',
  templateUrl: './teacher-cache-status.component.html',
  styleUrls: ['./teacher-cache-status.component.scss']
})
export class TeacherCacheStatusComponent implements OnInit {

  @Input() teacher: Teacher;
  @Input() refresh;
  loader = false;
  constructor(
    private teacherService: TeacherService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // this.authenticationService.currentUserSubject.subscribe(res => {
    //   this.teacher = res;
    // });
  }

  refreshStatus() {
    this.loader = true;
    this.teacherService.getTeacher2(this.teacher.id, true).subscribe(res => {
      this.teacher = res;
      this.loader = false;
    });
  }

  refreshCache() {
    this.loader = true;
    const param = { '_cache_status': 'expired' };
    this.teacherService.patchTeacher(this.teacher.url, param)
      .then(_teacher => {
        this.teacher = _teacher;
        this.teacherService.toastService.show('Votre demande de mise à jour du cache a été prise en compte !', 4000, 'green');
        this.loader = false;
        this.refreshStatus();
      });
  }

  openDialog(): void {
    if (this.teacher._cache_status === 'pending') {
      this.teacherService.toastService.show('La mise à jour du cache est en cours, merci de patienter', 4000, 'orange');
      return;
    }
    if (this.teacher._cache_status === 'expired') {
      this.teacherService.toastService.show('Le cache va bientôt être recalculé, merci de patienter', 4000, 'orange');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'Etes vous sur de vouloir mettre à jour le cache des disposnibilités pour ce professeur ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshCache()
      }
    });
  }
}
