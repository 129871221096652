import { ConversationService } from './conversation.service';
import { combineLatest } from 'rxjs';
import { RoutePermissionService } from './routePermission.service';
import { GroupService } from './group.service';
import { FormationService } from './formation.service';
import { AuthenticationService } from './authentification.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { OccurrenceService } from './occurrence.service';
import { AchievementManagerService } from './achievementManager.service';
import { AchievementService } from './achievement.service';
import { AchievementDoneservice } from './achievementDone.service';

@Injectable()
export class InitAppService extends KiwixiService {

    reload;
    formationId;
    currentUser;
    constructor(private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService,
        private authenticationService: AuthenticationService,
        private formationService: FormationService,
        private occurrenceService: OccurrenceService,
        private groupService: GroupService,
        private routePermissionService: RoutePermissionService,
        private conversationService: ConversationService,
        private achievementDoneservice: AchievementDoneservice,
        private achievementService: AchievementService,
        private achievementManagerService: AchievementManagerService
    ) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getUser() {
        const personna = this.authenticationService.getCurrentPersona();
        if (personna) {
            this.authenticationService.currentUserSubject.next(personna);
        }
    }

    getFormations() {
        const student = this.authenticationService.currentUserSubject.value;
        const listeFormation = this.formationService.listeFormations.value;
        if (student && !listeFormation) {
            let params = new HttpParams();
            params = params.append('student', student.id)
            params = params.append('status__in', 'active,done,pending,suspended')
            this.formationService.getObservableFormations(params).subscribe(data => {

                if (data) {
                    const _formations = data['results'];
                    if (_formations) {
                        this.formationService.listeFormations.next(_formations);

                        const formationToLoad = this.formationId ? this.formationId : this.formationService.getDefaultFormation().id;
                        if (formationToLoad) {
                            this.formationService.getObservableFormationById(formationToLoad).subscribe(_formation => {
                                if (_formation) {
                                    this.formationService.currentFormationSubject.next(_formation);
                                    this.routeLingueoPro();
                                }
                            });
                        }
                    }
                }
            });
        }

    }

    getNextOccurrence() {
        this.formationService.currentFormationSubject.subscribe(formation => {

            if (formation) {
                if (formation.next_occurrence) {
                    this.occurrenceService.getObservableOccurence(formation.next_occurrence).subscribe(occ => {
                        if (occ) {
                            this.formationService.nextOccurrence.next(occ);
                        }
                    });
                } else {
                    this.formationService.nextOccurrence.next(null);
                }
            }
        })
    }


    getRoutesPermissions() {
        if (!this.routePermissionService.routeFeaturePermissions.value) {
            this.authenticationService.getRoutesPermissions().subscribe(routes => {
                const results = routes ? routes.results : null;
                this.routePermissionService.routeFeaturePermissions.next(results);
            })
        }
    }

    getGroupsPermissions() {
        if (!this.groupService.appGroups.value) {
            this.authenticationService.getGroups().subscribe(groups => {
                const results = groups ? groups.results : null;
                this.groupService.appGroups.next(results);
            })
        }
    }


    getConversations() {
        let params = new HttpParams()
        params = params.append('persona', this.authenticationService.currentUserSubject.value.id.toString())
        params = params.append('ordering', 'true')
        params = params.append('page', '1');

        this.conversationService.getConversations(params)
            .subscribe(conversations => {
                if (conversations) {
                    if (conversations['results']) {
                        this.conversationService.conversationList.next(conversations['results']);
                    }
                }
            });
    }

    initApp(reload, formationId) {
        this.getUser();
        const isLoggedIn = this.authenticationService.currentUserSubject.value;
        this.reload = reload;
        this.formationId = formationId;

        this.getRoutesPermissions();
        this.getGroupsPermissions();
        if (isLoggedIn) {
            this.getConversations();
        }
        combineLatest([this.groupService.appGroups, this.routePermissionService.routeFeaturePermissions]).subscribe(result => {
            if (result[0] && result[1]) {
                this.process();
            }
        })

    }

    process() {
        const site = localStorage.getItem('site');
        if (site === 'lingueopro') {
            this.getUser();
            this.getFormations();
            this.getNextOccurrence();
        }

        if (site === 'backoffice') {
            this.getUser();
            this.routeBackoffice();
        }

        if (site === 'teacher') {
            this.routeTeacher();
        }
    }

    routeLingueoPro() {
        if (!this.reload) {
            this.router.navigate(['/formation', this.formationService.currentFormationSubject.value.id, 'dashboard']);
        }
    }

    routeBackoffice() {
        if (!this.reload) {
            this.currentUser = this.authenticationService.currentUserSubject.value.user;
            const isExercice = this.currentUser.groups.filter(group => group.name === 'admin-exercices').length > 0 ? true : false;
            const isStudent = this.currentUser.groups.filter(group => group.name === 'admin-students').length > 0 ? true : false;
            if (isExercice && !isStudent) {
                this.router.navigate(['/exercices']);
            } else {
                if (this.router.url === '/login') {
                    this.router.navigate(['']);
                } else {
                    this.router.navigate([this.router.url]);
                }
            }
        }
    }

    routeTeacher(): void {
        if (!this.reload) {
            const subsctiption = this.authenticationService.currentUserSubject.subscribe(_current_user => {
                if (_current_user.achievements && _current_user.achievements.length > 0 && this.achievementManagerService.achievementToFire.value.length === 0) {
                    this.achievementManagerService.setAchievementsToFire(_current_user);
                    const achievementToFire = this.achievementManagerService.achievementToFire.subscribe(val => {
                        this.router.navigate([val.filter(a => a.achievementDetail.slug === 'teacher-post-calendar-event' ? a : null)[0] ? '/schedule' : '/upcoming']);
                    })
                    achievementToFire.unsubscribe();
                } else {
                    this.router.navigate(['/upcoming']);
                }
            });
            subsctiption.unsubscribe();
        }
    }
}
