import { ExerciceResponseList } from './../model/exerciceResponseList.model';
import { ExerciceResponse } from './../model/exerciceResponse.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class ExerciceResponseService extends KiwixiService {

    apiExerciceResponse = environment.server + 'exercices_responses/';
    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getExerciceResponseById(_id): Observable<ExerciceResponse> {
        const url = this.apiExerciceResponse + _id + '/';
        return this.getExerciceResponseByUrl(url);
    }

    getExerciceResponseByUrl(_url): Observable<ExerciceResponse> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceResponse>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getExerciceResponse`))
            );
    }

    getExerciceResponses(params: HttpParams): Observable<ExerciceResponseList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceResponseList>(this.apiExerciceResponse, {
            headers: header,
            params: params
        })
            .map(_ExerciceResponses => _ExerciceResponses)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceResponseList>(`getObservableExerciceResponses`))
            );
    }

    postExerciceResponse(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<ExerciceResponse>(this.apiExerciceResponse, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceResponse>(`Création d'une réponse`)),
            );
    }

    patchExerciceResponse(_id, params) {
        const url = this.apiExerciceResponse + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<ExerciceResponse>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceResponse>(`Création d'une réponse`)),
            );
    }

    deleteExerciceResponse(_id) {
        const url = this.apiExerciceResponse + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.delete<ExerciceResponse>(url, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceResponse>(`suppression d'une reponse`)),
            );
    }

    deleteMultipleExerciceResponse(choices: ExerciceResponse[]) {
        let choicesToDelete = [];
        const headers = this.createHttpClientAuthorizationHeader();
        choices.forEach(choice => {
            choicesToDelete.push(this._http.delete<ExerciceResponse>(this.apiExerciceResponse + choice.id + '/', { headers: headers }))
        });
        return forkJoin(choicesToDelete)
    }
}
