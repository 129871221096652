/**
 * Composant pour la gestion des indispos.
 * Intègre un calendar sour la sélection de la periode.
 */

import { Component, OnInit } from '@angular/core';
import { EventService } from '../../../../service/event.service';
import { AuthenticationService } from '../../../../service/authentification.service';
import * as moment from 'moment';
import { Event } from '../../../../model/event.model';
@Component({
  selector: 'app-unavailability',
  templateUrl: './unavailability.component.html',
  styleUrls: ['./unavailability.component.scss']
})
export class UnavailabilityComponent implements OnInit {

  myTeacher = null;
  events = [];
  translatePath = 'teacher.schedule';

  // Calendar
  showCalendarFrom = false;
  showCalendarTo = false;
  dateSelected = moment(Date.now());
  fromSelected = moment(Date.now());
  startIsActive = false;
  endIsActive = false;
  startUnavailability = moment(Date.now());
  endUnavailability = moment(Date.now()).add(1, "days");
  countPages = 0;
  current_day = moment();
  loadEvents = true;

  constructor(
    private eventService: EventService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.myTeacher = this.authenticationService.currentUserSubject.value;
    this.updateEvents();
  }

  updateEvents() {
    this.countPages++;
    const id = this.authenticationService.extractIdFromUrl(this.myTeacher.unavaibility_calendar);
    const filterParams = [
      {
        key: 'calendar',
        value: id
      },
      {
        key: 'show_day',
        value: true
      },
      {
        key: 'page',
        value: this.countPages
      }
    ]
    this.eventService.getEventsToTeacher(filterParams).then(_events => {
      _events['results'].forEach(_event => {
        if (moment(_event.end).isSameOrAfter(this.current_day)) {
          this.events.push(_event);
        }
      });
      if (_events['next'] !== null) {
        this.updateEvents();
      } else {
        this.countPages = 0;
        // sorting
        this.events.sort(function (a, b) {
          return new Date(a.start).getTime() - new Date(b.start).getTime()
        });
        this.loadEvents = false;
      }
    })
      .catch(this.eventService.handleError);
  }

  /**
   * Méthode pour  afficher le calendar
   * @param idCalendar
   */
  displayCalendar(idCalendar) {
    if (idCalendar === 1) {
      this.showCalendarFrom = !this.showCalendarFrom;
      this.startIsActive = true;
      this.endIsActive = false;
      this.fromSelected = null;
    }
    if (idCalendar === 2) {
      this.showCalendarTo = !this.showCalendarTo;
      this.startIsActive = false;
      this.endIsActive = true;
      this.fromSelected = this.startUnavailability;
    }
  }

  /**
   * Méthode pour  mettre à jour la date depuis le calendar
   * @param _date
   */
  handleUpdateDateSelect(_date) {
    this.dateSelected = moment(_date);
    // Input Start
    if (this.startIsActive) {
      this.startUnavailability = this.dateSelected;
      if (this.endUnavailability !== null) {
        // Si le start > end
        if (moment(this.startUnavailability).isAfter(moment(this.endUnavailability))) {
          this.endUnavailability = this.dateSelected
        }
      } else {
        this.endUnavailability = this.dateSelected
      }

    }
    // Input End
    if (this.endIsActive) {
      this.endUnavailability = this.dateSelected
    }
  }

  /**
   * event au clic en dehors du calendar
   */
  handleOutside() {
    if (this.showCalendarFrom || this.showCalendarTo) {
      this.showCalendarFrom = false;
      this.showCalendarTo = false;
    }
  }

  /**
   * ajout d'une indispo
   */
  addAnUnavailibilty() {
    const _event = new Event();
    _event.rule = null;
    if (this.myTeacher && this.myTeacher.unavaibility_calendar) {
      _event.calendar = this.myTeacher.unavaibility_calendar;
    }
    const myStart = moment([moment(this.startUnavailability).year(),
    moment(this.startUnavailability).month(), moment(this.startUnavailability).date(),
      0, 0, 0, 0]);
    const myEnd = moment([moment(this.endUnavailability).year(),
    moment(this.endUnavailability).month(), moment(this.endUnavailability).date(),
      23, 59, 59, 999]);
    _event.start = moment(myStart).utc().toDate();
    _event.end = moment(myEnd).utc().toDate();
    _event.show_day = true;
    _event.event_type = 'unavailability';
    this.eventService.postEvent(_event)
      .then(res => {
        this.loadEvents = true;
        this.events = [];
        this.updateEvents();
      })
  }

  /**
   * suppression d'une indispo
   * @param _event
   */
  removeAnUnavailibilty(_event) {
    if (_event) {
      this.eventService.deleteEvent(_event)
        .then(res => {
          this.loadEvents = true;
          this.events = [];
          this.updateEvents();
        })
    }
  }

}
