import { Component, OnInit, Input } from '@angular/core';
import { OccurrenceService } from '../../../service/occurrence.service';
import { KiwixiGlobals } from '../../../kiwixi/kiwixi.globals';

@Component({
  selector: 'app-datatable-cell-next-occurrence',
  templateUrl: './datatable-cell-next-occurrence.component.html',
  styleUrls: ['./datatable-cell-next-occurrence.component.scss'],
  providers: [OccurrenceService, KiwixiGlobals]
})
export class DatatableCellNextOccurrenceComponent implements OnInit {
  @Input() occurrence;
  myOccurrence: Object;
  errorMessage = '';
  loading = true;

  constructor(private ocurrenceService: OccurrenceService, private kiwixiGlobals: KiwixiGlobals) { }

  ngOnInit() {

    if (!(typeof (this.occurrence) === 'object')) {
      this.ocurrenceService.getOccurence(this.kiwixiGlobals.getIdFromUrl(this.occurrence)).then(
        requestData => {
          // name or object ?
          this.myOccurrence = requestData;
          this.loading = false;
        },
        // handle the error, otherwise will break the Observable
        error => {
          this.errorMessage = <any>error;
        }
      );
    } else {
      this.loading = false;
      this.myOccurrence = this.occurrence;
    }
  }

}
