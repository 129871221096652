<ng-container *ngIf="showLingueoApp; else elseTemplate">
	<div id="lingueopro">
		<ng-container>
			<div class="lingueopro-constrainer">
				<app-header-menu></app-header-menu>
				<router-outlet></router-outlet>	
				<app-achievement-modal [slug]="'release'" [student]="student" *ngIf="student"></app-achievement-modal>
				<app-achievement-modal [slug]="'first-login'" [student]="student" *ngIf="student"></app-achievement-modal>
			</div>
		</ng-container>
	</div>
</ng-container>
<ng-template #elseTemplate>
	<div class="valign-wrapper white-text" style="height: 75vh;">
		<h5 style="width:100%; text-align: center; color: rgba(0, 0, 0, 0.3);">Chargement Lingueo...</h5>
	</div>
</ng-template>