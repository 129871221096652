import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Observable } from 'rxjs';

@Injectable()
export class DictionnaryService {
  url = 'traductions/'

  constructor(private _http: HttpClient, private kiwixiService: KiwixiService) { }


  getDictionnary2(_lang): Observable<any> {
    const site = localStorage.getItem('site');
    const newBundle = { 'site': site, 'language': _lang };
    let httpHeader = new HttpHeaders()
    httpHeader = httpHeader.append('x-bundle-recurring', JSON.stringify(newBundle));
    return this._http.get<any>(environment.server + this.url + '/?salt=' + Math.random().toString(), {
      headers: httpHeader,
    })
      .pipe(
        catchError(this.kiwixiService.handleErrorObservable<any>(`getDictionnary`))
      );
  }
  getDictionnary(_lang) {
    // let's connect with minimal viable informations to get locale
    const site = localStorage.getItem('site');
    const newBundle = { 'site': site, 'language': _lang };
    let httpHeader = new HttpHeaders()
    httpHeader = httpHeader.append('x-bundle-recurring', JSON.stringify(newBundle));
    return this._http.get(environment.server + this.url, {
      headers: httpHeader,
    })
      .map(res => {
        const body = res;
        return body || {};
      })
      .toPromise();
  }
}
