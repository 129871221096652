<div class="row information-container" *ngIf="dataFormations != undefined">
  <div class="left">
    <h6 class="center grey darken-1 white-text title-information">Formations & Élèves</h6>
  </div>
  <div class="right openforstudents-switch">
    <div class="col l6 slide-accept">
      <mat-slide-toggle labelPosition="before" [(ngModel)]="isAcceptStudents" (ngModelChange)="updateTeacher(myTeacher, 'accept_students')" [disabled]="isDisable">
        <b *ngIf="myTeacher.accept_students">Accepte de nouveaux élèves</b>
        <b *ngIf="!myTeacher.accept_students">N'accepte pas de nouveaux élèves</b>
      </mat-slide-toggle>
    </div>
  </div>
  <div id="table-formations-edit-teacher" class="row datatable-position">
    <app-datatable id="table-formandstud" class="col l12 app-datatable-position"
      [data]="dataFormations.data"
      [header]="dataFormations.headers"
      [pageSize]="pageSize"
      [totalData]="totalData"
      [component]=this
      [updateMethod]=this.getData
      [gotoMethod]=this.showDetail>
    </app-datatable>
  </div>
  <div class="row" *ngIf="!showTableFormations">
    <div class="center loading-formations">
      <b>Chargement des formations...</b>
    </div>
  </div>
</div>
