import { Formation } from './../../../../../../model/formation.model';
import { ProfilePhotosService } from './../../../../../../service/profilePhotos.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-identity',
  templateUrl: './confirm-identity.component.html',
  styleUrls: ['./confirm-identity.component.scss']
})
export class ConfirmIdentityComponent implements OnInit {
  @Input() formation: Formation;
  constructor(
    private profilePhotosService: ProfilePhotosService,
  ) { }

  ngOnInit() {
  }

  updateProfileStatus(status) {
    const params = {
      status: status,
    };
    this.profilePhotosService.patchProfilePhoto(this.formation.student.user.photo_profile.id, params).subscribe(profilePhoto => {
      if (profilePhoto.status !== 'validation_requested') {
        this.formation.student.user.photo_profile = profilePhoto;
        this.formation.student.user.photo_profile_validation_requested = false;
      }
    })

  }

}
