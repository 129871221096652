<div class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Média Global</h6>
    </div>
    <span class="media-help">Vous pouvez sélectionner ici un média auquel se référeront plusieurs questions de l’exercice.</span>
  </div>
  <div class="row information-subcontainer">
    <div class="col l12">
        <app-manage-exercices-medias [mediaType]="'exercice'" [object]="exercice"></app-manage-exercices-medias>
    </div>

</div>
