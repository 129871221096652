<div class="row"></div>
<div class="container" *ngIf="!showStudent">
  <div class="row center valign-wrapper">
    Chargement de la page Etudiant ...
  </div>
</div>
<div class="" *ngIf="user">

  <div class="row student-header">
    <div class="col l5 left">
      <div class="row valign-wrapper">
        <div class="col left header-avatar-block" *ngIf="user.photo_profile !== null"
          [ngClass]="{'photo_profile_validation_requested': user.photo_profile.status=='validation_requested', 'photo_profile_validated': user.photo_profile.status=='validated', 'photo_profile_refused':user.photo_profile.status=='refused'}">
          <img class="user-avatar" src="{{user.photo_profile.thumbnail}}"
            (error)="this.formationService.errorImgHandler($event)">
        </div>
        <div class="col left header-avatar-block" *ngIf="user.photo_profile == null">
          <img class="user-avatar" src="{{defaultAvatar}}">
        </div>
        <div class="header-data">
          <div class="left">
            <h6 class="student-name">{{user.first_name}} {{user.last_name}}</h6>
            <h6 class="currentID">ID : {{user.id}}</h6>
          </div>
        </div>
        <div class="vip">
          VIP 
          <mat-slide-toggle [checked]="VIP" [(ngModel)]="VIP" (ngModelChange)="updateVIP(VIP)">
          </mat-slide-toggle>  
        </div>
      </div>
    </div>
    <div class="col l3 right">
      <app-open-session [formation]="myFormation" [site]="mySite" [user]="user"></app-open-session>
    </div>
    <div class="col l3 right session-select push">
      <mz-select-container class="session-select">
        <select mz-select [placeholder]="'Choisir une session à ouvrir'" [(ngModel)]="mySite">
          <option *ngFor="let site of sites" [value]="site.name" class="left circle grey-text "
            [disabled]="site.disable">{{site.name}}</option>
        </select>
      </mz-select-container>
    </div>
  </div>

  <div class="row">
    <div class="col l4">
      <!-- Left panel -->

      <div class="information-container">

        <div class="row"></div>

        <div class="row">
          <div class="col l3 left avatar-wrapper">
            <div class="avatar-block" *ngIf="user.photo_profile !== null"
              [ngClass]="{'photo_profile_validation_requested': user.photo_profile.status=='validation_requested', 'photo_profile_validated': user.photo_profile.status=='validated', 'photo_profile_refused':user.photo_profile.status=='refused'}">
              <img class="user-avatar" src="{{user.photo_profile.thumbnail}}"
                (error)="this.formationService.errorImgHandler($event)">
            </div>
            <div class="avatar-block" *ngIf="user.photo_profile == null">
              <img class="user-avatar" src="{{defaultAvatar}}">
            </div>
            <div class="blue-grey lighten-3 white-text btn-change-avatar" #upload (click)="file.click()">
              Modifier
            </div>
            <input #file type="file" (change)="uploadAvatar($event)" [hidden]="true" />
          </div>
          <div class="col l9">
            <div class="row valign-wrapper breathing-row form-row">
              <div class="col l4 name-label">
                <span class="left">Prénom :</span>
              </div>
              <div class="col l8">
                <input type="text" [(ngModel)]="user.first_name" class="filter-col textfield"
                  (focusout)="updateUser(user, 'first_name')">
              </div>
            </div>
            <div class="row valign-wrapper breathing-row form-row">
              <div class="col l4 name-label">
                <span class="left">Nom :</span>
              </div>
              <div class="col l8">
                <input type="text" [(ngModel)]="user.last_name" class="filter-col textfield"
                  (focusout)="updateUser(user, 'last_name')">
              </div>
            </div>
          </div>
        </div>

        <div class="row valign-wrapper breathing-row form-row">
          <div class="col l3 left">
            Email :
          </div>
          <div class="col l6 left">
            <input id="email" type="email" [(ngModel)]="user.email" class="filter-col left validate textfield">
          </div>
          <button class="action-button confirm-change-btn" mz-button [flat]="'true'" (click)="validModalEmail = 'true'"
            [disabled]="!user.email">
            Valider
          </button>
          <app-password-modal [user]="user" [parent]=this *ngIf="validModalEmail" [field]="'email'"
            [callback_variable]="validModalEmail" (outputValidModal)="handleShowValidModal($event)">
          </app-password-modal>
        </div>

        <div class="row valign-wrapper breathing-row form-row">
          <div class="col l3 left">
            Date de naissance :
          </div>
          <div class="col l9 left">
            <input mdInput [matDatepicker]="birthDate" (click)="birthDate.open()"
              class="filter-col textfield left validate" [(ngModel)]="user.birthdate"
              (ngModelChange)="updateUser(user, 'birthdate')">
            <mat-datepicker #birthDate></mat-datepicker>
          </div>
        </div>

        <div class="row valign-wrapper breathing-row breathing-row">
          <div class="col l3 left">
            Status Photo Profile :
          </div>
          <div class="col l5 left" *ngIf="user.photo_profile">
            <mat-select [placeholder]="'Statut photo profil'" [(ngModel)]="user.photo_profile.status"
              (ngModelChange)="patchStatusPhotoProfile()">
              <mat-option *ngFor="let status of listStatusPhotosProfile" [value]="status.value"
                class="left circle grey-text ">
                {{status.name}}
              </mat-option>
            </mat-select>
          </div>
          <div class="col l5 left" *ngIf="user.photo_profile==null">
            Aucune photo
          </div>
        </div>

        <div class="row valign-wrapper breathing-row form-row">
          <div class="col l3 left">
            SF Contact ID :
          </div>
          <div class="col l6 left">
            <input id="sf_id" type="text" [(ngModel)]="user.sf_id" class="filter-col left validate textfield"
              (focusout)="updateUser(user, 'sf_id')">
          </div>
        </div>

        <div class="row valign-wrapper breathing-row breathing-row">
          <div class="col l3 left">
            Genre :
          </div>
          <div class="col l5 left">
            <mat-select [placeholder]="'Choisir'" [(ngModel)]="user.gender"
              (ngModelChange)="updateUser(user, 'gender')">
              <mat-option *ngFor="let gender of genders" [value]="gender.en" class="left circle grey-text ">
                {{gender.fr}}</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="row valign-wrapper breathing-row">
          <div class="col l3 left">
            Client sur :
          </div>
          <div class="col l9 list-sites" *ngIf="sitesAreReady">
            <mat-select [(ngModel)]="mySites" (onClose)="updateSites()" multiple>
              <mat-option *ngFor="let site of sites" [value]="site.url">
                {{ site.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="row valign-wrapper breathing-row breathing-row">
          <div class="row createdAt-row">
            <span class="">Compte crée le : </span>
            <span class="">
              {{user?.created_at | amLocal | amDateFormat: 'DD/MM/YYYY HH:mm:ss'}}
            </span>
          </div>
        </div>

        <div class="breathing-row">
          <div class="row valign-wrapper form-row ">
            <div class="col l3 left">
              Fuseau :
            </div>
            <div class="col l9">
              <app-timezone-autocomplete [myUser]="user" [isBackoffice]="true"></app-timezone-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="col l12 right-text grey-text">
              heure actuelle : {{now | amLocal | amDateFormat: 'HH:mm:ss'}}
            </div>
          </div>
        </div>

        <div class="row valign-wrapper breathing-row">
          <div class="col l6 left">
            Langue principale :
          </div>
          <div class="col l6">
            <form>
              <input type="text" class="dropdown" aria-label="Number" mdInput [formControl]="languageControl"
                [matAutocomplete]="auto2">
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayLanguageFn">
                <mat-option *ngFor="let language of languages" [value]="language">
                  {{ language.code | translate }}
                </mat-option>
              </mat-autocomplete>
            </form>
          </div>
        </div>

        <div class="row password-lbl">
          <div class="col l7 left">
            Nouveau mot de passe :
          </div>
        </div>
        <div class="row valign-wrapper row-bottom-none">
          <div class="">
            <input type="password" [disabled]="user.auto_pwd" [(ngModel)]="user.password"
              class="filter-col password-input">
          </div>
          <div class="col l3 offset-l1">
            <button class="action-button pswd-btn" mz-button [flat]="'true'" (click)="validModal = 'true'"
              [disabled]="!user.password && !user.auto_pwd">
              Changer
            </button>
            <app-password-modal [user]="user" [parent]=this [field]="user.auto_pwd?'auto_pwd':'password'"
              [callback_variable]="validModal" (outputValidModal)="handleShowValidModal($event)" *ngIf="validModal">
            </app-password-modal>
          </div>
        </div>
        <div class="row password-cbx">
          <mat-checkbox class="col l12" [(ngModel)]="user.auto_pwd" (ngModelChange)="user.password = null">Mot de passe
            auto-généré</mat-checkbox>
        </div>
        <div class="active-switch">
          <ng-container *ngIf="user.is_active; else elseActive">
            <mat-slide-toggle [(ngModel)]="user.is_active" (ngModelChange)="showValidModal = 'true'">Compte activé
            </mat-slide-toggle>
          </ng-container>
          <ng-template #elseActive>
            <mat-slide-toggle [(ngModel)]="user.is_active" (ngModelChange)="showValidModal = 'true'">Compte desactivé
            </mat-slide-toggle>
          </ng-template>
        </div>
        <app-valid-modal [user]="user" (outputValidModal)="handleShowValidModal($event)" *ngIf="showValidModal">
        </app-valid-modal>
      </div>
    </div>

    <div class="col l8">
      <div id="next-class-container" class="row information-container">
        <div class="left">
          <h6 class="center grey darken-1 white-text title-information">Prochain cours</h6>
        </div>
        <div>
          <app-next-class-student [user]="user"></app-next-class-student>
        </div>
      </div>
    </div>

    <div class="col l8">
      <div class="row information-container" *ngIf="dataFormations != undefined">
        <div class="left">
          <h6 class="center grey darken-1 white-text title-information">Formations</h6>
        </div>
        <div class="right new-formation-position">
          <button class="action-button" mz-button [flat]="'true'" (click)="showCreateFormation()">
            Nouvelle Formation
            <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'"></i>
          </button>
        </div>
        <div id="table-formations-edit-student" class="row datatable-position">
          <app-datatable class="col l12 app-datatable-position" [data]="dataFormations.data"
            [header]="dataFormations.headers" [pageSize]="pageSize" [totalData]="totalData" [component]=this
            [updateMethod]=this.getData [gotoMethod]=this.gotoDetailFormation [cssclass]="'subtable'">
          </app-datatable>
        </div>
      </div>
    </div>
  </div>

</div>