<div class="header-menu">
  <div class="teachers-logo" routerLink="upcoming"></div>
  <div class="header-greeting"><span>Hello {{myUser.user.first_name}}!</span></div>

  <div class="header-buttons">
  <div *ngIf="myUser" class="item teacher-avatar" routerLink="/myaccount">
      <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="myUser.user.photo_profile === null">
      <img class="user-avatar" src="{{myUser.user.photo_profile.thumbnail}}" *ngIf="myUser.user.photo_profile !== null">
  </div>
  <div class="item help-button" routerLinkActive=" "><a href="https://lingueocustomers.crisp.help/fr/" target="_blank"></a></div>
  </div>
</div>

<div class="nav-block">
  <div class="desktop-navrow nav-upcoming" routerLinkActive="  focused" routerLink="/upcoming">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.upcoming-class</span>
  </div>
  <div class="desktop-navrow nav-schedule" routerLinkActive="  focused" routerLink="/schedule">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.schedule-avaibilities</span>
  </div>
  <div class="desktop-navrow nav-reports" routerLinkActive="  focused" routerLink="/reports">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.class-reports</span>
  </div>
  <div class="desktop-navrow nav-trainings" routerLinkActive="  focused" routerLink="/trainings">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.student-trainings</span>
  </div>
  <div class="desktop-navrow nav-messages" routerLinkActive="  focused" routerLink="/messages">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.messages</span>
    <div class="unread-counter" *ngIf="nb_unreadMessages>0">{{ nb_unreadMessages }}</div>
  </div>
  <div class="desktop-navrow nav-sheets" routerLinkActive="  focused" *ngIf="checkFeaturePermission('sheets') && checkAvailableLanguageSheets()" routerLink="/sheets">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.sheets</span>
  </div>
  <div class="desktop-navrow nav-billing" routerLinkActive="  focused" routerLink="/billing">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.billing</span>
  </div>
  <div class="desktop-navrow nav-account" routerLinkActive="  focused" routerLink="/myaccount">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title" translate>teacher.nav.myaccount</span>
  </div>
  <div class="logout-navrow nav-logout" routerLinkActive="  focused" routerLink="/logout" (click)="logout()">
    <span class="sidenav-icon"></span>
    <span class="sidenav-title">Log Out</span>
  </div>
</div>
