import { FormationOptionsService } from './../../../../../../../service/formationOptions.service';
import { Formation } from './../../../../../../../model/formation.model';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Teacher } from '../../../../../../../model/teacher.model';
import { TeacherService } from '../../../../../../../service/teacher.service';
import { HttpParams } from '@angular/common/http';
import { MzToastService } from 'ng2-materialize';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss', '../../../../mainscreen.ux.scss']
})
export class TeacherComponent implements OnInit {

  @Input() formation: Formation;
  avalaible_teachers: Teacher[];
  exclude_teachers: any[] = [];
  forced_teachers: any[] = [];
  teacher_FormControl = new FormControl('', []);

  constructor(
    private teacherService: TeacherService,
    private formationOptionService: FormationOptionsService,
    private toastService: MzToastService,
  ) { }

  ngOnInit() {
    this.teacher_FormControl.valueChanges
      .debounceTime(500)
      .subscribe(val => {
        this.getAvailablesTeachers(val);

      }
      );
    this.getAvailablesTeachers('');
    this.getExclude_teachers();
    this.getForced_teachers();
  }

  // fonction permettant d'afficher le nom de l'organisation dans l'auto complete
  displayTeacherNameFn(teacher?: Teacher): String | undefined {
    return teacher ? teacher.user.first_name + ' ' + teacher.user.last_name : undefined;
  }


  getAvailablesTeachers(searchValue) {
    let params = new HttpParams();
    params = params.append('teach_language__id', this.teacherService.extractIdFromUrl(this.formation.language).toString());
    params = params.append('status', 'accepted');
    params = params.append('accept_students', 'true');
    params = params.append('name', searchValue);
    params = params.append('salt', Math.random().toString());

    this.teacherService.getObservableTeachers(params).subscribe(data => {
      if (data['count'] > 0) {
        this.avalaible_teachers = data['results']
      }
      else {
        this.avalaible_teachers = [];
      }
    });
  }

  getExclude_teachers() {
    let _params = new HttpParams();
    _params = _params.append('formation', this.formation.id.toString());
    _params = _params.append('option_type', 'exclude_teacher');

    this.formationOptionService.getFormationOptions(_params).subscribe(data => {
      if (data['count'] > 0) {
        this.exclude_teachers = data['results'];
      }
    });
  }
  getForced_teachers() {
    let _params = new HttpParams();
    _params = _params.append('formation', this.formation.id.toString());
    _params = _params.append('option_type', 'force_teacher');

    this.formationOptionService.getFormationOptions(_params).subscribe(data => {
      if (data['count'] > 0) {
        this.forced_teachers = data['results'];
      }
    });
  }

  saveOption(option_type) {
    if (!this.teacher_FormControl.value.id) {
      this.toastService.show('Merci de selctionner un professeur', 4000, 'red');
      return
    }
    const _params = {
      option_type: option_type,
      value: this.teacher_FormControl.value.id,
      formation: this.formation.url
    }

    this.formationOptionService.postFormationOption(_params).subscribe(data => {
      if (data.id > 0) {
        if (option_type === 'force_teacher') {
          this.forced_teachers.push(data);
        }
        if (option_type === 'exclude_teacher') {
          this.exclude_teachers.push(data);
        }
        this.toastService.show('Professeur ajouté', 4000, 'green');
      }
    });
  }

  deleteForcedTeacher(option_id) {
    this.formationOptionService.deleteOption(option_id).subscribe(data => {
      this.forced_teachers = this.forced_teachers.filter(option => option.id !== option_id);
      this.toastService.show('Professeur supprimé', 4000, 'green');
    })
  }

  deleteExcludeTeacher(option_id) {
    this.formationOptionService.deleteOption(option_id).subscribe(data => {
      this.exclude_teachers = this.exclude_teachers.filter(option => option.id !== option_id);
      this.toastService.show('Professeur supprimé', 4000, 'green');
    })
  }

}
