import { ExerciceAnswerChoiceList } from './../model/exerciceAnswerChoiceList.model';
import { ExerciceAnswerChoice } from './../model/exerciceAnswerChoice.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class ExerciceAnswerChoiceService extends KiwixiService {

    apiExerciceAnswerChoice = environment.server + 'exercices_answers_choices/';
    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getExerciceAnswerChoiceById(_id): Observable<ExerciceAnswerChoice> {
        const url = this.apiExerciceAnswerChoice + _id + '/';
        return this.getExerciceAnswerChoiceByUrl(url);
    }

    getExerciceAnswerChoiceByUrl(_url): Observable<ExerciceAnswerChoice> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceAnswerChoice>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getExerciceAnswerChoice`))
            );
    }

    getExerciceAnswerChoices(params: HttpParams): Observable<ExerciceAnswerChoiceList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceAnswerChoiceList>(this.apiExerciceAnswerChoice, {
            headers: header,
            params: params
        })
            .map(_ExerciceAnswerChoices => _ExerciceAnswerChoices)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceAnswerChoiceList>(`getObservableExerciceAnswerChoices`))
            );
    }

    postExerciceAnswerChoice(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<ExerciceAnswerChoice>(this.apiExerciceAnswerChoice, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceAnswerChoice>(`Création d'une réponse type choice`)),
            );
    }

    patchExerciceAnswerChoice(_id, params) {
        const url = this.apiExerciceAnswerChoice + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<ExerciceAnswerChoice>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceAnswerChoice>(`Création d'une réponse type choice`)),
            );
    }

    shuffle(answers: ExerciceAnswerChoice[]): ExerciceAnswerChoice[] {
        let ctr = answers.length;
        let temp;
        let index;

        // While there are elements in the array
        while (ctr > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * ctr);
            // Decrease ctr by 1
            ctr--;
            // And swap the last element with it
            temp = answers[ctr];
            answers[ctr] = answers[index];
            answers[index] = temp;
        }
        return answers;
    }
}
