<div id="listing-recurring">
  <div class="row"></div>
  <div class="row">
      <div class="col left">
        <h5 class="page-title">Disponibilités des professeurs</h5>
      </div>
  </div>

  <div class="row"> 
    <div class="row">
      <div [formGroup]="filterFormGroup">
        <div class="col l2 filter">
          <input class="date-input range-left-input" (click)="start_period.open()" [matDatepicker]="start_period" formControlName="start_period" placeholder="du">
          <mat-datepicker #start_period></mat-datepicker>
        </div>
        <div class="col l2 filter">
          <input class="date-input range-left-input" (click)="end_period.open()" [matDatepicker]="end_period" formControlName="end_period" placeholder="du">
          <mat-datepicker #end_period></mat-datepicker>
        </div>
        <div class="col l3 filter">
           <mat-select formControlName="language" class="header-select" placeholder="Langue">
                <mat-option *ngFor="let language of languageList"  [value]="language">
                {{language.label}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col l2 filter">
          <button (click)="getData()" class="action-button addnew-button export-button" mz-button [disabled]="!filterFormGroup.valid">Rechercher</button>
        </div>
        <div class="col l2 filter">
            <button class="action-button addnew-button export-button" mz-button (click)="export()" [disabled]="dataSource.data.length==0">
                Export XLS
                <i class="material-icons tiny left">launch</i>
            </button>
        </div>
      </div>
    </div>      
  </div>

  <div class="example-loading-shade" *ngIf="isLoadingResults">
      <mat-spinner></mat-spinner>
  </div>

  <div class="row datatable-position" >
    <div class="row totaux">
      <div>Nombre de professeurs : {{ dataSource.data.length | number:'':'fr-FR'}}</div>
      <div>Total heures récurrentes disponibles : {{ totalRecAvailabilities | number:'':'fr-FR'}}</div>
      <div>Total heures récurrentes réservées : {{ totalRecAvailabilitiesBooked | number:'':'fr-FR'}}</div>
      <div>Total heures ponctuelles disponibles : {{ totalPonctualAvailabilities | number:'':'fr-FR'}}</div>
      <div>Total heures ponctuelles réservées : {{ totalPonctualAvailabilitiesBooked | number:'':'fr-FR'}}</div>
    </div>
    <div class="row"> 
      <div class="filter-table">
        <input (keyup)="applyFilter($event.target.value)" placeholder="Filtrer">
      </div>
    </div>

    <div class="row">
      <table mat-table [dataSource]="dataSource" matSort class="reccurring-table">

      <!-- TeacherID Column -->
      <ng-container matColumnDef="teacherId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> TeacherID </th>
        <td mat-cell *matCellDef="let row"> {{row.teacherId}} </td>
      </ng-container>

      <!-- first_name Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
        <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
      </ng-container>

      <!-- last_name Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
        <td mat-cell *matCellDef="let row"> {{row.last_name}} </td>
      </ng-container>

      <!-- language Column -->
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Langue </th>
        <td mat-cell *matCellDef="let row"> {{row.language}} </td>
      </ng-container>

        <!-- rec_availabilities Column -->
      <ng-container matColumnDef="rec_availabilities">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Heures Rec dispo </th>
        <td mat-cell *matCellDef="let row"> {{row.rec_availabilities}} </td>
      </ng-container>

        <!-- rec_availabilities_booked Column -->
      <ng-container matColumnDef="rec_availabilities_booked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Heures Rec Réservées </th>
        <td mat-cell *matCellDef="let row"> {{row.rec_availabilities_booked}} </td>
      </ng-container>

        <!-- ponctual_availabilities Column -->
      <ng-container matColumnDef="ponctual_availabilities">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Heures Ponctuelles dispo </th>
        <td mat-cell *matCellDef="let row"> {{row.ponctual_availabilities}} </td>
      </ng-container>

        <!-- ponctual_availabilities_booked Column -->
      <ng-container matColumnDef="ponctual_availabilities_booked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Heures Ponctuelles Réservées </th>
        <td mat-cell *matCellDef="let row"> {{row.ponctual_availabilities_booked}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openTeacher(row)"></tr>
      </table>
      <div *ngIf="dataSource.data.length==0" class="no-records">
              No records found
      </div>
      <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>

    </div>
    <!-- FIN CONTENT PAGE -->
  </div>
</div>