import { KiwixiService } from './../../../../kiwixi/kiwixi.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';
import { FormationService } from '../../../../service/formation.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StudentService } from '../../../../service/student.service';
import { TeacherService } from '../../../../service/teacher.service';

import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { StaffService } from '../../../../service/staff.service';
import { ConversationService } from '../../../../service/conversation.service';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {
  myUser: any;
  searchInput = '';
  filterSelect = 1;
  conversationList: any[] = [];
  my_timeout = null;
  defaultAvatar = environment.static + '/avatar.png';
  searchControl: FormControl = new FormControl();
  translatePath = 'teacher.messages';
  showConversations = false;
  isLoaderLazy = false;
  nextPages = 1;

  constructor(
    private authenticationService: AuthenticationService,
    private formationService: FormationService,
    private studentService: StudentService,
    private conversationService: ConversationService,
    private kiwixiService: KiwixiService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
          this.getConversationList(this.searchInput);

          this.searchControl.valueChanges
            .debounceTime(300)
            .subscribe(term => {
              this.nextPages = 1;
              this.conversationList = []
              this.getConversationList(term);
            })
          this.polling();
        }
      }
    )
  }

  getConversationList(searchInput) {
    this.isLoaderLazy = true;
    let params = new HttpParams()
    params = params.append('persona', this.myUser.id)
    params = params.append('ordering', 'true')
    if (!this.nextPages) {
      this.isLoaderLazy = false;
      return;
    }
    params = params.append('page', this.nextPages.toString());

    if (searchInput) {
      params = params.append('student_name', searchInput)
    }
    this.conversationService.getConversations(params)
      .subscribe(conversations => {
        if (conversations) {
          if (conversations['results']) {
            conversations['results'].forEach(_data => this.conversationList.push(_data))
            this.conversationService.conversationList.next(this.conversationList);
            this.nextPages = this.kiwixiService.getUrlParams(conversations['next'], 'page');
          }
          this.showConversations = true;
          this.isLoaderLazy = false;
        }
      });
  }

  polling() {
    this.my_timeout = Observable.interval(300000)
      .subscribe(i => {
        this.nextPages = 1;
        this.conversationList = []
        this.getConversationList(this.searchInput)
      })
  }

  ngOnDestroy() {
    if (this.my_timeout) {
      this.nextPages = 1;
      this.my_timeout.unsubscribe()
    }
  }

  loadMore() {
    this.getConversationList(this.searchInput);
  }

  search(term: string): void {
    this.searchInput = term;
    this.searchControl.patchValue(term);
  }

}
