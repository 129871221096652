<div class="addeditevent-widget">
    <p class="widget-title" mat-dialog-title *ngIf="!availablesStartSlots">Add an {{type}} slot</p>
    <p class="widget-title" mat-dialog-title *ngIf="availablesStartSlots">Edit an {{type}} slot</p>
    <button class="widget-closebtn" (click)='delete()'>Delete</button>
    <div class="dialogs-wrap" mat-dialog-content>
        <div class="dialogs-row">
            <div class="dialog">
                <div class="dialog-text">
                    From :
                </div>
                
                <div class="dialog-choice not-editable" *ngIf="!availablesStartSlots">
                    {{data.event.start | amDateFormat:'HH:mm'}}
                </div>
            

                <div class="dialog-choice" *ngIf="availablesStartSlots">
                    <mat-select [(ngModel)]="eventStart" (ngModelChange)="getNextUnavailableSlot()">
                        <mat-option *ngFor="let slot of availablesStartSlots" [value]="slot">
                            {{ slot }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="dialog">
                <div class="dialog-text">
                    To :
                </div>
                <div class="dialog-choice">
                    <mat-select [(ngModel)]="eventEnd" (ngModelChange)="updateEnd()">
                        <mat-option *ngFor="let slot of allEndSlots" [value]="slot">
                            {{ slot }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button class="widget-savebtn" [disabled]="!validity" mat-button (click)="closeModal()">Save</button>
    </div>
</div>