import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../../../service/site.service';
@Component({
  selector: 'app-datatable-cell-site',
  templateUrl: './datatable-cell-site.component.html',
  styleUrls: ['./datatable-cell-site.component.scss']
})
export class DatatableCellSiteComponent implements OnInit {

  @Input() site;
  my_site: Object;
  errorMessage = '';
  loading = true;

  constructor(private siteService: SiteService) { }

  ngOnInit() {

    if (!(typeof (this.site) === 'object')) {
      this.siteService.getObservableSite(this.site)
        .subscribe(_site => {
          this.my_site = _site;
          this.loading = false;
        },
          // handle the error, otherwise will break the Observable
          error => {
            this.errorMessage = <any>error;
          });
    } else {
      this.loading = false;
      this.my_site = this.site;
    }
  }

}
