import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';

@Component({
  selector: 'app-recurring-availibility',
  templateUrl: './recurring-availibility.component.html',
  styleUrls: ['./recurring-availibility.component.scss']
})
export class RecurringAvailibilityComponent implements OnInit {
  translatePath = 'teacher.schedule';
  calendarId: number;
  calendarUrl: string;
  showInfo = false;

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(_current_user => {
      if (_current_user) {
        this.calendarId = this.authenticationService.extractIdFromUrl(_current_user.calendar);
        this.calendarUrl = _current_user.calendar;
      }
    })
  }

}
