import { IdentityComponent } from './identity/identity.component';
import { InitPonctualDemoTourComponent } from './init-ponctual-booking/init-ponctual-demo-tour/init-ponctual-demo-tour.component';
import { InitPonctualBookingComponent } from './init-ponctual-booking/init-ponctual-booking.component';
import { BookingModeComponent } from './booking-mode/booking-mode.component';
import { InformationComponent } from './information/information.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
import { IntroductionComponent } from './introduction/introduction.component';
import { LevelComponent } from './level/level.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ResumeComponent } from './resume/resume.component';
import { ConclusionComponent } from './conclusion/conclusion.component';
import { PlanFormationComponent } from './plan-formation.component';
import { PlanFormationGuard } from '../../../guard/plan-formation.guard';
import { InitPonctualBookingConclusionComponent } from './init-ponctual-booking/init-ponctual-booking-conclusion/init-ponctual-booking-conclusion.component';
import { BirthdateComponent } from './birthdate/birthdate.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';

// from module

export const routes: Routes = [
  {
    path: 'plan-formation',
    component: PlanFormationComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: 'introduction/:id', component: IntroductionComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'information/:id', component: InformationComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'identity/:id', component: IdentityComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'birthdate/:id', component: BirthdateComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'booking-mode/:id', component: BookingModeComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'level/:id', component: LevelComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'init-ponctual-booking/:id', component: InitPonctualBookingComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'init-ponctual-booking/conclusion/:id', component: InitPonctualBookingConclusionComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'schedule/:id', component: ScheduleComponent, canActivate: [AuthGuard, PlanFormationGuard] },
      { path: 'resume/:id', component: ResumeComponent, canActivate: [AuthGuard, PlanFormationGuard], data: {} },
      { path: 'conclusion/:id', component: ConclusionComponent, canActivate: [AuthGuard] },
      { path: 'demo-tour/:id', component: InitPonctualDemoTourComponent, canActivate: [AuthGuard] },
    ]
  }
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanFormationRoutingModule { }
