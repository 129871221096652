import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Language } from '../model/language.model';
import { Observable } from 'rxjs';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';


import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class LanguageService extends KiwixiService {
  apilanguages = environment.server + 'languages/';
  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getLanguages(filterParams): Promise<Language[]> {
    let params = new HttpParams();
    for (const filterParam of filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apilanguages, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getLanguage(id): Promise<Language> {
    const url = this.apilanguages + id;
    return this.getLanguageWithUrl(url);
  }

  getLanguageWithUrl(url): Promise<Language> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get(url, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getObservableLanguageWithUrl(url): Observable<Language> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Language>(url, { headers: header }).
      pipe(
        catchError(this.handleErrorObservable<Language>(`getLanguage`))
      );
  }

  getObservableLanguages(): Observable<Language[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Language[]>(this.apilanguages, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Language[]>(`getObservableLanguages`))
      );
  }

}
