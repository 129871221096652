<div class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Informations générales</h6>
    </div>
  </div>
  <div class="row information-subcontainer">
    <div class="col l7 panel-left">
      <div class="row clean-row">
        <span>Statut :</span>
        <mat-select class="status-dropdown" [(ngModel)]="formation.status" (ngModelChange)="selectStatus()">
          <mat-option *ngFor="let status of listStatus" [value]="status.code">
            {{ status.label }}
          </mat-option>
        </mat-select>
      </div>
      <div class="row clean-row">
        <div class="col l10 truncate">
          Heures consommées :
        </div>
        <div class="col l2">{{ formation.hours_consummed }}</div>
      </div>
      <div class="row clean-row">
        <div class="col l9 truncate">
          Heures totales de la formation :
        </div>
        <mz-input-container id="hours-total" class="col l3 hours-remaining" (focusout)="focusOutTotalHours()">
          <input mz-input [(ngModel)]="formation.hours_total" type="number">
        </mz-input-container>
      </div>
      <div class="row clean-row">
        <div class="col l10 truncate">
          Heures restantes à plannifier :
        </div>
        <div class="col l2">{{formation.hours_to_plan | number:'1.1-1'}}</div>
      </div>
      <div class="row clean-row">
        <div class="col l12">Periodes de validité :</div>
        <div class="col l5">
          <input mdInput [matDatepicker]="pickerStart" [max]="endDate" (ngModelChange)="updateStartDate($event)" (click)="pickerStart.open()" [(ngModel)]="startDate">
          <mat-datepicker #pickerStart [startAt]="startDate"></mat-datepicker>
        </div>
        <div class="col l2 arrow-position">
          <i mz-icon-mdi [icon]="'arrow-right'" class="cyan-text"></i>
        </div>
        <div class="col l5">
          <input mdInput [matDatepicker]="pickerEnd" [min]="startDate" (ngModelChange)="updateEndDate($event)" (click)="pickerEnd.open()" [(ngModel)]="endDate">
          <mat-datepicker #pickerEnd [startAt]="endDate"></mat-datepicker>
        </div>
        <div class="col l5">
          <input mdInput [matDatepicker]="pickerStartFrom" [min]="startDate" (ngModelChange)="updateStartFromDate($event)" (click)="pickerStartFrom.open()" [(ngModel)]="startFrom">
          <mat-datepicker #pickerStartFrom [startAt]="startFrom"></mat-datepicker>
        </div>
      </div>

      

      
      <div class="row">
        <div class="col l12">
          Site :
          <ng-container *ngIf="mySite">
            <a href="https://{{mySite.domain}}" target="_blank">{{mySite.name}}</a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col l5 panel-right">
      <mz-textarea-container class="textarea-full-width">
        <span>Notes aux professeurs :</span>
        <textarea class="largetextfield" mz-textarea [(ngModel)]="formation.notes_to_teachers" id="teachers-notes" (focusout)="focusOutNotes()">{{formation.notes_to_teachers}}
        </textarea>
      </mz-textarea-container>
      <div class="row responsable-edit" *ngIf="editResponsable">
        <div class="col l2 right waves-effect" (click)="cancelResponsable()">
          <i mz-icon [align]="'right'" [icon]="'cancel'" [size]="'tiny'"></i>
        </div>
        <form>
          <input type="text" class="dropdown" aria-label="Number" mdInput [formControl]="managerControl" [matAutocomplete]="autoManager" (focusout)="checkInputManager()">
          <mat-autocomplete #autoManager="matAutocomplete" [displayWith]="displayManagerFn">
            <mat-option *ngFor="let manager of filteredManager | async" [value]="manager">
              <div *ngIf="manager.user.first_name; else noFirstName">
                {{ manager.user.first_name }} {{ manager.user.last_name}}
              </div>
              <ng-template #noFirstName>
                {{ manager.user.email }}
              </ng-template>
            </mat-option>
          </mat-autocomplete>
        </form>
      </div>
      <div class="row responsable-non-edit" *ngIf="!editResponsable">
        <div class="col l10 truncate" *ngIf="queryResponsable">
          Responsable :
          <br />
          <p *ngIf="formation.manager.user.first_name || formation.manager.user.last_name">
            {{formation.manager.user.first_name}} {{formation.manager.user.last_name}}
          </p>
          <p *ngIf="!formation.manager.user.first_name && !formation.manager.user.last_name">
            {{formation.manager.user.email}}
          </p>
        </div>
        <div class="col l2 waves-effect waves-indigo" (click)="editResponsable = true;">
          <i mz-icon [align]="'right'" [icon]="'edit'" [size]="'tiny'"></i>
        </div>
      </div>
    </div>
    <div id="formation-groups" class="row col l12 clean-row flexparent centeralign">
        <span class="flexchild nogrow groupstitle">Groupes :</span>
        
          <app-chips class="flexchild flexparent centeralign"
            [parent]=this
            [chipsList]="selectedGroupSpecificList"
            [choices]="selectedGroupSpecificList"
            [data]="groupSpecificList"
            [label]="'name'"
            [myObject]=""
            [removableCondition]="true">
          </app-chips>
      </div>
   <div class="row clean-row">
        <div class="col l6 truncate">
           <span class="flexchild nogrow mode-financement-title col l4">Mode de financement :</span>
        </div>
        <div class="col l6"> <mat-select class="status-dropdown" [(ngModel)]="formation.mode_financement" (ngModelChange)="selectModeFinancement()">
          <mat-option *ngFor="let mode of listModeFinancement" [value]="mode.code">
            {{ mode.label }}
          </mat-option>
        </mat-select></div>
      </div>
  </div>
  <div class="row clean-row">
        <div class="col l6 truncate">
           <span class="flexchild nogrow mode-financement-title col l4">Mode de planification :</span>
        </div>
        <div class="col l6"> <mat-select class="status-dropdown" [(ngModel)]="formation.booking_mode" (ngModelChange)="selectBookingMode()">
          <mat-option *ngFor="let mode of listBookingMode" [value]="mode.code">
            {{ mode.label }}
          </mat-option>
        </mat-select></div>
      </div>
  <div class="row clean-row">
        <div class="col l6 truncate">
           <span class="flexchild nogrow mode-financement-title col l4">SF Opportunity ID :</span>
        </div>
        <div class="col l6"> 
         <input id="sf_id" type="text" [(ngModel)]="formation.sf_id" (ngModelChange)="updateOpportunityId()" 
          class="filter-col textfield left validate">
        </div>
  </div>
</div>
