<div class="row information-container">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Niveau & Progression</h6>
    </div>
  </div>
  <div class="row information-subcontainer" *ngIf="radarChartData[0]['data'].length > 0">
    <div class="container" style="display: block">
      <div class="row" style="margin-bottom: 0" >
        <app-radar2 [isBackoffice]=true [radarTemplate]="'page'"
          [pastOccurrences]="pastOccurrences" [totalOccurrences]="totalOccurrences" [formation]="formation" (selectedOccurrence)="handleSelectedOccurrence($event)">
        </app-radar2>
      </div>
    </div>
  </div>
  <div class="row information-subcontainer center" *ngIf="radarChartData[0]['data'].length === 0">
    <div class="container" style="display: block">
      <div class="row" style="margin-bottom: 0" >
          <button class="lilate-btn" type="button" name="button" [disabled]=true>Générer l'attestation LILATE</button>
      </div>
    </div>
  </div>

  <div class="row information-subcontainer center">
    <div class="container" style="display: block">
      <div class="row" style="margin-bottom: 0" >
        <app-edit-report-progression [formation]="formation" [occurrence]="currentOccurrence" (updateOccurrence)="handleSelectedOccurrence($event)"></app-edit-report-progression>
      </div>
    </div>
  </div>
</div>