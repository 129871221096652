import { Group } from './../model/group.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { GroupList } from '../model/groupList.model';


@Injectable()
export class GroupService extends KiwixiService {

    apiGroup = environment.server + 'groups/';
    public appGroups: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>(null);

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getGroupById(_id): Observable<Group> {
        const url = this.apiGroup + _id + '/';
        return this.getGroupByUrl(url);
    }

    getGroupByUrl(_url): Observable<Group> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<Group>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getGroup`))
            );
    }

    getGroups(params: HttpParams): Observable<GroupList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<GroupList>(this.apiGroup, {
            headers: header,
            params: params
        })
            .map(_Groups => _Groups)
            .pipe(
                catchError(this.handleErrorObservable<GroupList>(`getObservableGroups`))
            );
    }
}
