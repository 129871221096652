import { InitAppService } from './../../service/initapp.service';
/**
 * Composant parent du Backoffice
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DictionnaryService } from '../../service/dictionnary.service';
import { TranslateService } from '@ngx-translate/core';
import { LeftnavbarService } from './leftnavbar/leftnavbar.service';
import { NavElement } from '../../model/navelement.model';
import { Staff } from '../../model/staff.model';
import { OccurrenceService } from '../../service/occurrence.service';
import { LanguageService } from '../../service/language.service';
import { UserService } from '../../service/user.service';
import { StudentService } from '../../service/student.service';
import { PlanService } from '../../service/plan.service';
import { EventService } from '../../service/event.service';
import { TeacherService } from '../../service/teacher.service';
import { StaffService } from '../../service/staff.service';
import { SiteService } from '../../service/site.service';
import { TimezoneService } from '../../service/timezone.service';
import { RatingTeachersService } from '../../service/rating-teachers.service';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { AuthenticationService } from '../../service/authentification.service';
import { DateAdapter } from '@angular/material';
declare function gtag(a: string, b: any, c?: any): void;
@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss'],
  providers: [
    DictionnaryService,
    LeftnavbarService,
    OccurrenceService,
    LanguageService,
    UserService,
    StudentService,
    SiteService,
    TeacherService,
    TimezoneService,
    RatingTeachersService,
    StaffService,
    PlanService,
    AuthenticationService,
    EventService
  ]
})
export class BackofficeComponent implements OnInit, OnDestroy {
  navElements: NavElement[];   // liste des items de navigation
  showNavBar: boolean;         // boolean pour l'affichage du panneau lateral
  currentStaff: Staff;         // personna courant
  callSiteApi = null;          // variable pour le service Site
  errorMessage: any = '';
  showBackoffice = false;

  constructor(
    private router: Router,
    private dictionnaryService: DictionnaryService,
    private siteService: SiteService,
    private kiwixiService: KiwixiService,
    private kiwixiGlobals: KiwixiGlobals,
    private leftnavbarService: LeftnavbarService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    private initAppService: InitAppService,) {
    kiwixiGlobals.currentStaff.subscribe(value => this.currentStaff = value);
    kiwixiGlobals.isUserLoggedIn.subscribe(value => this.showNavBar = value);
    this.adapter.setLocale('fr');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const p = this.authenticationService.getCurrentPersona();
        const a = event.url
          .split('/')
          .map(part => part.split('?')[0])
          .map(part => part.split('#')[0])
          .filter(part => !part.match(/^\d*$/i))
          .join('/');
        if (p && p.user) {
          gtag('set', { 'user_id': p.user.id }); // Définir l'ID utilisateur à partir du paramètre user_id de l'utilisateur connecté.
        } else {
          // anonyme
          gtag('set', { 'user_id': 'non-logged' }); // Définir l'ID utilisateur à partir du paramètre user_id de l'utilisateur connecté.
        }
        gtag('event', 'screen_view', {
          'screen_name': a
          // 'full_url'    : event.urlAfterRedirects
        });
      }
    });
  }

  ngOnInit() {
    this.initAppService.initApp(null, null);
    if (this.authenticationService.getCurrentPersona()) {
      this.siteService.getObservableSites()
        .subscribe(_sites => {
          if (_sites) {
            this.callSiteApi = this.siteService.dataSites = _sites['results'];
          }
        },
          error => {
            this.errorMessage = <any>error;
          })
    } else {
      this.router.navigate(['login']);
    }
    this.authenticationService.checkCredentialObservable().subscribe(
      auth => {
        // continue
        // this.router.navigate(['/']);
      },
      error => {
        // this.router.navigate(['login']);
      }
    )
    this.leftnavbarService.getNavElements()
      .subscribe(res => {
        this.navElements = res;
      },
        err => {
          console.log(err);
        });

    this.setTranslation();

  }

  ngOnDestroy() {
    if (this.callSiteApi) {
      this.callSiteApi.unsubscribe();
    }
  }

  /**
   * Lancement de la traduction
   */
  setTranslation() {
    let _lang = 'fr';
    this.translate.setDefaultLang(_lang);
    localStorage.setItem('language', 'fr');
    if (localStorage.getItem('language') !== null && localStorage.getItem('language') !== undefined) {
      _lang = localStorage.getItem('language');
    }
    this.translate.use(_lang);
    this.dictionnaryService.getDictionnary2(_lang)
      .subscribe(res => {
        this.translate.setTranslation(_lang, res);
        this.showBackoffice = true;
      });
  }

}
