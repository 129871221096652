import { BehaviorSubject } from 'rxjs';
import { Formation } from './../../model/formation.model';
import { Student } from './../../model/student.model';
import { FormationService } from './../../service/formation.service';
import { ExerciceCategoryService } from './../../service/exerciceCategory.service';
import { ExerciceSpecificContentService } from './../../service/exerciceSpecificContent.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Teacher } from './../../model/teacher.model';
import { AuthenticationService } from './../../service/authentification.service';
import { TaskService } from './../../service/task.service';
import { HttpParams } from '@angular/common/http';
import { ExerciceSheetService } from './../../service/exerciceSheet.service';
import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ExerciceSheet } from '../../model/exerciceSheet.model';
import { Occurrence } from '../../model/occurrence.model';
import { Task } from '../../model/task.model';
import { ExerciceCategory } from '../../model/exerciceCategory.model';
import { ExerciceSpecificContent } from '../../model/exerciceSpecificContent.model';

@Component({
  selector: 'app-sheet-assign',
  templateUrl: './sheet-assign.component.html',
  styleUrls: ['./sheet-assign.component.scss']
})
export class SheetAssignComponent implements AfterViewInit {
  @Input() formation: Formation;
  @Input() occurrence: Occurrence;
  @Input() reportAddedSheet // report to parent component if sheet was added
  @Input() validatedByStudent: Student;
  @Output() emitSheetToView = new EventEmitter();
  @Output() isSheetAdded = new EventEmitter();
  currentUser: Teacher;
  sheetList: ExerciceSheet[] = [];
  taskSheetToDo: Task[] = [];
  categoryList: ExerciceCategory[] = [];
  cecrlLevelList;
  groupList: ExerciceSpecificContent[] = null;
  limitedGroupList: ExerciceSpecificContent[] = null;
  showFilterMenu = false;
  loadSheets = false;
  site;
  nextPage = 1;
  isDataSpecificContentReadyObservable;
  countTotalSheets = 0;

  filterSearchGroup = new FormGroup({
    title_fr: new FormControl(),
  });

  filterFormGroup = new FormGroup({
    cecrl_level: new FormControl(),
    category: new FormControl(),
    specific_content: new FormControl(),
    limited_specific_content: new FormControl(),
    status: new FormControl(),
  });

  constructor(
    private exerciceSheetService: ExerciceSheetService,
    private authenticationService: AuthenticationService,
    private exerciceSpecificContentService: ExerciceSpecificContentService,
    private exerciceCategoryService: ExerciceCategoryService,
    private taskService: TaskService,
    private formationService: FormationService,
  ) { }

  ngAfterViewInit() {
    this.site = localStorage.getItem('site');
    this.isDataSpecificContentReadyObservable = new BehaviorSubject(false);
    this.resetFilters();
    this.currentUser = this.authenticationService.currentUserSubject.value;
    this.cecrlLevelList = this.exerciceSheetService.getcecrlLevel();
    this.exerciceCategoryService.getCategories(null).subscribe(data => {
      if (data['results']) {
        this.categoryList = data['results'];
      }
    });
    this.exerciceSpecificContentService.getExercicesSpecificContent(null).subscribe(data => {
      if (data['results']) {
        this.groupList = data['results'];
        this.isDataSpecificContentReady();
      }
    })
    if (this.occurrence) {
      this.formationService.getObservableFormationByUrl(this.occurrence.formation).subscribe(formation => {
        if (formation) {
          this.limitedGroupList = formation.specific_content;
          this.isDataSpecificContentReady();
        }
      });
    }
    if (this.formation) {
      this.limitedGroupList = this.formation.specific_content;
      this.isDataSpecificContentReady();
    }
    this.filterFormGroup.valueChanges.subscribe(data => {
      this.newSearch();
    });
    this.filterSearchGroup.valueChanges.debounceTime(1000).subscribe(data => {
      this.newSearch();
    });
    if (this.occurrence) {
      this.getAssignedSheetList();
    }
    this.getAvailableSheet();
    this.isDataSpecificContentReadyObservable.subscribe(isReady => {
      if (isReady) {
        this.filterGroupList();
      }
    });

    if (!this.formation && !this.occurrence) {
      this.isDataSpecificContentReadyObservable.next(true);
    }
  }



  isDataSpecificContentReady() {
    if (this.limitedGroupList.length != null && this.groupList != null) {
      this.isDataSpecificContentReadyObservable.next(true);
    }
  }

  checkSpecificContent(sheet: ExerciceSheet) {
    if (sheet.specific_content.length === 0) {
      return true;
    }
    if (this.formation) {
      if (this.formation.specific_content.filter(sc => sheet.specific_content.map(sc => sc.id).indexOf(sc.id) > -1).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  filterGroupList() {
    this.groupList = this.groupList.filter(g => this.limitedGroupList.map(sc => sc.id).indexOf(g.id) > -1)
    this.groupList = this.groupList.filter(g => g.code !== 'default')
  }

  getAssignedSheetList() {
    const params = new HttpParams()
      .set('salt', Math.random().toString())
      .set('recipient_model_id', '25') // filter on student model
      .set('recipient_entity_id', this.occurrence.student.id.toString()) // filter for this student
      .set('what_model_id', '69') // filter on sheet model
      .set('for_model_id', '22') // filter on occurrence model
      .set('for_entity_id', this.occurrence.id.toString()) // filter on this occurrence
      .set('is_validate_by_student', this.occurrence.student.id.toString())
      .set('is_todo_by_student', this.occurrence.student.id.toString())

    this.taskService.getTasks(params).subscribe(data => {
      if (data.results) {
        this.taskSheetToDo = data.results;
      }
    })
  }

  getAvailableSheet() {
    let params = new HttpParams()
      .set('status', 'published') // filter on student entity
      .set('ordering', 'title_fr')
      .set('page_size', '20')
      .set('page', this.nextPage.toString())
      .set('salt', Math.random().toString());


    let language_filter = null;
    if (this.currentUser.user.teachers.length > 0) {
      language_filter = this.currentUser.teach_language.id;
    }
    if (this.formation) {
      language_filter = this.formation.language_detail.id;
    }
    if (language_filter) {
      params = params.set('language', language_filter.toString());
    }

    if (this.validatedByStudent) {
      params = params.set('is_validate_by_student', this.validatedByStudent.id.toString());
      params = params.set('is_todo_by_student', this.validatedByStudent.id.toString());
    }
    if (this.filterSearchGroup.controls.title_fr.value) {
      params = params.set('title_fr__icontains', this.filterSearchGroup.controls.title_fr.value);
    }
    if (this.filterFormGroup.controls.cecrl_level.value) {
      params = params.set('cecrl_level', this.filterFormGroup.controls.cecrl_level.value);
    }
    if (this.filterFormGroup.controls.category.value) {
      params = params.set('category', this.filterFormGroup.controls.category.value);
    }
    if (this.filterFormGroup.controls.status.value) {
      if (this.filterFormGroup.controls.status.value === 'todo') {
        params = params.set('filter_todo', this.validatedByStudent.id.toString());
      }
      if (this.filterFormGroup.controls.status.value === 'completed') {
        params = params.set('filter_completed', this.validatedByStudent.id.toString());
      }
      if (this.filterFormGroup.controls.status.value === 'uncompleted') {
        params = params.set('filter_uncompleted', this.validatedByStudent.id.toString());
      }
    }
    if (this.filterFormGroup.controls.specific_content.value) {
      params = params.set('specific_content', this.filterFormGroup.controls.specific_content.value);
    }
    if (this.filterFormGroup.controls.limited_specific_content.value) {
      params = params.set('specific_content', this.filterFormGroup.controls.limited_specific_content.value);
    }
    const that = this;
    this.exerciceSheetService.getObservableExerciceSheets(params).subscribe(data => {
      if (data) {
        that.sheetList = that.sheetList.concat(data.results);
        that.countTotalSheets = data.count;
        that.excludeAssignedSheet();
        if (data.next) {
          that.nextPage = that.exerciceSheetService.getUrlParams(data.next, 'page');
        } else {
          that.nextPage = null;
        }
      }
      this.loadSheets = false;
    })
  }

  resetFilters() {
    this.filterFormGroup.controls.cecrl_level.setValue('');
    this.filterFormGroup.controls.category.setValue('');
    this.filterFormGroup.controls.status.setValue('');
    this.filterFormGroup.controls.specific_content.setValue('');
    this.filterFormGroup.controls.limited_specific_content.setValue('');

  }

  newSearch() {
    this.loadSheets = true;
    this.nextPage = 1;
    this.sheetList = [];
    this.getAvailableSheet();
  }

  excludeAssignedSheet() {
    this.sheetList = this.sheetList.filter(sheet => this.taskSheetToDo.map(task => task.what.id).indexOf(sheet.id) === -1)
  }

  emitViewEmitter(sheet: ExerciceSheet, mode) {
    this.emitSheetToView.emit({ sheet: sheet, mode: mode });
  }

  assignSheetToClass(sheet) {

    const _params = {
      title: 'Fiche à traiter',
      task_sender: 'teacher',
      sender_entity_id: this.currentUser.id,
      task_recipient: 'student',
      recipient_entity_id: this.occurrence.student.id,
      what_entity: 'exercicesheet',
      what_entity_id: sheet.id,
      for_entity: 'occurrence',
      for_entity_id: this.occurrence.id
    };
    this.taskService.postTask(_params).subscribe(result => {
      this.taskSheetToDo.push(result);
      this.excludeAssignedSheet();
      this.isSheetAdded.emit(true);
    })
  }

  deleteTask(task) {
    this.taskService.deleteTask(task.id).subscribe(result => {
      this.taskSheetToDo = this.taskSheetToDo.filter(_task => _task.id !== task.id)
      this.sheetList.push(task.what)
    })
  }

  clickRowSheet(sheet: ExerciceSheet) {
    if (this.site === 'lingueopro') {
      this.emitViewEmitter(sheet, 'mobile');
    }
  }

}
