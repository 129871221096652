import { Component, Input, OnInit } from '@angular/core';
import { Achievement } from '../../model/achievement.model';
import { HelpNotif } from '../../model/helpNotif.model';
import { Student } from '../../model/student.model';
import { Teacher } from '../../model/teacher.model';
import { AchievementService } from '../../service/achievement.service';
import { AchievementDoneservice } from '../../service/achievementDone.service';
import { AchievementManagerService } from '../../service/achievementManager.service';
import { HelpNotifService } from '../../service/helpNotif.service';

@Component({
  selector: 'app-achievement-modal',
  templateUrl: './achievement-modal.component.html',
  styleUrls: ['./achievement-modal.component.scss', '../modal/modal.component.scss']
})
export class AchievementModalComponent implements OnInit {
  @Input() teacher: Teacher;
  @Input() student: Student;
  @Input() slug: string;
  achievement: Achievement = null;
  persona: any
  help: HelpNotif = null;
  canFire = false;
  title = null;
  content = null;
  illustration = null;

  constructor(
    private achievementDoneservice: AchievementDoneservice,
    private achievementService: AchievementService,
    private achievementManagerService: AchievementManagerService,
    private helpNotifservice: HelpNotifService,
  ) { }

  ngOnInit() {
    if (this.student) {
      this.getAchievement(this.student.achievements);
      this.persona = this.student;
    } else {
      this.getAchievement(this.teacher.achievements);
      this.persona = this.teacher;
    }

    this.achievementManagerService.achievementToFire.subscribe(val => {
      if (this.achievement && val) {
        if (this.achievementManagerService.canFire(this.achievement)) {
          this.getHelpNotif();
          this.canFire = true;
          this.achievementManagerService.setAchievementAsFire(this.achievement);
        }
      }
    });
  }

  getCurrentLanguage() {
    return this.achievementService.getCurrentLanguage();
  }

  getHelpNotif() {
    if (this.achievement.help_notif.length > 0) {
      this.helpNotifservice.getHelpNotifByUrl(this.achievement.help_notif[0]).subscribe(help => {
        if (help) {
          this.help = help;
          this.title = this.help['title_' + this.getCurrentLanguage()];
          this.content = this.help['content_' + this.getCurrentLanguage()];
          this.illustration = this.help['illustration_file_' + this.getCurrentLanguage()];
        }
      })
    }
  }

  getAchievement(achievements: Achievement[]) {
    if (achievements) {
      if (achievements.length > 0) {
        this.achievement = achievements.filter(a => a.slug === this.slug ? a : null)[0];
      }
    }
  }

  setAchievementAsDone() {
    let _params = {
      achievement: this.achievement.url,
    }
    if (this.student) {
      _params['content_type_model'] = 'student';
      _params['object_id'] = this.student.id;
    } else {
      _params['content_type_model'] = 'teacher';
      _params['object_id'] = this.teacher.id;
    }
    this.achievementDoneservice.postAchievementDone(_params).subscribe(achievementDone => {
      this.achievement.achievements_done = achievementDone.created_at;
      this.achievementService.updatePersonaAchievement(this.persona, this.achievement);
    })
  }

  close() {
    this.help = null;
    this.setAchievementAsDone();
  }
}

