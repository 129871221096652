<div id="{{help.slug}}" *ngIf="canFire()" [ngStyle]="help.css" class="tooltip-calendar tooltip"
  [ngClass]="{
    'tooltip-bottom': position === 'bottom',
    'tooltip-top': position === 'top',
    'tooltip-left': position === 'left',
    'tooltip-right': position === 'right'
  }">
  <h6 translate>{{help.title}}</h6>
  <div>
    {{help.content}}
  </div>
  <button (click)="next()">
    {{ help.is_last ? 'Close' : 'Next' }}
  </button>
</div>
