import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { ExerciceQuestion } from '../model/exerciceQuestion.model';
import { ExerciceQuestionList } from '../model/exerciceQuestionList.model';

@Injectable()
export class ExerciceQuestionService extends KiwixiService {

    apiExerciceQuestion = environment.server + 'exercices_questions/';

    typologyList = [
        { 'code': 'qcm-unique-choice', 'enable': true, 'label': 'QCM choix unique' },
        { 'code': 'qcm-multiple-choices', 'enable': false, 'label': 'QCM choix multiple' },
        { 'code': 'text', 'status': false, 'label': 'Text' },
        { 'code': 'sentense-composition-choices', 'enable': false, 'label': 'Composition de phrases' },
        { 'code': 'fill-gaps-text', 'enable': false, 'label': 'Phrase à trou texte' },
        { 'code': 'fill-gaps-choices', 'enable': false, 'label': 'Phrase à trou choix' },
    ]

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }
    getTypology() {
        return this.typologyList;
    }
    getExerciceQuestionById(_id): Observable<ExerciceQuestion> {
        const url = this.apiExerciceQuestion + _id + '/';
        return this.getExerciceQuestionByUrl(url);
    }

    getExerciceQuestionByUrl(_url): Observable<ExerciceQuestion> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceQuestion>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getExerciceQuestion`))
            );
    }

    getExerciceQuestions(params: HttpParams): Observable<ExerciceQuestionList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceQuestionList>(this.apiExerciceQuestion, {
            headers: header,
            params: params
        })
            .map(_ExerciceQuestions => _ExerciceQuestions)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceQuestionList>(`getObservableExerciceQuestions`))
            );
    }

    postExerciceQuestion(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<ExerciceQuestion>(this.apiExerciceQuestion, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceQuestion>(`Création d'une fiche`)),
            );
    }

    patchExerciceQuestion(_id, params) {
        const url = this.apiExerciceQuestion + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<ExerciceQuestion>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceQuestion>(`Création d'une fiche`)),
            );
    }
}
