import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import 'rxjs/';

@Injectable()
export class OutlookLingueoService extends KiwixiService {

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  downloadIcs(_url, _filename) {
    const header = this.createHttpClientAuthorizationHeader();
    this._http.get(_url, { headers: header, responseType: 'arraybuffer' })
      .subscribe((file) => {
        const myFile = new Uint8Array(file);
        const blob = new Blob([myFile], { type: 'application/force-download' });
        const url = window.URL.createObjectURL(blob);
        const $a = $('<a />', {
          'href': url,
          'download': _filename,
          'text': 'click'
        }).hide().appendTo('body')[0].click();
      });
  }

}
