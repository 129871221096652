import { ExerciceMedia } from './../model/exerciceMedia.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';


@Injectable()
export class ExerciceMediaService extends KiwixiService {

    apiMedia = environment.server + 'exercices_medias/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    deleteMedia(id): Observable<ExerciceMedia> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.delete<ExerciceMedia>(this.apiMedia + id + '/', {
            headers: header,
        })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceMedia>(`delete Media`))
            );
    }

    postEmbededdMedia(params): Promise<ExerciceMedia> {
        const self = this;
        const _formData = new FormData();
        _formData.append('enctype', 'multipart/form-data');
        self.headers.delete('Content-Type');
        _formData.append('media_type', params.media_type);
        _formData.append('source_type', params.source_type);
        _formData.append('media_type_to', params.media_type_to);
        _formData.append('entity_id', params.entity_id);
        _formData.append('url', params.url);
        _formData.append('plateform_type', params.plateform_type);
        const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
        return self._http.post(self.apiMedia, _formData, { headers: headers })
            .toPromise()
            .then((res: any) => res)
            .catch(error => {
                this.toastService.show('Erreur dans la création d\'un média', 500, 'red');
                console.error('error post Media service', error);
            })
    }

    postStoredMedia(file, params): Promise<ExerciceMedia> {
        const self = this;
        const _formData = new FormData();
        _formData.append('enctype', 'multipart/form-data');
        self.headers.delete('Content-Type');
        _formData.append('source', file);
        _formData.append('name', file.name);
        _formData.append('media_type', params.media_type);
        _formData.append('source_type', params.source_type);
        _formData.append('media_type_to', params.media_type_to);
        _formData.append('entity_id', params.entity_id);

        const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
        return self._http.post(self.apiMedia, _formData, { headers: headers })
            .toPromise()
            .then((res: any) => res)
            .catch(error => {
                this.toastService.show('Erreur dans la création d\'un média', 500, 'red');
                console.error('error post Media service', error);
            })
    }
}
