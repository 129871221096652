import { RoutePermissionList } from './../model/routePermissionList.model';
import { RoutePermission } from './../model/routePermission.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';


@Injectable()
export class RoutePermissionService extends KiwixiService {

    apiRoutePermission = environment.server + 'routes_permissions/';
    public routeFeaturePermissions: BehaviorSubject<RoutePermission[]> = new BehaviorSubject<RoutePermission[]>(null);

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getRoutePermissionById(_id): Observable<RoutePermission> {
        const url = this.apiRoutePermission + _id + '/';
        return this.getRoutePermissionByUrl(url);
    }

    getRoutePermissionByUrl(_url): Observable<RoutePermission> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<RoutePermission>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getRoutePermission`))
            );
    }

    getRoutePermissions(params: HttpParams): Observable<RoutePermissionList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<RoutePermissionList>(this.apiRoutePermission, {
            headers: header,
            params: params
        })
            .map(_RoutePermissions => _RoutePermissions)
            .pipe(
                catchError(this.handleErrorObservable<RoutePermissionList>(`getObservableRoutePermissions`))
            );
    }
}
