import { FormControl } from '@angular/forms';
import { Formation } from './../../../../../../model/formation.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  @Output() handleUpdateAllocationMode = new EventEmitter<string>();
  @Input() formation: Formation
  modeControl: FormControl = new FormControl();
  constructor() { }

  ngOnInit() {
    this.modeControl.setValue('linear_date_around');
  }

  emitAllocationMode(mode) {
    this.handleUpdateAllocationMode.emit(mode.value);
  }
}
