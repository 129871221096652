import { TaskService } from './../../service/task.service';
import { ExerciceAnswerChoiceService } from './../../service/exerciceAnswerChoice.service';
import { ExerciceAnswerChoice } from './../../model/exerciceAnswerChoice.model';
import { ExerciceResponseService } from './../../service/exerciceResponse.service';
import { forkJoin } from 'rxjs';
import { ExerciceResponse } from './../../model/exerciceResponse.model';
import { ExerciceSession } from './../../model/exerciceSession.model';
import { ExerciceService } from './../../service/exercice.service';
import { Student } from './../../model/student.model';
import { AuthenticationService } from './../../service/authentification.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { HttpParams } from '@angular/common/http';
import { ExerciceQuestionService } from './../../service/exerciceQuestion.service';
import { ExerciceQuestion } from './../../model/exerciceQuestion.model';
import { Exercice } from './../../model/exercice.model';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExerciceSheet } from '../../model/exerciceSheet.model';
import { ExerciceQuestionList } from '../../model/exerciceQuestionList.model';


@Component({
  selector: 'app-sheet-exercices',
  templateUrl: './sheet-exercices.component.html',
  styleUrls: ['./sheet-exercices.component.scss']
})
export class SheetExercicesComponent implements OnInit {
  @Input() sheet: ExerciceSheet;
  @Input() exercices: Exercice[];
  @Input() isExamination: Boolean;
  @Input() previousUrl;
  @Input() exerciceSession: ExerciceSession;
  @Output() exerciceToShow = new EventEmitter();
  currentQuestion: ExerciceQuestion;
  questions: ExerciceQuestion[] = [];
  correctResponses: ExerciceResponse[] = [];
  currentExerciceVideo;
  duration = 0;
  difficulty;
  questionIndex = 0;
  lastQuestion = false;
  isCompleted = false;
  student: Student;
  otherExercices: Exercice[];
  easyExercice: Exercice = null;
  mediumExercice: Exercice = null;
  hardExercice: Exercice = null;
  examinationExercices: Exercice[] = [];
  examinationDuration = 0;
  showFinalResult = false;
  percentResult = 0;
  percentValidationStep = this.exerciceService.practicePercentStep;

  constructor(
    private router: Router,
    private exerciceQuestionService: ExerciceQuestionService,
    private exerciceResponseService: ExerciceResponseService,
    private exerciceAnswerChoiceService: ExerciceAnswerChoiceService,
    private taskService: TaskService,
    private authenticationService: AuthenticationService,
    private exerciceService: ExerciceService,
    private embedService: EmbedVideoService,
  ) { }

  ngOnInit() {
    if (this.isExamination) {
      this.percentValidationStep = this.exerciceService.validatePercentStep;
    }
    this.student = this.authenticationService.getCurrentPersona();
    this.getDuration();
    this.getDifficulty();
    this.getQuestionsExercices();
  }

  initVideo() {
    if (this.currentQuestion.exercice) {
      if (this.currentQuestion.exercice.media) {
        if (this.currentQuestion.exercice.media.media_type === 'video' && this.currentQuestion.exercice.media.source_type === 'url') {
          this.currentExerciceVideo = this.embedService.embed(this.currentQuestion.exercice.media.url);
        }
      }
    }
  }

  getQuestionsExercices() {
    const getQuestionsRequests = [];
    this.exercices.map(exercice => {
      getQuestionsRequests.push(this.getQuestionsByExercice(exercice));
    });
    forkJoin(getQuestionsRequests).subscribe(result => {
      result.map((res: ExerciceQuestionList) => res.results.map(question => {
        if (question.typology === 'qcm-unique-choice') {
          question.answers_choices = this.exerciceAnswerChoiceService.shuffle(question.answers_choices);
        }
        this.questions.push(question)
      }));
      this.setNextQuestion(this.questions[0]);
      this.initVideo();
    })
  }

  getQuestionsByExercice(exercice: Exercice) {
    let params = new HttpParams();
    params = params.append('exercice', exercice.id.toString());
    params = params.append('status', 'enable');
    params = params.append('salt', Math.random().toString());
    return this.exerciceQuestionService.getExerciceQuestions(params)
  }

  goBack() {
    this.router.navigate([this.previousUrl]);
  }

  getDuration() {
    this.duration = this.exercices.map(e => e.duration).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  getDifficulty() {
    if (this.isExamination) {
      this.difficulty = 'hard';
    } else {
      if (this.exercices.length === 1) {
        this.difficulty = this.exercices[0].difficulty;
      }
    }
  }

  getNextQuestion() {
    const index = this.questions.indexOf(this.currentQuestion);
    if (index >= 0 && index < this.questions.length - 1) {
      return this.questions[index + 1];
    } else {
      return false;
    }
  }

  setNextQuestion(nextQuestion) {
    if (nextQuestion) {
      this.currentQuestion = nextQuestion;
      this.questionIndex += 1;
    }
  }

  isLastQuestion(question) {
    const index = this.questions.indexOf(question);
    return index === this.questions.length - 1;
  }

  processCompleted(isCompleted: boolean) {
    this.isCompleted = isCompleted;
    if (isCompleted) {
      this.getGoodAnswers();
      this.getOtherExercices();
    }
  }

  getGoodAnswers() {
    /// ATTENTION NE PREND EN CHARGE QUE LES QCM UNIQUE CHOICE EVOLUTION NECESSAIRE POUR LES AUTRES QUESTIONS
    // IL FAUDRA RECUPER LES REPONSES DE CHAQUE QUESTION AU MOMENT DU SAVE ET LES STOCKER DANS L'OBJET QUESTIONS
    // PUIS COMPARER POUR CHAQUE QUESTIONS EN FONCTION DU TYPE DE QUESTION, LE NB DE BONNE REPONSES
    this.correctResponses = [];
    let params = new HttpParams();
    params = params.append('session', this.exerciceSession.id.toString());
    params = params.append('student', this.student.id.toString());
    params = params.append('is_correct', 'true');
    params = params.append('salt', Math.random().toString());
    return this.exerciceResponseService.getExerciceResponses(params).subscribe(res => {
      this.correctResponses = res.results;
      this.percentResult = (this.correctResponses.length / this.questions.length) * 100;
      if (this.percentResult >= this.percentValidationStep && this.isExamination) {
        this.setTasksToCompleted();
      } else {
        this.showFinalResult = true;
      }
    });
  }

  setTasksToCompleted() {
    const params = new HttpParams()
      .set('salt', Math.random().toString())
      .set('recipient_model_id', '25') // filter on student model
      .set('recipient_entity_id', this.student.id.toString()) // filter for this student
      .set('what_model_id', '69') // filter on sheet model
      .set('what_entity_id', this.sheet.id.toString())
    const _params_for_patch = {
      status: 'completed'
    };
    this.taskService.getTasks(params).subscribe(tasks => {
      if (tasks.results.length > 0) {
        const patchTasksRequest = [];
        tasks.results.map(task => {
          patchTasksRequest.push(this.taskService.patchTask(task.id, _params_for_patch))
        });

        forkJoin(patchTasksRequest).subscribe(result => this.showFinalResult = true);
      } else {
        this.showFinalResult = true;
      }
    })
  }
  getOtherExercices() {
    let params = new HttpParams()
    params = params.append('status', 'published');
    params = params.append('sheets', this.sheet.id.toString());
    params = params.append('salt', Math.random().toString());
    if (this.student) {
      params = params.append('is_validate_by_student', this.student.id.toString());
    }
    this.exerciceService.getExercices(params).subscribe(exercices => {
      this.otherExercices = exercices.results;
      this.otherExercices = this.otherExercices.filter(_e => this.exercices.map(e => e.id).indexOf(_e.id))
      this.getRandomExaminationExercices();
      this.initExercice();
    })
  }

  initExercice() {
    let list = this.otherExercices.filter(exercice => exercice.difficulty === 'easy' && exercice.is_validate_by_student === false)
    if (list.length > 0) {
      this.easyExercice = list[Math.floor(Math.random() * list.length)];
    }

    list = this.otherExercices.filter(exercice => exercice.difficulty === 'medium' && exercice.is_validate_by_student === false)
    if (list.length > 0) {
      this.mediumExercice = list[Math.floor(Math.random() * list.length)];
    }

    list = this.otherExercices.filter(exercice => exercice.difficulty === 'hard' && exercice.is_validate_by_student === false)
    if (list.length > 0) {
      this.hardExercice = list[Math.floor(Math.random() * list.length)];
    }
  }

  goToNextQuestion() {
    const nextQuestion = this.getNextQuestion();
    if (!nextQuestion) { // pas de nouvelle question => fin du parcours.
      this.lastQuestion = true;
      this.isCompleted = true;
      return;
    } else {
      this.setNextQuestion(nextQuestion);
      if (this.isLastQuestion(nextQuestion)) {
        this.lastQuestion = true;
      }
    }
  }

  goToBilan() {
    this.router.navigate(['/bilan/' + this.exerciceSession.id])
  }

  goToExercice(exercice) {
    this.emitExerciceToShow([exercice.id]);
  }

  emitExerciceToShow(exerciceList) {
    this.exerciceToShow.emit(exerciceList);
  }

  getExaminationDuration() {
    this.examinationDuration = this.examinationExercices.map(e => e.duration).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  getRandomExaminationExercices() {
    let list = this.otherExercices.filter(exercice => exercice.difficulty === 'hard')
    if (list.length >= 1 && list.length <= 3) {
      this.examinationExercices = list;
    }
    if (list.length > 3) {
      while (this.examinationExercices.length < 3) {
        let index = Math.floor(Math.random() * list.length);
        let exist = this.examinationExercices.filter(exercice => exercice.id === list[index].id)
        if (exist.length === 0) {
          this.examinationExercices.push(list[index])
        }
      }
    }
    this.getExaminationDuration();
  }
}