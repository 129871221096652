/**
 * Composant les sous-composants de la page d'édition de l'enseignement.
 */

import { Component, OnInit, Input } from '@angular/core';
import { NewTeacher } from '../../../../../../model/new-teacher.model';
import { Language } from '../../../../../../model/language.model';
import { Site } from '../../../../../../model/site.model';

@Component({
  selector: 'app-teaching-space',
  templateUrl: './teaching-space.component.html',
  styleUrls: ['./teaching-space.component.scss', '../edit-teacher.ux.scss', '../../../mainscreen.ux.scss']
})
export class TeachingSpaceComponent implements OnInit {
  @Input() myTeacher: NewTeacher;
  @Input() languages: Language[];
  @Input() sites: Site[];
  @Input() sessionSites: Site[];
  @Input() parentComponent
  refresh: false;
  constructor() { }

  ngOnInit() {

  }


}
