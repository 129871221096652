import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() searchInput
  @Output() setFiltering = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  setFilterList() {
    this.setFiltering.emit(this.searchInput);
  }

}
