import { Achievement } from './achievement.model';
import { User } from './user.model';
import { Site } from './site.model';

export class Student {
  // avatar: string;
  id: number;
  site: string;
  url: string;
  formations: any[];
  test: string;
  user: User;
  sites: Site[];
  is_active: boolean;
  achievements: Achievement[];
  constructor() { }

  get getUser() {
    return this.user
  }
}
