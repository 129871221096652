<div class="decorative-border">
  <!-- Explaination -->
  <p class="explanation-text" translate>{{translatePath}}.unavailabilities-explaination</p>

  <!-- Add unavailability -->
  <div class="addunavail-wrapper" >
    <p translate>{{translatePath}}.add-unavailabilities-label</p>
    <div class="valign-wrapper" >
      <!-- From Calendar -->
      <span class="mobile-nodisplay" translate>{{translatePath}}.item-unavailability-from-label</span>
      <ng-container *ngIf="!showCalendarFrom; else elseCalendarFrom"> 
        <div class="unavail-date-wrap center" (click)="displayCalendar(1)">
          <b class="unavail-date">{{startUnavailability | amLocale: 'en' | amDateFormat:'DD MMMM YYYY'}}</b>
        </div>
      </ng-container>
      <ng-template #elseCalendarFrom>        
        <div class="unavail-date-wrap center calendar-container">
          <div class="desktop-mini-calendar calendar-left" appClickOutside (clickOutside)="handleOutside()">
            <app-daily-calendar 
              [dateSelected]="dateSelected"
              [from]="fromSelected"
              (updateDateSelect)= "handleUpdateDateSelect($event)">
            </app-daily-calendar>
          </div>
        </div>
      </ng-template>
      <!-- To Calendar -->
      <div class="center" translate>{{translatePath}}.item-unavailability-to-label</div>
      <ng-container *ngIf="!showCalendarTo; else elseCalendarTo">  
        <div class="unavail-date-wrap center" (click)="displayCalendar(2)">
          <b class="unavail-date">{{endUnavailability | amLocale: 'en' | amDateFormat:'DD MMMM YYYY'}}</b>
        </div>
      </ng-container>
      <ng-template #elseCalendarTo>
        <div class="unavail-date-wrap center calendar-container">
          <div class="desktop-mini-calendar calendar-right" appClickOutside (clickOutside)="handleOutside()">
            <app-daily-calendar 
              [dateSelected]="dateSelected"
              [from]="fromSelected"
              (updateDateSelect)= "handleUpdateDateSelect($event)">
            </app-daily-calendar>
          </div>
        </div> 
      </ng-template>
      

      <!-- Valid date -->
      <button class="valid-btn center" mz-button (click)="addAnUnavailibilty()" [disabled]="!startUnavailability || !endUnavailability">
        <b translate>{{translatePath}}.valid-unavailability-button</b>
      </button>
    </div>

  </div>
  <!-- unavailabilities list -->
  <ng-container *ngIf="!loadEvents; else elseLoader">
    <div class="unavailability-list">
      <p class="unavail-list-title" translate>teacher.schedule.planned-unavailabilities</p>
      <div class="unavail-row" *ngFor="let event of events">
        <div class="unavail-detail">
          <span translate>{{translatePath}}.item-unavailability-from-label</span>
          <span><b>{{event.start | amLocale: 'en' | amDateFormat:'MMM DD, YYYY'}}</b> </span>
          <span translate>{{translatePath}}.item-unavailability-to-label</span>
          <span><b>{{event.end | amLocale: 'en' | amDateFormat:'MMM DD, YYYY'}}</b> </span>
        </div>
        <i class="material-icons unavail-delete" (click)="removeAnUnavailibilty(event)">highlight_off</i>
      </div>
    </div>
  </ng-container>
  <ng-template #elseLoader>
    <div class="row center left pageload-message">
      <b translate>{{translatePath}}.unavailabilities-loading</b>
    </div>
  </ng-template>
</div>
