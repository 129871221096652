<div id="ponctual-booking" class="row information-container">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Disponibilités</h6>
    </div>
  </div>
  <div class="row information-subcontainer" >
    <div class="col l7 panel-left panel-size">
      <div class="list-title">
          <b *ngIf="loadDisponibilities">Chargement des disponibilité de {{myTeacher.user.first_name}} ...</b>
          <b *ngIf="!loadDisponibilities">Afficher les jours ou {{myTeacher.user.first_name}} est disponible...</b>
      </div>
      <div class="row valign-wrapper">
        <span>
          De
        </span>
        <mat-select class="time-picker" [(ngModel)]="startSelect" (ngModelChange)="selectStart()">
          <mat-option *ngFor="let start of startPicker" [value]="start" [disabled]="!start.enable">
            {{start.local_str}}
          </mat-option>
        </mat-select>
        <div class="">
          à
        </div>
        <mat-select class="time-picker" [(ngModel)]="endSelect" [disabled]="!startSelect">
          <mat-option *ngFor="let end of endPicker" [value]="end" [disabled]="!end.enable">
            {{end.local_str}}
          </mat-option>
        </mat-select>
        <div class="">
          un
        </div>
        <mat-select class="weekday-picker" [(ngModel)]="daySelect" [disabled]="!endSelect">
          <mat-option *ngFor="let day of dayPicker" [value]="day">
              {{day | amDateFormat:'dddd'}}
          </mat-option>
        </mat-select>
      </div>
      <div class="row calendar-container">
        <app-calendar
          (updateDateSelect)= "handleUpdateDateSelect($event)"
          (updateAvailables)= "handleUpdateAvailables($event)"
          [myStart]="startSelect"
          [myEnd]="endSelect"
          [filterDay]="daySelect"
          [availablesDays]="availablesDays"
          [loadDisponibilities]="loadDisponibilities"
          [myTeacher]="myTeacher">
        </app-calendar>
      </div>
    </div>
    <div class="col l5 panel-right" *ngIf="dateSelect">
      <div class="schedule-title" >
        Disponibilités de {{myTeacher.user.first_name}}<br/>
        le {{dateSelect | amDateFormat:'ddd DD MMM YYYY'}}
      </div>
      <div class="row center" *ngIf="loadDisponibilities">
          <mat-progress-spinner style="left: 79% !important;"
            [color]="'warn'"
            [mode]="'indeterminate'">
          </mat-progress-spinner>
      </div>
      <div class="dailyschedule-container" *ngIf="!loadDisponibilities && dateSelect">
        <div class="row" >
          <app-select-time-row 
            [myTeacher]="myTeacher"
            [dateSelect]="dateSelect">
          </app-select-time-row>
        </div>
      </div>
    </div>
  </div>
</div>
