<div *ngIf='student && formationList.length>0'>
    <nav>
        <div class="nav-wrapper header-student">
            <div class="valign-wrapper mainnav-wrapper">
                <div class="student-header-logo" (click)="gotoCurrentFormation()"></div>
                <div class="header-training multi-training" *ngIf="current_formation"  (click)="displayFormations()">
                    <div class="training-name mobile-nodisplay">Ma Formation en <span>{{current_formation.language_detail.label}}</span></div>
                    <div class="current-training-flag">
                        <app-lang-flag [language]="current_formation.language_detail"></app-lang-flag>
                    </div>
                    <div class="training-downarrow"></div>
                </div>
                <div class="header-greeting mobile-nodisplay"><span class="greeting">Hello</span>, {{student.user.first_name}} !</div>
                <div class="header-buttons">
                    <div class="item student-avatar" (click)="showStudent = !showStudent; showFormations = false; showHideLanguageMenu= false;" *ngIf="student" title="{{ 'lingueopro.profil.mon-profil' | translate}}">
                        <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="student.user && student.user.photo_profile === null" (error)="this.formationService.errorImgHandler($event)">
                        <img class="user-avatar" src="{{student.user.photo_profile.thumbnail}}" *ngIf="student.user && student.user.photo_profile !== null">
                    </div>
                    <div class="item language-selector" (click)="showHideLanguageMenu=!showHideLanguageMenu; showFormations = false; showStudent = false;" *ngIf="currentLanguage" title="{{ 'lingueopro.header.interface-ui' | translate}}">
                        <span>{{ currentLanguage}}</span>
                    </div>
                    <div class="item help-button" routerLinkActive=" "><a href="https://lingueocustomers.crisp.help/fr/" target="_blank"></a></div>
                </div>
            </div>
        </div>
    </nav>

    <!-- NAVIGATION -->
    <div class="row nav-block mobile-nav nomobile-nodisplay" *ngIf="!router.url.includes('plan-formation') && !router.url.includes('sheets/sheet')">
        <div class="nav-header">
            <div class="nav-header-pagetitle">
                <span translate>{{pageTitle}}</span>
            </div>
            <div class="nav-iconbar" *ngIf="current_formation">
                <div class="nav-element nav-dashboard" routerLinkActive=" focused" routerLink="./formation/{{current_formation.id}}/dashboard" [routerLinkActiveOptions]="{exact:
                    true}">
                    <span class="nav-icon"></span>
                </div>
                <div class="nav-element nav-upcoming" routerLinkActive=" focused" routerLink="./formation/{{current_formation.id}}/upcomings" [routerLinkActiveOptions]="{exact:
                    true}">
                    <span class="nav-icon"></span>
                </div>
                <div class="nav-element nav-past nav-element-disabled" *ngIf='pastOccurrences.length==0'>
                    <span class="nav-icon"></span>
                </div>
                <div class="nav-element nav-past" routerLinkActive=" focused" *ngIf='pastOccurrences.length>0' routerLink="./formation/{{current_formation.id}}/past">
                    <span class="nav-icon"></span>
                </div>
                <div class="nav-element nav-progress" routerLinkActive=" focused" routerLink="./formation/{{current_formation.id}}/progression">
                    <span class="nav-icon"></span>
                </div>
                <div class="nav-element nav-messages" routerLinkActive=" focused" routerLink="./formation/{{current_formation.id}}/messages">
                    <span class="nav-icon"></span>
                </div>
                <div class="nav-element nav-drills" *ngIf="checkFeaturePermission('sheets')" routerLinkActive=" focused" routerLink="./formation/{{current_formation.id}}/sheets">
                    <span class="nav-icon"></span>
                </div>
                <!--<div class="nav-element nav-help" routerLinkActive=" ">
                    <span class="nav-icon"><a href="https://lingueocustomers.crisp.help/fr/" target="_blank"></a></span>
                </div>-->
            </div>
        </div>
    </div>
    <div>
        <div class="nav-block dt-nav-block mobile-nodisplay" *ngIf="!router.url.includes('plan-formation')">
            <div class="nav-iconbar" *ngIf="current_formation">
                <div class="{{ stateMenu }} nav-dashboard" routerLinkActive="  focused" routerLink="./formation/{{current_formation.id}}/dashboard" [routerLinkActiveOptions]="{exact:
                    true}">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname">
                        <span *ngIf="current_formation.mode_formation=='standard'">Tableau de bord</span>
                        <span *ngIf="current_formation.mode_formation=='lilate'">Tableau de bord</span>
                    </span>
                </div>
                <div class="{{ stateMenu }} nav-upcoming" routerLinkActive="  focused" routerLink="./formation/{{current_formation.id}}/upcomings" [routerLinkActiveOptions]="{exact:
                    true}">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname">
                        <span *ngIf="current_formation.mode_formation=='standard'" translate>{{translatePath2}}.nav.next-lessons</span>
                        <span *ngIf="current_formation.mode_formation=='lilate'" translate>{{translatePath2}}.nav.next-lessons-lilate</span>
                    </span>
                </div>
                <div class="{{ stateMenu }} nav-past" routerLinkActive="focused" *ngIf='pastOccurrences.length>0' routerLink="./formation/{{current_formation.id}}/past">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname">
                        <span *ngIf="current_formation.mode_formation=='standard'" translate>{{translatePath2}}.nav.past-lessons</span>
                        <span *ngIf="current_formation.mode_formation=='lilate'" translate>{{translatePath2}}.nav.past-lessons-lilate</span>
                    </span>
                </div>
                <div class="nav-element nav-element-disabled nav-past" *ngIf='pastOccurrences.length==0'>
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname">
                        <span *ngIf="current_formation.mode_formation=='standard'" translate>{{translatePath2}}.nav.past-lessons</span>
                        <span *ngIf="current_formation.mode_formation=='lilate'" translate>{{translatePath2}}.nav.past-lessons-lilate</span>
                    </span>
                </div>

                <div class="{{ stateMenu }} nav-progress" routerLinkActive="focused" routerLink="./formation/{{current_formation.id}}/progression">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname" >
                        <span *ngIf="current_formation.mode_formation=='standard'" translate>{{translatePath2}}.nav.progression</span>
                        <span *ngIf="current_formation.mode_formation=='lilate'" translate>{{translatePath2}}.nav.progression-lilate</span>
                    </span>
                </div>
                <div class="{{ stateMenu }} nav-messages" routerLinkActive="  focused" routerLink="./formation/{{current_formation.id}}/messages">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname" translate>{{translatePath2}}.nav.contact-teacher</span>
                    <div class="unread-counter"*ngIf="nbMessagesUnread > 0">{{nbMessagesUnread}}</div>
                </div>
                <div class="{{ stateMenu }} nav-drills" *ngIf="checkFeaturePermission('sheets') && checkAvailableLanguageSheets()" routerLinkActive="  focused" routerLink="./formation/{{current_formation.id}}/sheets">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname" translate>{{translatePath2}}.nav.sheets</span>
                </div>
                <!--<div class="{{ stateMenu }} nav-help" routerLinkActive="  focused">
                    <span class="nav-icon"></span>
                    <span class="desktopnav-navname" translate><a href="https://lingueocustomers.crisp.help/fr/" target="_blank">Guide d'Utilisation</a></span>
                </div>-->
            </div>
        </div>
        
        <div class="menu" [hidden]="!showFormations">
            <div class="row">
                <ng-container *ngIf="formationsAreReady; else elseLoadFormations">
                    <!-- Formations en attente -->
                    <app-formation-list-menu [formations]="formations_wait" [status]="'pending'" [translatePath]="translatePath" 
                    (updateCurrentFormation)="handleCurrentFormation($event)">
                    </app-formation-list-menu>
                    <!-- Formations suspendues -->
                    <app-formation-list-menu [formations]="formations_suspended" [status]="'suspended'" [translatePath]="translatePath" 
                    (updateCurrentFormation)="handleCurrentFormation($event)">
                    </app-formation-list-menu>
                    <!-- Formations en cours -->
                    <app-formation-list-menu [formations]="formations_active" [status]="'active'" [translatePath]="translatePath" 
                    (updateCurrentFormation)="handleCurrentFormation($event)">
                    </app-formation-list-menu>
                    <!-- Formations terminées -->
                    <app-formation-list-menu [formations]="formations_done" [status]="'done'" [translatePath]="translatePath" 
                    (updateCurrentFormation)="handleCurrentFormation($event)">
                    </app-formation-list-menu>
                </ng-container>
                <ng-template #elseLoadFormations>
                    <div class="load-msg"> Chargement des formations...</div>
                    <!--Add translation key -->
                </ng-template>
            </div>
        </div>

        <div *ngIf="showStudent">
            <app-profil-student [student]="student" (updateProfileStatus)="handleProfileStatus($event)" [current_formation]="current_formation"></app-profil-student>
        </div>
        <div *ngIf="showFormations || showStudent || showHideLanguageMenu" class="opacity-cover" (click)="showStudent = !showStudent && showFormations = !showFormations && showHideLanguageMenu = !showHideLanguageMenu"></div>
        <div *ngIf="showHideLanguageMenu" class="languagemenu menu">
            <h6 translate>{{translatePath}}.interface-ui</h6>
            <div>
                <div *ngFor="let langue of siteEnv.languages">
                    <div (click)="setTranslate(langue)" class="lang-option-btn"[ngClass]="{'current-language': langue==currentLanguage}">
                        <span  translate>{{translatePath}}.{{langue}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>