import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Exercice } from '../../model/exercice.model';

@Component({
  selector: 'app-sheet-practice',
  templateUrl: './sheet-practice.component.html',
  styleUrls: ['./sheet-practice.component.scss']
})
export class SheetPracticeComponent implements OnInit {
  @Input() easyExercice: Exercice;
  @Input() mediumExercice: Exercice;
  @Input() hardExercice: Exercice;

  constructor(
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
  }

  goToExercice(exercice: Exercice): void {
    this.router.navigate(['./exercices/' + [exercice.id]], { relativeTo: this.route });
  }
}
