<div class="trainnext-wrapper">
  <!-- Events -->
  <ng-container *ngIf="formation; else elseTemplate">
    <div class="usualtimes-wrapper">
      <div class="usualtimes-title" translate>teacher.trainings.usual</div>
      <!-- Events -->
      <app-upcoming-events [events]="formation.events"></app-upcoming-events>
    </div>
   
    <div class="schedule-header">
      <span translate>teacher.trainings.training-schedule</span>:
    </div>
  
    <!-- upcomings -->
    <ng-container *ngIf="loadAllUpcomings">
      <div class="row center pageload-message">
        <b translate>teacher.trainings.upcomings-loading</b>
      </div>
    </ng-container>
    <div class="list-upcoming" [hidden]="loadAllUpcomings">
      <div *ngFor="let upcoming of upcomingBooked; let i = index">
        <app-upcoming [upcoming]="upcoming" [myIndex]="i" [isTeacherInterface]=true (displayUpcoming)="loadUpcomings($event)"></app-upcoming>
      </div>
      <div class="center training-enddate">
        <i translate>teacher.trainings.training-end</i><i> {{formation.validity_period_to | amLocale: 'en' | amDateFormat:'MMM DD, YYYY'}}</i>
      </div>
    </div>
  </ng-container>

  <ng-template #elseTemplate>
    <div class="row center pageload-message">
      <b translate>teacher.trainings.next-loading</b>
    </div>
  </ng-template>
</div>