import { GroupFormations } from './../model/group_formations.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';
import { GroupFormationsList } from '../model/groupFormationsList';

@Injectable()
export class GroupFormationsService extends KiwixiService {
    apiCorporate = environment.server + 'group_formations/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getGroups(params: HttpParams): Observable<GroupFormationsList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<GroupFormationsList>(this.apiCorporate, {
            headers: header,
            params: params,
        }).
            pipe(
                catchError(this.handleErrorObservable<GroupFormationsList>(`getGroupFormations`))
            );
    }

    getGroupWithUrl(_url): Observable<GroupFormations[]> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<GroupFormations[]>(_url, {
            headers: header
        }).
            pipe(
                catchError(this.handleErrorObservable<GroupFormations[]>(`getGroupFormationWithUrl`))
            );
    }
}

