<div id="create-plan" *ngIf="showScreen" class="setup-training-wrap container usual-container">
  
    
      <div class="steps-wrapper">
        <div class="onboarding-step step-birthdate complete-step"><div class="step-icon"></div></div>
        <div *ngIf="student && student.user.video_provider=='skype'" class="onboarding-step step1 current-step"><div class="step-icon"></div></div>
        <div class="onboarding-step step2"><div class="step-icon"></div></div>
        <div class="onboarding-step step3"><div class="step-icon"></div></div>
        <div class="onboarding-step step4"><div class="step-icon"></div></div>
      </div>
     
      <div class="text-intro-plan-formation">
        <div class="onboarding-subheading" translate>lingueopro.plan-formation.information.skype-title</div>
        <div translate *ngIf="formation.mode_formation=='standard'">lingueopro.plan-formation.information.title</div>
        <div translate *ngIf="formation.mode_formation=='lilate'">lingueopro.plan-formation.information.title-lilate</div>
        <div *ngIf="!student.user.id_skype" translate>lingueopro.plan-formation.information.no-skype-loaded</div>
        <div *ngIf="student.user.id_skype"><br />
          <span translate *ngIf="formation.mode_formation=='standard'">lingueopro.plan-formation.information.skype-loaded</span>
          <span translate *ngIf="formation.mode_formation=='lilate'">lingueopro.plan-formation.information.skype-loaded-lilate</span>
        </div>
      </div>
      <div class="skype-input-wrapper">
        <form >
            <input class="skypeid-input" matInput [formControl]="skypeFormControl">
            <mat-error *ngIf="skypeFormControl.touched && (skypeFormControl.hasError('required') || skypeFormControl.hasError('whitespace'))">
              <strong translate>lingueopro.plan-formation.information.skype_error</strong>
            </mat-error>
        </form>

     
        <button class="waves-effect waves-light nextstep-btn" (click)="next()" [disabled]="!skypeFormControl.valid">
          <span translate>common.Continuer</span>
        </button>
      </div>

      <div class="setupskype-help">
        <b translate>lingueopro.plan-formation.information.aide</b>
        <div class="text-intro-plan-formation" translate>lingueopro.plan-formation.information</div>
        <a href="https://lingueo.crisp.help/fr/article/comment-telecharger-et-creer-un-compte-skype-qk8vox/" target="_blank" translate>lingueopro.plan-formation.information.Comment installer Skype ?</a>
        <a href="https://lingueo.crisp.help/fr/article/comment-trouver-mon-identifiant-skype-xnr96z/" target="_blank" translate>lingueopro.plan-formation.information.Comment vérifier mon identifiant Skype ?</a>
      </div>
     
    
  
</div>