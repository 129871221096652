import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-datatable-cell-languages',
  templateUrl: './datatable-cell-languages.component.html',
  styleUrls: ['./datatable-cell-languages.component.scss']
})
export class DatatableCellLanguagesComponent implements OnInit {

  @Input() languages;
  my_languages: Object[];
  defaultAvatar = environment.static + '/avatar.png';
  errorMessage = '';
  loading = true;

  constructor() { }

  ngOnInit() {
    for (const language of this.languages) {
      this.my_languages.push(language);
    }
  }

}
