import { Occurrence } from './../../../../model/occurrence.model';
import { DictionnaryService } from './../../../../service/dictionnary.service';
import { TranslateService } from '@ngx-translate/core';
import { OccurrenceService } from './../../../../service/occurrence.service';
import { ConversationService } from './../../../../service/conversation.service';
import { HttpParams } from '@angular/common/http';
/**
 * Composant pour du menu latéral.
 * Intègre le composant pour l'affichage des formations & affichage du profil student.
 */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Formation } from '../../../../model/formation.model';
import { environment } from '../../../../../environments/environment';
import { FormationService } from '../../../../service/formation.service';
import { interval } from 'rxjs';
import { AuthenticationService } from '../../../../service/authentification.service';
import { Student } from '../../../../model/student.model';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {

  @Output() reloadFormation = new EventEmitter();
  student: Student;
  translatePath = 'lingueopro.header';
  translatePath2 = 'lingueopro';
  current_formation: Formation;
  formations_wait: any[] = [];
  formations_suspended: any[] = [];
  formations_active: any[] = [];
  formations_done: any[] = [];
  showFormations = false;
  showStudent = false;
  formationsAreReady = false;
  stateMenu = 'nav-element';
  countPages = 1;
  init = true;
  defaultAvatar = environment.static + '/avatar.png';
  pageTitle = 'lingueopro.nav.next-lessons';
  pollingMessage = null;
  formationList: Formation[] = [];
  nbMessagesUnread = 0;
  currentLanguage = null;
  showHideLanguageMenu = false;
  siteEnv;
  pastOccurrences: Occurrence[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formationService: FormationService,
    private conversationService: ConversationService,
    private authenticationService: AuthenticationService,
    private occurrenceService: OccurrenceService,
    private translateService: TranslateService,
    private dictionnaryService: DictionnaryService,
  ) { }

  ngOnInit() {
    const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);
    this.siteEnv = environment.urls[idx]
    this.currentLanguage = localStorage.getItem('language');
    this.formationsAreReady = false;
    this.formations_wait = [];
    this.formations_suspended = [];
    this.formations_active = [];
    this.formations_done = [];
    this.authenticationService.currentUserSubject.subscribe(student => {
      this.student = student;
    });
    this.formationService.listeFormations.subscribe(listeFormations => {
      if (listeFormations) {
        this.initFormations();
      }
    });
    this.formationService.currentFormationSubject.subscribe(formation => {
      if (formation) {
        this.current_formation = formation;
        this.getPastOccurrences();
      }
    });
    this.polling();
  }

  initFormations() {

    this.formationService.listeFormations.subscribe(listeFormations => {
      this.formationList = []
      this.formationsAreReady = false;
      this.formations_wait = [];
      this.formations_suspended = [];
      this.formations_active = [];
      this.formations_done = [];
      if (listeFormations) {
        listeFormations.forEach(_formation => {
          this.formationList.push(_formation);
          switch (_formation['status']) {
            case 'pending':
              this.formations_wait.push(_formation);
              break;
            case 'suspended':
              this.formations_suspended.push(_formation);
              this.stateMenu = 'nav-element-disabled'
              break;
            case 'active':
              this.formations_active.push(_formation);
              break;
            case 'done':
              this.formations_done.push(_formation);
              break;
            default:
          }
        });
        this.formationsAreReady = true;
      }
    });
    this.checkNewMessage();
  }

  getPastOccurrences() {
    let params = new HttpParams();
    params = params.append('formation', this.current_formation.id.toString());
    params = params.append('start__lte', moment().toISOString());
    params = params.append('ordering', '-start');
    params = params.append('page_size', '1');
    this.occurrenceService.getObservableOccurences(params).subscribe(res => {
      this.pastOccurrences = res['results']
    })
  }

  /**
   * Ecouteur sur le sous-composant des formations.
   * @param value
   */
  public handleCurrentFormation(value) {
    this.formationService.getObservableFormationById(value.id).subscribe(_formation => {
      this.current_formation = _formation;
      this.formationService.currentFormationSubject.next(_formation);
      this.getPastOccurrences();
      if (_formation.next_occurrence) {
        this.occurrenceService.getObservableOccurence(_formation.next_occurrence).subscribe(occ => {
          if (occ) {
            this.formationService.nextOccurrence.next(occ);
            this.goToFormation(_formation.id);
          }
        });
      } else {
        this.formationService.nextOccurrence.next(null);
        this.goToFormation(_formation.id);
      }
    });
  }

  goToFormation(formation_id) {
    this.checkNewMessage();
    // close menu
    this.showFormations = false;
    if (this.current_formation) {
      if (this.current_formation.status === 'pending') {
        this.router.navigate(['plan-formation', 'introduction', formation_id]);
      } else if (this.current_formation.status === 'supended') {
        this.router.navigate(['formation', formation_id, 'suspended']);
      } else {
        this.router.navigate(['/formation', formation_id, 'dashboard']);
      }
    } else {
      this.router.navigate(['/formation', formation_id]);
    }

  }

  public handleProfileStatus(value) {
    this.showStudent = value;
  }

  // navigation vers une formation.
  gotoCurrentFormation() {
    if (this.formationService.currentFormationSubject.value) {
      this.goToFormation(this.formationService.currentFormationSubject.value.id);
    }
  }

  polling() {
    this.pollingMessage = interval(10000)
      .subscribe(i => {
        this.checkNewMessage()
      });
  }

  checkNewMessage() {
    if (this.formationService.currentFormationSubject.value && this.authenticationService.currentUserSubject.value) {
      this.getNumberMessagesUnread();
    }
  }

  ngOnDestroy() {
    if (this.pollingMessage) {
      this.pollingMessage.unsubscribe()
    }
    this.showStudent = false;
  }

  getListOfTeachers() {
    return this.formationService.currentFormationSubject.value.teachers.map(function (teacher) {
      return teacher.id;
    });
  }

  getNumberMessagesUnread() {
    let params = new HttpParams()
    params = params.append('teacher', this.getListOfTeachers().join(','))
    params = params.append('student', this.authenticationService.currentUserSubject.value.id.toString())

    this.conversationService.getConversations(params)
      .subscribe(conversations => {
        if (conversations) {
          if (conversations['results']) {
            this.nbMessagesUnread = 0;
            this.conversationService.conversationList.next(conversations['results']);
            conversations['results'].forEach(_data => this.nbMessagesUnread += _data['unread_student']);
          }
        }
      }
      );
  }

  // Affichage de la page avec la formation.
  displayFormations() {
    if (!this.showFormations) {
      this.showFormations = true;
    } else {
      this.showFormations = false;
    }
    if (this.showStudent) {
      this.showStudent = false;
    }
    if (this.showHideLanguageMenu) {
      this.showHideLanguageMenu = false;
    }
  }

  checkAvailableLanguageSheets() {
    return this.authenticationService.checkAvailableLanguageSheets(this.current_formation.language_detail.id);
  }

  checkFeaturePermission(groupName) {
    return this.authenticationService.checkFeaturePermission(groupName);
  }

  setTranslate(langue) {
    this.translateService.setDefaultLang(langue);
    this.translateService.use(langue);
    this.dictionnaryService.getDictionnary2(langue)
      .subscribe(res => {
        if (res) {
          localStorage.setItem('language', langue);
          this.formationService.currentLanguage.next(langue);
          this.currentLanguage = langue;
          this.translateService.setTranslation(langue, res);
          this.showHideLanguageMenu = false;
        }
      });
  }
}
