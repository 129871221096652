import { ProfilePhotosService } from './../../../../service/profilePhotos.service';
import { InitAppService } from '../../../../service/initapp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormationService } from '../../../../service/formation.service';
import { Formation } from '../../../../model/formation.model';
import { Component, OnInit } from '@angular/core';
import { Student } from '../../../../model/student.model';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: [
    './identity.component.scss',
    '../schedule/schedule.component.scss',
    '../plan-formation.component.scss',
    '../schedule/schedule.ux.scss',
    '../plan-formation.ux.scss']
})
export class IdentityComponent implements OnInit {
  formation_id;
  formation: Formation
  student: Student = null;
  showScreen = false; s
  showWebcamModule = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initAppService: InitAppService,
    private profilePhotosService: ProfilePhotosService,
    private formationService: FormationService,
  ) { }

  ngOnInit() {
    this.formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, this.formation_id);

    this.formationService.currentFormationSubject.subscribe(formation => {
      console.log(formation);
      if (formation) {
        this.student = formation.student;
        this.formation = formation;
        this.showScreen = true;
      }
    });

  }

  uploadFile($event) {
    for (const file of $event.target.files) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      if (this.profilePhotosService.authorizedExtension.indexOf(ext.toLowerCase()) !== -1) {
        this.sendFile(file);
      } else {
        this.profilePhotosService.toastService.show('Fomat de fichier invalide, JPG ou PNG autorisé !', 4000, 'red');
      }
    }
  }

  sendFile(file) {
    this.profilePhotosService.postProfilePhoto(this.student.user, file, null).subscribe(photoProfile => {
      this.student.user.photo_profile = photoProfile;
      this.student.user.photo_profile_validation_requested = true;
      this.next();
    })
  }


  next() {
    if (this.formation.booking_mode === 'rec') {
      this.router.navigate(['plan-formation/schedule', this.formation_id]);
    } else if (this.formation.booking_mode === 'ponctual') {
      this.router.navigate(['plan-formation/init-ponctual-booking', this.formation_id]);
    } else {
      this.router.navigate(['plan-formation/booking-mode', this.formation_id]);
    }
  }

}
