/**
 * Composant pour la création d'un student.
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// model
import { Timezone } from '../../../../../model/timezone.model';
import { NewStudent } from '../../../../../model/new-student.model';
import { Site } from '../../../../../model/site.model';
import { Language } from '../../../../../model/language.model';
import { NewUser } from '../../../../../model/new-user.model';
// services
import { FormationService } from '../../../../../service/formation.service';
import { LanguageService } from '../../../../../service/language.service';
import { StudentService } from '../../../../../service/student.service';
import { SiteService } from '../../../../../service/site.service';
import { UserService } from '../../../../../service/user.service';
import { TimezoneService } from '../../../../../service/timezone.service';

/**
 * Variable locale pour le nouveau student.
 */
export class MyStudent {
  avatar: string;
  firstname: string;
  lastname: string;
  email: string;
  id_skype: string;
  gender: string;
  timezone: string;
  sites: Site[];
  main_language: Language;
  password: string;
  auto_pwd: boolean;
  constructor() { }
  setNewStudent(newStudent: NewStudent, site: string, user: string) {
    newStudent.site = site;
    newStudent.user = user;
  }
}
@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss', './create-student.ux.scss', '../../mainscreen.ux.scss'],
  providers: [TimezoneService, FormationService, LanguageService, SiteService, UserService, StudentService]
})
export class CreateStudentComponent implements OnInit {
  newStudent: MyStudent;                                  // new Student à créer.
  newUser: NewUser;                                       // new User à créer.
  defaultAvatar = environment.static + '/avatar.png';     // avatar standard.
  fileAvatar = null;                                      // avatar uploadé
  genders = [                                             // select gender
    { fr: 'Non spécifié', en: 'nc' },
    { fr: 'Femme', en: 'female' },
    { fr: 'Homme', en: 'male' }
  ];
  sites: Site[];                                          // data sites.
  showStudent = false;                                    // affichage de la page.
  now: any;                                               // Date du jour.
  // ---- zones :
  zones: Timezone[];                                      // data timezones.
  timezoneControl: FormControl = new FormControl();       // Form control sur le timezone.
  filteredTimezones: Observable<Timezone[]>;              // liste des zones filtrée.
  myTimezone: {};                                         // timezone sélectionné.
  // ---- languages :
  languages: Language[];                                  // data languages.
  languageControl: FormControl = new FormControl();       // Form control sur le language.
  filteredLanguages: Observable<Language[]>;              // liste des timezone filtrée.
  myLanguage: {};                                         // language sélecionné.
  // ---- services :
  router: Router;
  languageService: LanguageService;
  siteService: SiteService;
  userService: UserService;

  myForm: FormGroup = this.builder.group({
    language: this.languageControl,
    timezone: this.timezoneControl,
  });

  constructor(
    private studentService: StudentService,
    router: Router,
    public formationService: FormationService,
    public timezoneService: TimezoneService,
    public _languageService: LanguageService,
    public _siteService: SiteService,
    public _userService: UserService,
    private builder: FormBuilder


  ) {
    this.timezoneService = timezoneService;
    this.languageService = _languageService;
    this.siteService = _siteService;
    this.userService = _userService;
    this.router = router;
    this.newStudent = new MyStudent();
    this.sites = [];
  }

  ngOnInit() {

    // initialisation du nouveau Student & User.
    this.newStudent.avatar = environment.static + 'avatar.png';
    this.newStudent.sites = [];
    this.newUser = new NewUser();
    this.newUser.first_name = null;
    this.newUser.last_name = null;
    this.newUser.email = null;
    this.newUser.birthdate = null;
    this.newUser.video_provider = 'teams';
    this.newUser.id_skype = null;
    this.newUser.gender = null;
    this.newUser.timezone = null;
    this.newUser.main_language = null;
    this.newUser.password = null;
    this.newUser.sites = [];
    this.newUser.active = true;
    this.newUser.sf_id = null;
    this.newUser.auto_pwd = false;
    this.now = new Date(Date.now());
    this.zones = [];
    this.languages = [];
    this.getAllLanguages();
    this.getAllTimezones(1);
    this.getAllSite();

    // init timezones combo
    this.filteredTimezones = this.timezoneControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterTimezone(val) : this.zones);
    // init languages combo
    this.filteredLanguages = this.languageControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterLanguages(val) : this.languages);
    this.onChanges();

  }

  onChanges(): void {
    this.myForm.valueChanges.subscribe(val => {
      this.newUser.main_language = val.language.url;
    });
  }

  // filtre sur les timezones.
  filterTimezone(val: string): Timezone[] {
    return this.zones.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) > - 1);
  }
  // affichage du label du timezone.
  displayTimezoneFn(tz) {
    return tz ? tz.label : tz;
  }
  // mise à jour du timezone.
  updateUserTimezone() {
    this.newUser.timezone = this.myTimezone ? this.myTimezone['label'] : null;
  }

  // filtre sur les languages.
  filterLanguages(val: string): Language[] {
    return this.languages.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
  // affichage du label du language.
  displayLanguageFn(language) {
    return language ? language.label : language;
  }
  // mise à jour du language.
  updateUserLanguage() {
    this.newUser.main_language = this.myLanguage ? this.myLanguage['url'] : null;
  }

  /**
   * recupérer les sites.
   */
  getAllSite() {
    this.sites = [];
    this.siteService.getObservableSites()
      .subscribe(_sites => {
        if (_sites) {
          for (const site of _sites['results']) {
            if (site.available_formation) {
              this.sites.push(site);
            }
            // add lingueoPro by default
            if (site.url === environment.default_student_site) {
              this.newStudent.sites.push(site);
            }
          }
        }
      },
        error => {
          console.error(error);
        })
  }

  /**
   * recupérer les languages.
   */
  getAllLanguages() {
    this.languageService.getLanguages({})
      .then(_languageData => {
        for (const language of _languageData['results']) {
          if (language.code === 'language.it' || language.code === 'language.en' || language.code === 'language.fr') {
            this.languages.push(language)
          }
        }
        this.showStudent = true;
      });
  }

  /**
   * recupérer les timezones.
   */
  getAllTimezones(_countPagesSites) {
    this.timezoneService.getTimezones({})
      .then(_timezonesData => {
        for (const timezone of _timezonesData) {
          this.zones.push(timezone)
        }
      });
  }
  //

  /**
   * Méthode pour la création du nouveau compte
   */
  createNewAccount() {
    this.newUser.username = this.trimValue(this.newUser.first_name + this.newUser.last_name);
    this.newUser.sites = [];
    this.newStudent.sites.forEach(_site => {
      this.newUser.sites.push(_site.url);
    });
    const _self = this;
    this.userService.postNewUser(this.newUser)
      .then(_user => {
        if (this.fileAvatar) {
          this.userService.patchFile(_user.url, _user, this.fileAvatar)
        }
        for (const site of this.newStudent.sites) {
          const studentToPost = new NewStudent();
          this.newStudent.setNewStudent(studentToPost, site.url, _user['url'])
          this.studentService.postStudent(studentToPost);
        }
        setTimeout(function () {
          _self.router.navigate(['/students', _user.url.split('/')[6]]);
        }, 1500)
      })
      .catch(error => {
        console.error('error User service', error);
      });
  }

  trimValue(value) {
    return value.replace(/\s+$/, '');
  }

  /**
   * Méthode pour vérifier si les champs obligatoires sont remplies.
   */
  checkFields() {
    if (this.newUser) {
      if (this.newUser.first_name === null || this.newUser.first_name.length < 1) {
        return true
      }
      if (this.newUser.last_name === null || this.newUser.last_name.length < 1) {
        return true
      }
      if (this.newUser.email === null || this.newUser.email.length < 1) {
        return true
      }
      // if (this.newUser.id_skype === null || this.newUser.id_skype.length < 1) {
      //   return true
      // }
      if (this.newUser.gender === null) {
        return true
      }

      if (this.newUser.sf_id === null) {
        return true
      }
      if (this.newStudent.sites && this.newStudent.sites.length === 0) {
        return true
      }
      if (this.newUser.timezone === null) {
        return true
      }
      if (!this.newUser.auto_pwd) {
        if (!this.newUser.password || this.newUser.password.length < 1) {
          return true
        }
      }
    }
    return false
  }

}
