import { invoiceLogExport } from './../model/invoiceLogExport.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Invoice } from '../model/invoice.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import * as moment from 'moment';

@Injectable()
export class InvoiceLogExportService extends KiwixiService {

    apiInvoicesLogExport = environment.server + 'invoices_log_export/'

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }
    getInvoiceLogExport(params: HttpParams): Observable<invoiceLogExport[]> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<invoiceLogExport[]>(this.apiInvoicesLogExport, {
            headers: header,
            params: params
        })
            .map(reports => reports)
            .pipe(
                catchError(this.handleErrorObservable<invoiceLogExport[]>(`getInvoiceLogExport`))
            );
    }

    getInvoiceLogExportById(invoiceLogExport: invoiceLogExport): Observable<invoiceLogExport> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<invoiceLogExport>(this.apiInvoicesLogExport + invoiceLogExport.id + '/', {
            headers: header,
        })
            .pipe(
                catchError(this.handleErrorObservable<invoiceLogExport>(`getInvoiceLogExport`))
            );
    }


    patchInvoiceLogExport(_id, params) {
        const url = this.apiInvoicesLogExport + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<invoiceLogExport>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<invoiceLogExport>(`Création d'une réponse`)),
            );
    }

    createBundle(invoiceLogExport: invoiceLogExport) {
        const _url = environment.server + 'invoices_log_export/' + invoiceLogExport.id + '/create_bundle_file/?salt=' + Math.random().toString();
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<invoiceLogExport>(_url, { headers: header })
            .pipe(
                catchError(this.handleErrorObservable<invoiceLogExport>(`getCurrentInvoice`))
            );
    }

    downloadBundle(_url, _filename, _contentType) {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get(_url, { headers: header, responseType: 'arraybuffer' })
            .map((file) => {
                const myFile = new Uint8Array(file);
                const blob = new Blob([myFile], { type: _contentType });
                const url = window.URL.createObjectURL(blob);
                const $a = $('<a />', {
                    'href': url,
                    'download': _filename,
                    'text': 'click'
                }).hide().appendTo('body')[0].click();
            }).toPromise();
    }

}
