import { FormationService } from './../../../../service/formation.service';
import { InitAppService } from './../../../../service/initapp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { Student } from './../../../../model/student.model';
import { Formation } from './../../../../model/formation.model';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';
import { UserService } from '../../../../service/user.service';

@Component({
  selector: 'app-mystudentaccount',
  templateUrl: './mystudentaccount.component.html',
  styleUrls: ['./mystudentaccount.component.scss',
    '../../plan-formation/schedule/schedule.component.scss',
    '../../plan-formation/plan-formation.component.scss',
    '../../plan-formation/schedule/schedule.ux.scss',
    '../../plan-formation/plan-formation.ux.scss']
})
export class MystudentaccountComponent implements OnInit {
  formation_id;
  formation: Formation
  student: Student = null;
  showScreen = false;
  skypeFormControl = new FormControl('', [
    Validators.required, this.noWhitespaceValidator
  ]);
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initAppService: InitAppService,
    private userService: UserService,
    private formationService: FormationService,
  ) { }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit() {
    //this.formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    //this.initAppService.initApp(true, this.formation_id);
    this.authenticationService.currentUserSubject.subscribe(student => {
      this.student = student;
      this.skypeFormControl.setValue(this.student.user.id_skype)
      this.formationService.currentFormationSubject.subscribe(formation => {
        if (formation) {
          this.formation = formation;
          this.showScreen = true;
        }
      });
    });

    this.skypeFormControl.valueChanges.debounceTime(500).subscribe((value: string) => {
      value = value.trim();
      if (value) {
        const _paramsUser = {
          id_skype: value,
        }
        this.userService.patchObservableUser(this.student.user.url, _paramsUser).subscribe(res => {
          this.student.user = res;
          this.authenticationService.setLocalPersonaObj(this.student);
        })
      }
    })
  }

}
