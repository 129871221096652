/**
 * Composant pour la page de création d'une formation.
 * Intégrant 2 sous-composants pour l'édition et la plannification
 */

import { Component, OnInit } from '@angular/core';
// Model
import { Formation } from '../../../../model/formation.model';
// Service
import { StaffService } from '../../../../service/staff.service';
import { FormationService } from '../../../../service/formation.service';
import { StudentService } from '../../../../service/student.service';
import { TemplateService } from '../../../../service/template.service';

@Component({
  selector: 'app-create-formation',
  templateUrl: './create-formation.component.html',
  styleUrls: ['./create-formation.component.scss'],
  providers: [StaffService, FormationService, StudentService, TemplateService]
})
export class CreateFormationComponent implements OnInit {

  public formation: Formation;
  public showRecurrence: boolean;
  currentIdStudent: any;
  public createFormationPageIsReady: boolean
  showReccurrencesDebugg = true;

  constructor() { }

  ngOnInit() {
    this.formation = new Formation();
    this.showRecurrence = false;
  }

  // ecouteur pour la mise à jour de la formation depuis un sous-composant.
  handleUpdateFormation(value) {
    this.formation = value;
  }

  // ecouteur pour refresh le debugg de recurrence.
  refresh() {
    this.showReccurrencesDebugg = false;
    setTimeout(() => {
      this.showReccurrencesDebugg = true;
    }, 500);

  }

}
