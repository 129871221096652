import { InvoiceExportListComponent } from './mainscreen/invoices-page/invoice-export/invoice-export-list/invoice-export-list.component';
import { InvoiceExportCreateComponent } from './mainscreen/invoices-page/invoice-export/invoice-export-create/invoice-export-create.component';
import { ReportTeacherBillingExpectedComponent } from './mainscreen/reports/report-teacher-billing-expected/report-teacher-billing-expected.component';
import { ReportTeacherAvailabilitiesComponent } from './mainscreen/reports/report-teacher-availabilities/report-teacher-availabilities.component';
import { ReportsComponent } from './mainscreen/reports/reports.component';
import { ExercicesListComponent } from './mainscreen/exercices/exercices-list/exercices-list.component';
import { DebugMatriceComponent } from './mainscreen/formation/debug-matrice/debug-matrice.component';
import { InvoiceListComponent } from './mainscreen/invoices-page/invoice-list/invoice-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../../feature/login/login.component';
import { FormationComponent } from './mainscreen/formation/formation.component';
import { ListeFormationsComponent } from './mainscreen/liste-formations/liste-formations.component';
import { CreateFormationComponent } from './mainscreen/create-formation/create-formation.component';
import { StudentsListComponent } from './mainscreen/students/students-list/students-list.component';
import { CreateStudentComponent } from './mainscreen/students/create-student/create-student.component';
import { EditStudentComponent } from './mainscreen/students/edit-student/edit-student.component';
import { TeachersListComponent } from './mainscreen/teachers/teachers-list/teachers-list.component';
import { CreateTeacherComponent } from './mainscreen/teachers/create-teacher/create-teacher.component';
import { EditTeacherComponent } from './mainscreen/teachers/edit-teacher/edit-teacher.component';
import { AuthGuard } from '../../guard/auth.guard';
import { ResetPasswordComponent } from '../../feature/reset-password/reset-password.component';
import { MessagesComponent } from './mainscreen/messages/messages.component';
import { ConversationComponent } from './mainscreen/messages/conversation/conversation.component';
import { EditComponent } from './mainscreen/invoices-page/edit/edit.component';
import { SheetCreateComponent } from './mainscreen/exercices/sheet-create/sheet-create.component';
import { SheetExercicesCreateComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-create.component';
import { FormationSuiviComponent } from './mainscreen/formation-suivi/formation-suivi.component';
import { ExportCdcCertificationComponent } from './mainscreen/export-formation/export-formation.component';
import { ExportCdcCertificationListingComponent } from './mainscreen/export-formation/export-formation-listing/export-formation-listing.component';
import { MaintenanceGuard } from '../../guard/maintenance.guard';
import { MaintenanceComponent } from '../../feature/maintenance/maintenance.component';
import { UpcomingClassesListComponent } from './mainscreen/upcoming-classes-list/upcoming-classes-list.component';
import { ExportCdcCertificationDetailErrorComponent } from './mainscreen/export-formation/export-formation-detail-error/export-formation-detail-error.component';
/**
 * Module de gestion des routes pour la navigation.
 */

export const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { site: 'backoffice' }, canActivate: [MaintenanceGuard] },
  { path: 'reset-password/:uid/:token', component: ResetPasswordComponent, canActivate: [MaintenanceGuard] },
  { path: 'formations', component: ListeFormationsComponent, data: { title: 'liste des formations', id_nav: 2 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'formations/create', component: CreateFormationComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'formations/:id', component: FormationComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'formations-suivi', component: FormationSuiviComponent, data: { title: 'Suivi des Formations', id_nav: 7 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: '', component: StudentsListComponent, data: { title: 'liste des élèves', id_nav: 3 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'students/create', component: CreateStudentComponent, data: { title: 'Créer un élève', id_nav: 3 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'students/:id', component: EditStudentComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'teachers', component: TeachersListComponent, data: { title: 'liste des professeurs', id_nav: 4 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'teachers/create', component: CreateTeacherComponent, data: { title: 'Créer un professeur', id_nav: 4 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'teachers/admin/:id', component: EditTeacherComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'messages', component: MessagesComponent, data: { title: 'liste des messages', id_nav: 5 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'messages/conversation/:id', component: ConversationComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'invoices', component: InvoiceListComponent, data: { title: 'liste des factures', id_nav: 6 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'invoices/:id', component: EditComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'invoices/export/create', component: InvoiceExportCreateComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'invoices/export/list', component: InvoiceExportListComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'exercices', component: ExercicesListComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'exercices/sheet-create', component: SheetCreateComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'exercices/sheet-edit/:id', component: SheetCreateComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'exercices/sheet-edit/:id/exercice-create', component: SheetExercicesCreateComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'exercices/sheet-edit/:id/exercice-edit/:exercice_id', component: SheetExercicesCreateComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'debug-matrice', component: DebugMatriceComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'reports', component: ReportsComponent, data: { title: 'Rapports', id_nav: 8 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'reports/teacher_availabilities', component: ReportTeacherAvailabilitiesComponent, data: { title: 'Rapport Disponbilités Professeurs', id_nav: 8 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'reports/teacher_billing_expected', component: ReportTeacherBillingExpectedComponent, data: { title: 'Rapport Facturation Professeurs attendue', id_nav: 8 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'export/cdc', component: ExportCdcCertificationComponent, data: { title: 'liste des formations', id_nav: 9 }, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'export/cdc/detail-error', component: ExportCdcCertificationDetailErrorComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'export/cdc/list', component: ExportCdcCertificationListingComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'upcomings-classes/list', component: UpcomingClassesListComponent, data: { title: 'liste des prochains cours', id_nav: 10 }, canActivate: [AuthGuard] },

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BackofficeRoutingModule { }
