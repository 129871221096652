import { FormationService } from './../../../../../../service/formation.service';
import { Formation } from './../../../../../../model/formation.model';
import { Occurrence } from './../../../../../../model/occurrence.model';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ponctual-booking-conclusion',
  templateUrl: './ponctual-booking-conclusion.component.html',
  styleUrls: ['./ponctual-booking-conclusion.component.scss']
})
export class PonctualBookingConclusionComponent implements OnChanges {
  @Input() classCreated: Occurrence[] = [];
  @Input() formation: Formation;
  showComponent = false;

  constructor(
    private formationService: FormationService,
  ) { }

  ngOnChanges() {
    if (this.classCreated.length > 0) {
      this.showComponent = true;
    }
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

}
