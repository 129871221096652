/**
 * Etape 4 plan-formation : details de la plannification puis validation.
 */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainscreenGlobals } from '../../../../kiwixi/mainscreen.globals';
import { Formation } from '../../../../model/formation.model';
import { PlanService } from '../../../../service/plan.service';
import { FormationService } from '../../../../service/formation.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss', '../plan-formation.component.scss']
})
export class ResumeComponent implements OnInit {

  back_button: string;
  valid_button: string;
  newFormation: Formation;
  current_formation_id: any;
  planningLoader: boolean;
  errorPlanning = false;
  translatePath = 'lingueopro.plan-formation.resume';
  detailErrorPlanning: any;

  constructor(
    private mainscreenGlobals: MainscreenGlobals,
    private planService: PlanService,
    private activatedRoute: ActivatedRoute,
    private formationService: FormationService,
    private router: Router) {
    mainscreenGlobals.currentFormation.subscribe(value => this.newFormation = value);
    mainscreenGlobals.planningLoader.subscribe(value => this.planningLoader = value)
  }

  ngOnInit() {
    this.current_formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.formationService.getObservableFormationById(this.current_formation_id)
      .subscribe(_formation => {
        this.newFormation = _formation;
      })
  }
  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

  // retour vers l'edition des créneaux.
  goBack() {
    this.mainscreenGlobals.setCurrentFormation(this.newFormation);
    this.router.navigate(['plan-formation/schedule', this.current_formation_id])
  }

  /**
   * Validation de la plannification.
   */
  validPlanning() {
    this.mainscreenGlobals.setPlanningLoader(true);
    const param = {
      algorithm: 'naive',
      formation: this.mainscreenGlobals.getIdFromUrl(this.newFormation.url)
    }
    this.planService.postPlanFromStudent(param)
      .then(plan => {
        this.mainscreenGlobals.setPlanningLoader(false);
        if (plan['result'] === 'false') {
          this.errorPlanning = true;
          // On recupere le detail de l'erreur
          this.planService.getLogEntry(plan['task_id'])
            .subscribe(_log => {
              this.detailErrorPlanning = this.translatePath + '.' + _log['end_message'];
            })
        } else {
          this.router.navigate(['plan-formation/conclusion', this.current_formation_id]);
        }
      })
  }
}
