/**
 * Composant principal pour la gestion des rapports.
 */

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
