import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExerciceSheet } from './../../../../model/exerciceSheet.model';

@Component({
  selector: 'app-sheet-list',
  templateUrl: './sheet-list.component.html',
  styleUrls: ['./sheet-list.component.scss']
})
export class SheetListComponent implements OnInit {

  sheet: ExerciceSheet
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  showSheet(object) {
    if (object.mode === 'mobile') {
      this.router.navigate(['./sheet/' + object.sheet.id], { relativeTo: this.route });
    } else {
      this.sheet = object.sheet
    }

  }
}
