<div id="training-detail-screen" *ngIf="!editMode || (editMode && exerciceSheet)">
  <div class="row col12 training-header" *ngIf="!exerciceSheet">
  <span class="h5 page-title">Création nouvelle fiche d'apprentissage</span>
  </div>
  <div class="row col12 training-header" *ngIf="exerciceSheet">
    <span class="h5 page-title">Édition fiche d'apprentissage :#{{ exerciceSheet.id | number:'6.':'fr'}}</span>

    <span class="sheet-status">Statut : {{ sheetStatus.label }}</span>

    <div class="sheet-statchange-wrap" *ngIf="sheetStatus.code=='draft' && (isDrillsManager || isAdmin)">
        <button class="statchange-btn action-button submit-btn" mz-button (click)="updateStatus('moderate')" >
          <span class="statchange-icon"></span>Soumettre cette fiche
        </button>
    </div>

    <div class="sheet-statchange-wrap" *ngIf="(sheetStatus.code=='moderate' || sheetStatus.code=='disabled') && isDrillsManager">
        <button class="statchange-btn action-button publish-btn" mz-button (click)="updateStatus('published')" >
          <span class="statchange-icon"></span>Publier cette fiche
        </button>
    </div>

    <div class="sheet-statchange-wrap" *ngIf="sheetStatus.code=='published' && isDrillsManager">
        <button class="statchange-btn action-button hide-btn" mz-button (click)="updateStatus('disabled')" >
          <span class="statchange-icon"></span>Désactiver cette fiche
        </button>
    </div>

  </div>
  <div class="row">
    <div class="col l7 information-bloc">
      <app-sheet-information [exerciceSheet]="exerciceSheet"></app-sheet-information>
      <app-sheet-content  *ngIf="exerciceSheet" [exerciceSheet]="exerciceSheet"></app-sheet-content>
    </div>
    <div class="col l5 information-bloc" *ngIf="exerciceSheet">
      <app-sheet-related [exerciceSheet]="exerciceSheet"></app-sheet-related>
      <app-sheet-exercices-list [exerciceSheet]="exerciceSheet"></app-sheet-exercices-list>
    </div>
  </div>
</div>