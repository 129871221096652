<ng-container *ngIf="myTeacher && showProInfo; else elseTemplate">
  <!-- Langue enseignée -->
  <div class="languages-wrap">
    <div class="teaching-row">
        <b translate="">{{translatePath}}.teaching-lbl</b> : <span translate>{{myTeacher.teach_language.code}}</span>
    </div>
    
    <!-- Langues parlées -->
    <div class="speaking-row">
          <b translate>{{translatePath}}.speaking-lbl</b> :
          <span class="speaking_language" *ngFor="let language of myTeacher.languages" translate>
            {{ language.label }}
          </span>
    </div>
  </div>

  <!-- Description -->
  <div class="row">
    <b translate>{{translatePath}}.self-intro-lbl</b>:
    <a (click)="showInfo = true">
      <i class="material-icons helpicon">help</i>
    </a>
    <div class="popup-overlay-flex" *ngIf="showInfo">
    <ng-container *ngIf="showInfo">
      <div class="border-info-flex" >
        <i class="material-icons help-closeicon" (click)="showInfo = false">close</i>
        <div class="summary">
          <p class="help-title">Self-introduction</p>
          <p>Your self-introduction will be displayed to both your current and prospective students. Take this opportunity to let them know about you as a teacher! We recommend telling about your teaching experience, the languages you speak, where you’ve lived, and what you love! To cover more ground, we also suggest writing your presentation both in French and in your native language, in the respective text fields.</p>
          <p>If you ever need help writing the perfect introduction, don’t hesitate to get in touch with a Lingueo adviser.</p>
        </div>
      </div>
    </ng-container>
    </div>
  </div>
  <ng-container *ngIf="myTeacher.teach_language">
    <ng-container *ngIf="myTeacher.teach_language.label !== 'French' ">
      <div class="desclang-btn-wrapper">
        <mat-button-toggle-group [(ngModel)]="version">
          <mat-button-toggle class="toggle-french" [value]=true>
            <b translate>{{translatePath}}.french-btn</b>
          </mat-button-toggle>
          <mat-button-toggle class="toggle-native" [value]=false *ngIf="myTeacher.teach_language">
            <b translate>{{myTeacher.teach_language.code}}</b>              
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </ng-container>
  </ng-container>
   <div *ngIf="version">{{ myTeacher.description_fr }}</div>
   <div *ngIf="!version">{{ myTeacher.description_vo }}</div>
    <div class="usefuldocs-wrapper">
      <b>Useful ressources:</b>
      <a (click)="downloadDocumentationTemmplate('Charte_des_professeurs_2019_Avril_2019.pdf');" target="_blank">Charte des professeurs FR</a>
      <a (click)="downloadDocumentationTemmplate('Teacher_Charter_2019_Avril_2019.pdf');" target="_blank">Teacher's Charter EN</a>
      <a href="{{myTeacher.service_contract}}" target="_blank">My Contract with Lingueo</a>
    </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center loading pageload-message">
    <b translate>{{translatePath}}.pro-info-loading</b>
  </div>
</ng-template>

