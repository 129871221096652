import { SearchPlanSolution } from './../model/searchPlanSolution.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';



import { Observable, forkJoin, from } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

// Services
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { EventService } from './event.service';

import * as moment from 'moment';

@Injectable()
export class PlanService extends KiwixiService {

  apiPlan = environment.server + 'plan/';
  apiLogEntry = environment.server + 'planlog/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    private eventService: EventService,
    public toastService: MzToastService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getPlan(_params) {
    const headers = this.createHttpClientAuthorizationHeader();
    const self = this;
    return self._http.get(self.apiPlan + _params, { observe: 'response', headers: headers })
      .map((res: any) => {
        const result = res.body;
        const dataObj = JSON.parse(result.result);
        if (result.status === 'FAILURE') {
          return dataObj;
        } else if (result.status !== 'SUCCESS' && result.status !== 'FAILURE') {
          return this.sleep(200).then(() => {
            return self.getPlan(_params)
          })
        } else {
          return dataObj;
        }
      })
      .toPromise();
  }

  getPlanObservable(_params): Observable<any> {
    const headers = this.createHttpClientAuthorizationHeader();
    const self = this;
    return self._http.get(self.apiPlan + _params, { observe: 'response', headers: headers })
      .map((res: any) => {
        const result = res.body;
        const dataObj = JSON.parse(result.result);
        if (result.status === 'FAILURE') {
          return dataObj;
        } else if (result.status !== 'SUCCESS' && result.status !== 'FAILURE') {
          return this.sleep(500).then(() => {
            return self.getPlanObservable(_params).subscribe()
          })
        } else {
          return dataObj;
        }
      });
  }

  getPlanFromStudent(_params) {
    const headers = this.createHttpClientAuthorizationHeader();
    const self = this;
    return self._http.get(self.apiPlan + _params, { observe: 'response', headers: headers })
      .map((res: any) => {
        const result = res.body;
        const dataObj = result.result;
        if (result.status === 'FAILURE') {
          return dataObj;
        } else if (result.status !== 'SUCCESS' && result.status !== 'FAILURE') {
          return this.sleep(1000).then(() => {
            return self.getPlanFromStudent(_params)
          })
        } else {
          return dataObj;
        }
      })
      .toPromise();
  }

  getPlanAfterGenerate(_params) {
    const headers = this.createHttpClientAuthorizationHeader();
    const self = this;
    return self._http.get(self.apiPlan + _params, { observe: 'response', headers: headers })
      .map((res: any) => {
        const result = res.body;
        if (result.status === 'PROGRESS' || result.status === 'INIT') {
          return this.sleep(1000).then(() => {
            return self.getPlan(_params)
          })
        } else {
          return result;
        }
      })
      .toPromise();
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  postPlan(param) {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = param;
    return this._http.post(this.apiPlan, body, {
      headers: headers
    })
      .map(res => {
        return this.getPlan(res);
      })
      .toPromise();
  }

  searchSolution(param) {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = param;
    return this._http.post<SearchPlanSolution>(this.apiPlan, body, {
      headers: headers
    })
      .map(res => {
        return this.getPlan(res);
      })
      .toPromise();
  }

  postPlanFromStudent(param) {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = param;
    return this._http.post(this.apiPlan, body, {
      headers: headers
    })
      .map(res => {
        return this.getPlanFromStudent(res);
      })
      .toPromise();
  }

  createPlanning(param) {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = param;

    return this._http.post(this.apiPlan, body, {
      headers: headers
    })
      .toPromise()
      .then(res => {
        return this.getPlanAfterGenerate(res);
      })
      .catch(error => {
        console.error('error createPlanning', error);
        this.logout();
      })
  }

  getLogEntry(params): Observable<any> {

    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<any>(this.apiLogEntry + params, {
      headers: header
    })
      .map(_logs => _logs)
      .pipe(
        catchError(this.handleErrorObservable<any>(`getLogEntry`))
      );
  }

  addPonctualBookingPlanning(param) {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = param;
    return this._http.post(this.apiPlan, body, {
      observe: 'response',
      headers: headers
    })
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(error => {
        console.error('error addPonctualBookingPlanning', error);
        this.logout();
      })
  }

  /**
   * Methode pour gerer les retours services
  **/
  extractDataPlan(res: any) {
    return res;
  }

  getTeachersAvaibilities(_formationUrl, simulation = false) {
    const teachersAvailables = [];
    const param = {
      algorithm: 'availabilityteacher',
      simulation: simulation,
      formation: this.kiwixiGlobals.getIdFromUrl(_formationUrl)
    }
    return this.postPlan(param);
  }

  /*
   * Methode pour récupérer les dispos d'un prof
   */
  getTeacherDisponibilities(_teacherUrl) {
    const param = {
      algorithm: 'availabilityteacher',
      teacher: this.kiwixiGlobals.getIdFromUrl(_teacherUrl),
      month: moment().month() + 1,
      year: moment().year()
    }
    return this.postPlan(param);
  }

  /*
   * Services
   * Methode pour récupérer les heures disponibles
   */
  getDisponibilities(_formationUrl, algo_version = 'avaibilityHour', open = false) {
    const param = {
      algorithm: algo_version,
      formation: this.kiwixiGlobals.getIdFromUrl(_formationUrl),
      open_to_all_teacher: open
    }
    return this.postPlan(param);
  }

  resetSimulation(_formationId): Observable<any> {
    const header = this.createHttpClientAuthorizationHeader();
    const url = environment.server + 'events_delete/' + _formationId + '/';
    return this._http.delete(url, { headers: header });
  }

  /*
   * Services
   * Methode pour récupérer les profs dispo pour un event
   */
  getTeacherForEvent(_eventUrl, open = false) {
    const param = {
      algorithm: 'availabilityteachers',
      event: this.extractIdFromUrl(_eventUrl),
      open_to_all_teacher: open
    }
    return this.postPlan(param);
  }

  /*
   * Services
   * Methode pour récupérer les profs dispo pour un event
   */
  getTeachersForMultipleEvents(_events) {
    const obx = [];
    for (const event of _events) {
      const param = {
        algorithm: 'availabilityteachers',
        event: event.id,
        open_to_all_teacher: true
      }
      obx.push(this.postPlan(param));
    }
    return forkJoin(obx)
  }

  /**
   * @param _formation save event and generatePlanning
   * @param loader
   */
  generatePlanning(_formation, loader) {
    // Check des events de la formation
    const obx = [];
    for (const event of _formation.events) {
      if (event.start && event.end) {
        obx.push(this.eventService.saveEvent(event));
      }
    }
    return forkJoin(obx).map(
      res2 => {
        const param = {
          algorithm: 'writeCalendar',
          formation: this.kiwixiGlobals.getIdFromUrl(_formation.url)
        }
        return fromPromise(this.createPlanning(param)
          .then(res => {
            loader = false;
            this.toastService.show('Planning généré', 2000, 'green');
            _formation.hours_remaining = res;
            return res;
          }))
      },
      error => {
        this.toastService.show('Erreur generation Planning!!!', 2000, 'red');
      }
    );
  }

  /**
   * @param _formation save event and generatePlanning
   * @param loader
   */
  generateSimulation(_formation, loader) {
    // Check des events de la formation
    const obx = [];
    for (const event of _formation.events) {
      if (event.start && event.end) {
        obx.push(this.eventService.saveEvent(event));
      }
    }
    return forkJoin(obx).map(
      res2 => {
        const param = {
          algorithm: 'writeCalendar',
          formation: _formation.id
        }
        return fromPromise(this.createPlanning(param)
          .then(res => {
            loader = false;
            this.toastService.show('Planning généré', 2000, 'green');
            _formation.hours_remaining = res;
            return res;
          }))
      },
      error => {
        this.toastService.show('Erreur generation Planning!!!', 2000, 'red');
      }
    );
  }

  writeSimulation(_formation, loader) {
    const param = {
      algorithm: 'writeCalendar',
      formation: _formation.id,
    }
    return fromPromise(this.createPlanning(param)
      .then(res => {
        loader = false;
        this.toastService.show('Planning généré', 2000, 'green');
        // _formation.hours_remaining = res;
        return res;
      },
        error => {
          this.toastService.show('Erreur generation Planning!!!', 2000, 'red');
        })
    )
  }


  validPlanningFromReschedule(_formation, loader, algo) {

    const param = {
      algorithm: algo,
      formation: this.extractIdFromUrl(_formation.url)
    }
    return fromPromise(this.createPlanning(param)
      .then(res => {
        if (res === false) {
          this.toastService.show('Erreur generation Planning!!!', 2000, 'red');
        } else {
          this.toastService.show('Planning généré', 2000, 'green');
        }
        return res;
      },
        error => {
          this.toastService.show('Erreur generation Planning!!!', 2000, 'red');
        }))
  }

  generatePonctualBooking(_formation, newEvent) {
    return this.eventService.postEvent(newEvent).then(
      _newEvent => {
        const param = {
          algorithm: 'writeonetimelesson',
          formation: this.kiwixiGlobals.getIdFromUrl(_formation.url),
          teacher: null,
          event: _newEvent.id
        }
        if (newEvent.teacher) {
          param.teacher = this.kiwixiGlobals.getIdFromUrl(newEvent.teacher)
        }
        return this.addPonctualBookingPlanning(param)
          .then(res => {
            if (res['statusText']) {
              if (res['statusText'] === 'OK') {
                // this.router.navigate(['/formations', _formation.url.split('/')[6]]);
                this.toastService.show('Reservation ponctuelle validée', 1000, 'green');
                return this.getPlan(res['body']);
                //  window.location.reload();
              } else {
                this.toastService.show('Erreur create Planning!!!', 2000, 'red');
                console.error(res);
              }
            } else {
              this.toastService.show('Erreur create Planning!!!', 2000, 'red');
              console.error(res);
            }
          })
      }
    )
  }


  /* temporaire a supprimer après nos tests
   * Methode pour récupérer les jours disponibles
   *
   */
  getAvailableDays(_formationId, _open_to_all_teacher) {
    const param = {
      algorithm: 'allavailabilities',
      formation: _formationId,
      open_to_all_teacher: _open_to_all_teacher,
      timezone: this.zone_name
    }
    return from(this.postPlan(param));
  }

  /* temporaire a supprimer après nos tests
   * Methode pour récupérer les starts
   */
  getAvailableStarts(_formationId, _open_to_all_teacher, _dayId) {
    const param = {
      algorithm: 'allavailabilities',
      formation: _formationId,
      open_to_all_teacher: _open_to_all_teacher,
      timezone: this.zone_name,
      day: _dayId
    }
    return from(this.postPlan(param));
  }

  /* temporaire a supprimer après nos tests
   * Methode pour récupérer les ends
   */
  getAvailableEnds(_formationId, _open_to_all_teacher, _dayId, _start) {
    const param = {
      algorithm: 'allavailabilities',
      formation: _formationId,
      open_to_all_teacher: _open_to_all_teacher,
      timezone: this.zone_name,
      day: _dayId,
      start: _start
    }
    return from(this.postPlan(param));
  }

}
