import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingComponent } from './billing.component';
import { ListComponent } from './list/list.component';
import { InfoComponent } from './info/info.component';
import { DetailComponent } from './detail/detail.component';
import { BillingRoutingModule } from './billing-routing.module';
import { MaterializeModule } from 'ng2-materialize';
import { MaterialModule } from '../../../material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { DirectiveModule } from '../../../directive/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceComponent } from './list/invoice/invoice.component';
import { ClassDetailsComponent } from './class-details/class-details.component';
import { CurrentInvoiceComponent } from './list/current-invoice/current-invoice.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  imports: [
    CommonModule,
    MaterializeModule.forRoot(),
    MaterialModule,
    ReactiveFormsModule,
    MomentModule,
    DirectiveModule,
    BillingRoutingModule,
    InfiniteScrollModule,
    TranslateModule.forChild()
  ],
  exports: [
    InfiniteScrollModule
  ],
  declarations: [BillingComponent, ListComponent, InfoComponent, DetailComponent, InvoiceComponent, ClassDetailsComponent, CurrentInvoiceComponent]
})
export class BillingModule { }
