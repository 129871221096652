  <!-- Header -->
  <ng-container *ngIf="persona">
    <div class="conv-wrapper">
      <div class="conv-header" *ngIf="conversationWith && !conversationInTraining">
        <i class="material-icons back-btn" (click)="previousStep()">arrow_back</i>
        <div class="avatar-block">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="conversationWith.avatar === null">
            <img class="user-avatar" src="{{conversationWith.avatar}}" *ngIf="conversationWith.avatar !== null">
        </div>
        <div class="partner-name">
          <span translate>{{translatePath}}.conversation-with-btn</span> {{conversationWith.first_name}} {{conversationWith.last_name}}
        </div>
        
      </div>

      <div class="chat-bloc" #scrollMe>
          <b translate *ngIf="messageLoader">{{translatePath}}.load-messages</b>
          <div *ngIf="has_next" class="row center"><a class="pointer" (click)="getMoreMessages()" translate>{{translatePath}}.load-more-messages</a></div>
          <mat-spinner [diameter]="48" class="small-spinner" *ngIf="messageLoader"></mat-spinner>
          <div class="" *ngFor="let myMessagesByDate of myMessagesByDates">
              <p class="chat-date">
                  {{ myMessagesByDate.format }}
              </p>
            <div class="" *ngFor="let message of myMessagesByDate.messages"  trackScroll
            (trackScrollEnter)="enter()" 
            (trackScrollLeave)="leave()">
              <div class="chat-message-wrapper partner" *ngIf="checkFromVsTo(message)">
                  <div class="partner-message-avatar">
                      <img src="{{ his_avatar }}" class="user-avatar" />
                  </div>
                  <div class="chat-message" *ngIf="message.documents.length === 0" [innerHtml]="message.content"></div>
               
                    <div class="chat-message doc-message" *ngIf="message.documents.length > 0">
                        <div translate>{{translatePath}}.hehassharedfile</div>
                        <div class="shareddoc-row" (click)="file.click()" *ngFor="let doc of message.documents">
                            <a><i class="doc-message-icon"></i></a>
                            <div class="docname">{{ doc.name }}</div>
                            <a #file [hidden]="true" appDownloadLingueo [document]="doc"></a>
                        </div>
                    </div>
                
                <div class="chat-time">
                  {{message.created_at | amLocal | amLocale: 'en' | amDateFormat: 'HH:mm'}}
                </div>
              </div>
              <div class="chat-message-wrapper user" *ngIf="checkFrom(message)">
                <div class="chat-time">
                    {{message.created_at | amLocal | amLocale: 'en' | amDateFormat: 'HH:mm'}}
                </div>
                <div class="chat-message" *ngIf="message.documents.length === 0"  [innerHtml]="message.content"></div>
      
                <div class="chat-message doc-message" *ngIf="message.documents.length > 0">
                  <span></span><span translate>{{translatePath}}.hehassharedfile</span>
                  <div class="shareddoc-row" (click)="file.click()" *ngFor="let doc of message.documents">
                    <a><i class="doc-message-icon"></i></a>
                    <span class="docname">{{ doc.name }}</span>
                    <a #file [hidden]="true" appDownloadLingueo [document]="doc"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div>
      <div class="messageinput-bloc">
        <div id="message-create">
          <ckeditor class="message-editor" [editor]="Editor" [(ngModel)]="text_new_message" [config]="ckEditorConfig" (ready)="onReady($event)" ></ckeditor>
        </div>
          <div class="msg-buttons-row">
            <!-- Add-Documents -->
            <button class="addfile-btn"
            (click)="uploadDocs.click()">
              <span class="addfile-icon"></span>
            </button>
            <input #uploadDocs type="file" (change)="uploadFile($event)" [hidden]="true"/>
            <div class="row center loading pageload-message" *ngIf="uploadingDocument">
              <b translate>{{translatePath}}.documents-loading</b>
            </div>
            <button class="sendmessage-btn" type="button" (click)="sendMessage()" [disabled]="text_new_message === undefined || text_new_message.length === 0 || posting_message" >
                <b translate>{{translatePath}}.send-btn</b>
                <mat-spinner [diameter]="24" class="small-spinner" *ngIf="posting_message"></mat-spinner>
            </button>
          </div>
      </div>
      </div>
    </div>
  </ng-container>

  

  
