import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { RateStudentService } from '../../../../service/rate-student.service';
import { PendingService } from '../../../../service/pending.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../../service/authentification.service';

@Component({
  selector: 'app-list-report',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListReportComponent implements OnInit {
  loading_pending = false;
  loadingCompletedReport = false;
  nextPagesPending = null;
  nextPagesCompleted = null;
  ratingStudents: any[] = [];
  pendings: any[] = [];
  countPendings = 0;
  translatePath = 'teacher.reports';
  defaultAvatar = environment.static + '/avatar.png';
  searchControl: FormControl = new FormControl();
  searchInput = '';
  myUser: any;
  throttle = 1000; // temps accordé avant de fire l'event de scoll
  scrollDistance = 1; // % restant à partir duquel on fire l'event 1 = 10 %.

  constructor(
    private router: Router,
    private ratingStudentsService: RateStudentService,
    private pendingService: PendingService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.myUser = this.authenticationService.getCurrentPersona();
    this.getPendings();
    this.getFilterCompletedReports();
    this.searchControl.valueChanges
      .debounceTime(600)
      .subscribe(term => {
        this.ratingStudents = [];
        this.nextPagesCompleted = null;
        this.getFilterCompletedReports();
      })
  }


  getFilterCompletedReports() {
    this.loadingCompletedReport = true;
    let searchValue = '';
    if (this.searchControl.value) {
      searchValue = this.searchControl.value;
    }

    const params = {
      'student_name': searchValue,
      'page': 1,
      'teacher': this.myUser.id,
      'ordering': '-session__occurrence__start'
    }
    let url = this.ratingStudentsService.setUrlWithParams('rating_students', params);
    if (this.nextPagesCompleted) {
      url = this.nextPagesCompleted;
    }
    this.ratingStudentsService.getRatingStudentsByUrl(url)
      .subscribe(_ratingStudents => {
        if (_ratingStudents) {
          if (_ratingStudents['next']) {
            this.nextPagesCompleted = _ratingStudents['next'];
          } else {
            this.nextPagesCompleted = null;
          }
          // On incremente les données
          if (_ratingStudents['results']) {
            for (const _rating of _ratingStudents['results']) {
              this.ratingStudents.push(_rating)
            }
          }
        }
        else {
          this.nextPagesCompleted = null;
        }
        this.loadingCompletedReport = false;
      });

  }

  getPendings() {
    this.loading_pending = true;
    const params = {
      'page': 1,
      'salt': Math.random().toString(),
      'teacher': this.myUser.id,
    }
    let url = this.pendingService.setUrlWithParams('pendings', params);
    if (this.nextPagesCompleted) {
      url = this.nextPagesCompleted;
    }
    this.pendingService.getPendingsByUrl(url)
      .subscribe(_pendings => {
        if (_pendings) {
          if (_pendings['next']) {
            this.nextPagesPending = _pendings['next'];
          } else {
            this.nextPagesPending = null;
          }
          if (_pendings['results']) {
            _pendings['results'].forEach(_pending => {
              let exist = false;
              this.pendings.forEach(_existing => {
                if (_pending.url === _existing.url) {
                  exist = true;
                }
              });
              if (!exist) {
                if (_pending.teacher.url === this.myUser.url) {
                  this.countPendings += 1;
                  this.pendings.push(_pending)
                }
              }
            });
            this.loading_pending = false;
          }
        } else {
          this.nextPagesPending = null;
        }
      });
  }

  goToInfo(_ratingStudent) {
    const idSession = this.ratingStudentsService.extractIdFromUrl(_ratingStudent.session);
    this.router.navigate(['reports', 'info', idSession]);
  }

  resetSearch() {
    this.searchControl.setValue('');
  }

}
