/**
 * Service local pour l'edition d'un teacher.
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';



import { LanguageService } from '../../../../../service/language.service';
import { SiteService } from '../../../../../service/site.service';
import { RatingTeachersService } from '../../../../../service/rating-teachers.service';
import { UserService } from '../../../../../service/user.service';
import { TeacherService } from '../../../../../service/teacher.service';
import { StudentService } from '../../../../../service/student.service';

@Injectable()
export class EditTeacherService {
  apiTeachers = environment.server + 'teachers/';

  constructor(
    private languageService: LanguageService,
    private teacherService: TeacherService,
    private studentService: StudentService,
    private userService: UserService,
    private ratingTeachersService: RatingTeachersService,
    private siteService: SiteService) { }

  /**
   * GET API: récupérer les languages
   * @param filterParams
   */
  getLanguages(filterParams) {
    return this.languageService.getLanguages(filterParams);
  }

  /**
   * GET API: récupérer les sites
   * @param filterParams
   */
  getSites(filterParams) {
    return this.siteService.getAllSites(filterParams);
  }

  /**
   * GET API: récupérer le nombre de teachers
   */
  getCountTeachers() {
    return this.teacherService.getCountTeachers();
  }

  /**
   * GET API: récupérer les ratings teachers
   * @param filterParams
   */
  getRatingTeachers(filterParams) {
    return this.ratingTeachersService.getRatingTeachers(filterParams);
  }

  /**
   * GET API: récupérer un teacher.
   * @param id
   */
  getTeacher(id) {
    return this.teacherService.getTeacher(id);
  }

  /**
   * GET API: récupérer un étudiant.
   * @param url
   */
  getStudent(url) {
    return this.studentService.getStudentWithUrl(url);
  }

  /**
   * PATCH API: mettre à jour un teacher.
   * @param teacherUrl
   * @param param
   */
  updateTeacher(teacherUrl, param) {
    return this.teacherService.patchTeacher(teacherUrl, param);
  }

  /**
   * PATCH API: mettre à jour un user.
   * @param userUrl
   * @param param
   */
  updateUser(userUrl, param) {
    return this.userService.patchUser(userUrl, param);
  }

}
