import { SheetListComponent } from './sheet-list/sheet-list.component';
import { SheetDetailComponent } from './sheet-detail/sheet-detail.component';
import { SheetsComponent } from './sheets.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../guard/auth.guard';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';


export const routes: Routes = [
    {
        path: 'sheets',
        component: SheetsComponent,
        canActivate: [AuthGuard, MaintenanceGuard],
        children: [
            { path: '', component: SheetListComponent, canActivate: [AuthGuard] },
            { path: 'sheet/:id', component: SheetDetailComponent, canActivate: [AuthGuard] },
        ]
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class SheetsRoutingModule { }
