import { interval } from 'rxjs';
import { OccurrenceService } from './../../service/occurrence.service';
import { UpcomingService } from './../../service/upcoming.service';
import { Occurrence } from './../../model/occurrence.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-next-session-all-formations',
  templateUrl: './next-session-all-formations.component.html',
  styleUrls: ['./next-session-all-formations.component.scss', '../modal/modal.component.scss']
})
export class NextSessionAllFormationsComponent implements OnInit, OnDestroy {

  pollingObservable = null;
  nextSession: Occurrence = null;
  timeToGoInClass = false;
  isValidProvider = false;
  constructor(
    private upcomingService: UpcomingService,
    private occurrenceService: OccurrenceService,
  ) { }

  ngOnInit() {
    this.getNextSession();
    setInterval(() => this.checkTimeToGoInClass(), 1000);
    this.pollingObservable = interval(30000)
      .subscribe(i => {
        this.getNextSession();
      });
  }

  checkTimeToGoInClass() {
    //this.timeToGoInClass = true; // activer cette ligne pour tester et passer à false si on veut qu'elle soit toujours fermée
    // this.isMissedClass = true;
    if (this.nextSession) {
      if (this.nextSession.student.user.video_provider === 'teams') {
        if (this.nextSession.is_student_class_warning_showed) {
          return;
        }
        if (moment().isBetween(moment(this.nextSession.start), moment(this.nextSession.end))) {
          this.timeToGoInClass = true;
          this.checkProviderRequirements();
        } else {
          this.timeToGoInClass = false;
        }
      }
    }
  }

  checkProviderRequirements() {
    if (this.nextSession.provider_link) {
      this.isValidProvider = true;
    } else {
      this.isValidProvider = false;
    }
  }

  showClassPanel() {
    this.timeToGoInClass = true;
    this.checkProviderRequirements();
  }

  getNextSession() {
    let params = new HttpParams();
    params = params.append('page_size', '1');
    params = params.append('salt', Math.random().toString());

    this.upcomingService.getUpcomings(params).subscribe(upcompings => {
      if (upcompings) {
        if (upcompings['results'].length > 0) {
          this.nextSession = upcompings['results'][0];
        } else {
          this.nextSession = null;
        }
      } else {
        this.nextSession = null;
      }
    })
  }

  joinClass() {
    this.patchData('is_student_provider_link_clicked');
    this.openTeamsLink();
    this.close();
  }


  patchData(data) {
    const params = {
      [data]: moment().toISOString(),
    }
    this.nextSession.is_student_class_warning_showed = moment().toDate();
    this.occurrenceService.patchObservableOccurrence(this.nextSession.url, params).subscribe(occurrence => {
      if (occurrence) {
        this.nextSession = occurrence
      }
    });
  }

  close() {
    this.timeToGoInClass = false;
    this.patchData('is_student_class_warning_showed');
  }

  openTeamsLink() {
    if (this.nextSession.provider_link) {
      window.open(this.nextSession.provider_link, '_blank');
    }
  }

  ngOnDestroy() {
    if (this.pollingObservable) {
      this.pollingObservable.unsubscribe()
    }
  }
}
