/**
 * Composant pour l'affichage de la liste des Teachers.
 */

import { Component, OnInit } from '@angular/core';
import { TeachersListService } from './teachers-list.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teachers-list',
  templateUrl: './teachers-list.component.html',
  styleUrls: ['./teachers-list.component.scss', './teachers-list.ux.scss', '../../mainscreen.ux.scss'],
  providers: [TeachersListService]
})
export class TeachersListComponent implements OnInit {

  dataTeachers: Object;             // liste des teachers.
  private errorMessage: any = '';   // message d'erreur.
  countPages = 1;                   // paginator.
  pageSize = 10;                    // taille de la liste.
  totalData: number;                // nombre de teachers.

  constructor(
    private teachersListService: TeachersListService,
    private router: Router) { }

  ngOnInit() {
    this.dataTeachers = {
      data: [],
      headers: this.teachersListService.dataHeaders
    }
  }

  /**
   * Méthode pour appeler le service permettant de récupérer les teachers.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {

    // params de filtre/tri
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = [];
    }
    if (!_filterParams['ordering']) {
      _filterParams['ordering'] = {
        key: 'ordering',
        value: '-id'
      }
    }
    this.teachersListService.getTeachers(_filterParams)
      .then(
        res => {
          this.totalData = res['count'];
          const _teachers = res['results']
          if (_countPages === 1) {
            this.dataTeachers['data'] = [];
          }
          for (const _teacher of _teachers) {
            this.setRowsData(_teacher);
          }
        },
        error => this.errorMessage = <any>error
      );
    return this.dataTeachers['data'];
  }

  /**
   * Methode pour construire les lignes du tableau pour chaque teacher.
   * @param teacher
   */
  setRowsData(teacher) {
    const row = {
      url: teacher.url,
      id: teacher.url.split('/')[6],
      id_student: null,
      teacher: null,
      teacher_email: null,
      teach_language: teacher.teach_language,
      languages: teacher.languages,
      status: teacher.status
    };

    this.setLanguages(row, teacher);
    if (teacher.user !== undefined) {
      this.setTeacherInfoToRow(row, teacher)
    }
    this.dataTeachers['data'].push(row);
  }

  /**
   * Ajouter des infos liés au teacher sur la ligne.
   * @param row
   * @param teacher
   */
  setTeacherInfoToRow(row, teacher) {
    row.id_student = teacher.user.id_student;
    row.teacher = teacher;
    row.teacher_email = teacher.user.email;
  }

  /**
   * ajouter l'objet language.
   * @param row
   * @param teacher
   */
  setLanguages(row, teacher) {
    const list = []
    for (const language of teacher.languages) {
      list.push(language.label);
    }
    row.languages = list.toString();
  }

  /**
   * naviguer vers la page de création d'un teacher
   */
  showCreateTeacher() {
    this.router.navigate(['/teachers/create']);
  }

  /**
   * methode pour naviguer depuis la selection d'une ligne.
   * @param row
   */
  public showDetail(row) {
    this.router.navigate(['/teachers/admin', row.id]);
  }

}
