import { ProfilePhotosService } from './../../../../../service/profilePhotos.service';
/**
 * composant pour l' édition d'un student.
 */

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Student } from '../../../../../model/student.model';
import { User } from '../../../../../model/user.model';
import { Site } from '../../../../../model/site.model';
import { Language } from '../../../../../model/language.model';
import { environment } from '../../../../../../environments/environment';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { MzModalService, MzToastService } from 'ng2-materialize';
import { FormControl } from '@angular/forms';
import { Timezone } from '../../../../../model/timezone.model';
import * as moment from 'moment';

// services
import { FormationService } from '../../../../../service/formation.service';
import { LanguageService } from '../../../../../service/language.service';
import { StudentService } from '../../../../../service/student.service';
import { SiteService } from '../../../../../service/site.service';
import { UserService } from '../../../../../service/user.service';
import { TimezoneService } from '../../../../../service/timezone.service';

@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.scss', './edit-student.ux.scss', '../../mainscreen.ux.scss'],
  providers: [
    StudentService,
    FormationService,
    TimezoneService,
    MzModalService
  ]
})

export class EditStudentComponent implements OnInit {
  apiStudents = environment.server + 'students/';
  defaultAvatar = environment.static + '/avatar.png';

  now: any;                                         // Date du jour
  init: boolean;                                    // Boolean pour l'affichage de la page

  // --- User/Student :
  user: User;                                       // student user.
  students: any[];                                  // liste des students du user.
  currentIdStudent: number;                         // id student de la page.
  VIP: Boolean = false;                                     // status vip du user.
  showStudent = false;                              // boolean pour affichage le student.
  genders = [
    { fr: 'Non spécifié', en: 'nc' },
    { fr: 'Femme', en: 'female' },
    { fr: 'Homme', en: 'male' }
  ];
  listStatusPhotosProfile = this.userService.listStatusPhotosProfile;

  // --- sites :
  mySites: any[];
  sites: Site[];                                      // liste des sites disponibles.
  initSites: any[];                                   // liste des sites originale du student.
  removeSites = [];                                   // liste des sites à supprimer.
  patchSites = [];                                    // liste des sites qui remplacera la precedente.
  createSites = [];                                   // liste des sites à créer.
  mySite;                                             // site sélectionné.
  sitesAreReady: boolean;                             // boolean pour l'affichage de la select-box des sites.

  // --- modals :
  validModal = false;                                 // boolean pour l'affichage de la modal de validation du nouveau mot de passe.
  validModalEmail = false;                            // boolean pour l'affichage de la modal de validation de l'email.
  showValidModal = false;

  // --- timezones :
  zones: Timezone[];                                  // liste de timezones.
  timezoneControl: FormControl = new FormControl();   // Form Control du timezone.
  filteredTimezones: Observable<Timezone[]>;          // liste des timezones filtrées.
  myTimezone: string;                                 // timezone sélectionné.

  // --- languages :
  languages: Language[];                              // data languages.
  countPagesLanguages = 1;                            // paginateur data languages.
  languageControl: FormControl = new FormControl();   // Form Control du language.
  myLanguage: {};                                     // language sélectionné.
  myFormation;                                        // la formation du student.

  // --- datatable :
  totalData: number;                                  // nombre de formations.
  dataFormations: Object;                             // liste des formations.
  pageSize: number;                                   // nombre de lignes par page
  source: string;                                     // source du tableau
  countPages = 1;                                     // paginateur

  // Colonnes
  public dataHeadersEditStudent = [
    {
      name: 'status',
      title: 'Statut',
      type: 'string',
      ascending: false,
      filter_key: 'status__icontains',
      ordering_key: 'status',
      filter_type: 'select',
      list: this.formationService.getStatus(),
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'formation',
      title: 'Formation',
      type: 'formation',
      ascending: false,
      filter_key: 'name__icontains',
      ordering_key: 'name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'teachers',
      title: 'Professeurs',
      type: 'teachers',
      ascending: false,
      filter_key: 'teachers__user__username__icontains',
      ordering_key: 'teachers__user__username',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'validity_period_from',
      name2: 'validity_period_to',
      title: 'Valide',
      type: 'date_range',
      ascending: false,
      filter_key: 'validity_date',
      ordering_key: 'validity_date',
      filter_type: 'calendar',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'site',
      title: 'Site',
      type: 'site',
      ascending: false,
      filter_key: 'site__name__icontains',
      ordering_key: 'site__name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    }
  ]

  constructor(
    private router: Router,
    public formationService: FormationService,
    private route: ActivatedRoute,
    public timezoneService: TimezoneService,
    public languageService: LanguageService,
    public studentService: StudentService,
    public siteService: SiteService,
    public userService: UserService,
    private kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
    private profilePhotosService: ProfilePhotosService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.currentIdStudent = params['id'];
      this.languages = [];
    });
    this.showStudent = false;
    this.init = false;
    this.zones = [];
    this.sites = [];
    this.getAllSites();
    this.languages = [];
    this.getAllTimezones(1);
    this.userService.getObservableUserById(this.currentIdStudent)
      .subscribe(_user => {
        if (_user) {
          this.user = _user;
          this.init = true;
          this.getAllLanguages();
          //
          this.initStudents();
        }
      })

    // init timezones combo
    this.filteredTimezones = this.timezoneControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterTimezone(val) : this.zones);
    // init languages combo
    this.languageControl.valueChanges.subscribe(lang => {
      this.updateUserLanguage();
    })


    this.dataFormations = {
      data: [],
      headers: this.dataHeadersEditStudent
    }
    this.getData({}, this.countPages);

  }

  // filtrer les timezones
  filterTimezone(val: string): Timezone[] {
    return this.zones.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) > - 1);
  }

  /**
   * Recupérer la liste des students de notre user.
   */
  initStudents() {
    this.userService.getStudents(this.user)
      .then(students => {
        this.initSites = [];
        this.mySites = [];
        this.students = students;
        for (const _student of students) {
          if (_student['is_active']) {
            if (_student.vip) {
              this.VIP = true;
            }
            this.mySites.push(_student['site']);
            this.initSites.push(_student['site']);
          }
        }
        this.sitesAreReady = true;
      })
      .catch(error => {
        console.error('error getStudents service', error);
      });
  }

  // filtrer les languages
  filterLanguages(val: string): Language[] {
    return this.languages.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }

  // afficher le label d'un language.
  displayLanguageFn(language) {
    return language ? language.label : language;
  }

  /**
   * récupérer les sites
   */
  getAllSites() {
    return this.siteService.getSites()
      .then(_siteData => {
        for (const site of _siteData['results']) {
          if (site.available_formation) {
            site.disable = false;
            if (site.name === 'teenisio') {
              site.disable = true;
            }
            this.sites.push(site);
          }
        }
      })
      .catch(this.siteService.handleError);
  }

  /**
   * récupérer les timezones.
   * @param _countPagesSites
   */
  getAllTimezones(_countPagesSites) {
    this.timezoneService.getTimezones({})
      .then(_timezonesData => {
        for (const timezone of _timezonesData) {
          this.zones.push(timezone)
        }
      });
  }

  /**
   * récupérer tous les languages.
   */
  getAllLanguages() {
    this.myLanguage = this.user.main_language;
    this.languageService.getLanguages({})
      .then(_languageData => {
        for (const language of _languageData['results']) {
          if (language.code === 'language.it' || language.code === 'language.en' || language.code === 'language.fr') {
            this.languages.push(language);
            if (this.user.main_language === language.url) {
              this.languageControl.setValue(language);
            }
          }
        }
        this.showStudent = true;
      })
  }

  /**
   * permet de mettre à jour le language du user.
   */
  updateUserLanguage() {
    if (this.languageControl.value) {
      this.user.main_language = this.languageControl.value.url;
      this.updateUser(this.user, 'main_language');
    }
  }

  /**
   * Méthode pour mettre à jour les sites pour un student.
   */
  updateSites() {
    setSitesList(this);
    // #3 - launch remove
    this.removeSites.forEach(_site => {
      this.students.forEach(_student => {
        if (_student.site === _site) {
          _student.is_active = false;
          this.updateStudent(_student, 'is_active')
        }
      });
    });

    // #4 - launch patch
    this.patchSites.forEach(_site => {
      this.students.forEach(_student => {
        if (_student.site === _site) {
          _student.is_active = true;
          this.updateStudent(_student, 'is_active')
        }
      });
    });

    // #5 - launch create
    this.createSites.forEach(_site => {
      const newStudent = new Student();
      newStudent.is_active = true;
      newStudent.site = _site;
      newStudent.user = this.user;
      this.studentService.postStudent(newStudent)
        .then(res => {
          this.students.push(res)
          this.createSites = [];
          this.initSites = [];
          this.mySites.forEach(variable => {
            this.initSites.push(variable);
          });
        })
    });

    // méthode pour créer des liste Create, Patch, Remove
    function setSitesList(_self) {
      // #1 - remove sites
      _self.initSites.forEach(_site => {
        if (_self.mySites.length > 0) {
          if (!_self.objExist(_site, _self.mySites)) {
            _self.removeSites.push(_site);
          }
        } else {
          _self.removeSites = [];
          for (const _initSite of _self.initSites) {
            _self.removeSites.push(_initSite);
          }
        }
      });

      // #2 - new or patch
      _self.mySites.forEach(_site => {
        if (!_self.objExist(_site, _self.initSites)) {
          let _isNew = true;
          // check if this user have a student for this site
          _self.students.forEach(_student => {
            if (_student.site === _site) {
              _isNew = false;
            }
          });
          if (_isNew) {
            _self.createSites.push(_site);
          } else {
            _self.patchSites.push(_site);
          }
        }
      });
    }
  }

  /*
   * Methode pour vérifier si un teacher existe déjà dans une liste.
   */
  objExist(obj, list) {
    for (let _i = 0; _i < list.length; _i++) {
      if (obj === list[_i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Méthode pour récupérer les formations.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {
    // je veux toujours un filtre sur le currentStudent
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = {}
    }
    if (!_filterParams['filtering'].hasOwnProperty('student__user')) {
      _filterParams['filtering']['student__user'] = this.currentIdStudent;
    }
    this.formationService.getFormations(_filterParams)
      .then(
        res => {
          this.totalData = res['count'];
          const _formations = res['results']
          if (_countPages === 1) {
            this.dataFormations['data'] = [];
          }
          setFormationData(_formations, this);
        }
      );
    return this.dataFormations['data'];

    // settings rows for data-table of formations for the student
    function setFormationData(formations, _self) {
      for (const _formation of formations) {
        _self.setRowsData(_formation);
        if (!_self.myFormation && _formation.status === 'active') {
          _self.myFormation = _formation;
        }
      }

      // si pas de formation active on affiche une formation en attente
      if (!_self.myFormation) {
        formations.forEach(_formation => {
          if (!_self.myFormation && _formation.status === 'pending') {
            _self.myFormation = _formation;
          }
        });
      }
    }
  }


  /**
   * Methode pour construire les lignes du tableau pour chaque formation.
   * @param formation
   */
  setRowsData(formation) {
    const row = {
      status: formation.status,
      formation: formation,
      teachers: formation.teachers,
      site: formation.site,
      validity_period_from: formation.validity_period_from,
      validity_period_to: formation.validity_period_to,
    };
    this.dataFormations['data'].push(row);
  }

  /**
   * navigation vers le détail d'une formation.
   * @param row
   */
  gotoDetailFormation(row) {
    this.router.navigate(['/formations', this.formationService.extractIdFromUrl(row.formation.url)]);
  }

  /**
   * Mettre à jour le status VIP d'un student
   * @param VIP
   */
   updateVIP(vip) {
    this.students.forEach(_student => {
      _student.vip = vip;
      this.updateStudent(_student, 'vip')
    });
  }

  /**
   * Mettre à jour un student
   * @param student
   * @param field
   */
  updateStudent(student, field) {
    const param = {};
    param[field] = student[field];
    this.studentService.patchStudent(student.url, param)
      .then(res => {
        this.initSites = [];
        this.removeSites = [];
        this.patchSites = [];
        this.mySites.forEach(_site => {
          this.initSites.push(_site);
        });
      })
  }

  /**
   * mettre à jour un user.
   * @param user
   * @param field
   */
  updateUser(user, field) {
    const param = {};
    param[field] = user[field];
    this.userService.patchUser(user.url, param)
  }

  /**
   * mettre à jour le timezone du student
   * @param user
   * @param field
   */
  updateTimezone(user, field) {
    const param = {};
    param[field] = user[field];
    let tsValid = false;
    // controle timzone avant patch API
    for (const _tz of this.filterTimezone(param[field])) {
      if (_tz.label === param[field]) {
        tsValid = true;
      }
    }
    if (tsValid) {
      this.userService.patchUser(user.url, param);
    } else {
      this.toastService.show('Erreur Timezone', 2000, 'red');
    }
  }

  /**
   * methode pour charger un avatar.
   */
  uploadAvatar($event) {
    // on upload l'avatar sur le user.
    for (const file of $event.target.files) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      if (this.profilePhotosService.authorizedExtension.indexOf(ext.toLowerCase()) !== -1) {
        this.sendFile(file);
      } else {
        this.profilePhotosService.toastService.show('Fomat de fichier invalide, JPG ou PNG autorisé !', 4000, 'red');
      }
    }
  }

  sendFile(file) {
    this.profilePhotosService.postProfilePhoto(this.user, file, 'validated').subscribe(photoProfile => {
      this.user.photo_profile = photoProfile
      this.user.photo_profile_validation_requested = true;
    })
  }

  patchStatusPhotoProfile() {
    const params = {
      status: this.user.photo_profile.status,
    };
    this.profilePhotosService.patchProfilePhoto(this.user.photo_profile.id, params).subscribe(profilePhoto => {
      this.user.photo_profile = profilePhoto;
    })
  }



  /**
   * navigation vers la page de création d'une formation.
   */
  showCreateFormation() {
    this.kiwixiGlobals.setCurrentStudentIdToNewFormation(this.currentIdStudent)
    this.router.navigate(['/formations/create']);
  }

  /**
   * affichage de la modal
   * @param value
   */
  handleShowValidModal(value) {
    this.showValidModal = value;
    this.validModal = value;
    this.validModalEmail = value;
  }

}
