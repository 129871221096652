import { Formation } from './../../../../model/formation.model';
import { FormationService } from './../../../../service/formation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { InitAppService } from '../../../../service/initapp.service';
import * as moment from 'moment';
import 'moment/locale/fr';
@Component({
  selector: 'app-init-ponctual-booking',
  templateUrl: './init-ponctual-booking.component.html',
  styleUrls: ['./init-ponctual-booking.component.scss',
  '../plan-formation.component.scss']
})
export class InitPonctualBookingComponent implements OnInit {
  formation: Formation;
  showScreen = false;
  maxDate;
  maxDateFullDispo;
  showCreatedClass = false;
  componentToLoad;
  modalParams;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formationService: FormationService,
    private initAppService: InitAppService,
  ) { }

  ngOnInit() {
    this.maxDate = moment().add(8, 'days');
    this.maxDateFullDispo = moment().add(8, 'days');

    const formationId = this.activatedRoute.snapshot.paramMap.get('id');

    this.initAppService.initApp(true, formationId);

    this.formationService.currentFormationSubject.subscribe(formation => {
      if (formation) {
        if (formation.mode_formation === 'lilate') {
          this.maxDate = null;
          this.maxDateFullDispo = moment().add(31, 'days');
        }
        this.formation = formation;
        this.showScreen = true;
      }
    });
  }

  showCreated(data) {
    this.componentToLoad = 'init-ponctual-booking-conclusion';
    this.modalParams = { classCreated: data.classCreated, mode_formation: this.formation.mode_formation }
  }

  showDemo() {
    this.componentToLoad = 'init-ponctual-demo-tour';
    this.modalParams = {}
  }

}
