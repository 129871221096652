import { User } from './../../../../../model/user.model';
import { ExerciceCategoryService } from './../../../../../service/exerciceCategory.service';
import { Language } from './../../../../../model/language.model';
import { LanguageService } from './../../../../../service/language.service';
import { ExerciceSheet } from './../../../../../model/exerciceSheet.model';
import { ExerciceSheetService } from './../../../../../service/exerciceSheet.service';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { ConfirmationDialogComponent } from './../../../../../feature/confirmation-dialog/confirmation-dialog.component';
import { registerLocaleData } from '@angular/common';
import { Component, ViewChild, AfterViewInit, Pipe, PipeTransform } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import localeFr from '@angular/common/locales/fr';
import { ExerciceSpecificContent } from '../../../../../model/exerciceSpecificContent.model';
import { ExerciceCategory } from '../../../../../model/exerciceCategory.model';
registerLocaleData(localeFr);

@Component({
  selector: 'app-exercices-list',
  templateUrl: './exercices-list.component.html',
  styleUrls: ['./exercices-list.component.scss', '../../mainscreen.ux.scss'],
})
export class ExercicesListComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'select',
    'id',
    'title_fr',
    'language',
    'cecrl_level',
    'category',
    'specific_content',
    'status',
    'nb_exercices',
    'nb_hard_exercices',
  ];
  exercicesSheets: ExerciceSheet[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  defaultAvatar = environment.static + 'avatar.png';
  sheetsStatus = this.exerciceSheetService.getExerciceSheetsStatus();
  languageList: Language[] = [];
  categoryList: ExerciceCategory[] = [];
  cecrlLevelList;
  totalAmountRequest: Number = 0;
  selection = new SelectionModel(true, []);
  modeAllPage = false;
  allIds;
  page_size = 10;
  currentUser: User;
  isDrillsManager: boolean;

  filterFormGroup = new FormGroup({
    id: new FormControl(),
    title_fr: new FormControl(),
    language: new FormControl(),
    cecrl_level: new FormControl(),
    category: new FormControl(),
    specific_content: new FormControl(),
    status: new FormControl(),
  });

  bulkActionFormGroup = new FormGroup({
    statusBulkUpdate: new FormControl(),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private router: Router,
    private exerciceSheetService: ExerciceSheetService,
    private languageService: LanguageService,
    private exerciceCategoryService: ExerciceCategoryService,
    public dialog: MatDialog,
    private toastService: MzToastService,
  ) { }

  loadFilterListing() {
    if (this.exerciceSheetService.filterExerciceSheetListing.value) {
      this.filterFormGroup.controls.id.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.id);
      this.filterFormGroup.controls.title_fr.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.title_fr);
      this.filterFormGroup.controls.language.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.language);
      this.filterFormGroup.controls.cecrl_level.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.cecrl_level);
      this.filterFormGroup.controls.category.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.category);
      this.filterFormGroup.controls.specific_content.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.specific_content);
      this.filterFormGroup.controls.status.setValue(this.exerciceSheetService.filterExerciceSheetListing.value.status);
    }
  }

  ngAfterViewInit() {
    this.currentUser = this.exerciceSheetService.kiwixiGlobals.currentStaff.value.user;
    this.isDrillsManager = this.currentUser.groups.filter(group => group.name === 'drills-content-manager').length > 0 ? true : false;
    this.filterUpdateStatusChoice();
    this.loadFilterListing();
    this.languageService.getObservableLanguages().subscribe(data => {
      if (data['results']) {
        this.languageList = data['results'];
      }
    })
    this.exerciceCategoryService.getCategories(null).subscribe(data => {
      if (data['results']) {
        this.categoryList = data['results'];
      }
    })
    this.cecrlLevelList = this.exerciceSheetService.getcecrlLevel();
    this.exerciceSheetService.setFilterListing(this.filterFormGroup.value);
    this.filterFormGroup.valueChanges.debounceTime(1000).subscribe(data => {
      this.exerciceSheetService.setFilterListing(data);
      this.applyFilter();
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.getExerciceSheets();
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          this.totalAmountRequest = data.total;
          this.allIds = data.allids;

          return data.results;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.exercicesSheets = data;
      });
  }

  filterUpdateStatusChoice() {
    if (!this.isDrillsManager) {
      this.sheetsStatus = this.sheetsStatus.filter(status => status.code === 'moderate');
    }
  }

  getExerciceSheets() {
    return this.exerciceSheetService.getExerciceSheetsByFilter(this.paginator.pageSize, this.paginator.pageIndex, this.sort.active, this.sort.direction)
  }

  getGroupList(groups: ExerciceSpecificContent[]) {
    return groups.map(group => group.name).join(',');
  }

  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getExerciceSheets().subscribe(
      _sheets => {
        this.selection.clear();
        this.modeAllPage = false;
        this.resultsLength = _sheets.count;
        this.exercicesSheets = _sheets.results;
        this.totalAmountRequest = _sheets.total;
        this.allIds = _sheets.allids;
      });
  }

  clearFilter() {
    this.filterFormGroup.reset();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isPageSelected() {
    let pageSelected = true;
    this.exercicesSheets.forEach(sheet => {
      if (!this.selection.isSelected(sheet.id)) {
        pageSelected = false;
      }
    })
    return pageSelected;
  }

  clearPage() {
    this.exercicesSheets.forEach(sheet => {
      if (this.selection.isSelected(sheet.id)) {
        this.selection.toggle(sheet.id);
      }
    })
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isPageSelected() ?
      this.clearPage() :
      this.exercicesSheets.forEach(sheet => this.selection.select(sheet.id));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isPageSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected((row.id)) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  selectAllPage() {
    this.modeAllPage = !this.modeAllPage;
    if (this.modeAllPage) {
      this.allIds.forEach(id => this.selection.select(id));
    } else {
      this.selection.clear()
    }
  }

  getSelectedSheets() {
    return this.selection.selected.map(sheet_id => sheet_id);
  }

  postBulkUpdate() {
    const selectedSheets = this.getSelectedSheets();
    const new_status = this.bulkActionFormGroup.controls.statusBulkUpdate.value;

    this.exerciceSheetService.bulkUpdate(new_status, this.modeAllPage, this.allIds, selectedSheets).subscribe(
      result => {
        this.applyFilter();
        this.bulkActionFormGroup.controls.statusBulkUpdate.reset();
      });
  }

  bulkUpdate(value) {
    if (this.checkIfMinSheetSelected() === false) {
      this.bulkActionFormGroup.controls.statusBulkUpdate.reset();
      return;
    }
    if (this.modeAllPage && this.allIds.length > 1000) {
      this.toastService.show('Impossible de modifier plus de 1000 éléments à la fois', 4000, 'red');
      this.bulkActionFormGroup.controls.statusBulkUpdate.reset();
      return;
    }

    this.openDialog(value);
  }

  openDialog(value): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'Vous êtes sur le point de modifier le statut de ' + this.selection.selected.length + ' éléments avec cette valeur : ' + value
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postBulkUpdate()
      }
    });
  }

  checkIfMinSheetSelected() {
    if (this.selection.selected.length === 0) {
      this.toastService.show('Veuillez selectionnez au moins une facture', 4000, 'red');
      return false;
    } else {
      return true;
    }
  }

  showRecord(row) {
    this.router.navigate(['/exercices/sheet-edit/' + row.id]);
  }


}
