import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../service/authentification.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../login/login.ux.scss']
})
export class ResetPasswordComponent implements OnInit {

  uid;
  token;
  translatePath = 'resetpassword'
  password1 = new FormControl('', [Validators.required, Validators.minLength(8), ResetPasswordComponent.passwordValidator]);
  password2 = new FormControl('', [Validators.required, Validators.minLength(8), ResetPasswordComponent.passwordValidator]);
  formChanges = false;
  passwordForm: FormGroup = this.builder.group({
    password1: this.password1,
    password2: this.password2,
  }, {
    validator: ResetPasswordComponent.MatchPassword // your validation method
  });

  static passwordValidator(control) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.length > 7) {
        return null;
      }
    } else {
      return { 'emptyPassword': true };
    }
  }

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password1').value; // to get value in input tag
    const confirmPassword = AC.get('password2').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('password2').setErrors({ MatchPassword: true })
    } else {
      return null
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private builder: FormBuilder,
  ) { }

  ngOnInit() {
    this.uid = this.route.snapshot.params['uid'];
    this.token = this.route.snapshot.params['token'];
    this.subcribeToFormChanges();
  }

  subcribeToFormChanges() {
    // initialize stream
    const myFormValueChanges$ = this.passwordForm.valueChanges;

    // subscribe to the stream
    myFormValueChanges$.subscribe(data => {
      this.formChanges = true;
    })
  }

  sendNewPassword() {
    this.authenticationService.sendNewPassword(this.uid, this.token, this.password1.value, this.password2.value).subscribe(res => {
      this.authenticationService.toastService.show('mot de passe modifié', 4500, 'green');
      this.router.navigate(['login']);
    }, error => {
      this.authenticationService.toastService.show('une erreur est survenue', 4500, 'red');

    }
    )
  }

}
