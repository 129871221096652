<div class="row-formation" (click)="displayFormation()">
  <div class="training-flag">
    <div class="inset-shadow"></div>
    <app-lang-flag [language]="formation.language_detail"></app-lang-flag>
  </div>
  <div class="nav-training-title">
    <b>{{formation.name}}</b>
    <span class="nav-training-id"><b translate>{{translatePath}}.formation</b> <b>{{kiwixiGlobals.getIdFromUrl(formation.url)}}</b></span>
  </div>
</div>
