<p *ngIf="loading; else language">
  Chargement ...
</p>
<ng-template #language>
  <div *ngIf="my_language">
    <div class="valign-wrapper">
      <div class="col l2">
          <app-lang-flag [language]="my_language">
          </app-lang-flag>
      </div>
      <div class="col l10 truncate">
        {{ my_language.label }}
      </div>
    </div>
  </div>
</ng-template>
