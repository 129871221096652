import { ExerciceQuestion } from './exerciceQuestion.model';
export class ExerciceAnswerChoice {
    id: number;
    url: string;
    text: string;
    position: number;
    is_right: boolean;
    question: ExerciceQuestion;
    updated_at: Date;
    created_at: Date;
}
