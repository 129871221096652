<ng-container *ngIf="showBackoffice; else elseTemplate">
    <div class="backoffice-master-wrapper">
      <div class="backoffice-constrainer">
        <app-leftnavbar id="backnav" [navElements]="navElements" [currentStaff]="currentStaff" *ngIf="currentStaff && showNavBar">
        </app-leftnavbar>
        <app-mainscreen id="backoffice"></app-mainscreen>
      </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center">
    Chargement du Backoffice...
  </div>
</ng-template>
