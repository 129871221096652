import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';

import { OccurrenceService } from '../../../../service/occurrence.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { OccurrenceList } from '../../../../../app/model/occurrenceList.model';
import { Occurrence } from '../../../../../app/model/occurrence.model';
import { MatPaginator, MatSort } from '@angular/material';
import { SessionsService } from '../formation/sessions/sessions.service';
import { OnlineMeetingsService } from '../../../../../app/service/onlineMeetings.service';
import { MzToastService } from 'ng2-materialize';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-upcoming-classes-list',
  templateUrl: './upcoming-classes-list.component.html',
  styleUrls: ['./upcoming-classes-list.component.scss']
})
export class UpcomingClassesListComponent implements AfterViewInit {

  environment = environment;
  displayedColumns: string[] = ['id', 'student', 'teacher', 'formation', 'date', 'status', 'teams'];
  page_size = 10;
  occurrences: Occurrence[] = [];
  resultsLength = 0;
  sessionsStatus = this.sessionsService.getSessionsStatus();
  loaderGenerateLink = false;
  generateLinkID = 0;
  loaderOccurrences = false;

  filterDateFrom = {
    date: null,
    min: 0,
    hrs: 0
  };

  filterDateTo = {
    date: null,
    min: 0,
    hrs: 0
  };

  filterFormGroup = new FormGroup({
    id: new FormControl(),
    student_name: new FormControl(),
    teachers_name: new FormControl(),
    formation_name: new FormControl(),
    start: new FormControl(),
    status: new FormControl(),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private occurrenceService: OccurrenceService,
    private sessionsService: SessionsService,
    private onlineMeetingsService: OnlineMeetingsService,
    private toastService: MzToastService,
    private router: Router
  ) { }

  ngAfterViewInit() {
    if (localStorage.getItem('upcomingClasses') &&
      JSON.parse(localStorage.getItem('upcomingClasses')).id &&
      JSON.parse(localStorage.getItem('upcomingClasses')).student_name &&
      JSON.parse(localStorage.getItem('upcomingClasses')).teachers_name &&
      JSON.parse(localStorage.getItem('upcomingClasses')).formation_name &&
      JSON.parse(localStorage.getItem('upcomingClasses')).start &&
      JSON.parse(localStorage.getItem('upcomingClasses')).status
    ) {
      this.filterFormGroup.setValue(JSON.parse(localStorage.getItem('upcomingClasses')));
    }
    this.getOccurences();
    this.filterFormGroup.valueChanges.debounceTime(1000).subscribe(
      () => {
        localStorage.setItem('upcomingClasses', JSON.stringify(this.filterFormGroup.value));
        this.paginator.pageIndex = 0;
        this.getOccurences();
      }
    )
    this.sort.sortChange.debounceTime(1000).subscribe(
      () => {
        localStorage.setItem('upcomingClasses', JSON.stringify(this.filterFormGroup.value));
        this.paginator.pageIndex = 0;
        this.getOccurences();
      }
    )
    this.paginator.page.debounceTime(1000).subscribe(
      () => {
        localStorage.setItem('upcomingClasses', JSON.stringify(this.filterFormGroup.value));
        this.getOccurences();
      }
    )
  }

  /**
   * Formatage du filtre start__range
   */
  formatDate() {
    if (this.filterDateFrom.date && this.filterDateTo.date) {
      const from = moment(this.filterDateFrom.date).add(this.filterDateFrom.hrs, 'hours').add(this.filterDateFrom.min, 'minutes');
      const to = moment(this.filterDateTo.date).add(this.filterDateTo.hrs, 'hours').add(this.filterDateTo.min, 'minutes');
      return from.format('YYYY') + '-' + from.format('MM') + '-' + from.format('DD') //  + '-' + from.format('HH') + '-' + from.format('mm')
        + ','
        + to.format('YYYY') + '-' + to.format('MM') + '-' + to.format('DD'); // + '-' + to.format('HH') + '-' + to.format('mm');
    } else if (this.filterDateFrom.date) {
      const from = moment(this.filterDateFrom.date).add(this.filterDateFrom.hrs, 'hours').add(this.filterDateFrom.min, 'minutes');
      return from.format('YYYY') + '-' + from.format('MM') + '-' + from.format('DD'); // + '-' + from.format('HH') + '-' + from.format('mm');
    } else {
      return null;
    }
  }

  getOccurences() {
    this.loaderOccurrences = true;
    let params = new HttpParams();
    Object.keys(this.filterFormGroup.controls).forEach(key => {
      if (this.filterFormGroup.get(key).value)
        params = params.append(key, this.filterFormGroup.get(key).value);
    });
    if (this.paginator.pageIndex > 0) {
      params = params.append('page', (this.paginator.pageIndex + 1).toString());
    }
    if (this.formatDate()) {
      params = params.append('start__range', this.formatDate());
    }
    params = params.append('upcoming', 'true');
    params = params.append('page_size', this.paginator.pageSize.toString());
    params = params.append('salt', Math.random().toString());
    params = params.append('ordering', this.sort.direction === 'desc' ? '-' + this.sort.active : this.sort.active);
    this.occurrenceService.getObservableOccurencesList(params).subscribe(
      (data: OccurrenceList) => {
        this.loaderOccurrences = false;
        this.occurrences = data.results;
        this.resultsLength = data.count;
      }
    )
  }

  clearFilter() {
    this.filterFormGroup.reset();
    this.filterDateFrom = {
      date: null,
      min: 0,
      hrs: 0
    };

    this.filterDateTo = {
      date: null,
      min: 0,
      hrs: 0
    };
  }

  generateTeamsLink(element) {
    if (element && !element.provider_link) {
      this.loaderGenerateLink = true;
      this.generateLinkID = element.id;
      this.onlineMeetingsService.generateOnlineMeetings(element).subscribe(occ => {
        this.loaderGenerateLink = false;
        this.getOccurences();
      });
    }
  }

  /**
   * méthode pour copier lien Teams
   * @param val
   */
  copyLink(element) {
    if (element && element.provider_link) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = element.provider_link;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastService.show('Le lien a été ajouté à votre press papier', 2000, 'green');
    } else {
      this.toastService.show('Il n\'y a pas de lien pour le prochain cour', 2000, 'red');
    }
  }

  /**
   * navigation vers un etudiant
   * @param user
   */
  showDetailStudent(user) {
    this.router.navigate(['/students', user.id]);
  }

  /**
   * navigation vers un professeur
   * @param teacher
   */
  showDetailTeacher(teacher) {
    this.router.navigate(['/teachers/admin', teacher.id]);
  }
}
