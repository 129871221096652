import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MzBaseModal } from 'ng2-materialize';
import { Login } from '../../model/login.model';
import { AuthenticationService } from '../../service/authentification.service';
import { UserService } from '../../service/user.service';
import { StudentService } from '../../service/student.service';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
  providers: [AuthenticationService, UserService, StudentService]
})
export class PasswordModalComponent extends MzBaseModal implements OnInit {
  @Input() user;
  @Input() parent;
  @Input() field;
  @Input() callback_variable;
  @Output() outputValidModal = new EventEmitter();
  login: Login;
  public modalOptions: Materialize.ModalOptions = {
    complete: () => { this.callback_variable = false; this.outputValidModal.emit(false) } // Callback for Modal close
  };
  password_generate: boolean;

  constructor(private authenticationService: AuthenticationService, private userService: UserService) {
    super();
  }
  ngOnInit() {
    this.login = new Login();
    this.login.username = localStorage.getItem('persona');
  }

  validAdmin() {
    this.authenticationService.checkIfIsAdmin(this.login)
      .then(res => {
        this.updateUser(this.user, this.field)
      });
  }

  updateUser(user, field) {
    const param = {};
    param[field] = user[field];
    this.userService.patchUser(user.url, param)
      .then(res => {
        this.modalComponent.close();
        this.callback_variable = false;
        this.outputValidModal.emit(false);
      });
  }

}
