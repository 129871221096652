import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-cell-teachers',
  templateUrl: './datatable-cell-teachers.component.html',
  styleUrls: ['./datatable-cell-teachers.component.scss']
})
export class DatatableCellTeachersComponent implements OnInit {

  @Input() teachers;

  constructor() { }

  ngOnInit() {
  }

}
