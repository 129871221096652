import { ReportSheetAssignComponent } from './report-sheet-assign/report-sheet-assign.component';
/**
 * Routing local pour l'edition des rapports.
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
// from module
import { ReportsComponent } from './reports.component';
import { InfoComponent } from './report/info/info.component';
import { Step1Component } from './report/step1/step1.component';
import { Step2Component } from './report/step2/step2.component';
import { Step2firstComponent } from './report/step2first/step2first.component';
import { ListReportComponent } from './list/list.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';

export const routes: Routes = [
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: '', component: ListReportComponent, canActivate: [AuthGuard] },
      { path: 'info/:id', component: InfoComponent, canActivate: [AuthGuard] },
      { path: 'edit/:id', component: Step1Component, canActivate: [AuthGuard] },
      { path: 'edit/:id/step2', component: Step2Component, canActivate: [AuthGuard] },
      { path: 'edit/:id/step2first', component: Step2firstComponent, canActivate: [AuthGuard] },
      { path: 'edit/:id/assignsheet/:occurence_id', component: ReportSheetAssignComponent, canActivate: [AuthGuard] },
    ]
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
