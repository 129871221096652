<ng-container *ngIf="radarTemplate=='page';">
  <ng-container *ngIf="radarDataIsReady">
    <div class="row row-bottom" *ngIf="currentOccurrence">
      <div style="display: block">
        <div class="pagetitle">
            <b><span translate>radar.niveau</span>{{currentOccurrence.start | amDateFormat:'DD/MM/YYYY'}} : 
              <span class="mylevel" ng-if="myLevel">{{myLevel}}</span>
            </b>
        </div>
        <div>
        </div>
        <!-- <div class="training-name" *ngIf="formation && !isBackoffice">
          <b><span translate>radar.formation</span> {{formation.name}}</b>
        </div> -->
        <div class="canvas-wrapper">
          <canvas baseChart
            [options]="radarOptions"
            [datasets]="radarChartData"
            [labels]="radarChartLabels"
            [colors]="chartColors"
            [chartType]="'radar'"
            [legend]="false">
          </canvas>
        </div>
        <div id="slider-container" class="row row-bottom" style="font-size: 0.8rem;" *ngIf="pastOccurrences.length > 1">
          <div id="slider" [style.width.%]="progressionPercent">
            <div class="row" style="margin-bottom: 0;">
              <b class="right indigo-text" style="margin-right: -0.6rem;" translate>radar.actual</b>
            </div>
            <div class="row" style="margin-bottom: 0">
              <div class="slider-downarrow"></div>
            </div>
 
            <div class="grey slider-background" *ngIf="containerSilderSize" [style.width]="containerSilderSize+'px'"></div>
            <mat-slider class="row"
              max='{{maxOccurrence}}'
              min='{{minOccurrence}}'
              value='{{maxOccurrence}}'
              step='{{stepValue}}'
              [(ngModel)]="current_value"
              [style.width.%]="100"
              (ngModelChange)="slideOccurrence2()">
            </mat-slider>
            <!-- <mat-slider class="row"
              max='{{maxOccurrence}}'
              min='{{minOccurrence}}'
              value='{{maxOccurrence}}'
              step='{{stepValue}}'
              [(ngModel)]="current_value"
              [style.width.%]="100"
              (ngModelChange)="slideOccurrence()">
            </mat-slider> -->
          </div>
          <div class="slider-legende" #slideMe>
            <b class="left" translate>radar.start-formation</b>
            <b class="right" translate>radar.end-formation</b>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!radarDataIsReady">
      <div class="pagetitle">
        <b translate>radar.level-none</b>
        <span class="mylevel" ng-if="myLevel">{{myLevel}}</span>
      </div>
      <div *ngIf="formation && isBackoffice">
        <p translate>radar.formation</p> {{formation.name}}
      </div> 
      <div class="row row-bottom">
        <p class="lilate-copy" *ngIf="formation.mode_formation=='standard'" translate>radar.lilate_empty</p>
        <p class="lilate-copy" *ngIf="formation.mode_formation=='lilate'" translate>radar.lilate_empty-product-lilate</p>
      </div>
  </ng-container>

    <div class="row center">
      <button class="lilate-btn" type="button" name="button" [disabled]="!radarDataIsReady" (click)="generateLilate()" translate *ngIf="identityCheck">
        radar.generate
      </button>
      <br />
      <div class="row center" *ngIf="!identityCheck">
        <span *ngIf="!formation.student.user.photo_profile">Pour accéder à votre certificat, vous devez au préalable <span routerLink="../my-photo-profile" class="identityButton">ajouter votre photo d'identité</span> qui sera validé par votre professeur.</span>
        <span *ngIf="formation.student.user.photo_profile?.status=='validation_requested'">Votre photo d'identité est en cours de validation, vous aurez accès à votre certificat une fois la photo validée.</span>
        <span *ngIf="formation.student.user.photo_profile?.status=='refused'">Votre photo d'identité n'est pas valide, merci de contacter le service client pour accèder à votre certificat.</span>
      </div> 
    </div>
  
</ng-container>

<ng-container *ngIf="radarTemplate=='dashboard';">
  <ng-container *ngIf="radarDataIsReady">
    <div class="lilatesum">
      <div class="canvas-overwrapper">
        <div class="canvas-wrapper">
          <canvas baseChart
              [options]="radarOptions"
              [datasets]="radarChartData"
              [labels]="radarChartLabels"
              [colors]="chartColors"
              [chartType]="'radar'"
              [legend]="false">
            </canvas>
          <div class="lilate-grade">{{myLevel}}</div>
        </div>
      </div>
      <div class="lilatesum-right">
        <b>Mon Niveau LILATE</b>
        <!--<button class="lilate-btn" type="button" name="button" [disabled]="!radarDataIsReady" (click)="generateLilate()" translate>
          radar.generate
        </button>-->
        <button class="lilate-detail-btn" type="button" (click)="goToProgression()" translate>
          Voir en détail
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
    
