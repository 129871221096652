import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Template } from '../model/template.model';
import { MzToastService } from 'ng2-materialize';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class TemplateService extends KiwixiService {

    apiTemplate = environment.server + 'template_formations/';

    constructor(private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService,
    ) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getTemplates(): Observable<Template[]> {
        // header
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<Template[]>(
            this.apiTemplate,
            { headers: header }
        )
            .pipe(
                tap(
                    templates => {
                        return templates['results'] as Template
                    }
                ),
                catchError(this.handleErrorObservable('getTemplates', []))
            ).map(
                values => {
                    return values['results'] as Template[]
                }
            )
    }

}
