import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-cell-sites',
  templateUrl: './datatable-cell-sites.component.html',
  styleUrls: ['./datatable-cell-sites.component.scss']
})
export class DatatableCellSitesComponent implements OnInit {
  @Input() sites;
  constructor() { }

  ngOnInit() {
  }

}
