<!-- carousel -->
<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div *ngIf="i === currentSlide" [innerHTML]="slide.html"  class="slide"></div>
  </ng-container>

  <!-- BULLETS POINT -->
  <div class="well text-center dots-wrapper">
    <ul class="list-inline dots">
      <li *ngFor="let slide of slides; let i = index" [ngClass]="{'active': i === currentSlide}"></li>
    </ul>
  </div>
</div>

<!-- CONTROL -->
<div>
  <button class="next-btn" *ngIf="currentSlide!=slides.length-1" (click)="onNextClick()">
      Suivant
  </button>
  <button class="final-btn" *ngIf="currentSlide==slides.length-1" (click)="closeCarousel()" >
      Fermer
  </button>
</div>