import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lang-flag',
  templateUrl: './lang-flag.component.html',
  styleUrls: ['./lang-flag.component.scss']
})
export class LangFlagComponent implements OnInit {
  @Input() language;

  constructor() { }

  ngOnInit() { }
}
