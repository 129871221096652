<div id="listing-recurring">
    <div class="row"></div>
    <div class="row">
    <div class="col left">
        <h5 class="page-title">Liste des Factures</h5>

        </div>

        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button (click)="createExport()" [disabled]="selection.selected.length == 0">
                Paiement
                <!-- <i mz-icon [icon]="'launch'" [align]="'left'" [size]="'tiny'" ></i> -->
                <i class="material-icons tiny left">launch</i>
            </button>
        </div>

        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button routerLink="./export/list">
                Listing Exports
            </button>
        </div>

        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button (click)="export('comptable')" [disabled]="selection.selected.length == 0">
                Export Comptable
                <!-- <i mz-icon [icon]="'launch'" [align]="'left'" [size]="'tiny'" ></i> -->
                <i class="material-icons tiny left">launch</i>
            </button>
        </div> 
    </div>

    <div class="row">
        <h6 class="results-counter">{{ resultsLength }} factures trouvées 
        <span *ngIf="totalAmountRequest > 0">pour un montant total de {{totalAmountRequest | currency:'EUR':'symbol-narrow'}}</span>
        </h6>
    </div>

    <div class="row"></div>

    <div class="row">
        <div class="col l6 left"> 
            <div class="row">
                <div>
                    <button class="select-all-btn" (click)="clearFilter()"> Vider les filtres </button>
                    <button class="select-all-btn" (click)="selectAllPage()">
                        <span *ngIf="modeAllPage==true; else unSelectAll">Déséléctionner toutes les pages</span>
                        <ng-template #unSelectAll>Séléctionner toutes les pages</ng-template>
                    </button>  
                    ligne(s) selectionée(s) : <ng-container *ngIf="modeAllPage==false; else countAll"> <span>{{ selection.selected.length}}</span></ng-container>
                    <ng-template #countAll><span>{{ selection.selected.length }}</span></ng-template>
                </div>
            </div>      
        </div>
        <div class="col l2 right">
                <div [formGroup]="bulkActionFormGroup">
                    <mat-select formControlName="statusBulkUpdate" class="bulk-select" placeholder="Action par lot">
                        <mat-option *ngFor="let status of invoicesStatus"  (click)="bulkUpdate(status.label)" [value]="status.code"> 
                        {{status.label}}
                        </mat-option>
                    </mat-select>
                </div>
        </div>
    </div>


    <div class="row datatable-position">
    <!-- CONTENT PAGE -->
                    <div>
                        <div class="example-loading-shade" *ngIf="isLoadingResults">
                            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        </div>

                        <div [formGroup]="filterFormGroup">
                            
                            <table mat-table [dataSource]="invoices" class="reccurring-table"
                                matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
                                
                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                                        [checked]="selection.hasValue() && isPageSelected()"
                                                        [indeterminate]="selection.hasValue() && !isPageSelected()"
                                                        [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                                        (change)="$event ? selection.toggle(row.id) : null"
                                                        [checked]="selection.isSelected(row.id)"
                                                        [aria-label]="checkboxLabel(row.id)">
                                        </mat-checkbox>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- Checkbox Description Column -->
                                <ng-container matColumnDef="select-description">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                </ng-container>

                                <!-- ID Column -->
                                <ng-container matColumnDef="id">
                                    <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Ref</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.id}}</td>
                                    <td mat-footer-cell *matFooterCellDef> Total Page</td>
                                </ng-container>

                                <!-- ID Description Column -->
                                <ng-container matColumnDef="id-description">
                                    <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="id"/> </th>
                                </ng-container>

                                <!-- invoice_id Column -->
                                <ng-container matColumnDef="invoice_id">
                                    <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Num Facture</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.invoice_id}}</td>
                                    <td mat-footer-cell *matFooterCellDef> Total Page</td>
                                </ng-container>

                                <!-- ID Description Column -->
                                <ng-container matColumnDef="invoice_id-description">
                                    <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="invoice_id"/> </th>
                                </ng-container>

                                <!-- periodicity Column -->
                                <ng-container matColumnDef="periodicity">
                                    <th class="periode-header" mat-header-cell *matHeaderCellDef mat-sort-header>Période</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.periodicity | date: 'MMM yyyy'}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- periodicity Description Column -->
                                <ng-container matColumnDef="periodicity-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="date-input range-left-input" [min]="minDate" [max]="maxDate" (click)="date_from.open()" [matDatepicker]="date_from" formControlName="date_from" placeholder="du">
                                        <mat-datepicker #date_from></mat-datepicker>
                                    
                                        <input class="date-input" [min]="minDate" [max]="maxDate" [matDatepicker]="date_to" (click)="date_to.open()" formControlName="date_to" placeholder="au">
                                        <mat-datepicker #date_to></mat-datepicker>
                                    </th>
                                </ng-container>

                                <!-- teacher Column -->
                                <ng-container matColumnDef="teacher">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Professeur </th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">
                                        <div class="col header-avatar-block">
                                            <img *ngIf="row.teacher_avatar !== null; else elseAvatar" class="user-avatar" src='{{row.teacher_avatar.thumbnail}}' /> 
                                            <ng-template #elseAvatar>
                                                <img class="user-avatar" src="{{defaultAvatar}}">
                                            </ng-template>
                                            
                                        </div>
                                        <div class="col l8 truncate">
                                        {{row.teacher_name}} 
                                        </div>                    
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>

                                </ng-container>

                                <!-- teacher Description Column -->
                                <ng-container matColumnDef="teacher-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="teacher-input" name="filter-id" formControlName="teacher"/>
                                    </th>
                                </ng-container>

                                <!--amount Column -->
                                <ng-container matColumnDef="amount"> 
                                    <th class="amount-header" mat-header-cell *matHeaderCellDef mat-sort-header>Montant</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.amount | currency:'EUR' }}</td>
                                    <td mat-footer-cell *matFooterCellDef> {{getTotalAmountPage() | currency:'EUR'}} </td>
                                </ng-container>

                                <!-- amount Description Column -->
                                <ng-container matColumnDef="amount-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="amount-input range-left-input " name="filter_amount_from" formControlName="amount_from" placeholder="min" />
                                        <input class="amount-input" name="filter_amount_to" formControlName="amount_to" placeholder="max" />
                                    </th>
                                </ng-container>

                                <!--status Column -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Statut</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.status}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- status Description Column -->
                                <ng-container matColumnDef="status-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="status" class="header-select">
                                            <mat-option *ngFor="let status of invoicesStatus"  [value]="status.code">
                                            {{status.label}}
                                            </mat-option>
                                        </mat-select>
                                    </th>
                                </ng-container>

                                <!--uploaded_at Column -->
                                <ng-container matColumnDef="uploaded_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload facture</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.uploaded_at | date: 'dd MMM yyyy'}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- uploaded_at Description Column -->
                                <ng-container matColumnDef="uploaded_at-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="date-input range-left-input" [min]="minDate" [max]="maxDate" (click)="upload_date_from.open()" [matDatepicker]="upload_date_from" formControlName="upload_date_from" placeholder="du">
                                        <mat-datepicker #upload_date_from></mat-datepicker>
                                    
                                        <input class="date-input" [min]="minDate" [max]="maxDate" [matDatepicker]="upload_date_to" (click)="upload_date_to.open()" formControlName="upload_date_to" placeholder="au">
                                        <mat-datepicker #upload_date_to></mat-datepicker>
                                    </th>
                                </ng-container>

                                <!--payment_method Column -->
                                <ng-container matColumnDef="payment_method">
                                    <th mat-header-cell *matHeaderCellDef>Paiement</th>
                                    <td class="invoice-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.payment_method}}</td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- payment_method Description Column -->
                                <ng-container matColumnDef="payment_method-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="paiement" class="header-select">
                                            <mat-option *ngFor="let method of paiementMethod" [value]="method.code">
                                            {{method.label}}
                                            </mat-option>
                                        </mat-select>
                                    </th>
                                </ng-container>

                                 <!-- actions Column -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                                    <td mat-cell *matCellDef="let row">
                                        <ng-container  *ngIf="row.payment_method=='paypal'">
                                            <button class="action-button copy-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="copyText(row.paypal_email)">
                                                Paypal
                                            </button>
                                        </ng-container>
                                        <button class="action-button copy-button btn btn-flat waves-effect waves-light" mz-button [flat]="'true'" (click)="copyText(row.amount)">
                                            {{row.amount}} €
                                        </button>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>

                                <!-- ID Description Column -->
                                <ng-container matColumnDef="actions-description">
                                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                                </ng-container>

                            <tr mat-header-row class="first-line-header" *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-header-row class="second-line-header" *matHeaderRowDef="['select-description',
                                'id-description',
                                'invoice_id-description',
                                'periodicity-description',
                                'teacher-description',
                                'amount-description',
                                'status-description',
                                'uploaded_at-description',
                                'payment_method-description',
                                'actions-description']">
                            </tr>
                            <tr mat-row  *matRowDef="let row; columns: displayedColumns;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

                            </table>
                        </div>

                        <div>
                            <mat-paginator [length]="resultsLength" class="paginator" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
                        </div>
                    </div>
                    <!-- FIN CONTENT PAGE -->
    </div>
</div>