import { UpcomingClassesComponent } from './upcoming-classes/upcoming-classes.component';
import { SheetsComponent } from './sheets/sheets.component';
import { LostPasswordComponent } from './../../feature/lost-password/lost-password.component';
/**
 * Routing des principaux menus de teacher
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// from lingueo
import { PageNotFoundComponent } from '../../feature/page-not-found/page-not-found.component';
import { LoginComponent } from '../../feature/login/login.component';
import { AuthGuard } from '../../guard/auth.guard';

import { DeviceDetectorService } from 'ngx-device-detector';
import { PLATFORM_ID } from '@angular/core';

// from teacher
import { MyaccountComponent } from './myaccount/myaccount.component';
import { LogoutComponent } from '../../feature/logout/logout.component';
import { ResetPasswordComponent } from '../../feature/reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaintenanceGuard } from '../../guard/maintenance.guard';
import { MaintenanceComponent } from '../../feature/maintenance/maintenance.component';

const my_device_service = new DeviceDetectorService(PLATFORM_ID);

export let routes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'login', component: LoginComponent, canActivate: [MaintenanceGuard] },
  { path: 'lost-password', component: LostPasswordComponent, canActivate: [MaintenanceGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [MaintenanceGuard] },
  { path: 'reset-password/:uid/:token', component: ResetPasswordComponent, data: { site: 'teacher' }, canActivate: [MaintenanceGuard] },
  { path: 'myaccount', component: MyaccountComponent, canActivate: [AuthGuard, MaintenanceGuard] },
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [MaintenanceGuard] },
  { path: '**', component: PageNotFoundComponent, canActivate: [MaintenanceGuard] },
  { path: 'sheets', component: SheetsComponent, canActivate: [AuthGuard, MaintenanceGuard] },
];

if (!my_device_service.isDesktop()) {
  routes = [
    { path: 'login', component: LoginComponent, canActivate: [MaintenanceGuard] },
    { path: 'lost-password', component: LostPasswordComponent, canActivate: [MaintenanceGuard] },
    { path: 'logout', component: LogoutComponent, canActivate: [MaintenanceGuard] },
    { path: 'reset-password/:uid/:token', component: ResetPasswordComponent, data: { site: 'teacher' }, canActivate: [MaintenanceGuard] },
    { path: '', component: UpcomingClassesComponent, canActivate: [MaintenanceGuard] },
    { path: '**', component: PageNotFoundComponent, canActivate: [MaintenanceGuard] },
    { path: 'maintenance', component: MaintenanceComponent },
  ]
}


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TeacherRoutingModule { }
