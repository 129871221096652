<div class="header-eval header-eval-off" *ngIf="!showEval" (click)="showEval = !showEval">
  <div class="rate-callto truncate" translate>
    {{translatePath}}.rate-session
  </div>
  <div class="stars-bar">
    <div class="stars-btn" *ngFor="let item of [1,2,3,4,5]; let j = index">
      <div class="star-icon" *ngIf="item <= newValue">
        <div class="fullstar-icon" (click)="newValue = j"></div>
      </div>
      <div class="star-icon" *ngIf="item > newValue">
        <div class="emptystar-icon" (click)="newValue = j+1"></div>
      </div>
    </div>
  </div>
</div>

<div class="header-eval header-eval-on" *ngIf="showEval">
  <div class="rate-callto truncate" translate>
    {{translatePath}}.rate-session
  </div>
  <div class="stars-bar">
    <div class="stars-btn" *ngFor="let item of [1,2,3,4,5]; let j = index">
      <div class="star-icon" *ngIf="item <= newValue">
        <div class="fullstar-icon" (click)="newValue = j"></div>
      </div>
      <div class="star-icon" *ngIf="item > newValue">
        <div class="emptystar-icon" (click)="newValue = j+1"></div>
      </div>
    </div>
  </div>
</div>
<div class="" *ngIf="showEval">
  <textarea name="name" rows="4" cols="80" class="detailedcomment" placeholder="{{'lingueopro.dashboard.past_sessions.en_detail' | translate}}"
    [(ngModel)]="newMessage"></textarea>
  <div class="">
    <button class="switchable-btn sendeval-btn" type="button" name="button" [disabled]='!shareIsValid(newValue, newShortDescription)'
      (click)="shareEvaluation(newValue, newShortDescription, newMessage)" translate>{{translatePath}}.share-evaluation</button>
  </div>
</div>
