import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import 'rxjs/';

@Injectable()
export class DownloadLingueoService extends KiwixiService {
  apiDocumentationDownloadURL = environment.server + 'documents/documentation/';
  apiDocumentAdministratifDownloadURL = environment.server + 'documents/administratif/';


  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  downloadDoc(_url, _filename): Observable<any> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get(_url, { headers: header, responseType: 'arraybuffer' })
  }

  downloadFile(url, params) {
    const header = this.createHttpClientAuthorizationHeader();
    this._http.get(url, { headers: header, params: params, responseType: 'arraybuffer' })
      .subscribe((file: ArrayBuffer) => {
        const myFile = new Uint8Array(file);
        const blob = new Blob([myFile], { type: 'application/force-download' });
        const _url = window.URL.createObjectURL(blob);
        const $a = $('<a />', {
          'href': _url,
          'download': params.get('filename'),
          'text': 'click'
        }).hide().appendTo('body')[0].click();
      });
  }

  downloadTemplate(params) {
    this.downloadFile(this.apiDocumentationDownloadURL, params);
  }

  downloadDocumentAdministratif(params) {
    this.downloadFile(this.apiDocumentAdministratifDownloadURL, params);
  }
}
