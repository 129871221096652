import { FormationOptions } from './formationOptions.model';
import { ExerciceSpecificContent } from './exerciceSpecificContent.model';
import { Session } from './session.model';
import { Student } from './student.model';
import { Language } from './language.model';
import { Teacher } from './teacher.model';
import { Site } from './site.model';
import { Staff } from './staff.model';
import { Recurrence } from './recurrence.model';
import { Progression } from './progression.model';
import { Event } from './event.model';

export class HoursByTeachers {
  teacher_id: number;
  teacher_detail: Teacher;
  nb_hours: number;
}

export class Formation {
  id: number;
  id_form: string;
  url: string;
  updated_at: string;
  created_at: string;
  name: string;
  mode_financement: string;
  status: string;
  hours_remaining: number;
  hours_total: any;
  validity_period_from: Date;
  validity_period_to: Date;
  start_from: Date;
  notes_to_teachers: string;
  // site: string;
  site: Site;
  // responsable: Staff;
  sessions: Session[];
  student: Student;
  // student: string;
  manager: Staff;
  // language: string;
  language: string;
  language_detail: Language;
  teachers: Teacher[];
  active_teachers: Teacher[];
  recurrences: Recurrence[];
  progression: Progression;
  next_occurrence: string;
  next_session: Session;
  niveau: string;
  events: Event[];
  calendar: string;
  hours_to_plan: number;
  hours_planned: number;
  hours_consummed: number;
  hours_by_teachers: HoursByTeachers[];
  specific_content: ExerciceSpecificContent[];
  nb_report_by_student: number;
  booking_mode: string;
  mode_formation: string;
  trial_mode: string;
  is_stall: string;
  global_rate: string;
  options: FormationOptions[];
  sf_id: string;
  is_certificate_cdc_exported: boolean;
  constructor() { }
}
