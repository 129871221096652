/**
 * Sous composant pour gérer les informations générales à la création d'une formation
 */

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import * as moment from 'moment';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

// models
import { Formation } from '../../../../../model/formation.model';
import { Student } from '../../../../../model/student.model';
import { Staff } from '../../../../../model/staff.model';
import { Site } from '../../../../../model/site.model';
import { User } from '../../../../../model/user.model';
import { Language } from '../../../../../model/language.model';

// services
import { FormationService } from '../../../../../service/formation.service';
import { StudentService } from '../../../../../service/student.service';
import { StaffService } from '../../../../../service/staff.service';
import { SiteService } from '../../../../../service/site.service';
import { UserService } from '../../../../../service/user.service';
import { LanguageService } from '../../../../../service/language.service';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { TemplateService } from '../../../../../service/template.service';
import { Template } from '../../../../../model/template.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-new-formation-information',
  templateUrl: './new-formation-information.component.html',
  styleUrls: ['./new-formation-information.component.scss', '../../mainscreen.component.scss', './new-formation-information.ux.scss', '../../mainscreen.ux.scss'],
  providers: [StudentService]
})
export class NewFormationInformationComponent implements OnInit {

  // variables du composant parent :
  @Input() component: any;
  @Input() newFormation;
  @Output() newFormationOutput = new EventEmitter();
  myUser: User;
  originalFormation: Formation;
  studentNotFound: boolean;
  responsables: Staff[];
  filteredResponsablesList = [];
  levels = [];
  studentEmail = '';
  sites: Site[] = [];
  createFormationPageIsReady: boolean;
  defaultAvatar = environment.static + 'avatar.png';
  newStudent = false;
  siteSelected: Site;
  myHoursTotal = 0;
  listModeFinancement: any;

  // language
  languages: Language[];
  languageControl: FormControl = new FormControl();
  filteredLanguages: Observable<Language[]>;
  myLanguage: {};

  // sites
  sitesControl: FormControl = new FormControl();

  // manager
  managerControl: FormControl = new FormControl();
  filteredManager: Observable<Staff[]>;
  myManager: {};

  // template de formation
  templates: Template[] = [];
  templateControl: FormControl = new FormControl();
  filteredTemplate: Observable<Template[]>;
  myTemplate: {};

  myForm: FormGroup = this.builder.group({
    language: this.languageControl,
    template: this.templateControl,
    manager: this.managerControl,
    site: this.sitesControl
  });

  constructor(
    private cdRef: ChangeDetectorRef,
    private studentService: StudentService,
    private userService: UserService,
    private staffService: StaffService,
    private siteService: SiteService,
    private languageService: LanguageService,
    private kiwixiGlobals: KiwixiGlobals,
    private formationService: FormationService,
    private templateService: TemplateService,
    private builder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.listModeFinancement = this.formationService.getModeFinancement();
    this.kiwixiGlobals.currentStudentIdToNewFormation.subscribe(_userId => {
      if (_userId !== null) {
        this.userService.getUser(_userId)
          .then(_user => {
            this.myUser = _user;
            this.studentEmail = this.myUser.email
          })
      }
    });
    this.computeResponsables();

    // get template formation
    this.templateService.getTemplates().subscribe(
      data => {
        // on declare les form control comme il faut.
        this.templates = data;
        if (this.templates) {
          this.filteredTemplate = this.templateControl.valueChanges
            .startWith(null)
            .map(val => val ? this.filterTemplate(val) : this.templates);
        }
      },
      error => { }
    )

    this.levels = this.kiwixiGlobals.levels;
    this.getSites();
    // init languages combo
    this.filteredLanguages = this.languageControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterLanguages(val) : this.languages);

    // init manager combo
    this.filteredManager = this.managerControl.valueChanges
      .startWith(null)
      .map(val => val ? this.filterManager(val) : this.responsables);
    this.kiwixiGlobals.currentStaff.subscribe(value => this.newFormation.manager = value)

    this.onChanges();
  }

  onChanges(): void {
    this.templateControl.valueChanges.subscribe(val => {
      this.newFormation.name = val;
      this.validate();
    });

    this.languageControl.valueChanges.subscribe(val => {
      this.myLanguage = val;
      this.updateUserLanguage();
    });

    this.managerControl.valueChanges.subscribe(val => {
      this.selectResponsable(val);
    });

    this.sitesControl.valueChanges.subscribe(val => {
      this.siteSelected = val;
      this.checkStudentWithSiteSelected();
    });
  }

  filterTemplate(value) {
    return this.templates.filter(state =>
      state.label.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  }

  // Methode pour disable les date du calendrier End à partir du from
  toDateFilter = (d: Date): boolean => {
    moment(d)
    // compute min between from and now
    let from;
    if (moment().isAfter(moment(this.newFormation.validity_period_from))) {
      from = moment(moment(), 'DD/MM/YYYY');
    } else {
      from = moment(moment(this.newFormation.validity_period_from), 'DD/MM/YYYY');
    }
    const to = moment(d);
    // Prevent Saturday and Sunday from being selected.
    return to.isSameOrAfter(from);
  }

  // Filtrer le resposable
  filterManager(val: string): any[] {
    if (typeof (val) === 'object') {
      return this.responsables;
    }
    return this.responsables.filter(option => {
      if (option.user.first_name) {
        return (option.user.first_name.toLowerCase().indexOf(val.toLowerCase()) >= 0 ||
          option.user.last_name.toLowerCase().indexOf(val.toLowerCase()) >= 0
        )
      } else {
        return option.user.email.toLowerCase().indexOf(val.toLowerCase()) >= 0;
      }
    })
  }

  // Afficher le Prénom et Nom du responsable
  displayManagerFn(manager) {
    if (manager) {
      return manager.user.first_name ? manager.user.first_name + ' ' + manager.user.last_name : manager.user.email;
    }
    return '';
  }

  // ----- Languages ----
  filterLanguages(val: string): Language[] {
    return this.languages.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  displayLanguageFn(language) {
    return language ? language.label : language;
  }
  updateUserLanguage() {
    this.newFormation.language = this.myLanguage ? this.myLanguage['url'] : null;
  }

  /**
   * Récupérer la liste des sites
   */
  getSites() {
    this.sites = [];
    this.siteService.getObservableSites()
      .subscribe(_sites => {
        if (_sites) {
          for (const site of _sites['results']) {
            if (site.available_formation) {
              this.sites.push(site);
            }
          }
          this.getLanguages()
          this.siteService.dataSites = _sites['results']
        }
      },
        error => {
          console.error(error);
        })
  }

  /**
   * Récupérer les languages.
   */
  getLanguages() {
    this.languages = [];
    this.languageService.getLanguages({})
      .then(_languageData => {
        for (const language of _languageData['results']) {
          this.languages.push(language)
        }
        this.createFormationPageIsReady = true;
      });
  }

  /**
   * Methode pour préremplir le formulaire.
   */
  fakeNewFormation() {
    this.studentEmail = 'simon.cql@gmail.com';
    this.getUserFromEmail(this.studentEmail);
    this.newFormation.name = 'CreateFormationSimon';
    this.newFormation.niveau = '1';
    this.newFormation.hours_total = 10;
    this.newFormation.validity_period_from = new Date(Date.now());
    this.newFormation.validity_period_to = new Date('12/30/2018');
  }

  /**
   * update the current formation from the selection of one template.
   * remplace la saisie utilisateur
   * @param template
   */
  updateFormationFromTemplate(template) {
    this.newFormation.name = template.label;
    this.newFormation.hours_total = template.duree;
    this.newFormation.language = template.language;
    // update the language combobox
    const idx = this.languages.map(lang => lang.url).indexOf(template.language);

    if (idx >= 0) {
      this.myLanguage = this.languages[idx];
    }
  }

  // Si on valide un template de nom de formation....
  validate() {
    if (this.templates) {
      for (const _template of this.templates) {
        if (_template.label === this.newFormation.name) {
          this.updateFormationFromTemplate(_template)
        }
      }
      this.languageControl.setValue(this.myLanguage);
    }
  }

  /**
   * Méthode pour récupérer un user à partir de l'email.
   * @param userEmail
   */
  getUserFromEmail(userEmail) {
    if (userEmail.length > 1) {
      const _filterParams = []
      _filterParams['filtering'] = { 'email': userEmail, 'is_student': true };
      this.userService.getUsers(_filterParams)
        .then(_users => {
          if (_users['count'] === 0) {
            this.studentNotFound = true;
            this.newFormation.student = null;
          } else {
            this.studentNotFound = false;
            this.myUser = _users['results'][0];
          }
        })
    }
  }

  // On verifie si le student sélectionné existe sur le site sélectionné.
  checkStudentWithSiteSelected() {
    if (this.myUser !== undefined) {
      let _studentExist = false;
      let count = 0;
      for (let _i = 0; _i < this.myUser['students'].length; _i++) {
        count++;
        const myStudent = this.myUser['students'][_i];
        this.studentService.getStudentWithUrl(myStudent)
          .then(_student => {
            if (this.siteSelected) {
              // s'il existe on l'ajoute à la formation.
              if (_student.site === this.siteSelected.url) {
                this.newFormation.student = _student;
                _studentExist = true;
                this.newStudent = false;
                this.checkIfFormationChange();
              }
              if (count === this.myUser['students'].length) {
                // s'il n'existe pas on crée un nouveau student.
                if (!_studentExist) {
                  this.newFormation.student = new Student();
                  this.newFormation.student.user = this.myUser;
                  this.newFormation.student.site = this.siteSelected.url;
                  this.newStudent = true;
                  this.checkIfFormationChange();
                }
              }
            }
          })
          .catch(error => {
            console.error('error Student service', error);
            // this.studentService.logout();
          })
      }
    }
  }

  /**
   * Méthode pour récupérer la liste des responsables.
   */
  computeResponsables() {
    this.staffService.getStaffs()
      .then(
        _responsables => {
          this.responsables = _responsables;
        }
      );
  }

  // Filtrer les responsables.
  filterResponsable() {
    if (this.newFormation.manager.user.username !== '') {
      this.filteredResponsablesList = this.responsables.filter(function (el) {
        return el.user.username.toLowerCase().indexOf(this.newFormation.manager.user.username.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredResponsablesList = [];
    }
  }

  // Sélectionner les responsables.
  selectResponsable(item) {
    this.filteredResponsablesList = [];
    this.newFormation.manager = item;
  }

  /**
   * Méthode pour construire la nouvelle formation
   * @param isNewFormation
   */
  createFormation(isNewFormation) {
    this.newFormation.site = this.siteSelected.url;
    this.newFormation.hours_remaining = this.newFormation.hours_total;
    if (this.newStudent) {
      this.studentService.postStudent(this.newFormation.student)
        .then(_newStudent => {
          this.newFormation.student = _newStudent
          this.postNewFormation(this.newFormation, isNewFormation)
        })
    } else {
      this.postNewFormation(this.newFormation, isNewFormation)
    }
  }

  /**
   * Méthode pour Poster la nouvelle formation.
   * @param _param
   * @param _isNewFormation
   */
  postNewFormation(_param, _isNewFormation) {
    if (_isNewFormation) {
      this.formationService.postFormation(_param)
        .then(_formation => {
          // this.createOriginalFormation();
          // this.newFormation = _formation;
          // // this.component.showRecurrence = true;
          // this.newFormationOutput.emit(this.newFormation);
          this.router.navigate(['/formations', _formation.id]);
        });
    } else {
      this.formationService.patchFormation(this.newFormation.url, _param)
        .then(_formation => {
          // this.createOriginalFormation();
          // this.newFormation = _formation;
          // //this.component.showRecurrence = true;
          // this.newFormationOutput.emit(this.newFormation);
          this.router.navigate(['/formations', _formation.id]);
        });
    }
  }

  // Méthode pour figer la première version de la nouvelle formation et voir s'il ya des mises à jour
  createOriginalFormation() {
    this.originalFormation = new Formation();
    this.originalFormation.hours_remaining = this.newFormation.hours_remaining;
    this.originalFormation.niveau = this.newFormation.niveau;
    this.originalFormation.hours_total = this.newFormation.hours_total;
    this.originalFormation.name = this.newFormation.name;
    this.originalFormation.sf_id = this.newFormation.sf_id;
    this.originalFormation.validity_period_from = this.newFormation.validity_period_from;
    this.originalFormation.validity_period_to = this.newFormation.validity_period_to;
    this.originalFormation.student = this.newFormation.student;
    this.originalFormation.manager = this.newFormation.manager;
  }

  // Controller pour le bouton de création.
  newFormationIsReady() {
    if (
      this.siteSelected &&
      this.newFormation.student &&
      this.newFormation.name &&
      this.newFormation.sf_id &&
      this.newFormation.language &&
      this.newFormation.mode_financement &&
      // this.newFormation.niveau &&
      this.newFormation.hours_total &&
      this.newFormation.validity_period_from &&
      this.newFormation.validity_period_to &&
      (this.newFormation.manager && this.newFormation.manager.url)
    ) {
      return true
    }
    return false
  }

  // Méthode pour verifier si la formation à changer pour mise à jour.
  checkIfFormationChange() {
    if (this.originalFormation) {
      if (
        this.siteSelected.url !== this.originalFormation.student.site ||
        this.newFormation.student.url !== this.originalFormation.student.url ||
        this.newFormation.name !== this.originalFormation.name ||
        this.newFormation.sf_id !== this.originalFormation.sf_id ||
        this.newFormation.language !== this.originalFormation.language ||
        this.newFormation.niveau !== this.originalFormation.niveau ||
        this.newFormation.hours_total !== this.originalFormation.hours_total ||
        this.newFormation.validity_period_from !== this.originalFormation.validity_period_from ||
        this.newFormation.validity_period_to !== this.originalFormation.validity_period_to ||
        this.newFormation.manager !== this.originalFormation.manager
      ) {
        this.component.showRecurrence = false;
        this.cdRef.detectChanges();
        return true
      }
    }
    return false
  }

  // Evenement au focus out sur l'input heure total.
  focusOutHoursTotal() {
    if (typeof this.newFormation.hours_total === 'number') {
      this.checkIfFormationChange()
    }
  }

  // Evenement au focus out sur l'input du responsable.
  checkInputManager() {
    if (!this.newFormation.manager.url) {
      this.newFormation.manager = null;
    }
  }

}
