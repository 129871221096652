import { AssignedSheetDetailComponent } from './assign-sheet/assigned-sheet-detail/assigned-sheet-detail.component';
import { SheetDetailComponent } from './sheet-detail/sheet-detail.component';
import { AssignSheetComponent } from './assign-sheet/assign-sheet.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
// from module
import { ListComponent } from './list/list.component';
import { RescheduleComponent } from './reschedule/reschedule.component'
import { UpcomingClassesComponent } from './upcoming-classes.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';

export const routes: Routes = [
  {
    path: 'upcoming',
    component: UpcomingClassesComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    children: [
      { path: '', component: ListComponent, canActivate: [AuthGuard] },
      { path: 'reschedule/:id', component: RescheduleComponent },
      { path: 'sheets/sheet/:id/student/:student_id', component: SheetDetailComponent },
      { path: 'assign-study-sheet/occurrence/:id', component: AssignSheetComponent },
      { path: 'assign-study-sheet/occurrence/:id/sheets/sheet/:id', component: AssignedSheetDetailComponent },
    ]
  }
  // { path: '**', component: PageNotFoundComponent }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class UpcomingClassesRoutingModule { }
