import { Conversation } from './../model/conversation.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { tap, catchError } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { Message } from '../model/message.model';
import { Document } from '../model/document.model';
import { ConversationList } from '../model/conversationList.model';

@Injectable()
export class ConversationService extends KiwixiService {

  api = environment.server + 'messages/';
  apiConversation = environment.server + 'conversations/';
  apimessage = environment.server + 'messages/';
  public conversationList: BehaviorSubject<Conversation[]> = new BehaviorSubject<Conversation[]>([]);


  constructor(
    private _http: HttpClient,
    public toastService: MzToastService,
    public router: Router,
    public kiwixiGlobals: KiwixiGlobals
  ) {
    super(_http, router, kiwixiGlobals, toastService)
  }


  getConversations(params) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<ConversationList>(this.apiConversation, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<ConversationList>(`geConversationList`))
      );
  }

  getConversationById(id) {
    const header = this.createHttpClientAuthorizationHeader();
    const url = this.apiConversation + id + '/';
    return this._http.get(url, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable(`getMessges formation manager`))
      );
  }

  /**
   *
   * @param formation url
   * @param student url
   * @param manager url
   */
  getMessageFormationManager(formation, student, manager, page = null) {
    // check str vs id
    if (typeof (formation) === 'string') {
      formation = this.extractIdFromUrl(formation);
    }
    if (typeof (student) === 'string') {
      student = this.extractIdFromUrl(student);
    }
    if (typeof (manager) === 'string') {
      manager = this.extractIdFromUrl(manager);
    }
    if (page === null) {
      page = 1
    }
    const header = this.createHttpClientAuthorizationHeader();
    const params = new HttpParams()
      .set('formation', formation)
      .set('student', student)
      .set('staff', manager)
      .set('page', page)
      .set('ordering', '-created_at')

    return this._http.get<Message[]>(this.api, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Message[]>(`getMessges formation manager`))
      );

  }

  /**
   *
   * @param formation url
   * @param student url
   * @param manager url
   */
  getUserMessages(_formation, _role, _to, page = null) {
    if (_formation && typeof (_formation) === 'string') {
      _formation = this.extractIdFromUrl(_formation);
    }
    if (typeof (_to) === 'string') {
      _to = this.extractIdFromUrl(_to);
    }
    if (page === null) {
      page = 1
    }
    const header = this.createHttpClientAuthorizationHeader();
    let params = new HttpParams();
    if (_formation) {
      params = params.append('formation', _formation);
    }
    params = params.append(_role, _to);
    params = params.append('ordering', '-created_at');
    params = params.append('page', page);
    return this._http.get<Message[]>(this.api, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Message[]>(`getUserMessages`))
      );

  }

  getUserMessagesByConversationId(conversationId, page = null) {
    if (page === null) {
      page = 1
    }
    const header = this.createHttpClientAuthorizationHeader();
    let params = new HttpParams();
    params = params.append('conversation', conversationId);
    params = params.append('ordering', '-created_at');
    params = params.append('page', page);
    return this._http.get<Message[]>(this.api, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Message[]>(`getUserMessages`))
      );
  }

  getUserMessagesByUrl(url) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Message[]>(url, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Message[]>(`getUserMessages`))
      );
  }

  getMultipleUsersMessages(_role, users) {
    const obx = [];
    for (const user of users) {
      obx.push(this.getUserMessages(null, _role, user.url, 1))
    }
    return forkJoin<Message[]>(obx)
  }

  /**
   *
   * @param formation
   * @param student
   * @param teacher
   */
  getMessageFormationTeacher(formation, student, teacher, page = null) {
    // check str vs id
    if (typeof (student) === 'string') {
      student = this.extractIdFromUrl(student);
    }
    if (typeof (teacher) === 'string') {
      teacher = this.extractIdFromUrl(teacher);
    }
    if (page === null) {
      page = 1
    }
    const header = this.createHttpClientAuthorizationHeader();
    const params = new HttpParams()
      .set('formation', formation)
      .set('student', student)
      .set('teacher', teacher)
      .set('page', page)
      .set('ordering', '-created_at')
    return this._http.get<Message[]>(this.api, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Message[]>(`getMessges formation teacher`))
      );

  }

  /**
   * @param message the text message
   * @param formation_id
   * @param from
   * @param to
   */
  addMessage(message, from, to, documents = []) {
    const header = this.createHttpClientAuthorizationHeader();
    const body = {
      content: message,
      from_user: from,
      to: to,
      documents: documents
    }
    return this._http.post(this.api, body, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Message[]>(`Envoi de message`))
    );
  }

  /**
   * @param message the text message
   * @param formation_id
   * @param from
   * @param to
   */
  postNewMessage(conversationId, message, from, to, documents = []) {
    const header = this.createHttpClientAuthorizationHeader();
    const body = {
      conversation_id: conversationId,
      content: message,
      from_user: from,
      to: to,
      documents: documents
    }
    if (from && from !== to) {
      return this._http.post(this.api, body, {
        headers: header
      }).pipe(
        catchError(this.handleErrorObservable<Message[]>(`Envoi de message`))
      );
    } else {
      catchError(this.handleErrorObservable<Message[]>(`Verifier from vs to`))
    }
  }

  /**
 * @param message the text message
 * @param formation_id
 * @param from
 * @param to
 */
  addMessageToTeacher(message, from, to, documents = []) {
    const header = this.createHttpClientAuthorizationHeader();
    const body = {
      content: message,
      from: from,
      to: to,
      documents: documents
    }
    return this._http.post(this.api, body, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Message[]>(`Envoi de message`))
    );
  }


  private sendDocument(file, formation, from, to) {
    const apiDocument = environment.server + 'documents/';
    const header = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    header.delete('Content-Type');
    _formData.append('source', file);
    _formData.append('name', file.name);
    _formData.append('formation', formation);
    _formData.append('from', from);
    _formData.append('to', to);
    return this._http.post(apiDocument, _formData, {
      headers: header
    }).pipe(
      tap(n => {
      }),
      catchError(this.handleErrorObservable<Message[]>(`Envoi de fichier`))
    )
  }

  public sendMessageDocuments(files, formation, from, to) {
    const obx = [];
    for (const file of files) {
      if (file.size < environment.maxFileSize) {
        obx.push(this.sendDocument(file, formation, from, to))
      } else {
        this.toastService.show(environment.fileSizeAlert, 2000, 'red');
      }
    }
    return forkJoin<Document>(obx).map(
      resDocs => {
        return resDocs.map(val => val.url);
        // return this.addMessage('doc en pj', formation, from, to, resDocs.map(val => val.url)).subscribe()
      }
    )
  }

  isRead(message, _user) {
    if (message.to === _user && !message.opened) {
      this.patchMessage(message.url, { opened: true })
        .then(_message => {
          message.opened = true;
        })
    }
  }

  patchMessage(messageUrl, param): Promise<Message> {
    this.headers = this.setHeader();
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(messageUrl, param, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(err => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
      })
  }
}
