import { ExerciceMedia } from './exerciceMedia.model';
import { ExerciceQuestion } from './exerciceQuestion.model';
import { ExerciceSheet } from './exerciceSheet.model';
import { ExerciceCategory } from './exerciceCategory.model';
import { ExerciceTag } from './exerciceTag.model';
export class Exercice {
    id: number;
    url: string;
    instruction: string;
    difficulty: string;
    cecrl_level: string;
    category: ExerciceCategory;
    sheets: ExerciceSheet[];
    questions: ExerciceQuestion[];
    tags: ExerciceTag[];
    duration: number;
    nb_questions: number;
    media: ExerciceMedia;
    is_validate_by_student: boolean;
    status: string;
    updated_at: Date;
    created_at: Date;
}
