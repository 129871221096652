<div id="formation-export-recurring">
    <div class="row">
        <mat-spinner *ngIf="isLoadingCheck"></mat-spinner>
    </div>
    <div class="row">
        <div class="col left">
            <h5 class="page-title">Listing des erreurs de l'export n° {{export.id}}</h5>
        </div>
        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button [routerLink]="['/export/cdc/list']">
                Listing Exports
            </button>
        </div>
    </div>

    <div class="row datatable-position header" *ngIf="!isLoadingCheck">
        Erreurs à corriger : {{ nbErrors }}
        <div *ngIf="nbErrors>0" class="error">Le fichier n'a pas pu être généré il reste des
            erreurs</div>
    </div>

    <div *ngIf="nbErrors>0" class="row">
        <div class="mat-elevation-z8">
            <table  mat-table [dataSource]="dataSource" MatSort class="formation-table">
                <ng-container matColumnDef="formationID">
                    <th mat-header-cell *matHeaderCellDef> ID Formation. </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="student">
                    <th mat-header-cell *matHeaderCellDef> Eleve </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.student}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="formation">
                    <th mat-header-cell *matHeaderCellDef> Formation </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="errors">
                    <th mat-header-cell *matHeaderCellDef> Erreurs </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let error of element.is_valid_export_certifications_cdc.errors">
                            {{ error }}
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource.data.length==0" class="no-records">
                No records found
            </div>
        </div>
    </div>

</div>