import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ExerciceSpecificContentService } from './../../../../../../service/exerciceSpecificContent.service';
import { ExerciceSheet } from './../../../../../../model/exerciceSheet.model';
import { ExerciceSheetService } from './../../../../../../service/exerciceSheet.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../../../../../service/language.service';
import { ExerciceCategoryService } from '../../../../../../service/exerciceCategory.service';
import { Language } from '../../../../../../model/language.model';
import { ExerciceCategory } from '../../../../../../model/exerciceCategory.model';
import { Router } from '@angular/router';
import { ExerciceSpecificContent } from '../../../../../../model/exerciceSpecificContent.model';

@Component({
  selector: 'app-sheet-information',
  templateUrl: './sheet-information.component.html',
  styleUrls: ['./sheet-information.component.scss', '../../../formation/informations/informations.ux.scss', '../../../formation/formation.ux.scss', '../../../mainscreen.ux.scss']
})
export class SheetInformationComponent implements OnInit {
  @Input() exerciceSheet: ExerciceSheet;
  languageList: Language[] = [];
  categoryList: ExerciceCategory[] = [];
  groupSpecificList: ExerciceSpecificContent[] = [];
  selectedGroupSpecificList: ExerciceSpecificContent[] = [];
  cecrlLevelList = [];
  filteredGroupSpecific: Observable<ExerciceSpecificContent[]>;

  sheetFormGroup = new FormGroup({
    title_fr: new FormControl('', Validators.required),
    language: new FormControl('', Validators.required),
    cecrl_level: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    specific_content: new FormControl(),
    pdf: new FormControl(),
  });

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private exerciceCategoryService: ExerciceCategoryService,
    private exerciceSheetService: ExerciceSheetService,
    private exerciceSpecificContentService: ExerciceSpecificContentService,
  ) { }

  ngOnInit() {

    if (this.exerciceSheet) {
      this.loadData();
    }
    this.languageService.getObservableLanguages().subscribe(data => {
      if (data['results']) {
        this.languageList = data['results'];
      }
    });
    this.exerciceCategoryService.getCategories(null).subscribe(data => {
      if (data['results']) {
        this.categoryList = data['results'];
      }
    });
    this.exerciceSpecificContentService.getExercicesSpecificContent(null).subscribe(data => {
      if (data['results']) {
        this.groupSpecificList = data['results'];
      }
    });

    // initialisation variable pour afficher la liste filtree des groupes spécifiques.
    this.filteredGroupSpecific = this.sheetFormGroup.controls.specific_content.valueChanges
      .startWith('')
      .map(val => this.groupSpecificList.filter(function (el) {
        return el.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
      }.bind(this)));

    this.cecrlLevelList = this.exerciceSheetService.getcecrlLevel();
  }

  loadData() {
    this.sheetFormGroup.controls.title_fr.setValue(this.exerciceSheet.title_fr);
    this.sheetFormGroup.controls.language.setValue(this.exerciceSheet.language.url);
    this.sheetFormGroup.controls.cecrl_level.setValue(this.exerciceSheet.cecrl_level);
    this.sheetFormGroup.controls.category.setValue(this.exerciceSheet.category.url);
    this.selectedGroupSpecificList = this.exerciceSheet.specific_content;
  }

  createSheet() {
    const forms = this.sheetFormGroup.controls;
    const _params = {
      title_fr: forms.title_fr.value,
      language: forms.language.value,
      cecrl_level: forms.cecrl_level.value,
      category: forms.category.value,
    };

    this.exerciceSheetService.postExerciceSheet(_params).subscribe(sheetCreated => {
      if (sheetCreated.id) {
        this.router.navigate(['/exercices/sheet-edit', sheetCreated.id]);
      }
    });
  }

  updateSheet(field) {
    if (this.exerciceSheet) {
      const fieldControl = this.sheetFormGroup.get(field);
      const _params = {
        [field]: fieldControl.value
      };
      this.exerciceSheetService.patchExerciceSheet(this.exerciceSheet.id, _params).subscribe(result => {
      });
    }
  }

  updateFromChips(_data, myObject) {
    const listToUpdate = [];
    _data.map(chip => listToUpdate.push(chip.url))
    const _params = {
      'specific_content': listToUpdate
    };
    this.exerciceSheetService.patchExerciceSheet(this.exerciceSheet.id, _params).subscribe(result => {
    });
  }

  uploadFile($event) {
    for (const file of $event.target.files) {
      this.exerciceSheetService.patchFile(this.exerciceSheet, file).subscribe(exerciceSheet => {
        if (exerciceSheet && exerciceSheet.id) {
          this.exerciceSheet = exerciceSheet;
        }
      })
    }
  }

}