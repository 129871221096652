/**
 * Composant pour l'affichage des invoices
 * Intègre des directive pour l'affichage d'un invoice vs current-invoice
 */

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InvoiceService } from '../../../../service/invoice.service';
import { HttpParams } from '@angular/common/http';
import { DownloadLingueoService } from './../../../../directive/download-lingueo/download-lingueo.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss', '../billing.component.scss']
})
export class ListComponent implements OnInit {
  versionSelect: any;
  myTeacher;
  pageIsReady;
  isDesktop;
  countPages: number;
  invoices: any[] = [];
  totalData = 0;
  throttle = 1000; // temps accordé avant de fire l'event de scoll
  scrollDistance = 1; // % restant à partir duquel on fire l'event 1 = 10 %.

  constructor(
    private authenticationService: AuthenticationService,
    private invoiceService: InvoiceService,
    private deviceService: DeviceDetectorService,
    private downloadService: DownloadLingueoService) {
    this.countPages = 1;
    this.versionSelect = true
    this.myTeacher = null;
    this.pageIsReady = false;
    this.isDesktop = this.deviceService.isDesktop()
  }

  ngOnInit() {
    this.myTeacher = this.authenticationService.getCurrentPersona();
    if (this.myTeacher) {
      this.getTeacherInvoices(this.countPages);
    }
  }

  onScrollDown() {
    if (this.lazyIsAvailable()) {
      this.countPages++;
      this.getTeacherInvoices(this.countPages);
    }
  }

  lazyIsAvailable() {
    if ((this.countPages * 10) < this.totalData) {
      return true;
    }
    return false;
  }

  downloadInvoiceTemmplate(templateName) {
    let params = new HttpParams();
    params = params.append('filename', templateName);
    this.downloadService.downloadTemplate(params);
  }

  /**
   * Methode pour récupérér les factures d'un prof.
   * @param _countPages
   */
  getTeacherInvoices(_countPages) {
    let params = new HttpParams();
    params = params.append('teacher', this.myTeacher.id);
    params = params.append('page', _countPages.toString());
    this.invoiceService.getObservableInvoices(params)
      .subscribe(_invoices => {
        if (_invoices) {
          for (const _invoice of _invoices['results']) {
            this.invoices.push(_invoice)
          }
          this.totalData = _invoices['count'];
          this.pageIsReady = true;
        } else {
          this.pageIsReady = true;
        }
      })
  }

}
