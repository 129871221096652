<div id="listing-recurring">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
            <h5 class="page-title">Création d'un export paiement professeurs</h5>
        </div>
    </div>

    <div class="row">

        <div>Veuillez choisir le mode de paiement ?</div>
        <mat-radio-group aria-label="Choisir le mode de paiement" [formControl]="methodPaiementForm">
            <mat-radio-button value="iban">IBAN</mat-radio-button>
            <mat-radio-button value="paypal">PAYPAL</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="row" *ngIf="invoicesValidity.length>0">
        <div *ngIf="amount > 0">{{ invoicesValidity.length }} factures trouvées pour un montant total de {{ amount }} Euros</div>
    </div>

   

    <div class="row" >
        <div class="mat-elevation-z8">
            <div class="row">Listing des erreurs</div>
            <table mat-table [dataSource]="dataSource" MatSort class="reccurring-table">
                <ng-container matColumnDef="invoiceID">
                <th mat-header-cell *matHeaderCellDef> ID Facture. </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice.id}} </td>
                </ng-container>

                <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef> Période </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice.id}} </td>
                </ng-container>

                <ng-container matColumnDef="teacher">
                <th mat-header-cell *matHeaderCellDef> Professeur </th>
                <td mat-cell *matCellDef="let element">
                    <a [href]="getTeacherUrl(element)" target="_blank">{{element.invoice.teacher_name}}</a>
                </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Montant </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice.amount}} </td>
                </ng-container>

                <ng-container matColumnDef="errors">
                <th mat-header-cell *matHeaderCellDef> Erreurs </th>
                <td mat-cell *matCellDef="let element"> 
                    <div *ngFor="let error of element.is_valid.errors">
                        {{ error }}
                    </div>
                </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource.data.length==0" class="no-records">
                    No records found
            </div>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>


    <div class="row datatable-position">
        erreurs à corriger : {{ nbErrors}}
        <button (click)="getSelectedInvoices()" *ngIf="nbErrors>0">Recharger</button>
    </div>

    <div class="row datatable-position" *ngIf="checkIsValidInvoice && isPaymentFileDownloaded==false">
        <button (click)="createExport()" [disabled]="nbErrors>0">Télécharger fichier de paiement</button>
        <div *ngIf="nbErrors>0" class="bundle_error">Impossible de télécharger le fichier de paiement si il reste des erreurs</div>
    </div>

    <div class="row datatable-position" *ngIf="isPaymentFileDownloaded">
        <span>Le paiement a t il été traité avec succès ?</span>
        <mat-radio-group aria-label="Le paiement a t il été traité avec succès ?" [formControl]="checkPaymentForm">
            <mat-radio-button (click)="setInvoiceLogExportAsPaid(true)" value="true">oui</mat-radio-button>
            <mat-radio-button (click)="setInvoiceLogExportAsPaid(false)" value="false">non</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="row datatable-position" *ngIf="checkPaymentForm.value=='true'">
        <button (click)="createBundle()">Création de dossier ZIP</button>
    </div>
</div>