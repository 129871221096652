/**
 * Sous-composant pour l'affichage des sessions.
 * Intègre le composant datatable pour l'affichage.
 */

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Formation } from '../../../../../model/formation.model';
import { Occurrence } from '../../../../../model/occurrence.model';
import { SessionsService } from './sessions.service';
import { OccurrenceService } from '../../../../../service/occurrence.service';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss', './sessions.ux.scss', '../formation.ux.scss', '../../mainscreen.ux.scss'],
  providers: [SessionsService]
})
export class SessionsComponent implements OnInit, OnChanges {

  public occurrences: Occurrence[];
  source: string;
  @Input() formation: Formation;
  @Output() formationUpdater = new EventEmitter();
  pageSize: number;
  dataSessions: Object;
  dataFormation: Object;
  countOccurencesPages = 1;
  private errorMessage: any = '';
  totalData: number;

  constructor(
    private sessionsService: SessionsService,
    private kiwixiGlobals: KiwixiGlobals,
    private occurrenceService: OccurrenceService
  ) { }

  ngOnInit() {
    this.kiwixiGlobals.setLoading(true)
    this.pageSize = 10;
    this.dataSessions = {
      data: [],
      header: this.sessionsService.sessionHeaders
    }
    this.occurrences = [];
    this.getDataSession([], 1)
  }

  ngOnChanges() { }

  public getDataSession(_filterParams, _countPages) {
    // #1 set params.
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = [];
    }
    _filterParams['filtering']['student'] = this.getIdFromUrl(this.formation['student']['url']);
    _filterParams['filtering']['formation'] = this.getIdFromUrl(this.formation['url']);
    if (!_filterParams['ordering']) {
      _filterParams['ordering'] = {
        key: 'ordering',
        value: 'start'
      }
    }
    _filterParams['page'] = _countPages;
    // #2  call api
    this.occurrenceService.getOccurrencesResults(_filterParams)
      .then(
        res => {
          this.totalData = res['count'];
          if (_countPages === 1) {
            this.dataSessions['data'] = [];
          }
          for (const occurence of res['results']) {
            this.setRowsData(occurence);
          }
          this.source = 'sessions';
        },
        error => this.errorMessage = <any>error
      );
    return this.dataSessions['data'];
  }

  /**
   * Methode pour construire les lignes du tableau pour chaque occurrence.
   * @param occurrence
   */
  setRowsData(occurrence) {
    const _date = new Date(occurrence.start)
    const _date2 = new Date(occurrence.end)
    const row = {
      initdate: _date,
      enddate: _date2,
      date: _date.toLocaleDateString(),
      teacher: occurrence.teacher,
      status: this.setStatusToSession(occurrence.status, this.sessionsService),
      url: occurrence.url,
      isNext: false
    };
    this.dataSessions['data'].push(row);
  }

  /**
   * Afficher le status de la session.
   * @param session_status
   * @param _sessionsService
   */
  setStatusToSession(session_status, _sessionsService) {
    const status = _sessionsService.getSessionsStatus();
    const idx = _sessionsService.getSessionsStatus().map(val => val.code).indexOf(session_status);
    if (idx >= 0) {
      return status[idx];
    } else {
      return null;
    }
  }

  /**
   * mettre à jour le status de l'occurrence - patch api.
   * @param value
   */
  updateStatus(value) {
    const url = value['url'];
    const status_code = value['status']['code'];
    this.occurrenceService.patchOccurrence(url, { 'status': status_code }).then(
      res => {
        // must refresh formation
        this.formationUpdater.emit();
      }
    )
  }

  getIdFromUrl(url) {
    return url.split('/')[6].toString();
  }

}
