export class RatingStudent {
  url: string;
  id: number;
  start: Date;
  status: string;
  message: string;
  level_grammar: string;
  grammar: number;
  level_accent: number;
  accent: number;
  level_expression: number;
  expression: number;
  level_comprehension: number;
  comprehension: number;
  level_vocabulary: number;
  vocabulary: number;
  session: string
  level_oral: string;
  level_written: string;
  constructor() { }
}
