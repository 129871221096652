<div [ngClass]="{'placeholder-state': !isReportExist}" class="lilatesum-block">
  
    <div class="lilatesum-title">
      <span class="lilatesum-icon"></span>
      <span translate>lingueopro.dashboard.Mon Niveau LILATE</span>
    </div>
     <div class="lilatesum-flex">
       <div class="canvas-overwrapper">
        <div class="canvas-wrapper">
          <div class="lilate-grade" style="text-align: center;"><img style="width:120px; margin:auto; display:inline-block;" [src]="levelLilateImage" /></div>
        </div>
      </div>
      <div class="lilatesum-btn-wrap">
        <button [ngClass]="{'disable-lilate-link': !isReportExist}" class="lilate-detail-btn" type="button" (click)="generateLilate()" translate [disabled]="!isReportExist">lingueopro.dashboard.generate-lilate</button>
        <button [ngClass]="{'disable-lilate-link': !isReportExist}" class="lilate-detail-btn" type="button" routerLink="/formation/{{formation.id}}/progression" translate [disabled]="!isReportExist">lingueopro.dashboard.Voir en détail</button>
      </div>
      <div class="lilatesum-placeholdertext" *ngIf="!isReportExist"><span translate>lingueopro.dashboard.lilate_placeholder</span></div>
    </div>
 
</div>