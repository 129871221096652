import { achievementsByPass } from './../../environments/achievementsByPass';
import { Achievement } from './../model/achievement.model';
import { Student } from './../model/student.model';
import { AchievementToFire } from './../model/achievementToFire.model';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';


@Injectable()
export class AchievementManagerService extends KiwixiService {

    apiHelpNotif = environment.server + 'help_notifs/';
    public achievementToFire: BehaviorSubject<AchievementToFire[]> = new BehaviorSubject<AchievementToFire[]>([]);


    constructor(
        _http: HttpClient,
        _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getAchievementsWithBetterPriority(achievement: Achievement) {
        return this.achievementToFire.value.filter(a => a.priority < achievement.priority && a.fired === false).length;
    }

    isAchievementToByPass(achievement: Achievement) {
        if (achievementsByPass.elements.filter(a => a === achievement.slug).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isAchievementAlreadyFired(achievement: Achievement) {
        if (this.achievementToFire.value.filter(a => a.id === achievement.id && a.fired === true).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isAchievementExist(achievement: Achievement) {
        if (this.achievementToFire.value.filter(a => a.id === achievement.id).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    canFire(achievement: Achievement) {
        if (this.isAchievementToByPass(achievement)) {
            return true;
        }
        if (!this.isAchievementExist(achievement)) {
            return false;
        }
        if (this.getAchievementsWithBetterPriority(achievement) === 0 && this.isAchievementAlreadyFired(achievement) === false) {
            return true;
        } else {
            return false;
        }
    }

    setAchievementsToFire(personna: any) {
        if (personna) {
            if (personna.achievements) {
                let achievementListToFire: AchievementToFire[] = [];
                personna.achievements.map(a => {
                    if (a.achievements_done === null) {
                        achievementListToFire.push({
                            'fired': false,
                            'id': a.id,
                            'priority': a.priority,
                            'achievementDetail': a
                        })
                    }
                });
                this.achievementToFire.next(achievementListToFire);
            }
        }
    }


    setAchievementAsFire(achievement: Achievement) {
        this.achievementToFire.value.map(a => a.id === achievement.id ? a.fired = true : null)
    }
}
