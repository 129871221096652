<!-- title -->
<b translate class="page-title">teacher.upcoming.upcoming-title</b>
<div class="upcoming-wrapper">
  <ng-container *ngIf="totalData > 0; else elseEmpty">
    <!-- Search bar -->
    <app-search-bar (setFiltering)="search($event)"></app-search-bar>
    <!-- count upcomings -->
    <div class="upcoming-counter">
      {{totalData}}
      <span translate>teacher.upcoming.count-classes-label</span>
      {{lastUpcomingDate}}
    </div>
    
    <!-- upcomings -->
    <ng-container *ngIf="loadAllUpcomings">
      <div class="row center loader-position">
        <b class="white-text">
          <span translate>teacher.upcoming.sessions-loading</span>
        </b>
      </div>
    </ng-container>

    <div class="list-upcoming">
      <div>
        <span translate>teacher.upcoming.help-teams.message</span>
      </div>

      <mat-expansion-panel *ngFor="let groupedByDay of upcomingBooked; let isFirst = first" [expanded]="isFirst">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-data"><span class="panel-date">{{ groupedByDay.date | amLocale: 'en' | amDateFormat:'ddd DD MMM' }} </span> : {{ groupedByDay.items.length }} lessons</div>
            <div class="panel-sepline"></div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngFor="let upcoming of groupedByDay.items; let i = index">
          <app-upcoming [upcoming]="upcoming" [myIndex]="i" [isTeacherInterface]=false (displayUpcoming)="loadUpcomings($event)"></app-upcoming>
        </div>
      </mat-expansion-panel>
    </div>
    <div>
      <button class="loadmore-btn" *ngIf="nextPages" (click)="loadMore()">Load More</button>
    </div>
  </ng-container>
  <ng-template #elseEmpty>
    <div>
      <b>
        <ng-container *ngIf="loadAllUpcomings; else elseLoad">
          <span translate>teacher.upcoming.sessions-loading</span>
        </ng-container>
        <ng-template #elseLoad>          
          <span translate>teacher.upcoming.no-sessions</span>
        </ng-template>
      </b>
    </div>
  </ng-template>
</div>