import { HttpParams } from '@angular/common/http';
import { ConfirmationDialogComponent } from './../../../../../../feature/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { ExerciceSheet } from './../../../../../../model/exerciceSheet.model';
import { Component, OnInit, Input } from '@angular/core';
import { Exercice } from '../../../../../../model/exercice.model';
import { ExerciceService } from '../../../../../../service/exercice.service';

@Component({
  selector: 'app-sheet-exercices-list',
  templateUrl: './sheet-exercices-list.component.html',
  styleUrls: ['./sheet-exercices-list.component.scss', '../../../formation/informations/informations.ux.scss', '../../../formation/formation.ux.scss', '../../../mainscreen.ux.scss']
})
export class SheetExercicesListComponent implements OnInit {
  @Input() exerciceSheet: ExerciceSheet;
  exercices: Exercice[] = [];
  easyList: Exercice[] = [];
  mediumList: Exercice[] = [];
  hardList: Exercice[] = [];
  constructor(
    private exerciceService: ExerciceService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getExercices();
  }

  getExercices() {
    let params = new HttpParams();
    params = params.append('sheets', this.exerciceSheet.id.toString());
    params = params.append('status', 'published');

    this.exerciceService.getExercices(params).subscribe(response => {
      if (response.results.length > 0) {
        this.exercices = response.results;
        this.updateExerciceList();
      }
    })
  }
  updateExerciceList() {
    this.getEasyExercices();
    this.getMediumExercices();
    this.getHardExercices();
  }

  getEasyExercices() {
    this.easyList = this.exercices.filter(exercice => exercice.difficulty === 'easy');
  }
  getMediumExercices() {
    this.mediumList = this.exercices.filter(exercice => exercice.difficulty === 'medium');
  }
  getHardExercices() {
    this.hardList = this.exercices.filter(exercice => exercice.difficulty === 'hard');
  }


  deleteExercice(exerciceId: number): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'Vous êtes sur le point de supprimer un exercice'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.patchExercice(exerciceId)
      }
    });
  }

  patchExercice(exerciceId: number) {
    const _params = {
      'status': 'deleted'
    };
    this.exerciceService.patchExercice(exerciceId, _params).subscribe(result => {
      this.exercices = this.exercices.filter(exercice => exercice.id !== exerciceId);
      this.updateExerciceList();
    })
  }
}
