<div id="create-plan"  *ngIf="formation" class="setup-training-wrap container usual-container">
  
  <div class="steps-wrapper">
    <div class="onboarding-step step-birthdate complete-step"><div class="step-icon"></div></div>
    <div *ngIf="student.user.video_provider=='skype'" class="onboarding-step step1 "><div class="step-icon"></div></div>
    <div class="onboarding-step step2"><div class="step-icon"></div></div>
    <div class="onboarding-step step3"><div class="step-icon"></div></div>
    <div class="onboarding-step step4"><div class="step-icon"></div></div>
  </div>


  <div class="onboarding-subheading" translate>lingueopro.onboarding.birthdate.subheader</div>
  <div translate>lingueopro.onboarding.birthdate.subheader-text</div>
  <div class="birthdate-wrap">
    <div class="onboarding-subheading" translate>lingueopro.onboarding.birthdate.field</div>
    <div translate>lingueopro.onboarding.birthdate.field.text</div>
    <mat-form-field class="birthdate-field">
      <input matInput [matDatepicker]="picker" [(ngModel)]="student.user.birthdate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker ></mat-datepicker>
    </mat-form-field>
  </div>
  <button class="nextstep-btn" (click)="updateUser(student.user)">
    <b translate>lingueopro.onboarding.birthdate.button</b>
  </button>
</div>