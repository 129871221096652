<div id="listing-export-log">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
            <h5 class="page-title">Listing des exports</h5>
        </div>
    </div>
    
    <div class="row">
        <h6 class="results-counter">{{ dataSource.data.length }} exports trouvées </h6>
    </div>

    <div class="row">
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort class="reccurring-table">
                <ng-container matColumnDef="exportID">
                <th mat-header-cell *matHeaderCellDef> Export ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="nbInvoice">
                <th mat-header-cell *matHeaderCellDef> Nombre facture exportées </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice_requested.length}} </td>
                </ng-container>

                <ng-container matColumnDef="dateExport">
                <th mat-header-cell *matHeaderCellDef> date export </th>
                <td mat-cell *matCellDef="let element">{{element.created_at | date: 'dd MMM yyyy hh:mm'}}</td>
                </ng-container>

                <ng-container matColumnDef="bundleStatus">
                <th mat-header-cell *matHeaderCellDef> Bundle status </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="createBundle(element)" *ngIf="element.bundle_creation_status=='idle' && element.bundle_created_at">Re générer le bundle</button>
                    <button (click)="createBundle(element)" *ngIf="element.bundle_creation_status=='idle' && !element.bundle_created_at">Créer le bundle</button>
                    <mat-spinner class="loader" *ngIf="element.bundle_creation_status=='in_progress'"></mat-spinner>
                    <button (click)="downloadBundle(element)" *ngIf="element.bundle_created_at">Télécharger le bundle</button>
                </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource.data.length==0" class="no-records">
                    No records found
            </div>
            <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>