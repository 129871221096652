<div class="decorative-border">
  <div class="daily-header">
      <!-- Explaination -->
      <div class="row explanation-text" translate>{{translatePath}}.daily-schedule-explanation</div>
  </div>

  <app-ponctual-calendar
  [calendarId]="calendarId"
  [calendarUnavailabilityId]="calendarUnavailabilityId"
  [calendarUrl]="calendarUrl"
  [calendarUnavailabilityUrl]="calendarUnavailabilityUrl"
  [teacherId]="teacherId"
  [teacher]="teacher"
  >
  </app-ponctual-calendar>

  <!-- Loader -->
  <ng-template #elseLoadEvents>
      <div class="row center pageload-message">
          <b translate>teacher.schedule.daily-loading</b>
      </div>
  </ng-template>
</div>
