import { InitAppService } from './../../../service/initapp.service';
import { Component, OnInit } from '@angular/core';
import { OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormationService } from '../../../service/formation.service';
import { AuthenticationService } from '../../../service/authentification.service';

/**
 * Composant principal pour l'affichage des pasges concernant la formation sélectionné.
 */
@Component({
  selector: 'app-formation',
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.scss']
})
export class FormationComponent implements OnInit, OnChanges {

  pageTitle = '';
  translatePath = 'lingueopro';
  formation = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formationService: FormationService,
    private authenticationService: AuthenticationService,
    private initAppService: InitAppService,

  ) {
  }

  ngOnInit() {
    const currentFormationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, currentFormationId);
    this.initFormation();
  }

  // Chargement de la formation sélectionné.
  initFormation() {
    const _formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.authenticationService.getCurrentPersona()) {
      for (const _formationUrl of this.authenticationService.getCurrentPersona().formations) {
        if (this.authenticationService.extractIdFromUrl(_formationUrl).toString() === _formation_id) {
          this.formationService.currentFormationSubject.subscribe(_formation => {
            if (_formation) {
              this.formationService.setStudentCrispEnv(_formation.student);
              this.formation = _formation;
              // Si la formation est en attente ou navigue vers la page dédié.
              if (_formation.status === 'pending') {
                this.router.navigate(['plan-formation', 'introduction', this.formation.id]);
              }
              if (_formation.status === 'suspended') {
                this.router.navigate(['formation', this.formation.id, 'suspended']);
              }
            }
          })
        }
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  ngOnChanges(simpleChange: SimpleChanges) { }

}
