import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Formation } from '../../../../model/formation.model';
import { Student } from '../../../../model/student.model';
import { FormationService } from '../../../../service/formation.service';
import { InitAppService } from '../../../../service/initapp.service';
import { UserService } from '../../../../service/user.service';

@Component({
  selector: 'app-birthdate',
  templateUrl: './birthdate.component.html',
  styleUrls: [
    '../schedule/schedule.component.scss',
    '../plan-formation.component.scss',
    '../schedule/schedule.ux.scss',
    '../plan-formation.ux.scss',
    './birthdate.component.scss'
  ]
})
export class BirthdateComponent implements OnInit {
  formation_id;
  formation: Formation
  student: Student = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initAppService: InitAppService,
    private formationService: FormationService,
    private userService: UserService,
    private adapter: DateAdapter<any>
  ) { this.adapter.setLocale('fr'); }

  ngOnInit() {
    this.formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, this.formation_id);

    this.formationService.currentFormationSubject.subscribe(formation => {
      if (formation) {
        this.student = formation.student;
        this.formation = formation;
      }
    });
  }

  /**
   * mettre à jour un user.
   * @param user
   */
   updateUser(user) {
    const param = {};
    param['birthdate'] = user['birthdate'];
    this.userService.patchUser(user.url, param).then(
      () => this.next()
    )
  }

  goToIdentity() {
    this.router.navigate(['plan-formation/identity', this.formation.id]);
  }

  goToInformation() {
    this.router.navigate(['plan-formation/information', this.formation.id]);
  }

  goToSchedule() {
    if (this.formation.booking_mode === 'rec') {
      this.router.navigate(['plan-formation/schedule', this.formation.id]);
    } else if (this.formation.booking_mode === 'ponctual') {
      this.router.navigate(['plan-formation/init-ponctual-booking', this.formation.id]);
    } else {
      this.router.navigate(['plan-formation/booking-mode', this.formation.id]);
    }
  }

  next() {
    if ((this.formation.student.user.video_provider === 'skype' && this.formation.student.user.id_skype === '') || this.formation.student.user.video_provider === '') {
      this.goToInformation();
    } else {
      if (!this.formation.student.user.photo_profile || (this.formation.student.user.photo_profile && this.formation.student.user.photo_profile.status === 'refused')) {
        this.goToIdentity();
      } else {
        this.goToSchedule();
      }
    }
  }

}
