<div id="listing-recurring">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
          <h5 class="page-title">Liste des rapports</h5>
        </div>
    </div>

    <div class="row title">
      <div>Veuillez cliquer sur l'un des rapports ci-dessous</div>
      <div>
        <ul>
          <li routerLink="./teacher_availabilities" class="reportLink"> - Disponbilité des professeurs</li>
          <li routerLink="./teacher_billing_expected" class="reportLink"> - Facturation professeurs attendue</li>
        </ul>
      </div>
    </div>
</div>