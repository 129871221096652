<div class="docpage-wrapper">
  <ng-container *ngIf="!conversationInTraining">
      <ng-container *ngIf="sharing; else elseSharing1">
        <div class="chatdoc-header">
          <div *ngIf="!conversationInTraining">
            <i class="material-icons back-btn" (click)="back()">arrow_back</i>
            <b class="page-title" translate>{{translatePath}}.share-documents-lbl</b>
          </div>
        </div>
      </ng-container>
      <ng-template #elseSharing1>

          <div class="page-title">
            <b translate>{{translatePath}}.documents-title</b>
          </div>

      </ng-template> 
  </ng-container>

  <div class="docslist-wrapper">
    <div class="searchbar-wrapper">
      <!-- Search bar -->
      <app-search-bar (setFiltering)="search($event)" [searchInput]="inputTerm"></app-search-bar>
    </div>
    <div class="adddoc-wrapper">
      <ng-container *ngIf="sharing; else elseSharing2">
        <!-- Share-Device-Documents -->
        <button class="adddoc-btn action-btn suboption" (click)="uploadDeviceDocs.click()" translate>{{translatePath}}.share-device-documents-btn</button>
        <input #uploadDeviceDocs type="file" (change)="uploadFileAndShare($event)" [hidden]="true"/>
      </ng-container>
      <ng-template #elseSharing2>
        <!-- Add-Documents -->
        <button class="adddoc-btn action-btn" (click)="uploadDocs.click()" translate>{{translatePath}}.import-documents-btn</button>
        <input #uploadDocs type="file" (change)="uploadFile($event)" [hidden]="true"/>
      </ng-template>
    </div>

    <ng-container *ngIf="!loadDocuments; else elseTemplate">
      <div class="table-documents">
        <div class="row-list document-row" *ngFor = "let doc of documents">
          <span class="file-icon"><!--insert_drive_file--></span>
          <div class="fileinfo" (click)="file.click()">
            <span class="truncate docname">{{doc.name}}</span>
            <span class="docuploadinfo">
              <span><b translate>teacher.documents.uploaded-on</b> {{doc.created_at | amLocal | amLocale: 'en' | amDateFormat: 'DD/MM/YYYY'}}</span>
            </span>
          </div>
          <i *ngIf="!sharing" class="material-icons small filedelete-icon" (click)="removeDocument(doc)">cancel</i>
          <span *ngIf="sharing"><mat-checkbox [(ngModel)]="doc.checked"></mat-checkbox></span>
          <a #file [hidden]="true" appDownloadLingueo [document]="doc"></a>
        </div>
        <div class="row center">
          <div class="col l12 " (click)="getDocuments()" class="load-more">Load more documents...</div>
        </div>
      </div>
      <!--
      <div class="sortoptions-row">
        <div class="sortoptions-label"><span translate>{{translatePath}}.order-lbl</span>:</div>
       <div class="sortoption-btn" (click)="ordering('created_at')">Date</div>
        <div class="sortoption-btn" (click)="ordering('name')">A-Z</div>
        <button class="share-btn action-btn" *ngIf="sharing" (click)="share()" [disabled]="documentsExists()">
          <span translate>{{translatePath}}.share-btn</span>
        </button>
      </div>
      --->
    </ng-container>
    <ng-template #elseTemplate>
      <div class="row center loading pageload-message">
        <b translate>{{translatePath}}.documents-loading</b>
      </div>
    </ng-template>
  </div>
</div>
