/**
 * Sous-composant pour les dispos du teacher
 */

import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/fr';
// services
import { KiwixiGlobals } from '../../../../../../../kiwixi/kiwixi.globals';
import { PlanService } from '../../../../../../../service/plan.service';
import { OccurrenceService } from '../../../../../../../service/occurrence.service';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: [
    './availability.component.scss',
    '../../edit-teacher.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [OccurrenceService, { provide: MAT_DATE_LOCALE, useValue: 'en-FR' }]
})
export class AvailabilityComponent implements OnInit {
  @Input() myTeacher
  startPicker: any[];
  endPicker: any[];
  dayPicker: any[];
  startSelect: any;
  endSelect: any;
  daySelect: any;
  dateSelect: any;
  availablesDays: any[] = [];
  originalavailablesDays: any[];
  occurrences: any;
  loadDisponibilities: boolean;
  availableDay;

  constructor(
    private planService: PlanService,
    private occurrenceService: OccurrenceService,
    private kiwixiGlobals: KiwixiGlobals) { }

  ngOnInit() {
    this.getTeacherDisponibilities(moment().month() + 1, moment().year())
    const filterParams = [
      {
        key: 'teacher',
        value: this.kiwixiGlobals.getIdFromUrl(this.myTeacher.url)
      }
    ]

    this.occurrenceService.getOccurrencesResults(filterParams)
      .then(_occurrences => {
        this.occurrences = _occurrences;
      })
  }

  /**
   * méthode pour construire les pickers.
   * @param isStart
   */
  setTimePicker(isStart) {
    const myTimeList = [];
    for (let i = 0; i < 25; i++) {
      if (i < 24) {
        const _myDate1 = moment([moment().year(), moment().month(), moment().date(), i, 0, 0, 0]);
        const my_obj1 = { id: i + 1, enable: false, datetime: _myDate1, local_str: _myDate1.format('HH:mm') }
        myTimeList.push(my_obj1);
        if (!isStart && i === 0) {
          myTimeList.splice(0, 1);
        }
        const _myDate2 = moment([moment().year(), moment().month(), moment().date(), i, 30, 0, 0]);
        const my_obj2 = { id: i + 1, enable: false, datetime: _myDate2, local_str: _myDate2.format('HH:mm') }
        myTimeList.push(my_obj2);
      } else {
        const _myDate3 = moment([moment().year(), moment().month(), moment().date(), 23, 59, 59, 0]);
        const my_obj3 = { id: i + 1, enable: false, datetime: _myDate3, local_str: _myDate3.format('HH:mm') }
        if (!isStart) {
          myTimeList.push(my_obj3);
        }
      }
    }
    return myTimeList
  }

  /**
   * méthode pour construire la liste des jours.
   */
  initDayList() {
    this.dayPicker = [];
    this.daySelect = null;
    for (let _i = 0; _i < 7; _i++) {
      this.dayPicker.push(moment().day(_i));
    }

    // on place Dimanche en fin de liste
    this.dayPicker.push(this.dayPicker[0]);
    this.dayPicker.splice(0, 1);
  }

  /**
   * évenement à la selection du start.
   */
  selectStart() {
    this.initPicker(this.endPicker, false);
    this.initModels();
  }

  /**
   * Methode pour initialiser le picker avec les dispos
   */
  initPicker(_mySelectList, isStart) {
    if (this.availablesDays) {
      for (let _i = 0; _i < this.availablesDays.length; _i++) {
        for (let _j = 0; _j < this.availablesDays[_i]['dispo'].length; _j++) {
          this.setDisponibilitiesToSelectTime(this.availablesDays[_i]['dispo'][_j]['start'], this.availablesDays[_i]['dispo'][_j]['end'], _mySelectList, isStart);
        }
      }
    }
  }

  /**
   * Initialiser les variables Start & End
   */
  initModels() {
    for (const myendPicker of this.endPicker) {
      if (this.startSelect.datetime.isSameOrAfter(moment(myendPicker.datetime))) {
        myendPicker.enable = false;
      }
      if (this.startSelect.datetime.local().hour() === myendPicker.datetime.local().hour()
        && this.startSelect.datetime.local().minute() === myendPicker.datetime.local().minute()) {
        this.endSelect = this.endPicker[this.endPicker.indexOf(myendPicker) + 1];
        break;
      }
    }
  }


  /* Methode pour récupérer les heures disponibles */
  getTeacherDisponibilities(_month, _year) {
    this.loadDisponibilities = true;
    const param = {
      algorithm: 'availabilityteacher',
      teacher: this.kiwixiGlobals.getIdFromUrl(this.myTeacher.url),
      month: _month,
      year: _year,
      timezone: momenttz.tz.guess()
    }
    this.planService.postPlan(param)
      .then(_res => {
        if (_res) {
          this.originalavailablesDays = _res;
          this.availablesDays = _res;
          this.loadDisponibilities = false;
          this.initSelectTime();
        }
      })
  }

  /**
   * méthode pour re/initialiser les selecteurs
   */
  initSelectTime() {
    this.startPicker = [];
    this.endPicker = [];
    this.dateSelect = null;
    this.initDayList();
    this.startPicker = this.setTimePicker(true);
    this.endPicker = this.setTimePicker(false);
    this.initPicker(this.startPicker, true);
  }

  /**
   * méthode pour défini si les items sont dispos/indispos.
   * @param _start
   * @param _end
   * @param _mySelectList
   * @param isStart
   */
  setDisponibilitiesToSelectTime(_start, _end, _mySelectList, isStart) {

    // variable local pour le start de la dispo.
    const _myAvailableStart = moment({
      years: moment().year(), months: moment().month(), date: moment().date(),
      hours: moment(_start).hour(), minutes: moment(_start).minute(),
      seconds: 0, milliseconds: 0
    });

    // variable local pour le end de la dispo.
    let _myAvailableEnd = moment({
      years: moment().year(), months: moment().month(), date: moment().date(),
      hours: moment(_end).hour(), minutes: moment(_end).minute(),
      seconds: 0, milliseconds: 0
    });

    // si la dispo end à minuit le lendemain on la set à 23:59.
    if (_myAvailableEnd.format('HH:mm') === '00:00') {
      _myAvailableEnd = moment({
        years: moment().year(), months: moment().month(), date: moment().date(),
        hours: 23, minutes: 59,
        seconds: 59, milliseconds: 999
      });
    }

    // *** Pour chaque item du selecteur.....
    for (let _i = 0; _i < _mySelectList.length; _i++) {
      const mySelect = _mySelectList[_i];

      // on construit un moment local de l'item pour comparer sa datetime vs avec la dispo.
      let _mySelectTime = moment({
        years: moment().year(), months: moment().month(), date: moment().date(),
        hours: moment(mySelect.datetime).hour(), minutes: moment(mySelect.datetime).minute(),
        seconds: 0, milliseconds: 0
      });
      if (!mySelect.enable) {

        // si l'item est à minuit le lendemain on la set à 23:59.
        if (mySelect.id === 25) {
          _mySelectTime = moment({
            years: moment().year(), months: moment().month(), date: moment().date(),
            hours: 23, minutes: 59,
            seconds: 59, milliseconds: 999
          });
        }

        if (isStart) {
          // on definie le status de dispo pour le le selecteur start :
          mySelect.enable = checkIfStartHourIsBetween(_myAvailableStart, _myAvailableEnd, _mySelectTime);
        } else {
          // on definie le status de dispo pour le le selecteur end :
          mySelect.enable = checkIfEndHourIsBetween(_myAvailableStart, _myAvailableEnd, _mySelectTime);
        }
      }
    }

    /**
     * méthode qui définie si l'item start intersect la dispo
     * @param start
     * @param end
     * @param value
     */
    function checkIfStartHourIsBetween(start, end, value) {
      if (start <= value && value < end) {
        return true;
      }
      return false;
    }

    /**
     * méthode qui définie si l'item end intersect la dispo
     * @param start
     * @param end
     * @param value
     */
    function checkIfEndHourIsBetween(start, end, value) {
      if (start < value && value <= end) {
        return true;
      }
      return false;
    }
  }

  /* On extrait les dispo pour un jour sélectionné */
  changeDateSelect() {
    this.availablesDays = this.originalavailablesDays;
    for (const _availableDay of this.availablesDays) {
      const _myAvailableDay = moment({
        years: moment(_availableDay.day).year(), months: moment(_availableDay.day).month(), date: moment(_availableDay.day).date(),
        hours: 0, minutes: 0, seconds: 0, milliseconds: 0
      });
      if (_myAvailableDay.isSame(moment(this.dateSelect))) {
        this.availableDay = _availableDay
        break;
      }
    }
  }

  /******* Hanle from childs component or directive *******/
  handleUpdateDateSelect(value) {
    this.dateSelect = value;
    this.changeDateSelect();
  }

  handleUpdateAvailables(_date) {
    this.initSelectTime();
    this.getTeacherDisponibilities(_date.month, _date.year);
  }
}
