<div class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Contenu Fiche</h6>
    </div>
  </div>
  <div class="row information-subcontainer">
    <div class="col l12">
      <button class="teaching-or-admin-btn" [className]="!editContent ? 'teaching-or-admin-btn active' : 'teaching-or-admin-btn inactive'" mz-button (click)="editContent = false" >Mode Lecture</button>
      <button class="teaching-or-admin-btn" [className]="editContent ? 'teaching-or-admin-btn active' : 'teaching-or-admin-btn inactive'" mz-button (click)="editContent = true">Mode Edition</button>

      <app-sheet-edit-content *ngIf="editContent" [exerciceSheet]="exerciceSheet" (updateSheetObject)="updateSheetObject()"></app-sheet-edit-content>
      <app-sheet-view-content *ngIf="!editContent" [exerciceSheet]="exerciceSheet"></app-sheet-view-content>
    </div>

</div>
