/**
 * Commposant pour le reschedule par le teacher.
 */
import { HttpParams } from '@angular/common/http';
import { OccurrenceHistoryStatusService } from './../../../../service/occurrenceHistoryStatus.service';
import { Component, OnInit } from '@angular/core';
import { FormationService } from '../../../../service/formation.service';
import { environment } from '../../../../../environments/environment';
import { UpcomingService } from '../../../../service/upcoming.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../../service/event.service';
import { Event } from '../../../../model/event.model';
import { AuthenticationService } from '../../../../service/authentification.service';
@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit {
  upcoming;
  student = null;
  myTeacher = null;
  occurrence_id = null;
  formation = null;
  showReport = false;
  editMessage = false;
  isUnavailable = true;
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.upcoming';
  myMessageCtrl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  messageReportForm: FormGroup = this.builder.group({
    description: this.myMessageCtrl
  });
  formChanges = false;
  countReportByFormation = 0;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formationService: FormationService,
    private eventService: EventService,
    private authenticationService: AuthenticationService,
    private upcomingService: UpcomingService,
    private builder: FormBuilder,
    private occurrenceHistoryStatusService: OccurrenceHistoryStatusService) { }

  ngOnInit() {
    this.occurrence_id = this.route.snapshot.params.id;
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myTeacher = user;
          this.upcomingService.getUpcoming(this.occurrence_id)
            .subscribe(_upcoming => {
              this.upcoming = _upcoming;
              if (this.upcoming.formation) {

                const formationId = this.formationService.extractIdFromUrl(this.upcoming.formation);
                const params = new HttpParams()
                  .set('formation', formationId.toString())
                  .set('new_status', 'postponed_by_teacher')
                  .set('from_persona', this.myTeacher.id)
                  .set('content_type_user_id', '27') // content type for model teacher

                this.occurrenceHistoryStatusService.getOccurrenceHistoryStatus(params).subscribe(result => {
                  this.countReportByFormation = result.count
                });

                this.formationService.getObservableFormationById(formationId)
                  .subscribe(_formation => {
                    this.formation = _formation;
                    this.student = _formation.student
                    this.showReport = true;
                    this.messageReportForm.patchValue(this.upcoming)
                    this.subcribeToFormChanges();
                  })
              }
            });
        }
      }
    )
  }

  subcribeToFormChanges() {
    this.messageReportForm.valueChanges.subscribe(data => {
      this.formChanges = true;
    })
  }

  confirmReport() {
    const param = {
      cancelled_message: this.messageReportForm.value.description,
      status: 'postponed_by_teacher'
    }
    this.upcomingService.patchUpcoming(this.upcoming.url, param)
      .then(res => {
        this.upcomingService.toastService.show(
          `Report Confirmé`,
          1000,
          'green'
        );
        this.router.navigate(['/']);
      });
  }

  postEvent() {
    const _event = new Event();
    _event.rule = null;
    if (this.myTeacher) {
      _event.calendar = this.myTeacher.unavaibility_calendar;
    }
    _event.start = this.upcoming.start;
    _event.end = this.upcoming.end;
    this.eventService.postEvent(_event)
      .then(res => {
        this.router.navigate(['/']);
        this.upcomingService.toastService.show(
          `Report et Indisponibilité Confirmé`,
          1000,
          'green'
        );
      })
  }

}
