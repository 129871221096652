<p *ngIf="loading; else student">
  Chargement ...
</p>
<ng-template #student>
  <div *ngIf="!my_student || !my_student.user || my_student.user.photo_profile === null && my_student.user.first_name === null && my_student.user.last_name === null; else infoStudent">--</div>
  <ng-template #infoStudent>
    <div class="valign-wrapper row">
      <div class="col datatable-avatar-block test"  *ngIf="my_student.user.photo_profile !== null"[ngClass]="{'photo_profile_validation_requested': my_student.user.photo_profile.status=='validation_requested', 'photo_profile_validated': my_student.user.photo_profile.status=='validated', 'photo_profile_refused': my_student.user.photo_profile.status=='refused'}">
        <img class="user-avatar" src='{{ my_student.user.photo_profile.thumbnail}}' *ngIf="my_student.user.photo_profile !== null" (error)="this.studentService.errorImgHandler($event)">
      </div>
      <div class="col datatable-avatar-block test"  *ngIf="my_student.user.photo_profile === null">
        <img class="user-avatar" src='{{defaultAvatar}}' *ngIf="my_student.user.photo_profile === null">
      </div>
      <div class="col truncate">
        {{ my_student.user.first_name }}<br/>{{ my_student.user.last_name }}
      </div>
    </div>
  </ng-template>
</ng-template>