import { HttpParams } from '@angular/common/http';
import { ExerciceService } from './../../../../../../../service/exercice.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Exercice } from '../../../../../../../model/exercice.model';
import { ExerciceQuestionService } from '../../../../../../../service/exerciceQuestion.service';
@Component({
  selector: 'app-sheet-exercices-question',
  templateUrl: './sheet-exercices-question.component.html',
  styleUrls: ['./sheet-exercices-question.component.scss', '../../../../formation/informations/informations.ux.scss', '../../../../formation/formation.ux.scss', '../../../../mainscreen.ux.scss']
})
export class SheetExercicesQuestionComponent implements OnInit {
  @Input() exercice: Exercice;
  typologyList;
  typology = new FormControl('', Validators.required);
  instructionForm = new FormControl();

  constructor(
    private exerciceQuestionService: ExerciceQuestionService,
    private exerciceService: ExerciceService,
  ) { }

  ngOnInit() {
    this.getQuestions();
  }

  getQuestions() {
    let params = new HttpParams();
    params = params.append('exercice', this.exercice.id.toString());
    params = params.append('status', 'enable');
    this.exerciceQuestionService.getExerciceQuestions(params).subscribe(response => {
      if (response.results.length > 0) {
        this.exercice.questions = response.results;
        this.typologyList = this.exerciceQuestionService.getTypology();
      }
    });
    this.typologyList = this.exerciceQuestionService.getTypology();
    this.typology.setValue('qcm-unique-choice');
    this.instructionForm.setValue(this.exercice.instruction);
  }

  addQuestion() {
    if (!this.exercice.questions) {
      this.exercice.questions = [];
    }
    const _params = {
      typology: this.typology.value,
      status: 'enable',
      position: 1,
      exercice: this.exercice.url
    };
    this.exerciceQuestionService.postExerciceQuestion(_params).subscribe(questionCreated => {
      if (questionCreated.id) {
        switch (this.typology.value) {
          case 'qcm-unique-choice':
            this.exercice.questions.push(questionCreated);
            break;
          default:
            this.exerciceQuestionService.toastService.show('Type de question invalid', 2000, 'red');
            break;
        }
      }
    })
  }

  deleteQuestion(questionId) {
    const _params = {
      status: 'deleted',
    };
    this.exerciceQuestionService.patchExerciceQuestion(questionId, _params).subscribe(question => {
      this.exercice.questions = this.exercice.questions.filter(question => question.id !== questionId)
    });
  }

  patchInstruction() {
    const _params = {
      instruction: this.instructionForm.value,
    };
    this.exerciceService.patchExercice(this.exercice.id, _params).subscribe();
  }

}
