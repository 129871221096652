<div class="valign-wrapper">
  <div class="">
    De
  </div>
      <mat-select class="time-picker" [(ngModel)]="myStart" [compareWith]="compareObjects" (ngModelChange)="selectStart()" [disabled]="!condition">
        <mat-option *ngFor="let start of startPicker" [value]="start" [disabled]="!start.enable">
          {{start.local_str}}
        </mat-option>
      </mat-select>
  <div class="">
    à
  </div>
      <mat-select class="time-picker" [(ngModel)]="myEnd" [compareWith]="compareObjects" (ngModelChange)="selectEnd()" [disabled]="!myStart">
        <mat-option *ngFor="let end of endPicker" [value]="end" [disabled]="!end.enable">
          {{end.local_str}}
        </mat-option>
      </mat-select>
</div>
