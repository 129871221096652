import { OccurrenceService } from './../../service/occurrence.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Occurrence } from '../../model/occurrence.model';
import { TranslateService } from '@ngx-translate/core';
import { Formation } from '../../model/formation.model';
import { FormationService } from '../../service/formation.service';
/**
 * Composant pour l'affichage spécifique du prochain cours.
 */

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { EventService } from '../../service/event.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentification.service';
import * as moment from 'moment';
import { MzToastService } from 'ng2-materialize';
import { Teacher } from '../../model/teacher.model';


@Component({
  selector: 'app-next-session',
  templateUrl: './next-session.component.html',
  styleUrls: ['./next-session.component.scss']
})
export class NextSessionComponent implements OnInit, OnChanges {
  @Input() nextSession: Occurrence;
  @Input() formation: Formation;

  student = null;
  myTeacher: Teacher;
  sheetPermission = false;
  isSheetLanguageAvailable = false;
  translatePath = 'lingueopro.dashboard.upcoming_sessions';
  componentToLoad: string;
  modalParams: Object;
  duration: any;
  durationMs: number;
  countDownLabelHours: string;
  countDownLabelDays: string;
  countDownLabelDay: string;
  countDownLabelMinutes: string;
  countDownLabelSeconds: string;
  countdown: any = null;
  countdownStatus = 'in'
  titleLabel: string;
  isSheetAssigned = false;
  isValidProvider = false;

  constructor(
    private eventService: EventService,
    private formationService: FormationService,
    private toastService: MzToastService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private occurrenceService: OccurrenceService,
  ) { this.setCountDownTranslation() }


  ngOnInit() {
    this.translateService.onTranslationChange.subscribe(change => this.setCountDownTranslation(true));
    setInterval(() => this.updateContext(), 1000);
  }


  updateContext() {
    this.setCountdown();
    this.checkProviderRequirements();
  }
  ngOnChanges() {
    this.initCompontent()
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

  initCompontent() {
    this.checkSheetsPermission();
    this.checkAvailableLanguageSheets();
    this.student = this.authenticationService.currentUserSubject.value;

    if (this.nextSession) {
      this.myTeacher = this.nextSession.teacher;
      this.updateContext();
    } else {
      this.nextSession = null;
    }

  }

  /**
   * Methode pour annuler une occurrence et l'ajouter à la liste des occurrences annulées.
   * @param _occurrence
   * @param _index
   */
  cancelOccurence() {
    // On test si le délai d'annulation.
    const lessOneDay = moment(this.nextSession.start).diff(moment(Date.now()))
    const duration = moment.duration(lessOneDay);
    if (duration.asHours() > 24) {
      const occurrence_id = this.eventService.extractIdFromUrl(this.nextSession.url)
      this.router.navigate(['formation', this.eventService.extractIdFromUrl(this.formation.url), 'upcomings', 'cancelsession', occurrence_id]);
    } else {
      this.toastService.show('Il est impossible d\'annuler un cours moins de 24 h avant le début du cours', 3000, 'red');
    }
  }

  showSheet(object) {
    this.router.navigate(['/formation/' + this.formation.id + '/sheets/sheet/' + object.sheetId]);
  }

  checkAvailableLanguageSheets() {
    this.isSheetLanguageAvailable = this.authenticationService.checkAvailableLanguageSheets(this.formation.language_detail.id);
  }

  checkSheetsPermission() {
    this.sheetPermission = this.authenticationService.checkFeaturePermission('sheets');
  }
  showModalTeacherProfil(teacher) {
    this.componentToLoad = 'teacherProfil';
    this.modalParams = { teacher: teacher, showReviews: false }
  }

  setCountDownTranslation(refreshCountDown = false) {
    this.translateService.stream('common.jour').subscribe((text: string) => { this.countDownLabelDay = text; if (refreshCountDown) { this.setCountdown() } });
    this.translateService.stream('common.jours').subscribe((text: string) => { this.countDownLabelDays = text; if (refreshCountDown) { this.setCountdown() } });
    this.translateService.stream('common.hours').subscribe((text: string) => { this.countDownLabelHours = text; if (refreshCountDown) { this.setCountdown() } });
    this.translateService.stream('common.minutes').subscribe((text: string) => { this.countDownLabelMinutes = text; if (refreshCountDown) { this.setCountdown() } });
    this.translateService.stream('common.seconds').subscribe((text: string) => { this.countDownLabelSeconds = text; if (refreshCountDown) { this.setCountdown() } });
    this.translateService.get('lingueopro.dashboard.upcoming_sessions.detail-formation').subscribe((text: string) => { this.titleLabel = text; });
  }

  /**
  * Definit le lien d'appel skype de l'élève
  */
  skypeLink() {
    if (this.myTeacher.user.id_skype) {
      const skypeID = 'skype:' + this.myTeacher.user.id_skype + '?call';
      return this.sanitizer.bypassSecurityTrustUrl(skypeID);
    }
  }


  /**
   * Methode pour l'affichage du decompte
   * definiton du status d'affichage en fonction du temps restant.
   */
  setCountdown() {
    if (this.nextSession) {
      const _sessionDiff = moment(this.nextSession.end).diff(this.nextSession.start); // interval de temps entre start vs end
      const sessionDuration = moment.duration(_sessionDiff); // la durée
      let halfTime = null; // 30 min
      // definiton du half time si > 1h
      if (sessionDuration.asHours() > 1) {
        const calc = sessionDuration.asHours() / 2;
        halfTime = moment(this.nextSession.start).add(calc.toString(), 'hours')
      } else {
        halfTime = moment(this.nextSession.start).add('15', 'minutes'); // si inférieur à 1h, on crée un object moment de 15min.
      }

      // Définition du status du decompte.
      const now = moment();
      let diff = moment(this.nextSession.start).diff(now); // object moment entre le start vs now.
      this.durationMs = diff;
      this.duration = moment.duration(diff); // durée restant start vs now
      if (this.duration.asHours() < 0) {
        if (moment(now).isAfter(moment(this.nextSession.end))) {
          this.countdownStatus = 'finish';
          diff = moment(now).diff(this.nextSession.end);
          this.duration = moment.duration(diff);
        } else if (moment(now).isAfter(moment(halfTime))) {
          this.countdownStatus = 'from';
          diff = moment(now).diff(this.nextSession.start);
          this.duration = moment.duration(diff);

        } else {
          this.countdownStatus = 'from';
          diff = moment(now).diff(this.nextSession.start);
          this.duration = moment.duration(diff);
        }
      } else {
        this.countdownStatus = 'in';
      }
      if (Math.round(this.duration.asDays()) === 1) {
        this.countdown = Math.ceil(this.duration.asDays()) + ' ' + this.countDownLabelDay;
      }
      if (Math.ceil(this.duration.asDays()) > 1) {
        this.countdown = Math.ceil(this.duration.asDays()) + ' ' + this.countDownLabelDays;
      } else if (Math.floor(this.duration.asHours()) >= 1) {
        this.countdown = this.duration.hours() + ' ' + this.countDownLabelHours + ' ' + this.duration.minutes() + ' ' + this.countDownLabelMinutes;
      } else if (Math.floor(this.duration.asHours()) < 1) {
        this.countdown = this.duration.minutes() + 'min ' + this.duration.seconds() + 's';
      } else {
        this.countdown = Math.floor(this.duration.asHours()) + ' h ' + this.duration.minutes() + ' min ' + this.duration.seconds() + 's';
      }
    }
  }

  setIsSheetAssignedOutput(state) {
    this.isSheetAssigned = state;
  }

  checkProviderRequirements() {
    // return this.isValidProvider = true;
    if (this.nextSession) {
      if (this.nextSession.provider_link) {
        // le bouton est valide pendant tout le cours et 5 minutes avant
        if (moment().isBetween(moment(this.nextSession.start).subtract(5, 'minutes'), moment(this.nextSession.end))) {
          this.isValidProvider = true;
        } else {
          this.isValidProvider = false;
        }
      }
    }
  }

  markAsClicked() {
    const params = {
      'is_student_provider_link_clicked': moment().toISOString(),
    }
    this.occurrenceService.patchObservableOccurrence(this.nextSession.url, params).subscribe(occurrence => {
      if (occurrence) {
        this.nextSession = occurrence;
        this.openTeamsLink();
      }
    });
  }

  openTeamsLink() {
    window.open(this.nextSession.provider_link, '_blank');
  }

  joinClass() {
    this.markAsClicked();
  }
}
