import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import * as moment from 'moment';
import { KiwixiService } from '../../../../../../kiwixi/kiwixi.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input() allContacts;
  @Input() contact;
  myLink: any;
  defaultAvatar = environment.static + '/avatar.png';
  isAdmin = false;
  isToday = false;

  constructor(private kiwixiService: KiwixiService) { }

  ngOnInit() {
    if (this.contact.messages.length > 0) {
      this.checkPersona();
      const today = moment();
      const contactDay = moment(this.contact.messages[0].updated_at);
      if (today.format('DD/MM/YYYY') === contactDay.format('DD/MM/YYYY')) {
        this.isToday = true;
      }
    }
    switch (this.contact.role) {
      case 'student':
        this.myLink = './formation/' + this.contact.formation_id + '/student/' + this.contact.id;
        break;
      case 'teacher':
        this.myLink = './teacher/' + this.contact.id;
        break;
      default:
        break;
    }
  }

  checkPersona() {
    this.isAdmin = !(this.contact.messages[0].from_user.search('students') <= 0 || this.contact.messages[0].from_user.search('teachers') <= 0);
  }

}

