<div class="valign-wrapper chosen-row" *ngIf="showTeacher">
  <div class="teacherinfo-wrapper valign-wrapper">
    <div class="avatar-wrapper waves-effect" (click)="displayProfilTeacher(teacher)">
      <img src="{{teacher_obj.user.photo_profile.thumbnail}}" class="user-avatar" *ngIf="teacher_obj.user.photo_profile !== null; else defaultAvatarTag"
        (error)="this.teacherService.errorImgHandler($event)">
      <ng-template #defaultAvatarTag>
        <img src="{{defaultAvatar}}" class="user-avatar">
      </ng-template>
    </div>
    <div class="teacherdetail-wrapper">
      <span class="waves-effect teachername" (click)="displayProfilTeacher(teacher)">{{teacher_obj.user.first_name}} {{teacher_obj.user.last_name}}</span>
      <br/>
      <span>{{event.start | amDateFormat:'dddd'}}s {{event.start | amDateFormat:'HH:mm'}}-{{event.end | amDateFormat:'HH:mm'}}</span>
    </div>
  </div>
</div>