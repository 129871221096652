<div id="assigned-list" *ngIf="occurrence">
    <div class="sheetslist-title"><span translate>sheet-assign.assigned.title</span></div>

    <div class="sheetlist-wrapper">
        <div class="sheetlist-row" *ngFor="let task of taskSheetToDo">
            <div class="sheetlist-subwrap">
                <div class="sheet-icon" [ngClass]="{'sheet-validated': task.what.is_validate_by_student==true, 'sheet-todo': task.what.is_todo_by_student==true}"></div>
                <span class="sheet-title">{{ task.what.title_fr }}</span>
                <button class="seesheet-btn seesheet-btn-desktop" (click)="emitViewEmitter(task.what, 'desktop')"></button>
                <button class="seesheet-btn seesheet-btn-mobile" (click)="emitViewEmitter(task.what, 'mobile')"></button>
            </div>
            <button class="deletesheet-btn" (click)="deleteTask(task)"></button>
        </div>
    </div>
</div>
<div id="available-list" >
    <div class="sheetslist-title"><span translate>sheet-assign.available.sheets</span> </div>
    <div>
        <div class="assign-filter-wrap" [formGroup]="filterSearchGroup">
            <input class="assign-filter-field" type="text" id='search' formControlName="title_fr" placeholder="Rechercher par nom">
            <button class="assign-filter-btn" (click)="showFilterMenu = !showFilterMenu"><span class="filter-icon"></span><span translate>common.filter</span></button>
        </div>
      

    </div>
    <div class="filter-and-list-wrap">
        <div class="filter-options-wrapper" *ngIf="showFilterMenu" [formGroup]="filterFormGroup">
                <div class="filter-options">
                    <div class="filter-header"><span class="filter-icon"></span><span translate>sheet-assign.available.filter.learning</span><div class="close-btn" (click)="showFilterMenu=false"></div></div>
                    <div class="filter-criteria">
                        <div>
                            <mat-radio-group formControlName="cecrl_level">
                                <div class="filter-title"><span translate>common.level</span> :</div>
                                <mat-radio-button value=""><span translate>common.all</span></mat-radio-button>
                                <div class="opt-btn-wrap level-options">
                                    <mat-radio-button *ngFor="let level of cecrlLevelList" value="{{ level }}">{{ level }}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="filter-criteria">
                        <div>
                            <mat-radio-group formControlName="category">
                                <div class="filter-title"><span translate>sheet-assign.filter.categories</span> :</div>
                                <mat-radio-button value=""><span translate>common.all</span></mat-radio-button>
                                <div class="opt-btn-wrap">
                                    <mat-radio-button *ngFor="let category of categoryList" value="{{ category.id}}">{{ category.name}}</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="filter-criteria">
                        <div>
                            <mat-radio-group formControlName="status">
                                <div class="filter-title"><span translate>sheet-assign.filter.status</span> :</div>
                                <mat-radio-button value=""><span translate>common.all</span></mat-radio-button><br>
                                <div class="opt-btn-wrap">
                                    <mat-radio-button value="todo"><span translate>sheet-assign.filter.todo</span></mat-radio-button>
                                    <mat-radio-button value="completed"><span translate>sheet-assign.filter.completed</span></mat-radio-button>
                                    <mat-radio-button value="uncompleted"><span translate>sheet-assign.filter.uncompleted</span></mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="filter-criteria" *ngIf="occurrence && isDataSpecificContentReadyObservable.value">
                        <div *ngIf="groupList.length>0">
                            <mat-radio-group formControlName="specific_content">
                                <div class="filter-title"><span translate>sheet-assign.filter.specific_content</span> :</div>
                                <mat-radio-button value=""><span translate>common.all</span></mat-radio-button><br>
                                <mat-radio-button *ngFor="let group of limitedGroupList" value="{{ group.id }}"> {{ group.name}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="filter-criteria" *ngIf="!occurrence && isDataSpecificContentReadyObservable.value">
                         <div *ngIf="groupList.length>0">
                            <div class="filter-title"><span translate>sheet-assign.filter.specific_content</span> :</div>
                            <mat-select class="speccontent-select" formControlName="limited_specific_content">
                                <mat-option value=""><span translate>common.all</span></mat-option>
                                <mat-option *ngFor="let group of groupList"  [value]="group.id">
                                {{ group.name}}
                                </mat-option>
                            </mat-select>

                        </div>
                    </div>

                    <button class="show-filtered-btn" (click)="newSearch();showFilterMenu=false;" [disabled]="loadSheets"><span translate>sheet-assign.filter.see_filtered_list</span> ({{ countTotalSheets }}) <mat-spinner class="loader" *ngIf="loadSheets"></mat-spinner></button>
                    <div class="reset-filters-btn" (click)="resetFilters()"><span translate>sheet-assign.filter.reset_filters</span></div>
                </div>
            </div>
        <div class="sheetlist-wrapper">
            
            <div *ngFor="let sheet of sheetList">
                <div class="sheetlist-row" *ngIf="checkSpecificContent(sheet)">
                   <div class="sheetlist-subwrap">
                        <div class="sheet-icon" [ngClass]="{'sheet-validated': sheet.is_validate_by_student==true, 'sheet-todo': sheet.is_todo_by_student==true}"></div>
                        <span class="sheet-title">{{ sheet.title_fr }}</span>
                        <button class="seesheet-btn seesheet-btn-desktop" (click)="emitViewEmitter(sheet, 'desktop')"></button>
                        <button class="seesheet-btn seesheet-btn-mobile" (click)="emitViewEmitter(sheet, 'mobile')"></button>
                    </div>
                    <button class="addsheet-btn" (click)="assignSheetToClass(sheet)" *ngIf="occurrence"></button>
                </div>
            </div>
            <div *ngIf="nextPage>1"><a (click)="getAvailableSheet()" class="showmore-btn">Afficher plus de fiches</a></div>
        </div>
    </div>
</div> 