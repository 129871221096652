import { empty } from 'rxjs';
import { PonctualBookingConclusionComponent } from './../../application/lingueopro/formation/edit-schedule/ponctual-booking/ponctual-booking-conclusion/ponctual-booking-conclusion.component';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { KiwixiService } from './../../kiwixi/kiwixi.service';
import { Component, Input, ViewChild, ViewContainerRef, OnChanges, ComponentFactoryResolver, Output, EventEmitter, OnInit } from '@angular/core';
import { TeacherProfilComponent } from '../teacher-profil/teacher-profil.component';
import { InitPonctualBookingConclusionComponent } from '../../application/lingueopro/plan-formation/init-ponctual-booking/init-ponctual-booking-conclusion/init-ponctual-booking-conclusion.component';
import { InitPonctualDemoTourComponent } from '../../application/lingueopro/plan-formation/init-ponctual-booking/init-ponctual-demo-tour/init-ponctual-demo-tour.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() componentToLoad;
  @Input() modalParams;
  @Input() allowClose;
  @Output() emitEvent = new EventEmitter();
  @ViewChild('modalcontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  overlay
  element;
  randomId;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private kiwixiService: KiwixiService,
  ) { }

  ngOnInit() {
    this.entry.clear();
    this.randomId = Math.floor(Math.random() * 10000) + 1;
  }

  ngOnChanges() {
    this.element = document.getElementById('basemodal-' + this.randomId)
    if (this.element) {
      if (this.componentToLoad) {

        switch (this.componentToLoad) {
          case 'teacherProfil': {
            const factory = this.resolver.resolveComponentFactory(TeacherProfilComponent);
            this.overlay = this.entry.createComponent(factory);
            this.overlay.instance.teacher = this.modalParams.teacher;
            this.overlay.instance.showReviews = this.modalParams.showReviews;
            break;
          }
          case 'init-ponctual-booking-conclusion': {
            const factory = this.resolver.resolveComponentFactory(InitPonctualBookingConclusionComponent);
            this.overlay = this.entry.createComponent(factory);
            this.overlay.instance.classCreated = this.modalParams.classCreated;
            this.overlay.instance.mode_formation = this.modalParams.mode_formation;
            this.overlay.instance.showDemoEmiter.subscribe(event => {
              this.emitEvent.emit()
              this.close();
            });
            break;
          }
          case 'init-ponctual-demo-tour': {
            const factory = this.resolver.resolveComponentFactory(InitPonctualDemoTourComponent);
            this.overlay = this.entry.createComponent(factory);
            break;
          }
          case 'ponctual-booking-conclusion': {
            const factory = this.resolver.resolveComponentFactory(PonctualBookingConclusionComponent);
            this.overlay = this.entry.createComponent(factory);
            this.overlay.instance.classCreated = this.modalParams.classCreated;
            this.overlay.instance.formation = this.modalParams.formation;
            this.overlay.instance.mode_formation = this.modalParams.mode_formation;
            break;
          }
          default: {
            this.kiwixiService.toastService.show('Aucun composant à afficher dans le modal', 500, 'red')
            return;
            break;
          }
        }
      }

      this.show();
    }
  }

  show() {
    if (this.element) {
      document.body.appendChild(this.element);
      this.element.style.display = 'block';

      const that = this;
      // close modal on background click
      this.element.addEventListener('click', function (e: any) {
        if (e.target.className === 'modalbackground' || e.target.className === 'modalcontent') {
          that.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.close();
  }

  close() {
    if (this.element) {
      this.element.style.display = 'none';
      if (this.overlay) {
        this.overlay.destroy();
      }
    }
  }
}
