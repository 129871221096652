/**
 * service local pour les infroamtions d'une formation.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Formation } from '../../../../../model/formation.model';
import { Staff } from '../../../../../model/staff.model';
import { KiwixiService } from '../../../../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class InformationsService extends KiwixiService {

  apiStaff = environment.server + 'staffs/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getManagers(): Promise<Staff[]> {
    const headers = this.createHttpClientAuthorizationHeader();
    const _params = new HttpParams().set('page_size', '100');
    return this._http.get(this.apiStaff, { headers: headers, params: _params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  updateFormationVariable(formationUrl, param): Promise<Formation> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(formationUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

}
