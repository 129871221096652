<ng-container *ngIf="isLoaded; else elseTemplate">
    <div class="timeslot-row" *ngFor="let event of events">
      <div class="timeslot-time">• {{event.start | amLocale: 'en' | amDateFormat:'dddd HH:mm'}} - {{event.end| amLocale: 'en' | amDateFormat:'HH:mm'}} with</div>
      <div class="teacher-name" *ngFor="let eventTeacher of event.teachers">
        <span *ngIf="teacher.id == eventTeacher.id" translate>{{translatePathTrainings}}.you</span>
        <span *ngIf="teacher.id != eventTeacher.id" translate>{{teacher.user.first_name}}</span>
      </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center pageload-message">
    <b translate>teacher.trainings.upcoming-loading</b>
  </div>
</ng-template>
