<ng-container *ngIf="!nextOccurrence && isLoaded">
		<div class="nonext nextclass-content">
			<div class="dashboard-spacetitle">
				<span translate>No lessons planned yet</span>
			</div>
			<div class="dashboard-maininfo">
				<span translate>{{translatePath}}.next-session-explaination</span>
			</div>
		</div>
	</ng-container>

<ng-container *ngIf="nextOccurrence && isLoaded">
  <div class="nextclass-content">
        <div class="dashboard-spacetitle">
          <span translate>{{translatePath}}.next-class-label</span>
          <span [ngSwitch]="countdownStatus">
            
              <span *ngSwitchCase="'from'" translate>{{translatePath}}.from-label</span>
           
           
              <span *ngSwitchCase="'outdated'" translate>{{translatePath}}.outdated-since-label</span>
         
         
              <span *ngSwitchCase="'finish'" translate>{{translatePath}}.finish-since-label</span>
         

              <span *ngSwitchDefault translate>{{translatePath}}.in-label</span>
              {{countdown}}
            </span>
        </div>
        
        <!-- student -->
        <div class="dashboard-maininfo">
          <div class="training-suspended" *ngIf="nextOccurrence.formation_status=='suspended'">Suspended</div>
          <div class="nextclass-time">
            {{nextOccurrence.start | amLocale: 'en' | amDateFormat:'ddd DD MMM'}}, 
            {{nextOccurrence.start | amLocale: 'en' | amDateFormat:'HH:mm'}} - {{nextOccurrence.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
          </div>
          <div class="nextclass-studname"><span translate>{{translatePath}}.with-label</span> {{student.user.first_name}} {{student.user.last_name}}</div>
          <div class="tag-specific-content" *ngIf="formation.specific_content">
            <span *ngFor='let sp of formation.specific_content'>
              <span *ngIf="sp.name!='default'">{{ sp.name }}</span>
            </span>
          </div>
        </div>

        <div class="nextclass-skypeid" *ngIf="student.user.video_provider=='skype'"><span>{{student.user.id_skype}}</span></div>

        <div class="seetraining-btn" *ngIf="formation" routerLink="/trainings/detail/{{formation.id}}/info">
          <span translate>{{translatePath}}.see-training</span>
        </div>
        <div>
          <button *ngIf="nextOccurrence.provider_link" (click)="joinClass()" class="teamscall-btn" [disabled]="!linkIsValid()">
           <span>Join class</span>
          </button>
        </div>
  </div>
</ng-container>

	<ng-container *ngIf='!isLoaded'>
		<div class="row center loading pageload-message">
			<span translate>{{translatePath}}.next-occurrence-loading</span>
		</div>
	</ng-container>