import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { StudentService } from '../../../../../service/student.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() contactId;
  @Input() nb_unread_message;
  @Input() conversationId;
  myLink: any;
  contact: any;
  defaultAvatar = environment.static + '/avatar.png';
  showContact: Boolean = false;
  constructor(private studentService: StudentService) { }

  ngOnInit() {
    if (this.contactId) {

      this.studentService.getObservableStudentById(this.contactId).subscribe(
        student => {
          this.contact = student;
          this.myLink = './conversation/' + this.conversationId + '/student/' + this.contact.id;
          this.showContact = true;
        }
      )
    }
  }



}
