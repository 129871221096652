<div class="row information-container">

  <div class="left">
    <h6 class="center grey darken-1 white-text title-information">Cours</h6>
  </div>

  <div id="table-sessions" class="row datatable-position" *ngIf="source">
    <app-datatable class="col l12"
      [data]="dataSessions.data"
      [header]="dataSessions.header"
      [pageSize]="pageSize"
      [totalData]="totalData"
      [component]=this
      [updateMethod]=this.getDataSession
      (selectStatus)=updateStatus($event)
      [gotoMethod]=this.showDetailSession>
    </app-datatable>
  </div>
</div>
