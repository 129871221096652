<div class="page-title">Messagerie</div>
<div class="contactlist-wrapper">


      <!-- Search bar -->
      <div class="search-wrapper">
        <app-search-bar (setFiltering)="search($event)"></app-search-bar>
      </div>

      <mat-button-toggle-group [formControl]="filterSelectCtrl">
        <mat-button-toggle [value]=1>
          <span>Tous</span>
        </mat-button-toggle>
        <mat-button-toggle [value]=2>
          <span>Elèves</span>
        </mat-button-toggle>
        <mat-button-toggle [value]=3>
          <span>Professeurs</span>
        </mat-button-toggle>
      </mat-button-toggle-group>



  <ng-container *ngIf="showContacts; else elseTemplate">
    <div [ngSwitch]="filterSelect" class="contacts-wrapper">
      <!-- All -->
      <div *ngSwitchCase=1>
        <div class="contact-row" *ngFor = "let item of allContacts">
            <app-contact [allContacts]="allContacts" [contact]="item"></app-contact>
        </div>
      </div>
      <!-- students -->
      <div *ngSwitchCase=2>
        <div class="contact-row" *ngFor = "let item of studentsMessagesList">
          <app-contact [allContacts]="studentsMessagesList" [contact]="item"></app-contact>
        </div>
      </div>
      <!-- teachers -->
      <div *ngSwitchCase=3>
        <div class="contact-row" *ngFor = "let item of teachersMessagesList">
            <app-contact [allContacts]="teachersMessagesList" [contact]="item"></app-contact>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="chat-wrapper">
<!-- Chat should appear on the same page as contacts list -->
</div>

<ng-template #elseTemplate>
  <div class="row center">
    <mat-progress-spinner
      [color]="'warn'"
      [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</ng-template>

