import { Component, OnInit, Input } from '@angular/core';

// Model
import { Language } from '../../../model/language.model';

// Service
import { FormationService } from '../../../service/formation.service';
import { LanguageService } from '../../../service/language.service';

@Component({
  selector: 'app-datatable-cell-formation',
  templateUrl: './datatable-cell-formation.component.html',
  styleUrls: ['./datatable-cell-formation.component.scss']
})
export class DatatableCellFormationComponent implements OnInit {

  @Input() formation;
  my_formation: Object;
  my_language: Language;
  errorMessage = '';
  loading = true;

  constructor(private formationService: FormationService, private languageService: LanguageService) { }

  ngOnInit() {
    if (!(typeof (this.formation) === 'object')) {
      this.formationService.getFormationWithUrl(this.formation).then(
        requestData => {
          this.my_formation = requestData;
          this.getLanguages();
        },
        // handle the error, otherwise will break the Observable
        error => {
          this.errorMessage = <any>error;
        }
      );
    } else {
      this.loading = false;
      this.my_formation = this.formation;
      this.getLanguages();
    }
  }

  getLanguages() {
    if (!this.my_formation['language_detail'] || !(typeof (this.my_formation['language_detail']) === 'object')) {
      this.languageService.getObservableLanguageWithUrl(this.my_formation['language']).subscribe(
        _language => {
          if (_language) {
            // name or object ?
            this.my_language = _language;
            this.loading = false;
          }
        },
        // handle the error, otherwise will break the Observable
        error => {
          this.errorMessage = <any>error;
        }
      );
    } else {
      this.my_language = this.my_formation['language_detail']
    }
  }

}
