import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../guard/auth.guard';
import { MyaccountComponent } from './myaccount.component';
import { InformationComponent } from './information/information.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';

export const routes: Routes = [
  {
    path: 'myaccount',
    component: MyaccountComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: '', component: InformationComponent, canActivate: [AuthGuard] },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MyaccountRoutingModule { }
