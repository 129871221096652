/**
 * composant pour l' affichage de la liste des students.
 * qui contiendra le composant datatable permettant l'affichage du tableau des students.
 */

import { Component, OnInit } from '@angular/core';
import { Student } from '../../../../../model/student.model';
import { Router } from '@angular/router';
import { StudentService } from '../../../../../service/student.service';
import { UserService } from '../../../../../service/user.service';

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss', './students-list.ux.scss', '../../mainscreen.ux.scss'],
  providers: [StudentService]
})
export class StudentsListComponent implements OnInit {

  pageSize: number;
  students: Student[];
  dataStudents: Object;
  loading: boolean;
  start = 0;
  offset = 100;
  sort = 'ascending';
  search = '';
  private errorMessage: any = '';
  countPages = 1;
  totalData = 0;

  public dataHeaders = [
    {
      name: 'id',
      title: 'ID',
      type: 'string',
      ascending: false,
      filter_key: 'id__icontains',
      ordering_key: 'id',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'string'
    },
    {
      name: 'student',
      title: 'Eleve',
      type: 'user',
      ascending: false,
      filter_key: 'name',
      ordering_key: 'last_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'string'
    },
    {
      name: 'student_email',
      title: 'Email Eleve',
      type: 'email',
      ascending: false,
      filter_key: 'email__icontains',
      ordering_key: 'email',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'string'
    },
    {
      name: 'date',
      title: 'Date de création',
      type: 'calendar',
      ascending: false,
      filter_key: 'created_at__range',
      ordering_key: 'created_at',
      filter_type: 'calendar',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'date'
    },
    {
      name: 'sites',
      title: 'Sites',
      type: 'sites',
      ascending: false,
      filter_key: 'sites',
      ordering_key: 'students__sites__name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'string'
    }
  ]

  constructor(
    private router: Router,
    private studentService: StudentService,
    private userService: UserService) {
    this.dataStudents = {
      data: [],
      headers: this.dataHeaders
    }
  }

  ngOnInit() {
    this.pageSize = 100;
  }

  /**
   * Méthode pour récupérer les étudiants.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = [];
    }
    if (!_filterParams['ordering']) {
      _filterParams['ordering'] = {
        key: 'ordering',
        value: '-id'
      }
    }
    _filterParams['filtering']['is_student'] = true;

    this.userService.getUsers(_filterParams)
      .then(
        res => {
          this.totalData = res['count'];
          const _users = res['results'];
          if (_countPages === 1) {
            this.dataStudents['data'] = [];
          }
          for (let _i = 0; _i < _users.length; _i++) {
            this.setRowsData(_users[_i], _i);
          }
        },
        error => this.errorMessage = <any>error
      );
    return this.dataStudents['data'];
  }

  /**
   * Methode pour construire les lignes du tableau pour chaque student.
   * @param _user
   * @param _index
   */
  setRowsData(_user, _index) {
    const _date = new Date(_user.created_at);
    const row = {
      url: _user.url,
      id: _user.url.split('/')[6],
      student: _user,
      student_email: _user.email,
      date: _date.toLocaleDateString(),
    };
    this.dataStudents['data'].push(row);
    this.getUserSites(_user, _index)
  }

  /**
   * Methode pour récupérer les tites d'un user.
   * @param _user
   * @param _index
   */
  getUserSites(_user, _index) {
    const _sites = [];
    this.studentService.getMultipleStudents(_user.students)
      .subscribe(myStudents => {
        if (myStudents) {
          for (const myStudent of myStudents) {
            if (myStudent && myStudent['is_active']) {
              _sites.push(myStudent['site']);
            }
          }
        }
        if (this.dataStudents['data'][_index]) {
          this.dataStudents['data'][_index].sites = _sites;
        }
      },
        error => {
          console.error(error)
        })
  }

  /**
   * navigation vers un etudiant
   * @param row
   */
  showDetail(row) {
    this.router.navigate(['/students', row.id]);
  }

  /**
   * navigation vers la page de création d'un étudiant.
   * @param row
   */
  showCreateStudent() {
    this.router.navigate(['/students/create']);
  }
}
