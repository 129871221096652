/**
 * Annuler la session
 */

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { OccurrenceService } from '../../../../../../service/occurrence.service';

@Component({
  selector: 'app-cancel-session',
  templateUrl: './cancel-session.component.html',
  styleUrls: ['./cancel-session.component.scss']
})
export class CancelSessionComponent implements OnInit {
  @Input() pending;
  translatePath = 'teacher.reports';
  reasons = [
    { status: 'abs_student_without_justification', keyvalue: 'student_no_show_without_justification' },
    { status: 'abs_teacher_without_justification', keyvalue: 'teacher_no_show' }
  ];
  selected = null;
  reasonCtrl = new FormControl('', [Validators.required]);
  detailsCtrl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  noSessionForm: FormGroup = this.builder.group({
    reason: this.reasonCtrl,
    details: this.detailsCtrl
  });
  reasonChange = false;
  constructor(
    private router: Router,
    private occurrenceService: OccurrenceService,
    private builder: FormBuilder
  ) { }

  ngOnInit() {
    this.reasonCtrl = new FormControl(this.pending.status);
    this.detailsCtrl = new FormControl(this.pending.cancelled_reason);
    this.subcribeToFormChanges();
  }

  subcribeToFormChanges() {
    this.reasonCtrl.valueChanges.subscribe(value => {
      this.reasonChange = true;
    });
  }

  sendReport() {
    this.occurrenceService.patchOccurrence(this.pending.url, { status: this.reasonCtrl.value.status, cancelled_reason: this.detailsCtrl.value })
      .then(_ => {
        this.router.navigate(['/reports']);
      })
  }

}
