import { invoiceLogExport } from './../model/invoiceLogExport.model';
import { InvoiceListFilter } from './../model/invoiceListFilter.model';
import { FilterListComponent } from './../feature/filter-list/filter-list.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Invoice } from '../model/invoice.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import * as moment from 'moment';
import { InvoiceList } from '../model/invoiceList.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { InvoiceValidity } from '../model/invoiceValidity.model';

@Injectable()
export class InvoiceService extends KiwixiService {

  apiInvoices = environment.server + 'invoices/';
  apiCurrent = this.apiInvoices + 'current/';
  public filterInvoiceListing: BehaviorSubject<InvoiceListFilter> = new BehaviorSubject<InvoiceListFilter>(null);


  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  setFilterListing(data) {
    const invoicesFilter: InvoiceListFilter = new InvoiceListFilter;
    invoicesFilter.id = data.id;
    invoicesFilter.invoice_id = data.invoice_id;
    invoicesFilter.teacher = data.teacher;
    invoicesFilter.date_from = data.date_from;
    invoicesFilter.date_to = data.date_to;
    invoicesFilter.amount_from = data.amount_from;
    invoicesFilter.amount_to = data.amount_to;
    invoicesFilter.status = data.status;
    invoicesFilter.upload_date_from = data.upload_date_from;
    invoicesFilter.upload_date_to = data.upload_date_to;
    invoicesFilter.paiement = data.paiement;
    this.filterInvoiceListing.next(invoicesFilter);
  }

  getInvoiceById(_id): Observable<Invoice> {
    const url = this.apiInvoices + _id + '/';
    return this.getInvoice(url);
  }

  getInvoice(_url): Observable<Invoice> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Invoice>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<any>(`getInvoice`))
      );
  }

  getCurrentInvoice(): Observable<any> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<any>(this.apiCurrent, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<any>(`getCurrentInvoice`))
      );
  }

  patchInvoice(invoiceUrl, param): Promise<Invoice> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(invoiceUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(res => {
        const error = res;
        if (error && error.error && error.error[0]) {
          this.toastService.show('Erreur' + error.error[0], 4000, 'red');
        } else {
          this.toastService.show('Erreur patchInvoice', 4000, 'red');
        }
      })
  }

  postBulkInvoice(body) {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post(this.apiInvoices + 'bulkupdate/', body, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable(`Modification par lot des factures`)),
      );
  }

  checkValidityInvoices(selectedInvoices): Observable<InvoiceValidity[]> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post<InvoiceValidity[]>(this.apiInvoices + 'export/check_invoice_validity/', selectedInvoices, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<InvoiceValidity[]>(`Verification de la validité des factures`)),
      );
  }

  getInvoices(_filterParams): Promise<Invoice[]> {
    let params = new HttpParams();
    // ordering:
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params = params.set(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params = params.set(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      const _page = _filterParams['page'];
      params = params.set(_page.key, _page.value);
    }
    if (_filterParams['teacher']) {
      const _page = _filterParams['teacher'];
      params = params.set(_page.key, _page.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiInvoices, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getObservableInvoices(params: HttpParams): Observable<InvoiceList> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<InvoiceList>(this.apiInvoices, {
      headers: header,
      params: params
    })
      .map(_invoices => _invoices)
      .pipe(
        catchError(this.handleErrorObservable<InvoiceList>(`getObservableInvoices`))
      );
  }

  getActivatedFilter(page_size, page, orderingField, orderingDirection) {
    let _params = new HttpParams();

    if (this.filterInvoiceListing.value) {
      if (this.filterInvoiceListing.value.id) {
        _params = _params.set('id', this.filterInvoiceListing.value.id.toString());
      }
      if (this.filterInvoiceListing.value.invoice_id) {
        _params = _params.set('invoice_id', this.filterInvoiceListing.value.invoice_id.toString());
      }
      if (this.filterInvoiceListing.value.teacher) {
        _params = _params.set('teacher__name', this.filterInvoiceListing.value.teacher);
      }
      if (this.filterInvoiceListing.value.date_from || this.filterInvoiceListing.value.date_to) {
        const date_from = this.filterInvoiceListing.value.date_from ? moment(this.filterInvoiceListing.value.date_from).format('YYYY-MM-DD') : '';
        const date_to = this.filterInvoiceListing.value.date_to ? moment(this.filterInvoiceListing.value.date_to).format('YYYY-MM-DD') : '';
        _params = _params.set('periodicity__range', date_from + ',' + date_to);
      }
      if (this.filterInvoiceListing.value.amount_from) {
        _params = _params.set('amount__gte', this.filterInvoiceListing.value.amount_from.toString());
      }
      if (this.filterInvoiceListing.value.amount_to) {
        _params = _params.set('amount__lt', this.filterInvoiceListing.value.amount_to.toString());
      }
      if (this.filterInvoiceListing.value.status) {
        _params = _params.set('status__icontains', this.filterInvoiceListing.value.status);
      }
      if (this.filterInvoiceListing.value.upload_date_from || this.filterInvoiceListing.value.upload_date_to) {
        const date_from = this.filterInvoiceListing.value.upload_date_from ? moment(this.filterInvoiceListing.value.upload_date_from).format('YYYY-MM-DD') : '';
        const date_to = this.filterInvoiceListing.value.upload_date_to ? moment(this.filterInvoiceListing.value.upload_date_to).format('YYYY-MM-DD') : '';
        _params = _params.set('uploaded_at__range', date_from + ',' + date_to);
      }
      if (this.filterInvoiceListing.value.paiement) {
        _params = _params.set('payment_method', this.filterInvoiceListing.value.paiement);
      }
    }
    _params = _params.set('page', page + 1);
    if (orderingDirection === 'desc') {
      orderingField = '-' + orderingField;
    }
    _params = _params.set('ordering', orderingField);

    _params = _params.set('page_size', page_size);
    return _params;
  }

  getInvoicesByFilter(page_size, page, orderingField, orderingDirection): Observable<InvoiceList> {
    const _params = this.getActivatedFilter(page_size, page, orderingField, orderingDirection);
    return this.getObservableInvoices(_params);
  }

  bulkUpdate(new_status, isModeAllPage, allIds, selectedInvoices): Observable<any> {
    const params = {}
    params['selected_invoices'] = selectedInvoices;
    params['new_status'] = new_status;
    return this.postBulkInvoice(params);
  }

  getInvoicesStatus() {
    return [
      {
        label: 'Reçue',
        code: 'received',
        valid: true
      },
      // {
      //   label: 'En Cours',
      //   code: 'ongoing',
      //   valid: true
      // },
      {
        label: 'En attente',
        code: 'pending',
        valid: true
      },
      {
        label: 'Timeout',
        code: 'expired',
        valid: true
      },
      {
        label: 'Réglée',
        code: 'paid',
        valid: true
      }
    ];
  }

  getInvoicesPayment() {
    return [
      {
        label: 'Paypal',
        code: 'paypal',
        valid: true
      },
      {
        label: 'IBAN',
        code: 'iban',
        valid: true
      }
    ];
  }

  downloadExport(_url, _filename, _contentType) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get(_url, { headers: header, responseType: 'arraybuffer' })
      .map((file) => {
        const myFile = new Uint8Array(file);
        const blob = new Blob([myFile], { type: _contentType });
        const url = window.URL.createObjectURL(blob);
        const $a = $('<a />', {
          'href': url,
          'download': _filename,
          'text': 'click'
        }).hide().appendTo('body')[0].click();
      }).toPromise();
  }



  postExport(params) {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post<invoiceLogExport>(this.apiInvoices + 'export/', params, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<invoiceLogExport>(`Création d'une réponse type choice`)),
      );
  }

  private sendDocument<Document>(file, _id) {
    const url = this.apiInvoices + _id + '/';
    const header = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    header.delete('Content-Type');
    _formData.append('source', file);
    _formData.append('name', file.name);
    return this._http.post(url, _formData, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Document>(`upload invoice`))
    )
  }

  public sendDocuments<Message>(files, _id) {
    const obx = [];
    for (const file of files) {
      obx.push(this.sendDocument(file, _id))
    }
    return forkJoin<Document>(obx)
  }

  patchFile(invoiceUrl, param, field, file): Promise<Invoice> {
    const self = this;
    const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    self.headers.delete('Content-Type');
    _formData.append(field, file);
    _formData.append('name', file.name);
    return this._http.patch(invoiceUrl, _formData, { headers: headers })
      .toPromise()
      .then((res: any) => res)
  }

  getRecalculation(_invoiceUrl): Observable<any> {
    const header = this.createHttpClientAuthorizationHeader();
    const _url = _invoiceUrl + 'recalculation/'
    return this._http.get<any>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<any>(`getRecalculation`))
      );
  }

  getDuration(_occ) {
    const ms = moment(moment(_occ.end), 'HH:mm').diff(moment(moment(_occ.start), 'HH:mm'));
    return ms;
  }

  getFormatDuration(_ms) {
    const _duration = moment.utc(moment.duration(_ms).asMilliseconds());
    return _duration.format('H') + 'h' + _duration.format('mm');
  }

}
