import { RatingStudent } from './../../../../../model/rating-student.model';
import { HttpParams } from '@angular/common/http';
import { SessionService } from './../../../../../service/session.service';
/**
 * Etape 2-a : édition d'un rapport existant.
 */

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingService } from '../../../../../service/pending.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { RateStudentService } from '../../../../../service/rate-student.service';
import { FormationService } from '../../../../../service/formation.service';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {
  pending: any;
  occurrence_id: any;
  formation: any;
  existingReport: any;
  existingReport_date: any;
  translatePath = 'teacher.reports';
  showLevel = true;
  lastReport: any;
  items: any[] = [];
  editMode = false;
  sendReportIsActive = true;
  defaultAvatar = environment.static + '/avatar.png';

  /* ****** Variables pour le formulaire ****** */
  grammarCtrl = new FormControl('', [Validators.required]);
  expressionCtrl = new FormControl('', [Validators.required]);
  comprehensionCtrl = new FormControl('', [Validators.required]);
  vocabularyCtrl = new FormControl('', [Validators.required]);
  accentCtrl = new FormControl('', [Validators.required]);
  levelForm: FormGroup = this.builder.group({
    grammar: this.grammarCtrl,
    expression: this.expressionCtrl,
    comprehension: this.comprehensionCtrl,
    vocabulary: this.vocabularyCtrl,
    accent: this.accentCtrl
  });
  alternativeSelected = null;
  levelFormChange = false;
  subjects: any;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    private pendingService: PendingService,
    private ratingStudentService: RateStudentService,
    private formationService: FormationService,
    private sessionService: SessionService,
    private builder: FormBuilder
  ) { }

  ngOnInit() {
    this.items = this.reportService.subjectsItem;
    this.occurrence_id = this.route.snapshot.params.id;
    this.pendingService.getPending(this.occurrence_id)
      .subscribe(_pending => {
        this.pending = _pending;
        if (this.pending) {
          this.formationService.getFormationWithUrl(this.pending.formation)
            .then(
              _formation => {
                this.formation = _formation;
                this.subjects = this.reportService.getSubjects(this.pending.session.rate_teacher);
                this.setLastReport();
                this.subcribeToFormChanges();
              }
            );
        }
      });
  }

  setLastReport() {
    if (this.formation) {
      let params = new HttpParams();
      params = params.append('status', 'completed');
      params = params.append('session__formation__student', this.formation.student.id.toString());
      params = params.append('session__formation__language', this.formation.language_detail.id.toString());
      params = params.append('page_size', '1');
      this.ratingStudentService.getRatingStudents(params).subscribe(rates => {
        if (rates['results'].length > 0) {
          const rate: RatingStudent = rates['results'][0];
          this.lastReport = this.reportService.getLastReportData(rate);
          if (this.lastReport) {
            this.checkIfSubjectsIsEmpty()
            this.lastReport.forEach(_item => {
              if (_item.level.value === 100 && !_item.level.levelUp) {
                // debugger;
                this.sendReportIsActive = false;
              }
            });
          }
          this.reportService.initFormBuider(this.levelForm, rate);
        }
      });
    }
  }

  checkIfSubjectsIsEmpty() {
    let countZero = 0;
    this.subjects.forEach(_subject => {
      if (_subject.level.value === 0) {
        countZero++;
      }
    });
    if (countZero === 5) {
      this.subjects = [];
      this.lastReport.forEach(_last => {
        const obj = {
          label: _last.label,
          level: {
            start_label: _last.level.start_label,
            end_label: _last.level.end_label,
            value: _last.level.value,
            levelUp: _last.level.levelUp
          },
          name: _last.name
        }
        this.subjects.push(obj);
      });
    } else {
      this.editMode = true;
    }
  }

  /**
   * Methode pour souscrire aux changements dans le formulaire.
   */
  subcribeToFormChanges() {
    const myFormValueChanges$ = this.levelForm.valueChanges;
    myFormValueChanges$.subscribe(data => {
      this.levelFormChange = true;
    })
  }

  /**
   * Methode pour enregistrer le report
   */
  register() {
    this.reportService.register(this.levelForm, this.pending, 'completed')
      .then(res => {
        this.ratingStudentService.toastService.show(
          `Compte rendu Sauvegardé`,
          1000,
          'green'
        )
        this.router.navigate(['/reports']);
      })
  }

  /**
   * Methode pour mettre à jour la valeur d'un crontrolleur du formulaire.
   * @param _level
   */
  handleSubject(_level) {
    this.sendReportIsActive = true;
    this.subjects.forEach(_item => {
      if (_item.level.value === 100 && !_item.level.levelUp) {
        this.sendReportIsActive = false;
      }
    });
    const param = {};
    param[_level.name] = {
      label: _level.level.start_label,
      value: _level.level.value
    };
    if (_level.level.levelUp) {
      param[_level.name].label = _level.level.end_label
    }
    this.levelForm.patchValue(param);
  }

  disableStyle() {
    if (this.editMode) {
      return 'grey-text text-lighten-1'
    }
  }

  sendReport(_status) {
    this.subjects.forEach(_item => {
      if (_item.level.value === 100 && _item.level.levelUp && _item.level.end_label !== 'C2') {
        _item.level.value = 10;
        switch (_item.level.start_label) {
          case 'A1':
            _item.level.start_label = 'A2'
            _item.level.end_label = 'B1'
            break;
          case 'A2':
            _item.level.start_label = 'B1'
            _item.level.end_label = 'B2'
            break;
          case 'B1':
            _item.level.start_label = 'B2'
            _item.level.end_label = 'C1'
            break;
          case 'B2':
            _item.level.start_label = 'C1'
            _item.level.end_label = 'C2'
            break;
          default:
            break;
        }
      }
      if (_item.level.value === 0 && _item.level.start_label !== 'A1') {
        _item.level.value = 100;
        switch (_item.level.start_label) {
          case 'A2':
            _item.level.start_label = 'A1'
            _item.level.end_label = 'A2'
            break;
          case 'B1':
            _item.level.start_label = 'A2'
            _item.level.end_label = 'B1'
            break;
          case 'B2':
            _item.level.start_label = 'B1'
            _item.level.end_label = 'B2'
            break;
          case 'C1':
            _item.level.start_label = 'B2'
            _item.level.end_label = 'C1'
            break;
          default:
            break;
        }
      }
      const param = {};
      param[_item.name] = {
        start_label: _item.level.start_label,
        value: _item.level.value
      };
      this.levelForm.patchValue(param);
    });
    this.reportService.register(this.levelForm, this.pending, _status)
      .then(res => {
        this.ratingStudentService.toastService.show(
          `Compte rendu Sauvegardé`,
          1000,
          'green'
        )
        this.router.navigate(['/reports']);
      })
  }

  completeLater() {
    if (this.levelFormChange) {
      this.sendReport('draft');
    } else {
      this.router.navigate(['/reports']);
    }
  }

  addProgression(idx, e) {
    let old_value = 0
    if (this.lastReport) {
      old_value = Number(this.lastReport[idx].level.value)
    }
    let progression = old_value + Number(e.value);
    if (progression > 100) {
      progression = 100;
    }
    this.subjects[idx].level.value = progression;
    const param = {};
    param[this.subjects[idx].name] = {
      label: this.subjects[idx].level.start_label,
      value: this.subjects[idx].level.value
    };
    if (this.subjects[idx].level.levelUp) {
      param[this.subjects[idx].name].label = this.subjects[idx].level.end_label
    }
    this.levelForm.patchValue(param)
  }

}
