import { Teacher } from './teacher.model';
import { Session } from './session.model';
import { Attachment } from './attachment.model';
import { Student } from './student.model';

export class Occurrence {
  id: number;
  url: string
  attachment: Attachment;
  formation: string;
  moved: boolean;
  abstract: boolean;
  session: Session;
  student: Student;
  teacher: Teacher;
  title: string;
  description: string;
  start: Date;
  end: Date;
  original_start: Date;
  original_end: Date;
  provider_link: string;
  is_student_class_warning_showed: Date;
  is_student_provider_link_clicked: Date;
  is_teacher_provider_link_clicked: Date;
  alternative_date_1: Date;
  alternative_date_1_status: string;
  alternative_date_2: Date;
  alternative_date_2_status: string;
  alternative_date_3: Date;
  alternative_date_3_status: string;
  lost: boolean;
  is_ratable: boolean;
  is_next: boolean;
  created_on: Date;
  updated_on: Date;
  status: string;
  cancelled_status: string;
  cancelled_reason: string;
  cancelled_message: string;
  cancelled_at: Date;
  sub_teacher: string;
  event: string;
  class_type: string;
  constructor() { }

}
