
<h5 class="page-title">Nouvelle formation</h5>
<div class="row" >
  <div class="col l4">
    <app-new-formation-information
      [component]=this
      [newFormation]="formation"
      (newFormationOutput)="handleUpdateFormation($event)">
    </app-new-formation-information>
  </div>
  <div class="col l6" *ngIf="formation.url">
    <app-new-formation-recurrences *ngIf="showReccurrencesDebugg" [newFormation]="formation" (newFormationOutput)="refresh()"></app-new-formation-recurrences>
  </div>
</div>
