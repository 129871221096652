/**
 * Sous-composant pour l'affichage de la Progression.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Progression } from '../../../../../model/progression.model';
import { Formation } from '../../../../../model/formation.model';
import { Occurrence } from '../../../../../model/occurrence.model';
import { KiwixiGlobals } from '../../../../../kiwixi/kiwixi.globals';
import { FormationService } from '../../../../../service/formation.service';
import { TeacherService } from '../../../../../service/teacher.service';

@Component({
  selector: 'app-progression',
  templateUrl: './progression.component.html',
  styleUrls: ['./progression.component.scss', './progression.ux.scss', '../formation.ux.scss', '../../mainscreen.ux.scss'],
  providers: [FormationService]
})
export class ProgressionComponent implements OnInit {

  @Input() formation: Formation; // input du parent.
  dataSessions: Object[];
  currentOccurrence: Occurrence;
  occurrences: Occurrence[];
  pastOccurrences: Occurrence[];
  totalOccurrences = 0;
  myTeacher: any;
  progression: Progression;
  public radarChartLabels: string[] = [];
  public radarChartData: any = [{ data: [], label: 'Niveau' }];
  current_index = 0;
  sliderSize = '';
  countOccurencesPages = 0;
  liliate_explaination: string;
  liliate_empty_1: string;
  liliate_empty_2: string;
  // niveau de la formation
  levels = [
    { label: 'Grand débutant', value: '1' },
    { label: 'Débutant', value: '2' },
    { label: 'Intermédiaire', value: '3' },
    { label: 'Avancé', value: '4' },
    { label: 'Maitrise', value: '5' },
    { label: 'Je ne sais pas', value: '0' }
  ]
  evaluation_connection = [
    { label: 'A1', value: 20 },
    { label: 'A2', value: 40 },
    { label: 'B1', value: 60 },
    { label: 'B2', value: 80 },
    { label: 'C1', value: 100 },
    { label: 'C2', value: 120 }
  ]

  constructor(private kiwixiGlobals: KiwixiGlobals,
    private formationService: FormationService,
    private teacherService: TeacherService) {
    kiwixiGlobals.formationOccurrences.subscribe(value => this.occurrences = value)
  }

  ngOnInit() {
    this.liliate_explaination = this.kiwixiGlobals.liliate_explaination;
    this.liliate_empty_1 = this.kiwixiGlobals.liliate_empty_1;
    this.liliate_empty_2 = this.kiwixiGlobals.liliate_empty_2;
    this.pastOccurrences = []
    this.occurrences = []
    this.getOccurences();
  }

  /**
   * Récupération des occurrences - get api.
   */
  getOccurences() {
    this.countOccurencesPages++;
    this.formationService.getOccurrences(this.getIdFromUrl(this.formation.student.url), this.getIdFromUrl(this.formation.url), this.countOccurencesPages)
      .then(
        _occurrences => {
          for (const _occurrence of _occurrences['results']) {
            this.occurrences.push(_occurrence)
          }
          if (_occurrences['next'] !== null) {
            this.getOccurences()
          } else {
            this.countOccurencesPages = 0;
            this.totalOccurrences = this.occurrences.length
            this.pastOccurrences = this.buildRadarOccurrencesList();
            this.pastOccurrences.sort(function (a, b) {
              return new Date(a.start).getTime() - new Date(b.start).getTime()
            });
            if (this.pastOccurrences.length > 0) {
              this.setSliderSize(this.pastOccurrences);
              this.setCurrentOccurrence(this.pastOccurrences[0]);
            }
          }
        }
      );
  }

  // filtrer les occurences à afficher sur le radar.
  buildRadarOccurrencesList() {
    return this.occurrences.filter(myoccs =>
      myoccs.status === 'done' ||
      myoccs.status === 'old_report'
    );
  }

  getIdFromUrl(url) {
    return url.split('/')[6];
  }

  /**
   * methode pour afficher l'occurrence courant
   * @param _occurrence
   */
  setCurrentOccurrence(_occurrence) {
    this.currentOccurrence = _occurrence;

    this.buildProgressionData(this.currentOccurrence)
    if (this.radarChartData[0]['data'].length === 0) {
      if (this.currentOccurrence && this.currentOccurrence['teacher']) {
        this.teacherService.getObservableTeacherByUrl(this.currentOccurrence['teacher'].url)
          .subscribe(_teacher => {
            this.myTeacher = _teacher;
          })
      }
    }
  }

  /**
   * Méthode pour construire la data occurrence pour le radar en fonction du current occurrence du slider.
   * @param current_occurrence
   */
  public buildProgressionData(current_occurrence) {
    this.progression = new Progression();
    const dataProgression = [];
    this.radarChartLabels = []
    this.radarChartData = [
      { data: [], label: 'Niveau' }
    ]
    if (current_occurrence && current_occurrence.session && current_occurrence.session.rate_teacher !== null) {
      dataProgression.push(this.computeEvaluation(current_occurrence.session.rate_teacher.level_grammar));
      dataProgression.push(this.computeEvaluation(current_occurrence.session.rate_teacher.level_expression));
      dataProgression.push(this.computeEvaluation(current_occurrence.session.rate_teacher.level_comprehension));
      dataProgression.push(this.computeEvaluation(current_occurrence.session.rate_teacher.level_vocabulary));
      dataProgression.push(this.computeEvaluation(current_occurrence.session.rate_teacher.level_accent));
      this.progression.data = dataProgression;
      this.progression.labels = [
        'Grammaire ' + current_occurrence.session.rate_teacher.level_grammar,
        'Expression' + current_occurrence.session.rate_teacher.level_expression,
        'Comprehension' + current_occurrence.session.rate_teacher.level_comprehension,
        'Vocabulaire' + current_occurrence.session.rate_teacher.level_vocabulary,
        'Accent' + current_occurrence.session.rate_teacher.level_accent];
      this.progression.level = 56;
      this.progression.current_date = current_occurrence.start;
      this.radarChartLabels = this.progression.labels;
      this.radarChartData[0]['data'] = this.progression.data;
    } else {
      this.current_index++
      if (this.current_index < this.pastOccurrences.length - 1) {
        this.buildProgressionData(this.pastOccurrences[this.current_index]);
      }
    }
  }
  handleSelectedOccurrence(currentOccurrence) {
    this.currentOccurrence = currentOccurrence;
  }

  // récupérer la valeur correspondant au niveau.
  computeEvaluation(_level) {
    return this.evaluation_connection.filter(_eval => _eval.label === _level)[0].value;
  }

  // initialiser le slider
  setSliderSize(_past_occurrences) {
    this.sliderSize = Math.round(_past_occurrences.length / this.totalOccurrences * 100).toString();
  }

  // methode pour mettre à jour la formation.
  saveFormation() {
    this.formationService.patchFormation(this.formation.url, { 'niveau': this.formation.niveau });
  }

}
