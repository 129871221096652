/**
 * Composant principal pour le chat des teachers.
 * Contient des sous-composants pour les contacts & conversations.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
