import { HelpNotifManagerService } from './../../service/helpNotifManagerService';
import { HelpNotifService } from './../../service/helpNotif.service';
import { AchievementService } from './../../service/achievement.service';
import { AchievementDoneservice } from './../../service/achievementDone.service';
import { AchievementManagerService } from './../../service/achievementManager.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UpcomingService } from './../../service/upcoming.service';
import { ProfilePhotosService } from './../../service/profilePhotos.service';
import { InvoiceLogExportService } from './../../service/invoiceLogExport.service';
import { ReportService } from './../../service/report.service';
import { RateStudentService } from './../../service/rate-student.service';
import { CorporateService } from './../../service/coporate.service';
import { FormationSuiviService } from './../../service/formationSuivi.service';
import { RoutePermissionService } from './../../service/routePermission.service';
import { GroupService } from './../../service/group.service';
import { ExerciceSessionService } from './../../service/exerciceSession.service';
import { ExerciceService } from './../../service/exercice.service';
import { ExerciceSheetService } from './../../service/exerciceSheet.service';
/**
 * Module principale du Backoffice.
 */

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterializeModule, MzToastService } from 'ng2-materialize';
import { MomentModule } from 'ngx-moment';
import { ChartsModule } from 'ng2-charts';
// import { MaterialChipsModule } from 'angular2-material-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { BackofficeComponent } from './backoffice.component';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { LeftnavbarComponent } from './leftnavbar/leftnavbar.component';
import { MainscreenComponent } from './mainscreen/mainscreen.component';
import { FormationComponent } from './mainscreen/formation/formation.component';
import { BackofficeRoutingModule } from './backoffice.routing.module';
import { InformationsComponent } from './mainscreen/formation/informations/informations.component';
import { SessionsComponent } from './mainscreen/formation/sessions/sessions.component';
import { ListeFormationsComponent } from './mainscreen/liste-formations/liste-formations.component';
import { RecurrencesComponent } from './mainscreen/formation/recurrences/recurrences.component';
import { ProgressionComponent } from './mainscreen/formation/progression/progression.component';
import { BookingComponent } from './mainscreen/formation/booking/booking.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { StudentsListComponent } from './mainscreen/students/students-list/students-list.component';
import { CreateStudentComponent } from './mainscreen/students/create-student/create-student.component';
import { EditStudentComponent } from './mainscreen/students/edit-student/edit-student.component';
import { TeachersListComponent } from './mainscreen/teachers/teachers-list/teachers-list.component';
import { CreateTeacherComponent } from './mainscreen/teachers/create-teacher/create-teacher.component';
import { EditTeacherComponent } from './mainscreen/teachers/edit-teacher/edit-teacher.component';
import { TeachingSpaceComponent } from './mainscreen/teachers/edit-teacher/teaching-space/teaching-space.component';
import { PersonalInformationsComponent } from './mainscreen/teachers/edit-teacher/teaching-space/personal-informations/personal-informations.component';
import { TeachingInformationsComponent } from './mainscreen/teachers/edit-teacher/teaching-space/teaching-informations/teaching-informations.component';
import { OfficeSpaceComponent } from './mainscreen/teachers/edit-teacher/office-space/office-space.component';
import { FormationsAndStudentsComponent } from './mainscreen/teachers/edit-teacher/teaching-space/formations-and-students/formations-and-students.component';
import { EvaluationsComponent } from './mainscreen/teachers/edit-teacher/teaching-space/evaluations/evaluations.component';
import { AvailabilityComponent } from './mainscreen/teachers/edit-teacher/teaching-space/availability/availability.component';
import { AdminComponent } from './mainscreen/teachers/edit-teacher/office-space/admin/admin.component';
import { InvoicesComponent } from './mainscreen/teachers/edit-teacher/office-space/invoices/invoices.component';
import { HistoricalAdminTeacherComponent } from './mainscreen/teachers/edit-teacher/office-space/historical-admin-teacher/historical-admin-teacher.component';
import { CreateFormationComponent } from './mainscreen/create-formation/create-formation.component';
import { NewFormationInformationComponent } from './mainscreen/create-formation/new-formation-information/new-formation-information.component';
import { NewFormationRecurrencesComponent } from './mainscreen/create-formation/new-formation-recurrences/new-formation-recurrences.component';

import { AuthGuard } from '../../guard/auth.guard';
import { MaintenanceGuard } from '../../guard/maintenance.guard';
// interceptor
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopInterceptor, InterceptorModule } from '../../kiwixi/cache.interceptor';
import { CachingInterceptor } from '../../interceptors/cache.interceptor';

import { AuthenticationService } from '../../service/authentification.service';

// date format
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { FeatureModule } from '../../feature/feature.module';
import { SessionService } from '../../service/session.service';
import { DirectiveModule } from '../../directive/directive.module';

import { PasswordModalComponent } from '../../feature/password-modal/password-modal.component';
import { MaterialModule } from '../../material.module';
import { ItemTeacherAvailableComponent } from './mainscreen/create-formation/new-formation-recurrences/item-teacher-available/item-teacher-available.component';
import { ItemTeacherAssignedComponent } from './mainscreen/create-formation/new-formation-recurrences/item-teacher-assigned/item-teacher-assigned.component';
import { ValidModalComponent } from '../../feature/valid-modal/valid-modal.component';
import { MessagesComponent } from './mainscreen/messages/messages.component';
import { ContactsComponent } from './mainscreen/messages/contacts/contacts.component';
import { ConversationComponent } from './mainscreen/messages/conversation/conversation.component';
import { TeacherService } from '../../service/teacher.service';
import { FormationService } from '../../service/formation.service';
import { FormationOptionsService } from './../../service/formationOptions.service';
import { StudentService } from '../../service/student.service';
import { EventService } from '../../service/event.service';
import { OccurrenceService } from '../../service/occurrence.service';
import { ConversationService } from '../../service/conversation.service';
import { ContactComponent } from './mainscreen/messages/contacts/contact/contact.component';
import { InitAppService } from '../../service/initapp.service';
import { ExerciceMediaService } from '../../service/exerciceMedia.service';

import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from './../../kiwixi/error.exception';
import { InvoiceService } from '../../service/invoice.service';
import { EditComponent } from './mainscreen/invoices-page/edit/edit.component';
import { DocumentService } from '../../service/document.service';
import { SessionsService } from './mainscreen/formation/sessions/sessions.service';
import { CacheService } from '../../interceptors/cache.service';
import { OpenSessionComponent } from './mainscreen/open-session/open-session.component';
import { DownloadLingueoService } from '../../../app/directive/download-lingueo/download-lingueo.service';
import { InvoiceListComponent } from './mainscreen/invoices-page/invoice-list/invoice-list.component';
import { OptionsComponent } from './mainscreen/formation/recurrences/options/options.component';
import { TeacherComponent } from './mainscreen/formation/recurrences/options/teacher/teacher.component';
import { TimeSelectorNoDispoComponent } from '../../feature/time-selector-no-dispo/time-selector-no-dispo.component';
import { DebugMatriceComponent } from './mainscreen/formation/debug-matrice/debug-matrice.component';
import { ExercicesListComponent } from './mainscreen/exercices/exercices-list/exercices-list.component';
import { ExerciceCategoryService } from '../../service/exerciceCategory.service';
import { SheetCreateComponent } from './mainscreen/exercices/sheet-create/sheet-create.component';
import { SheetInformationComponent } from './mainscreen/exercices/sheet-create/sheet-information/sheet-information.component';
import { SheetContentComponent } from './mainscreen/exercices/sheet-create/sheet-content/sheet-content.component';
import { SheetRelatedComponent } from './mainscreen/exercices/sheet-create/sheet-related/sheet-related.component';
import { SheetExercicesListComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-list/sheet-exercices-list.component';
import { ExerciceSpecificContentService } from '../../service/exerciceSpecificContent.service';
import { SheetEditContentComponent } from './mainscreen/exercices/sheet-create/sheet-content/sheet-edit-content/sheet-edit-content.component';
import { SheetViewContentComponent } from './mainscreen/exercices/sheet-create/sheet-content/sheet-view-content/sheet-view-content.component';
import { EmbedVideo } from 'ngx-embed-video';
import { SheetExercicesCreateComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-create.component';
import { SheetExercicesInformationComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-information/sheet-exercices-information.component';
import { SheetExercicesRelatedComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-related/sheet-exercices-related.component';
import { SheetExercicesMediaComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-media/sheet-exercices-media.component';
import { SheetExercicesQuestionComponent } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-question/sheet-exercices-question.component';
import { ExerciceQuestionService } from '../../service/exerciceQuestion.service';
import { EditQuestionComponentQCM } from './mainscreen/exercices/sheet-create/sheet-exercices-create/sheet-exercices-question/edit-question-qcm/edit-question-qcm.component';
import { ExerciceAnswerChoiceService } from '../../service/exerciceAnswerChoice.service';
import { ManageExercicesAnswersChoiceComponent } from '../../directive/manage-exercices-answers-choice/manage-exercices-answers-choice.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormationSuiviComponent } from './mainscreen/formation-suivi/formation-suivi.component';
import { EditReportProgressionComponent } from './mainscreen/formation/progression/edit-report-progression/edit-report-progression.component';
import { ReportsComponent } from './mainscreen/reports/reports.component';
import { ReportTeacherAvailabilitiesComponent } from './mainscreen/reports/report-teacher-availabilities/report-teacher-availabilities.component';
import { ReportTeacherBillingExpectedComponent } from './mainscreen/reports/report-teacher-billing-expected/report-teacher-billing-expected.component';

export class LingueoProDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
import 'moment/locale/fr';
import { InvoiceExportCreateComponent } from './mainscreen/invoices-page/invoice-export/invoice-export-create/invoice-export-create.component';
import { InvoiceExportListComponent } from './mainscreen/invoices-page/invoice-export/invoice-export-list/invoice-export-list.component';
import { GroupFormationsService } from '../../service/groupFormations.service';
import { NextClassStudentComponent } from './mainscreen/students/edit-student/next-class-student/next-class-student.component';
import { OnlineMeetingsService } from '../../service/onlineMeetings.service';
import { ExportCdcCertificationListingComponent } from './mainscreen/export-formation/export-formation-listing/export-formation-listing.component';
import { ExportCdcCertificationComponent } from './mainscreen/export-formation/export-formation.component';
import { ExportCdcCertificationDetailErrorComponent } from './mainscreen/export-formation/export-formation-detail-error/export-formation-detail-error.component';

import { UpcomingClassesListComponent } from './mainscreen/upcoming-classes-list/upcoming-classes-list.component';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MaterializeModule.forRoot(),
    BrowserAnimationsModule,
    BackofficeRoutingModule,
    MomentModule,
    ChartsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    // MaterialChipsModule,
    InterceptorModule,
    FeatureModule,
    DirectiveModule,
    EmbedVideo.forRoot(),
    CKEditorModule,
  ],
  declarations: [
    BackofficeComponent,
    LeftnavbarComponent,
    MainscreenComponent,
    FormationComponent,
    InformationsComponent,
    SessionsComponent,
    ListeFormationsComponent,
    RecurrencesComponent,
    ProgressionComponent,
    BookingComponent,
    StudentsListComponent,
    CreateStudentComponent,
    EditStudentComponent,
    PasswordModalComponent,
    ValidModalComponent,
    TeachersListComponent,
    CreateTeacherComponent,
    EditTeacherComponent,
    TeachingSpaceComponent,
    PersonalInformationsComponent,
    TeachingInformationsComponent,
    OfficeSpaceComponent,
    FormationsAndStudentsComponent,
    EvaluationsComponent,
    AdminComponent,
    InvoicesComponent,
    HistoricalAdminTeacherComponent,
    CreateFormationComponent,
    NewFormationInformationComponent,
    NewFormationRecurrencesComponent,
    NewFormationRecurrencesComponent,
    AvailabilityComponent,
    ItemTeacherAvailableComponent,
    ItemTeacherAssignedComponent,
    MessagesComponent,
    ContactsComponent,
    ConversationComponent,
    ContactComponent,
    EditComponent,
    OpenSessionComponent,
    InvoiceListComponent,
    OptionsComponent,
    TeacherComponent,
    DebugMatriceComponent,
    ExercicesListComponent,
    SheetCreateComponent,
    SheetInformationComponent,
    SheetContentComponent,
    SheetRelatedComponent,
    SheetExercicesListComponent,
    SheetEditContentComponent,
    SheetViewContentComponent,
    SheetExercicesCreateComponent,
    SheetExercicesInformationComponent,
    SheetExercicesRelatedComponent,
    SheetExercicesMediaComponent,
    SheetExercicesQuestionComponent,
    EditQuestionComponentQCM,
    FormationSuiviComponent,
    EditReportProgressionComponent,
    ReportsComponent,
    ReportTeacherAvailabilitiesComponent,
    ReportTeacherBillingExpectedComponent,
    InvoiceExportCreateComponent,
    InvoiceExportListComponent,
    NextClassStudentComponent,
    ExportCdcCertificationDetailErrorComponent,
    ExportCdcCertificationListingComponent,
    ExportCdcCertificationComponent,
    UpcomingClassesListComponent,
  ],
  entryComponents: [
    PasswordModalComponent,
    ValidModalComponent,
    EditQuestionComponentQCM,
    TimeSelectorNoDispoComponent,
    ManageExercicesAnswersChoiceComponent
  ],
  providers: [
    KiwixiService,
    InitAppService,
    CacheService,
    KiwixiGlobals,
    AuthGuard,
    MaintenanceGuard,
    AuthenticationService,
    SessionService,
    TeacherService,
    FormationService,
    FormationOptionsService,
    StudentService,
    EventService,
    OccurrenceService,
    ConversationService,
    DocumentService,
    InvoiceService,
    InvoiceLogExportService,
    ExerciceService,
    ExerciceSheetService,
    ExerciceCategoryService,
    ExerciceSpecificContentService,
    ExerciceMediaService,
    ExerciceQuestionService,
    ExerciceAnswerChoiceService,
    ExerciceSessionService,
    SessionsService,
    DownloadLingueoService,
    GroupService,
    RoutePermissionService,
    FormationSuiviService,
    CorporateService,
    GroupFormationsService,
    RateStudentService,
    ReportService,
    ProfilePhotosService,
    UpcomingService,
    OnlineMeetingsService,
    DeviceDetectorService,
    AchievementManagerService,
    AchievementDoneservice,
    AchievementService,
    HelpNotifService,
    HelpNotifManagerService,
    { provide: DateAdapter, useClass: LingueoProDateAdapter },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    MzToastService,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true }
  ],
  bootstrap: [BackofficeComponent],
  exports: [InvoiceListComponent, OptionsComponent, TeacherComponent, DebugMatriceComponent]
})
export class BackofficeModule { }
