import { ProfileGuard } from './../../../guard/profile.guard';
import { PhotosProfileUpdateComponent } from './photos-profile-update/photos-profile-update.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../guard/auth.guard';
import { FormationComponent } from './formation.component';
import { PastComponent } from './past/past.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { SuspendedComponent } from './suspended/suspended.component';
import { MystudentaccountComponent } from './mystudentaccount/mystudentaccount.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';

/**
 * Routing pour une formation.
 */
export const routes: Routes = [
  {
    path: 'formation/:id',
    component: FormationComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.dashboard' } },
      { path: 'upcomings', loadChildren: './upcoming/upcoming.module#UpcomingModule', canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.upcoming' } },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.dashboard' } },
      { path: 'past', component: PastComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.past' } },
      { path: 'progression', component: EvaluationComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.progression' } },
      { path: 'suspended', component: SuspendedComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.suspended' } },
      { path: 'sheets', loadChildren: './sheets/sheets.module#SheetsModule', canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.sheets' } },
      { path: 'messages', loadChildren: './messages/messages.module#MessagesModule', canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.messages' } },
      { path: 'edit-schedule', loadChildren: './edit-schedule/edit-schedule.module#EditScheduleModule', canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.messages' } },
      { path: 'myaccount', component: MystudentaccountComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.myaccount' } },
      { path: 'my-photo-profile', component: PhotosProfileUpdateComponent, canActivate: [AuthGuard], data: { 'title': 'formation.photos-profile-update' } },

    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormationRoutingModule { }
