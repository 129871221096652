import { ExerciceAnswerChoiceService } from './../../service/exerciceAnswerChoice.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ExerciceAnswerChoice } from './../../model/exerciceAnswerChoice.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExerciceQuestion } from '../../model/exerciceQuestion.model';

@Component({
  selector: 'app-manage-exercices-answers-choice',
  templateUrl: './manage-exercices-answers-choice.component.html',
  styleUrls: ['./manage-exercices-answers-choice.component.scss']
})
export class ManageExercicesAnswersChoiceComponent implements OnInit {
  @Input() question: ExerciceQuestion;
  @Input() answer: ExerciceAnswerChoice;
  @Input() parentComponent;
  @Output() removeAnswer: EventEmitter<any> = new EventEmitter();

  text = new FormControl('', Validators.required);
  constructor(
    private exerciceAnswerChoiceService: ExerciceAnswerChoiceService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.text.setValue(this.answer.text);
  }

  patchAnswer() {
    const _params = {
      'text': this.text.value
    };
    this.exerciceAnswerChoiceService.patchExerciceAnswerChoice(this.answer.id, _params).subscribe(answer => {
    });
  }

  emitRemoveAnswer() {
    this.removeAnswer.emit()
  }

}
