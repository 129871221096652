<div id="exo-information" class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Données Exercice</h6>
    </div>
  </div>
  <div class="row information-subcontainer" [formGroup]="exerciceFormGroup">
   <div class="col l12 flexparent centeralign">
      <span class="flexchild nogrow exo-info-difficulty">Difficulté : </span>
      <mat-radio-group class="flexchild"  formControlName="difficulty" (focusout)="updateExercice('difficulty')">
            <mat-radio-button *ngFor="let difficulty of difficultyList" [value]="difficulty.data">
              <span  class ="radio-button-label">{{ difficulty.label }}</span>
            </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="exerciceFormGroup.controls.difficulty.touched && exerciceFormGroup.controls.difficulty.hasError('required')">Ce champs est requis</mat-error>
    </div>

    <div class="col l12">
        <span class="flexchild nogrow">Durée : </span>
      <input class="flexchild duration-input" name="duration" type="number"  formControlName="duration" (focusout)="updateExercice('duration')" />
      <span class="flexchild nogrow">min</span>
      <mat-error *ngIf="exerciceFormGroup.controls.duration.touched && exerciceFormGroup.controls.duration.hasError('required')">Ce champs est requis</mat-error>
    </div>

    <div class="center" *ngIf="!exercice">
        <button class="creatraining-btn action-button" mz-button (click)="createExercice()" [disabled]="!exerciceFormGroup.valid">
          Créer un exercice
        </button>
    </div>
  </div>

 

</div>

