<div id="create-plan" class="setup-training-wrap container usual-container" *ngIf="formation">
  
    <div class="steps-wrapper">
      <div class="onboarding-step step-birthdate complete-step"><div class="step-icon"></div></div>
      <div *ngIf="formation.student.user.video_provider=='skype'" class="onboarding-step step1 complete-step"><div class="step-icon"></div></div>
      <div class="onboarding-step step2 complete-step"><div class="step-icon"></div></div>
      <div class="onboarding-step step3 current-step"><div class="step-icon"></div></div>
      <div class="onboarding-step step4"><div class="step-icon"></div></div>
    </div>
      <div>
        <div class="onboarding-subheading" translate>lingueopro.plan-formation.schedule.your-planning</div>
        <div class="text-intro-plan-formation">
          <div><span translate [translateParams]="{langue: formation.language_detail.label, hours_total:formation.hours_total, validity_period_to: formation.validity_period_to | amLocale: getCurrentLanguage() | amDateFormat: 'DD MMMM YYYY' }">{{translatePath}}.title-fne</span> 
        </div>
        <div class="trainingstart-wrap">
          <div class="onboarding-sub-subheading" translate>lingueopro.plan-formation.schedule.formation-start</div>
          <div class="trainingstart-copy" translate>lingueopro.plan-formation.schedule.start_formation</div>
          <mat-form-field class="trainingstart-field">
            <input matInput [matDatepicker]="picker" [formControl]="startTrainingDate" (dateChange)="updateStartDate($event)" [min]="minStartTraningDate" [max]="formation.validity_period_to" [disabled]="loaderSearchSolution">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker ></mat-datepicker>
          </mat-form-field>
          <div class="bloc-astuce-schedule" *ngIf="formation?.mode_financement=='fne' else otherModeFinancement" [innerHtml]="'lingueopro.plan-formation.schedule.astuce-fne' | translate"></div>
          <ng-template #otherModeFinancement>
           <div translate [translateParams]="{ nbHoursByWeek: nbHoursByWeek}">lingueopro.plan-formation.schedule.default-title</div>
          </ng-template>
        </div>

        <div class="text-intro-plan-formation">
          <div class="onboarding-sub-subheading" translate>lingueopro.plan-formation.schedule.weekly-hours</div>
          <div translate>{{translatePath}}.title-sessions</div>
        </div>

        <div class="timeslotcontainer">
          <ng-template #timeslotcontainer></ng-template>
        </div>

        <div class="row center" *ngIf="searchPlanResult">
           <div>
              <div class="row alert-hours-to-book" *ngIf="!searchPlanResult.is_valid.status">
                      <div translate>lingueopro.plan-formation.schedule.error_missing_hours_title</div>
                      <div *ngIf="searchPlanResult.is_valid.error_code==001" translate [translateParams]="{ missing_hours: searchPlanResult.is_valid.message}" >lingueopro.plan-formation.schedule.missing-hours</div>
                      <div translate>lingueopro.plan-formation.schedule.error_missing_hours_advise</div>
              </div>
              <button *ngIf="!searchPlanResult.is_valid.status" class="waves-effect waves-light addslot-btn" [disabled]="loaderSearchSolution" (click)="addTimeSlot(1)">
                <span translate>{{translatePath}}.button-add-slot</span>
              </button>
            </div>
        </div>
        <div *ngIf="!searchPlanResult">
              <button class="waves-effect waves-light addslot-btn" [disabled]="loaderSearchSolution" (click)="addTimeSlot(1)">
                <span translate>{{translatePath}}.button-add-slot</span>
              </button>
        </div>
        <div class="center" *ngIf="!searchPlanResult && !loaderSearchSolution">
            <button class="waves-effect waves-light nextstep-btn" [disabled]="!getNewEvents().length>0" (click)="searchSolution(true)">
              <span translate>{{translatePath}}.button-simulation</span>
            </button>
        </div>
        
        <div>
              <div *ngIf="loaderSearchSolution">
                  <mat-spinner class="loader"></mat-spinner>
              </div>
              <div *ngIf="searchPlanResult">
                <div *ngIf="searchPlanResult.events_added.length > 0 && searchPlanResult.is_valid.status;">
                  <div class="onboarding-scheduleconf-wrap" *ngIf="searchPlanResult.events_added.length > 0">
                    <div class="onboarding-sub-subheading" translate>lingueopro.plan-formation.schedule.confirmation-planning</div>
                    <div class="title-search-result" *ngIf="searchPlanResult.is_valid.status" translate>{{translatePath}}.simulation-success</div>
                    <div class="title-search-result" *ngIf="!searchPlanResult.is_valid.status" translate>{{translatePath}}.simulation-proposal</div>
                    
                      <ul class="slots-list">
                        <li *ngFor="let event of searchPlanResult.events_added">
                          <span translate [translateParams]="{day: event.details.start | amLocale: getCurrentLanguage() | amDateFormat:'dddd', start: event.details.start | date :'HH:mm' , end: event.details.end | date :'HH:mm' }">{{translatePath}}.resume-slot</span>
                        </li>
                      </ul>
                      <div class="training-range">
                       (<span translate [translateParams]="{start: firstOccurrence | amLocale: getCurrentLanguage() | amDateFormat:'dddd DD MMMM', end: lastOccurrence | amLocale: getCurrentLanguage() | amDateFormat :'dddd DD MMMM' }">{{translatePath}}.resume-all-occurrences</span>)
                      </div>
                    
                  </div>
                  <div class="row alert-hours-to-book" *ngIf="!searchPlanResult.is_valid.status">
                    <div *ngIf="countFailureSearchSolution>2" translate>
                      {{translatePath}}.simuation-contact-support
                    </div>
                  </div>
                  <div class="dualbtn-wrapper" id="validate-plan" >
                   <button class="waves-effect waves-light nextstep-btn" (click)="searchSolution(false)" *ngIf="searchPlanResult.status==ok || searchPlanResult.is_valid.status">
                      <span translate>{{translatePath}}.button-validate-plan</span>
                    </button>
                  </div>
                </div>
              </div>
        </div>
 

      </div>
   
  
  <div id="bottom-page"></div>
</div>