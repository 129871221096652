/**
 * Service local pour le menu de navigation
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class LeftnavbarService {

  constructor(private http: HttpClient) { }

  getNavElements() {
    return this.http.get('assets/data/navigation.json')
      .map((res: any) => res)
    //  .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
}
