import { Router, ActivatedRoute } from '@angular/router';
import { TaskService } from './../../service/task.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { Occurrence } from '../../model/occurrence.model';
import { Task } from '../../model/task.model';

@Component({
  selector: 'app-sheet-assigned',
  templateUrl: './sheet-assigned.component.html',
  styleUrls: ['./sheet-assigned.component.scss']
})
export class SheetAssignedComponent implements OnChanges {

  @Input() occurrence: Occurrence;
  @Input() title;
  @Output() seeSheetOutput = new EventEmitter();
  @Output() isSheetAssignedOutput = new EventEmitter();
  taskSheetToDo: Task[] = [];

  constructor(
    private taskService: TaskService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnChanges() {
    if (this.occurrence) {
      this.getAssignedSheetList();
    }
  }

  getAssignedSheetList() {
    const params = new HttpParams()
      .set('salt', Math.random().toString())
      .set('recipient_model_id', '25') // filter on student model
      .set('recipient_entity_id', this.occurrence.student.id.toString()) // filter for this student
      .set('what_model_id', '69') // filter on sheet model
      .set('for_model_id', '22') // filter on occurrence model
      .set('for_entity_id', this.occurrence.id.toString()) // filter on this occurrence
      .set('is_validate_by_student', this.occurrence.student.id.toString())
      .set('is_todo_by_student', this.occurrence.student.id.toString())

    this.taskService.getTasks(params).subscribe(data => {
      if (data.results) {
        data.results.length > 0 ? this.isSheetAssignedOutput.emit(true) : this.isSheetAssignedOutput.emit(false);
        this.taskSheetToDo = data.results;
      }
    })

  }

  emitSeeSheetOutput(mode, sheet) {
    this.seeSheetOutput.emit({ mode: mode, sheetId: sheet.id });
  }

  deleteTask(task) {
    this.taskService.deleteTask(task.id).subscribe(result => {
      this.taskSheetToDo = this.taskSheetToDo.filter(_task => _task.id !== task.id)
    })
  }

  showSheet(sheet) {
    if (sheet) {
      this.router.navigate(['./sheets/sheet/' + sheet.id], { relativeTo: this.route });
    }
  }

}
