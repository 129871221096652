<div class="information-container" *ngIf="myTeacher">
  <div class="row form-row">
    <div class="col l5">
      <h6 class="darken-1 white-text title-information">Infos Personnelles</h6>
    </div>
  </div>
  <div class="row row-bottom-none">
    <div class="col l3 avatar-wrapper">
      <div class="avatar-block">
        <img class="user-avatar" src="{{myTeacher.user.photo_profile.thumbnail}}" *ngIf="myTeacher.user.photo_profile !==null; else elseTeacherAvatar" 
        (error)="this.userService.errorImgHandler($event)">
        <ng-template #elseTeacherAvatar><img class="user-avatar" src="{{defaultAvatar}}"></ng-template>
      </div>
      <div class="blue-grey lighten-3 white-text btn-change-avatar" #upload (click)="file.click()">
        Modifier
      </div>
      <input #file type="file" (change)="uploadAvatar($event)" [hidden]="true"/>
    </div>
    <div class="col l9">
      <div class="row valign-wrapper form-row">
        <div class="col l4 push-l1">
          <span class="left">Prénom :</span>
        </div>
        <div class="col l7">
          <input type="text" [(ngModel)]="myTeacher.user.first_name" class="filter-col textfield"
            (focusout)="updateUser(myTeacher.user,'first_name')" appPreventPaste>
        </div>
      </div>
      <div class="row valign-wrapper form-row">
        <div class="col l4 push-l1">
          <span class="left">Nom :</span>
        </div>
        <div class="col l7">
          <input type="text" [(ngModel)]="myTeacher.user.last_name" class="filter-col textfield"
          (focusout)="updateUser(myTeacher.user,'last_name')" appPreventPaste>
        </div>
      </div>
    </div>
  </div>
  <div class="row valign-wrapper form-row">
    <div class="col l3 left">
      <span>Email :</span>
    </div>
    <div class="col l9">
      <input id="email" type="email" [(ngModel)]="myTeacher.user.email"
      (focusout)="checkValidEmailToUpdate(myTeacher.user,'email')" class="filter-col left validate textfield">
    </div>
  </div>

  <div class="row valign-wrapper form-row">
    <div class="col l3 left">
      <span>Skype :</span>
    </div>
    <div class="col l9">
      <input type="text" [(ngModel)]="myTeacher.user.id_skype" class="filter-col left textfield"
      (focusout)="updateUser(myTeacher.user,'id_skype')">
    </div>
  </div>

  <div class="row valign-wrapper row-bottom-none">
    <div class="col l3 left">
      <span>Fuseau :</span>
    </div>
    <div class="col l9">
        <app-timezone-autocomplete [myUser]="myTeacher.user" [isBackoffice]="true"></app-timezone-autocomplete>
      <!-- <form>
          <input type="text" class="dropdown"
          aria-label="Number" mdInput [formControl]="timezoneControl"
          [matAutocomplete]="auto" [(ngModel)]="myTeacher.user.timezone"  (focusout)="updateUser(myTeacher.user,'timezone')">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let timezone of filteredTimezones | async"
              [value]="timezone.label">
              {{ timezone.code | translate }} ({{ timezone.value }})
            </mat-option>
          </mat-autocomplete>
        </form> -->
    </div>
  </div>
  <div class="row">
    <div class="right grey-text">
      Heure actuelle : {{now | amLocal | amDateFormat: 'HH:mm:ss'}}
    </div>
  </div>

  <div class="row row-bottom-none">
    <div class="col l7 left">
      <span>Nouveau mot de passe : </span>
    </div>
  </div>
  <div class="row valign-wrapper row-bottom-none">
      <input class="col l7 left" type="password" [disabled]="myTeacher.user.auto_pwd" [(ngModel)]="myTeacher.user.password" class="filter-col password-input">
    <div class="left col l4">
        <button class="action-button" mz-button [flat]="'true'" (click)="validModal = 'true'" [disabled]="!myTeacher.user.password && !myTeacher.user.auto_pwd">
            Changer
          </button>
          <app-password-modal 
            [user]="myTeacher.user"
            [parent]=this
            [field]="myTeacher.user.auto_pwd?'auto_pwd':'password'"
            [callback_variable]="validModal"
            (outputValidModal)="handleShowValidModal($event)"
            *ngIf="validModal">
          </app-password-modal>
      <!-- <button class="action-button" mz-button [flat]="'true'" (click)="validModal = 'true'" [disabled]="!myTeacher.user.password && !myTeacher.user.auto_pwd">
        Changer
      </button>
      <app-password-modal [user]="myTeacher.user" [parent]=this *ngIf="validModal"></app-password-modal> -->
    </div>
  </div>
  <div class="row password-cbx">
    <mat-checkbox class="col l8" [(ngModel)]="myTeacher.user.auto_pwd" (ngModelChange)="myTeacher.user.password = null">Mot de passe auto-généré</mat-checkbox>
  </div>
</div>
