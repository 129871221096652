<div class="row"></div>
<div class="row" *ngIf="dataTeachers != undefined">
  <div class="left">
    <h5 class="page-title">Liste des Professeurs</h5>
  </div>
  <div class="right">
    <button class="action-button addnew-button" mz-button (click)="showCreateTeacher()">
      Créer nouveau Professeur
      <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'" ></i>
    </button>
  </div>
</div>
<div id="table-teachers" class="row datatable-position">
  <app-datatable class="col l12 app-datatable-position"
    [data]="dataTeachers['data']"
    [header]="dataTeachers['headers']"
    [pageSize]="pageSize"
    [totalData]="totalData"
    [component]=this
    [updateMethod]=this.getData
    [gotoMethod]=this.showDetail
    [filterKey]="'teacherListFilter'"
    >
  </app-datatable>
</div>
