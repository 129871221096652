<ng-container *ngIf="showReport; else elseTemplate">
  <div class="resched-wrapper">
  
    <!-- Session Title -->
    <div class="resched-header">
      <div class="resched-icon"></div>
      <div>
        <b translate>{{translatePath}}.reschedulling</b> {{student.user.first_name}} {{student.user.last_name}}
        <div>
          {{upcoming.start | amLocale: 'en' | amDateFormat:'ddd DD MMM, YYYY'}} from {{upcoming.start | amLocale: 'en' | amDateFormat:'HH:mm'}} to {{upcoming.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
        </div>
      </div>
    </div>

    <!-- Explanations -->
    <div class="resched-info-wrapper">
      <div>
        <div><span translate>{{translatePath}}.reschedulling-number-of-postponed</span> : {{ countReportByFormation }} lesson(s)</div>
        <div class="warning-too-many-report"><span *ngIf="countReportByFormation >2" translate>{{translatePath}}.reschedulling-warning-teacher</span> </div>
      </div>
      <div>
        <p>
          {{student.user.first_name}}
          <span translate>{{translatePath}}.reschedulling-explanation</span>
        </p>
      </div>
    </div>

    <!-- Message -->
    <div class="message-wrapper">
      <form [formGroup]="messageReportForm" (ngSubmit)="confirmReport()">
        <span translate>{{translatePath}}.reschedulling-message</span>{{student.user.first_name}} :
          <mat-form-field class="report-message">
            <textarea matInput [formControl]="myMessageCtrl" name="description"></textarea>
          </mat-form-field>
          <!-- Validation -->
          <div class="resched-btn-row">
            <button class="back-btn" routerLink="/upcoming">
              <span translate>{{translatePath}}.cancel-btn</span>
            </button>
            <button class="resched-btn" type="submit" [disabled]="!messageReportForm.valid || !formChanges">
              <span translate>{{translatePath}}.request-btn</span>
            </button>
          </div>
      </form>
    </div>

  
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center pageload-message">
    <b translate>{{translatePath}}.reschedule-pending-loading</b>
  </div>
</ng-template>
