<div class="row">
  <div class="col l5">
    <div class="row" *ngIf="myTeacher">
      <app-personal-informations [myTeacher]="myTeacher" [parentComponent]=parentComponent></app-personal-informations>
    </div>
  </div>
  <div class="col l7">
    <app-formations-and-students [myTeacher]="myTeacher"></app-formations-and-students>
  </div>
</div>
<div class="row">
  <div class="col l4">
    <app-teaching-informations
      [myTeacher]="myTeacher"
      [languages]="languages"
      [sites]="sites"
      [parentComponent]=parentComponent
      [sessionSites]="sessionSites">
    </app-teaching-informations>
  </div>
  <div class="col l8">
        <app-availability [myTeacher]="myTeacher"></app-availability>
  </div>
</div>
<div class="row">
  <!--<div class="col l8 changehistory-block" *ngIf="!refresh">
    <app-historical-admin-teacher [myTeacher]="myTeacher"></app-historical-admin-teacher>
  </div>
  <div class="col l8 center changehistory-block" *ngIf="refresh">
    <b>Chargement de l'historique...</b>
  </div>-->
  <div class="col l4">
    <app-evaluations [myTeacher]="myTeacher"></app-evaluations>
  </div>
</div>

