/**
 * Composant pour la gestion des documents du teacher.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../service/authentification.service';
import { DocumentService } from '../../../service/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConversationService } from '../../../service/conversation.service';
import { FormationService } from '../../../service/formation.service';
import { TeacherService } from '../../../service/teacher.service';
import { StudentService } from '../../../service/student.service';
import { StaffService } from '../../../service/staff.service';
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  conversation_id: any;
  myUser: any;
  student: any;
  documents: any[] = [];
  searchControl: FormControl = new FormControl();
  translatePath = 'teacher.documents';
  loadDocuments = false;
  isAscending = false;
  inputTerm = '';
  sortField = 'created_at';
  sharing = false;
  conversationInTraining = false;
  callServiceFormationId = null;
  currentPage = 1;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private formationService: FormationService,
    private teacherService: TeacherService,
    private studentService: StudentService,
    private staffService: StaffService,
    private conversationService: ConversationService,
    private documentService: DocumentService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.sharing = data.sharing;
      this.conversationInTraining = data.training;
    })
    const studentId = this.route.snapshot.paramMap.get('id_student');
    this.conversation_id = this.route.snapshot.paramMap.get('id_conversation');
    if (studentId) {
      this.studentService.getObservableStudentById(studentId)
        .subscribe(_student => {
          this.student = _student;
        })
    };

    this.loadDocuments = true;
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
          this.getDocuments();
          this.searchControl.valueChanges
            .debounceTime(300)
            .subscribe(term => {
              this.documents = [];
              this.inputTerm = term;
              this.currentPage = 1;
              this.getDocuments();
            })
        }
      }
    )
  }

  ngOnDestroy() {
    if (this.callServiceFormationId) {
      this.callServiceFormationId.unsubscribe();
    }
  }

  getDocuments() {
    this.loadDocuments = true;
    let order = this.sortField;
    if (!this.isAscending) {
      order = '-' + this.sortField
    }

    this.documentService.getTeacherDocuments(this.myUser.user.id, this.inputTerm, order, this.currentPage).subscribe(
      documents => {
        if (documents['next']) {
          this.currentPage = this.documentService.getUrlParams(documents['next'], 'page');
        }
        documents['results'].forEach(doc => {
          doc.checked = false;
          this.documents.push(doc);
        });
        this.loadDocuments = false;
      }
    )
  }

  ordering(value) {
    this.sortField = value;
    this.isAscending = !this.isAscending;
    this.getDocuments();
  }

  search(term: string): void {
    this.searchControl.patchValue(term);
  }

  share() {
    const docs = this.documents
      .filter(_docs => _docs.checked)
      .map(e => e.url)
    if (docs[0]) {
      this.sendMessage(docs)
    }
  }

  sendMessage(docs) {
    const persona_id = this.route.snapshot.paramMap.get('id');
    this.route.data.subscribe(data => {
      switch (data.persona) {
        case 'student':
          this.conversationService.postNewMessage(this.conversation_id, 'doc en pj', this.myUser.url, this.student.url, docs).subscribe(
            msg => {
              this.back();
            });
          break;
        case 'teacher':
          this.teacherService.getObservableTeacherById(persona_id)
            .subscribe(_teacher => {
              this.conversationService.postNewMessage(this.conversation_id, 'doc en pj', this.myUser.url, _teacher.url, docs).subscribe(
                msg => {
                  this.back();
                }
              )
            })
          break;
        case 'staff':
          this.staffService.getObservableStaffById(persona_id)
            .subscribe(_staff => {
              this.conversationService.postNewMessage(this.conversation_id, 'doc en pj', this.myUser.url, _staff.url, docs).subscribe(
                msg => {
                  this.back();
                }
              )
            })
          break;
        default:
          break;
      }
    })
  }

  back() {
    const persona_id = this.route.snapshot.paramMap.get('id');
    this.route.data.subscribe(data => {
      switch (data.persona) {
        case 'student':
          const myLink = './messages/conversation/' + this.conversation_id + '/student/' + this.student.id;
          this.router.navigate([myLink]);
          break;
        case 'teacher':
          this.router.navigate(['./messages/teacher/' + persona_id]);
          break;
        case 'staff':
          this.router.navigate(['./messages/staff/' + persona_id]);
          break;
        default:
          break;
      }
    })
  }

  uploadFileAndShare($event) {
    if (this.myUser && this.myUser.user) {
      this.loadDocuments = true;
      const myDocservice = this.documentService.sendDocuments($event.target.files,
        this.myUser.user.url,
        null).subscribe(_docs => {
          if (_docs[0]) {
            this.sendMessage(_docs)
          } else {
            this.loadDocuments = false;
          }
        })
      this.loadDocuments = !myDocservice.closed;
    }
  }

  removeDocument(doc) {
    const index = this.documents.indexOf(doc, 0);
    if (index > -1) {
      this.documentService.deleteDocument(doc.url)
        .then(res => {
          if (res['ok']) {
            this.documents.splice(index, 1);
            this.documentService.toastService.show('Document deleted successfully', 4000, 'green');
          }
        })
    }
  }

  /**
   * upload file
   */
  uploadFile($event) {
    if (this.myUser && this.myUser.user) {
      this.loadDocuments = true;
      const myDocservice = this.documentService.sendDocuments($event.target.files, this.myUser.user.url, null)
        .subscribe(_docs => {
          if (_docs[0]) {
            this.getDocuments();
          } else {
            this.loadDocuments = false;
          }
        })
      this.loadDocuments = !myDocservice.closed;
    }
  }

  documentsExists() {
    if (this.documents.filter(_docs => _docs.checked).length > 0) {
      return false;
    }
    return true;
  }
}
