import { Injectable, ErrorHandler } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() { }

  handleError(error) {
    const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);
    const user_email = localStorage.getItem('persona')
    Sentry.init({
      dsn: environment.urls[idx].sentryDsn,
      //release: 'b472e7d5',
      release: process.env.VERSION,
    })
    if (user_email) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: user_email,
        })
      })
    }
    if (environment.envName === 'staging' || environment.envName === 'prod') {
      Sentry.captureException(error.originalError || error)
    } else {
      console.log(error)
    }
  }
}
