import { OccurrenceHistoryStatusList } from './../model/occurrenceHistoryStatusList.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class OccurrenceHistoryStatusService extends KiwixiService {

  apiOccurrenceStatusList = environment.server + 'occurrences_history_status/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }


  getOccurrenceHistoryStatus(params: HttpParams): Observable<OccurrenceHistoryStatusList> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<OccurrenceHistoryStatusList>(this.apiOccurrenceStatusList, {
      headers: header,
      params: params
    })
      .map(_invoices => _invoices)
      .pipe(
        catchError(this.handleErrorObservable<OccurrenceHistoryStatusList>(`getOccurrenceHistoryStatus`))
      );
  }

}
