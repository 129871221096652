<div id="exercice-overwrap" *ngIf="sheet && currentQuestion">
  <div class="exercice-viewer-header">
      <div class="header-leftwrap">
          <a class="exercice-backbtn" *ngIf="isExamination" routerLink=".."></a>
          <a class="exercice-backbtn" *ngIf="!isExamination" routerLink="../.."></a>
          <div *ngIf="!isExamination" class="{{ difficulty }} difficulty-icon"></div>
          <div class="time-sticker">
            <div class="time-wrap">
              <span class="time-sticker-digits">{{ duration}}</span><br/><span class="time-sticker-units">min</span>
            </div>
          </div>
      </div>
      <div class="header-midwrap">
        <div class="header-constrain">
          <div class="exercice-label" *ngIf="!isExamination"><span translate>exercices.exercice</span></div>
          <div class="exercice-label" *ngIf="isExamination"><span translate>exercices.examen</span></div>
          <div class="sheet-title">{{ sheet.title_fr }}</div>
          <div class="sheet-tags-wrap">
            <div class="sheet-cat sheet-tag">{{ sheet.category.name }}</div>
            <div class="sheet-level sheet-tag">{{ sheet.cecrl_level }}</div>
            <div class="sheet-group sheet-tag" *ngFor='let group of sheet.specific_content'>{{ group.name }}</div>
          </div>
        </div>
      </div>  
      <div class="header-rightwrap">
        <div class="quizzcount" [ngClass]="{'quizzcount-exo': !isExamination, 'quizzcount-exam': isExamination}">
          <div class="quizzcount-current">Q{{ questionIndex}}</div>
          <div class="quizzcount-total">{{ this.questions.length }}</div>
        </div>
      </div>
  </div>

  <div class="exercice-content-wrap" *ngIf="currentQuestion">
    <div class="center exercice-instruction">
      {{ currentQuestion.exercice.instruction }}
    </div>
    <div class="global-media-block" *ngIf="currentQuestion.exercice.media">
      <div class="quizz-media" *ngIf="currentQuestion.exercice.media?.media_type=='image'">
        <img class="quizz-img" src="{{ currentQuestion.exercice.media.url }}" />
      </div>
      <div class="quizz-media" *ngIf="currentQuestion.exercice.media?.media_type=='video'">
        <div class="quizz-vid" [innerHtml]="currentExerciceVideo"></div>
      </div>
    </div>

    <div *ngIf="currentQuestion">
      <div *ngIf="currentQuestion.typology=='qcm-unique-choice'">
        <app-sheet-exercice-qcm 
          [index]="questionIndex" 
          [modeBilan]="false"
          [responses]=""
          [exerciceSession]="exerciceSession"
          [lastQuestion]="lastQuestion" 
          [question]="currentQuestion" 
          (emitNextQuestion)="goToNextQuestion($event)"
          (emitIsCompleted)="processCompleted($event)"
          >
        </app-sheet-exercice-qcm>
      </div>
    </div>

    <div class="completed-block" *ngIf="isCompleted && showFinalResult">
        <div class="finalscore">
          <span translate>exercices.good_answers</span> : <span [ngClass]="{'success-examination': percentResult>=percentValidationStep}">{{ correctResponses.length }} / {{ questions.length}}</span>
        </div>
        <div class="seesummary-btn" *ngIf="isExamination" [routerLink]="['../bilan', exerciceSession.id]"><span class="seesummary-icon"></span><span translate>exercices.show_results</span></div>
        <div class="seesummary-btn" *ngIf="!isExamination" [routerLink]="['../../bilan', exerciceSession.id]"><span class="seesummary-icon"></span><span translate>exercices.show_results</span></div>
        <div class="exam-comment" *ngIf="isExamination">
          <div class="complete-block" *ngIf="percentResult>=percentValidationStep">
            <div class="complete-sticker"></div>
            <div class="complete-comment">
              <p translate>exercices.congratulations</p>
                
              <a class="backtosheet-btn backfromexam" *ngIf="isExamination" routerLink=".."><span translate>exercices.back_sheet</span></a>
              <a class="backtosheet-btn backfromexam" *ngIf="!isExamination" routerLink="../.."><span translate>exercices.back_sheet</span></a>

                
            </div>
          </div>
          <div class="failed-block" *ngIf="percentResult<percentValidationStep">
            
              <p class="failed-comment" translate>exercices.try_again</p>
              
              <a class="backtosheet-btn backfromexam" *ngIf="isExamination" routerLink=".."><span translate>exercices.back_sheet</span></a>
              <a class="backtosheet-btn backfromexam" *ngIf="!isExamination" routerLink="../.."><span translate>exercices.back_sheet</span></a>

            
          </div>
        </div>
      
      <div *ngIf="!isExamination">
        <a class="backtosheet-btn return-from-exam" *ngIf="isExamination" routerLink=".."><span translate>exercices.back_sheet</span></a>
        <a class="backtosheet-btn" *ngIf="!isExamination" routerLink="../.."><span translate>exercices.back_sheet</span></a>

        <div id="tryanother-block" *ngIf="easyExercice || mediumExercice || hardExercice">
          <div class="sheet-block-title"><span translate>autres exercices</span></div>
          <div class="difficulty-blocks-wrap">
            <div class="difficulty-block difficulty-easy" *ngIf="easyExercice" (click)="goToExercice(easyExercice)"><div class="difficulty-icon" ></div><span class="difficulty-label" translate>exercices.easy</span><br/><span class="duration-label">~{{ easyExercice.duration }} min</span> </div>
            <div class="difficulty-block difficulty-medium" *ngIf="mediumExercice" (click)="goToExercice(mediumExercice)"><div class="difficulty-icon" ></div><span class="difficulty-label" translate>exercices.medium</span><br/><span class="duration-label">~{{ mediumExercice.duration }} min</span></div>
           <div class="difficulty-block difficulty-hard" *ngIf="hardExercice" (click)="goToExercice(hardExercice)"><div class="difficulty-icon" ></div><span class="difficulty-label" translate>exercices.challenge</span><br/><span class="duration-label">~{{ hardExercice.duration }} min</span></div>
          </div>
        </div>

        <div id="exam-block" class="sheet-viewer-subblock" *ngIf="examinationExercices.length>2">
          <div class="sheet-block-title"><span translate>challenge</span></div>
          <div class="sheet-helpcopy">
            <span translate>sheet-view.help2</span>
          </div>
          <div class="startexam-wrap">
            <div class="startexam-btn" routerLink="../../examination"><span class="difficulty-label" translate>sheet-view.pass_examination</span><br/><span class="duration-label">~{{ examinationDuration }} min</span></div>
          </div>
        </div>
      </div>
      
      
    </div>

  </div>
</div>