<ng-container *ngIf="showUpcoming">
  <!-- Bloc 1 -->
  <div class="upcoming-block">

      <div class="personal-block" (click)="showInfo()" [ngClass]="{'clickable': upcoming.is_next}">
        <div class="avatar-block" *ngIf="student" routerLink="../trainings/detail/{{formationId}}/info">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="student.user.photo_profile === null">
            <img class="user-avatar" src="{{student.user.photo_profile.thumbnail}}" *ngIf="student.user.photo_profile !== null">
        </div>
        <div class="student-label">
            <div class="training-suspended" *ngIf="upcoming.formation_status=='suspended'" translate>{{translatePath}}.occurrence-suspended-warning</div>
            <div class="timeinfo">{{upcoming.start | amLocale: 'en' | amLocale: 'en' | amDateFormat:'ddd DD MMM'}},
              {{upcoming.start | amLocale: 'en'| amLocale: 'en' | amDateFormat:'HH:mm'}} - {{upcoming.end | amLocale: 'en' | amDateFormat:'HH:mm'}}</div>
            <div class="student-name">{{student.user.first_name}} {{student.user.last_name}}</div>
            
        </div>
        <div class="actions-wrapper" *ngIf="!upcoming.is_next && upcoming.teacher.url === myUser.url">
          <div class="message-minibtn" (click)="goToConversation()"></div>          
          <div class="resched-minibtn" (click)="goToReschedule()"></div>
        </div>
        <ng-container *ngIf="upcoming.is_next">
          <div class="toggleicon">
            <ng-container *ngIf="!showInfos; else elseShowInfos">
              <div class="toggleicon-more"></div>
            </ng-container>
            <ng-template #elseShowInfos>
              <div class="toggleicon-less"></div>
            </ng-template>
          </div>
        </ng-container>
      </div>

      <!-- Bloc 2 -->
        <ng-container *ngIf="showInfos">
          <div class="teachermsg-wrapper">
            <ng-container *ngIf="editMessage; else elseEditMessage">
              <form [formGroup]="messageForm" (ngSubmit)="updateNextSession('message')">            
                <div>
                  <mat-form-field>
                    <textarea matInput [formControl]="myMessageCtrl"></textarea>
                  </mat-form-field>
                </div>
                <button type="submit" class="savemsg-btn">
                  <span translate>{{translatePath}}.save-message-btn</span>
                </button>
              </form>
            </ng-container>
          </div>
          <ng-template #elseEditMessage>
            <div *ngIf="nextSession?.message; else elseMessage">
              <div class="subtitle"><span translate>Lesson's content / To-do:</span></div>
              <button class="editmsg-btn" (click)="editMessage = !editMessage">
                <span translate>{{translatePath}}.edit-message-btn</span>
              </button>
                <p class="teachermsg-content">
                  {{nextSession.message}}
                </p>
                
            </div>
            <ng-template #elseMessage>
              <div class="subtitle"><span translate>Lesson's content / To-do:</span></div>
              <button class="editmsg-btn nomessage" (click)="editMessage = !editMessage">
                <span translate>{{translatePath}}.edit-message-btn</span>
              </button>
              <div><p class="teachermsg-content no-msg" translate>{{translatePath}}.no-message</p></div>
            </ng-template>
          </ng-template>
          <div class="bottom-wrap">
          <!-- sheets to do --->
            <div *ngIf="sheetPermission && isSheetLanguageAvailable" id="upcoming-assigned" class="sheets-todo">
              <div class="subtitle"><span translate>teacher.sheets.title</span></div>
                <app-sheet-assigned [occurrence]="upcoming" (seeSheetOutput)="showSheet($event)"></app-sheet-assigned>
                <button class="assignsheet-btn" routerLink="/upcoming/assign-study-sheet/occurrence/{{upcoming.id}}"><span>Assign a study sheet</span></button>
            </div>
            <div class="last-recap" *ngIf="prevSession">
              <div class="subtitle"><span translate>Last time's recap:</span></div>
              <p class="teachermsg-content">
                {{ prevSession.message }}
              </p>
            </div>

              <!-- Reschedule -->
            <div class="buttons-wrap">
              <ng-container *ngIf="upcoming.teacher">        
                <ng-container *ngIf="upcoming.teacher.url === myUser.url; else elseOtherTeacher">
                    <div class="message-btn" (click)="goToConversation()">Chat with {{student.user.first_name}}</div>          
                    <div class="resched-btn" (click)="goToReschedule()">
                      <span translate>{{translatePath}}.reschedule</span>
                    </div>
                </ng-container>
                <ng-template #elseOtherTeacher>
                </ng-template>
              </ng-container> 
            </div>
          </div>
        </ng-container>

        <div class="otherteacher-tag" *ngIf="upcoming.teacher.url !== myUser.url">
          <span translate>{{upcoming.teacher.user.first_name}}</span> is in charge of this lesson.
        </div>
  </div>
</ng-container>


