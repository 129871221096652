<p *ngIf="loading; else student">
  Chargement ...
</p>
<ng-template #student>
  <div class="valign-wrapper row" *ngIf="my_user">
    <div class="col datatable-avatar-block" *ngIf="my_user.photo_profile" [ngClass]="{'photo_profile_validation_requested': my_user.photo_profile.status=='validation_requested', 'photo_profile_validated': my_user.photo_profile.status=='validated', 'photo_profile_refused': my_user.photo_profile.status=='refused'}">
      <img class="user-avatar" src='{{ my_user.photo_profile.thumbnail}}' *ngIf="my_user.photo_profile !== null">
    </div>
    <div class="col datatable-avatar-block" *ngIf="my_user.photo_profile === null">
          <img class="user-avatar" src='{{defaultAvatar}}' >
    </div>
  
    <div class="col l9 truncate">
      {{ my_user.first_name }}<br/>{{ my_user.last_name }}
    </div>
  </div>
</ng-template>
