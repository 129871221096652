<div class="eval-container">
	<ng-container *ngIf="pastOccurrencesIsReady">
		<ng-container *ngIf="pastOccurrencesIsReady; else elseTemplate">
			<div class="container usual-container">
				
					<app-radar2 
						[radarTemplate]="'page'"
						[isBackoffice]=false
						[pastOccurrences]="pastOccurrences"
						[totalOccurrences]="totalOccurrences"
						[formation]="formation">
					</app-radar2>
				
			</div>	
		</ng-container>
		<ng-template #elseTemplate>
			<div class="mobile-nodisplay pageload-message">
				<b translate>lingueopro.evaluation.evaluation-loading</b>
			</div>
		</ng-template>
	</ng-container>

</div>

