import { ReportTeacherAvailabilities } from './../../../../../model/reportTeacherAvailabilities.model';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { LanguageService } from './../../../../../service/language.service';
import { ReportService } from './../../../../../service/report.service';
import { Language } from './../../../../../model/language.model';
import { FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-report-teacher-availabilities',
  templateUrl: './report-teacher-availabilities.component.html',
  styleUrls: ['./report-teacher-availabilities.component.scss', '../../mainscreen.ux.scss']
})
export class ReportTeacherAvailabilitiesComponent implements OnInit {
  displayedColumns: string[] = ['teacherId', 'first_name', 'last_name', 'language', 'rec_availabilities', 'rec_availabilities_booked', 'ponctual_availabilities', 'ponctual_availabilities_booked']
  dataSource: MatTableDataSource<ReportTeacherAvailabilities> = new MatTableDataSource([]);
  languageList: Language[] = [];
  totalRecAvailabilities = 0;
  totalRecAvailabilitiesBooked = 0;
  totalPonctualAvailabilities = 0;
  totalPonctualAvailabilitiesBooked = 0;
  isLoadingResults = false;
  noData = this.dataSource.connect().pipe(map(data => data.length === 0));
  filterFormGroup = new FormGroup({
    start_period: new FormControl('', Validators.required),
    end_period: new FormControl('', Validators.required),
    language: new FormControl('', Validators.required),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private languageService: LanguageService,
    private reportService: ReportService,
  ) {

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.languageService.getObservableLanguages().subscribe(data => {
      if (data['results']) {
        this.languageList = data['results'];
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setTotal(data) {
    this.totalRecAvailabilities = data.map(x => x.rec_availabilities).reduce((acc, value) => Number(acc) + Number(value), 0);
    this.totalRecAvailabilitiesBooked = data.map(x => x.rec_availabilities_booked).reduce((acc, value) => Number(acc) + Number(value), 0);
    this.totalPonctualAvailabilities = data.map(x => x.ponctual_availabilities).reduce((acc, value) => Number(acc) + Number(value), 0);
    this.totalPonctualAvailabilitiesBooked = data.map(x => x.ponctual_availabilities_booked).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  getData() {
    this.isLoadingResults = true;
    let params = new HttpParams();
    params = params.append('start_period', this.filterFormGroup.controls.start_period.value.toISOString());
    params = params.append('end_period', this.filterFormGroup.controls.end_period.value.toISOString());
    params = params.append('language_id', this.filterFormGroup.controls.language.value.id);
    params = params.append('salt', Math.random().toString());
    this.reportService.getReportTeacherAvailabilities(params).subscribe(data => {
      this.setTotal(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
    });
  }

  /**
   * méthode pour download le fichier d'export.
   * @param _type
   */
  export() {

    const params = {
      start_period: this.filterFormGroup.controls.start_period.value.toISOString(),
      end_period: this.filterFormGroup.controls.end_period.value.toISOString(),
      language_id: this.filterFormGroup.controls.language.value.id
    };
    this.reportService.postExport(params, this.filterFormGroup.controls.language.value.label)
      .then(res => {
        this.reportService.toastService.show('Téléchargement terminé', 4000, 'green');
      })
  }

  openTeacher(row: ReportTeacherAvailabilities) {
    window.open("/teachers/admin/" + row.teacherId, "_blank");
  }

}
