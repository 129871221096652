<div class="profil-maincontent" *ngIf="student">
    <div class="return-icon" (click)="parent.showStudent = false" *ngIf="currentFormation"> output todo</div>
    <span class="pagetitle left" translate>{{translatePath}}.mon-profil</span>
    <button class="logout-btn" type="button" name="button" (click)="logout()" translate>{{translatePath}}.deconnect</button>
    <div class="row sidenav-user-row personal-block">
      <div class="col avatar-block">
        <div class="picreplace-container">
          <div class="picreplace-hover mobile-nodisplay" (click)="studentPhoto.click()">
            <span translate>{{translatePath}}.upload-pic</span>
          </div>
          <div class="avatar-wrapper">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="student.user.photo_profile === null" (error)="this.studentService.errorImgHandler($event)">
            <img class="user-avatar" src="{{student.user.photo_profile.thumbnail}}" *ngIf="student.user.photo_profile !== null">
          </div>
          <div class="picreplace-btn" (click)="goToSkype()" routerLink="/formation/{{ current_formation.id }}/my-photo-profile"translate>{{translatePath}}.upload-pic</div>
        </div>
      </div>
      <div class="col s9 user-label">
        <div class="">
          <div class="user-firstname">
            {{student.user.first_name}}
          </div>
          <div class="user-firstname">
            {{student.user.last_name}}
          </div>
        </div>
        <div class="">
          <div class="user-email">
            {{student.user.email}}
          </div>
        </div>
      </div>
    </div>
  
    <div class="breathing-row">
      <div class="row valign-wrapper form-row ">
        <div class="col l4 left align-top" translate>
          {{translatePath}}.fuseau
        </div>
        <div class="col l8">
          <form>
            <input type="text" class="dropdown" id="student-timezone"
            aria-label="Number" mdInput [formControl]="timezoneControl"
            [matAutocomplete]="auto"  (onChange)="updateUser(student.user, 'timezone')">
            <mat-autocomplete #auto="matAutocomplete">
  
              <mat-option *ngFor="let timezone of filteredTimezones | async"
                [value]="timezone.label">
                <p translate>{{ timezone.label }} ({{ timezone.value }})</p>
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>
      </div>
      <div *ngIf="student.user.video_provider=='skype'" class="row valign-wrapper form-row ">
        <div class="col l4 left" translate>
          {{translatePath}}.Profil Skype
        </div>
        <div class="col l8">
          {{ student.user.id_skype}} <span class="update_skype_link"  (click)="goToSkype()" routerLink="/formation/{{ current_formation.id }}/myaccount">modifier</span>
        </div>
      </div>
      <div class="row valign-wrapper form-row ">
        <div class="col l4 left" translate>
          Photo profile
        </div>
        <div class="col l8">
           <span class="update_skype_link" (click)="goToSkype()" routerLink="/formation/{{ current_formation.id }}/my-photo-profile">modifier</span>
        </div>
      </div>
    </div>
  </div>
  