/**
 * Composant gérant l'affichage d'une liste des formations
 * en intégrant le composant app-datatable.
 */

import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Formation } from '../../../../model/formation.model';
import { KiwixiGlobals } from '../../../../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';

// service
import { FormationService } from '../../../../service/formation.service';
import { ListeFormationsService } from './liste-formations.service';

@Component({
  selector: 'app-liste-formations',
  templateUrl: './liste-formations.component.html',
  styleUrls: ['./liste-formations.component.scss', './liste-formations.ux.scss', '../mainscreen.ux.scss'],
  providers: [FormationService, ListeFormationsService]
})
export class ListeFormationsComponent implements OnInit {

  // ---- datatable :
  dataFormations: Object;
  pageSize: number;
  source: string;
  mySize = 15;
  totalData: number;

  // ---- formations :
  formations: Formation[];
  loading: boolean;
  private errorMessage: any = '';

  constructor(
    private formationService: FormationService,
    private kiwixiGlobals: KiwixiGlobals,
    private router: Router,
    private listeFormationsService: ListeFormationsService) {
    kiwixiGlobals.isLoading.subscribe(value => this.loading = value)
  }

  ngOnInit() {
    this.source = 'formations'
    this.pageSize = 100;
    this.dataFormations = {
      data: [],
      headers: this.listeFormationsService.dataHeaders
    }
  }

  /**
   * Methode pour récupérer les formations pour le composant datatable.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = [];
    }
    if (!_filterParams['ordering']) {
      _filterParams['ordering'] = {
        key: 'ordering',
        value: '-id'
      }
    }
    const params = new HttpParams();
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params.append(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params.append(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      params.append('page', _filterParams['page']);
    }
    this.formationService.getFormations(_filterParams)
      .then(
        res => {
          this.totalData = res['count'];
          if (_countPages === 1) {
            this.dataFormations['data'] = [];
          }
          const _formations = res['results'];
          for (const _formation of _formations) {
            this.setRowsData(_formation);
          }
        },
        error => this.errorMessage = <any>error
      );
    return this.dataFormations['data'];
  }

  /**
   * Methode pour construire les lignes du tableau pour chaque formation.
   * @param formation
   */
  setRowsData(formation) {
    const row = {
      id: this.setIdFormation(formation.url),
      status: formation.status,
      student: formation.student,
      student_email: formation.student,
      formation: formation, // this.languageService.getLanguageAvatar(formation.language),
      teachers: formation.teachers,
      site: formation.site,
      nextSession: formation.events,
      valide: this.setPeriod(formation.validity_period_from, formation.validity_period_to),
      validity_period_from: formation.validity_period_from,
      validity_period_to: formation.validity_period_to,
      url: formation.url,
    };
    this.dataFormations['data'].push(row);
  }

  // affichage de l'id formation.
  setIdFormation(url) {
    return url.split('/')[6];
  }

  // formater l'affichge de la période.
  setPeriod(from, to) {
    const dateStart = new Date(from).toLocaleDateString();
    const dateEnd = new Date(to).toLocaleDateString();
    const stringPeriod = 'Du ' + dateStart + ' au ' + dateEnd;
    return stringPeriod;
  }

  /**
   * naviguer vers la page de création d'une formation.
   */
  showCreateFormation() {
    this.kiwixiGlobals.setCurrentStudentIdToNewFormation(null);
    this.router.navigate(['/formations/create']);
  }

  /**
   * naviguer vers la page d'edition d'une formation.
   */
  public showDetail(row) {
    const _id = this.kiwixiGlobals.getIdFromUrl(row.url);
    this.router.navigate(['/formations', _id]);
  }

}
