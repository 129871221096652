import { Component, OnInit, OnDestroy } from '@angular/core';
import { InvoiceService } from '../../../../service/invoice.service';
import { TeacherService } from '../../../../service/teacher.service';
import * as moment from 'moment';
import { AuthenticationService } from '../../../../service/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  invoice_id
  invoice;
  myTeacher;
  showPage: boolean;
  paymentList: any[] = [];
  callTeacherApi: any;
  formNotValid = false;
  paypalemailCtrl = new FormControl('', [
    Validators.required,
    InfoComponent.validInvoiceForm
  ]);
  ibanNumberCtrl = new FormControl('', [Validators.required, InfoComponent.validInvoiceForm]);
  bicNumberCtrl = new FormControl('', [Validators.required, InfoComponent.validInvoiceForm]);

  invoiceForm: FormGroup = this.builder.group({
    paypal_email: this.paypalemailCtrl,
    iban: this.ibanNumberCtrl,
    bic: this.bicNumberCtrl,
  }, {
      validator: InfoComponent.validInvoiceForm
    });

  static validInvoiceForm(_invoiceForm: FormGroup) {
    let matchingError;
    if (_invoiceForm.get('paypal_email') && _invoiceForm.get('iban') && _invoiceForm.get('bic')) {
      matchingError = !_invoiceForm.get('paypal_email').valid && !_invoiceForm.get('iban').valid && !_invoiceForm.get('bic').valid;
    }
    return matchingError;
  }
  constructor(
    private invoiceService: InvoiceService,
    private teacherService: TeacherService,
    private route: ActivatedRoute,
    private builder: FormBuilder,
    private authenticationService: AuthenticationService) {
    this.showPage = false;
    this.paymentList = this.invoiceService.getInvoicesPayment();
  }

  ngOnInit() {
    const currentPersonna = this.authenticationService.getCurrentPersona();
    if (currentPersonna) {
      this.callTeacherApi = this.teacherService.getObservableTeacherByUrl(currentPersonna.url)
        .subscribe(_teacher => {
          this.myTeacher = _teacher
          if (this.invoiceForm) {
            this.invoiceForm.patchValue(this.myTeacher);
            this.showPage = true;
            Object.keys(this.invoiceForm.controls).forEach(key => {
              const ctrl = this.invoiceForm.get(key);
              ctrl.markAsTouched({ onlySelf: true });
            });
            this.checkIfIsValid();
          }
        })
    } else {
      this.showPage = true;
    }
  }

  updateTeacher(field) {
    const param = {};
    param[field] = this.myTeacher[field];
    this.teacherService.patchTeacher(this.myTeacher.url, param)
      .then(_teacher => {
        this.myTeacher = _teacher;
        this.checkIfIsValid();
      })
  }

  ngOnDestroy() {
    if (this.callTeacherApi) {
      this.callTeacherApi.unsubscribe();
    }
  }

  /**
 * Methode pour souscrire aux changements dans le formulaire.
 */
  subcribeToFormChanges() {
    const myFormValueChanges$ = this.invoiceForm.valueChanges;
    myFormValueChanges$.subscribe(data => {
      this.checkIfIsValid();
    })
  }

  checkIfIsValid() {
    if (this.invoiceForm.get('paypal_email') && this.invoiceForm.get('iban') && this.invoiceForm.get('bic')) {
      this.formNotValid = !this.invoiceForm.get('paypal_email').valid && !this.invoiceForm.get('iban').valid && !this.invoiceForm.get('bic').valid;
    }
  }

  isNotValid() {
    if (this.formNotValid) {
      return 'warning-input';
    } else {
      return '';
    }
  }

}
