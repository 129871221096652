import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Student } from '../model/student.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { MzToastService } from 'ng2-materialize';
import { catchError } from 'rxjs/operators';

@Injectable()
export class StudentService extends KiwixiService {

  apistudents = environment.server + 'students/';

  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getStudent(id): Promise<Student> {
    const urlStudent = this.apistudents + id + '/';
    return this.getStudentWithUrl(urlStudent);
  }

  getMultipleStudents(studentsUrl) {
    const obx = [];
    for (const url of studentsUrl) {
      obx.push(this.getObservableStudent(url))
    }
    return forkJoin<Student[]>(obx)
  }

  getObservableStudentById(id): Observable<Student> {
    const url = this.apistudents + id + '/';
    return this.getObservableStudent(url);
  }


  getObservableStudent(urlStudent): Observable<Student> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Student>(urlStudent, { headers: header }).
      pipe(
        catchError(this.handleErrorObservable<Student>(`getObservableStudent`))
      );
  }

  getObservableStudents(params: HttpParams): Observable<Student[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Student[]>(this.apistudents, {
      headers: header,
      params: params
    })
      .pipe(
        catchError(this.handleErrorObservable<Student[]>(`getObservableStudents`))
      );
  }

  getStudentsWithUrl(_url): Observable<Student[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Student[]>(_url, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Student[]>(`getStudentsWithUrl`))
      );
  }

  getStudentLevel(urlStudent, id_language): Observable<Student> {
    this.checkCredential();
    const urlStudentLevel = urlStudent + 'level/' + id_language + '/';
    // const urlStudentLevel = 'http://api.recurring.test/api/v1/students/2/level/41/'; // URL Test
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Student>(urlStudentLevel, { headers: header }).
      pipe(
        // catchError(this.handleErrorObservable<Student>(`getStudentLevel`))
      );
  }

  getStudentWithUrl(urlStudent): Promise<Student> {
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(urlStudent, { headers: headers })
      .toPromise()
      .then((res: any) => res)
    // .catch(error => {
    //   console.error('error Student service', error);
    //   this.logout();
    // })
  }

  postStudent(student): Promise<Student> {
    const body = student;
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.post(this.apistudents, body, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La création a échouée`, 2000, 'red');
        console.error('error Student service', error);
      })
  }

  patchStudent(studentUrl, param): Promise<Student> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(studentUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
        console.error('error Student service', error);
      })
  }

  public getStudents(_filterParams): Promise<Object[]> {
    let params = new HttpParams();
    // ordering:
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params = params.set(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params = params.set(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      params = params.set('page', _filterParams['page']);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apistudents, { headers: headers })
      .toPromise()
      .then(
        res => {
          const body = res;
          return body;
        })
      .catch(this.handleError);
  }

  public getNewFormationStudents(_filterParams): Promise<Object[]> {
    let params = new HttpParams();
    for (const filterParam of _filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apistudents, { headers: headers })
      .toPromise()
      .then(
        res => {
          const body = res;
          return body;
        })
      .catch(this.handleError);
  }

  patchFile(studentUrl, param, field, file): Promise<Student> {
    const self = this;
    const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    headers.delete('Content-Type');
    _formData.append(field, file);
    _formData.append('name', file.name);
    _formData.append('student', param);
    return this._http.patch(studentUrl, _formData, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
        console.error('error Student patchFile service', error);
      })
  }

}
