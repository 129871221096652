import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Timezone } from '../../model/timezone.model';
import { Observable } from 'rxjs';
import { UserService } from '../../service/user.service';
import { MzToastService } from 'ng2-materialize';
import { TimezoneService } from '../../service/timezone.service';

@Component({
  selector: 'app-timezone-autocomplete',
  templateUrl: './timezone-autocomplete.component.html',
  styleUrls: ['./timezone-autocomplete.component.scss']
})
export class TimezoneAutocompleteComponent implements OnInit, OnDestroy {
  @Input() myUser;
  @Input() isBackoffice;
  @Input() isCreate;
  zones: Timezone[];
  filteredTimezones: Observable<Timezone[]>;
  timezoneControl: FormControl = new FormControl();
  initTimezone;
  myUserService;
  translatePath = 'teacher.account';
  constructor(
    private userService: UserService,
    private timezoneService: TimezoneService,
    private toastService: MzToastService) { }

  ngOnInit() {
    this.zones = [];
    if (this.myUser) {
      this.initTimezone = this.myUser.timezone;
      this.timezoneControl.setValue(this.initTimezone);
      this.getAllTimezones(1);
      // init timezones combo
      this.filteredTimezones = this.timezoneControl.valueChanges
        .startWith(null)
        .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
        .map(val => val ? this.filterTimezone(val) : this.zones);
    }
  }

  ngOnDestroy() { }

  getAllTimezones(_countPagesSites) {
    this.timezoneService.getTimezones({})
      .then(_timezonesData => {
        for (const timezone of _timezonesData) {
          this.zones.push(timezone)
        }
      });
  }

  filterTimezone(val: string): Timezone[] {
    return this.zones.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) > - 1);
  }

  updateTimezone(field, _e) {
    if (_e.relatedTarget && _e.relatedTarget.localName.includes('mat-option')) {
    } else {
      const param = {};
      param[field] = this.myUser[field];
      let tsValid = false;
      // controle timzone avant patch API
      for (const _tz of this.filterTimezone(param[field])) {
        if (_tz.label === param[field]) {
          tsValid = true;
        }
      }
      if (tsValid) {
        if (this.myUser[field] !== this.initTimezone) {
          this.saveTimezone(param)
        }
      } else {
        this.toastService.show('Erreur Timezone', 2000, 'red');
      }
    }
  }

  selectTimezone(field, _item) {
    const param = {};
    param[field] = _item;
    let tsValid = false;
    // controle timzone avant patch API
    for (const _tz of this.filterTimezone(param[field])) {
      if (_tz.label === param[field]) {
        tsValid = true;
      }
    }
    if (tsValid) {
      if (_item !== this.initTimezone) {
        this.myUser[field] = _item;
        this.saveTimezone(param)
      }
    } else {
      this.toastService.show('Erreur Timezone', 2000, 'red');
    }
  }

  saveTimezone(param) {
    if (!this.isCreate) {
      this.myUserService = this.userService.patchUser(this.myUser.url, param).then(_res => {
        this.initTimezone = _res.timezone;
        this.toastService.show('Timezone valid', 2000, 'green');
      });
    }
  }
}
