import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Event } from '../model/event.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { MzToastService } from 'ng2-materialize';
import * as moment from 'moment';
import { AuthenticationService } from './authentification.service';
import { AchievementService } from './achievement.service';
import { AchievementDoneservice } from './achievementDone.service';
import { AchievementManagerService } from './achievementManager.service';
import { HelpNotifService } from './helpNotif.service';

@Injectable()
export class EventService extends KiwixiService {

  apiEvents = environment.server + 'events/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
    public httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private achievementDoneservice: AchievementDoneservice,
    private achievementService: AchievementService,
    private achievementManagerService: AchievementManagerService,
    private helpNotifservice: HelpNotifService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }
  getEvents(_filterParams): Observable<Event[]> {
    let params = new HttpParams();
    for (const filterParam of _filterParams) {
      params.set(filterParam.key, filterParam.value);
      params = params.set(filterParam.key, filterParam.value);
    }
    this.createHttpClientAuthorizationHeader();
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get<Event[]>(this.apiEvents, {
      headers: headers,
      params: params
    })
  }

  getEventsToTeacher(_filterParams): Promise<Event[]> {
    let params = new HttpParams();
    for (const filterParam of _filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get(this.apiEvents, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
    // .catch(this.handleError);
  }

  getEvent(eventUrl): Promise<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get(eventUrl, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Event service', error);
        // this.toastService.show("Erreur dans la récupération d'un evenement", 500, 'red');
      })
  }

  delete(event): Promise<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.delete(event.url, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Event service', error);
        this.toastService.show('Erreur sur la suppression du créneau', 2000, 'red');
      })
  }

  deleteEvent(event): Promise<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.delete(event.url, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Event service', error);
        this.toastService.show('Erreur sur la suppression du créneau', 2000, 'red');
      })
  }

  postEvent(event): Promise<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    const body = event;
    return this._http.post(this.apiEvents, body, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show('Erreur sur la création de la recurrrence', 2000, 'red');
        console.error('error post Event service', error);
      })
  }

  putEvent(eventUrl, param): Promise<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.put(eventUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error put Event service', error);
        // this.toastService.show("Erreur dans la sauvegarde d'un créneau", 2000, 'red');
      })
  }

  patchEvent(event, param): Promise<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(event, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show('Erreur sur la mise à jour de la recurrrence', 2000, 'red');
        console.error('error patch Event service', error);
      })
  }

  multiPatchEvent(_events): Observable<Event[]> {
    const obx = [];
    for (const _event of _events) {
      const param = {
        start: moment(_event.start).utc().toISOString(),
        end: moment(_event.end).utc().toISOString()
      }
      obx.push(this.patchEvent(_event.url, param))
    }
    return forkJoin([obx])
  }

  multiPostEvent(_events): Observable<Event[]> {
    const obx = [];
    for (const _event of _events) {
      obx.push(this.postEvent(_event))
    }
    return forkJoin([obx])
  }

  multiDeleteEvent(_events): Observable<Event[]> {
    const obx = [];
    for (const _event of _events) {
      obx.push(this.deleteEvent(_event))
    }
    return forkJoin([obx])
  }

  multiPostAndPatchEvent(_posts, _patchs): Observable<Event[]> {
    const obx = [];
    for (const _event of _posts) {
      obx.push(this.postEvent(_event))
    }
    for (const _event of _patchs) {
      const param = {
        start: moment(_event.start).utc().toISOString(),
        end: moment(_event.end).utc().toISOString(),
      }
      obx.push(this.patchEvent(_event.url, param))
    }
    return forkJoin([obx])
  }

  saveEvent(event): Observable<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    this.checkCredential();
    if (event.url) {
      return this._http.patch(event.url, JSON.stringify(event), { headers: headers })
        .map(response => {
          event = response
          return response as Event
        })
        .pipe(
          catchError(this.handleErrorObservable('sauvegarde d un event', new Event()))
        )

    } else {
      this.checkFirstEventAchievement();
      return this._http.post(this.apiEvents, JSON.stringify(event), { headers: headers })
        .map(response => {
          event = response
          return response as Event
        }).pipe(
          catchError(this.handleErrorObservable('sauvegarde d un event', new Event()))
        )
    }

  }

  private checkFirstEventAchievement() {
    this.authenticationService.currentUserSubject.subscribe(_current_user => {
      if (_current_user.achievements) {
        if (_current_user.achievements.length > 0) {
          const achievement = _current_user.achievements.filter(a => a.slug === 'teacher-post-calendar-event' ? a : null)[0];
          this.achievementManagerService.achievementToFire.subscribe(val => {
            if (achievement && val) {
              if (this.achievementManagerService.canFire(achievement)) {
                this.achievementManagerService.setAchievementAsFire(achievement);
                let _params = {
                  achievement: achievement.url,
                  content_type_model: 'teacher',
                  object_id: _current_user.id
                }
                this.achievementDoneservice.postAchievementDone(_params).subscribe(achievementDone => {
                  achievement.achievements_done = achievementDone.created_at;
                  this.achievementService.updatePersonaAchievement(_current_user, achievement);
                })
              }
            }
          })
        }
      }
    });
  }

  saveEvents(events): Observable<Event[]> {
    const obx = [];
    for (const event of events) {
      if (event.start && event.end) {
        obx.push(this.saveEvent(event));
      }
    }
    return forkJoin([obx])
  }

  deleteOneEvent(event): Observable<Event> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.delete<Event>(event.url, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable('sauvegarde d un event', new Event()))
      )
  }
}
