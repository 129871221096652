<div class="quizz-wrap">

  <div class="quizz-question">
    <div class="quizz-index-block" [ngClass]="{'correct-answer': showResult && formQuestion.value.is_right && formQuestion.dirty, 'bad-answer': showResult && !formQuestion.value.is_right && formQuestion.dirty, 'no-answer': formQuestion.pristine }"><span class="quizz-index">Q{{index}}</span></div>
    <div class="quizz-label">{{ question.label}}</div>
  </div>

  <div class="quizz-media" *ngIf="question.media?.media_type=='image'">
    <img class="quizz-img" src="{{ question.media.url }}" />
  </div>
  <div class="quizz-media" *ngIf="question.media?.media_type=='video'">
    <div class="quizz-vid" [innerHtml]="currentQuestionVideo"></div>
  </div>
  <mat-radio-group  [formControl]="formQuestion" aria-label="Select an option" [ngClass]="{'success': formQuestion.value.is_right==true, 'failure': formQuestion.value.is_right==false}">
    <mat-radio-button class="quizz-radio" *ngFor="let choice of question.answers_choices; index as i" [checked]="choice.id == selectedAnswer.id" [value]="choice" (click)="saveResponse(choice, i)" [ngClass]="{'correct-answer': choice.is_right && formQuestion.dirty, 'bad-answer': !choice.is_right && formQuestion.dirty}">
      {{ choice.text }} <span *ngIf="environment=='dev' || environment=='staging'">&nbsp;{{ choice.is_right }}</span>
    </mat-radio-button>
  </mat-radio-group>

  <div class="quizz-bottomwrap">
    <div class="quizzresult-wrap">
      <div class="quizzresult" *ngIf="showResult">
        <div class="quizzresult-correct" *ngIf="formQuestion.value.is_right && formQuestion.dirty"> <span translate>exercices.correct</span> !</div>
        <div class="quizzresult-wrong" *ngIf="!formQuestion.value.is_right && formQuestion.dirty"> <span translate>exercices.bad_answer</span> !</div>
      </div>
    </div>
    <div class="nextquizz-btn-wrap" *ngIf="!lastQuestion">
    <button class="nextquizz-btn" (click)="nextQuestion()" *ngIf="!lastQuestion && !modeBilan" [disabled]="!showResult"><span translate>exercices.next_question</span></button>
    </div>
    <div class="quizzresult-stuffing"></div>
  </div>

</div>