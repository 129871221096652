import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { environment } from '../../../../../../environments/environment';
import { ExportCdcCertificationList } from '../../../../../model/exportCdcCertificationsList.model';
import { FormationService } from '../../../../../service/formation.service';
import * as moment from 'moment';
import { ExportCdcCertificationLog } from '../../../../../model/exportCdcCertificationLog.model';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-export-formation-listing',
  templateUrl: './export-formation-listing.component.html',
  styleUrls: [
    '../export-formation.component.scss',
    './export-formation-listing.component.scss', '../../mainscreen.ux.scss'
  ]
})
export class ExportCdcCertificationListingComponent implements AfterViewInit {

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: string[] = ['exportID', 'nbFormation', 'dateExport', 'bundleStatus', 'validateStatus', 'deleteAction']
  isLoadingResults = false;
  resultsLength;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private foramtionService: FormationService,
    private router: Router,
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getExports();
    this.paginator.page.debounceTime(1000).subscribe(
      () => {
        this.getExports();
      }
    )
  }

  getExports() {
    this.dataSource = new MatTableDataSource([]);
    this.isLoadingResults = true;
    let params = new HttpParams();
    if (this.paginator.pageIndex > 0) {
      params = params.append('page', (this.paginator.pageIndex + 1).toString());
    }
    params = params.append('salt', Math.random().toString());
    params = params.append('page_size', this.paginator.pageSize.toString());
    this.foramtionService.getExports(params).subscribe(
      (data: ExportCdcCertificationList) => {
        if (data.results) {
          this.dataSource = new MatTableDataSource(data.results);
          this.resultsLength = data.count;
          this.isLoadingResults = false;
        }
      }
    )
  }

  syncExport(exportCDC: ExportCdcCertificationLog, sync: Boolean) {
    this.foramtionService.patchExport(exportCDC.id, { is_synced: sync }).subscribe(
      () => {
        this.getExports();
      }
    )
  }

  deleteExport(exportCDC: ExportCdcCertificationLog) {
    this.foramtionService.patchExport(exportCDC.id, { is_deleted: true }).subscribe(
      () => {
        this.getExports();
      }
    )
  }

  detailsError(element) {
    this.router.navigateByUrl('/export/cdc/detail-error',
    {
      state: {
        export: element,
      }
    });
  }

  downloadExportCdcCertificationFile(element) {
    const _url = environment.server + 'export_cdc_certifications/export/download/' + element.id + '/';
    const _contentType = 'application/xml';
    const date = moment().format('DD_MM_YYYY')
    const filename = 'export-formations-' + element.id + '-' + date;
    this.foramtionService.downloadExport(_url, filename, _contentType).subscribe(res => {
    })
  }
}
