<br /><br />
<div *ngIf="loading">
  <div class="row">Chargement</div>
</div>
<div *ngIf="!nextSession && !loading">
  Pas de cours à venir
</div>
<div *ngIf="nextSession">
  <div class="row">
    Le prochain cours aura lieu le <b>{{ nextSession.start | amLocale: 'fr' | amDateFormat:'dddd DD MMMM'}} de {{nextSession.start | date :'HH:mm'}} à {{nextSession.end | date :'HH:mm'}}</b> avec {{ nextSession.teacher.user.first_name}}
  </div>

  <div class="row" *ngIf="nextSession.provider_link">
    <button (click)="openTeamsLink()">Rejoindre le cours</button>
    <button (click)="copyLink()">Copier le lien</button>
  </div>
  <div class="row" *ngIf="!nextSession.provider_link">
    <button (click)="generateTeamsLink()" [disabled]="loaderGenerateLink">Crée une réunion TEAMS</button>
    <div *ngIf="loaderGenerateLink">Création de la réunion en cours</div>
  </div>
</div>

