import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Occurrence } from '../model/occurrence.model';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class UpcomingService extends KiwixiService {
  apiUpcoming = environment.server + 'upcomings/';

  constructor(
    private _http: HttpClient,
    private httpClient: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getUpcomings(params: HttpParams): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this.httpClient.get<Occurrence[]>(this.apiUpcoming, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getUpcomings`))
      );
  }

  getUpcomingsWithUrl(_url): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this.httpClient.get<Occurrence[]>(_url, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getUpcomings`))
      );
  }

  getUpcomingsNextOccurrence(_url, params: HttpParams): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this.httpClient.get<Occurrence[]>(_url, {
      headers: header,
      params: params
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getUpcomings`))
      );
  }

  getUpcoming(id): Observable<Occurrence> {
    const urlUpcoming = this.apiUpcoming + id + '/';
    const header = this.createHttpClientAuthorizationHeader();
    return this.httpClient.get<Occurrence>(urlUpcoming, { headers: header }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence>(`getUpcoming`))
      );
  }

  patchUpcoming(_url, param): Promise<Occurrence> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this.httpClient.patch(_url, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
        console.error('error Patch Upcoming', error);
      })
  }
}
