import { PreviousRouteService } from './../../../../service/previous-route.service';
import { ExerciceSheetService } from './../../../../service/exerciceSheet.service';
import { ActivatedRoute } from '@angular/router';
import { ExerciceSheet } from './../../../../model/exerciceSheet.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sheet-detail',
  templateUrl: './sheet-detail.component.html',
  styleUrls: ['./sheet-detail.component.scss', '../../sheets/sheet-detail/sheet-detail.component.scss']
})
export class SheetDetailComponent implements OnInit {
  sheet: ExerciceSheet = null;
  previousUrl
  constructor(
    private route: ActivatedRoute,
    private exerciceSheetService: ExerciceSheetService,
    private previousRouteService: PreviousRouteService,
  ) { }

  ngOnInit() {
    const sheetId = this.route.snapshot.params.id;
    const studentId = this.route.snapshot.params.student_id;
    this.getSheet(sheetId, studentId);
    this.previousUrl = this.previousRouteService.getPreviousUrl();
  }

  getSheet(sheetId, studentId) {
    this.exerciceSheetService.getExerciceSheetById(sheetId, studentId).subscribe(data => {
      if (data) {
        this.sheet = data;
      }
    })
  }
}
