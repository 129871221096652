import { HttpParams } from '@angular/common/http';
import { SessionService } from './../../../../../service/session.service';
/**
 * Composant pour les informations générale de la formation/training.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { FormationService } from '../../../../../service/formation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../../service/authentification.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ReportService } from '../../../reports/report.service';
import { OccurrenceService } from '../../../../../service/occurrence.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  translatePath = 'teacher.trainings';
  defaultAvatar = environment.static + '/avatar.png';
  formation_id: any;
  formation: any;
  teachers: any[] = [];
  myUser: any;
  currentOccurrence: any;
  currentReport = null;
  currentSession = null;
  editNotes = false;
  formChanges = false;
  showDetails = false;
  notesCtrl = new FormControl();
  notesForm: FormGroup = this.builder.group({
    notes_to_teachers: this.notesCtrl
  });
  callServiceFormationId = null;
  /** RADAR variables **/
  public radarChartData: any = [{ data: [], labels: [] }, { data: [], labels: [] }];
  radarChartType = 'radar';
  chartColors = [
    {
      backgroundColor: 'rgba(0, 151, 167, 1)',
      borderColor: '#0097a7',
      pointBackgroundColor: '#276073',
      pointBorderColor: '#276073',
      pointHoverBackgroundColor: '#276073',
      pointHoverBorderColor: '#276073',
      pointRadius: 0
    },
    {
      backgroundColor: 'rgba(211, 211, 211, 0.5)',
      borderColor: 'rgba(211, 211, 211, 0)',
      pointBackgroundColor: '#276073',
      pointBorderColor: '#276073',
      pointHoverBackgroundColor: '#276073',
      pointHoverBorderColor: '#276073',
      pointRadius: 0
    }
  ];
  radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      display: true,
      ticks: {
        display: false,
        min: 0,
        max: 100,
        stepSize: 20,
        maxTicksLimit: 7
      },
      angleLines: {
        display: true,
        color: '#0097a7'
      },
      gridLines: {
        display: false,

      }
    }
  }

  evaluation_connection = [
    { label: 'A1', value: 10 },
    { label: 'A2', value: 20 },
    { label: 'B1', value: 40 },
    { label: 'B2', value: 60 },
    { label: 'C1', value: 80 },
    { label: 'C2', value: 100 }
  ]

  constructor(
    private formationService: FormationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private builder: FormBuilder,
    private reportService: ReportService,
    private occurrenceService: OccurrenceService,
    private sessionService: SessionService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(param => {
      this.formation_id = this.route.parent.snapshot.params.id_formation;
      this.authenticationService.currentUserSubject.subscribe(
        user => {
          if (user && user.hasOwnProperty('user') && this.formation_id) {
            this.myUser = user;
            this.callServiceFormationId = this.formationService.getObservableFormationById(this.formation_id)
              .subscribe(_formation => {
                this.formation = _formation;
                this.notesForm.patchValue(this.formation);
                this.subcribeToFormChanges();
                this.setTeachers();
                this.setCurrentRateTeacher()
              })
          }
        }
      )
    })
  }

  ngOnDestroy() {
    if (this.callServiceFormationId) {
      this.callServiceFormationId.unsubscribe();
    }
  }

  setCurrentRateTeacher() {
    const params = new HttpParams().set('formation', this.formation_id);
    this.sessionService.getObservableSessions(params).subscribe(_sessions => {
      for (let i = 0; i < _sessions.length; i++) {
        const _session = _sessions[i];
        if (_session) {
          if (_session.rate_teacher && _session.rate_teacher.status === 'completed') {
            this.currentSession = _session;
            this.currentReport = this.reportService.getLastReportData(_session.rate_teacher);
            this.occurrenceService.getObservableOccurence(this.currentSession.occurrence)
              .subscribe(_occurrence => {
                this.currentOccurrence = _occurrence;
                this.buildProgressionData()
              })
            break
          }
        }
      }
    });
  }

  subcribeToFormChanges() {
    this.notesForm.valueChanges.subscribe(data => {
      this.formChanges = true;
    })
  }

  /**
   * Methode pour construire la liste de sprofs a afficher
   */
  setTeachers() {
    this.teachers = [];
    // le premier doit etre le currentUser
    const myTeacher = this.formation.teachers.filter(_teacher => _teacher.user.id === this.myUser.user.id)
    this.teachers.push(myTeacher[0]);
    this.formation.teachers.forEach(_teacher => {
      if (_teacher.user.id !== this.myUser.user.id) {
        this.teachers.push(_teacher);
      }
    });
  }

  gotoProfil(idx, teacher) {
    if (idx > 0) {
      this.router.navigate(['trainings', 'teacher-profil', teacher.id]);
    }
  }

  updateNotes(field) {
    const param = {};
    if (this.formChanges) {
      param[field] = this.notesForm.value.notes_to_teachers;
      this.formationService.patchFormation(this.formation.url, param)
        .then(res => {
          this.formation.notes_to_teachers = res['notes_to_teachers'];
          this.editNotes = false;
          this.formChanges = false;
          this.formationService.toastService.show(
            `Notes Sauvegardés`,
            1000,
            'green'
          )
        });
    } else {
      this.editNotes = false;
      this.formationService.toastService.show(
        `Pas de notes Sauvegardé`,
        1000,
        'red'
      )
    }
  }

  buildProgressionData() {
    this.radarChartData[0].data.push(this.computeEvaluation(this.currentOccurrence.session.rate_teacher.level_grammar));
    this.radarChartData[0].data.push(this.computeEvaluation(this.currentOccurrence.session.rate_teacher.level_expression));
    this.radarChartData[0].data.push(this.computeEvaluation(this.currentOccurrence.session.rate_teacher.level_comprehension));
    this.radarChartData[0].data.push(this.computeEvaluation(this.currentOccurrence.session.rate_teacher.level_vocabulary));
    this.radarChartData[0].data.push(this.computeEvaluation(this.currentOccurrence.session.rate_teacher.level_accent));
    this.radarChartData[0]['labels'] = [
      'Grammaire - ' + this.currentOccurrence.session.rate_teacher.level_grammar + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.grammar) + '%',
      'Expression - ' + this.currentOccurrence.session.rate_teacher.level_expression + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.expression) + '%',
      'Comprehension - ' + this.currentOccurrence.session.rate_teacher.level_comprehension + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.comprehension) + '%',
      'Vocabulaire - ' + this.currentOccurrence.session.rate_teacher.level_vocabulary + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.vocabulary) + '%',
      'Accent - ' + this.currentOccurrence.session.rate_teacher.level_accent + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.accent) + '%',
    ];
    this.radarChartData[1]['data'] = [100, 100, 100, 100, 100];
    this.radarChartData[1]['labels'] = [
      'Grammaire - ' + this.currentOccurrence.session.rate_teacher.level_grammar + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.grammar) + '%',
      'Expression - ' + this.currentOccurrence.session.rate_teacher.level_expression + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.expression) + '%',
      'Comprehension - ' + this.currentOccurrence.session.rate_teacher.level_comprehension + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.comprehension) + '%',
      'Vocabulaire - ' + this.currentOccurrence.session.rate_teacher.level_vocabulary + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.vocabulary) + '%',
      'Accent - ' + this.currentOccurrence.session.rate_teacher.level_accent + ' : ' + Math.round(this.currentOccurrence.session.rate_teacher.accent) + '%',
    ];
  }

  computeEvaluation(_level) {
    return this.evaluation_connection.filter(_eval => _eval.label === _level)[0].value;
  }

}
