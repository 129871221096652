import { ExerciceService } from './../../../../../../service/exercice.service';
import { ExerciceSheetService } from './../../../../../../service/exerciceSheet.service';
import { ActivatedRoute } from '@angular/router';
import { ExerciceSheet } from './../../../../../../model/exerciceSheet.model';
import { Component, OnInit } from '@angular/core';
import { Exercice } from '../../../../../../model/exercice.model';

@Component({
  selector: 'app-sheet-exercices-create',
  templateUrl: './sheet-exercices-create.component.html',
  styleUrls: ['./sheet-exercices-create.component.scss']
})
export class SheetExercicesCreateComponent implements OnInit {
  editMode = false;
  exerciceSheet: ExerciceSheet = null;
  exercice: Exercice = null;
  constructor(
    private route: ActivatedRoute,
    private exerciceSheetService: ExerciceSheetService,
    private exerciceService: ExerciceService,
  ) { }

  ngOnInit() {
    const sheetId = this.route.snapshot.paramMap.get('id');
    const exerciceId = this.route.snapshot.paramMap.get('exercice_id');
    if (sheetId) {
      this.exerciceSheetService.getExerciceSheetById(sheetId).subscribe(sheet => {
        this.exerciceSheet = sheet;
      })
    }

    if (exerciceId) {
      this.editMode = true;
      this.exerciceService.getExerciceById(exerciceId).subscribe(exercice => {
        this.exercice = exercice;
      })
    }


  }

}
