<div class="container create-account">
  <div class="information-container teachercreate-container">
    <div class="row">
      <div class="col l3">
        <h6 class="center grey darken-1 white-text title-information">Création Professeur</h6>
      </div>
    </div>
    <div class="row">
      <div class="col l3 left avatar-wrapper">
        <div class="avatar-block">
          <img class="user-avatar" src="{{myavatar}}" *ngIf="myavatar; else elseTeacherAvatar" (error)="this.teacherService.errorImgHandler($event)">
          <ng-template #elseTeacherAvatar>
            <img class="user-avatar" src="{{defaultAvatar}}">
          </ng-template>
        </div>
        <span class="blue-grey lighten-3 white-text btn-change-avatar" #upload (click)="file.click()">
          Modifier
        </span>
        <input #file type="file" (change)="uploadAvatar($event)" [hidden]="true" />
      </div>
      <div class="col l9">
        <div class="row valign-wrapper form-row">
          <div class="col offset-l1 l3">
            <span class="">Prénom :</span>
          </div>
          <div class="col l8">
            <input type="text" [(ngModel)]="newTeacher.user.first_name" class="filter-col textfield" appPreventPaste>
            <div class="error" *ngIf="errors.first_name">Le prénom ne doit pas contenir de caractère spéciaux</div>
          </div>
        </div>
        <div class="row valign-wrapper form-row">
          <div class="col offset-l1 l3">
            <span class="">Nom :</span>
          </div>
          <div class="col l8">
            <input type="text" [(ngModel)]="newTeacher.user.last_name" class="filter-col textfield" appPreventPaste>
            <div class="error" *ngIf="errors.last_name">Le nom ne doit pas contenir de caractère spéciaux</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row valign-wrapper form-row">
      <div class="col l3">
        <span class="">Email :</span>
      </div>
      <div class="col l9 left bloc-input">
        <input id="email" type="email" [(ngModel)]="newTeacher.user.email" (focusout)="checkValidEmail($event)" class="filter-col left validate textfield">
      </div>
    </div>

    <div class="row valign-wrapper form-row">
      <div class="col l3">
        <span class="left">Skype :</span>
      </div>
      <div class="col l9 left bloc-input">
        <input type="text" [(ngModel)]="newTeacher.user.id_skype" class="filter-col left textfield">
      </div>
    </div>
    <div class="row valign-wrapper form-row">
      <div class="col l3">
        <span class="left">Fuseau :</span>
      </div>
      <div class="col l9 left bloc-input">
        <app-timezone-autocomplete [myUser]="newTeacher.user" [isBackoffice]="true" [isCreate]="true"></app-timezone-autocomplete>
      </div>
    </div>
    <div class="row">
      <div class="col l12 right-text grey-text">
        heure actuelle : {{now | amLocal | amDateFormat: 'HH:mm:ss'}}
      </div>
    </div>

    <div class="row valign-wrapper">
      <div class="col l4">
        <span class="left">Mot de passe :</span>
      </div>
      <div class="col l8">
        <input type="password" [disabled]="newTeacher.user.auto_pwd" [(ngModel)]="newTeacher.user.password" class="filter-col left textfield">
      </div>
    </div>
    <div class="row password-cbx">
      <mat-checkbox class="col l8 offset-l4" [(ngModel)]="newTeacher.user.auto_pwd">Mot de passe auto-généré</mat-checkbox>
    </div>

    <div class="row center">
      <button class="action-button final-button" mz-button (click)="createNewAccount()" [disabled]="checkFields()">
        Créer ce compte
      </button>
    </div>
  </div>
</div>