<!--<div class="row">
  <button mz-button (click)="refreshSheet()" >refresh</button>
</div>-->

<div class="sheet-viewer">
  <div class="sheet-viewer-header">
    <div class="header-constrain">
      <div class="sheet-title">{{ exerciceSheet.title_fr }}</div>
      <div class="sheet-tags-wrap">
        <div class="sheet-cat sheet-tag">{{ exerciceSheet.category.name }}</div>
        <div class="sheet-level sheet-tag">{{ exerciceSheet.cecrl_level }}</div>
        <div class="sheet-group sheet-tag" *ngFor='let group of exerciceSheet.specific_content'>{{ group.name }}</div>
      </div>
    </div>
  </div>
  <div class="sheet-viewer-body">
      <div class="sheet-viewer-media" *ngIf="exerciceSheet.media?.media_type=='image'">
          <img class="sheet-viewer-img" src="{{ exerciceSheet.media.url }}" width="150" />
      </div>
      <div class="sheet-viewer-media" *ngIf="exerciceSheet.media?.media_type=='video'">
          <div class="sheet-viewer-vid" [innerHtml]="iframe_video"></div>
      </div>
      <div class="sheet-viewer-content" [innerHTML]="content">
      </div>
      <div class="sheet-viewer-tips-wrap" *ngIf="exerciceSheet.show_to_remember">
        <div class="sheet-viewer-tips-title">À RETENIR !</div>
        <div class="sheet-viewer-tips-content" [innerHTML]="to_remember"></div>
      </div> 
  </div>
</div>

<!--
<div class="row">
  <div>{{ exerciceSheet.title_fr }}</div>
  <div>{{ exerciceSheet.category.name }}</div>
  <div>{{ exerciceSheet.cecrl_level }}</div>
  <div *ngFor='let group of exerciceSheet.specific_content'>{{ group.name }}</div>
  <div>{{ exerciceSheet.duration }} min</div>
  <div>
   <div *ngIf="exerciceSheet.media?.media_type=='image'">
      <img src="{{ exerciceSheet.media.url }}" width="150" />
    </div>
    <div *ngIf="exerciceSheet.media?.media_type=='video'">
     <div [innerHtml]="iframe_video"></div>
    </div>
  
  </div>
  <div [innerHTML]="exerciceSheet.content"></div>
  <div *ngIf="exerciceSheet.show_to_remember" [innerHTML]="exerciceSheet.to_remember"></div>
</div>-->
