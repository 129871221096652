import { OnChanges, SimpleChanges, Component, OnInit, EventEmitter, Input } from '@angular/core';
import { TeacherService } from '../../../../../../service/teacher.service';
import { environment } from '../../../../../../../environments/environment';
import { } from '@angular/core/src/metadata/lifecycle_hooks';


@Component({
  selector: 'app-item-teacher-assigned',
  templateUrl: './item-teacher-assigned.component.html',
  styleUrls: ['./item-teacher-assigned.component.scss']
})
export class ItemTeacherAssignedComponent implements OnInit, OnChanges {
  @Input() event;
  @Input() teacher;

  teacher_obj;
  showTeacher = false;
  defaultAvatar = environment.static + 'avatar.png';

  constructor(
    private teacherService: TeacherService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teacher) {
      this.loadTeacher();
    }
  }

  loadTeacher() {
    if (this.teacher) {
      this.teacherService.getTeacherWithUrl(this.teacher)
        .then(_teacher => {
          this.teacher_obj = _teacher;
          this.showTeacher = true;
        })
    } else {
      this.showTeacher = false;
    }
  }

  displayProfilTeacher(teacher) {
    const myUrl = '/teachers/admin/' + this.teacherService.extractIdFromUrl(this.teacher)
    window.open(myUrl, '_blank')
  }
}
