<div id="formation-export-recurring">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
            <h5 class="page-title">Listing des exports de formations</h5>
        </div>
    </div>

    <div class="row header">
        <h6 class="results-counter">{{ dataSource.data.length }} exports trouvées </h6>
        <button (click)="getExports()">Actualiser</button>
    </div>

    <div class="row">
        <div class="mat-elevation-z8">
            <div class="example-loading-shade" *ngIf="isLoadingResults">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource" matSort class="formation-table">
                <ng-container matColumnDef="exportID">
                    <th mat-header-cell *matHeaderCellDef>Export ID</th>
                    <td mat-cell *matCellDef="let element" (click)="element.status === 'error' ? detailsError(element) : ''">{{element.id}}</td>
                </ng-container>

                <ng-container matColumnDef="nbFormation">
                    <th mat-header-cell *matHeaderCellDef>Nombre formations exportées</th>
                    <td mat-cell *matCellDef="let element" (click)="element.status === 'error' ? detailsError(element) : ''">{{element.count_formations}}</td>
                </ng-container>

                <ng-container matColumnDef="dateExport">
                    <th mat-header-cell *matHeaderCellDef>Date export</th>
                    <td mat-cell *matCellDef="let element" (click)="element.status === 'error' ? detailsError(element) : ''">{{element.created_at | date: 'dd MMM yyyy HH:mm'}}</td>
                </ng-container>

                <ng-container matColumnDef="bundleStatus">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" (click)="element.status === 'error' ? detailsError(element) : ''">
                        <span *ngIf="element.status === 'error'" >Fichier contenant des erreurs</span>
                        <button (click)="downloadExportCdcCertificationFile(element)"
                            *ngIf="element.status === 'done'">Télécharger
                            le fichier d'export</button>
                        <div class="loading" *ngIf="element.status === 'in_progress'">
                            Génération de l'export en cours
                            <span>.</span><span>.</span><span>.</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="validateStatus">
                    <th mat-header-cell *matHeaderCellDef>Synchronisation CDC</th>
                    <td mat-cell *matCellDef="let element" (click)="element.status === 'error' ? detailsError(element) : ''">
                        <mat-icon [color]="'primary'" class="green-icon"
                            *ngIf="element.status === 'done' && element.is_synced === null"
                            (click)="syncExport(element, true)">check_circle_outline</mat-icon>
                        <mat-icon [color]="'warn'" class="red-icon"
                            *ngIf="element.status === 'done' && element.is_synced === null"
                            (click)="syncExport(element, false)">block</mat-icon>
                        <span *ngIf="element.status === 'done' && element.is_synced === true">Synchronisé</span>
                        <span *ngIf="element.status === 'done' && element.is_synced === false">Non synchronisé</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="deleteAction">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.status === 'error'" (click)="deleteExport(element)">highlight_off</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="dataSource.data.length==0" class="no-records">
                No records found
            </div>
            <mat-paginator class="paginator" [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>