<div class="border-occurrence" [ngClass]="{'mustedit': occurrence && occurrence.is_ratable }">
    <ng-container *ngIf="occurrence">
        <div class="mustedit-clickover"  *ngIf="occurrence.is_ratable" routerLink="/reports/edit/{{occurrence.id}}"></div>
    </ng-container>

    <div class="personal-block">
        <div class="avatar-block">
            <div class="avatar-inset-shadow"></div>
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="myObject.student.user.photo_profile === null" (error)="this.reportService.errorImgHandler($event)">
            <img class="user-avatar" src="{{myObject.student.user.photo_profile.thumbnail}}" *ngIf="myObject.student.user.photo_profile !== null">
        </div>
        <div class="user-label">
            <div *ngIf="showStudentName">{{myObject.student.user.first_name}} {{myObject.student.user.last_name}}</div>
            <div>
                <span class="timeinfo">{{myObject.start | amLocale: 'en' | amDateFormat:'ddd DD MMM, YYYY'}}   {{myObject.start | amLocale: 'en' | amDateFormat:'HH:mm'}} - {{myObject.end | amLocale: 'en' | amDateFormat:'HH:mm'}}</span>
                <span *ngIf="showTeacherName">By {{ myObject.teacher.user.first_name }}</span>
            </div>
        </div>
        <ng-container *ngIf="occurrence">
            <i class="material-icons open-icon" *ngIf="occurrence.is_ratable" routerLink="/reports/edit/{{occurrence.id}}">chevron_right</i>
        </ng-container>
    </div>
 
</div>
