<div class="row information-container test-main" *ngIf="exercices && exerciceSheet">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Exercices liés</h6>
      <span class="exo-totalcount">x {{ exercices.length }}</span>
    </div>
  </div>
  <div class="row information-subcontainer">
    <div class="col l12">
      <div class="action-button addexo-button" mz-button routerLink="/exercices/sheet-edit/{{ exerciceSheet.id }}/exercice-create/">
        Créer un nouvel exercice
        <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'"></i>
      </div>
    </div>

   <div class="col l12">
      <span class="exo-level">Facile</span> <span class="exo-subcount">x {{ easyList.length }}</span>
      <div class="exo-sublist">
        <div class="row exo-row" *ngFor="let exercice of easyList">
          <div class="col exo-id">#{{ exercice.id }}</div>
          <div class="col exo-time">{{ exercice.duration }} min</div>
          <div class="col exo-state"><span *ngIf="exercice.status=='uncomplete' || exercice.status=='disabled'">{{ exercice.status }}</span></div>
          <div class="col exo-editbutton" mz-button routerLink="/exercices/sheet-edit/{{ exerciceSheet.id }}/exercice-edit/{{ exercice.id }}">
            Editer
            <i mz-icon [icon]="'create'" [align]="'left'" [size]="'tiny'"></i>
          </div>
          <div class="col exo-deletebutton" mz-button (click)="deleteExercice(exercice.id)">
          </div>
        </div>
      </div>  
    </div>

   <div class="col l12 ">
      <span class="exo-level">Moyen</span> <span class="exo-subcount">x {{ mediumList.length }}</span>
      <div class="exo-sublist">
        <div class="row exo-row" *ngFor="let exercice of mediumList">
            <div class="col exo-id">#{{ exercice.id }}</div>
            <div class="col exo-time">{{ exercice.duration }} min</div>
            <div class="col exo-state"><span *ngIf="exercice.status=='uncomplete' || exercice.status=='disabled'">{{ exercice.status }}</span></div>
            <div class="col exo-editbutton" mz-button routerLink="/exercices/sheet-edit/{{ exerciceSheet.id }}/exercice-edit/{{ exercice.id }}">
              Editer
              <i mz-icon [icon]="'create'" [align]="'left'" [size]="'tiny'"></i>
            </div>
            <div class="col exo-deletebutton" mz-button (click)="deleteExercice(exercice.id)">
            </div>
        </div>
      </div>
    </div>

    <div class="col l12">
      <span class="exo-level">Difficile</span> <span class="exo-subcount">x {{ hardList.length }}</span>
      <div class="exo-sublist">
        <div class="row exo-row" *ngFor="let exercice of hardList">
            <div class="col exo-id">#{{ exercice.id }}</div>
            <div class="col exo-time">{{ exercice.duration }} min</div>
            <div class="col exo-state"><span *ngIf="exercice.status=='uncomplete' || exercice.status=='disabled'">{{ exercice.status }}</span></div>
            <div class="col exo-editbutton" mz-button routerLink="/exercices/sheet-edit/{{ exerciceSheet.id }}/exercice-edit/{{ exercice.id }}">
              Editer
              <i mz-icon [icon]="'create'" [align]="'left'" [size]="'tiny'"></i>
            </div>
            <div class="col exo-deletebutton" mz-button (click)="deleteExercice(exercice.id)">
            </div>
        </div>
      </div>
    </div>

  </div>


</div>
