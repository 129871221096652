/**
 * composant pour l'affichage d'une occurrence d'un invoice.
 */

import { Component, OnInit, Input } from '@angular/core';
import { FormationService } from '../../../../service/formation.service';
import * as moment from 'moment';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})
export class ClassDetailsComponent implements OnInit {
  @Input() occurrence;
  student;
  formationCall;
  showSession = false;
  constructor(private formationService: FormationService) { }

  ngOnInit() {
    this.student = this.occurrence.student;
    this.setDuration(this.occurrence);
    this.showSession = true;
  }

  setDuration(_occ) {
    const ms = moment(moment(_occ.end), 'HH:mm').diff(moment(moment(_occ.start), 'HH:mm'));
    const _duration = moment.utc(moment.duration(ms).asMilliseconds())
    if (_duration.hour() < 1) {
      _occ.duration = _duration.minute() + 'm';
    } else {
      _occ.duration = _duration.hour() + 'h' + _duration.minute();
    }
  }

}
