import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Site } from '../model/site.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MzToastService } from 'ng2-materialize';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SiteService extends KiwixiService {

  apiSites = environment.server + 'sites/';
  dataSites: any;

  constructor(private _http: HttpClient, private _router: Router, kiwixiGlobals: KiwixiGlobals, public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getStudentSites(filterParams): Promise<Site[]> {
    let params = new HttpParams();
    for (const filterParam of filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiSites, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getAllSites(filterParams): Promise<Site[]> {
    let params = new HttpParams();
    for (const filterParam of filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiSites, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getSites(): Promise<Site[]> {
    const url = this.apiSites;
    // If the Subject was NOT subscribed before OR if forceRefresh is requested
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(url, { headers: headers }).toPromise()
      .then((res: any) => res)
    // .catch(this.handleError);
  }

  getObservableSites(): Observable<Site[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Site[]>(this.apiSites, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Site[]>(`getObservableSites`))
      );
  }

  getSite(urlSite, forceRefresh?: boolean): Promise<Site> {
    // If the Subject was NOT subscribed before OR if forceRefresh is requested
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(urlSite, { headers: headers }).toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getSiteWithUrl(urlSite, forceRefresh?: boolean): Promise<Site> {
    // If the Subject was NOT subscribed before OR if forceRefresh is requested
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(urlSite, { headers: headers }).toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getObservableSite(urlSite, forceRefresh?: boolean): Observable<Site> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Site>(urlSite, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Site>(`getObservableSite`))
      );
  }

  getSitesList() {
    return this.getObservableSites()
      .subscribe(_siteData => {
        const _sites = _siteData['results'].map(_site => _site.name);
        return _sites
      });
  }

  getSiteName(_site) {
    if (this.dataSites && this.dataSites.length > 0 && _site) {
      for (const data of this.dataSites) {
        if (data.url === _site) {
          return data.name;
        }
      }
    }
    return null
  }

}
