import { OccurrenceService } from './../../../../../../service/occurrence.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Occurrence } from '../../../../../../model/occurrence.model';
import { ExerciceSheet } from '../../../../../../model/exerciceSheet.model';

@Component({
  selector: 'app-assign-sheet',
  templateUrl: './assign-sheet.component.html',
  styleUrls: ['./assign-sheet.component.scss']
})
export class AssignSheetComponent implements OnInit {
  occurrence: Occurrence = null;
  sheet: ExerciceSheet = null;
  constructor(
    private route: ActivatedRoute,
    private occurrenceService: OccurrenceService,
    private router: Router
  ) { }

  ngOnInit() {
    const occurrenceId = this.route.snapshot.params.id;
    this.occurrenceService.getObservableOccurenceById(occurrenceId).subscribe(data => {
      if (data) {
        this.occurrence = data;
      }
    })
  }

  showSheet(object) {
    if (object.mode === 'mobile') {
      this.router.navigate(['/sheets/' + object.sheet.id]);
    } else {
      this.sheet = object.sheet
    }
  }

}
