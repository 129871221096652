<ng-container *ngIf="session; else elseTemplate">
  <div class="report-wrapper">
    <!-- Header student identity -->
    <div class="report-header">
      <i class="material-icons back-btn" (click)="previousStep()">arrow_back</i>
      <div class="report-icon"></div>
      <div class="avatar-block mobile-nodisplay" *ngIf="formation">
            <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="formation.student.user.photo_profile === null">
            <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" *ngIf="formation.student.user.photo_profile !== null">
      </div>
      <div class="user-label" *ngIf="session.rate_teacher">
        <div><b translate>{{translatePath}}.report-title</b> : {{session.rate_teacher.student_first_name}} {{session.rate_teacher.student_last_name}}</div>
        <div>
            {{session.rate_teacher.start | amLocale: 'en' | amDateFormat:'ddd DD MMM, YYYY'}}  
            {{session.rate_teacher.start | amLocale: 'en' | amDateFormat:'HH:mm'}} - {{session.rate_teacher.end | amLocale: 'en' | amDateFormat:'HH:mm'}}
        </div>
      </div>
    </div>

    <div class="reporter-row" *ngIf="myReportTeacher && myUser.user.url !== myReportTeacher.user.url ">
        <span translate>teacher.reports.report-by</span> {{myReportTeacher.user.first_name}} {{myReportTeacher.user.last_name}}
    </div>

    <div class="feedback-wrapper" *ngIf="session.rate_teacher">
      <b class="paragraph-title" translate>{{translatePath}}.feedback-text</b>
      <div class="feedback-copy">
        {{session.rate_teacher.message}}
      </div>
    </div>
    <div class="nexttime-wrapper">
      <b class="paragraph-title" translate>{{translatePath}}.for-next-time-text</b>
      <div class="nexttime-copy">
        <ng-container *ngIf="!session.next_session; else elseNextSession">
            <b><i translate>{{translatePath}}.no-next-session</i></b>
        </ng-container>
        <ng-template #elseNextSession>      
            {{nextMessage}}
        </ng-template>
      </div>
    </div>

        <!-- Levels -->
    <div class="levels-wrapper" *ngIf="session.rate_teacher">
      <b class="paragraph-title">{{session.rate_teacher.student_first_name}}'s Level : {{session.rate_teacher.level}}</b>
      <div *ngFor="let subject of myReport; let idx = index">
        <app-level [editMode]=false [subject]="subject" [isFirst]=false [previousReport]="previousReport"></app-level>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center loading pageload-message">
    <b translate>{{translatePath}}.info-loading</b>
  </div>
</ng-template>
    
    
    
  
