<div id="teacher">
    <ng-container *ngIf="showTeacherApp; else elseTemplate">
        <ng-container *ngIf="isLoggedIn; else elseNotLogged">
            <div class="">
                <div class="mobile-menu-zone nomobile-nodisplay">
                    <app-mobile-menu (goToLogout)="logout()"></app-mobile-menu>
                </div>
                <div class="desktop-menu-zone mobile-nodisplay">
                    <app-desktop-menu (goToLogout)="logout()"></app-desktop-menu>
                </div>
                <div class="main-zone">
                    <div class="main-wrapper">
                        <div class="main-center">
                            <router-outlet></router-outlet>
                            <!-- <app-release-warning [teacher]="teacher" *ngIf="teacher"></app-release-warning>
                            <app-first-login [teacher]="teacher" *ngIf="teacher"></app-first-login> -->
                            <app-achievement-modal [slug]="'release'" [teacher]="teacher" *ngIf="teacher"></app-achievement-modal>
                            <app-achievement-modal [slug]="'first-login'" [teacher]="teacher" *ngIf="teacher"></app-achievement-modal>
                        </div>
                        <div class="main-side mobile-nodisplay">
                            <app-right-menu></app-right-menu>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #elseNotLogged>
            <router-outlet (activate)="componentAdded($event)"></router-outlet>
        </ng-template>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="valign-wrapper white-text" style="height: 75vh;">
            <h5 style="margin-left: 42%">Chargement Teacher ...</h5>
        </div>
    </ng-template>
</div>