import { environment } from './../../../../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { BilanComponent } from './sheets/sheet-detail/bilan/bilan.component';
import { SheetExerciceComponent } from './sheets/sheet-detail/sheet-exercice/sheet-exercice.component';
import { SheetExaminationComponent } from './sheets/sheet-detail/sheet-examination/sheet-examination.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//
import { MaterialModule } from '../../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
//
import { FormationComponent } from './formation.component';
import { DocumentsComponent } from './documents/documents.component';
import { PastComponent } from './past/past.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { FeatureModule } from '../../../feature/feature.module';
import { SessionService } from '../../../service/session.service';
import { DirectiveModule } from '../../../directive/directive.module';
import { MessageService } from '../../../service/message.service';
import { UpcomingService } from '../../../service/upcoming.service';
import { MomentModule } from 'ngx-moment';
import { SuspendedComponent } from './suspended/suspended.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


import {
  GoogleApiModule,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
} from 'ng-gapi';
import { FormationRoutingModule } from './formation-routing.module';
import { MystudentaccountComponent } from './mystudentaccount/mystudentaccount.component';
import { PhotosProfileUpdateComponent } from './photos-profile-update/photos-profile-update.component';
import { DashboardComponent } from './dashboard/dashboard.component';
const gapiClientConfig: NgGapiClientConfig = {
  // client_id: '166968030463-hi0cat4v0kgbv0ikkfl3lj8b1u4d14n8.apps.googleusercontent.com',
  client_id: environment.google_app_id,
  discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],
  scope: [
    'https://www.googleapis.com/auth/calendar',
  ].join(' ')
};


@NgModule({
  imports: [
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    CommonModule,
    FormationRoutingModule,
    ChartsModule,
    DirectiveModule,
    FeatureModule,
    MaterialModule,
    MomentModule,
    InfiniteScrollModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [
    FormationComponent,
    DocumentsComponent,
    PastComponent,
    EvaluationComponent,
    SuspendedComponent,
    SheetExaminationComponent,
    SheetExerciceComponent,
    BilanComponent,
    MystudentaccountComponent,
    PhotosProfileUpdateComponent,
    DashboardComponent,
  ],
  providers: [
    SessionService,
    MessageService,
    UpcomingService,
  ],
  exports: [FeatureModule, SuspendedComponent, InfiniteScrollModule, SheetExerciceComponent, SheetExaminationComponent]
})
export class FormationModule { }
