<mz-sidenav>
  <mz-sidenav-header>
    <li>
      <div class="admin-logo"></div>
    </li>
  </mz-sidenav-header>
  <mz-sidenav-link>
    <div class="row sidenav-user-container" *ngIf="currentStaff">
      <div *ngIf="!showUserDetail">
        <div class="user-label-mono">
          <div class="mini-avatar-block">
            <img class="user-avatar" src="{{avatar}}" (error)="this.kiwixiService.errorImgHandler($event)">
          </div>
          <span>{{currentStaff.user.first_name}} {{currentStaff.user.last_name}}</span>
        </div>
        <div class="user-profile-toggle" (click)="showUserDetail = !showUserDetail" *ngIf="!showUserDetail">
          <i class="material-icons waves-effect waves-light">expand_more</i>
        </div>
      </div>
      <div *ngIf="showUserDetail">
        <div class="row sidenav-user-row" *ngIf="!showEditDetail">
          <div class="col height-avatar-admin">
            <img class="user-avatar" src="{{avatar}}" (error)="this.kiwixiService.errorImgHandler($event)">
          </div>
          <div class="col l8 user-label label-position ">
            <div class="label-admin-name">{{currentStaff.user.first_name}} {{currentStaff.user.last_name}}</div>
            <div class="label-admin-job">{{currentStaff.poste}}</div>
          </div>
          <div class="col icon-position user-profile-toggle" (click)="showUserDetail = !showUserDetail" *ngIf="showUserDetail">
          <i class="material-icons waves-effect waves-light">expand_less</i>
          </div>
        </div>
        <div class="row sidenav-user-row" *ngIf="!showEditDetail">
          <div class="col l1">
            <i class="material-icons waves-effect waves-light" (click)="showEditDetail = !showEditDetail">edit</i>
          </div>
          <div class="col l1">
            <i class="material-icons waves-effect waves-light" (click)="logout()">exit_to_app</i>
          </div>
          <div class="col l8 user-label label-position">
            <div class="label-admin-mail">{{currentStaff.user.email}}</div>
          </div>
        </div>
        <div class="row sidenav-user-row" *ngIf="showEditDetail">
          <div class="row sidenav-user-row">
            <div class="col height-avatar-admin">
              <div class="picreplace-container">
                <div class="picreplace-hover">
                  <span #upload (click)="file.click()">Modifier</span>
                  <input #file type="file" (change)="uploadAvatar($event)" [hidden]="true" />
                </div>
                <img class="user-avatar" src="{{avatar}}" (error)="this.kiwixiService.errorImgHandler($event)">
              </div>
            </div>
            <div class="col l8 offset-l1 user-label">
              <input type="text" class="user-label height-input-admin" placeholder="Prénom" [(ngModel)]="currentStaff.user.first_name">
              <input type="text" class="user-label height-input-admin" placeholder="Nom" [(ngModel)]="currentStaff.user.last_name">
            </div>
          </div>
          <div class="row sidenav-user-row">
            <div class="col l12">
              <input type="text" class="user-label height-input-admin" placeholder="Poste" [(ngModel)]="currentStaff.poste">
              <input type="text" class="user-label height-input-admin" placeholder="E-mail" [(ngModel)]="currentStaff.user.email">
            </div>
          </div>
        </div>
        <div class="row sidenav-user-row buttons-row" *ngIf="showEditDetail">
          <span class="waves-effect waves-light cancel-button" (click)="showEditDetail = !showEditDetail; initStaff()">Annuler</span>
          <span class="waves-effect waves-light save-button" (click)="showEditDetail = !showEditDetail; saveStaff()">Sauvegarder</span>
        </div>
      </div>
    </div>
  </mz-sidenav-link>
  <div class="sidenav-row" *ngFor="let item of navElements">
    <mz-sidenav-link *ngIf="item.subItems.length < 1; else collapsible;" [active]="item.isActive">
      <a *ngIf="!showNavBar" class="grey-text dashboard-link waves-effect waves-light" translate>{{ item.translate }}</a>
      <a *ngIf="showNavBar" class="grey-text text-darken-3 dashboard-link waves-effect waves-light" (click)="activeNav(item)" routerLink="{{item.path}}"
        translate>
        {{ item.translate }}
        <span class="sidenav-icon"></span>
      </a>
    </mz-sidenav-link>
    <ng-template #collapsible>
      <mz-sidenav-collapsible>
        <mz-sidenav-collapsible-header>
          <i mz-icon-mdi [icon]="'menu-right'" class="pink-text"></i>
          <a *ngIf="showNavBar" class="grey-text text-darken-3" routerLink="{{item.path}}" translate>{{ item.translate }}</a>
          <a *ngIf="!showNavBar" class="grey-text" translate>{{ item.translate }}</a>
        </mz-sidenav-collapsible-header>
        <mz-sidenav-collapsible-content>
          <mz-sidenav-link *ngFor="let subItem of item.subItems">
            <a *ngIf="!showNavBar" class="grey-text grey darken-1" translate>{{ subItem.translate }}</a>
            <a *ngIf="showNavBar" class="waves-effect grey darken-1 grey-text text-darken-3" routerLink="{{subItem.path}}" translate>{{ subItem.translate }}</a>
          </mz-sidenav-link>
        </mz-sidenav-collapsible-content>
      </mz-sidenav-collapsible>
    </ng-template>
  </div>
</mz-sidenav>