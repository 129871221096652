import { Language } from './language.model';
import { ExerciceCategory } from './exerciceCategory.model';
import { ExerciceSpecificContent } from './exerciceSpecificContent.model';
export class ExerciceSheetListFilter {
    id: number;
    title_fr: string;
    language: Language;
    cecrl_level: string;
    category: ExerciceCategory;
    specific_content: ExerciceSpecificContent;
    status: string;
}
