import { AuthenticationService } from './../../../service/authentification.service';
import { Student } from './../../../model/student.model';
import { FormationService } from './../../../service/formation.service';
/**
 * Composant principal pour la plannification d'une formation en attente.
 * Intégre un sous-routing avec des sous-composants.
 */

import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { MainscreenGlobals } from '../../../kiwixi/mainscreen.globals';
import { Formation } from '../../../model/formation.model';

@Component({
  selector: 'app-plan-formation',
  templateUrl: './plan-formation.component.html',
  styleUrls: ['./plan-formation.component.scss']
})
export class PlanFormationComponent implements OnInit {
  defaultAvatar: string;
  showPlanFormation = false;
  translatePath = 'lingueopro';
  student: Student;
  currentFormation: Formation;

  constructor(
    private mainscreenGlobals: MainscreenGlobals,
    private authenticationService: AuthenticationService,
    private formationService: FormationService) { }

  ngOnInit() {
    this.formationService.currentFormationSubject.subscribe(value => this.currentFormation = value)
    this.authenticationService.currentUserSubject.subscribe(student => {
      if (student) {
        this.formationService.setStudentCrispEnv(student);
        this.showPlanFormation = true;
      }
    })
  }
}

@Injectable()
export class MyFormation {
  public newFormation: Formation;
  setNewFormation(formation) {
    this.newFormation = formation;
  }
  getNewFormation() {
    return this.newFormation;
  }
}

