import { OccurrenceService } from './../../service/occurrence.service';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FormationService } from './../../service/formation.service';
import { Event } from './../../model/event.model';
import { PlanService } from './../../service/plan.service';
import { Teacher } from './../../model/teacher.model';
import { FormControl, Validators } from '@angular/forms';
import { Formation } from './../../model/formation.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/fr';

@Component({
  selector: 'app-ponctual-schedule',
  templateUrl: './ponctual-schedule.component.html',
  styleUrls: ['./ponctual-schedule.component.scss']
})
export class PonctualScheduleComponent implements OnInit {

  @Input() formation: Formation;
  @Input() chooseTeacher;
  @Input() maxDate;
  @Input() maxDateFullDispo;
  @Input() multiSlot;
  @Input() modeCalendar;
  @Input() firstClassTraining;
  @Output() classCreated = new EventEmitter();
  teacher: Teacher;

  // Availabilities
  availablesDays: any[] = [];
  availableDay;

  month;
  year;

  // Loaders
  loaderWritePlanning: boolean = false;
  loadDisponibilities: boolean = false;

  teacher_FormControl = new FormControl('', [Validators.required]);
  slotStart_FormControl = new FormControl();
  slotEnd_FormControl = new FormControl();
  selectedDate
  startTime = [];
  endTime = [];
  showHelp = false;
  noMoreTime = false;

  classToCreate = [];
  eventsToCreate: Event[] = []

  constructor(
    private planService: PlanService,
    private formationService: FormationService,
    private occurrenceService: OccurrenceService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.month = moment().month() + 1
    this.year = moment().year();
    if (this.chooseTeacher) {
      this.teacher_FormControl.valueChanges.subscribe(teacher => {
        if (teacher) {
          if (teacher === 'new') {
            this.teacher = null;
          } else {
            this.teacher = teacher;
          }
        }
        this.getTeacherAvailabiliies();
      });
      if (this.formation.teachers.length > 0) {
        this.teacher_FormControl.setValue(this.formation.teachers[0]);
      }
    } else {
      this.getTeacherAvailabiliies();
    }

    if (!this.multiSlot) {
      this.slotEnd_FormControl.valueChanges.subscribe(changes => {
        if (changes) {
          this.classToCreate = [];
          this.chooseSlot();
        }
      });
    }
  }

  resetSlotSelect() {
    this.slotStart_FormControl.reset()
    this.slotEnd_FormControl.reset()
    this.startTime = [];
    this.endTime = [];
    if (!this.multiSlot) {
      this.classToCreate = [];
    }
  }

  /* Methode pour récupérer les heures disponibles */
  getTeacherAvailabiliies() {
    this.resetSlotSelect();
    this.availablesDays = [];
    this.loadDisponibilities = true;
    let param = {
      algorithm: 'availabilityteacher',
      teacher: null,
      formation: this.formation.id,
      month: this.month,
      year: this.year,
      max_date: null,
      max_date_full_dispos: null,
      calendar_mode: 'month',
      timezone: momenttz.tz.guess()
    }
    if (!this.modeCalendar) {
      param.calendar_mode = 'week'
    }
    if (this.maxDate) {
      param.max_date = this.maxDate
    }
    if (this.maxDateFullDispo) {
      param.max_date_full_dispos = this.maxDateFullDispo
    }
    if (this.teacher) {
      param.teacher = this.teacher.id;
    }
    this.planService.postPlan(param)
      .then(_res => {
        if (_res) {
          // on recupère les données >= à la date du jour
          _res.forEach(_available => {
            if (moment(_available.day).isAfter(moment(), 'day')) {
              this.availablesDays.push(_available);
            }
          });

        }
        this.loadDisponibilities = false;
      })
  }

  /******* Hanle from childs component or directive *******/
  handleUpdateDateSelect(selectedDate) {
    this.resetSlotSelect();
    this.selectedDate = selectedDate;
    this.initStart();
  }

  // Fonction permetant de vérifier si le slot de debut est compatible avec les end autorisés
  checkIfEndIfPossible(slotToCheck) {
    let possible = false;
    const myDate = slotToCheck.clone();
    const forceDuration = this.formationService.getFormationSessionForceDuration(this.formation);
    const hoursForceDuration = parseInt(forceDuration.value) / 2;
    const forcedSlot = myDate.add(hoursForceDuration, 'hours')
    const day = this.availablesDays.filter(days => moment(days.day).isSame(moment(this.selectedDate), 'day'))
    if (day) {
      const dispos = day[0].dispo;
      dispos.forEach(dispo => {
        let start = moment(dispo.start);
        const end = moment(dispo.end);
        const duration = moment.duration(end.diff(start));
        const slotsLength = duration.asHours() * 2;
        for (let i = 0; i < slotsLength; i++) {
          start = start.add(30, 'minutes');
          const slotDuration = moment.duration(forcedSlot.diff(slotToCheck)).asHours();
          if (start.isSame(forcedSlot)) {
            if (slotDuration === hoursForceDuration) {
              possible = true;
            }
          }

        }
      });
    }
    return possible;
  }

  initStart() {
    this.resetSlotSelect();
    const day = this.availablesDays.filter(days => moment(days.day).isSame(moment(this.selectedDate), 'day'))
    if (day) {
      const dispos = day[0].dispo;
      dispos.forEach(dispo => {
        let start = moment(dispo.start);
        const end = moment(dispo.end);
        const duration = moment.duration(end.diff(start));
        const slotsLength = duration.asHours() * 2;
        for (let i = 0; i < slotsLength; i++) {
          if (start.isSameOrAfter(moment(this.selectedDate).set(({ hour: 0, minute: 0, second: 0, millisecond: 0 })))) {
            let enable = true;
            if (start.isBefore(moment().add(1, 'day'))) {
              enable = false;
            }
            const my_obj = {
              date: start.toISOString(),
              str: start.format('HH:mm'),
              enable: enable,
              slot: dispo,
            }
            if (enable) { // si le slot est actif
              // si la durée de la formation est imposée
              if (this.formationService.checkFormationSessionForceDurationExist(this.formation)) {
                // Si ce start est lié à un end dont la durée est compatible avec la durée imposée
                if (this.checkIfEndIfPossible(start)) {
                  this.startTime.push(my_obj);
                }
              } else {
                this.startTime.push(my_obj);
              }
            }
          }
          start = start.add(30, 'minutes');
        }
      });
    }
  }

  initEnd(slot) {
    this.endTime = [];
    this.slotEnd_FormControl.reset();
    const day = this.availablesDays.filter(days => moment(days.day).isSame(moment(this.selectedDate), 'day'))
    if (day) {
      const moment_start_value = moment(this.slotStart_FormControl.value);
      let max_duration = 2;
      if (this.formation.hours_to_plan < 2) {
        max_duration = this.formation.hours_to_plan;
      }
      let hoursForceDuration = null;
      if (this.formationService.checkFormationSessionForceDurationExist(this.formation)) {
        const forceDuration = this.formationService.getFormationSessionForceDuration(this.formation);
        hoursForceDuration = parseInt(forceDuration.value) / 2;
      }

      const max_end_value = moment(this.slotStart_FormControl.value).add(max_duration, 'hours');
      let start = moment(slot.start);
      const end = moment(slot.end);
      const duration = moment.duration(end.diff(start));
      const slotsLength = duration.asHours() * 2;
      for (let i = 0; i < slotsLength; i++) {
        start = start.add(30, 'minutes');
        if (start.isAfter(moment_start_value) && start.isSameOrBefore(max_end_value)) {
          let isEnable = true;

          const slot_duration = moment.duration(start.diff(moment_start_value));
          const diff_slot_hours = slot_duration.asHours();
          // a améliorer pour mieux gérer le code de la gestion de la durée du cours. A refactoriser
          // si la formation à une durée imposée
          if (hoursForceDuration) {
            // si la durée du slot n'est égale 
            if (diff_slot_hours !== hoursForceDuration) {
              isEnable = false;
            }
          }
          const my_obj = {
            date: start.toISOString(),
            str: start.format('HH:mm'),
            enable: isEnable,
          }
          this.endTime.push(my_obj);
        }
      }
      let initEndValue = moment(this.slotStart_FormControl.value).add(60, 'minutes');
      const isDispoInOneHour = this.endTime.filter(end => moment(end.date).toISOString() === initEndValue.toISOString())
      if (isDispoInOneHour.length === 0) {
        initEndValue = moment(this.slotStart_FormControl.value).add(30, 'minutes');
      }
      this.slotEnd_FormControl.setValue(initEndValue.toISOString());
    }
  }

  handleUpdateAvailables(_date) {
    this.month = _date.month;
    this.year = _date.year;
    this.getTeacherAvailabiliies();
  }

  chooseSlot() {
    const slot = {
      id: Math.random(),
      start: moment(this.slotStart_FormControl.value),
      end: moment(this.slotEnd_FormControl.value),
      teacher: this.teacher,
    }
    const isExist = this.checkIfEventExist(slot);
    if (isExist) {
      return;
    }
    this.classToCreate.push(slot)
    if (this.multiSlot) {
      this.slotStart_FormControl.reset();
      this.slotEnd_FormControl.reset();
      this.endTime = [];
    }
    this.checkIfEnoughTime();
  }

  checkIfEnoughTime() {
    let timeToBook = 0;
    this.classToCreate.forEach(slot => {
      timeToBook += moment.duration(slot.end.diff(slot.start)).asHours();
    })
    this.formation.hours_to_plan - timeToBook <= 0 ? this.noMoreTime = true : this.noMoreTime = false;
  }

  deleteClassToCreate(slot) {
    this.classToCreate = this.classToCreate.filter(_slot => _slot.start !== slot.start);
    this.checkIfEnoughTime();
  }

  closeCreatedPopup() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  saveBooking() {
    this.loaderWritePlanning = true;
    this.eventsToCreate = [];
    this.classToCreate.forEach(slot => {
      const event = new Event();
      event.calendar = this.formation.calendar;
      if (this.teacher) {
        event.teacher = slot.teacher.url;
      } else {
        event.teacher = null;
      }
      event.start = slot.start.toISOString();
      event.end = slot.end.toISOString();
      event.event_type = 'ponctual_session';
      event.title = 'ponctual booking';
      event.rule = null;
      this.eventsToCreate.push(event)
    });
    this.postBooking();
  }

  postBooking() {
    const generatePonctualBookingRequests = [];
    this.eventsToCreate.forEach(event => {
      generatePonctualBookingRequests.push(this.planService.generatePonctualBooking(this.formation, event))
    })
    forkJoin(generatePonctualBookingRequests).subscribe(result => {
      if (result.length >= 0) {
        if (result[0] === 'error_teacher_not_available') {
          this.formationService.toastService.show('Le cours n\'a pas pu être réservé ! merci de contacter le service client', 4000, 'red');
          this.resetSlotSelect();
          this.eventsToCreate = [];
          this.classToCreate = [];
          this.loaderWritePlanning = false;
        } else {
          this.formationService.getObservableFormationByUrl(this.formation.url).subscribe(formation => {
            this.formation = formation;
            this.formationService.currentFormationSubject.next(formation);
            this.occurrenceService.getObservableOccurence(formation.next_occurrence).subscribe(occ => {
              if (occ) {
                this.formationService.nextOccurrence.next(occ);
                this.resetSlotSelect();
                this.classCreated.emit({ classCreated: result });
                this.eventsToCreate = [];
                this.classToCreate = [];
                this.loaderWritePlanning = false;
              }
            });
          });
        }
      }

    });
  }


  checkIfEventExist(slotToCreate) {
    let isExist = false;
    const format = 'hh:mm';
    this.classToCreate.forEach(slot => {
      if (slot.start) {
        const new_id = slot.id;
        const new_start = slot.start;
        const new_end = slot.end;
        const new_start_time = moment(new_start.utc().format('HH:mm'), format);
        const new_end_time = moment(new_end.utc().format('HH:mm'), format);

        const start = moment(slotToCreate.start)
        const end = moment(slotToCreate.end)
        const start_time = moment(start.utc().format('HH:mm'), format)
        const end_time = moment(end.utc().format('HH:mm'), format)
        const isBetween = new_start_time.isBetween(start_time, end_time, undefined, '[)');
        const isSame = new_start_time.isSame(start_time) && new_end_time.isSame(end_time);
        if (new_start.weekday() === start.weekday() &&
          (start.isSameOrAfter(new_start) && start.isBefore(new_end) || end.isAfter(new_start) && end.isSameOrBefore(new_end)) && isExist === false
        ) {
          isExist = true;
          this.formationService.toastService.show('Vous essayez d\'ajouter deux fois le meme créneau !', 4000, 'red');
        }
      }
    });
    return isExist;
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }
}
