import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';
import { Country } from '../model/country.model';

@Injectable()
export class CountryService extends KiwixiService {
  apiUpcoming = environment.server + 'country/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getCountries(params: HttpParams): Observable<Country[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Country[]>(this.apiUpcoming, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Country[]>(`getCountries`))
      );
  }

  getCountriesWithUrl(_url): Observable<Country[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Country[]>(_url, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Country[]>(`getCountriesWithUrl`))
      );
  }
}

