import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { ConfirmationDialogComponent } from './../../../../../feature/confirmation-dialog/confirmation-dialog.component';
import { registerLocaleData } from '@angular/common';
import { InvoiceService } from './../../../../../service/invoice.service';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Invoice } from '../../../../../model/invoice.model';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss', '../../mainscreen.ux.scss']
})
export class InvoiceListComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'select',
    'id',
    'invoice_id',
    'periodicity',
    'teacher',
    'amount',
    'status',
    'uploaded_at',
    'payment_method',
    'actions'
  ];
  invoices: Invoice[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  defaultAvatar = environment.static + 'avatar.png';
  invoicesStatus = this.invoiceService.getInvoicesStatus();
  paiementMethod = this.invoiceService.getInvoicesPayment();
  totalAmountRequest: Number = 0;
  selection = new SelectionModel(true, []);
  modeAllPage = false;
  allIds;
  page_size = 10;
  environment = environment;


  filterFormGroup = new FormGroup({
    id: new FormControl(),
    invoice_id: new FormControl(),
    teacher: new FormControl(),
    date_from: new FormControl(),
    date_to: new FormControl(),
    amount_from: new FormControl(),
    amount_to: new FormControl(),
    status: new FormControl('received'),
    upload_date_from: new FormControl(),
    upload_date_to: new FormControl(),
    paiement: new FormControl(),
  });

  bulkActionFormGroup = new FormGroup({
    statusBulkUpdate: new FormControl(),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    public dialog: MatDialog,
    private toastService: MzToastService,) { }

  loadFilterListing() {
    if (this.invoiceService.filterInvoiceListing.value) {
      this.filterFormGroup.controls.id.setValue(this.invoiceService.filterInvoiceListing.value.id);
      this.filterFormGroup.controls.invoice_id.setValue(this.invoiceService.filterInvoiceListing.value.invoice_id);
      this.filterFormGroup.controls.teacher.setValue(this.invoiceService.filterInvoiceListing.value.teacher);
      this.filterFormGroup.controls.date_from.setValue(this.invoiceService.filterInvoiceListing.value.date_from);
      this.filterFormGroup.controls.date_to.setValue(this.invoiceService.filterInvoiceListing.value.date_to);
      this.filterFormGroup.controls.amount_from.setValue(this.invoiceService.filterInvoiceListing.value.amount_from);
      this.filterFormGroup.controls.amount_to.setValue(this.invoiceService.filterInvoiceListing.value.amount_to);
      this.filterFormGroup.controls.status.setValue(this.invoiceService.filterInvoiceListing.value.status);
      this.filterFormGroup.controls.upload_date_from.setValue(this.invoiceService.filterInvoiceListing.value.upload_date_from);
      this.filterFormGroup.controls.upload_date_to.setValue(this.invoiceService.filterInvoiceListing.value.upload_date_to);
      this.filterFormGroup.controls.paiement.setValue(this.invoiceService.filterInvoiceListing.value.paiement);
    }
  }

  ngAfterViewInit() {
    this.loadFilterListing();
    this.invoiceService.setFilterListing(this.filterFormGroup.value);
    this.filterFormGroup.valueChanges.debounceTime(1000).subscribe(data => {
      this.invoiceService.setFilterListing(data);
      this.applyFilter();
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.getInvoices();
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          this.totalAmountRequest = data.total;
          this.allIds = data.allids;

          return data.results;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.invoices = data;
      });
  }

  getInvoices() {
    return this.invoiceService.getInvoicesByFilter(this.paginator.pageSize, this.paginator.pageIndex, this.sort.active, this.sort.direction)
  }

  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getInvoices().subscribe(
      _invoices => {
        this.selection.clear();
        this.modeAllPage = false;
        this.resultsLength = _invoices.count;
        this.invoices = _invoices.results;
        this.totalAmountRequest = _invoices.total;
        this.allIds = _invoices.allids;
      });
  }

  clearFilter() {
    this.filterFormGroup.reset();
  }

  getTotalAmountPage() {
    return this.invoices.map(i => i.amount).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isPageSelected() {
    let pageSelected = true;
    this.invoices.forEach(invoice => {
      if (!this.selection.isSelected(invoice.id)) {
        pageSelected = false;
      }
    })
    return pageSelected;
  }

  clearPage() {
    this.invoices.forEach(invoice => {
      if (this.selection.isSelected(invoice.id)) {
        this.selection.toggle(invoice.id);
      }
    })
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isPageSelected() ?
      this.clearPage() :
      this.invoices.forEach(invoice => this.selection.select(invoice.id));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isPageSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected((row.id)) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  selectAllPage() {
    this.modeAllPage = !this.modeAllPage;
    if (this.modeAllPage) {
      this.allIds.forEach(id => this.selection.select(id));
    } else {
      this.selection.clear()
    }
  }

  getSelectedInvoices() {
    return this.selection.selected.map(invoice_id => invoice_id);
  }

  postBulkUpdate() {
    const selectedInvoices = this.getSelectedInvoices();
    const new_status = this.bulkActionFormGroup.controls.statusBulkUpdate.value;

    this.invoiceService.bulkUpdate(new_status, this.modeAllPage, this.allIds, selectedInvoices).subscribe(
      result => {
        this.applyFilter();
        this.bulkActionFormGroup.controls.statusBulkUpdate.reset();
      });
  }

  bulkUpdate(value) {
    if (this.checkIfMinInvoiceSelected() === false) {
      this.bulkActionFormGroup.controls.statusBulkUpdate.reset();
      return;
    }
    if (this.modeAllPage && this.allIds.length > 1000) {
      this.toastService.show('Impossible de modifier plus de 1000 éléments à la fois', 4000, 'red');
      this.bulkActionFormGroup.controls.statusBulkUpdate.reset();
      return;
    }

    this.openDialog(value);
  }

  openDialog(value): void {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'Vous êtes sur le point de modifier le statut de ' + this.selection.selected.length + ' éléments avec cette valeur : ' + value
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postBulkUpdate()
      }
    });
  }

  checkIfMinInvoiceSelected() {
    if (this.selection.selected.length === 0) {
      this.toastService.show('Veuillez selectionnez au moins une facture', 4000, 'red');
      return false;
    } else {
      return true;
    }
  }

  /**
   * méthode pour download le fichier d'export.
   * @param _type
   */
  export(_type) {
    if (this.checkIfMinInvoiceSelected() === false) {
      return;
    }
    const _url = environment.server + 'invoices/export/';
    const selectedInvoices = this.getSelectedInvoices();
    if (selectedInvoices) {
      let _contentType = null;
      if (_type === 'hsbc') {
        _contentType = 'application/xml';
      } else if (_type === 'comptable') {
        _contentType = 'text/csv';
      }
      // this.invoiceService.postExport(_url, _type, selectedInvoices, 'export_' + _type, _contentType)
      //   .then(res => {
      //     this.toastService.show('Téléchargement terminé', 4000, 'green');
      //   })
    }
  }

  createExport() {

    this.router.navigateByUrl('/invoices/export/create',
      {
        state: {
          seletedInvoices: this.selection.selected,
          paiement_method: this.filterFormGroup.controls.paiement.value,
        }
      }
    );
  }

  showRecord(row) {
    this.router.navigate(['/invoices/' + row.id]);
  }

  /**
   * méthode pour copier l'email
   * @param val
   */
  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.show(val + ' a été ajouté à votre press papier', 2000, 'green');
  }

}
