/**
 * Composant pour l'affichage des évaluations.
 */

import { Component, OnInit, OnChanges } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { FormationService } from '../../../../service/formation.service';
import { Occurrence } from '../../../../model/occurrence.model';
import { InitAppService } from '../../../../service/initapp.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnChanges {
  pastOccurrences: Occurrence[];
  occurrences: Occurrence[];
  formation: any;
  pastOccurrencesIsReady = false;
  formation_id = null;
  countOccurencesPages = 0;
  totalOccurrences = 0;

  constructor(
    private route: ActivatedRoute,
    private formationService: FormationService,
    private initAppService: InitAppService,

  ) { }
  ngOnInit() {
    this.pastOccurrences = [];
    this.formation_id = this.route.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, this.formation_id);
    this.formationService.currentFormationSubject.subscribe(_formation => {
      if (_formation) {
        this.formation = _formation;
        this.pastOccurrences = []
        this.occurrences = []
        this.getOccurences();
      }
    })
  }

  ngOnChanges() { }

  /**
   * Récupération des occurrences
   */
  getOccurences() {
    this.countOccurencesPages++;
    this.formationService.getOccurrences(this.formationService.extractIdFromUrl(this.formation.student.url),
      this.formationService.extractIdFromUrl(this.formation.url), this.countOccurencesPages)
      .then(
        _occurrences => {
          if (_occurrences) {// pour chaque occurrence on ajoute la session
            for (const _occurrence of _occurrences['results']) {
              this.occurrences.push(_occurrence)
            }
            // pagination
            if (_occurrences['next'] !== null) {
              this.getOccurences()
            } else {
              this.countOccurencesPages = 0;
              // qd toutes les occurrences sont récupéres on affiche...
              this.totalOccurrences = this.occurrences.length; // compte des occurences
              this.pastOccurrences = this.buildRadarOccurrencesList(); // radars
              // on tri l'affichage en focntion de la date.
              this.pastOccurrences.sort(function (a, b) {
                return new Date(a.start).getTime() - new Date(b.start).getTime()
              });
              this.pastOccurrencesIsReady = true; // les cours passé sont pret à etre affichés dans l'évaluation.
            }
          }
        }
      );
  }

  // Affichage des occurrences pour le radar.
  buildRadarOccurrencesList() {
    return this.occurrences.filter(myoccs =>
      myoccs.status === 'done' ||
      myoccs.status === 'old_report'
    );
  }

}


