/**
 * sous-composant pour gérer les contacts.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { TeacherService } from '../../../../../service/teacher.service';
import { StudentService } from '../../../../../service/student.service';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../service/authentification.service';
import { FormControl } from '@angular/forms';
import { FormationService } from '../../../../../service/formation.service';
import { ConversationService } from '../../../../../service/conversation.service';
import { Teacher } from '../../../../../model/teacher.model';
import * as moment from 'moment';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {
  teachers: any[];
  teachersMessagesList: any[];
  countPagesTeachers = 1;
  students: any[];
  studentsMessagesList: any[];
  countPagesStudents = 1;
  allContacts: any[] = [];
  contacts: any[] = [];
  my_timeout = null;
  formation_in_charge: Boolean;
  requestTeachersUrl = null;
  requestStudentsUrl = null;
  myUser: any;
  searchInput = '';
  countPages = 1;
  filterSelect = 1;
  studentsAreLoaded = false;
  teachersAreLoaded = false;
  showContacts = false;
  defaultAvatar = environment.static + '/avatar.png';
  searchControl: FormControl = new FormControl();
  filterSelectCtrl: FormControl = new FormControl();

  constructor(
    private teacherService: TeacherService,
    private studentService: StudentService,
    private formationService: FormationService,
    private conversationService: ConversationService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    // on récupère le user actif.
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
          this.initContactsList('');
          // initialise du debounce de recherche des contacts.
          this.searchControl.valueChanges
            .debounceTime(300)
            .subscribe(term => {
              this.initContactsList(term);
            })
        }
      }
    )
  }

  ngOnDestroy() {
    if (this.my_timeout) {
      this.my_timeout.unsubscribe();
    }
  }

  /**
   * Polling pour mettre à jour la liste des contacts
   */
  polling() {
    this.my_timeout = Observable.interval(300000)
      .subscribe(i => {
        this.initContactsList(this.searchInput);
      })
  }

  /**
   * Initialisation de la liste des contacts.
   * @param term
   */
  initContactsList(term) {
    this.allContacts = [];
    this.teachers = [];
    this.teachersMessagesList = [];
    this.students = [];
    this.studentsMessagesList = [];
    this.getAllTeachers(term);
    this.getAllStudents(term);
  }

  /**
   * Récupération des teachers
   * @param term
   */
  getAllTeachers(term) {
    const _teachers = [];
    let params = new HttpParams();
    params = params.append('teachers__user__last_name__icontains', term);
    params = params.append('page', this.countPages.toString());
    params = params.append('ordering', 'teacher__user__last_name');
    this.formationService.getObservableFormations(params)
      .subscribe(formations => {
        formations
          .map(e => e.teachers)
          .filter(a => {
            a.filter(b => {
              _teachers.push(b)
            })
          })
        if (_teachers.length > 0) {
          let countOtherTeachers = 0;
          this.teacherService.getMultipleTeachers2(_teachers)
            .subscribe(_res => {
              _res.forEach(_teacher => {
                this.conversationService.getUserMessages(null, 'teacher', _teacher['url'], 1)
                  .subscribe(_messages => {
                    if (_messages) {
                      // *** check unread from api ***
                      _teacher['messages'] = [];
                      _messages['results'].forEach(_mess => {
                        if (_mess.from_user === this.myUser.url || _mess.to === this.myUser.url) {
                          _teacher['messages'].push(_mess)
                        }
                      });
                      // _teacher['messages'] = _messages['results'];
                      _messages['unread'] = _teacher['messages'].filter(_mess => !_mess.opened).length;
                      if (Number(_messages['unread']) > 9) {
                        _teacher['unread'] = 9
                      } else {
                        _teacher['unread'] = _messages['unread'];
                      }
                      // *** check updated_at from api ***
                      if (_teacher['unread'] > 0) {
                        _teacher['updated_at'] = _teacher['messages'][0]['updated_at']
                      }
                      if (!this.checkIfExist(_teacher, this.teachers)) {
                        // On filtre si l'inputSearch correspond au last_name des profs de la formation
                        if (_teacher.user.last_name.indexOf(term) !== -1) {
                          this.teachers.push(_teacher);
                        }
                      }
                      this.addToContacts(this.teachers, 'teacher');
                      countOtherTeachers++;
                      if (countOtherTeachers === _teachers.length) {
                        // this.teachersMessagesList = this.sortContacts(this.teachers);
                        this.teachersMessagesList = this.teachers;
                        this.teachersAreLoaded = true;
                        this.diplayContacts();
                      }
                    }
                  })
              });
            })
        } else {
          this.teachersAreLoaded = true;
          this.diplayContacts();
        }
      })
  }

  checkIfExist(obj, list) {
    let _exist = false
    for (const _item of list) {
      if (_item.url === obj.url) {
        _exist = true;
      }
    }
    return _exist
  }

  /**
   * récupérer les élèves.
   */
  getAllStudents(term) {
    let params = new HttpParams();
    params = params.append('student_name', term);
    params = params.append('page', this.countPages.toString());
    params = params.append('ordering', 'student__user__last_name');
    this.formationService.getObservableFormations(params)
      .subscribe(formations => {
        const allCurrentStudent = [];
        let countCurrentStudent = 0;
        const currentStudentsFormations = formations
          .filter(_formationsCurrent => _formationsCurrent.status === 'active')
        if (currentStudentsFormations.length > 0) {
          currentStudentsFormations.forEach(_formation => {
            if (_formation) {
              this.studentService.getObservableStudent(_formation['student'])
                .subscribe(_student => {
                  this.conversationService.getUserMessages(_formation.url, 'student', _student.url, 1)
                    .subscribe(_messages => {
                      if (_messages) {
                        countCurrentStudent++;
                        _student['messages'] = [];
                        _messages['results'].forEach(_mess => {
                          if (_mess.from_user === this.myUser.url || _mess.to === this.myUser.url) {
                            _student['messages'].push(_mess)
                          }
                        });
                        // *** set unreads ***
                        _messages['unread'] = _student['messages'].filter(_mess => !_mess.opened).length;
                        // set Languages
                        _student['languages'] = this.addLanguages(_student, formations);
                        if (Number(_messages['unread']) > 9) {
                          _student['unread'] = 9;
                        } else {
                          _student['unread'] = _messages['unread'];
                        }
                        // *** set updated_at  ***
                        if (_student['messages'].length > 0) {
                          _student['updated_at'] = _student['messages'][0]['updated_at']
                        }
                        // - un élève peut avoir plusieurs formations on ajoute empile les messages/unread/ de toutes les formations
                        if (!this.checkIfExist(_student, allCurrentStudent)) {
                          allCurrentStudent.push(_student);
                        } else {
                          for (const _item of allCurrentStudent) {
                            if (_item.url === _student.url) {
                              if (_student['messages'].length > 0) {
                                for (const _newMess of _student['messages']) {
                                  _item['messages'].push(_newMess);
                                }
                                _item['updated_at'] = _student['updated_at'];
                              }
                              _item['unread'] = _item['unread'] + _student['unread'];
                            }
                          }
                        }
                        this.addToContacts(allCurrentStudent, 'student');
                        if (countCurrentStudent === currentStudentsFormations.length) {
                          this.studentsMessagesList = allCurrentStudent;
                          this.studentsAreLoaded = true;
                          this.diplayContacts();
                        }
                      }
                    })
                })
            }
          });
        } else {
          this.studentsAreLoaded = true;
          this.diplayContacts();
        }
      })
  }

  /**
   * ajout des languages
   * @param _student
   * @param formations
   */
  addLanguages(_student, formations) {
    const myLanguages = [];
    for (const _formStudentUrl of _student.formations) {
      formations.forEach(_formation => {
        if (_formation.url === _formStudentUrl) {
          if (!checkIfLanguageExist(_formation.language, myLanguages)) {
            myLanguages.push(_formation.language);
          }
        }
      });
    }
    return myLanguages

    function checkIfLanguageExist(obj, list) {
      let _exist = false
      for (const _item of list) {
        if (_item === obj) {
          _exist = true;
        }
      }
      return _exist
    }
  }

  /**
   * méthode pour ajouter des contacts.
   * @param _contacts
   * @param _role
   */
  addToContacts(_contacts, _role) {
    _contacts.forEach(_contact => {
      _contact.role = _role;
      if (_contact.formation_id) {
        if (!this.allContacts.find(o => o.url === _contact.url && o.formation_id === _contact.formation_id)) {
          this.allContacts.push(_contact);
        }
      } else {
        if (!this.allContacts.find(o => o.url === _contact.url)) {
          this.allContacts.push(_contact);
        }
      }
    });
  }

  /**
   * Methode pour triés les contacts.
   * @param allContacts
   */
  sortContacts(allContacts) {
    const contacts = [];
    const unreadList = allContacts.filter(_contacts => _contacts.unread > 0);
    const readList = allContacts.filter(_contacts => _contacts.unread === 0);
    this.sortListByDatetime(unreadList);
    this.sortListByDatetime(readList);
    // #1 - contact with unreads
    for (const _unread of unreadList) {
      contacts.push(_unread)
    }
    for (const _read of readList) {
      contacts.push(_read)
    }
    return contacts;
  }

  // input search
  search(term: string): void {
    this.searchInput = term;
    this.searchControl.patchValue(term);
  }

  // tri par datetime
  sortListByDatetime(_list) {
    _list.sort(compare)

    function compare(a, b) {
      if (moment(a.updated_at) > moment(b.updated_at)) {
        return -1;
      }
      if (moment(a.updated_at) < moment(b.updated_at)) {
        return 1;
      }
      return 0;
    }
  }

  /**
   * Methode pour afficher les contacts triés.
   */
  diplayContacts() {
    if (this.studentsAreLoaded && this.teachersAreLoaded) {
      this.contacts = this.sortContacts(this.allContacts);
      this.showContacts = true;
    }
  }

}
