import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageService } from '../service/language.service';
import { FormationListMenuComponent } from './formation-list-menu/formation-list-menu.component';
import { FormationItemMenuComponent } from './formation-item-menu/formation-item-menu.component';
import { LangFlagComponent } from './lang-flag/lang-flag.component';
import { EditTeacherEvaluationComponent } from './edit-teacher-evaluation/edit-teacher-evaluation.component';
import { PersonaAvatarComponent } from './persona-avatar/persona-avatar.component';
import { SelectTimePickerComponent } from './select-time-picker/select-time-picker.component';
import { SelectTimeRowComponent } from './select-time-row/select-time-row.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MaterialModule } from '../material.module';
import { MomentModule } from 'ngx-moment';
import { BookingTimeRowComponent } from './booking-time-row/booking-time-row.component';
import { ChipsComponent } from './chips/chips.component';
import { EventsTeacherComponent } from './events-teacher/events-teacher.component';
import { SolutionTeachersComponent } from './solution-teachers/solution-teachers.component';
import { StudentEvaluationComponent } from './student-evaluation/student-evaluation.component';
import { MinimalPersonaAvatarComponent } from './minimal-persona-avatar/minimal-persona-avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadLingueoDirective } from './download-lingueo/download-lingueo.directive';
import { OutlookLingueoDirective } from './outlook-lingueo/outlook-lingueo.directive';
import { ManageExercicesMediasComponent } from './manage-exercices-medias/manage-exercices-medias.component';
import { ManageExercicesAnswersTextComponent } from './manage-exercices-answers-text/manage-exercices-answers-text.component';
import { ManageExercicesAnswersChoiceComponent } from './manage-exercices-answers-choice/manage-exercices-answers-choice.component';
import { PreventPasteDirective } from './prevent-paste.directive';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    MomentModule,
  ],
  declarations: [
    FormationListMenuComponent,
    LangFlagComponent,
    FormationItemMenuComponent,
    EditTeacherEvaluationComponent,
    PersonaAvatarComponent,
    SelectTimePickerComponent,
    SelectTimeRowComponent,
    CalendarComponent,
    BookingTimeRowComponent,
    ChipsComponent,
    // check if useful
    EventsTeacherComponent,
    SolutionTeachersComponent,
    StudentEvaluationComponent,
    MinimalPersonaAvatarComponent,
    DownloadLingueoDirective,
    OutlookLingueoDirective,
    ManageExercicesMediasComponent,
    ManageExercicesAnswersTextComponent,
    ManageExercicesAnswersChoiceComponent,
    PreventPasteDirective
  ],
  exports: [
    FormationListMenuComponent,
    LangFlagComponent,
    EditTeacherEvaluationComponent,
    PersonaAvatarComponent,
    SelectTimePickerComponent,
    SelectTimeRowComponent,
    CalendarComponent,
    BookingTimeRowComponent,
    MinimalPersonaAvatarComponent,
    ChipsComponent,
    DownloadLingueoDirective,
    OutlookLingueoDirective,
    ManageExercicesMediasComponent,
    ManageExercicesAnswersTextComponent,
    ManageExercicesAnswersChoiceComponent,
    PreventPasteDirective
  ],
  providers: [LanguageService]
})
export class DirectiveModule { }
