import { ProfileGuard } from './../../../../guard/profile.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../../guard/auth.guard';
import { FormationComponent } from '../../formation/formation.component';
import { PastComponent } from '../../formation/past/past.component';
import { EvaluationComponent } from '../../formation/evaluation/evaluation.component';
import { DashboardComponent } from '../../formation/dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: 'formation/:id',
    component: FormationComponent,
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.dashboard' } },
      { path: 'upcomings', loadChildren: '../../formation/upcoming/upcoming.module#UpcomingModule', canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.upcoming' } },
      { path: 'past', component: PastComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.past' } },
      { path: 'progression', component: EvaluationComponent, canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.progression' } },
      { path: 'sheets', loadChildren: './../../formation/sheets/sheets.module#SheetsModule', canActivate: [AuthGuard, ProfileGuard], data: { 'title': 'formation.sheets' } },
      { path: 'messages', loadChildren: './../../formation/messages/messages.module#MessagesModule', data: { 'title': 'formation.messages' } }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HeaderRoutingModule { }
