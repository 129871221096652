<div class='weekdays-list' *ngIf="device === 'mobile'">
  <div class="weekdays-day" *ngFor="let day of weekDays; let i = index" (click)="goToDate(i)"><b>{{day}}</b></div>
</div>

<div class="error" *ngIf="errors && errors.errorOverlap" translate>
  <i class="material-icons">warning</i>
  teacher.schedule.error.error-overlap
</div>
<div class="error" *ngIf="errors && errors.errorPast" translate>
  <i class="material-icons">warning</i>
  teacher.schedule.error.error-past-event
</div>
<div class="error" *ngIf="errors && errors.errorTwoDays" translate>
  <i class="material-icons">warning</i>
  teacher.schedule.error.error-two-days
</div>

<full-calendar 
    #generalCalendar
    [header]="{
      left: '',
      center: '',
      right: ''
    }" 
    [eventTimeFormat]="eventTimeFormat" 
    defaultView='timeGridWeek'
    [weekends]="true" 
    [selectable]="true"
    [selectMirror]="true" 
    [plugins]="calendarPlugins" 
    [allDaySlot]="false" 
    [firstDay]="1" 
    [columnHeaderFormat]="{weekday: 'long'}"
    [scrollTime]="'08:00:00'"
    (select)="handleDateSelect($event)" 
    (eventDrop)="handleEventDrop($event)"
    (eventResize)="handleEventResize($event)"
    [selectOverlap]="selectOverlap"
    [eventOverlap]="eventOverlap"
    (eventClick)="handleClickEvent($event)"
    [longPressDelay]="800"
    >
  </full-calendar>
  <mat-spinner *ngIf="loading" class="loader"></mat-spinner>
