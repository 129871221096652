import { FormationService } from './../../../../service/formation.service';
/**
 * Composant manager du profil student.
 */

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
// Model
import { Student } from '../../../../model/student.model';
import { Timezone } from '../../../../model/timezone.model';

// Service
import { TimezoneService } from '../../../../service/timezone.service';
import { UserService } from '../../../../service/user.service';

@Component({
  selector: 'app-profil-student',
  templateUrl: './profil-student.component.html',
  styleUrls: ['./profil-student.component.scss']
})
export class ProfilStudentComponent implements OnInit {

  translatePath = 'lingueopro.profil';
  defaultAvatar = environment.static + '/avatar.png';
  init = false;
  // Zones
  zones: Timezone[];
  timezoneControl: FormControl = new FormControl();
  myTimezone: any;
  filteredTimezones: Observable<Timezone[]>;

  // input du composant parent
  @Input() student: Student;
  @Input() current_formation;
  @Output() updateProfileStatus = new EventEmitter();

  constructor(
    private timezoneService: TimezoneService,
    private userService: UserService,
    private formationService: FormationService,
  ) { }

  ngOnInit() {
    this.zones = [];
    this.init = false;

    // recupération des timezones.
    this.timezoneService.getTimezones({})
      .then(_timezonesData => {
        for (const timezone of _timezonesData) {
          this.zones.push(timezone)
        }
        this.timezoneControl.setValue(this.student.user.timezone);
      });

    // init timezones combo
    this.filteredTimezones = this.timezoneControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterTimezone(val) : this.zones);
  }

  // gestion des timezone
  updateUserTimezone() {
    if (this.init) {
      this.student.user.timezone = this.myTimezone ? this.myTimezone['label'] : null;
    }
  }

  // filtrer la liste de timezones.
  filterTimezone(val: string): Timezone[] {
    return this.zones.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) >= 0);
  }

  /**
   * mettre à jour le user
   * @param user
   * @param field
   */
  updateUser(user, field) {
    const param = {};
    param[field] = user[field];
    this.userService.patchUser(user.url, param)
  }

  /**
   * méthode pour télécharger un fichier.
   * @param $event
   * @param field
   */
  uploadFile($event, field) {
    for (const file of $event.target.files) {
      const param = {};
      param[field] = this.student[field];
      this.userService.patchFile(this.student.user.url, this.student.user, file)
        .then(_student => {
          this.student.user = _student;
        });
    }
  }

  goToSkype() {
    this.updateProfileStatus.emit(false);
  }

  logout() {
    this.updateProfileStatus.emit(false);
    this.userService.logout();
    this.formationService.listeFormations.next(null);
    this.formationService.currentFormationSubject.next(null);
  }

}
