import { Corporate } from './../model/corporate.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';
import { CorporateList } from '../model/corporateList.model';

@Injectable()
export class CorporateService extends KiwixiService {
    apiCorporate = environment.server + 'corporates/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getCorporates(params: HttpParams): Observable<CorporateList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<CorporateList>(this.apiCorporate, {
            headers: header,
            params: params,
        }).
            pipe(
                catchError(this.handleErrorObservable<CorporateList>(`getCorporates`))
            );
    }

    getCorporateWithUrl(_url): Observable<Corporate[]> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<Corporate[]>(_url, {
            headers: header
        }).
            pipe(
                catchError(this.handleErrorObservable<Corporate[]>(`getCorporateWithUrl`))
            );
    }
}

