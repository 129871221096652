<ng-container *ngIf="taskSheetToDo.length>0">
  <div class="sheetslist-subtitle" *ngIf="title"><span translate [innerHtml]="title"></span> :</div>
  <div class="sheetlist-wrapper">
      <div class="sheetlist-row" *ngFor="let task of taskSheetToDo">
        <div class="sheetlist-subwrap">
        <div class="sheet-icon" [ngClass]="{'sheet-validated': task.what.is_validate_by_student==true, 'sheet-todo': task.what.is_todo_by_student==true}"></div>
        <span class="sheet-title">{{ task.what.title_fr }}</span>
        <button class="seesheet-btn" (click)='emitSeeSheetOutput("destop", task.what)'></button>
        </div>
        <button class="deletesheet-btn" (click)="deleteTask(task)"></button>
      </div>
  </div>
</ng-container>