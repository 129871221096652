import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validate-sheet',
  templateUrl: './validate-sheet.component.html',
  styleUrls: ['./validate-sheet.component.scss']
})
export class ValidateSheetComponent implements OnInit {
  @Input() examinationDuration: number;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
  }

  goToExamination(): void {
    this.router.navigate(['./examination/'], { relativeTo: this.route });
  }
}
