/**
 * Composant principal pour la planification.
 * Intègre un sous-touting pour les pages/composant : Daily, availability, unavailibility.
 */

import { Component, OnInit } from '@angular/core';
import { AchievementService } from '../../../service/achievement.service';
import { AchievementDoneservice } from '../../../service/achievementDone.service';
import { AchievementManagerService } from '../../../service/achievementManager.service';
import { Achievement } from '../../../model/achievement.model';
import { AuthenticationService } from '../../../service/authentification.service';
import { Teacher } from '../../../model/teacher.model';
import { HelpNotifManagerService } from '../../../service/helpNotifManagerService';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  isSchedule = true;
  translatePath = 'teacher.schedule';

  achievement: Achievement = null;
  helps = [];
  canFire = false;
  teacher: Teacher = null;

  device = this.authentification.getDeviceMode();

  constructor(
    private achievementManagerService: AchievementManagerService,
    private authenticationService: AuthenticationService,
    private helpNotifManagerService: HelpNotifManagerService,
    private achievementDoneservice: AchievementDoneservice,
    private achievementService: AchievementService,
    private authentification: AuthenticationService,
  ) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(_current_user => {
      this.teacher = _current_user;
    });

    this.getCalendarAchievement(this.teacher.achievements);
    this.achievementManagerService.achievementToFire.subscribe(val => {
      if (this.achievement && val) {
        if (this.achievementManagerService.canFire(this.achievement)) {
          this.helpNotifManagerService.setHelpNotif(this.achievement);
          this.canFire = true;
        }
      }
    })
  }

  getCalendarAchievement(achievements: Achievement[]) {
    if (achievements) {
      if (achievements.length > 0) {
        this.achievement = achievements.filter(a => a.slug === 'calendar-tooltip' ? a : null)[0];
      }
    }
  }

  postAchivement() {
    this.achievementManagerService.setAchievementAsFire(this.achievement);
    let _params = {
      achievement: this.achievement.url,
      content_type_model: 'teacher',
      object_id: this.teacher.id
    }
    this.achievementDoneservice.postAchievementDone(_params).subscribe(achievementDone => {
      this.achievement.achievements_done = achievementDone.created_at;
      this.achievementService.updatePersonaAchievement(this.teacher, this.achievement);
    })
  }
}
