<div id="assign-sheets" *ngIf="occurrence">
    <div class="assign-header">
        <i class="material-icons back-btn" routerLink="/upcoming">arrow_back</i>
        <span class="page-title" translate>teacher.sheets.title</span>
        <a class="class-ref" routerLink="/upcoming">for {{ occurrence.student.user.first_name}}'s {{ occurrence.start | date:'MMM dd' }} class</a>
    </div>

    <div class="assign-mainwrap">

        <div class="sheetslists-wrap">
            <app-sheet-assign
                [formation]="null" 
                [occurrence]="occurrence"
                [validatedByStudent]="occurence.student"
                [reportAddedSheet]="false" 
                (emitSheetToView)="showSheet($event)" 
                (isSheetAdded)="null"  
                *ngIf="occurrence">
            </app-sheet-assign>
        </div>

        <div class="desktop-sheet-viewer">
            <app-sheet-view [sheet]="sheet" [showExercices]="false" [showExamination]="false" [filterByStudent]=""></app-sheet-view>
        </div>

    </div>

</div>