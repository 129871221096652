<div *ngIf="!loadedSession || !loadedNextSession || !loadedRating">
Loading...
</div>
<form class="tookplace-forms" [formGroup]="reportForm" *ngIf="loadedSession && loadedNextSession && loadedRating">
  <app-confirm-identity [formation]="formation"></app-confirm-identity>
  <div class="forms-wrapper">
    <div class="form-subsec-title" translate>{{translatePath}}.feedback-text</div>
    <mat-form-field>
      <textarea matInput [formControl]="feedbackCtrl" name="feedback"></textarea>
      <mat-hint class="minLength" *ngIf="feedbackCtrl.hasError('minlength') || !feedbackCtrl.dirty"><strong>Your feedback should be at least 100 characters</strong> </mat-hint>
      <mat-hint align="end">{{feedbackCtrl.value?.length}}</mat-hint>

    </mat-form-field>
  
    <ng-container *ngIf="nextOccurrence?.session" >
      <div class="form-subsec-title" translate>{{translatePath}}.for-next-time-text</div>
      <mat-form-field>
        <textarea matInput [formControl]="nextTimeMessageCtrl" name="nextTime"></textarea>
        <mat-hint class="minLength" *ngIf="nextTimeMessageCtrl.hasError('minlength') || !nextTimeMessageCtrl.dirty"><strong>Your feedback should be at least 100 characters</strong> </mat-hint>
        <mat-hint align="end">{{nextTimeMessageCtrl.value?.length}}</mat-hint>
      </mat-form-field>
    </ng-container>
  </div>

  <div id="upcoming-assigned" *ngIf="nextOccurrence && sheetPermission && isSheetLanguageAvailable">
    <div class="form-subsec-title"><span translate>teacher.report.next_time_study_sheet</span></div>
    <app-sheet-assigned *ngIf="nextOccurrence" [occurrence]="nextOccurrence"></app-sheet-assigned>
    <button class="assignsheet-btn" *ngIf="nextOccurrence" routerLink="./assignsheet/{{ nextOccurrence?.id }}"><span>Assign a study sheet</span></button>
  </div>

  <div class="reportPanel">
    <div class="form-subsec-title">Student's Level</div>
      <div class="title">Please report the student's level in each category below. You may also specify how close the student is to the next level.</div>
     <div class="axes-wrapper">
      <div class="axe">
        <div class="axe_title">Grammar</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="grammarLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">Level Percentage:</div>
          <div>
            <mat-radio-group  [formControl]="grammarPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Expression</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="expressionLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">Level Percentage:</div>
          <div>
            <mat-radio-group  [formControl]="expressionPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Comprehension</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="comprehensionLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">Level Percentage:</div>
          <div>
            <mat-radio-group  [formControl]="comprehensionPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Vocabulary</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="vocabularyLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">Level Percentage:</div>
          <div>
            <mat-radio-group  [formControl]="vocabularyPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Accent</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="accentLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">Level Percentage:</div>
          <div>
            <mat-radio-group  [formControl]="accentPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Overall Spoken Level</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="oralLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Overall Written Level</div>
        <div class="axe_data">
          <div class="axe_data_title">Student's Level:</div>
          <div>
            <mat-radio-group  [formControl]="writtenLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      
    </div>
  </div>

  <div class="report-buttons-row">
    <button class="action-btn"  (click)="save()" [disabled]="!reportForm.valid || saveLoading">
      <span class="loader-wheel" *ngIf="saveLoading"></span>
      <span translate>Save</span>
    </button>
  </div>
</form>
