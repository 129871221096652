<div id="upcoming-classes-list">
  <h5 class="page-title">Liste des prochains cours</h5>
  <button class="clear-btn" (click)="clearFilter()"> Vider les filtres </button>
 
  <div class="row">
    <div [formGroup]="filterFormGroup">
      <mat-spinner *ngIf="loaderOccurrences"></mat-spinner>
      <table mat-table [dataSource]="occurrences" class="upcoming-table" matSort matSortActive="id" matSortDisableClear
        matSortDirection="desc">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id cours</th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="id-description">
          <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="id" />
          </th>
        </ng-container>

        <ng-container matColumnDef="student">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Eleve </th>
          <td mat-cell *matCellDef="let element" (click)="showDetailStudent(element.student.user)">
            <app-datatable-cell-student [student]="element.student">
            </app-datatable-cell-student>
          </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="student-description">
          <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="student_name" />
          </th>
        </ng-container>

        <ng-container matColumnDef="teacher">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Professeur </th>
          <td mat-cell *matCellDef="let element" (click)="showDetailTeacher(element.teacher)">
            <app-datatable-cell-teacher [teacher]="element.teacher">
            </app-datatable-cell-teacher>
          </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="teacher-description">
          <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="teachers_name" />
          </th>
        </ng-container>

        <ng-container matColumnDef="formation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Formation </th>
          <td mat-cell *matCellDef="let element">
            <app-datatable-cell-formation [formation]="element.formation">
            </app-datatable-cell-formation>
          </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="formation-description">
          <th mat-header-cell *matHeaderCellDef>
            <input name="filter-id" formControlName="formation_name" />
          </th>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let element">
            {{element.start | date: 'dd MMM yyyy HH:mm'}} à 
            {{element.end | date: 'HH:mm'}}
          </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="date-description">
          <th mat-header-cell *matHeaderCellDef>
            <div class="date-select">
              <input class="date-input range-left-input" (click)="selectDateStart.open()"
                [matDatepicker]="selectDateStart" placeholder="du" [(ngModel)]="filterDateFrom.date"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="formatDate() ? getOccurences() : ''">
              <mat-datepicker #selectDateStart></mat-datepicker>
            </div>

            <div class="date-select">
              <input class="date-input range-left-input" (click)="selectDateEnd.open()" [matDatepicker]="selectDateEnd"
                placeholder="au" [(ngModel)]="filterDateTo.date" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="formatDate() ? getOccurences() : ''">
              <mat-datepicker #selectDateEnd></mat-datepicker>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element">
            {{element.status}}
          </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="status-description">
          <th mat-header-cell *matHeaderCellDef>
            <mat-select formControlName="status" class="header-select">
              <mat-option *ngFor="let method of sessionsStatus" [value]="method.code">
                {{method.label}}
              </mat-option>
            </mat-select>
          </th>
        </ng-container>

        <ng-container matColumnDef="teams">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.provider_link">
              <button class="copylink-btn" (click)="copyLink(element)">Copier le lien</button>
            </div>
            <div *ngIf="!element.provider_link">
              <button class="createroom-btn" (click)="generateTeamsLink(element)" [disabled]="loaderGenerateLink">Créer la réunion
                TEAMS</button>
              <div class="creating-loadertext" *ngIf="loaderGenerateLink && generateLinkID === element.id">Création de la réunion en cours</div>
            </div>
          </td>
        </ng-container>

        <!-- student Description Column -->
        <ng-container matColumnDef="teams-description">
          <th mat-header-cell *matHeaderCellDef>
          </th>
        </ng-container>

        <tr mat-header-row class="first-line-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-header-row class="second-line-header" *matHeaderRowDef="[
              'id-description',
              'student-description',
              'teacher-description',
              'formation-description',
              'date-description',
              'status-description',
              'teams-description'
             ]">
        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="resultsLength" class="paginator" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]">
      </mat-paginator>
    </div>
  </div>

</div>