import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Staff } from '../model/staff.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class StaffService extends KiwixiService {

  apiStaffs = environment.server + 'staffs/';

  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getStaff(id): Observable<Staff> {
    const header = this.createHttpClientAuthorizationHeader();
    const url = this.apiStaffs + id + '/';
    return this._http.get<Staff>(url, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Staff>(`getStaff id=${id}`))
    );
  }

  getStaffs(): Promise<Staff[]> {
    const headers = this.createHttpClientAuthorizationHeader();
    const _params = new HttpParams().set('page_size', '100');
    return this._http.get(this.apiStaffs, { headers: headers, params: _params })
      .toPromise()
      .then((res: any) => res['results'])
      .catch(this.handleError);
  }

  getObservableStaffs(params: HttpParams): Observable<Staff[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Staff[]>(this.apiStaffs, {
      headers: header,
      params: params
    })
      .map(_staffs => _staffs['results'])
      .pipe(
        catchError(this.handleErrorObservable<Staff[]>(`getObservableStaffs`))
      );
  }

  patchStaff(staffUrl, param): Promise<Staff> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(staffUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
        console.error('error Staff service', error);
      })
  }

  getObservableStaffById(id): Observable<Staff> {
    const url = this.apiStaffs + id + '/';
    return this.getObservableStaffByUrl(url);
  }

  getObservableStaffByUrl(_url): Observable<Staff> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Staff>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Staff>(`getObservableStaffByUrl`))
      );
  }

}
