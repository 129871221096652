<div class="trainpast-wrapper">
  <div class="reports-wrapper">
    <ng-container *ngIf="pendings.length > 0">
      
      
      
      <!-- Pendings -->
      <div class="pendinglist-wrapper">
        <b class="subsection-title">Pending reports</b>
        <div class="pending-counter">
          {{countPendings}}
          <span translate>{{translatePath}}.count-pending</span>
          {{lastUpcomingDate}}
        </div>
        <div *ngFor="let pending of pendings">
            <app-report [showStudentName]="true" [showTeacherName]="true" [occurrence]= "pending"></app-report>
        </div>
        <div *ngIf="loading_pending==true" class="loading-text">
            Loading Pending Reports                   
            <mat-spinner class="loader"></mat-spinner>
          </div>
        <div *ngIf="nextPagesPending" (click)="getPendings()" class="more">more</div>
      </div> 
    </ng-container>  

    <!-- search completed reports -->
    <div class="done-reports-space">
      <b class="subsection-title" translate>{{translatePath}}.completed-reports</b>
      <div class="donelist-wrapper">
        <div *ngFor="let ratingStudent of ratingStudents">
          <div (click)="goToInfo(ratingStudent)" class="done-report">
              <div class="done-overlay"></div>
              <app-report [showStudentName]="true" [showTeacherName]="true" [ratingStudent]="ratingStudent"></app-report>
          </div>
        </div>
        <div *ngIf="loadingCompletedReport==true" class="loading-text">
            Loading Completed Reports                   
            <mat-spinner class="loader"></mat-spinner>
          </div>
        <div *ngIf="nextPagesCompleted"(click)="getFilterCompletedReports()" class="more">More</div>
      </div>
    </div>
  </div>
</div>