<div class="information-container" *ngIf="myTeacher">
  <div class="row form-row">
    <div class="col l5">
      <h6 class="darken-1 white-text title-information">Administratif</h6>
    </div>
  </div>
  <div class="row row-bottom-none">
    <div class="col l12">
      <div class="row valign-wrapper form-row">
        <div class="col l3 left">
          <span class="left">Statut :</span>
        </div>
        <div class="col l5 left">
          <mat-select class="dropdown" [(ngModel)]="myTeacher.status"
            (ngModelChange)="updateTeacher(myTeacher, 'status')" style="width: 60%;">
            <mat-option *ngFor="let status of listStatus" [value]="status.code">
              {{ status.label }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row valign-wrapper form-row">
        <div class="col l3">
          <span class="left">Adresse :</span>
        </div>
        <div class="col l9">
          <mz-textarea-container class="textarea-full-width">
            <textarea class="largetextfield" mz-textarea [(ngModel)]="myTeacher.address" id="teachers-notes"
              (focusout)="updateTeacher(myTeacher, 'address')" maxlength="70" appPreventPaste>{{myTeacher.address}}
            </textarea>
            <div class="error" *ngIf="errors.address">L'adresse ne doit pas contenir de caractère spéciaux</div>
          </mz-textarea-container>
        </div>
      </div>
      <!-- CP -->
      <div class="row valign-wrapper form-row">
        <div class="col l5">
          <span class="left">Code Postal :</span>
        </div>
        <div class="input-field col l7">
          <input type="text" class="validate filter-input" maxlength="32" [(ngModel)]="myTeacher.cp"
            (focusout)="updateTeacher(myTeacher, 'cp')">
        </div>
      </div>
      <!-- Ville -->
      <div class="row valign-wrapper form-row">
        <div class="col l5">
          <span class="left">Ville :</span>
        </div>
        <div class="input-field col l7">
          <input type="text" class="validate filter-input" maxlength="255" [(ngModel)]="myTeacher.city" (focusout)="updateTeacher(myTeacher, 'city')">
          <div class="error" *ngIf="errors.city">La ville ne doit pas contenir de caractères spéciaux</div>
        </div>
      </div>
      <!-- Pays -->
      <div class="row valign-wrapper">
        <div class="col l4">
          <p class="left">Pays :</p>
        </div>
        <div class="col l8">
          <form>
            <mat-form-field>
              <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                [disabled]="!countrySelect">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCountryFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                  (focusout)="selectCountry(option)">
                  {{ option.label }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </div>

      <!-- Phone -->
      <div class="row valign-wrapper form-row">
        <div class="col l5">
          <span class="left">Téléphone fixe :</span>
        </div>
        <div class="input-field col l7">
          <input type="text" class="validate filter-input" maxlength="255" [(ngModel)]="myTeacher.phone"
            (focusout)="updateTeacher(myTeacher, 'phone')">
        </div>
      </div>

      <!-- Mobile Phone -->
      <div class="row valign-wrapper form-row">
        <div class="col l5">
          <span class="left">Téléphone mobile :</span>
        </div>
        <div class="input-field col l7">
          <input type="text" class="validate filter-input" maxlength="255" [(ngModel)]="myTeacher.mobile_phone"
            (focusout)="updateTeacher(myTeacher, 'mobile_phone')">
        </div>
      </div>

      <div class="admindocs-block">
        <!-- Piece d'identité -->
        <div class="row valign-wrapper form-row document-row" *ngIf="myTeacher.id_card !== null; else elseIdCard">
          <div class="document-name">
            <span class="left">Pièce d'identité :</span>
          </div>
          <i class="material-icons file-icon">insert_drive_file</i>
          <div class="col l3 btn-box">
            <button class="action-button" mz-button [flat]=true (click)="docIdCard.click()">
              <span>Ouvrir</span>
            </button>
            <a #docIdCard href="{{myTeacher.id_card}}" [hidden]="true" target="_blank"></a>
          </div>
          <div class="col l3 btn-box">
            <button #upload class="action-button" mz-button [flat]=true (click)="changeIdCard.click()">
              <span>Changer</span>
            </button>
            <input #changeIdCard type="file" (change)="uploadFile($event, 'id_card')" [hidden]="true" />
          </div>
        </div>
        <ng-template #elseIdCard>
          <div class="row valign-wrapper form-row document-row">
            <div class="document-name">
              <span class="left">Pièce d'identité :</span>
            </div>
            <i class="material-icons file-icon grey-text">insert_drive_file</i>
            <div class="col l3 btn-box">
              <button class="action-button" mz-button [flat]=true (click)="uploadIdCard.click()">
                <span>Uploader</span>
              </button>
              <input #uploadIdCard type="file" (change)="uploadFile($event, 'id_card')" [hidden]="true" />
            </div>
            <div class="col l3"></div>
          </div>
        </ng-template>

        <!-- Preuve statut fiscal -->
        <div class="row valign-wrapper form-row document-row" *ngIf="myTeacher.tax_proof !== null; else elseTaxProof">
          <div class="document-name">
            <span class="left">Preuve statut fiscal :</span>
          </div>
          <i class="material-icons file-icon">insert_drive_file</i>
          <div class="col l3 btn-box">
            <button class="action-button" mz-button [flat]=true (click)="docTaxProof.click()">
              <span>Ouvrir</span>
            </button>
            <a #docTaxProof href="{{myTeacher.tax_proof}}" [hidden]="true" target="_blank"></a>
          </div>
          <div class="col l3 btn-box">
            <button #upload class="action-button" mz-button [flat]=true (click)="changeTaxProof.click()">
              <span>Changer</span>
            </button>
            <input #changeTaxProof type="file" (change)="uploadFile($event, 'tax_proof')" [hidden]="true" />
          </div>
        </div>
        <ng-template #elseTaxProof>
          <div class="row valign-wrapper form-row document-row">
            <div class="document-name">
              <span class="left">Preuve statut fiscal :</span>
            </div>
            <i class="material-icons grey-text file-icon">insert_drive_file</i>
            <div class="col l3 btn-box">
              <button class="action-button" mz-button [flat]=true (click)="uploadTaxProof.click()">
                <span>Uploader</span>
              </button>
              <input #uploadTaxProof type="file" (change)="uploadFile($event, 'tax_proof')" [hidden]="true" />
            </div>
            <div class="col l3"></div>
          </div>
        </ng-template>

        <!-- Contrat Presta -->
        <div class="row valign-wrapper form-row document-row"
          *ngIf="myTeacher.service_contract !== null; else elseServiceContract">
          <div class="document-name">
            <span class="left">Contrat prestation :</span>
          </div>
          <i class="material-icons file-icon">insert_drive_file</i>
          <div class="col l3 btn-box">
            <button class="action-button" mz-button [flat]=true (click)="docContract.click()">
              <span>Ouvrir</span>
            </button>
            <a #docContract href="{{myTeacher.service_contract}}" [hidden]="true" target="_blank"></a>
          </div>
          <div class="col l3 btn-box">
            <button #upload class="action-button" mz-button [flat]=true (click)="changeServiceContract.click()">
              <span>Changer</span>
            </button>
            <input #changeServiceContract type="file" (change)="uploadFile($event, 'service_contract')"
              [hidden]="true" />
          </div>
        </div>
        <ng-template #elseServiceContract>
          <div class="row valign-wrapper form-row document-row">
            <div class="document-name">
              <span class="left">Contrat prestation :</span>
            </div>
            <i class="material-icons grey-text file-icon">insert_drive_file</i>
            <div class="col l3 btn-box">
              <button class="action-button" mz-button [flat]=true (click)="uploadServiceContract.click()">
                <span>Uploader</span>
              </button>
              <input #uploadServiceContract type="file" (change)="uploadFile($event, 'service_contract')"
                [hidden]="true" />
            </div>
            <div class="col l3"></div>
          </div>
        </ng-template>
        <!-- CV -->
        <div class="row valign-wrapper form-row document-row">
          <div class="document-name">
            <span class="left">Curriculum vitae :</span>
          </div>
          <i class="material-icons file-icon">insert_drive_file</i>
          <div *ngIf="myTeacher.cv" class="col l3 btn-box">
            <button class="action-button" mz-button [flat]=true (click)="docContract.click()">
              <span>Ouvrir</span>
            </button>
            <a #docContract href="{{myTeacher.cv}}" [hidden]="true" target="_blank"></a>
          </div>
          <div class="col l3 btn-box">
            <button #upload class="action-button" mz-button [flat]=true (click)="changeServiceContract.click()">
              <span *ngIf="myTeacher.cv">Changer</span>
              <span *ngIf="!myTeacher.cv">Uploader</span>
            </button>
            <input #changeServiceContract type="file" (change)="uploadFile($event, 'cv')"
              [hidden]="true" />
          </div>
        </div>
      </div>
      <div class="admin-fiscal-block">
        <div class="row valign-wrapper form-row">
          <div class="col l5">
            <span class="left">N° statut fiscal :</span>
          </div>
          <div class="input-field col l7">
            <input type="text" class="validate filter-input" [(ngModel)]="myTeacher.tax_status_number"
              (focusout)="updateTeacher(myTeacher, 'tax_status_number')">
          </div>
        </div>
        <div class="row valign-wrapper form-row">
          <div class="col l5">
            <span class="left">Type de paiement :</span>
          </div>
          <div class="col l7">
            <mat-select class="dropdown" [(ngModel)]="myTeacher.preferred_payment"
              (ngModelChange)="updateTeacher(myTeacher, 'preferred_payment')" style="width: 60%;">
              <mat-option *ngFor="let payment of listPayments" [value]="payment.code">
                {{ payment.label }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div [ngSwitch]="myTeacher.preferred_payment">
          <div class="row valign-wrapper form-row" *ngSwitchCase="'paypal'">
            <div class="col l4">
              <span class="left">Email Paypal :</span>
            </div>
            <div class="input-field col l8">
              <input type="text" class="validate filter-input" [(ngModel)]="myTeacher.paypal_email"
                (focusout)="updateTeacher(myTeacher, 'paypal_email')">
            </div>
          </div>

          <div class="row valign-wrapper form-row" *ngSwitchCase="'iban'">
            <div class="col l4">
              <span class="left">IBAN :</span>
            </div>
            <div class="input-field col l8">
              <input type="text" class="filter-input" [ngClass]="ibanIsValid(myTeacher.iban)"
                [(ngModel)]="myTeacher.iban" (focusout)="updateTeacher(myTeacher, 'iban')">
            </div>
          </div>

          <div class="row valign-wrapper form-row" *ngSwitchCase="'iban'">
            <div class="col l4">
              <span class="left">Swift(BIC) :</span>
            </div>
            <div class="input-field col l8">
              <input type="text" class="validate filter-input" [(ngModel)]="myTeacher.bic"
                (focusout)="updateTeacher(myTeacher, 'bic')">
            </div>
          </div>

        </div>

        <div class="row valign-wrapper form-row">
          <div class="col l6">
            <span class="left">Tarif horaire (Général) :</span>
          </div>
          <div class="input-field col l2">
            <input type="number" class="validate filter-input" [(ngModel)]="myTeacher.generic_hourly_rate"
              (focusout)="updateTeacher(myTeacher, 'generic_hourly_rate')">
          </div>
          <div class="col l1">€</div>
          <div class="col l3"></div>
        </div>
        <div class="row valign-wrapper form-row">
          <div class="col l6">
            <span class="left">Tarif horaire (Pro) :</span>
          </div>
          <div class="input-field col l2">
            <input type="number" class="validate filter-input" [(ngModel)]="myTeacher.professionnal_hourly_rate"
              (focusout)="updateTeacher(myTeacher, 'professionnal_hourly_rate')">
          </div>
          <div class="col l1">€</div>
          <div class="col l3"></div>
        </div>
      </div>
      <div class="memo-block">
        <div class="row valign-wrapper form-row">
          <div class="col l2">
            <span class="left">Mémo :</span>
          </div>
          <div class="col l10">
            <mz-textarea-container class="textarea-full-width">
              <textarea class="largetextfield" mz-textarea [(ngModel)]="myTeacher.memo" id="teachers-memo"
                (focusout)="updateTeacher(myTeacher, 'memo')">{{myTeacher.memo}}
              </textarea>
            </mz-textarea-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>