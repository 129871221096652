<div routerLink="{{myLink}}">
  <div class="contact-avatar-wrapper">
    <div class="unread-counter"*ngIf="contact.unread > 0">{{contact.unread}}</div>
    <div class="avatar-inset-shadow"></div>
    <div class="contact-avatar">
      <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="contact.user.photo_profile === null" (error)="this.kiwixiService.errorImgHandler($event)">
      <img class="user-avatar" src="{{contact.user.photo_profile.thumbnail}}" *ngIf="contact.user.photo_profile !== null">
    </div>
  </div>
  <div class="contact-detail">
    <div class="left">
      <b>{{contact.user.first_name}} {{contact.user.last_name}}</b>
      ( 
        <span>{{contact.role}}</span>
        <span class="flag-wrapper" *ngFor = "let language of contact.languages">
            <app-lang-flag [language]="language"></app-lang-flag>
        </span>
      )
    </div>
    <div class="right message-time">
      <ng-container *ngIf="isToday; else elseToday">
          {{contact.updated_at | amDateFormat:'HH:mm'}}
      </ng-container>
      <ng-template #elseToday>
        le {{contact.updated_at | amDateFormat:'DD/MM/YYYY'}}
      </ng-template>
    </div>
  </div>
  <div *ngIf="contact.messages && contact.messages[0]" class="contact-detail">
    <ng-container *ngIf="isAdmin; else elseAdmin">
        <span class="preview-user">Moi :</span>
    </ng-container>
    <ng-template #elseAdmin>
        <span class="preview-user">{{contact.user.first_name}} :</span>
    </ng-template>
    <i class="message-preview">{{contact.messages[0].content}}</i>
  </div>
</div>