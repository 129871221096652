import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import { EventApi } from '@fullcalendar/core';
import { AuthenticationService } from '../../../../../service/authentification.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarService, eventType } from '../../../../../service/calendar.service';
import * as moment from 'moment';
@Component({
  selector: 'app-availability-calendar',
  templateUrl: './availability-calendar.component.html',
  styleUrls: ['./availability-calendar.component.scss']
})
export class AvailabilityCalendarComponent implements AfterViewInit {
  @ViewChild('generalCalendar') calendarComponent: FullCalendarComponent;
  @Input() calendarId;
  @Input() calendarUrl;


  allSlots = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30'
    , '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'
    , '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59']

  weekDays = [
    moment().startOf('isoWeek').format('ddd'),
    moment().startOf('isoWeek').add(1, 'day').format('ddd'),
    moment().startOf('isoWeek').add(2, 'days').format('ddd'),
    moment().startOf('isoWeek').add(3, 'days').format('ddd'),
    moment().startOf('isoWeek').add(4, 'days').format('ddd'),
    moment().startOf('isoWeek').add(5, 'days').format('ddd'),
    moment().startOf('isoWeek').add(6, 'days').format('ddd')
  ]

  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin];
  device = this.authentification.getDeviceMode();

  loading = true;

  errors = {
    errorOverlap: false,
    errorPast: false,
    errorTwoDays: false,
  }

  ladingFct = (value) => this.loading = value;

  constructor(
    private calendarService: CalendarService,
    private authentification: AuthenticationService
  ) { }

  ngAfterViewInit() {
    moment.locale('en-GB');
    this.device === 'desktop' ? this.calendarComponent.getApi().changeView('timeGridWeek') : this.calendarComponent.getApi().changeView('timeGridDay');
    this.calendarService.setCalendar(this.calendarComponent);
    this.calendarService.getEvents(eventType.availability, false, null, this.calendarId, true, this.ladingFct, false);
    if (this.device === 'mobile') {
      this.goToDate(0);
    }
  }

  goToDate(index) {
    if (this.device === 'mobile') {
      const days = document.getElementsByClassName('weekdays-day');
      Array.from(days).forEach((day) => {
        day.classList.remove('active-day');
      })
      days[index].classList.add('active-day');
    }
    this.calendarService.goToDate(moment().startOf('isoWeek').add(index, 'days').toDate())
  }

  /**
   * Callback function au click sur un event pour edition
   */
  handleClickEvent(selectInfo) {
    this.clearErrors();
    // Ouvre la modal que si il s'agit d'un event ponctual
    if (selectInfo.event.extendedProps.type === eventType.availability) {
      const newEvent = {
        start: moment(selectInfo.event.start).toISOString(),
        end: moment(selectInfo.event.end).toISOString(),
        url: selectInfo.event.extendedProps.url
      }

      this.calendarService.openModal({
        event: newEvent,
        availablesStartSlots: this.getAvailableSlots(newEvent, 'start'),
        availablesEndSlots: this.getAvailableSlots(newEvent, 'end'),
        eventToRemove: selectInfo.event
      }, false);
    }
  }

  /**
   * Callback function au select dans le calendar
   */
  handleDateSelect(selectInfo) {
    this.clearErrors();
    if (this.calendarService.isSameDay(selectInfo.startStr, selectInfo.endStr)) {
      this.calendarService.selectableCalendar(false);

      const newEvent = {
        start: moment(selectInfo.startStr).toISOString(),
        end: moment(selectInfo.endStr).toISOString(),
        event_type: eventType.availability,
        calendar: this.calendarUrl
      }

      // Si simple click alors ouverture de modal sinon création de l'event
      if (moment(selectInfo.endStr).diff(moment(selectInfo.startStr), 'minutes') === 30) {
        if (moment(newEvent.end).format('HH:mm') === '00:00') {
          newEvent.end = moment(newEvent.end).subtract(1, 'second').toISOString();
        }
        this.device === 'desktop'
          ? this.calendarService.openModal({ event: newEvent, maxRange: this.getNextEvent(newEvent) }, true)
          : setTimeout(() => { this.calendarService.openModal({ event: newEvent, maxRange: this.getNextEvent(newEvent) }, true) }, 100);
      } else {
        this.calendarService.saveEvent(newEvent);
      }
    }
  }

  /**
   * Callback function au resize dans le calendar
   */
  handleEventResize = (eventResizeInfo) => {
    this.clearErrors();
    if (this.calendarService.isSameDay(eventResizeInfo.event.start, eventResizeInfo.event.end)) {
      this.calendarService.handleEventResize(eventResizeInfo)
    } else {
      eventResizeInfo.revert();
      this.errors.errorTwoDays = !this.calendarService.isSameDay(eventResizeInfo.event.start, eventResizeInfo.event.end)
    }
  }
  /**
   * Callback function au drag an drop dans le calendar
   */
  handleEventDrop = (eventDropInfo) =>  {
    this.clearErrors();
    if (this.calendarService.isSameDay(eventDropInfo.event.start, eventDropInfo.event.end)) {
      this.calendarService.handleEventDrop(eventDropInfo)
    } else {
      eventDropInfo.revert();
      this.errors.errorTwoDays = !this.calendarService.isSameDay(eventDropInfo.event.start, eventDropInfo.event.end)
    }
  }

  clearErrors() {
    this.errors = {
      errorOverlap: false,
      errorPast: false,
      errorTwoDays: false,
    }
  }

  /**
   * Callback function au drag an drop dans le calendar
   */
  getNextEvent(checkEvent) {
    if (this.calendarService.getAvailabilityEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start), moment(checkEvent.start).endOf('day').toISOString())).sort(function (a, b) { return a.start.getTime() - b.start.getTime() }).length > 0) {
      const nextEventTime = this.calendarService.getAvailabilityEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start), moment(checkEvent.start).endOf('day').toISOString())).sort(function (a, b) { return a.start.getTime() - b.start.getTime() })[0].start;
      return [nextEventTime.getHours(), nextEventTime.getMinutes()].map(this.calendarService.leadingZero).join(':');
    } else {
      return '23:59'
    };
  }

  /**
   * Retourne tout les slot dispo de la journée en comparaison a un event
   */
  getAvailableSlots(checkEvent, type) {
    const slots = this.allSlots.slice();
    type === 'start' ? slots.pop() : slots.splice(0, 1);
    if (this.calendarService.getAvailabilityEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start).startOf('day'), moment(checkEvent.start).endOf('day'))).sort(function (a, b) { return a.start.getTime() - b.start.getTime() }).length > 0) {
      this.calendarService.getAvailabilityEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start).startOf('day'), moment(checkEvent.start).endOf('day'))).sort(function (a, b) { return a.start.getTime() - b.start.getTime() }).forEach(
        element => {
          if (moment(checkEvent.start).toDate().getTime() !== moment(element.start).toDate().getTime()) {
            if (slots.indexOf(moment(element.start).format('HH:mm')) > 0) {
              // l'utilisation de Math.round pour gerer le slot de 23:59
              type === 'end'
                ? slots.splice(slots.indexOf(moment(element.start).format('HH:mm')) + 1, Math.round((moment(element.end).diff(moment(element.start), 'minutes') / 30)) === 1 ? Math.round((moment(element.end).diff(moment(element.start), 'minutes') / 30)) : Math.round((moment(element.end).diff(moment(element.start), 'minutes') / 30)) - 1)
                : type === 'start' ? slots.splice(slots.indexOf(moment(element.start).format('HH:mm')), (moment(element.end).diff(moment(element.start), 'minutes') / 30))
                  : slots;
            }
          }
        }
      );
    }
    return slots;
  }

  selectOverlap = (event: EventApi): Boolean => {
    this.errors.errorOverlap = eventType.availability ? true : false;
    return event.extendedProps.type === eventType.availability ? false : true;
  }

  eventOverlap = (still: EventApi, moving: EventApi): Boolean => {
    this.errors.errorOverlap = eventType.availability ? true : false;
    return still.extendedProps.type === eventType.availability ? false : true;
  }

  /**
   * Retourne les horraires des events au bon format
   */
  eventTimeFormat = (date) => this.calendarService.eventTimeFormat(date);

}
