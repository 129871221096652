export class Progression {
  url: string;
  level: number;
  labels: string[];
  data: number[];
  slider_value: number;
  from_formation: Date;
  to_formation: Date;
  current_date: Date;
  constructor() { }

}
