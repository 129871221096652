import { Formation } from './../../model/formation.model';
import { Occurrence } from './../../model/occurrence.model';
import { Router } from '@angular/router';
/**
 * Composant pour la gestion du radar.
 */

import { Component, OnInit, Input, OnChanges, AfterViewChecked, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { RadarService } from './radar2.service';
import { SessionService } from '../../service/session.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { environment } from '../../../environments/environment';
import { BaseChartDirective } from 'ng2-charts';
@Component({
  selector: 'app-radar2',
  templateUrl: './radar2.component.html',
  styleUrls: ['./radar2.component.scss'],
  providers: [RadarService, SessionService]
})
export class Radar2Component implements OnInit, OnChanges, AfterViewChecked {
  @Input() pastOccurrences;
  @Input() formation: Formation;
  @Input() totalOccurrences;
  @Input() isBackoffice;
  @Input() radarTemplate;
  @Output() selectedOccurrence = new EventEmitter();

  @ViewChild('slideMe') private mySliderContainer: ElementRef;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  lastOccurrenceWithReport: any;
  currentOccurrence = null;
  radarChartData = [{ data: [], label: 'Niveau' }, { data: [], label: 'Niveau' }];
  radarChartLabels = Array<any>();
  chartColors = [];
  radarDataIsReady = false;
  myLilateIsReady = false;
  liliate_explaination: string;
  liliate_empty: string;
  radarOptions = {}
  myLilate: any;
  myLevel = 'A1';

  // slider
  sliderSize: string;
  sliderWidth: number;
  maxOccurrence = null;
  minOccurrence = '0';
  stepValue = 1;
  current_value = null;
  containerSilderSize: number;
  current_index = 0;
  first_init = true;
  progressionPercent: number;
  max_duration = 0;
  mapTimeWithOccurrences: any[] = [];
  identityCheck = false;
  constructor(
    private kiwixiGlobals: KiwixiGlobals,
    private radarService: RadarService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() {
    this.liliate_explaination = this.kiwixiGlobals.liliate_explaination;
    this.liliate_empty = this.kiwixiGlobals.liliate_empty;
    this.progressionPercent = this.getProgressionPercent(this.formation.hours_consummed, this.formation.hours_total)
  }
  emitSelectedOccurrence() {
    if (this.currentOccurrence) {
      this.selectedOccurrence.emit(this.currentOccurrence);
    }
  }
  ngOnChanges() {
    if (this.formation) {
      this.myLilate = environment.server + 'lilate/generatepdf/' + this.formation.id + '/?salt=' + Math.random();
    }

    this.identityCheck = this.checkIdentidyValidity();

    if (this.pastOccurrences.length > 0) {
      this.radarChartLabels = []
      this.radarChartData = [{ data: [], label: 'Niveau' }, { data: [], label: 'Niveau' }]
      this.maxOccurrence = this.pastOccurrences.length - 1;
      this.current_value = this.pastOccurrences.length - 1;
      this.currentOccurrence = this.pastOccurrences[this.current_value];
      this.emitSelectedOccurrence();
      if (this.first_init) {
        this.initRadar();
      }
    } else {
      this.myLevel = 'A1';
    }
  }

  checkIdentidyValidity() {
    const dateProfileFeature = moment({ y: 2021, M: 3, d: 19, h: 0, m: 0, s: 0 });
    // si la date de debut de formation est avant la mise en ligne de la fonction, c'est valide
    if ((this.formation.status === 'active' || this.formation.status === 'done' || this.formation.status === 'suspended') && moment(this.formation.validity_period_from).isBefore(dateProfileFeature)) {
      return true;
    } else { // si après la mise en ligne
      // si pas de profile
      if (!this.formation.student.user.photo_profile) {
        return false;
      }
      else { // si profile
        // et cela a été validé
        if (this.formation.student.user.photo_profile.status == 'validated') {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  ngAfterViewChecked() {
    if (this.mySliderContainer) {
      this.containerSilderSize = this.mySliderContainer.nativeElement.offsetWidth;
      this.cdRef.detectChanges();
    }
  }

  /* initialisation des données du radar */
  initRadar() {
    if (this.currentOccurrence && !this.radarDataIsReady) {
      this.sliderSize = (this.pastOccurrences.length / this.totalOccurrences * 100).toString();
      this.chartColors = this.radarService.chartColors;
      this.radarOptions = this.radarService.radarOptions;
      for (let i = this.pastOccurrences.length - 1; i >= 0; i--) {
        if (this.radarService.radarIsAvailable(this.pastOccurrences[i])) {
          this.radarDataIsReady = true;
          this.radarService.setDataRadar(this.pastOccurrences[i], this.radarChartData);
          this.myLevel = this.pastOccurrences[i].session.rate_teacher.level;
          this.lastOccurrenceWithReport = this.pastOccurrences[i]
          this.radarChartLabels = ['', '', '', '', '']
          if (this.radarTemplate === 'page') {
            this.radarChartLabels = this.radarService.radarChartLabels;
          }
          break;
        }
      }
    }
    this.first_init = false;
  }

  radarIsReady() {
    if (this.currentOccurrence) {
      return true;
    }
    return false;
  }

  evaluationIsAvailable() {
    return this.radarChartData[0]['data'].length > 0;
  }

  generateLilate() {
    this.radarService.downloadPdf(this.myLilate, this.formation, this.lastOccurrenceWithReport);
  }

  /* Methode pour calculer la progression de la formation dans le temps consommé */
  getProgressionPercent(value, total) {
    return Math.round((value / total) * 100);
  }

  /** pour chaque heure passé mettre l'occurrence correspondante**/
  setTimeLine() {
    this.mapTimeWithOccurrences = [];
    // pour chaque occurrence on recupere la duration...
    for (const _pastOccurrence of this.pastOccurrences) {
      const _ms = this.kiwixiGlobals.getDuration(_pastOccurrence.start, _pastOccurrence.end);
      const _min = this.max_duration
      const _duration = Number(moment.duration(_ms)) / 3600000;
      this.max_duration += _duration;
      this.mapTimeWithOccurrences.push({ occurrence: _pastOccurrence, min: _min, max: this.max_duration })
    }
    this.current_value = this.max_duration;
    this.currentOccurrence = this.getOccurrenceWithTimeline();
    this.emitSelectedOccurrence();
  }

  /** Methode à l'event du slider */
  slideOccurrence2() {
    this.radarChartLabels = []
    this.radarChartData = [{ data: [], label: 'Niveau' }, { data: [], label: 'Niveau' }]
    this.currentOccurrence = this.pastOccurrences[this.current_value];
    this.emitSelectedOccurrence();
    if (this.currentOccurrence) {
      if (this.radarService.radarIsAvailable(this.currentOccurrence)) {
        this.radarService.setDataRadar(this.currentOccurrence, this.radarChartData);
        this.myLevel = this.currentOccurrence.session.rate_teacher.level;
        this.radarChartLabels = this.radarService.radarChartLabels;
        this.chart.chart.config.data.labels = this.radarChartLabels;
      } else {
        // l'occurrence n'a pas de statut permettant d'afficher un les resultats d'un rapport....
        let prevIndex = this.pastOccurrences.indexOf(this.currentOccurrence) - 1;
        // ...on va recupérer le précent rapport s'il existe
        if (this.pastOccurrences[prevIndex]) {
          while (!this.radarService.radarIsAvailable(this.pastOccurrences[prevIndex])) {
            prevIndex--;
          }
        } else {
          this.myLevel = 'A1';
          prevIndex = null;
        }
        if (prevIndex) {
          this.radarService.setDataRadar(this.pastOccurrences[prevIndex], this.radarChartData);
          this.myLevel = this.pastOccurrences[prevIndex].session.rate_teacher.level;
          this.radarChartLabels = this.radarService.radarChartLabels;
          this.chart.chart.config.data.labels = this.radarChartLabels;
        }
      }
    }
  }

  getOccurrenceWithTimeline() {
    if (this.current_value > 0) {
      for (const mapTime of this.mapTimeWithOccurrences) {
        if (this.current_value > mapTime.min && this.current_value <= mapTime.max) {
          return mapTime.occurrence;
        }
      }
    } else {
      return this.mapTimeWithOccurrences[0].occurrence
    }
    return null
  }

  goToProgression() {
    this.router.navigate(['/formation/' + this.formation.id + '/progression']);

  }
}
