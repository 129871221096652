import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { EventService } from '../../service/event.service';
import { MzToastService } from 'ng2-materialize';
import { FormationService } from '../../service/formation.service';
import { PlanService } from '../../service/plan.service';

@Component({
  selector: 'app-time-selectors',
  templateUrl: './time-selectors.component.html',
  styleUrls: ['./time-selectors.component.scss']
})
export class TimeSelectorsComponent implements OnInit, OnDestroy {
  @Input() event;
  @Input() indexItem;
  @Input() formation;
  @Input() days;
  @Input() initDays;
  @Input() create_mode;
  @Input() extendChoices;
  @Output() updateEvent = new EventEmitter();
  @Output() initValidButton = new EventEmitter();

  showSchedule = false;                          // affichers les selecteurs
  translatePath = 'lingueopro.reschedule';
  firstChange = true;

  /** variables selection du jour */
  dayList: any[] = [];
  daySelected;
  showDaySelector = false;
  myAvailableDaysService;

  /** variables selection du start */
  startSelected;
  startsList: any[] = [];
  showStartSelector = false;
  startIsLoading = false;
  loadStarts = false;
  myAvailableStartService;

  /** variables selection du end */
  endSelected;
  endsList: any[] = [];
  showEndSelector = false;
  endIsLoading = false;
  loadEnds = false;
  myAvailableEndService;


  constructor(
    private eventService: EventService,
    private toastService: MzToastService,
    private planService: PlanService,
    private formationService: FormationService) { }

  ngOnInit() {
    this.showSchedule = true;
    this.setSelectors();
  }

  ngOnDestroy() {
    if (this.myAvailableDaysService) {
      this.myAvailableDaysService.unsubscribe();
    }
    if (this.myAvailableStartService) {
      this.myAvailableStartService.unsubscribe();
    }
    if (this.myAvailableEndService) {
      this.myAvailableEndService.unsubscribe();
    }
  }

  /**
   * Initialisation des selecteurs
   */
  setSelectors() {
    this.showStartSelector = false;
    this.showEndSelector = false;
    this.startIsLoading = false;
    this.endIsLoading = false;
    if (this.initDays) {
      this.initAvailabilities();
    } else {
      this.initDaysSelector();
    }
    // 1 - init day
    if (this.event && this.event.start && this.event.end) {
      const my_day = moment(this.event.start).day();
      this.daySelected = this.dayList.filter(_day => _day.moment_id === my_day)[0];
      // 2 - init start selector
      if (this.daySelected) {
        this.initStartSelector();
      }
    }
  }

  /**
   * Output de l'event sur le component parent
   */
  emitEvent() {
    this.event.start = moment(this.startSelected).toISOString();
    this.event.end = moment(this.endSelected).toISOString();
    // clean teacher
    this.event.teacher = null;
    this.updateEvent.emit(this.event);
  }

  /**
   * on supprime l'event de la liste des events de la formation.
   */
  destroyEvent() {
    this.formation.events = this.formation.events.filter(obj => obj !== this.event);
    // sauvegarde du delete:
    if (this.event.url) {
      this.eventService.delete(this.event).then(
        res => {
          this.formationService.getFormationWithUrl(this.formation.url).then(formation => {
            this.formation.hours_remaining = formation.hours_remaining;
          });
          // emit to update session listing
          this.updateEvent.emit();
          this.toastService.show('Evenement supprimé', 4000, 'green');
        },
        error => {
          this.toastService.show('Erreur de suppression de l évènement', 4000, 'red');
        }
      )
    }
    this.updateEvent.emit();
  }

  /**
   * On initialise le selecteur des jours.
   */
  initDaysSelector() {
    this.dayList = [
      { str: this.translatePath + '.monday', moment_id: 1, data_id: 0, enable: false },
      { str: this.translatePath + '.tuesday', moment_id: 2, data_id: 1, enable: false },
      { str: this.translatePath + '.wednesday', moment_id: 3, data_id: 2, enable: false },
      { str: this.translatePath + '.thursday', moment_id: 4, data_id: 3, enable: false },
      { str: this.translatePath + '.friday', moment_id: 5, data_id: 4, enable: false },
      { str: this.translatePath + '.saturday', moment_id: 6, data_id: 5, enable: false },
      { str: this.translatePath + '.sunday', moment_id: 0, data_id: 6, enable: false }
    ]

    for (const _day of this.dayList) {
      _day.enable = setDayEnable(_day.data_id, this)
    }

    this.showDaySelector = true;

    function setDayEnable(_iDay, _self) {
      if (_self.days) {
        for (const _day of _self.days) {
          if (_day === _iDay) {
            return true
          }
        }
      }
      return false
    }
  }

  /**
   * On initialise les dispos jours
   */
  initAvailabilities() {
    if (this.formation.id) {
      this.myAvailableDaysService = this.planService.getAvailableDays(this.formation.id, this.extendChoices)
        .subscribe(_days => {
          if (_days) {
            this.days = _days;
            this.initDaysSelector();
          }
        })
    }
  }

  /**
   * On initialise les dispos Starts
   */
  initStartSelector() {
    this.showStartSelector = false;
    this.showEndSelector = false;
    this.startIsLoading = true;
    this.endsList = [];
    this.endSelected = null;
    this.startsList = [];
    this.startSelected = null;
    if (!this.firstChange) {
      this.event.start = null;
      this.event.end = null;
      this.event.teacher = null;
      if (this.create_mode) {
        this.updateEvent.emit();
      } else {
        this.initValidButton.emit();
      }
    }
    getStart(this);

    function getStart(_self) {
      _self.loadStarts = true;
      _self.myAvailableStartService = _self.planService.getAvailableStarts(_self.formation.id, _self.extendChoices, _self.daySelected.data_id)
        .subscribe(_starts => {
          _self.loadStarts = false;
          if (_starts) {
            _self.startsList = [];
            let slot_moment = _self.setSlotMomentDate();
            // on init la liste des starts
            for (let i = 0; i <= 47; i++) {
              const my_obj = {
                enable: setStartSlotEnable(slot_moment, _starts),
                str: slot_moment.toISOString(),
                local_str: slot_moment.format('HH:mm'),
                tz: slot_moment.tz()
              }
              _self.startsList.push(my_obj);
              // init start si l'event existe deja
              if (_self.event.start) {
                const myStart = moment([slot_moment.year(), slot_moment.month(), slot_moment.date(),
                moment(_self.event.start).hour(), moment(_self.event.start).minute(), 0, 0]);
                if (myStart.isSame(slot_moment) && moment(_self.event.start).day() === _self.daySelected.moment_id && _self.firstChange) {
                  _self.startSelected = my_obj.str
                }
              }
              // on ajout 30min pour la prochaine slot start
              slot_moment = slot_moment.add({ minutes: 30 });
              if (_self.startsList.length === 48) {
                _self.showStartSelector = true;
                _self.startIsLoading = false;
                if (_self.startSelected) {
                  _self.initEndSelector();
                }
              }
            }
          }
        })
    }
    function setStartSlotEnable(_slot_moment, _dispos) {

      for (const _dispo of _dispos) {
        if (moment(_dispo).isSame(moment(_slot_moment))) {
          return true
        }
      }
      return false
    }
  }

  /**
   * On initialise les dispos Ends
   */
  initEndSelector() {
    this.showEndSelector = false;
    this.endIsLoading = true;
    this.endsList = [];
    this.endSelected = null;
    if (!this.firstChange) {
      this.event.end = null;
      this.event.teacher = null;
      if (this.create_mode) {
        this.updateEvent.emit();
      } else {
        this.initValidButton.emit();
      }
    }
    getEnd(this)

    function getEnd(_self) {
      _self.loadEnds = true;
      _self.myAvailableEndService = _self.planService.getAvailableEnds(_self.formation.id, _self.extendChoices, _self.daySelected.data_id, _self.startSelected)
        .subscribe(_ends => {
          _self.loadEnds = false;
          _self.endsList = [];
          if (_ends && _ends[0]) {
            setSelectorItem(_ends, _self)
          } else {
            const myFirstDispo_moment = moment(_self.startSelected);
            setSelectorItem([myFirstDispo_moment], _self)
            _self.showEndSelector = true;
            _self.endIsLoading = false;
          }
          _self.firstChange = false;
        })
    }

    function setSelectorItem(_dispos, _self) {
      const max_dispo = getMaxEnd(_dispos); // on recupère le end max;
      let slot_moment = _self.setSlotMomentDate();
      for (let i = 0; i <= 48; i++) {
        const my_obj = {
          enable: setEndSlotEnable(slot_moment, max_dispo, _self),
          str: slot_moment.toISOString(),
          local_str: slot_moment.format('HH:mm')
        }
        if (i > 0) {
          _self.endsList.push(my_obj);
        }
        // init end si l'event existe deja
        if (_self.event.end) {
          const myEnd = moment([slot_moment.year(), slot_moment.month(), slot_moment.date(),
          moment(_self.event.end).hour(), moment(_self.event.end).minute(), 0, 0]);
          if (myEnd.isSame(slot_moment) && moment(_self.event.end).day() === _self.daySelected.moment_id && _self.firstChange) {
            _self.endSelected = my_obj.str
          }
        }
        // on ajout 30min pour la prochaine slot end
        slot_moment = slot_moment.add({ minutes: 30 });
        if (_self.endsList.length === 48) {
          _self.showEndSelector = true;
          _self.endIsLoading = false;
        }
      }
    }

    function getMaxEnd(_ends) {
      let currentEnd = null
      for (const _end of _ends) {
        if (currentEnd) {
          if (moment(_end).isAfter(moment(currentEnd))) {
            currentEnd = _end;
          }
        } else {
          currentEnd = _end;
        }
      }
      return currentEnd
    }

    function setEndSlotEnable(_slot_moment, _max_dispo, _self) {
      if (moment(moment(_slot_moment)).isAfter(moment(_self.startSelected))
        && moment(_slot_moment).isSameOrBefore(moment(_max_dispo))) {
        return true
      }
      return false
    }
  }

  /**
   * Methode pour définir la date des selecteurs
   */
  setSlotMomentDate() {
    const d = moment();
    // On verifie si le jour sélectionné est antérieur à la date du jour
    if (this.daySelected.moment_id === d.day()) {
      d.add(1, 'weeks')
    } else if (this.daySelected.moment_id < d.day() && this.daySelected.moment_id > 0) {
      d.add(1, 'weeks')
    }
    let _weekday = d.weekday(this.daySelected.data_id);

    // On vérifie si le jour sélectionné est antérieur à la date du début de formation
    if (moment(this.formation.validity_period_from).isSameOrAfter(_weekday)) {
      const validity_period_from = moment(this.formation.validity_period_from);
      _weekday = validity_period_from.weekday(this.daySelected.data_id);
      if (moment(this.formation.validity_period_from).isAfter(_weekday)) {
        _weekday = _weekday.add(1, 'weeks')
      }
    }

    const myDate = moment([_weekday.year(), _weekday.month(), _weekday.date(), 0, 0, 0, 0]);
    return myDate;
  }

}
