<div class="sched-wrapper">
    <span class="page-title" translate>teacher.schedule.title</span>
    <div class="sched-tab-bar">
        <div class="schedule-tab">
            <a routerLink="./availability" routerLinkActive="router-link-active"
                [routerLinkActiveOptions]="{exact: true}" translate>
                {{translatePath}}.nav-weekly
            </a>
            <app-tooltip [slug]="'calendar-tooltip-weekly'" [position]="'bottom'" (post)="postAchivement()"></app-tooltip>
        </div>
        <div class="schedule-tab">
            <a routerLink="./ponctual" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}"
                translate>
                {{translatePath}}.nav-daily
            </a>
            <app-tooltip [slug]="'calendar-tooltip-daily'" [position]="'bottom'" (post)="postAchivement()"></app-tooltip>
        </div>
        <div class="schedule-tab">
            <a routerLink="./unavailability" routerLinkActive="router-link-active"
                [routerLinkActiveOptions]="{exact: true}" translate>
                {{translatePath}}.nav-unavailabilities
            </a>
            <app-tooltip [slug]="'calendar-tooltip-unavailabilities'" [position]="'bottom'" (post)="postAchivement()"></app-tooltip>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>