
<app-dashboard-teacher-next-session></app-dashboard-teacher-next-session>

<div class="billing-wrapper">
    <div class="dashboard-spacetitle">
		<span translate>teacher.dashboard-teacher.billing</span>
	</div>
    <app-dashboard-teacher-billing></app-dashboard-teacher-billing>
</div>

<div class="notifications-wrapper">
    <div class="dashboard-spacetitle">
		<span translate>teacher.dashboard-teacher.news</span>
	</div>
    <app-dashboard-teacher-notification></app-dashboard-teacher-notification>
</div>