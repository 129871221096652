import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Occurrence } from '../model/occurrence.model';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class NotificationService extends KiwixiService {
  apiNotifications = environment.server + 'notifications/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getNotifications(params: HttpParams): Observable<any[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<any[]>(this.apiNotifications, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<any[]>(`getNotifications`))
      );
  }

  patchNotifications(notificationId, param): Promise<Occurrence> {
    const notificationUrl = this.apiNotifications + notificationId + '/';
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(notificationUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour de la notification a échouée`, 2000, 'red');
        console.error('error patch occurrence service', error);
      })
  }
}
