import { HelpNotifList } from './../model/helpNotifList.model';
import { HelpNotif } from './../model/helpNotif.model';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';


@Injectable()
export class HelpNotifService extends KiwixiService {

    apiHelpNotif = environment.server + 'help_notifs/';


    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getHelpNotifById(_id): Observable<HelpNotif> {
        const url = this.apiHelpNotif + _id + '/';
        return this.getHelpNotifByUrl(url);
    }

    getHelpNotifByUrl(_url): Observable<HelpNotif> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<HelpNotif>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getHelpNotif`))
            );
    }

    getHelpNotifs(params: HttpParams): Observable<HelpNotifList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<HelpNotifList>(this.apiHelpNotif, {
            headers: header,
            params: params
        })
            .map(_HelpNotifs => _HelpNotifs)
            .pipe(
                catchError(this.handleErrorObservable<HelpNotifList>(`getObservableHelpNotifs`))
            );
    }

    postHelpNotif(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<HelpNotif>(this.apiHelpNotif, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<HelpNotif>(`Création d'une fiche`)),
            );
    }

    patchHelpNotif(_id, params) {
        const url = this.apiHelpNotif + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<HelpNotif>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<HelpNotif>(`Création d'une fiche`)),
            );
    }
}
