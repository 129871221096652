import { DateAdapter } from '@angular/material';
import { ReportTeacherBillingExpected } from './../../../../../model/reportTeacherBillingExpected.model';
import { ReportService } from './../../../../../service/report.service';
import { HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';


@Component({
  selector: 'app-report-teacher-billing-expected',
  templateUrl: './report-teacher-billing-expected.component.html',
  styleUrls: ['./report-teacher-billing-expected.component.scss']
})
export class ReportTeacherBillingExpectedComponent implements OnInit {
  isLoadingResults = false;
  dataResults: ReportTeacherBillingExpected[] = []
  filterFormGroup = new FormGroup({
    start_period: new FormControl('', Validators.required),
    end_period: new FormControl('', Validators.required),
  });
  public barChartColors: Color[] = [
    { backgroundColor: '#db0112' },
    { backgroundColor: '#023893' },
  ]
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartLabels: Label[] = []
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [];
  constructor(
    private reportService: ReportService,
  ) {
    moment.locale('fr')
  }

  ngOnInit() {
    this.filterFormGroup.controls.start_period.valueChanges.subscribe(value => this.initEndValue());
  }

  initEndValue() {
    if (!this.filterFormGroup.controls.end_period.value) {
      const end = moment(this.filterFormGroup.controls.start_period.value).add(2, 'month')
      this.filterFormGroup.controls.end_period.setValue(end.format());
    }
  }

  getData() {
    this.barChartData = [];
    this.barChartLabels = [];
    this.dataResults = [];
    this.isLoadingResults = true;
    let params = new HttpParams();
    params = params.append('start_period', moment(this.filterFormGroup.controls.start_period.value).toISOString());
    params = params.append('end_period', moment(this.filterFormGroup.controls.end_period.value).toISOString());
    params = params.append('salt', Math.random().toString());
    this.reportService.getReportTeacherBillingExpected(params).subscribe(data => {
      this.dataResults = data;
      this.initChart();
      this.isLoadingResults = false;
    });
  }

  initChart() {
    this.barChartLabels = this.dataResults.map(period => moment(period.month).format('MMMM'));
    this.barChartData.push({ data: this.dataResults.map(period => period.iban), label: 'Iban' })
    this.barChartData.push({ data: this.dataResults.map(period => period.paypal), label: 'Paypal' });
  }


}
