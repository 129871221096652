<div id="listing-recurring">
  <div class="row"></div>
  <div class="row">
      <div class="col left">
        <h5 class="page-title">Facturation professeurs attendue</h5>
      </div>
  </div>

  <div class="row"> 
    <div class="row">
      <div [formGroup]="filterFormGroup">
        <div class="col l2 filter">
          <input class="date-input range-left-input" (click)="start_period.open()" [matDatepicker]="start_period" formControlName="start_period" placeholder="du">
          <mat-datepicker #start_period></mat-datepicker>
        </div>
        <div class="col l2 filter">
          <input class="date-input range-left-input" (click)="end_period.open()" [matDatepicker]="end_period" formControlName="end_period" placeholder="du">
          <mat-datepicker #end_period></mat-datepicker>
        </div>
    
        <div class="col l2 filter">
          <button (click)="getData()" class="action-button addnew-button export-button" mz-button [disabled]="!filterFormGroup.valid">Rechercher</button>
        </div>
      </div>
    </div>      
  </div>

  <div class="example-loading-shade" *ngIf="isLoadingResults">
      <mat-spinner></mat-spinner>
  </div>
  <div class="row datatable-position" *ngIf="dataResults.length>0">
    <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [colors]="barChartColors"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
  </div>

  <div class="row" *ngIf="dataResults.length>0"> 
    <div *ngFor="let period of dataResults">
      <span> {{ period.month  | amLocale: 'fr' | amDateFormat:'MMMM'}} : </span>
      <span> Iban : {{ period.iban | number:'':'fr-FR'}} €</span> - 
      <span> Paypal : {{ period.paypal | number:'':'fr-FR'}}  €</span>

    </div>
  </div>
</div>