import { DownloadLingueoService } from './../../../../../directive/download-lingueo/download-lingueo.service';
import { HttpParams } from '@angular/common/http';
/**
 * Sous composant pour l'affichage des infos pros.
 */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Language } from '../../../../../model/language.model';
import { LanguageService } from '../../../../../service/language.service';
import { TeacherService } from '../../../../../service/teacher.service';
import { environment } from '../../../../../../environments/environment';


@Component({
  selector: 'app-pro-info',
  templateUrl: './pro-info.component.html',
  styleUrls: ['./pro-info.component.scss']
})
export class ProInfoComponent implements OnInit, OnDestroy {
  @Input() myTeacher;
  languages: Language[];
  showProInfo = false;
  spokenTags = [];
  version = true;
  showInfo = false;
  languageControl: FormControl = new FormControl();
  filteredLanguages: Observable<Language[]>;
  spokenLanguageControl: FormControl = new FormControl();
  filteredSpokenLanguages: Observable<Language[]>;
  translatePath = 'teacher.account';
  charteFrLink = environment.static + 'documents/Charte_des_professeurs_2019_Avril_2019.pdf';
  charteEnLink = environment.static + 'documents/Teacher_Charter_2019_Avril_2019.pdf';

  constructor(
    private teacherService: TeacherService,
    private languageService: LanguageService,
    private downloadService: DownloadLingueoService) { }

  ngOnInit() {
    this.languages = [];
    this.getAllLanguages(1);
    this.filteredLanguages = this.languageControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterLanguages(val) : this.languages);

    this.filteredSpokenLanguages = this.spokenLanguageControl.valueChanges
      .startWith('')
      .map(val => this.languages.filter(function (el) {
        return el.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
      }.bind(this)));
  }

  ngOnDestroy() { }

  filterLanguages(val: string): Language[] {
    return this.languages.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  displayLanguageFn(language) {
    return language ? language.label : language;
  }

  getAllLanguages(_countPagesLanguages) {
    const _filterParams = [
      {
        key: 'page',
        value: _countPagesLanguages.toString()
      }
    ]
    this.languageService.getLanguages(_filterParams)
      .then(_languageData => {
        this.languages = _languageData['results']
        this.showProInfo = true;
      });
  }

  selectLanguage(language) {
    this.myTeacher.teach_language = language;
    this.updateTeacher(this.myTeacher, 'teach_language')
      .then(
        teacher => {
          if (this.myTeacher.languages.map(val => val['id']).indexOf(language.id) < 0) {
            this.myTeacher.languages.push(language);
            this.updateTeacher(this.myTeacher, 'languages');
          }
        }
      )
  }

  selectSpokenLanguage(spoken) {
    if (!this.selectExist(spoken.label, this.spokenTags)) {
      this.spokenTags.push(spoken.label);
      this.myTeacher.languages.push(spoken);
      this.updateTeacher(this.myTeacher, 'languages');
    }
    this.spokenLanguageControl.setValue('');
  }

  selectExist(select, arr) {
    return arr.some(function (el) {
      return el === select;
    });
  }

  updateFromChips(_data, myObject) {
    switch (myObject) {
      case 'languages':
        this.myTeacher.languages = _data;
        this.updateTeacher(this.myTeacher, 'languages');
        break;
      case 'sites':
        this.myTeacher.teach_on = [];
        _data.forEach(_site => {
          this.myTeacher.teach_on.push(_site.url);
        });
        this.updateTeacher(this.myTeacher, 'teach_on');
        break;
      default:
    }
  }

  updateTeacher(teacher, field) {
    const param = {};
    param[field] = teacher[field];
    return this.teacherService.patchTeacher(teacher.url, param)
  }

  removeSpoken(spoken: any): void {
    const index = this.myTeacher.languages.indexOf(spoken);
    if (index >= 0) {
      this.myTeacher.languages.splice(index, 1);
    }
    this.updateTeacher(this.myTeacher, 'languages');
  }

  downloadDocumentationTemmplate(templateName) {
    let params = new HttpParams();
    params = params.append('filename', templateName);
    this.downloadService.downloadTemplate(params);
  }

  downloadTeacherContract() {
    const filename = this.myTeacher.service_contract.substring(this.myTeacher.service_contract.lastIndexOf('/') + 1);
    let params = new HttpParams();
    params = params.append('path', this.myTeacher.service_contract);
    params = params.append('filename', filename);

    this.downloadService.downloadDocumentAdministratif(params);
  }

}
