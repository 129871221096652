import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import * as urlParse from 'url-parse';

abstract class HttpCache {
  /**
   * Returns a cached response, if any, or null if not present.
   */
  abstract get(req: HttpRequest<any>): HttpResponse<any> | null;

  /**
   * Adds or updates the response in the cache.
   */
  abstract put(req: HttpRequest<any>, resp: HttpResponse<any>): void;
}

interface CacheEntry {
  url: string;
  response: HttpResponse<any>
  entryTime: number;
}

@Injectable()
export class CacheService implements HttpCache {
  cacheMap = new Map<string, CacheEntry>();
  get(req: HttpRequest<any>): HttpResponse<any> | null {
    const entry = this.cacheMap.get(req.urlWithParams);
    if (!entry) {
      return null;
    }
    return entry.response;
  }
  put(req: HttpRequest<any>, res: HttpResponse<any>): void {
    const entry: CacheEntry = { url: req.urlWithParams, response: res, entryTime: Date.now() };
    this.cacheMap.set(req.urlWithParams, entry);
  }
  refresh(url) {
    this.cacheMap.forEach(entry => {
      const endpoint = urlParse(url);
      const path = endpoint.pathname;
      if (entry.url.includes(path.split('/')[3])) {
        this.cacheMap.delete(entry.url);
      }
      if (path.includes('/occurrences/') && (entry.url.includes('/upcomings/') || entry.url.includes('/pendings/'))) {
        this.cacheMap.delete(entry.url);
      }

      if (path.includes('/plan/') && (entry.url.includes('/events/') || entry.url.includes('/formations/') || entry.url.includes('/occurrences/'))) {
        this.cacheMap.delete(entry.url);
      }
    })
  }
}
