<div>
  <mat-label>Rechercher un professeur</mat-label>
  <div class="row">

        <input matInput type="text" name ="teacher" class="dropdown" [matAutocomplete]="autoTemplate" [formControl]="teacher_FormControl" />
        <mat-autocomplete #autoTemplate="matAutocomplete" id="teacherOptions" [class]="'test'" [displayWith]="displayTeacherNameFn" [style.width]="'auto'">
         <mat-option *ngFor="let teacher of avalaible_teachers" [value]="teacher">
          {{teacher.user.first_name}} {{teacher.user.last_name}}
         </mat-option>
        </mat-autocomplete>
  </div>
  <div class="row buttons">
    <div class="col l6 center">
      <button class="action-button" mz-button (click)="saveOption('exclude_teacher')">Exclure</button>
    </div>
    <div class="col l6 center">
      <button class="action-button" mz-button (click)="saveOption('force_teacher')">En priorité</button>
    </div>
  </div>
  <div class="row teacher-liste">
    <div class="col l6 left">
      <div><mat-label>Liste exclus</mat-label></div>
      <table class="table-listing-teacher">
        <tr *ngFor="let option of exclude_teachers">
          <td><a matTooltip="Id : {{option.decoded_value.id}}" routerLink="/teachers/admin/{{option.decoded_value.id}}" target="_blank">{{option.decoded_value.user.first_name}} {{option.decoded_value.user.last_name}}</a></td> 
          <td>
            <button mat-button (click)="deleteExcludeTeacher(option.id)">
              <i class="material-icons">delete</i>
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div class="col l6 left">
      <div><mat-label>Liste priorisés</mat-label></div>
      <table class="table-listing-teacher">
        <tr *ngFor="let option of forced_teachers">
          <td><a matTooltip="Id : {{option.decoded_value.id}}" routerLink="/teachers/admin/{{option.decoded_value.id}}" target="_blank">{{option.decoded_value.user.first_name}} {{option.decoded_value.user.last_name}}</a></td>
          <td>
            <button mat-button (click)="deleteForcedTeacher(option.id)">
              <i class="material-icons">delete</i>
            </button></td>
        </tr>
      </table>
    </div>
  </div>

  


</div>
