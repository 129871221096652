<div class="row information-container" *ngIf="createFormationPageIsReady; Else loading">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Informations générales</h6>
    </div>
    <div class="right" *ngIf="!newFormation.url">
      <button type="button" name="button" (click)='fakeNewFormation()'>REMPLIR</button>
    </div>
  </div>
  <div class="information-subcontainer">

    <div class="row valign-wrapper">
      <div class="col l4 left field-name">
        <b class="left">Email Élève :</b>
      </div>
      <div class="col l8 left">
        <input id="email" type="email" [(ngModel)]="studentEmail" (ngModelChange)="checkIfFormationChange()" (focusout)="getUserFromEmail(studentEmail)"
          class="filter-col textfield left validate">
      </div>
    </div>
    <div class="studentinfo-wrapper" *ngIf="myUser">
      <div class="studentinfo-subwrapper">
        <div class="avatar-block">
          <img *ngIf="myUser.avatar !== null; else elseAvatar" class="user-avatar" src="{{myUser.avatar}}" (error)="this.formationService.errorImgHandler($event)">
          <ng-template #elseAvatar>
            <img class="user-avatar" src="{{defaultAvatar}}">
          </ng-template>
        </div>
        <span class="student-name left">{{myUser.first_name}} {{myUser.last_name}}</span>
      </div>
    </div>
    <div *ngIf="studentNotFound && studentEmail.length > 0" class="row center">
      <div class="col l12">
        <b>L'adresse entrée ne correspond à aucun élève</b>
      </div>
    </div>
  </div>
  <div class="row valign-wrapper">
    <div class="col l5 left field-name">
      <b class="left">Site :</b>
    </div>
    <div class="col l7 left" *ngIf="sites">
      <mat-select [formControl]="sitesControl" [disabled]="!myUser">
        <mat-option *ngFor="let site of sites" [value]="site">
          <div *ngIf="site && site !== null">
            {{ site.name }}
          </div>
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row valign-wrapper">
    <div class="col l5 left field-name">
      <b class="left">Nom formation :</b>
    </div>
    <div class="col l7 left" *ngIf="filteredTemplate">
      <mat-form-field>
        <input matInput type="text" class="dropdown" [matAutocomplete]="autoTemplate" [formControl]="templateControl" />
        <mat-autocomplete #autoTemplate="matAutocomplete" [class]="'test'" [style.width]="'auto'">
          <mat-option *ngFor="let template of filteredTemplate | async" [value]="template.label">
            <div>
              {{ template.label }}
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row valign-wrapper">
      <div class="col l4 left field-name">
        <b class="left">SF Opportunity ID :</b>
      </div>
      <div class="col l8 left">
        <input id="sf_id" type="text" [(ngModel)]="newFormation.sf_id" (ngModelChange)="checkIfFormationChange()" 
          class="filter-col textfield left validate">
      </div>
  </div>

  <div class="row valign-wrapper">
    <div class="col l5 left field-name">
      <b class="left">Langue étudiée :</b>
    </div>
    <div class="col l7 left" *ngIf="filteredLanguages">
      <form>
        <input type="text" class="dropdown" aria-label="Number" mdInput [formControl]="languageControl" [matAutocomplete]="auto2">
        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayLanguageFn">
          <mat-option *ngFor="let language of filteredLanguages | async" [value]="language">
            <div *ngIf="language && language !== null">
              {{ language.label }}
            </div>
          </mat-option>
        </mat-autocomplete>
      </form>
    </div>
  </div>
  <div class="row valign-wrapper">
    <div class="col l5 left field-name">
      <b class="left">Niveau auto-déclaré :</b>
    </div>
    <div class="col l7 left" *ngIf="levels">
      <mat-select [(ngModel)]="newFormation.niveau" (ngModelChange)="checkIfFormationChange()">
        <mat-option *ngFor="let level of levels" [value]="level.value">
          <div *ngIf="level && level !== null">
            {{ level.label }}
          </div>
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row valign-wrapper">
    <div class="col l5 left field-name">
      <b class="left">Mode de financement :</b>
    </div>
    <div class="col l7 left" *ngIf="listModeFinancement">
      <mat-select [(ngModel)]="newFormation.mode_financement" (ngModelChange)="checkIfFormationChange()">
         <mat-option *ngFor="let mode of listModeFinancement" [value]="mode.code">
            {{ mode.label }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row valign-wrapper">
    <div class="col l5 left field-name">
      <b class="left">Crédits horaires :</b>
    </div>
    <div class="col l2">
      <input type="number" class="validate filter-input" [(ngModel)]="newFormation.hours_total" (focusout)="focusOutHoursTotal()"
        class="filter-col textfield left validate" step="0.5">
    </div>
    <div class="col l6"></div>
  </div>
  <div class="row">
    <div class="col l12">
      <b>Periodes de validité :</b>
    </div>
    <div class="col l5">
      <input mdInput [matDatepicker]="pickerStart" (click)="pickerStart.open()" class="filter-col textfield left validate" [(ngModel)]="newFormation.validity_period_from"
        (ngModelChange)="checkIfFormationChange()">
      <mat-datepicker #pickerStart></mat-datepicker>
    </div>
    <div class="col l2 arrow-position">
      <i mz-icon-mdi [icon]="'arrow-right'" class="pink-text"></i>
    </div>
    <div class="col l5">
      <input mdInput [matDatepicker]="pickerEnd" (click)="pickerEnd.open()" [matDatepickerFilter]="toDateFilter" [(ngModel)]="newFormation.validity_period_to"
        (ngModelChange)="checkIfFormationChange()" class="filter-col textfield left validate">
      <mat-datepicker #pickerEnd></mat-datepicker>
    </div>
  </div>
  <div class="row valign-wrapper">
    <div class="col l4 left field-name">
      <b class="left">Responsable :</b>
    </div>
    <div class="col l8 left" *ngIf="filteredManager">
      <form>
        <input type="text" class="dropdown" aria-label="Number" mdInput [formControl]="managerControl" [matAutocomplete]="autoManager">
        <mat-autocomplete #autoManager="matAutocomplete" [displayWith]="displayManagerFn">
          <mat-option *ngFor="let manager of filteredManager | async" [value]="manager">
            <div *ngIf="manager.user.first_name; else noFirstName">
              {{ manager.user.first_name }} {{ manager.user.last_name}}
            </div>
            <ng-template #noFirstName>
              {{ manager.user.email }}
            </ng-template>
          </mat-option>
        </mat-autocomplete>
      </form>
    </div>
  </div>

  <div *ngIf="newFormation.url; else elseCreateFormation">
    <div class="center" *ngIf="!component.showRecurrence; else elseInactive">
      <button class="creatraining-btn" mz-button (click)="createFormation(false)">
        Mettre à jour la formation
      </button>
    </div>
    <ng-template #elseInactive>
      <div class="fake-creatraining-btn">
        <span class="grey-text">Mettre à jour la formation</span>
      </div>
    </ng-template>

  </div>
  <ng-template #elseCreateFormation>
    <div class="center" *ngIf="newFormationIsReady(); else elseInactive">
      <button class="creatraining-btn" mz-button (click)="createFormation(true)">
        Créer la formation sans planning ou professeur
      </button>
    </div>
    <ng-template #elseInactive>
      <div class="fake-creatraining-btn">
        <span class="grey-text">Créer la formation sans planning ou professeur</span>
      </div>
    </ng-template>
  </ng-template>
</div>
<ng-template #loading> Chargement...</ng-template>