<div class="row" *ngIf="myTeacher">
  <div class="row teacher-header">
    <div class="left teacher-idinfo">
      <div class="row valign-wrapper">
        <div class="left header-avatar-block">
          <img class="user-avatar" src="{{myTeacher.user.photo_profile.thumbnail}}" *ngIf="myTeacher.user.photo_profile !==null; else elseStudentAvatar1" (error)="this.teacherService.errorImgHandler($event)">
          <ng-template #elseStudentAvatar1>
            <img class="user-avatar" src="{{defaultAvatar}}">
          </ng-template>
        </div>
        <div class="left header-data">
          <h6 class="teacher-name">{{myTeacher.user.first_name}} {{myTeacher.user.last_name}}
            <span *ngIf="myTeacher.teach_language">, professeur de {{ myTeacher.teach_language.label}}</span>
          </h6>
          <h6 class="currentID">ID: {{myTeacher.id}}</h6>
        </div>
      </div>
    </div>
    <div class="left">
        <app-open-session [site]="'teacher'" [user]="myTeacher.user" [teacher]="myTeacher" ></app-open-session>
    </div>

    <div class="left">
      <ng-container *ngIf="!teaching; else elseEnseignement">
          <button class="teaching-or-admin-btn-inactive" mz-button (click)="teaching = !teaching" [disabled]="teaching">Enseignement</button>
      </ng-container>
      <ng-template #elseEnseignement>
          <button class="teaching-or-admin-btn" mz-button (click)="teaching = !teaching" [disabled]="teaching">Enseignement</button>
      </ng-template>
    </div>
    <div class="left">
        <ng-container *ngIf="!teaching; else elseAdmin">
            <button class="teaching-or-admin-btn" mz-button (click)="teaching = !teaching" [disabled]="!teaching">Administratif</button>
        </ng-container>
        <ng-template #elseAdmin>
            <button class="teaching-or-admin-btn-inactive" mz-button (click)="teaching = !teaching" [disabled]="!teaching">Administratif</button>
        </ng-template>
    </div>
    <div class="teacher_cache_status">
      <app-teacher-cache-status [teacher]="myTeacher" [refresh]="true"></app-teacher-cache-status>
    </div>
  </div>
  <div class="row">
    <div class="col l12" [hidden]="!teaching">
      <app-teaching-space [parentComponent]=this [myTeacher]="myTeacher" [languages]="languages" [sites]="sites" [sessionSites]="sessionSites"></app-teaching-space>
    </div>
    <div class="col l12 center" [hidden]="teaching">
      <app-office-space [myTeacher]="myTeacher" [parentComponent]=this></app-office-space>
    </div>
  </div>
</div>