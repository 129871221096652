<div id="formation-export-recurring">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
            <h5 class="page-title">Liste des formations</h5>

        </div>

        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button routerLink="list">
                Listing Exports
            </button>
        </div>

        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button (click)="export()"
                [disabled]="selection.selected.length == 0">
                Export formations
                <!-- <i mz-icon [icon]="'launch'" [align]="'left'" [size]="'tiny'" ></i> -->
                <i class="material-icons tiny left">launch</i>
            </button>
        </div>
    </div>

    <div class="row">
        <h6 class="results-counter">
            {{ resultsLength }} formations trouvées
        </h6>
    </div>

    <div class="row"></div>

    <div class="row">
        <div class="col l6 left">
            <div class="row">
                <div>
                    <button class="select-all-btn" (click)="clearFilter()"> Vider les filtres </button>
                    <button class="select-all-btn" (click)="selectAllPage()">
                        <span *ngIf="modeAllPage==true; else unSelectAll">Déséléctionner toutes les pages</span>
                        <ng-template #unSelectAll>Séléctionner toutes les pages</ng-template>
                    </button>
                    ligne(s) selectionée(s) : <ng-container *ngIf="modeAllPage==false; else countAll"> <span>{{
                            selection.selected.length}}</span></ng-container>
                    <ng-template #countAll><span>{{ selection.selected.length }}</span></ng-template>
                </div>
            </div>
        </div>
    </div>


    <div class="row datatable-position">
        <!-- CONTENT PAGE -->
        <div>
            <div class="example-loading-shade" *ngIf="isLoadingResults">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            </div>

            <div [formGroup]="filterFormGroup">

                <table mat-table [dataSource]="formations" class="formation-table" matSort matSortActive="id"
                    matSortDisableClear matSortDirection="desc">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isPageSelected()"
                                [indeterminate]="selection.hasValue() && !isPageSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row.id) : null" [checked]="selection.isSelected(row.id)"
                                [aria-label]="checkboxLabel(row.id)">
                            </mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- Checkbox Description Column -->
                    <ng-container matColumnDef="select-description">
                        <th mat-header-cell *matHeaderCellDef></th>
                    </ng-container>

                    <!--export_status Column -->
                    <ng-container matColumnDef="export_status">
                        <th mat-header-cell *matHeaderCellDef>Statut d'export</th>
                        <td class="export_status-detail" (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">
                            {{row.status_export_certificat_cdc}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- export_status Description Column -->
                    <ng-container matColumnDef="export_status-description">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-select formControlName="status_export_certificat_cdc" class="header-select">
                                <mat-option *ngFor="let status of formationsExportStatus"  [value]="status.code">
                                    {{status.label}}
                                </mat-option>
                            </mat-select>
                        </th>
                    </ng-container>

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>ID Form.</th>
                        <td class="id-detail" (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">{{row.id}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- ID Description Column -->
                    <ng-container matColumnDef="id-description">
                        <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="id" /> </th>
                    </ng-container>

                    <!--status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Statut</th>
                        <td class="status-detail" (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">
                            {{row.status}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- status Description Column -->
                    <ng-container matColumnDef="status-description">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-select formControlName="status" class="header-select">
                                <mat-option *ngFor="let status of formationsStatus"  [value]="status.code">
                                    {{status.label}}
                                </mat-option>
                            </mat-select>
                        </th>
                    </ng-container>

                    <!-- student Column -->
                    <ng-container matColumnDef="student__user__last_name">
                        <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Eleve</th>
                        <td (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">
                            <div *ngIf="row.student && row.student !== null; else emptyStudent">
                                <app-datatable-cell-student [student]="row.student">
                                </app-datatable-cell-student>
                            </div>
                            <ng-template #emptyStudent>
                                <div>---</div>
                            </ng-template>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- student Description Column -->
                    <ng-container matColumnDef="student__user__last_name-description">
                        <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="student_name" />
                        </th>
                    </ng-container>

                    <!-- student_email Column -->
                    <ng-container matColumnDef="student__user__email">
                        <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Email Eleve</th>
                        <td (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">
                            <!-- {{ getStudent(row.student, 'email') }} -->
                            <app-datatable-cell-student-email [student]="row.student">
                            </app-datatable-cell-student-email>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- student_email Description Column -->
                    <ng-container matColumnDef="student__user__email-description">
                        <th mat-header-cell *matHeaderCellDef> <input name="filter-id"
                                formControlName="student__user__email__icontains" />
                        </th>
                    </ng-container>

                    <!-- formation Column -->
                    <ng-container matColumnDef="name">
                        <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Formation</th>
                        <td class="formation-detail" (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">
                            {{ row.name }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- Formation Description Column -->
                    <ng-container matColumnDef="name-description">
                        <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="name__icontains" />
                        </th>
                    </ng-container>

                    <!-- site Column -->
                    <ng-container matColumnDef="site__name">
                        <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
                        <td (click)="selection.toggle(row.id)" mat-cell *matCellDef="let row">
                            <app-datatable-cell-site [site]="row.site">
                            </app-datatable-cell-site>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <!-- site Description Column -->
                    <ng-container matColumnDef="site__name-description">
                        <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="site__name__icontains" />
                        </th>
                    </ng-container>

                    <tr mat-header-row class="first-line-header" *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-header-row class="second-line-header" *matHeaderRowDef="[
                            'select-description',
                            'export_status-description',
                            'id-description',
                            'status-description',
                            'student__user__last_name-description',
                            'student__user__email-description',
                            'name-description',
                            'site__name-description'
                        ]">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr> -->

                </table>
            </div>

            <div>
                <mat-paginator [length]="resultsLength" class="paginator" [pageSize]="10"
                    [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
            </div>
        </div>
        <!-- FIN CONTENT PAGE -->
    </div>
</div>