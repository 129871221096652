<div id="basemodal-first-login" class="basemodal" style="display:block;" *ngIf="help && canFire">
  <div class="modalcontent">
    <div class="modalbody">
   
        <div class="popup-title" [innerHtml]="title"></div>
        <div class="popup-illu" *ngIf="illustration">
          <div class="popup-illu-inner"><img src="{{ illustration }}" *ngIf="illustration" /></div>
        </div>
        <div class="popup-copy" [innerHtml]="content">
        </div>
       
        <button class="closepopup-btn" (click)="close()">Compris !</button>
      </div>
  </div>
  <div class="modalbackground"></div>
</div>
