import { ExerciceSpecificContentService } from './../../../../../../../service/exerciceSpecificContent.service';
import { ExerciceSpecificContent } from './../../../../../../../model/exerciceSpecificContent.model';
/**
 * Sous-composant pour l'édition des informations d'enseignement.
 */

import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';


// Model
import { Language } from '../../../../../../../model/language.model';
import { Site } from '../../../../../../../model/site.model';
import { Teacher } from '../../../../../../../model/teacher.model';

// Service Local
import { EditTeacherService } from '../../edit-teacher.service';

// Services
import * as GLOBALS from '../../../../../../../kiwixi/kiwixi.globals';

@Component({
  selector: 'app-teaching-informations',
  templateUrl: './teaching-informations.component.html',
  styleUrls: [
    './teaching-informations.component.scss',
    './teaching-informations.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [EditTeacherService]
})
export class TeachingInformationsComponent implements OnInit {

  @Input() myTeacher: Teacher;
  @Input() languages: Language[];
  @Input() sites: Site[];
  @Input() sessionSites: Site[];
  @Input() parentComponent
  filteredLanguagesList = [];
  selectedLanguage = '';
  spokenTags = [];
  initSpokentagsSize: number;
  onModelChange: EventEmitter<any[]> = new EventEmitter();
  selectedSite = '';
  filteredSitesList = [];
  showAdmin = false;
  countPagesLanguages = 1;
  countPagesSites = 1;
  teachingSelect: Object;
  vf = true;
  vo = false;

  groupSpecificList: ExerciceSpecificContent[] = [];
  selectedGroupSpecificList: ExerciceSpecificContent[] = [];
  filteredGroupSpecific: Observable<ExerciceSpecificContent[]>;

  languageControl: FormControl = new FormControl();
  filteredLanguages: Observable<Language[]>;

  spokenLanguageControl: FormControl = new FormControl();
  filteredSpokenLanguages: Observable<Language[]>;
  addSpoken = false;

  siteControl: FormControl = new FormControl();
  filteredSites: Observable<Site[]>;
  addSite = false;


  myForm: FormGroup = this.builder.group({
    language: this.languageControl,
    spoken: this.spokenLanguageControl,
    site: this.siteControl,
  });

  // Chips
  selectable = true;
  removable = true;

  constructor(
    private editTeacherService: EditTeacherService,
    private exerciceSpecificContentService: ExerciceSpecificContentService,
    private builder: FormBuilder) { }

  ngOnInit() {

    // Initialisation du champs 'Enseigne'.
    if (this.myTeacher.teach_language && this.myTeacher.teach_language !== null) {
      this.teachingSelect = this.myTeacher.teach_language;
      this.languageControl.setValue(this.teachingSelect);
    }

    this.selectedGroupSpecificList = this.myTeacher.specific_content;
    this.exerciceSpecificContentService.getExercicesSpecificContent(null).subscribe(data => {
      if (data['results']) {
        this.groupSpecificList = data['results'];
      }
    });

    // On affiche la page
    this.showAdmin = true;

    // initialisation variable pour afficher la liste filtree des languages.
    this.filteredLanguages = this.languageControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterLanguages(val) : this.languages);

    // initialisation variable pour afficher la liste filtree des langues parlées.
    this.filteredSpokenLanguages = this.spokenLanguageControl.valueChanges
      .startWith('')
      .map(val => this.languages.filter(function (el) {
        return el.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
      }.bind(this)));

    // initialisation variable pour afficher la liste filtree des sites.
    this.filteredSites = this.siteControl.valueChanges
      .startWith('')
      .map(val => this.sites.filter(function (el) {
        return el.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      }.bind(this)));
  }

  /**
   * methode pour filter les languages
   * @param val
   */
  filterLanguages(val: string): Language[] {
    return this.languages.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  /**
   * méthode pour afficher le label du language.
   * @param language
   */
  displayLanguageFn(language) {
    return language ? language.label : language;
  }

  /**
   * méthode pour récupérer tous les languages.
   * @param _countPagesLanguages
   */
  getAllLanguages(_countPagesLanguages) {
    const _filterParams = [
      {
        key: 'page',
        value: _countPagesLanguages.toString()
      }
    ]
    this.editTeacherService.getLanguages(_filterParams)
      .then(_languageData => {
        for (const language of _languageData['results']) {
          this.languages.push(language)
        }
        this.showAdmin = true;
      });
  }

  /**
   * méthode pour mettre à jour le language du prof au select.
   * @param language.
   */
  selectLanguage(language) {
    this.myTeacher.teach_language = language;
    this.updateTeacher(this.myTeacher, 'teach_language')
      .then(
        teacher => {
          if (this.myTeacher.languages.map(val => val['id']).indexOf(language.id) < 0) {
            this.myTeacher.languages.push(language);
            this.updateTeacher(this.myTeacher, 'languages');
          }
        }
      )
  }

  /**
   * méthode pour mettre à jour la langue parlée au select.
   * @param spoken
   */
  selectSpokenLanguage(spoken) {
    if (!this.selectExist(spoken.label, this.spokenTags)) {
      this.spokenTags.push(spoken.label);
      this.myTeacher.languages.push(spoken);
      this.updateTeacher(this.myTeacher, 'languages');
    }
    this.spokenLanguageControl.setValue('');
    this.addSpoken = false;
  }

  /**
   * supprimer une langue parlée.
   * @param spoken
   */
  removeSpoken(spoken: any): void {
    const index = this.myTeacher.languages.indexOf(spoken);
    if (index >= 0) {
      this.myTeacher.languages.splice(index, 1);
    }
    this.updateTeacher(this.myTeacher, 'languages');
  }

  /**
   * mettre à jour le domaine.
   * @param _data
   * @param myObject
   */
  updateFromChips(_data, myObject) {
    switch (myObject) {
      case 'languages':
        this.myTeacher.languages = _data;
        this.updateTeacher(this.myTeacher, 'languages');
        break;
      case 'specific_content':
        this.myTeacher.specific_content = _data;
        this.updateTeacher(this.myTeacher, 'specific_content');
        break;
      case 'sites':
        this.myTeacher.teach_on = [];
        _data.forEach(_site => {
          this.myTeacher.teach_on.push(_site.url);
        });
        this.updateTeacher(this.myTeacher, 'teach_on');
        break;
      default:
    }

  }

  /**
   * function pour vérifier si la seection existe déjà.
   * @param select
   * @param arr
   */
  selectExist(select, arr) {
    return arr.some(function (el) {
      return el === select;
    });
  }

  /**
   * function pour actier le toggle.
   * @param status
   */
  isActive(status) {
    return GLOBALS.KiwixiGlobals.prototype.toggleButton(status);
  }

  /**
   * méthode pour mettre à jour le teacher.
   * @param teacher
   * @param field
   */
  updateTeacher(teacher, field) {
    const param = {};
    param[field] = teacher[field];
    return this.editTeacherService.updateTeacher(teacher.url, param).then(
      ret => {
        // return this.parentComponent.refreshLogs();
      }
    )
  }
  /**
   * méthode pour mettre à jour le user.
   * @param user
   * @param field
   */
  updateUser(user, field) {
    const param = {};
    param[field] = user[field];
    this.editTeacherService.updateUser(user.url, param)
    // this.parentComponent.refreshLogs();
  }

}
