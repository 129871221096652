import { ExerciceCategoryList } from './../model/exerciceCategoryList.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';


@Injectable()
export class ExerciceCategoryService extends KiwixiService {

    apiExerciceCategory = environment.server + 'exercices_category/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getCategories(params: HttpParams): Observable<ExerciceCategoryList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceCategoryList>(this.apiExerciceCategory, {
            headers: header,
            params: params
        })
            .map(categories => categories)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceCategoryList>(`getCategories`))
            );
    }


}
