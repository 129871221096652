import { Language } from './language.model';

export class NewUser {
  avatar: string;
  url: string;
  first_name: string;
  last_name: string;
  username: string;
  gender: string;
  email: string;
  birthdate: Date;
  video_provider: string;
  id_skype: string;
  id_student: string;
  main_language: Language;
  timezone: string;
  password: string;
  auto_pwd: boolean;
  sites: Object[];
  active: boolean;
  sf_id: string;
  constructor() { }
}
