<div id="create-plan"  *ngIf="showScreen" class="setup-training-wrap create-container container recurrencies-pick-block">
  <div class="decorative-border">
    <div class="inner-container">
      <div class="center warning-photo-profile" *ngIf="showWarningPhotoProfile" [innerHtml]="'lingueopro.update-profile.Merci ajouter une photo à votre profil' | translate" ></div>
      <ng-container *ngIf="!student.user.photo_profile_validation_requested">
        <div class="center">
          <div class="row col l12 center" *ngIf="student.user.photo_profile">
              <img [src]="student.user.photo_profile.photo_identity" class="photo_identity" />
          </div>
            <div class="row col l12 center">
                <input #studentPhoto type="file" (change)="uploadFile($event, 'photo_identity')" [hidden]="true"/>
                <button class="waves-effect waves-light nextstep-btn"  (click)="studentPhoto.click()" >
                  <span translate *ngIf="student.user.photo_profile">lingueopro.update-profile.Remplacer votre photo</span>
                  <span translate *ngIf="!student.user.photo_profile">lingueopro.update-profile.Ajouter votre photo</span>
                </button>
            </div>
            
            <div class="row col l12 center">
                <span translate>lingueopro.update-profile.Si vous n'avez pas de photo à disposition,</span> <span class="button-webcam" (click)="showWebcamModule=!showWebcamModule" translate>lingueopro.update-profile.il suffit d'allumer votre webcam !</span>
            </div>
            <div class="row col l12 center">
                <app-webcam *ngIf="showWebcamModule" (photoSelectedOutput)="sendFile($event)"></app-webcam>
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="student.user.photo_profile_validation_requested">
        <div class="center">
          <span translate>lingueopro.update-profile.La photo ci-dessous sera validée par un professeur lors du prochain cours.</span>
          <br /><br />
          <img  *ngIf="student.user.photo_profile" [src]="student.user.photo_profile.photo_identity" />
        </div>
      </ng-container> 

    </div>
  </div>
</div>