<div class="login-screen" id="myCheck">
  <div class="login-wrapper">
    <form [formGroup]="passwordForm" (ngSubmit)="sendNewPassword()">
          <div class="row">
            <div class="login-logo"></div>
          </div>
          <div class="lostpassword-illu"></div>
          <input matInput
                placeholder="{{ 'resetpassword.Saisissez votre nouveau mot de passe' | translate }}"
                type="password"
                [formControl]="password1" />
            <div class="form-control-feedback"
                *ngIf="password1.errors && (password1.dirty || password1.touched)">
              <p *ngIf="password1.errors.required" translate>{{translatePath}}.Un mot de passe est requis</p>
              <p *ngIf="password1.errors.minlength" translate>{{translatePath}}.minlength</p>
          </div>

            <input matInput
                placeholder="{{ 'resetpassword.Confirmez votre nouveau mot de passe' | translate }}"
                type="password"
                [formControl]="password2" />
            <div class="form-control-feedback"
                *ngIf="password2.errors && (password2.dirty || password2.touched)">
              <p *ngIf="password2.errors.required" translate>{{translatePath}}.Un mot de passe est requis</p>
              <p *ngIf="password2.errors.minlength" translate>{{translatePath}}.minlength</p>
              <p *ngIf="passwordForm.controls.password2.errors?.MatchPassword" translate>{{translatePath}}.samepassword</p>
              
          </div>
                
            <button class="login-btn" mat-button type="submit" [disabled]="!passwordForm.valid || !formChanges" translate>{{translatePath}}.Valider</button>
    </form>
  </div>
</div>