<p *ngIf="loading; else site">
  Chargement ...
</p>
<ng-template #site>
  <!-- Site column type -->
  <!--<a
    href="https://{{my_site.domain}}"
    target="_blank"
    class="truncate">
    {{my_site.name}}
  </a>-->
  <p>
    {{my_site.name}}
  </p>
</ng-template>
