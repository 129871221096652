<ng-container *ngIf="student">
  <div class="training-info-wrapper">
    <div class="avatar-block">
        <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="student.user.photo_profile === null">
        <img class="user-avatar" src="{{student.user.photo_profile.thumbnail}}" *ngIf="student.user.photo_profile !== null">
    </div>
    <div class="user-info">
      <div><b>{{student.user.first_name}} {{student.user.last_name}}</b></div>
      <div>{{training.name}}</div>
    </div>
    <div class="actual-see-btn" routerLink="./detail/{{training.id}}/info">
    </div>
    <div class="see-btn">
      <i class="material-icons">chevron_right</i>
    </div>
  </div>
</ng-container>
