<div class="page-title">
  <b translate>{{translatePath}}.trainings-title</b>
</div>
<ng-container>    
  <div class="trainings-wrapper">
    <div class="trainings-counter">
      <span translate>{{translatePath}}.currently-start-label</span>
      {{countTrainings}}
      <span translate>{{translatePath}}.currently-end-label</span>
    </div>

    <!-- Search bar -->
    <app-search-bar (setFiltering)="setSearch($event)"></app-search-bar>
    
    <div class="scroll-trainings">
      <!-- current trainings -->
      <div class="current-half">
        <div class="trainings-title-row">
          <span translate>{{translatePath}}.currently-title</span>
          <div class="toggle-icon">
            <span [hidden]="!showCurrent" (click)="showCurrent = false;"><b>–</b></span>
            <span [hidden]="showCurrent" (click)="showCurrent = true;"><b>+</b></span>
          </div>
        </div>
        <div class="current-wrapper" [hidden]="!showCurrent">
            <div *ngFor="let current of myTrainings" class="current-block">
              <div>
                <app-training [training]="current"></app-training>
              </div>
            </div>
        </div>
        <div class="row center" *ngIf="ongoingSearchResult?.next"><a (click)="searchFormations('ongoing')" class='more'>More trainings</a></div>
        <ng-container *ngIf="loadOngoingTrainings">  
          <div class="row center left pageload-message">
            <b translate>{{translatePath}}.trainings-loading</b>
          </div>
        </ng-container>
      </div>
      <!-- past trainings -->
      <div class="past-half">
        <div class="trainings-title-row">
          <span translate>{{translatePath}}.past-title</span>
          <div class="toggle-icon">
            <span [hidden]="!showPast" (click)="showPast = false;"><b>–</b></span>
            <span [hidden]="showPast" (click)="showPast = true"><b>+</b></span>
          </div>
        </div>
        <div class="past-wrapper" [hidden]="!showPast">
          <div *ngFor="let past of myPastTrainings" class="past-block">
            <div>
              <app-training [training]="past"></app-training>
            </div>
          </div>
        </div>
        <div class="row center" *ngIf="doneSearchResult?.next"><a (click)="searchFormations('done')" class='more'>More trainings</a></div>
        <ng-container *ngIf="loadPastTrainings">  
          <div class="row center left pageload-message">
            <b translate>{{translatePath}}.trainings-loading</b>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
