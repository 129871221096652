/**
 * Module du menu lateral.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { FeatureModule } from '../../../feature/feature.module';
import { FormationService } from '../../../service/formation.service';
import { EventService } from '../../../service/event.service';
import { OccurrenceService } from '../../../service/occurrence.service';
import { DirectiveModule } from '../../../directive/directive.module';
import { ProfilStudentComponent } from './profil-student/profil-student.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material';
import { TimezoneService } from '../../../service/timezone.service';
import { UserService } from '../../../service/user.service';
import { StudentService } from '../../../service/student.service';
import { StaffService } from '../../../service/staff.service';
import { TeacherService } from '../../../service/teacher.service';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderRoutingModule } from './header-menu/header-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FeatureModule,
    DirectiveModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderRoutingModule,
    TranslateModule.forChild(),
  ],
  declarations: [HeaderMenuComponent, ProfilStudentComponent],
  exports: [
    HeaderMenuComponent,
    ProfilStudentComponent,
    TranslateModule
  ],
  providers: [FormationService,
    EventService,
    OccurrenceService,
    TimezoneService,
    UserService,
    StudentService,
    StaffService,
    TeacherService,
  ]
})
export class ComponentModule { }
