/**
 * Composant correspondat à l'ecran principal
 */

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mainscreen',
  templateUrl: './mainscreen.component.html',
  styleUrls: ['./mainscreen.component.scss', './mainscreen.ux.scss']
})
export class MainscreenComponent implements OnInit {
  @Input() showNavBar: boolean;

  constructor() { }

  ngOnInit() { }

}
