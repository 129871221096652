import { Component, OnInit, Input } from '@angular/core';
import { InvoiceService } from '../../../../../service/invoice.service';
import * as moment from 'moment';
import { TeacherService } from '../../../../../service/teacher.service';
import { MzToastService } from 'ng2-materialize';
import { sumBy } from 'lodash';

@Component({
  selector: 'app-current-invoice',
  templateUrl: './current-invoice.component.html',
  styleUrls: ['./current-invoice.component.scss']
})
export class CurrentInvoiceComponent implements OnInit {
  current_invoice;
  @Input() myTeacher;
  showCurrent: boolean;
  showDetail: boolean;
  loadUpload: boolean;

  constructor(private invoiceService: InvoiceService, private teacherService: TeacherService, private toastService: MzToastService) {
    this.showCurrent = false;
    this.showDetail = false;
    this.loadUpload = false;
  }

  ngOnInit() {
    this.invoiceService.getCurrentInvoice()
      .subscribe(_current => {
        if (_current) {
          this.current_invoice = _current;
          this.current_invoice.occurrences = _current['occurrences'].sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
          this.showCurrent = true;
          this.showDetail = true;
        }
      })
  }


}
