import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suspended',
  templateUrl: './suspended.component.html',
  styleUrls: ['./suspended.component.scss']
})
export class SuspendedComponent implements OnInit {

  translatePath = 'lingueopro.suspended';
  constructor() { }

  ngOnInit() {
  }

}
