<div id="sheet-information" class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Données Fiche</h6>
    </div>
  </div>
  <div class="row information-subcontainer" [formGroup]="sheetFormGroup">
    <div class="col l12 flexparent centeralign">
      <span class="flexchild nogrow">Titre :</span>
      <input class="sheet_title_input flexchild" name="title_fr" type="text"  formControlName="title_fr" (focusout)="updateSheet('title_fr')" />
      <mat-error *ngIf="sheetFormGroup.controls.title_fr.touched && sheetFormGroup.controls.title_fr.hasError('required')">Ce champs est requis</mat-error>
    </div>

    <div class="col l12 flexparent centeralign">
      <span class="flexchild nogrow">Langue étudiée :</span>
      <div class="sheet_language_input flexchild">
        <mat-select formControlName="language" class="header-select" (focusout)="updateSheet('language')">
          <mat-option *ngFor="let language of languageList"  [value]="language.url">
            {{language.label}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="sheetFormGroup.controls.language.touched && sheetFormGroup.controls.language.hasError('required')">Ce champs est requis</mat-error>
      </div>
    </div>
    

    <div class="col l12 flexparent centeralign">
      <span class="flexchild nogrow">Niveau CECRL :</span>
      <mat-radio-group class="flexchild"  formControlName="cecrl_level" (focusout)="updateSheet('cecrl_level')">
            <mat-radio-button *ngFor="let level of cecrlLevelList" [value]="level">
              <span  class ="radio-button-label">{{ level }}</span>
            </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="sheetFormGroup.controls.cecrl_level.touched && sheetFormGroup.controls.cecrl_level.hasError('required')">Ce champs est requis</mat-error>
    </div>

    <div class="col l12 flexparent centeralign">
      <span class="flexchild nogrow">Catégorie :</span>
      <mat-radio-group class="flexchild" formControlName="category" (focusout)="updateSheet('category')">
            <mat-radio-button *ngFor="let category of categoryList" [value]="category.url">
              <span  class ="radio-button-label">{{ category.name }}</span>
            </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="sheetFormGroup.controls.category.touched && sheetFormGroup.controls.category.hasError('required')">Ce champs est requis</mat-error>
    </div>

    <div class="col l12 flexparent centeralign"  *ngIf="exerciceSheet">
      <span class="flexchild nogrow">Groupes de contenus exclusifs :</span>
      <app-chips class="flexchild flexparent centeralign"
        [parent]=this
        [chipsList]="selectedGroupSpecificList"
        [choices]="filteredGroupSpecific"
        [data]="groupSpecificList"
        [label]="'name'"
        [myObject]=""
        [removableCondition]="true">
      </app-chips>
    </div>

    <div class="col l12 flexparent centeralign"  *ngIf="exerciceSheet">
      <span class="flexchild nogrow">PDF :</span>
      <div class="flexchild">
        <i class="material-icons file-icon">insert_drive_file</i>
        <div *ngIf="exerciceSheet.pdf" class="col l3 btn-box">
            <button class="action-button" mz-button [flat]=true (click)="pdf.click()">
              <span>Ouvrir</span>
            </button>
            <a #pdf href="{{exerciceSheet.pdf}}" [hidden]="true" target="_blank"></a>
          </div>
          <div class="col l3 btn-box">
            <button #upload class="action-button" mz-button [flat]=true (click)="changePdf.click()">
              <span *ngIf="exerciceSheet.pdf">Changer</span>
              <span *ngIf="!exerciceSheet.pdf">Uploader</span>
            </button>
            <input #changePdf type="file" (change)="uploadFile($event)" formControlName="pdf" [hidden]="true" />
          </div>
      </div>
    </div>
      
  </div>

  <div class="center" *ngIf="!exerciceSheet">
      <button class="creatraining-btn action-button" mz-button (click)="createSheet()" [disabled]="!sheetFormGroup.valid">
        Créer une fiche
      </button>
    </div>

  <div class="col l12 creation-row" *ngIf="exerciceSheet">
    Créée le : {{ exerciceSheet.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}
    <div *ngIf="exerciceSheet.published_at">Dernière publication : {{ exerciceSheet.published_at | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
  </div>
</div>

