import { Component, OnInit, ElementRef, ViewChild, Input, Output, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
// import { ScrollEvent } from 'ngx-scroll-event';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators'
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';

// global
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { environment } from '../../../environments/environment';

// service
import { DatatableService } from './datatable.service';
import { SiteService } from '../../service/site.service';
import { FormationService } from '../../service/formation.service';
import { StudentService } from '../../service/student.service';
import { SessionsService } from '../../application/backoffice/mainscreen/formation/sessions/sessions.service';
import { TeachersListService } from '../../application/backoffice/mainscreen/teachers/teachers-list/teachers-list.service';
import { InvoiceService } from '../../service/invoice.service';

const state = {
  user: {},
  isLoading: false,
  items: []
};

const store = new BehaviorSubject<any>(state);

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss', './datatable.ux.scss'],
  providers: [
    DatatableService,
    FormationService,
    SessionsService,
    StudentService,
    TeachersListService,
    InvoiceService,
    SiteService
  ]
})
export class DatatableComponent implements OnInit {

  @Input() data;
  @Input() pageSize;
  @Input() header;
  @Input() counterChange;
  @Input() totalData;
  @Input() component;
  @Input() updateMethod;
  @Input() gotoMethod;
  @Input() cssclass;
  @Input() initFilter;
  @Input() filterKey;
  myMonth = null;
  myYear = null;
  @Output() selectStatus = new EventEmitter();

  router: Router
  title: string;
  startPage: number;
  private errorMessage: any = '';
  start = 0;
  offset = 100;
  sort = 'ascending';
  filterParam = {};
  countPages = 1;
  isAscending = null;
  private isVisible = false;
  dataTable: Object;
  defaultAvatar = environment.static + '/avatar.png';
  showTable = false;
  modelChanged: Subject<any> = new Subject<any>();
  months = [
    { label: 'Janvier', prefix: 'Jan.', month: 1 },
    { label: 'Février', prefix: 'Feb.', month: 2 },
    { label: 'Mars', prefix: 'Mar.', month: 3 },
    { label: 'Avril', prefix: 'Apr.', month: 4 },
    { label: 'Mai', prefix: 'May.', month: 5 },
    { label: 'Juin', prefix: 'Jun.', month: 6 },
    { label: 'Juillet', prefix: 'Jul.', month: 7 },
    { label: 'Août', prefix: 'Aug.', month: 8 },
    { label: 'Septembre', prefix: 'Sep.', month: 9 },
    { label: 'Octobre', prefix: 'Oct.', month: 10 },
    { label: 'Novembre', prefix: 'Nov.', month: 11 },
    { label: 'Décembre', prefix: 'Dec.', month: 12 }
  ];
  years = [];

  throttle = 1000; // temps accordé avant de fire l'event de scoll
  scrollDistance = 1; // % restant à partir duquel on fire l'event 1 = 10 %.

  constructor(private datatableService: DatatableService,
    private route: ActivatedRoute, private kiwixiGlobals: KiwixiGlobals,
    private formationService: FormationService,
    private sessionsService: SessionsService,
    private studentService: StudentService,
    private teachersListService: TeachersListService,
    private invoiceService: InvoiceService,
    private cdRef: ChangeDetectorRef,
    _router: Router,
    private siteService: SiteService) {
    this.router = _router;
    this.modelChanged
      .debounceTime(300) // wait 300ms after the last event before emitting last event
      .pipe(distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        this.filterData(model.filter, model.col)
      });
  }

  toggle() {
    this.isVisible = !this.isVisible;
  }

  debounceInput(_filter, _col) {
    this.modelChanged.next({ filter: _filter, col: _col });
  }

  ngOnInit() {
    this.filterParam['filtering'] = {};
    this.filterParam['filtering'] = this.filterKey && localStorage.getItem(this.filterKey) ? JSON.parse(localStorage.getItem(this.filterKey)).filtering : {};
    const my_year = moment().year()
    const nextYear = Number(my_year) + 1;
    for (let index = 2000; index < nextYear; index++) {
      this.years.push(index)
    }
    this.startPage = 0;
    this.showTable = true;
    this.dataTable = {
      data: [],
      headers: this.header
    };
    if (this.filterParam['filtering']) {
      this.header.forEach(column => {
        if (this.filterParam['filtering'][column.filter_key]) {
          column.filter = this.filterParam['filtering'][column.filter_key];
        };
      })
    }
    if (this.initFilter) {
      this.header.forEach(column => {
        if (column.name === this.initFilter.col) {
          column.list.forEach(_status => {
            if (_status.code === this.initFilter.filter) {
              column.filter = this.initFilter.filter;
              this.filterData(column.filter, column);
            }
          });
        }
      });
    } else {
      this.updateData();
    }
    this.cdRef.detectChanges();
  }

  onScrollDown() {
    if (this.lazyIsAvailable()) {
      this.countPages++;
      this.dataTable = {
        data: this.data,
        headers: this.header
      };
      this.filterParam['page'] = this.countPages.toString();
      // le lazyloading ne concerne pas teacher-invoices
      // if (this.component.source !== 'teacher-invoices') {
      this.updateData();
      // }
    }
  }

  lazyIsAvailable() {
    if ((this.countPages * 10) < this.totalData) {
      return true;
    }
    return false;
  }

  // goto method is coming from parent component
  goto(row) {
    if (this.gotoMethod) {
      this.gotoMethod.apply(this.component, [row]);
    }
  }

  trimValue(value) {
    return value.replace(/\s+$/, '');
  }

  setDemarcation(row) {
    if (this.isAscending !== null) {
      if (row.ascending && this.isAscending) {
        return 'demarcation-top';
      } else if (row.descending && !this.isAscending) {
        return 'demarcation-top';
      }
    }
    return null;
  }

  filterPeriod(_column, _myMonth, _myYear) {
    if (_myMonth) {
      _column.filter = _myMonth;
      this.filterParam['filtering']['periodicity__month'] = _myMonth;
    }
    if (_myYear) {
      _column.filter = _myYear;
      this.filterParam['filtering']['periodicity__year'] = _myYear;
    }
    this.filterParam['filtering']['page'] = 1;
    this.countPages = 1;
    this.filterParam['page'] = this.countPages.toString();
    this.updateData();
  }

  // **** Filter Column **** //
  filterData(filter, col) {
    this.dataTable = {
      data: [],
      headers: this.header,
      count: 0
    }
    if (col.type_filtrage === 'date') { // verifier si on conserve type_filtrage
      if (filter !== '') {
        filter = moment(filter).format('YYYY-MM-DD');
      }
    }
    if (col.filter_type === 'calendar') {
      if (filter !== '') {
        filter = moment(filter).format('YYYY-MM-DD');
      }
    }
    if (col.filter_type === 'interval') {
      filter = null;
      if (col.filter_min && !col.filter_max) {
        if (col.filter_key = 'amount__range') {
          delete this.filterParam['filtering']['amount__range'];
        }
        col.filter_key = 'amount__gte';
        filter = col.filter_min;
      } else if (col.filter_max && !col.filter_min) {
        if (col.filter_key = 'amount__range') {
          delete this.filterParam['filtering']['amount__range'];
        }
        col.filter_key = 'amount__gte';
        filter = col.filter_max;
      } else if (col.filter_min && col.filter_max) {
        if (col.filter_key = 'amount__gte') {
          delete this.filterParam['filtering']['amount__gte'];
        }
        col.filter_key = 'amount__range';
        filter = col.filter_min + ',' + col.filter_max;
      }
    }
    this.filterParam['filtering'][col.filter_key] = filter;
    this.filterParam['filtering']['page'] = 1;
    this.countPages = 1;
    this.filterParam['page'] = this.countPages.toString();
    this.updateData();
  }
  // ***** ORDERING ***** //
  ordering(column) {

    this.dataTable = {
      data: [],
      headers: this.header
    }
    let _sort = '-' + column.ordering_key;
    column.ascending = !column.ascending;
    this.isAscending = null;
    if (column.ascending) {
      _sort = column.ordering_key;
    }
    // specifique pour la periode de validité
    if (column.name === 'valide') {
      if (column.ascending) {
        _sort = 'validity_period_from';
      } else {
        _sort = '-validity_period_to';
      }
    }
    // reset page
    this.countPages = 1;
    this.filterParam['ordering'] = {
      key: 'ordering',
      value: _sort
    }
    this.updateData();
  }

  /* Updating dataTable based on component */
  updateData() {
    if (this.filterKey) {
      localStorage.setItem(this.filterKey, JSON.stringify(this.filterParam));
    } 
    this.data = this.updateMethod.apply(this.component, [this.filterParam, this.countPages]);
    // this.handleAsynchrony();
  }

  clearFilterOrder() {
    this.myMonth = null;
    this.myYear = null;
    this.filterParam = {};
    this.filterParam['filtering'] = {};
    this.countPages = 1;
    this.data = [];
    for (let j = 0; j < this.header.length; j++) {
      this.header[j]['filter'] = '';
      if (this.header[j]['filter_min']) {
        this.header[j]['filter_min'] = '';
      }
      if (this.header[j]['filter_max']) {
        this.header[j]['filter_max'] = '';
      }
    }
    this.updateData();
  }

  clearFilterInputs(_exceptCol) {
    this.filterParam = {};
    this.filterParam['filtering'] = [];
    this.countPages = 1;
    this.data = [];
    for (let j = 0; j < this.header.length; j++) {
      if (_exceptCol !== this.header[j].name) {
        this.header[j]['filter'] = '';
        if (this.header[j]['filter_min']) {
          this.header[j]['filter_min'] = '';
        }
        if (this.header[j]['filter_max']) {
          this.header[j]['filter_max'] = '';
        }
      }
    }
  }

  compareWithDateNow() {
    const _now = Date.now()
    for (let j = 0; j < this.data.length; j++) {
      if (this.data[j].initdate.getTime() > _now) {
        this.data[j].next = true
        break
      }
    }
  }

  setCellWidth(name) {
    if (name) {
      const colSize = document.getElementById('col-' + name).offsetWidth
      const classname = colSize + 'px';
      return classname
    }
  }

  setCellMaxWidth(name) {
    if (name) {
      const colSize = document.getElementById('col-' + name).offsetWidth - 11
      const classname = colSize + 'px';
      return classname
    }
  }

  // handle Emitter
  handleSelectStatus(value) {
    this.selectStatus.emit(value);
  }

}
