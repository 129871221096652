import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Document } from '../model/document.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { Observable, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class DocumentService extends KiwixiService {

  apiDocument = environment.server + 'documents/';
  apiAttachment = environment.server + 'attachments/';

  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getDocument(url): Promise<Document> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get(url, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Document', error);
        this.toastService.show('Erreur dans la recuperation d\'un document', 500, 'red');
      })
  }

  postDocument(file): Promise<Document> {
    const self = this;
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    self.headers.delete('Content-Type');
    _formData.append('source', file);
    _formData.append('name', file.name);

    const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
    return self._http.post(self.apiDocument, _formData, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show('Erreur dans la création d\'un document', 500, 'red');
        console.error('error post Document service', error);
      })
  }

  deleteDocument(url): Promise<Document> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.delete(url, { headers: headers, observe: 'response' })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Delete Document', error);
        this.toastService.show('Erreur sur la supression du document', 4000, 'red');
      })
  }

  /**
   * inclut conversation et attachments
   */
  getDocumentFromFormation(id): Observable<Document[]> {
    const header = this.createHttpClientAuthorizationHeader();
    const params = new HttpParams()
      .set('formation', id)
      .set('ordering', '-created_at')
    return this._http.get<Document[]>(this.apiDocument, {
      params: params,
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Document[]>(`get documents for formation`))
      );
  }

  /**
   * inclut conversation et attachments
   */
  getTeacherDocuments(_ownerId, _inputTerm, order, page): Observable<Document[]> {
    const header = this.createHttpClientAuthorizationHeader();
    let params = new HttpParams();
    params = params.append('owner', _ownerId);
    if (_inputTerm.length > 0) {
      params = params.append('name__icontains', _inputTerm);
    }
    if (order.length > 0) {
      params = params.append('ordering', order);
    }
    if (page > 0) {
      params = params.append('page', page);
    }
    return this._http.get<Document[]>(this.apiDocument, {
      params: params,
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Document[]>(`get documents for formation`))
      );
  }

  public sendDocuments<Message>(files, from, to) {
    const obx = [];
    for (const file of files) {
      if (file.size < environment.maxFileSize) {
        obx.push(this.sendDocument(file, from, to))
      } else {
        this.toastService.show(environment.fileSizeAlert, 2000, 'red');
      }
    }
    return forkJoin<Document>(obx)
  }

  private sendDocument(file, from, to) {
    const apiDocument = environment.server + 'documents/';
    const header = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    header.delete('Content-Type');
    _formData.append('source', file);
    _formData.append('name', file.name);
    _formData.append('from', from);
    return this._http.post(apiDocument, _formData, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Document[]>(`Envoi de fichier`))
    )
  }

}
