import { TimeSlotDirective } from './schedule/timeslot.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureModule } from '../../../feature/feature.module';
import { DirectiveModule } from '../../../directive/directive.module';
import { MomentModule } from 'ngx-moment';
import { PlanFormationComponent } from './plan-formation.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { EventService } from '../../../service/event.service';
import { PlanService } from '../../../service/plan.service';
import { PlanFormationRoutingModule } from './plan-formation-routing.module';
import { MainscreenGlobals } from '../../../kiwixi/mainscreen.globals';
import { LevelComponent } from './level/level.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ResumeComponent } from './resume/resume.component';
import { ConclusionComponent } from './conclusion/conclusion.component';
import { ItemEventComponent } from './schedule/item-event/item-event.component';
import { TimeSelectorNoDispoComponent } from '../../../feature/time-selector-no-dispo/time-selector-no-dispo.component';
import { InformationComponent } from './information/information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PlanFormationGuard } from '../../../guard/plan-formation.guard';
import { BookingModeComponent } from './booking-mode/booking-mode.component';
import { InitPonctualBookingComponent } from './init-ponctual-booking/init-ponctual-booking.component';
import { InitPonctualBookingConclusionComponent } from './init-ponctual-booking/init-ponctual-booking-conclusion/init-ponctual-booking-conclusion.component';
import { InitPonctualDemoTourComponent } from './init-ponctual-booking/init-ponctual-demo-tour/init-ponctual-demo-tour.component';
import { IdentityComponent } from './identity/identity.component';
import { BirthdateComponent } from './birthdate/birthdate.component';

@NgModule({
  imports: [
    CommonModule,
    DirectiveModule,
    FeatureModule,
    MaterialModule,
    MomentModule,
    PlanFormationRoutingModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),

  ],
  declarations: [
    PlanFormationComponent,
    IntroductionComponent,
    LevelComponent,
    ScheduleComponent,
    ResumeComponent,
    ConclusionComponent,
    ItemEventComponent,
    TimeSlotDirective,
    InformationComponent,
    BookingModeComponent,
    InitPonctualBookingComponent,
    InitPonctualBookingConclusionComponent,
    InitPonctualDemoTourComponent,
    IdentityComponent,
    BirthdateComponent,
  ],
  exports: [
    InitPonctualBookingConclusionComponent, InitPonctualDemoTourComponent,
  ],
  entryComponents: [
    TimeSelectorNoDispoComponent,
  ],
  providers: [
    EventService, PlanService, MainscreenGlobals, PlanFormationGuard
  ]
})
export class PlanFormationModule { }
