/**
 * Etape 2-b : première edition d'un rapport.
 */

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingService } from '../../../../../service/pending.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { RateStudentService } from '../../../../../service/rate-student.service';
import { StudentService } from '../../../../../service/student.service';
import { FormationService } from '../../../../../service/formation.service';
import { ReportService } from '../../report.service';
import { LanguageService } from '../../../../../service/language.service';

@Component({
  selector: 'app-step2first',
  templateUrl: './step2first.component.html',
  styleUrls: ['./step2first.component.scss']
})
export class Step2firstComponent implements OnInit {
  pending: any;
  occurrence_id: any;
  formation: any;
  existingReport: any;
  existingReport_date: any;
  translatePath = 'teacher.reports';
  showLevel = true;
  defaultAvatar = environment.static + '/avatar.png';
  myLanguage: any;

  /* ****** Variables pour le formulaire ****** */
  grammarCtrl = new FormControl('', [Validators.required]);
  expressionCtrl = new FormControl('', [Validators.required]);
  comprehensionCtrl = new FormControl('', [Validators.required]);
  vocabularyCtrl = new FormControl('', [Validators.required]);
  accentCtrl = new FormControl('', [Validators.required]);
  levelForm: FormGroup = this.builder.group({
    grammar: this.grammarCtrl,
    expression: this.expressionCtrl,
    comprehension: this.comprehensionCtrl,
    vocabulary: this.vocabularyCtrl,
    accent: this.accentCtrl
  });
  levelFormChange = false;
  subjects: any[] = [];
  selfEvaluation = [
    { niveau: '1', keyvalue: 'absolute-beginner' },
    { niveau: '2', keyvalue: 'beginner' },
    { niveau: '3', keyvalue: 'intermediate' },
    { niveau: '4', keyvalue: 'advanced' },
    { niveau: '5', keyvalue: 'expert' },
    { niveau: '6', keyvalue: 'do-not-know' },
  ]

  selfEvaluated = ''

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
    private pendingService: PendingService,
    private ratingStudentService: RateStudentService,
    private formationService: FormationService,
    private languageService: LanguageService,
    private builder: FormBuilder
  ) { }

  ngOnInit() {
    this.showLevel = false;
    this.occurrence_id = this.route.snapshot.params.id;
    this.pendingService.getPending(this.occurrence_id)
      .subscribe(_pending => {
        this.pending = _pending;
        this.formationService.getFormationWithUrl(this.pending.formation)
          .then(
            _formation => {
              this.formation = _formation;
              this.languageService.getObservableLanguageWithUrl(this.formation.language)
                .subscribe(_language => {
                  this.myLanguage = _language
                })
              if (this.selfEvaluation.filter(e => e.niveau === this.formation.niveau)[0]) {
                this.selfEvaluated = this.selfEvaluation.filter(e => e.niveau === this.formation.niveau)[0].keyvalue;
              }
              this.subjects = this.reportService.getSubjects(this.pending.session.rate_teacher);
              if (!this.reportService.reportIsEmpty(this.pending.session.rate_teacher)) {
                this.levelFormChange = true;
                this.reportService.initFormBuider(this.levelForm, this.pending.session.rate_teacher)
              }
              this.setLastReport();
              this.subcribeToFormChanges();
              this.showLevel = true;
            }
          );
      });
  }

  /**
   * Methode pour initialiser la derniere evaluation existante pour cet etudiant dans cette langue.
   */
  setLastReport() {
    this.studentService.getStudentLevel(this.formation.student.url, this.formationService.extractIdFromUrl(this.formation.language))
      .subscribe(_level => {
        if (_level && _level['status'] === 'completed') {
          this.existingReport_date = _level['updated_at'];
          this.existingReport = this.reportService.getLastReportData(_level);
        }
      })
  }

  /**
   * Methode pour definir initialiser le formulaire de level
   */
  initLevelForm() {
    this.levelForm.reset({
      grammar: '',
      expression: '',
      comprehension: '',
      vocabulary: '',
      accent: ''
    });
    this.levelFormChange = false;
    const myMessage = this.pending.session.rate_teacher.message;
    this.ratingStudentService.deleteRatingStudent(this.pending.session.rate_teacher.url)
      .then(_res => {
        const param = {
          message: myMessage,
          session: this.pending.session['url']
        };
        this.ratingStudentService.postRatingStudent(param)
          .subscribe(_ratingStudent => {
            this.ngOnInit();
          })
      })

  }

  /**
   * Methode pour souscrire aux changements dans le formulaire.
   */
  subcribeToFormChanges() {
    const myFormValueChanges$ = this.levelForm.valueChanges;
    myFormValueChanges$.subscribe(data => {
      this.levelFormChange = true;
    })
  }

  /**
   * Methode pour enregistrer le report
   */
  register(_status) {
    this.subjects.forEach(_item => {
      if (_item.level.value === 100 && _item.level.levelUp && _item.level.end_label !== 'C2') {
        _item.level.value = 10;
      }
      if (_item.level.value === 0) {
        _item.level.value = 10;
        switch (_item.level.start_label) {
          case 'A2':
            _item.level.start_label = 'A1'
            _item.level.end_label = 'A2'
            break;
          case 'B1':
            _item.level.start_label = 'A2'
            _item.level.end_label = 'B1'
            break;
          case 'B2':
            _item.level.start_label = 'B1'
            _item.level.end_label = 'B2'
            break;
          case 'C1':
            _item.level.start_label = 'B2'
            _item.level.end_label = 'C1'
            break;
          default:
            break;
        }
      }
      const param = {};
      param[_item.name] = {
        start_label: _item.level.start_label,
        value: _item.level.value
      };
      this.levelForm.patchValue(param);
    });
    this.reportService.register(this.levelForm, this.pending, _status)
      .then(res => {
        this.ratingStudentService.toastService.show(
          `Compte rendu Sauvegardé`,
          1000,
          'green'
        )
        this.router.navigate(['/reports']);
      })
  }

  completeLater() {
    if (this.levelFormChange) {
      this.register('draft');
    } else {
      this.router.navigate(['/reports']);
    }
  }

  /**
   * Methode pour mettre à jour la valuer d'un crontrolleur du formulaire.
   * @param _level
   */
  handleSubject(_level) {
    const param = {};
    param[_level.name] = _level.level;
    this.levelForm.patchValue(param)
  }

}
