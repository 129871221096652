/**
 * Composant parent pour l'edition d'une formation.
 * Intégrant les sous-composant : Informations, Recurrences, Sessions, Progression, Booking.
 */

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Formation } from '../../../../model/formation.model';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SessionsComponent } from './sessions/sessions.component';
import { BookingComponent } from './booking/booking.component';

// models
import { Language } from '../../../../model/language.model';
import { Teacher } from '../../../../model/teacher.model';
import { Occurrence } from '../../../../model/occurrence.model';
import { Site } from '../../../../model/site.model';

// services
import { SiteService } from '../../../../service/site.service';
import { EventService } from '../../../../service/event.service';
import { KiwixiGlobals } from '../../../../kiwixi/kiwixi.globals';
import { FormationService } from '../../../../service/formation.service';
import { LanguageService } from '../../../../service/language.service';
import { SessionsService } from './sessions/sessions.service';

@Component({
  selector: 'app-formation',
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.scss', './formation.ux.scss', '../mainscreen.ux.scss'],
  providers: [FormationService, SessionsService, EventService, BookingComponent]
})
export class FormationComponent implements OnInit, OnDestroy {

  @ViewChild(SessionsComponent)
  private sessionsComponent: SessionsComponent;
  formation: Formation;
  source: string;
  teachers: Teacher[]
  occurrences: Occurrence[];
  private errorMessage: any = '';
  loading: boolean;
  teaching = false;
  dashboard = true;
  defaultAvatar = environment.static + '/avatar.png';
  myFormationService = null;
  loadComponent = false;
  sites = [];
  mySite;
  cacheLoader = false;
  studentUrl = null;
  siteEnv;

  constructor(
    private formationService: FormationService,
    private kiwixiGlobals: KiwixiGlobals,
    private route: ActivatedRoute,
    private siteService: SiteService,
    private languageService: LanguageService) {
    kiwixiGlobals.isLoading.subscribe(value => this.loading = value)
  }

  ngOnInit() {
    const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);
    this.siteEnv = environment.urls[idx]
    this.getAllSites();
    this.route.params.subscribe(params => {
      this.getFormation(params['id'])
    });
  }

  ngOnDestroy() {
    if (this.myFormationService) {
      this.myFormationService.unsubscribe();
    }
  }

  // initial_data pour site
  getAllSites() {
    return this.siteService.getSites()
      .then(_siteData => {
        for (const site of _siteData['results']) {
          this.sites.push(site);
          if (site.name = 'lingueopro') {
            this.mySite = site.name;
          }
        }
      })
      .catch(this.siteService.handleError);
  }

  /**
   * Récupérer la formation
   * @param id
   */
  getFormation(id) {
    this.myFormationService = this.formationService.getObservableFormationById(id)
      .subscribe(
        response => {
          if (response) {
            this.getSite(response, this.formation);
            this.formation = response;
            this.studentUrl = this.siteEnv.url + '/students/' + this.formation.student.user.id;
          }
        },
        error => {
          this.errorMessage = <any>error;
        }
      );
  }

  // methode pour afficher l'id d'une formation.
  getId() {
    return this.formation ? this.formation.url.split('/')[6] : '';
  }

  /**
   * Méthode pour récupérer l'objet site de la formation.
   * @param informationData
   * @param formation
   */
  getSite(informationData, formation) {
    this.siteService.getSite(informationData.site).then(
      site => {
        // name or object ?
        if (formation) {
          formation.site = new Site(site.url, site.name, null);
          this.getLanguage(formation);
        }
      },
      // handle the error, otherwise will break the Observable
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  /**
   * Méthode pour récupérer l'objet language de la formation.
   * @param formation
   */
  getLanguage(formation) {
    this.languageService.getObservableLanguageWithUrl(formation.language)
      .subscribe(
        language => {
          if (language) {
            formation.language = new Language(language.url, language.code, language.flag, language.label);
          }
          this.formation = formation;
        },
        error => this.errorMessage = <any>error
      );
  }

  // --- Ecouteur sur les composants enfant :

  // refresh la formation.
  handleUpdateFormation(_formation) {
    if (_formation) {
      this.formation = _formation;
    } else {
      this.route.params.subscribe(params => {
        this.getFormation(params['id'])
      });
    }
  }

  // refresh le component
  handleInitComponent() {
    this.route.params.subscribe(params => {
      this.getFormation(params['id'])
    });
  }

  // refresh la liste des sessions
  handleUpdateOccurenceList() {
    if (this.sessionsComponent) {
      this.sessionsComponent.getDataSession({}, 1);
    }
  }

  cleanCache() {
    this.cacheLoader = true;
    this.formationService.cleanCache(this.formation.id)
      .subscribe(_ => {
        this.cacheLoader = false;
      })
  }

}
