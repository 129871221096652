import { ClickOutsideDirective } from './unavailability/click-outside.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './schedule.component';
import { UnavailabilityComponent } from './unavailability/unavailability.component';
import { ScheduleRoutingModule } from './schedule-routing.module';
import { MatSelectModule, MatDatepickerModule, MatProgressSpinnerModule } from '@angular/material';
import { MaterializeModule } from 'ng2-materialize';
import { FeatureModule } from '../../../feature/feature.module';
import { DailyModule } from './daily/daily.module';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AvailabilityCalendarComponent } from './recurring-availibility/availability-calendar/availability-calendar.component';
import { PonctualAvailibilityComponent } from './ponctual-availibility/ponctual-availibility.component';
import { RecurringAvailibilityComponent } from './recurring-availibility/recurring-availibility.component';
import { PonctualCalendarComponent } from './ponctual-availibility/ponctual-calendar/ponctual-calendar.component';
import { ModalEvent } from '../../../feature/modal-event/modal-event';
import { DirectiveModule } from '../../../directive/directive.module';
@NgModule({
  imports: [
    CommonModule,
    ScheduleRoutingModule,
    MatSelectModule,
    DailyModule,
    MomentModule,
    FeatureModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MaterializeModule.forRoot(),
    TranslateModule.forChild(),
    FullCalendarModule,
    DirectiveModule
  ],
  declarations: [
    ScheduleComponent,
    AvailabilityCalendarComponent,
    UnavailabilityComponent,
    ClickOutsideDirective,
    PonctualAvailibilityComponent,
    PonctualCalendarComponent,
    RecurringAvailibilityComponent,
    ModalEvent
  ],
  entryComponents: [
    ModalEvent
  ]
})
export class ScheduleModule { }