import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Occurrence } from '../model/occurrence.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { OccurrenceList } from '../model/occurrenceList.model';

@Injectable()
export class OccurrenceService extends KiwixiService {

  apiOccurrence = environment.server + 'occurrences/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private sessionService: SessionService,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getOccurence(id): Promise<Occurrence> {
    const urlOccurent = this.apiOccurrence + id + '/';
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(urlOccurent, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Occurence', error);
        // this.logout();
      })
  }

  getOccurrencesResults(_filterParams): Promise<Occurrence[]> {
    let params = new HttpParams();    // ordering:
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params = params.set(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params = params.set(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      params = params.set('page', _filterParams['page']);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiOccurrence, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Occurence', error);
        // this.logout();
      })
  }

  getOccurrences(filterParams): Promise<Occurrence[]> {
    let params = new HttpParams();
    for (const filterParam of filterParams) {
      params = params.set(filterParam.key, filterParam.value);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiOccurrence, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error Occurence', error);
        this.logout();
      })
  }

  patchOccurrence(occurrenceUrl, param): Promise<Occurrence> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(occurrenceUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        this.toastService.show(`La mise à jour de l'occurence a échouée`, 2000, 'red');
        console.error('error patch occurrence service', error);
      })
  }

  patchObservableOccurrence(occurrenceUrl, param) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.patch<Occurrence>(occurrenceUrl, JSON.stringify(param), { headers: header })
      .pipe(
        catchError(this.handleErrorObservable<Occurrence>(`getObservableOccurence`))
      );
  }

  getObservableOccurenceById(_id): Observable<Occurrence> {
    const url = this.apiOccurrence + _id + '/';
    return this.getObservableOccurence(url);
  }

  getObservableOccurence(_url): Observable<Occurrence> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Occurrence>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Occurrence>(`getObservableOccurence`))
      );
  }

  getObservableOccurences(params: HttpParams): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Occurrence[]>(this.apiOccurrence, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getRatingStudents`))
      );
  }

  getObservableOccurencesList(params: HttpParams): Observable<OccurrenceList> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<OccurrenceList>(this.apiOccurrence, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<OccurrenceList>(`getRatingStudents`))
      );
  }

  getOccurencesByUrl(_url): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Occurrence[]>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getOccurencesByUrl`))
      );
  }

}
