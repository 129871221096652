import { InvoiceLogExportService } from './../../../../../../service/invoiceLogExport.service';
import { invoiceLogExport } from './../../../../../../model/invoiceLogExport.model';
import { environment } from './../../../../../../../environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { InvoiceService } from './../../../../../../service/invoice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoiceValidity } from '../../../../../../model/invoiceValidity.model';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-export-create',
  templateUrl: './invoice-export-create.component.html',
  styleUrls: ['./invoice-export-create.component.scss']
})
export class InvoiceExportCreateComponent implements OnInit {

  selectedInvoices: [number];
  invoicesValidity: InvoiceValidity[] = [];
  amount: number = 0;
  nbErrors: number = 0;
  checkIsValidInvoice: boolean = false;
  isPaymentFileDownloaded: boolean = false;
  displayedColumns: string[] = ['invoiceID', 'period', 'teacher', 'amount', 'errors'];
  dataSource: MatTableDataSource<InvoiceValidity> = new MatTableDataSource([]);
  methodPaiementForm = new FormControl('', Validators.required);
  checkPaymentForm = new FormControl('', Validators.required);
  invoiceLogExport: invoiceLogExport = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    private invoiceLogExportService: InvoiceLogExportService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!history.state.seletedInvoices) {
      this.router.navigate(['invoices']);
      return;
    }
    this.selectedInvoices = history.state.seletedInvoices;
    this.methodPaiementForm.valueChanges.subscribe(method => this.getSelectedInvoices());
    if (history.state.paiement_method) {
      this.methodPaiementForm.setValue(history.state.paiement_method)
    }

  }

  getSelectedInvoices() {
    this.amount = 0;
    this.nbErrors = 0;
    this.invoicesValidity = [];
    this.checkIsValidInvoice = false;
    this.isPaymentFileDownloaded = false;
    this.checkPaymentForm.setValue('false');
    const params = {
      selected_invoices: this.selectedInvoices,
      payment_method: this.methodPaiementForm.value,
    };
    this.invoiceService.checkValidityInvoices(params).subscribe(data => {
      if (data.length > 0) {
        data.map(invoice => this.invoicesValidity.push(invoice))
        this.dataSource = new MatTableDataSource(data.filter(obj => obj.is_valid.valid === false));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.initData()
        this.checkIsValidInvoice = true
      }
    });
  }

  initData() {
    this.getAmount();
    this.getNumberErrors();
  }

  getTeacherUrl(element) {
    const env = environment.urls.filter(e => e.module === 'backoffice')
    return env[0].url + "/teachers/admin/" + this.invoiceService.extractIdFromUrl(element.invoice.teacher)
  }

  getAmount() {
    this.amount = this.invoicesValidity.reduce(function (acc, obj) {
      return acc + parseFloat(obj.invoice.amount.toString());
    }, 0)
  }

  getNumberErrors() {
    this.invoicesValidity.map(invoice => this.nbErrors += invoice.is_valid.errors.length);
  }

  createExport() {
    if (this.selectedInvoices) {
      const params = {
        type: this.methodPaiementForm.value,
        selected_invoices: this.selectedInvoices,
      };
      this.invoiceService.postExport(params).subscribe(invoiceLogExport => {
        if (invoiceLogExport) {
          this.invoiceLogExport = invoiceLogExport;
          this.downloadPaymentFile()
        }
      })
    }
  }

  downloadPaymentFile() {
    const _url = environment.server + 'invoices_log_export/' + this.invoiceLogExport.id + '/download_payment_file/';
    if (this.selectedInvoices) {
      let _contentType = null;
      const _type = this.methodPaiementForm.value;
      if (_type === 'iban') {
        _contentType = 'application/xml';
      } else {
        _contentType = 'text/csv';
      }
      const date = moment().format('DD_MM_YYYY')
      const filename = this.invoiceLogExport.type_export + '-paiement-' + this.invoiceLogExport.id + '-' + date;
      this.invoiceService.downloadExport(_url, filename, _contentType).then(res => {
        this.isPaymentFileDownloaded = true;
      })
    }
  }

  setInvoiceLogExportAsPaid(status) {
    this.invoiceLogExportService.patchInvoiceLogExport(this.invoiceLogExport.id, { is_paid: status }).subscribe(invoiceLogExport => {
      if (invoiceLogExport) {
        this.invoiceLogExport = invoiceLogExport;
      }
    })
  }

  createBundle() {
    this.invoiceLogExportService.createBundle(this.invoiceLogExport).subscribe(invoiceLogExport => {
      if (invoiceLogExport.id) {
        this.router.navigate(["/invoices/export/list"]);
      }
    })
  }

}
