<div class="information-container" *ngIf="myTeacher">
  <div class="row form-row">
    <div class="col l5">
      <h6 class="darken-1 white-text title-information">Factures</h6>
    </div>
    <div class="right total-data-position" *ngIf="totalData">
      <h6>Nombre de factures : {{totalData}} </h6>
    </div>
  </div>
  <ng-container *ngIf="showInvoices; else elseTemplate">
    <div id="table-teacher-invoices" class="row">
      <app-datatable class="col l12" [data]="dataInvoices['data']" [header]="dataInvoices['headers']" [pageSize]="pageSize" [totalData]="totalData"
        [component]=this [updateMethod]=this.getData>
      </app-datatable>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="row center">Chargement des factures...</div>
  </ng-template>

</div>