
<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Options avancées
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-radio-group [formControl]="modeControl" aria-label="Select an option"  (change)="emitAllocationMode($event)">
      <mat-radio-button value="balanced">Mode équilibré</mat-radio-button>
      <mat-radio-button value="best">Mode Linéaire (best event first)</mat-radio-button>
      <mat-radio-button value="linear_date_around">Mode Linéaire (optimal date)</mat-radio-button>
    </mat-radio-group>
    <app-teacher [formation]="formation"></app-teacher>

  </mat-expansion-panel>
</mat-accordion>