<div id="create-plan"  *ngIf="showScreen" class="setup-training-wrap container usual-container">
  
  <div class="steps-wrapper">
    <div class="onboarding-step step-birthdate complete-step"><div class="step-icon"></div></div>
    <div *ngIf="student.user.video_provider=='skype'" class="onboarding-step step1 complete-step"><div class="step-icon"></div></div>
    <div class="onboarding-step step2 current-step"><div class="step-icon"></div></div>
    <div class="onboarding-step step3"><div class="step-icon"></div></div>
    <div class="onboarding-step step4"><div class="step-icon"></div></div>
  </div>
  
  <div class="onboarding-subheading" translate>lingueopro.plan-formation.identity.title</div>
  <div class="photo_identity_standards" translate>
    <div translate>lingueopro.plan-formation.Cette photo doit répondre aux critères d'une photo d'identité :</div>
    <div>
      <ul>
        <li translate>lingueopro.plan-formation.Votre tête doit être droite et votre visage centré dirigé face à l’objectif.</li>
        <li translate>lingueopro.plan-formation.Votre visage doit être dégagé et vos yeux visibles et ouverts.</li>
        <li translate>Si vous portez des lunettes, vos verres ne doivent être ni teintés, ni colorés.</li>
      </ul>
    </div>
  </div>
  <div translate>lingueopro.onboarding.identity.Pour commencer nous avons besoin d'une photo de vous !</div>
  <div translate>lingueopro.onboarding.identity.Elle permet à votre formateur de savoir à tout moment qui vous êtes dans la préparation de ses cours :-)
  <span translate>lingueopro.onboarding.identity.Cette photo apparaitra également sur votre certificat LILATE (LILATE).</span>
  </div>
  <div>
      <input #studentPhoto type="file" (change)="uploadFile($event, 'photo_identity')" [hidden]="true"/>
      <button class="waves-effect waves-light nextstep-btn"  (click)="studentPhoto.click()" >
        <span translate>lingueopro.onboarding.identity.Ajouter votre photo</span>
      </button>
  </div>
  <div *ngIf="student.user.photo_profile">
      <img [src]="student.user.photo_profile.photo_identity" class="photo_identity" />
  </div>

  <div class="identity-help">
      <span translate>lingueopro.onboarding.identity.Si vous n'avez pas de photo à disposition</span> <span class="button-webcam" (click)="showWebcamModule=!showWebcamModule" translate>lingueopro.onboarding.identity.il suffit d'allumer votre webcam !</span>
  </div>
  <button class="nextstep-btn webcam-btn"  (click)="showWebcamModule=!showWebcamModule" >
    <span translate>Prendre une photo avec ma caméra</span>
  </button>
  <div>
      <app-webcam *ngIf="showWebcamModule" (photoSelectedOutput)="sendFile($event)"></app-webcam>
  </div>
        
 
  
</div>