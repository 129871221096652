<div class="option-row valign-wrapper">
    <div class="teachers-wrapper">
      <!-- - {{ extra.score }} - {{ extra.volume }} - pour warning lie à la solution -->
      <div class="teacher-element" *ngFor="let teacher of teachers">
        <div class="center" (click)="displayProfilTeacher(teacher)">
          <div class="teacher-img" *ngIf="teacher.user.photo_profile !== null; else defaultAvatarTag">
            <img src="{{teacher.user.photo_profile.thumbnail}}" class="user-avatar" (error)="this.teacherService.errorImgHandler($event)">
          </div>
          <ng-template #defaultAvatarTag>
            <div class="teacher-img default">
              <img src="{{defaultAvatar}}" class="user-avatar">
            </div>
          </ng-template>
        </div>
        <div class="center" (click)="displayProfilTeacher(teacher)">
          <div class="">
            <span>{{teacher.user.first_name}} {{teacher.user.last_name}}</span>
          </div>
        </div>
      </div>
    </div>
    <button class="waves-effect waves-light assign-btn" (click)="selectSolution()" *ngIf="!choosen">
      <b translate>Choisir</b>
    </button>
    <span class=assign-btn *ngIf="choosen">
      <i class="material-icons">check</i>
    </span>
  </div>
  <hr />
  