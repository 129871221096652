<div id="training-detail-screen" *ngIf="!editMode && exerciceSheet || (editMode && exercice && exerciceSheet)">

    <div class="h5 page-title exercice-title" *ngIf="!exercice">
    Exercice (Fiche : <a routerLink="..">{{ exerciceSheet.title_fr }}</a>)
    </div>
    <div class="h5 page-title exercice-title" *ngIf="exercice">
    Exercice : #{{ exercice.id | number:'6.':'fr'}} (Fiche : <a routerLink="../..">{{ exerciceSheet.title_fr }}</a>)
    </div>

  <div class="row">
      <app-sheet-exercices-information class="col l6 information-bloc" [exerciceSheet]="exerciceSheet" [exercice]="exercice"></app-sheet-exercices-information>
      <app-sheet-exercices-related class="col l6 information-bloc"  *ngIf="exercice" [exercice]="exercice"></app-sheet-exercices-related>
  </div>
  <div class="row">  
    <div class="col l12 information-bloc" *ngIf="exercice">
      <app-sheet-exercices-media [exercice]="exercice"></app-sheet-exercices-media>
    </div>
  </div>
  <div class="row">  
    <div class="col l12 information-bloc" *ngIf="exercice">
      <app-sheet-exercices-question [exercice]="exercice"></app-sheet-exercices-question>
    </div>
  </div>
</div>