import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RatingStudent } from '../model/rating-student.model';

@Injectable()
export class RateStudentService extends KiwixiService {
  apiRatingStudents = environment.server + 'rating_students/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getRatingStudents(params: HttpParams): Observable<RatingStudent[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<RatingStudent[]>(this.apiRatingStudents, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<RatingStudent[]>(`getRatingStudentsByParams`))
      );
  }

  getRatingStudentsByUrl(url): Observable<RatingStudent[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<RatingStudent[]>(url, {
      headers: header,
    }).
      pipe(
        catchError(this.handleErrorObservable<RatingStudent[]>(`getRatingStudentsByURL`))
      );
  }

  getRatingStudent(id): Observable<RatingStudent> {
    const header = this.createHttpClientAuthorizationHeader();
    const _urlRatingStudent = this.apiRatingStudents + id + '/';
    return this._http.get<RatingStudent>(_urlRatingStudent, { headers: header }).
      pipe(
        catchError(this.handleErrorObservable<RatingStudent>(`getRatingStudentByID`))
      );
  }

  postRatingStudent(body) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.post<RatingStudent>(this.apiRatingStudents, body, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<RatingStudent>(`Envoi de RatingStudent`))
    );
  }

  deleteRatingStudent(rateUrl): Promise<RatingStudent> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.delete(rateUrl, { headers: headers })
      .toPromise()
      .then((res: any) => res)
  }

  patchRatingStudent(ratingStudentUrl, param): Promise<RatingStudent> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(ratingStudentUrl, param, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(err => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
      })
  }
}
