import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MzBaseModal } from 'ng2-materialize';
import { UserService } from '../../service/user.service';
import { MzToastService } from 'ng2-materialize';
@Component({
  selector: 'app-valid-modal',
  templateUrl: './valid-modal.component.html',
  styleUrls: ['./valid-modal.component.scss'],
  providers: [UserService]
})
export class ValidModalComponent extends MzBaseModal implements OnInit {
  @Output() outputValidModal = new EventEmitter();
  @Input() user;
  public modalOptions: Materialize.ModalOptions = {
    dismissible: false, // Clickout false
    complete: () => { } // Callback for Modal close
  };

  constructor(private userService: UserService, private toastService: MzToastService) { super() }

  ngOnInit() { }

  cancel() {
    this.user.is_active = !this.user.is_active;
    this.outputValidModal.emit(false);
    this.modalComponent.close();
  }

  valid() {
    let statusLbl;
    let statusUrl;
    if (this.user.is_active) {
      statusLbl = 'activation du compte de ' + this.user.first_name;
      statusUrl = 'activate/'
    } else {
      statusLbl = 'desactivation du compte de ' + this.user.first_name;
      statusUrl = 'deactivate/'
    }
    this.userService.activateStatus(this.user, statusUrl)
      .subscribe(res => {
        this.outputValidModal.emit(false);
        this.modalComponent.close();
        this.userService.patchUser(this.user.url, { is_active: this.user.is_active })
          .then(_user => {
            this.toastService.show(statusLbl, 1000, 'green');
          });
      });
  }

}
