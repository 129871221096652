import { StudentFeedbackComponent } from './../../../feature/student-feedback/student-feedback.component';
/**
 * Module pour mon Compte/Profil
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyaccountComponent } from './myaccount.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterializeModule } from 'ng2-materialize';
import { MaterialModule } from '../../../material.module';
import { TimezoneService } from '../../../service/timezone.service';
import { ProInfoComponent } from './information/pro-info/pro-info.component';
import { DirectiveModule } from '../../../directive/directive.module';
import { MomentModule } from 'ngx-moment';
import { RatingTeachersService } from '../../../service/rating-teachers.service';
import { InformationComponent } from './information/information.component';
import { MyaccountRoutingModule } from './myaccount-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureModule } from '../../../feature/feature.module';

@NgModule({
  imports: [
    CommonModule,
    MyaccountRoutingModule,
    MaterializeModule.forRoot(),
    FormsModule,
    FeatureModule,
    MaterialModule,
    ReactiveFormsModule,
    MomentModule,
    DirectiveModule,
    TranslateModule.forRoot(),
  ],
  declarations: [MyaccountComponent, ProInfoComponent, InformationComponent],
  providers: [TimezoneService, RatingTeachersService]
})
export class MyaccountModule { }
