import { ExerciceSheetService } from './../../../../../../../service/exerciceSheet.service';
import { FormControl } from '@angular/forms';
import { ExerciceSheet } from './../../../../../../../model/exerciceSheet.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-sheet-edit-content',
  templateUrl: './sheet-edit-content.component.html',
  styleUrls: ['./sheet-edit-content.component.scss']
})
export class SheetEditContentComponent implements OnInit {
  @Input() exerciceSheet: ExerciceSheet;
  @Output() updateSheetObject: EventEmitter<ExerciceSheet> = new EventEmitter();
  content = new FormControl();
  to_remember = new FormControl();
  show_to_remember = new FormControl();
  public Editor = DecoupledEditor;
  ckEditorConfig = {
    link: {
      // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
      addTargetToExternalLinks: true,
    },
    toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'fontsize', '|', 'alignment', 'indent', 'outdent', '|', 'bulletedList', 'numberedList', '|', 'link', 'insertTable', '|', 'undo', 'redo'],
  }

  constructor(
    private exerciceSheetService: ExerciceSheetService,
  ) { }
  ngOnInit() {
    this.loadData();
    this.show_to_remember.valueChanges.subscribe(val => {
      const _params = {
        show_to_remember: val
      };
      this.patchSheet(_params);
    })
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  loadData() {
    this.show_to_remember.setValue(this.exerciceSheet.show_to_remember);
  }

  updateSheet(element, controlName) {
    const val = element[controlName].value;
    const _params = {
      [controlName]: val
    };
    this.patchSheet(_params);
  }

  updateWysywigSheet(controlName) {
    const _params = {
      [controlName]: this.exerciceSheet[controlName]
    };
    this.patchSheet(_params);
  }

  patchSheet(_params) {
    this.exerciceSheetService.patchExerciceSheet(this.exerciceSheet.id, _params).subscribe(sheet => {
      this.exerciceSheet = sheet;
      this.emitUpdateSheetObject(sheet);
    });
  }

  emitUpdateSheetObject(sheet) {
    this.updateSheetObject.emit(sheet);
  }


}
