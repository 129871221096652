import { HelpNotifService } from './../../service/helpNotif.service';
import { AchievementManagerService } from './../../service/achievementManager.service';
import { AchievementService } from './../../service/achievement.service';
import { AchievementDoneservice } from './../../service/achievementDone.service';
import { NotificationService } from './../../service/notification.service';
import { ProfilePhotosService } from './../../service/profilePhotos.service';
import { GroupService } from './../../service/group.service';
import { ExerciceSessionService } from './../../service/exerciceSession.service';
import { PreviousRouteService } from './../../service/previous-route.service';
import { EmbedVideo } from 'ngx-embed-video';
import { ExerciceSheetService } from './../../service/exerciceSheet.service';
import { ExerciceService } from './../../service/exercice.service';
import { DownloadLingueoService } from './../../directive/download-lingueo/download-lingueo.service';
/**
 * Module principal de teacher.
 */

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterializeModule, MzToastService } from 'ng2-materialize';
import { MomentModule } from 'ngx-moment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TeacherComponent } from './teacher.component';
import { TeacherRoutingModule } from './teacher.routing.module';
import { KiwixiService } from '../../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';

// import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { TimeslotComponent } from '../../directive/timeslot/timeslot.component';
import { AuthenticationService } from '../../service/authentification.service';
import { AuthGuard } from '../../guard/auth.guard';
import { DeviceDetectorModule } from 'ngx-device-detector';

// component specifique de l'application
import { HeaderTeacherComponent } from './component/header-teacher/header-teacher.component';
import { FooterTeacherComponent } from './component/footer-teacher/footer-teacher.component';
import { MobileMenuComponent } from './component/mobile-menu/mobile-menu.component';
import { DesktopMenuComponent } from './component/desktop-menu/desktop-menu.component';

// module de l'application teacher
import { UpcomingClassesModule } from './upcoming-classes/upcoming-classes.module';
import { ScheduleModule } from './schedule/schedule.module';
import { BillingModule } from './billing/billing.module';
import { TrainingsModule } from './trainings/trainings.module';
import { MessagesModule } from './messages/messages.module';
import { MyaccountModule } from './myaccount/myaccount.module';
import { DocumentsModule } from './documents/documents.module';
import { ReportsModule } from './reports/reports.module';
import { TeacherService } from '../../service/teacher.service';
import { DictionnaryService } from '../../service/dictionnary.service';
import { FeatureModule } from '../../feature/feature.module';
import { EventService } from '../../service/event.service';
import { OccurrenceService } from '../../service/occurrence.service';
import { OccurrenceHistoryStatusService } from './../../service/occurrenceHistoryStatus.service';
import { UserService } from '../../service/user.service';
import { StudentService } from '../../service/student.service';
import { StaffService } from '../../service/staff.service';
import { FormationService } from '../../service/formation.service';
import { PlanService } from '../../service/plan.service';
import { UpcomingService } from '../../service/upcoming.service';
import { RateStudentService } from '../../service/rate-student.service';
import { PendingService } from '../../service/pending.service';
import { SessionService } from '../../service/session.service';
import { InitAppService } from '../../service/initapp.service';

import { MaterialModule } from '../../material.module';
import { DirectiveModule } from '../../directive/directive.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from './../../kiwixi/error.exception';
import { InvoiceService } from '../../service/invoice.service';

// interceptor
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CachingInterceptor } from '../../interceptors/cache.interceptor';
import { CacheService } from '../../interceptors/cache.service';
import { TaskService } from '../../service/task.service';
import { ExerciceSpecificContentService } from '../../service/exerciceSpecificContent.service';
import { ExerciceCategoryService } from '../../service/exerciceCategory.service';
import { SheetsModule } from './sheets/sheets.module';
import { RoutePermissionService } from '../../service/routePermission.service';
import { RightMenuComponent } from './component/right-menu/right-menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaintenanceGuard } from '../../guard/maintenance.guard';
import { ScheduleGuard } from '../../guard/schedule.guard';
@NgModule({
  declarations: [
    TeacherComponent,
    HeaderTeacherComponent,
    FooterTeacherComponent,
    RightMenuComponent,
    TimeslotComponent,
    DesktopMenuComponent,
    MobileMenuComponent,
    DashboardComponent
  ],
  imports: [
    MaterialModule,
    // InterceptorModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterializeModule.forRoot(),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    MomentModule,
    DeviceDetectorModule.forRoot(),
    // application specific import with routing
    UpcomingClassesModule,
    ScheduleModule,
    BillingModule,
    TrainingsModule,
    MessagesModule,
    MyaccountModule,
    SheetsModule,
    DocumentsModule,
    ReportsModule,
    TranslateModule.forRoot(),
    TranslateModule.forChild(),
    DirectiveModule,
    FeatureModule,
    UpcomingClassesModule,
    TeacherRoutingModule,
    EmbedVideo.forRoot(),
  ],
  exports: [
    InfiniteScrollModule,
  ],
  providers: [
    KiwixiService,
    KiwixiGlobals,
    InitAppService,
    AuthGuard,
    MaintenanceGuard,
    ScheduleGuard,
    AuthenticationService,
    MzToastService,
    TeacherService,
    DictionnaryService,
    EventService,
    OccurrenceService,
    OccurrenceHistoryStatusService,
    UserService,
    StudentService,
    StaffService,
    FormationService,
    PlanService,
    UpcomingService,
    RateStudentService,
    PendingService,
    SessionService,
    NotificationService,
    InvoiceService,
    CacheService,
    DownloadLingueoService,
    ExerciceService,
    ExerciceSheetService,
    ExerciceSpecificContentService,
    ExerciceCategoryService,
    ExerciceSessionService,
    TaskService,
    PreviousRouteService,
    GroupService,
    RoutePermissionService,
    ProfilePhotosService,
    AchievementDoneservice,
    AchievementService,
    AchievementManagerService,
    HelpNotifService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true }
  ],
  bootstrap: [TeacherComponent]
})
export class TeacherModule { }
