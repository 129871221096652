import { ExerciceSheetService } from './../../../../../../service/exerciceSheet.service';
import { ExerciceSheet } from './../../../../../../model/exerciceSheet.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sheet-content',
  templateUrl: './sheet-content.component.html',
  styleUrls: ['./sheet-content.component.scss', '../../../formation/informations/informations.ux.scss', '../../../formation/formation.ux.scss', '../../../mainscreen.ux.scss']
})
export class SheetContentComponent implements OnInit {
  @Input() exerciceSheet: ExerciceSheet;
  editContent = true;
  constructor(
    private exerciceSheetService: ExerciceSheetService,
  ) { }

  ngOnInit() {
  }

  refreshSheet() {
    this.exerciceSheetService.getExerciceSheetById(this.exerciceSheet.id).subscribe(sheet => this.exerciceSheet = sheet);
  }

  updateSheetObject(sheet) {
    this.refreshSheet();
  }
}
