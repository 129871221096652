import { FormationOptions } from './../model/formationOptions.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';


@Injectable()
export class FormationOptionsService extends KiwixiService {

    apiFormationOption = environment.server + 'formations_options/';


    constructor(private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService
    ) {
        super(_http, _router, kiwixiGlobals, toastService);
    }


    getFormationOptions(params: HttpParams): Observable<FormationOptions[]> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<FormationOptions[]>(this.apiFormationOption, {
            headers: header,
            params: params
        })
            .pipe(
                catchError(this.handleErrorObservable<FormationOptions[]>(`getOptions`))
            );
    }

    postFormationOptionById(id): Observable<FormationOptions> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<FormationOptions>(this.apiFormationOption, {
            headers: header,
            params: new HttpParams().set('certification', id),
        })
            .pipe(
                catchError(this.handleErrorObservable<FormationOptions>(`getOptionById`))
            );
    }

    postFormationOption(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<FormationOptions>(this.apiFormationOption, params, { headers: headers, })
            .pipe(
                catchError(this.handleErrorObservable<FormationOptions>(`Création d'une option`)),
            );
    }

    deleteOption(id): Observable<FormationOptions> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.delete<FormationOptions>(this.apiFormationOption + id + '/', {
            headers: header,
        })
            .pipe(
                catchError(this.handleErrorObservable<FormationOptions>(`deleteOption`))
            );
    }

}
