import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-formation-list-menu',
  templateUrl: './formation-list-menu.component.html',
  styleUrls: ['./formation-list-menu.component.scss']
})
export class FormationListMenuComponent implements OnInit {
  @Input() formations;
  @Input() status;
  @Input() translatePath;
  @Output() updateCurrentFormation = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  initCurrentFormation(value) {
    this.updateCurrentFormation.emit(value)
  }

}
