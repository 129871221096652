<div class="comprate-block">
  <div class="comprate-title">
    <span class="comprate-icon"></span>
    <span translate>lingueopro.dashboard.comprate-title</span>
  </div>
  <div class="comprate-graph">
    <div class="comprate-graph-element comprate-graph-total" [style.width.%]="totalHours"></div>
    <div class="comprate-graph-element comprate-graph-planned" [style.width.%]="hoursPlanned"></div>
    <div class="comprate-graph-element comprate-graph-used" [style.width.%]="hoursConsummed"></div>
    <div class="comprate-stats"><span class="comprate-stats-planned">{{formation.hours_planned | number : '1.0-1'}}</span><span class="comprate-stats-total">/{{formation.hours_total | number : '1.0-1'}}h</span><span class="comprate-stats-used"> dont {{formation.hours_consummed | number : '1.0-1'}}h de cours pris</span></div>
  </div>
  
  <!--<span translate [translateParams]="{ hours_planned: formation.hours_planned, hours_consummed: formation.hours_consummed, hours_total: formation.hours_total | number}">lingueopro.dashboard.title1</span>-->
  <div class="booking-btn-wrap">
    <button class="booking-btn rec-booking-btn" *ngIf="formation.booking_mode!='ponctual'"(click)="goToRecurrentBooking()">
      <span *ngIf="nbEventSession>0" translate>lingueopro.dashboard.Gérer</span>
      <span *ngIf="nbEventSession==0" translate>lingueopro.dashboard.Réserver</span> 
      <span translate>lingueopro.dashboard.mes cours récurrents</span>
    </button>
    <button class="booking-btn ponctual-booking-btn" *ngIf="formation.hours_to_plan>0 && formation.mode_formation=='standard'" (click)="goToClassPonctualBooking()" translate>lingueopro.dashboard.Reserver des cours ponctuels</button>
    <button class="booking-btn ponctual-booking-btn" *ngIf="formation.hours_to_plan>0 && formation.mode_formation=='lilate'" (click)="goToClassPonctualBooking()" translate>lingueopro.dashboard.Reserver un test</button>
  </div>
  <div  *ngIf="formation.hours_to_plan>0">
    <div class="deadline-text" *ngIf="formation.mode_formation=='standard'" translate [translateParams]="{ hours_to_plan: formation.hours_to_plan, validity_period_to: formation.validity_period_to | amDateFormat:'DD/MM/YYYY'}">lingueopro.dashboard.title2</div>
    <div class="deadline-text" *ngIf="formation.mode_formation=='lilate'" translate [translateParams]="{ hours_to_plan: formation.hours_to_plan, validity_period_to: formation.validity_period_to | amDateFormat:'DD/MM/YYYY'}">lingueopro.dashboard.title2-lilate</div>
  </div>
</div>