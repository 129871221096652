<ng-container *ngIf="showSchedule && showDaySelector; else elseTemplate">
  <div class="row">
    <div class="col l2 timeslot-text">
      <!-- Tous les... -->
      <span *ngIf="indexItem === 0" translate>lingueopro.reschedule.each-label</span>
      <!-- Et les... -->
      <span *ngIf="indexItem === 1" translate>lingueopro.reschedule.and-label</span>
      <!-- Et aussi les... -->
      <span *ngIf="indexItem > 1" translate>lingueopro.reschedule.also-label</span>
    </div>
    <!-- <div class="col l10"> -->
    <div class="col l10">
      <div class="col l4 select-schedule day-picker">
        <mat-select [(ngModel)]="daySelected" (ngModelChange)="initStartSelector()" [disabled]="loadStarts">
          <mat-option *ngFor="let day of dayList" [value]="day" [disabled]="!day.enable">
            <span translate>{{day.str}}</span>
          </mat-option>
        </mat-select>
      </div>

      <ng-container *ngIf="showStartSelector ; else elseLoadStart">
        <div class="col l1 schedule-label">
          <span translate>lingueopro.reschedule.from-label</span>
        </div>
        <div class="col l4 select-schedule time-picker">
          <mat-select [(ngModel)]="startSelected" (ngModelChange)="initEndSelector()" [disabled]="loadEnds">
            <mat-option *ngFor="let start of startsList" [value]="start.str" [disabled]="!start.enable">
              {{ start.local_str }}
            </mat-option>
          </mat-select>
        </div>

      </ng-container>
      <ng-template #elseLoadStart>
        <div class="col l3 loading-label center" *ngIf="startIsLoading && daySelected">
          Chargement...
        </div>
      </ng-template>

      <ng-container *ngIf="showEndSelector; else elseLoadEnd">
        <ng-container>
          <div class="col l1 schedule-label">
            <span translate>lingueopro.reschedule.to-label</span>
          </div>
          <div class="col l4 select-schedule time-picker">
            <mat-select [(ngModel)]="endSelected" (ngModelChange)="emitEvent()">
              <mat-option *ngFor="let end of endsList" [value]="end.str" [disabled]="!end.enable">
                {{ end.local_str }}
              </mat-option>
            </mat-select>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #elseLoadEnd>
        <div class="col l3 loading-label" *ngIf="endIsLoading && startSelected">
          Chargement...
        </div>
      </ng-template>
    </div>
    <ng-container *ngIf="create_mode">
      <div class="col l2 remove-pos">
        <span class="remove-btn" (click)="destroyEvent()" translate>lingueopro.reschedule.remove-label</span>
      </div>
    </ng-container>

  </div>
</ng-container>
<ng-template #elseTemplate>
  <span class="load-position" translate>{{translatePath}}.load-slot-label</span>...
</ng-template>