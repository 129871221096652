import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';
import { DocumentsComponent } from './documents.component';
// from module

export const routes: Routes = [
  {
    path: 'documents',
    component: DocumentsComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    data: { 'sharing': false }
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
