import { interval } from 'rxjs';
import { OccurrenceService } from './../../../service/occurrence.service';
import { UpcomingService } from './../../../service/upcoming.service';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from './../../../service/authentification.service';
import { Teacher } from './../../../model/teacher.model';
import { Occurrence } from '../../../model/occurrence.model';
import { FormationService } from '../../../service/formation.service';
import { Formation } from '../../../model/formation.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-teacher-next-session',
  templateUrl: './dashboard-teacher-next-session.component.html',
  styleUrls: ['./dashboard-teacher-next-session.component.scss']
})
export class DashboardTeacherNextSessionComponent implements OnInit, OnDestroy {
  teacher: Teacher;
  nextOccurrence: Occurrence;
  nextOccurrenceService
  student: any;
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.dashboard-teacher';
  idSkype: any
  countdown: any;
  countdownStatus = 'in'
  formation: Formation = new Formation();
  duration;
  isLoaded = false;
  pollingObservable = null;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private formationService: FormationService,
    private authenticationService: AuthenticationService,
    private upcomingService: UpcomingService,
    private occurrenceService: OccurrenceService,
  ) { }

  ngOnInit() {
    this.teacher = this.authenticationService.currentUserSubject.value;
    setInterval(() => this.setCountdown(), 1000);
    this.getNextOccurrences();
    this.pollingObservable = interval(30000)
      .subscribe(i => {
        this.getNextOccurrences();
      });
  }

  /**
   * Definit le lien d'appel skype de l'élève
   */
  skypeLink() {
    if (this.student.user.id_skype) {
      this.idSkype = 'skype:' + this.student.user.id_skype + '?call';
      return this.sanitizer.bypassSecurityTrustUrl(this.idSkype);
    }
  }
  setStudent() {
    if (this.nextOccurrence.student) {
      this.student = this.nextOccurrence.student
    }
  }

  getformation() {
    this.formationService.getObservableFormationByUrl(this.nextOccurrence.formation).subscribe(formation => {
      this.formation = formation
    });
  }

  getNextOccurrences() {
    const url = environment.server + 'upcomings/?teacher=' + this.teacher.id + '&page_size=1&salt=' + Math.random();
    const _param = new HttpParams();
    this.nextOccurrenceService = this.upcomingService.getUpcomingsNextOccurrence(url, _param)
      .subscribe(_occs => {
        if (_occs && _occs['results'].length > 0) {
          this.nextOccurrence = _occs['results'][0];
          this.setStudent();
          this.getformation();
        } else {
          this.nextOccurrence = null;
        }
        this.isLoaded = true;
      })
  }

  ngOnDestroy() {
    if (this.nextOccurrenceService) {
      this.nextOccurrenceService.unsubscribe();
    }
  }

  setCountdown() {
    if (this.nextOccurrence) {
      const _sessionDiff = moment(this.nextOccurrence.end).diff(this.nextOccurrence.start);
      const sessionDuration = moment.duration(_sessionDiff);
      let halfTime = null;
      if (sessionDuration.asHours() > 1) {
        const calc = sessionDuration.asHours() / 2;
        halfTime = moment(this.nextOccurrence.start).add(calc.toString(), 'hours')
      } else {
        halfTime = moment(this.nextOccurrence.start).add('15', 'minutes')
      }
      // debugger
      const now = moment();
      let diff = moment(this.nextOccurrence.start).diff(now);
      this.duration = moment.duration(diff);
      if (this.duration.asHours() < 0) {
        if (moment(now).isAfter(moment(this.nextOccurrence.end))) {
          this.countdownStatus = 'finish';
          diff = moment(now).diff(this.nextOccurrence.end);
          this.duration = moment.duration(diff);
        } else if (moment(now).isAfter(moment(halfTime))) {
          this.countdownStatus = 'from';
          diff = moment(now).diff(this.nextOccurrence.start);
          this.duration = moment.duration(diff);
          // Handle pour récupérer le prochain next session...
          //this.getNextOccurrences();
        } else {
          this.countdownStatus = 'from';
          diff = moment(now).diff(this.nextOccurrence.start);
          this.duration = moment.duration(diff);
        }
      } else {
        this.countdownStatus = 'in';
      }

      if (Math.ceil(this.duration.asDays()) > 1) {
        this.countdown = Math.ceil(this.duration.asDays()) + ' Days ';
      } else if (Math.floor(this.duration.asHours()) >= 1) {
        this.countdown = this.duration.hours() + ' Hours ' + this.duration.minutes() + ' Minutes';
      } else if (Math.floor(this.duration.asHours()) < 1) {
        this.countdown = this.duration.minutes() + 'min ' + this.duration.seconds() + 's';
      } else {
        this.countdown = Math.floor(this.duration.asHours()) + ' h ' + this.duration.minutes() + ' min ' + this.duration.seconds() + 's';
      }
    }
  }

  linkIsValid() {
    // le bouton skype est dispo à moins de 5 min avant le prochain cours ou pendant le cours (jusqu'à 30 min).
    if (this.duration && Math.floor(this.duration.asHours()) < 1 && this.duration.minutes() < 5 || this.countdownStatus === 'from') {
      return true
    }
    return false
  }

  joinClass() {
    this.patchData('is_teacher_provider_link_clicked');
    this.openTeamsLink();
  }


  patchData(data) {
    const params = {
      [data]: moment().toISOString(),
    }
    this.occurrenceService.patchObservableOccurrence(this.nextOccurrence.url, params).subscribe(occurrence => {
      if (occurrence) {
        this.nextOccurrence = occurrence
      }
    });
  }


  openTeamsLink() {
    if (this.nextOccurrence.provider_link) {
      window.open(this.nextOccurrence.provider_link, '_blank');
    }
  }


}
