<div id="assign-sheets" *ngIf="occurrence">
    <div class="assign-header">
        <i class="material-icons back-btn" routerLink="../../..">arrow_back</i>
        <span class="page-title" translate>teacher.sheets.title</span>
        <span class="class-ref">for {{ occurrence.student.user.first_name}}'s {{ occurrence.start | date:'MMM dd' }} class</span>
    </div>

    <div class="assign-mainwrap">

        <div class="sheetslists-wrap">
            <app-sheet-assign *ngIf="occurrence"
                [formation]="null"
                [occurrence]="occurrence" 
                [validatedByStudent]="occurrence.student"
                [reportAddedSheet]="false" 
                (emitSheetToView)="showSheet($event)" 
                (isSheetAdded)="null">
            </app-sheet-assign>
        </div>

        <div class="desktop-sheet-viewer">
            <app-sheet-view [sheet]="sheet" [showExercices]="false" [showExamination]="false" [filterByStudent]=""></app-sheet-view>
        </div>

    </div>

</div>