<div class="valign-wrapper">
    <button class="changemonth-btn action-button" mz-button [flat]=true (click)="setPreviousDay()">
      <<
    </button>
  <div class="monthlabel">
    <span>{{monthNames[month - 1]}}  {{year}}</span>
  </div>
    <button class="changemonth-btn action-button" mz-button [flat]=true (click)="setNextDay()">
      >>
    </button>
</div>
<div class="row">
  <table class='calendar'>
    <tr class='weekdays-list'>
      <td class="center"><b>Dim</b></td>
      <td class="center"><b>Lun</b></td>
      <td class="center"><b>Mar</b></td>
      <td class="center"><b>Mer</b></td>
      <td class="center"><b>Jeu</b></td>
      <td class="center"><b>Ven</b></td>
      <td class="center"><b>Sam</b></td>
    </tr>
    <tr class="calendar-boxes" *ngFor="let line of calendarData">
      <td *ngFor="let item of line.cols">
        <div class='day-off' *ngIf="item === 0"></div>
        <div *ngIf="item > 0">
          <div class="day-enable" [ngClass]="setStyle(item)" (click)="selectDay(item)">{{item}}</div>
        </div>
      </td>
    </tr>
  </table>
</div>