<div id="listing-recurring">
    <div class="row"></div>
    <div class="row">
        <div class="col left">
          <h5 class="page-title">Suivi des Formations</h5>
        </div>

        <div class="col right">
            <button class="action-button addnew-button export-button" mz-button (click)="export()" [disabled]="selection.selected.length == 0">
                Export XLS
                <i class="material-icons tiny left">launch</i>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="results-counter">Date de mise à jour : {{ dateLastUpdate | date: 'dd MMM yyyy HH:mm'}} </div> 

        <div class="stats-formation">
            <div class="results-counter">Formations : {{ resultsLength }} </div> 
            <div class="results-counter">Formations planifiées: {{ totalActive }} </div> 
            <div class="results-counter">Formations terminée: {{ totalDone }} </div> 
            <div class="results-counter">Formations Non planifiées: {{ totalPending }} </div> 
            <div class="results-counter">Formations Suspendues: {{ totalSuspended }} </div> 
            <div class="results-counter">Formations Annulées: {{ totalCancelled }} </div>
        </div>
        <div class="stats-budget">
            <div class="results-counter">Planification moyenne: {{ planification_moyennne }} </div> 
            <div class="results-counter">Budget contractualisé: {{ budget_contrat | currency:'EUR':'symbol-narrow'}} </div> 
            <div class="results-counter">Budget engagé: {{ totalAmountEngaged | currency:'EUR':'symbol-narrow'}} </div> 
            <div class="results-counter">Budget réalisé: {{ totalAmountConsumed | currency:'EUR':'symbol-narrow'}} </div> 
        </div>
       
    </div>


     

   
        <div class="col l6 left"> 
            <div class="row">
                <div>
                    <button class="select-all-btn" (click)="clearFilter()"> Vider les filtres </button>
                    <button class="select-all-btn" (click)="selectAllPage()">
                        <span *ngIf="modeAllPage==true; else unSelectAll">Déséléctionner toutes les pages</span>
                        <ng-template #unSelectAll>Séléctionner toutes les pages</ng-template>
                    </button>  
                    ligne(s) selectionée(s) : <ng-container *ngIf="modeAllPage==false; else countAll"> <span>{{ selection.selected.length}}</span></ng-container>
                    <ng-template #countAll><span>{{ selection.selected.length }}</span></ng-template>
                </div>
            </div>      
        </div>


    <div class="row datatable-position">
    <!-- CONTENT PAGE -->
                    <div>
                        <div class="example-loading-shade" *ngIf="isLoadingResults">
                            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        </div>

                        <div [formGroup]="filterFormGroup">
                           <div class="row filter_group_formations">
                                Filrer par regroupement de groupe : 
                                <mat-select formControlName="group_formations" class="header-select" multiple>
                                    <mat-option *ngFor="let group of groupFormationsList" [value]="group.id">
                                        {{group.name}}
                                    </mat-option>
                                </mat-select>
                            </div> 
                            <table mat-table [dataSource]="formations" class="reccurring-table"
                                matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
                                
                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                                        [checked]="selection.hasValue() && isPageSelected()"
                                                        [indeterminate]="selection.hasValue() && !isPageSelected()"
                                                        [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                                        (change)="$event ? selection.toggle(row.id) : null"
                                                        [checked]="selection.isSelected(row.id)"
                                                        [aria-label]="checkboxLabel(row.id)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <!-- Checkbox Description Column -->
                                <ng-container matColumnDef="select-description">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                </ng-container>

                                <!-- ID Column -->
                                <ng-container matColumnDef="id">
                                    <th class="ref-header" mat-header-cell *matHeaderCellDef mat-sort-header>Ref</th>
                                    <td class="formation-detail" (click)="showRecord(row)" mat-cell *matCellDef="let row">{{row.id}}</td>
                                </ng-container>

                                <!-- ID Description Column -->
                                <ng-container matColumnDef="id-description">
                                    <th mat-header-cell *matHeaderCellDef> <input name="filter-id" formControlName="id"/> </th>
                                </ng-container>

                                <!--company Column -->
                                <ng-container matColumnDef="company"> 
                                    <th class="company-header" mat-header-cell *matHeaderCellDef mat-sort-header>Entreprise</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">
                                      <span *ngIf="row.company">{{row.company.name }}</span>
                                    </td>
                                </ng-container>

                                <!-- company Description Column -->
                                <ng-container matColumnDef="company-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="company-input" name="filter_company" formControlName="company"/>
                                    </th>
                                </ng-container>

                                <!--language Column -->
                                <ng-container matColumnDef="language"> 
                                    <th class="language-header" mat-header-cell *matHeaderCellDef mat-sort-header>Langue</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.language.label }}</td>
                                </ng-container>
                                
                                <!-- language Description Column -->
                                <ng-container matColumnDef="language-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="language" class="header-select">
                                            <mat-option *ngFor="let language of languageList" [value]="language">
                                              {{language.label}}
                                            </mat-option>
                                          </mat-select>
                                    </th>
                                </ng-container>

                                <!--first_name Column -->
                                <ng-container matColumnDef="first_name"> 
                                    <th class="first_name-header" mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.student.user.first_name }}</td>
                                </ng-container>

                                <!-- first_name Description Column -->
                                <ng-container matColumnDef="first_name-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="first_name-input" name="filter_first_name" formControlName="first_name"/>
                                    </th>
                                </ng-container>

                                <!--last_name Column -->
                                <ng-container matColumnDef="last_name"> 
                                    <th class="last_name-header" mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.student.user.last_name }}</td>
                                </ng-container>

                                <!-- last_name Description Column -->
                                <ng-container matColumnDef="last_name-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="last_name-input" name="filter_last_name" formControlName="last_name"/>
                                    </th>
                                </ng-container>

                                <!--email Column -->
                                <ng-container matColumnDef="email"> 
                                    <th class="email-header" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.student.user.email }}</td>
                                </ng-container>

                                <!-- email Description Column -->
                                <ng-container matColumnDef="email-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="email-input" name="filter_email" formControlName="email"/>
                                    </th>
                                </ng-container>

                                <!--hours_total Column -->
                                <ng-container matColumnDef="hours_total"> 
                                    <th class="hours_total-header" mat-header-cell *matHeaderCellDef mat-sort-header>H total</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.hours_total }}</td>
                                </ng-container>

                                <!-- hours_total Description Column -->
                                <ng-container matColumnDef="hours_total-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="hours_total-input" name="filter_hours_total" formControlName="hours_total"/>
                                    </th>
                                </ng-container>

                                <!--hours_to_plan Column -->
                                <ng-container matColumnDef="hours_to_plan"> 
                                    <th class="hours_to_plan-header" mat-header-cell *matHeaderCellDef mat-sort-header>H planifié</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.hours_to_plan }}</td>
                                </ng-container>

                                <!-- hours_to_plan Description Column -->
                                <ng-container matColumnDef="hours_to_plan-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="hours_to_plan-input" name="filter_hours_to_plan" formControlName="hours_to_plan"/>
                                    </th>
                                </ng-container>

                                <!--hours_to_plan_percent Column -->
                                <ng-container matColumnDef="hours_to_plan_percent"> 
                                    <th class="hours_to_plan_percent-header" mat-header-cell *matHeaderCellDef mat-sort-header>% planifié</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.hours_to_plan_percent }}</td>
                                </ng-container>

                                <!-- hours_to_plan_percent Description Column -->
                                <ng-container matColumnDef="hours_to_plan_percent-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <input class="hours_to_plan_percent-input" name="filter_hours_to_plan_percent" formControlName="hours_to_plan_percent"/>
                                    </th>
                                </ng-container>

                                <!--last_occ Column -->
                                <ng-container matColumnDef="last_occ">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fin réelle</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.last_occ | date: 'dd MMM yyyy'}}</td>
                                </ng-container>

                                <!-- last_occ Description Column -->
                                <ng-container matColumnDef="last_occ-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                    </th>
                                </ng-container>

                                <!--validity_period_to Column -->
                                <ng-container matColumnDef="validity_period_to">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fin contrat</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.validity_period_to | date: 'dd MMM yyyy'}}</td>
                                </ng-container>

                                <!-- validity_period_to Description Column -->
                                <ng-container matColumnDef="validity_period_to-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                    </th>
                                </ng-container>

                                <!--status Column -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Statut</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.status}}</td>
                                </ng-container>

                                <!-- status Description Column -->
                                <ng-container matColumnDef="status-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="status" class="header-select" multiple>
                                            <mat-option *ngFor="let status of formationSuiviStatus"  [value]="status.code">
                                            {{status.label}}
                                            </mat-option>
                                        </mat-select>
                                    </th>
                                </ng-container>

                                <!--Decrochage Column -->
                                <ng-container matColumnDef="is_stall">
                                    <th mat-header-cell *matHeaderCellDef>Decrochage ?</th>
                                    <td class="formation-detail" mat-cell *matCellDef="let row">{{row.is_stall}}</td>
                                </ng-container>

                                <!-- Decrochage Description Column -->
                                <ng-container matColumnDef="is_stall-description">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-select formControlName="is_stall" class="header-select" multiple>
                                            <mat-option value="True">Oui</mat-option>
                                            <mat-option value="False">Non</mat-option>
                                        </mat-select>
                                    </th>
                                </ng-container>

                            <tr mat-header-row class="first-line-header" *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-header-row class="second-line-header" *matHeaderRowDef="['select-description',
                                'id-description',
                                'company-description',
                                'language-description',
                                'first_name-description',
                                'last_name-description',
                                'email-description',
                                'hours_total-description',
                                'hours_to_plan-description',
                                'hours_to_plan_percent-description',
                                'last_occ-description',
                                'validity_period_to-description',
                                'status-description',
                                'is_stall-description']">
                            </tr>
                            <tr mat-row  *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>
                        </div>

                        <div>
                            <mat-paginator [length]="resultsLength" class="paginator" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
                        </div>
                    </div>
                    <!-- FIN CONTENT PAGE -->
    </div>
</div>