<div class="pubprofile-wrapper">
  <div class="decorative-border">
    <div class="pubprofile-header">
      <b translate class="mobile-page-title">{{translatePath}}.public-profil-title</b>
    </div>
    <ng-container *ngIf="teacher; else elseTemplate">
      <div class="teachintro-wrapper">
        <div class="teachintro-pic">
          <div class="avatar-inset-shadow"></div>
          <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="teacher.user.photo_profile === null">
          <img class="user-avatar" src="{{teacher.user.photo_profile.thumbnail}}" *ngIf="teacher.user.photo_profile !== null">
        </div>
        <div class="teachintro-copy">
          <div class="teachintro-position" >
            <b translate [translateParams]="{first_name: teacher.user.first_name, language: teacher.teach_language.label}">overlay-profil-teacher</b>
          </div>
          <div class="teachintro-bulk">
            {{teacher.description_fr}}
          </div>
        </div>
      </div>
      <div class="studreviews-wrapper" *ngIf="showReviews">
        <app-student-feedback [teacher]="teacher"></app-student-feedback>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="row center loading pageload-message">
        <b translate>{{translatePath}}.profil-public-loading</b>
      </div>
    </ng-template>
  </div>
</div>
