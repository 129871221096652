  <div class="sheet-block-title"><span translate>sheet-view.exercices</span></div>
  <div *ngIf="easyExercice || mediumExercice || hardExercice">
    <div class="sheet-helpcopy">
      <span translate>sheet-view.help</span>
    </div>
    <div class="difficulty-blocks-wrap">
      <div class="difficulty-block difficulty-easy" *ngIf="easyExercice" (click)="goToExercice(easyExercice)"><div class="difficulty-icon" ></div><span class="difficulty-label" translate>exercices.easy</span><br/></div>
      <div class="difficulty-block difficulty-medium" *ngIf="mediumExercice" (click)="goToExercice(mediumExercice)"><div class="difficulty-icon" ></div><span class="difficulty-label" translate>exercices.medium</span><br/></div>
     <div class="difficulty-block difficulty-hard" *ngIf="hardExercice" (click)="goToExercice(hardExercice)"><div class="difficulty-icon" ></div><span class="difficulty-label" translate>exercices.challenge</span><br/></div>
    </div>
  </div>