import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { tap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { environment } from '../../environments/environment';

const CACHABLE_URL = '/api/v1';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {

  module;

  constructor(private cache: CacheService) {
    const idx = environment.urls.map((item) => item.source).indexOf(window.location.origin);
    this.module = environment.urls[idx].module;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.isRefreshAuthorized(req)) {
      this.cache.refresh(req.url);
    }

    if (!this.isRequestCachable(req)) {
      return next.handle(req);
    }
    const cachedResponse = this.cache.get(req);
    if (cachedResponse !== null) {
      return of(cachedResponse);
    }
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.put(req, event);
        }
      })
    );
  }

  private isRefreshAuthorized(req: HttpRequest<any>) {
    let is_valid = false;
    if (req.method === 'POST' || req.method === 'PATCH' || req.method === 'DELETE') {
      is_valid = true;
    }
    if (req.body !== null && req.body.algorithm !== undefined) {
      if (req.body.algorithm === 'availabilityteacher' ||
        req.body.algorithm === 'availabilityteachers' ||
        req.body.algorithm === 'allavailabilities'
      ) {
        is_valid = false;
      }
    }

    return is_valid;
  }

  private isRequestCachable(req: HttpRequest<any>) {
    if ((req.method !== 'GET') && (req.url.indexOf(CACHABLE_URL) > -1)) {
      return false;
    }
    if (
      req.url.includes('cache/update') ||
      req.url.includes('message') ||
      req.url.includes('conversations') ||
      req.url.includes('plan') ||
      req.url.includes('auth') ||
      req.url.includes('dashboard') ||
      req.url.includes('me')
    ) {
      return false;
    }

    return true
  }
}