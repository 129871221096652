<!-- select Date -->
<div class="daily-options-wrap">
  <div class="date-changer">
    <div class="date-changer-prev leftnavarrow">
    </div>
    <div class="date-changer-main" (click)="dateSelect.open()">
      <div type="text"><b>{{dateSelected}}</b></div>
      <input matInput [matDatepicker]="dateSelect" [(ngModel)]="newDate" (ngModelChange)="goToDate()" class="hidden">
      <mat-datepicker #dateSelect></mat-datepicker>
    </div>
    <div class="date-changer-next rightnavarrow">
    </div>
  </div>
  <div class="daily-buttons-overwrap">
    <div class="daily-editmodes-wrap">

      <button class="avail-btn" [ngClass]="{'active': avaibilityEditionMode == true}"
        (click)="avaibilityEditionMode = true; unavaibilityEditionMode = false">
        <div class="availicon"></div>
        <b translate>teacher.schedule.add-avaibility</b>
      </button>

      <button class="unavail-btn" [ngClass]="{'active': unavaibilityEditionMode == true}"
        (click)="unavaibilityEditionMode = true; avaibilityEditionMode = false">
        <div class="unavailicon"></div>
        <b translate>teacher.schedule.add-unavaibility</b>
      </button>
    </div>
    <button class="export-btn" mz-tooltip [tooltip]="'Export'" [delay]="0" [position]="'top'" (click)="exportButton = !exportButton">
      <!--EXPORT BUTTON GOES HERE-->
      <div *ngIf="exportButton" class="popover">
        <div class="popover-body">
          <button class="export-btn-inner" (click)="openGoogleAgenda()">
            <b><span class="mobile-nodisplay" translate>teacher.schedule.export-in</span> Google Calendar</b>
          </button>
          <button class="export-btn-inner" appOutlookLingueo [teacher]="teacher">
            <b><span class="mobile-nodisplay" translate>teacher.schedule.export-in</span> Outlook / iCal</b>
          </button>
        </div>
      </div>
    </button>

  </div>
</div>

<div class="error" *ngIf="errors && errors.errorOverlap" translate>
  <i class="material-icons">warning</i>
  teacher.schedule.error.error-overlap
</div>
<div class="error" *ngIf="errors && errors.errorPast" translate>
  <i class="material-icons">warning</i>
  teacher.schedule.error.error-past-event
</div>
<div class="error" *ngIf="errors && errors.errorTwoDays" translate>
  <i class="material-icons">warning</i>
  teacher.schedule.error.error-two-days
</div>

<full-calendar #ponctualCalendar [header]="{
      left: '',
      center: '',
      right: ''
    }" [eventTimeFormat]="eventTimeFormat" defaultView="timeGridWeek" [nowIndicator]='true' [weekends]="true" [selectable]="true" [selectMirror]="true"
  [plugins]="calendarPlugins" (select)="handleDateSelect($event)" [slotEventOverlap]="false" [allDaySlot]="false"
  [firstDay]="1" 
  [scrollTime]="scrollTime" (eventDrop)="handleEventDrop($event)" (eventResize)="handleEventResize($event)"
  [selectOverlap]="selectOverlap" [eventOverlap]="eventOverlap" (eventClick)="handleClickEvent($event)" [columnHeaderHtml]="titleFormat" 
  [longPressDelay]="800"
  >
</full-calendar>
<mat-spinner *ngIf="loading && (loading.occurrence || loading.ponctual || loading.recurring)" class="loader">
</mat-spinner>

<!-- [columnHeaderFormat]="{ day: 'numeric', weekday: 'long', omitCommas: true}" [columnHeaderFormat]="{weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true}"-->