import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Occurrence } from '../model/occurrence.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OnlineMeetingsService extends KiwixiService {

    api = environment.server + 'generate_online_meetings/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    generateOnlineMeetings(occurrence: Occurrence): Observable<Occurrence> {
        const header = this.createHttpClientAuthorizationHeader();
        const url = this.api + occurrence.id + '/';
        return this._http.get<Occurrence>(url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<Occurrence>(`generateOnlineMeetings`))
            );
    }
}
