import { TeacherService } from './../../../service/teacher.service';
import { AuthenticationService } from './../../../service/authentification.service';
import { Teacher } from './../../../model/teacher.model';
/**
 * Composant pour l'affichage d'une notification.
 */

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../service/notification.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Notification } from '../../../model/notification.model';

export class DashboardNotification {
  action_object_id: string;
  code: number;
  action_object: string;
  link: string;
  count: number;
  details: string;
  btnKeyLabel: string;
  icon: string;

  constructor(action_object: string, link: string, count: number, details: string, btnKeyLabel: string, icon: string) {
    this.action_object = action_object;
    this.link = link;
    this.count = count;
    this.details = details;
    this.btnKeyLabel = btnKeyLabel;
    this.icon = icon;
  }
}
@Component({
  selector: 'app-dashboard-teacher-notification',
  templateUrl: './dashboard-teacher-notification.component.html',
  styleUrls: ['./dashboard-teacher-notification.component.scss']
})
export class DashboardTeacherNotificationComponent implements OnInit, OnDestroy {

  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.dashboard-teacher';
  teacher: Teacher;
  notifObservable;
  dashboard_notifications: any[] = [];                // liste des notifications constantes.
  notifications: any[] = [];                          // liste des notifications web.
  countPendingsReports = 0;                           // compte des reports en attente.
  countMessages = 0;                                  // compte des messages en attente de lecture.
  isLoaded = false;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private teacherService: TeacherService,
  ) { }
  ngOnInit() {
    this.teacher = this.authenticationService.currentUserSubject.value;
    this.getNotifications();
    this.notifObservable = Observable.interval(300000)          // TODO voir si perte de memoire?
      .subscribe(i => {
        this.getNotifications();
      })
  }

  /**
  * récupérer les notifications.
  */
  getNotifications() {
    if (this.teacher.id) {
      this.teacherService.getDashboard(this.teacher.id)
        .subscribe(dashboard_notifications => {
          if (dashboard_notifications) {
            if (dashboard_notifications['notifications']) {
              this.setWebNotifications(dashboard_notifications['notifications'])
            }
            if (Object.keys(dashboard_notifications)) {
              this.setDashboardNotification(Object.keys(dashboard_notifications), dashboard_notifications)
            }
          }
          this.isLoaded = true;
        });
    }
  }

  setWebNotifications(_notifications) {
    _notifications.forEach(_notif => {
      const myNotif = <Notification>_notif;
      let formationExist = false;
      this.notifications.forEach(_currentnotif => {
        if (_currentnotif.id && _currentnotif.url === myNotif.url) {
          formationExist = true;
        }
      });
      if (!formationExist && !_notif.deleted && _notif.base_notification.code !== 1) {
        if (myNotif.web_content) { // **** attention si le contenu est vide on n'ajoute pas le notif
          this.notifications.push(myNotif);
        }
      }
      if (_notif.deleted) {
        if (this.notifications.indexOf(myNotif) > -1) {
        }
      }
    });
  }


  setDashboardNotification(_keys, dashboard_notifications) {
    _keys.forEach(_field => {
      switch (_field) {
        case 'pending_reports':
          this.countPendingsReports = dashboard_notifications.pending_reports;
          const current_countPendingsReports = this.dashboard_notifications.filter(_notif => _notif.action_object === 'pending_reports')[0]
          if (this.countPendingsReports > 0) {
            if (!current_countPendingsReports) {
              const notif = new DashboardNotification(
                'pending_reports',
                '/reports',
                this.countPendingsReports,
                'You have ' + this.countPendingsReports + ' pending reports',
                'reports-list', // cle a mettre
                'file-icon'
              )
              this.dashboard_notifications.push(notif);
            } else {
              current_countPendingsReports.count = this.countPendingsReports;
              current_countPendingsReports.details = 'You have ' + this.countPendingsReports + ' pending reports';
            }
          } else {
            if (current_countPendingsReports) {
              this.dashboard_notifications.splice(this.dashboard_notifications.indexOf(current_countPendingsReports), 1);
            }
          }
          break;
        case 'pending_messages':
          this.countMessages = dashboard_notifications.pending_messages;
          const current_countMessages = this.dashboard_notifications.filter(_notif => _notif.action_object === 'pending_messages')[0]
          if (this.countMessages > 0) {
            if (!current_countMessages) {
              const notif = new DashboardNotification(
                'pending_messages',
                '/messages',
                this.countMessages,
                'You have ' + this.countMessages + ' new messages',
                'messages-list',
                'message-icon'
              )
              this.dashboard_notifications.push(notif);
            } else {
              current_countMessages.count = this.countMessages;
              current_countMessages.details = 'You have ' + this.countMessages + ' new messages';
            }
          } else {
            if (current_countMessages) {
              this.dashboard_notifications.splice(this.dashboard_notifications.indexOf(current_countMessages), 1);
            }
          }
          break;
        case 'should_upload_avatar':
          const current_Upload = this.dashboard_notifications.filter(_notif => _notif.action_object === 'should_upload_avatar')[0]
          if (dashboard_notifications.should_upload_avatar) {
            if (!current_Upload) {
              const notif = new DashboardNotification(
                'should_upload_avatar',
                '/myaccount',
                1,
                'You should upload a profile picture!',
                'profile',
                ''
              )
              this.dashboard_notifications.push(notif);
            }
          } else {
            if (current_Upload) {
              this.dashboard_notifications.splice(this.dashboard_notifications.indexOf(current_Upload), 1);
            }
          }
          break;
        default:
          break;
      }
    });
  }

  deleteNotification(notification) {
    this.notificationService.patchNotifications(notification.id, { deleted: true })
      .then(_ => {
        this.removeNotification(notification);
        this.getNotifications();
      })
  }

  removeNotification(_notification) {
    this.notifications.splice(this.notifications.indexOf(_notification), 1);
  }

  gotoAndDeleteNotification(notification) {
    this.router.navigate(['trainings', 'detail', notification.action_object_id, 'info']);
    this.deleteNotification(notification);
  }




  ngOnDestroy() {
    if (this.notifObservable) {
      this.notifObservable.unsubscribe();
    }
    if (this.teacher) {
      this.teacher = null;
    }
  }
}
