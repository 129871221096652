<div class="forms-wrapper" *ngIf="formation.student.user.photo_profile_validation_requested">

  <div><b class="form-subsec-title" translate>teacher.report.identity.ID validation</b></div>
  <div translate>teacher.report.identity.Please validate your learner's identity.</div>
  <div translate>teacher.report.identity.Because the lILATE is an official test, it is important to guarantee the identity of the learner being evaluated.</div>
  <div translate>teacher.report.identity.If the learner's photo changes in the future, you will be asked to validate it again.</div>

  <div><img [src]="formation.student.user.photo_profile.photo_identity" /></div>
  <div class="buttons-wrap">
    <button class="action-btn rightstudent-btn"  (click)="updateProfileStatus('validated')"><span translate>teacher.report.identity.confirm</span></button> 
    <button class="action-btn wrongstudent-btn"  (click)="updateProfileStatus('refused')"><span translate>teacher.report.identity.Damn, it's not him</span></button> 
  </div>
</div>
