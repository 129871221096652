import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { TeacherService } from '../../../../service/teacher.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teacher-profil',
  templateUrl: './teacher-profil.component.html',
  styleUrls: ['./teacher-profil.component.scss']
})
export class TeacherProfilComponent implements OnInit {
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.trainings';
  teacher_id: any;
  teacher: any;
  constructor(
    private _location: Location,
    private teacherService: TeacherService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.teacher_id = this.route.snapshot.params.id;
    this.teacherService.getObservableTeacherById(this.teacher_id)
      .subscribe(_teacher => {
        this.teacher = _teacher;
      })
  }

  back() {
    this._location.back();
  }

}
