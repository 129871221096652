/**
 * composant pour ouvrir la session d'un teacher ou student sur une nouvelle page.
 */

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-open-session',
  templateUrl: './open-session.component.html',
  styleUrls: ['./open-session.component.scss', '../mainscreen.ux.scss']
})
export class OpenSessionComponent implements OnInit, OnChanges {

  @Input() formation;
  @Input() site;
  @Input() user;
  @Input() teacher;
  link;

  constructor(private authentificationService: AuthenticationService) { }

  ngOnInit() { }

  ngOnChanges(): void {
    if (this.site) {
      environment.urls.forEach(_url => {
        if (_url.module === this.site) {
          // On récupère le domaine pour définir quel site ouvrir.
          this.link = _url.url;
        }
      });
    }
  }

  /**
   * vérifie la validité du bouton d'ouverture de la session
   */
  isNotValid() {
    if (this.site === 'lingueopro') {
      if (this.site && this.formation && this.user) {
        return false;
      }
    } else if (this.site === 'teacher') {
      if (this.site && this.teacher && this.user) {
        return false;
      }
    }
    return true;
  }

  /**
   * Methode pour ouvrir la session.
   */
  openSession() {

    // #1 - on définie le bundle du persona.
    const newBundle = {
      site: this.site,
      language: localStorage.getItem('language'),
      device: this.authentificationService.getDeviceMode(),
      role: null,
      persona: this.user.email,
      session_id: null,
      hash: null
    }
    if (this.site === 'lingueopro') {
      newBundle.role = 'student';
    } else if (this.site === 'teacher') {
      newBundle.role = 'teacher';
    }

    // #2 - on appel l'API callMe pour récupérer le sign et la session.
    if (this.link) {
      this.authentificationService.callMe()
        .subscribe(res => {
          const responseheaders = res.headers;
          const _sign = responseheaders.get('session').split('_')[1];
          newBundle.session_id = responseheaders.get('session').split('_')[0];
          // #3 - on ouvre un nouvel onglet avec le site correspondant au personna.
          if (this.site === 'lingueopro') {
            window.open(this.link
              + '?_sign=' + _sign
              + '&access_token=' + localStorage.getItem('access_token')
              + '&persona=' + newBundle.persona
              + '&session_id=' + newBundle.session_id
              + '&formation_id=' + this.formation.id
              , '_blank');
          } else if (this.site === 'teacher') {
            window.open(this.link
              + '?_sign=' + _sign
              + '&access_token=' + localStorage.getItem('access_token')
              + '&persona=' + newBundle.persona
              + '&session_id=' + newBundle.session_id
              , '_blank');
          }

        });
    }
  }

}
