import { Component, OnInit, Input } from '@angular/core';
import { StudentService } from '../../../service/student.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-datatable-cell-student',
  templateUrl: './datatable-cell-student.component.html',
  styleUrls: ['./datatable-cell-student.component.scss'],
  providers: [StudentService]
})
export class DatatableCellStudentComponent implements OnInit {

  @Input() student;
  my_student: Object;
  defaultAvatar = environment.static + '/avatar.png';
  errorMessage = '';
  loading = true;

  constructor(private studentService: StudentService) { }

  ngOnInit() {
    if (!(typeof (this.student) === 'object')) {
      this.studentService.getObservableStudent(this.student)
        .subscribe(_student => {
          this.my_student = _student;
          this.loading = false;
        },
          error => {
            this.errorMessage = <any>error;
          })
    } else {
      this.loading = false;
      this.my_student = this.student;
    }
  }

}
