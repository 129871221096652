import { GoogleAuthService } from 'ng-gapi';
import { AfterViewInit, Component, EventEmitter, Inject, Input, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventApi, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import rrulePlugin from '@fullcalendar/rrule';
import * as moment from 'moment';
import { AuthenticationService } from '../../../../../service/authentification.service';
import { CalendarService } from '../../../../../service/calendar.service';

enum eventType {
  availability = 'availability',
  ponctual_availability = 'ponctual_availability',
  unavailability = 'unavailability',
  ponctual_unavailability = 'ponctual_unavailability',
  occurrence = 'occurrence'
}
@Component({
  selector: 'app-ponctual-calendar',
  templateUrl: './ponctual-calendar.component.html',
  styleUrls: ['./ponctual-calendar.component.scss']
})
export class PonctualCalendarComponent implements AfterViewInit {
  @ViewChild('ponctualCalendar') calendarComponent: FullCalendarComponent;
  @Input() teacher;
  @Input() calendarId;
  @Input() teacherId;
  @Input() calendarUnavailabilityId;
  @Input() calendarUnavailabilityUrl;
  @Input() calendarUrl;

  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin];
  calendarVisible = false;
  currentEvents: EventInput[] = [];
  dateSelected;
  newDate = new Date();
  avaibilityEditionMode = true;
  unavaibilityEditionMode = false;
  exportButton = false;
  errors = {
    errorOverlap: false,
    errorPast: false,
    errorTwoDays: false,
  }

  ladingFct = (type) => this.setLoading(type)

  loading = {
    occurrence: true,
    ponctual: true,
    recurring: true
  };

  scrollTime = moment().startOf('hour').subtract(2, 'hours').format('HH:mm:ss');

  device = this.authentification.getDeviceMode();

  allSlots = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30'
    , '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'
    , '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59']

  constructor(
    private authentification: AuthenticationService,
    private calendarService: CalendarService,
    private googleAuthService: GoogleAuthService,
  ) { }

  ngAfterViewInit() {
    moment.locale('en-GB');
    this.calendarService.setCalendar(this.calendarComponent);
    this.device === 'desktop' ? this.calendarComponent.getApi().changeView('timeGridWeek') : this.calendarComponent.getApi().changeView('timeGridDay');
    this.calendarService.getEvents(eventType.availability + ',' + eventType.unavailability, false, this.calendarUnavailabilityId, this.calendarId, false, this.ladingFct, 'recurring');
    this.calendarService.getEvents(eventType.ponctual_availability + ',' + eventType.ponctual_unavailability, true, this.calendarUnavailabilityId, this.calendarId, false, this.ladingFct, 'ponctual');
    this.calendarService.getLessonForDate(this.teacherId, this.ladingFct, 'occurrence');
    this.overloadCalendarButton();
  }

  async goToDate() {
    this.setLoading('clean');
    this.calendarService.goToDate(this.newDate);
    this.calendarService.getPonctualEvents().forEach(event => event.remove());
    this.calendarService.getOccurenceEvents().forEach(event => event.remove());
    this.calendarService.getLessonForDate(this.teacherId, this.ladingFct, 'occurrence');
    this.calendarService.getEvents(eventType.ponctual_availability + ',' + eventType.ponctual_unavailability, true, this.calendarUnavailabilityId, this.calendarId, false, this.ladingFct, 'ponctual');
    this.device === "desktop"
      ? this.dateSelected = moment(this.calendarComponent.getApi().getDate()).startOf('isoWeek').format('ddd DD MMM') + ' - ' + moment(this.calendarComponent.getApi().getDate()).endOf('isoWeek').format('ddd DD MMM') + ' (' + moment(this.calendarComponent.getApi().getDate()).week().toString() + ')'
      : this.dateSelected = moment(this.calendarComponent.getApi().getDate()).format('ddd DD MMM');
  }

  /**
   * Gestion de la navigation du calendar
   */
  overloadCalendarButton() {
    this.device === "desktop"
      ? this.dateSelected = moment(this.calendarComponent.getApi().getDate()).startOf('isoWeek').format('ddd DD MMM') + ' - ' + moment(this.calendarComponent.getApi().getDate()).endOf('isoWeek').format('ddd DD MMM') + ' (' + moment(this.calendarComponent.getApi().getDate()).week().toString() + ')'
      : this.dateSelected = moment(this.calendarComponent.getApi().getDate()).format('ddd DD MMM');
    document.getElementsByClassName('leftnavarrow')[0].addEventListener('click', () => {
      this.setLoading('clean');
      this.calendarService.getPonctualEvents().forEach(event => event.remove());
      this.calendarService.getOccurenceEvents().forEach(event => event.remove());
      this.calendarComponent.getApi().prev()
      this.calendarService.getLessonForDate(this.teacherId, this.ladingFct, 'occurrence');
      this.calendarService.getEvents(eventType.ponctual_availability + ',' + eventType.ponctual_unavailability, true, this.calendarUnavailabilityId, this.calendarId, false, this.ladingFct, 'ponctual');
      this.device === "desktop"
        ? this.dateSelected = moment(this.calendarComponent.getApi().getDate()).startOf('isoWeek').format('ddd DD MMM') + ' - ' + moment(this.calendarComponent.getApi().getDate()).endOf('isoWeek').format('ddd DD MMM') + ' (' + moment(this.calendarComponent.getApi().getDate()).week().toString() + ')'
        : this.dateSelected = moment(this.calendarComponent.getApi().getDate()).format('ddd DD MMM');
    });
    document.getElementsByClassName('rightnavarrow')[0].addEventListener('click', () => {
      this.setLoading('clean');
      this.calendarService.getPonctualEvents().forEach(event => event.remove());
      this.calendarService.getOccurenceEvents().forEach(event => event.remove());
      this.calendarComponent.getApi().next()
      this.calendarService.getLessonForDate(this.teacherId, this.ladingFct, 'occurrence');
      this.calendarService.getEvents(eventType.ponctual_availability + ',' + eventType.ponctual_unavailability, true, this.calendarUnavailabilityId, this.calendarId, false, this.ladingFct, 'ponctual');
      this.device === "desktop"
        ? this.dateSelected = moment(this.calendarComponent.getApi().getDate()).startOf('isoWeek').format('ddd DD MMM') + ' - ' + moment(this.calendarComponent.getApi().getDate()).endOf('isoWeek').format('ddd DD MMM') + ' (' + moment(this.calendarComponent.getApi().getDate()).week().toString() + ')'
        : this.dateSelected = moment(this.calendarComponent.getApi().getDate()).format('ddd DD MMM');
    });
  }

  setLoading(type) {
    switch (type) {
      case 'occurrence':
        this.loading.occurrence = false;
        break;
      case 'ponctual':
        this.loading.ponctual = false;
        break;
      case 'recurring':
        this.loading.recurring = false;
        break;
      case 'clean':
        this.loading.occurrence = true;
        this.loading.ponctual = true;
        break;
      default:
        break;
    }
    this.loading.occurrence || this.loading.ponctual || this.loading.recurring ? this.calendarService.selectableCalendar(false) : this.calendarService.selectableCalendar(true);
  }

  /**
   * Callback function au click sur un event pour edition
   */
  handleClickEvent(selectInfo) {
    // Ouvre la modal que si il s'agit d'un event ponctual après le moment présent
    this.clearErrors();
    if ((selectInfo.event.extendedProps.type === eventType.ponctual_availability || selectInfo.event.extendedProps.type === eventType.ponctual_unavailability)
      && moment().diff(moment(selectInfo.event.start), 'minutes') < 0) {
      const newEvent = {
        start: moment(selectInfo.event.start).toISOString(),
        end: moment(selectInfo.event.end).toISOString(),
        url: selectInfo.event.extendedProps.url
      }

      this.calendarService.openModal({
        event: newEvent,
        availablesStartSlots: this.getAvailableSlots(newEvent, 'start'),
        availablesEndSlots: this.getAvailableSlots(newEvent, 'end'),
        eventToRemove: selectInfo.event,
        type: selectInfo.event.extendedProps.type
      }, false);
    }
  }

  /**
   * Callback function au select dans le calendar
   */
  handleDateSelect(selectInfo) {
    this.clearErrors();
    if (this.calendarService.isPastEvent(selectInfo.startStr) && this.calendarService.isSameDay(selectInfo.startStr, selectInfo.endStr)) {
      this.calendarService.selectableCalendar(false);

      const newEvent = {
        start: moment(selectInfo.startStr).toISOString(),
        end: moment(selectInfo.endStr).toISOString(),
        event_type: this.avaibilityEditionMode ? eventType.ponctual_availability : eventType.ponctual_unavailability,
        calendar: this.avaibilityEditionMode ? this.calendarUrl : this.calendarUnavailabilityUrl
      }

      // Si simple click alors ouverture de modal sinon création de l'event
      if (moment(selectInfo.endStr).diff(moment(selectInfo.startStr), 'minutes') === 30) {
        if (moment(newEvent.end).format('HH:mm') === '00:00') {
          newEvent.end = moment(newEvent.end).subtract(1, 'second').toISOString();
        }
        console.log(newEvent.end);
        this.device === 'desktop'
        ? this.calendarService.openModal({ event: newEvent, maxRange: this.getNextEvent(newEvent), type: newEvent.event_type }, true)
          : setTimeout(() => { this.calendarService.openModal({ event: newEvent, maxRange: this.getNextEvent(newEvent), type: newEvent.event_type }, true) }, 100);
      } else {
        this.calendarService.saveEvent(newEvent);
      }
    } else {
      this.calendarComponent.getApi().unselect();
      this.errors.errorPast = !this.calendarService.isPastEvent(selectInfo.startStr);
      this.errors.errorTwoDays = !this.calendarService.isSameDay(selectInfo.startStr, selectInfo.endStr);
    }
  }

  /**
   * Callback function au resize dans le calendar
   */
  handleEventResize = (eventResizeInfo) => {
    this.clearErrors();
    if (this.calendarService.isPastEvent(eventResizeInfo.event.start) && this.calendarService.isSameDay(eventResizeInfo.event.start, eventResizeInfo.event.end)) {
      this.calendarService.handleEventResize(eventResizeInfo)
    } else {
      eventResizeInfo.revert();
      this.errors.errorPast = !this.calendarService.isPastEvent(eventResizeInfo.event.start)
      this.errors.errorTwoDays = !this.calendarService.isSameDay(eventResizeInfo.event.start, eventResizeInfo.event.end)
    }
  }

  /**
   * Callback function au drag an drop dans le calendar
   */
  handleEventDrop = (eventDropInfo) => {
    this.clearErrors();
    if (this.calendarService.isPastEvent(eventDropInfo.event.start) && this.calendarService.isSameDay(eventDropInfo.event.start, eventDropInfo.event.end)) {
      this.calendarService.handleEventDrop(eventDropInfo)
    } else {
      eventDropInfo.revert();
      this.errors.errorPast = !this.calendarService.isPastEvent(eventDropInfo.event.start)
      this.errors.errorTwoDays = !this.calendarService.isSameDay(eventDropInfo.event.start, eventDropInfo.event.end)
    }
  }

  clearErrors() {
    this.errors = {
      errorOverlap: false,
      errorPast: false,
      errorTwoDays: false,
    }
  }

  /**
   * Callback function au drag an drop dans le calendar
   */
  getNextEvent(checkEvent) {
    if (this.calendarService.getPonctualEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start), moment(checkEvent.start).endOf('day').toISOString())).sort(function (a, b) { return a.start.getTime() - b.start.getTime() }).length > 0) {
      const nextEventTime = this.calendarService.getPonctualEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start), moment(checkEvent.start).endOf('day').toISOString())).sort(function (a, b) { return a.start.getTime() - b.start.getTime() })[0].start;
      return [nextEventTime.getHours(), nextEventTime.getMinutes()].map(this.calendarService.leadingZero).join(':');
    } else {
      return '23:59'
    };
  }

  /**
   * Retourne tout les slot dispo de la journée en comparaison a un event
   */
  getAvailableSlots(checkEvent, type) {
    const slots = this.allSlots.slice();
    type === 'start' ? slots.pop() : slots.splice(0, 1);
    if (this.calendarService.getPonctualEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start).startOf('day'), moment(checkEvent.start).endOf('day'))).sort(function (a, b) { return a.start.getTime() - b.start.getTime() }).length > 0) {
      this.calendarService.getPonctualEvents().filter(event => moment(event.start).isBetween(moment(checkEvent.start).startOf('day'), moment(checkEvent.start).endOf('day'))).sort(function (a, b) { return a.start.getTime() - b.start.getTime() }).forEach(
        element => {
          if (moment(checkEvent.start).toDate().getTime() !== moment(element.start).toDate().getTime()) {
            if (slots.indexOf(moment(element.start).format('HH:mm')) > 0) {
              // l'utilisation de Math.round pour gerer le slot de 23:59
              type === 'end'
                ? slots.splice(slots.indexOf(moment(element.start).format('HH:mm')) + 1, Math.round((moment(element.end).diff(moment(element.start), 'minutes') / 30)) === 1 ? Math.round((moment(element.end).diff(moment(element.start), 'minutes') / 30)) : Math.round((moment(element.end).diff(moment(element.start), 'minutes') / 30)) - 1)
                : type === 'start' ? slots.splice(slots.indexOf(moment(element.start).format('HH:mm')), (moment(element.end).diff(moment(element.start), 'minutes') / 30))
                  : slots;
            }
          }
        }
      );
    }
    return slots;
  }

  selectOverlap = (event: EventApi): Boolean => {
    this.errors.errorOverlap = event.extendedProps.type === eventType.ponctual_availability || event.extendedProps.type === eventType.ponctual_unavailability ? true : false;
    return event.extendedProps.type === eventType.ponctual_availability || event.extendedProps.type === eventType.ponctual_unavailability ? false : true;
  }

  eventOverlap = (still: EventApi, moving: EventApi): Boolean => {
    this.errors.errorOverlap = still.extendedProps.type === eventType.ponctual_availability || still.extendedProps.type === eventType.ponctual_unavailability ? true : false;
    return still.extendedProps.type === eventType.ponctual_availability || still.extendedProps.type === eventType.ponctual_unavailability ? false : true;
  }

  /**
   * Retourne les headers au bon format
   */
  titleFormat = (date):string  => {
    return moment(date).format('ddd DD/MM');
  }

  /**
   * Retourne les horraires des events au bon format
   */
  eventTimeFormat = (date) => this.calendarService.eventTimeFormat(date);

  openGoogleAgenda() {
    this.signIn();
  }

  public signIn(): void {
    this.googleAuthService.getAuth()
      .subscribe((auth) => {
        auth.signIn().then(res => this.writeFormationCalendar(res));
      });
  }

  private writeFormationCalendar(res: any) {
    console.log(res)
    sessionStorage.setItem(
      'token_key', res.getAuthResponse().access_token
    );
    const params = {
      access_token: res.getAuthResponse().access_token,
    };
    this.authentification.writeGoogleCalendar(params).subscribe(
      calendar => {
      }
    )
  }
}