import { OnlineMeetingsService } from './../../../../../../service/onlineMeetings.service';
import { OccurrenceService } from './../../../../../../service/occurrence.service';
import { User } from './../../../../../../model/user.model';
import { UpcomingService } from './../../../../../../service/upcoming.service';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Occurrence } from '../../../../../../model/occurrence.model';
import { MzToastService } from 'ng2-materialize';
@Component({
  selector: 'app-next-class-student',
  templateUrl: './next-class-student.component.html',
  styleUrls: ['./next-class-student.component.scss']
})
export class NextClassStudentComponent implements OnInit {
  @Input() user: User;
  loading = false;
  loaderGenerateLink = false;
  nextSession: Occurrence = null;
  constructor(
    private upcomingService: UpcomingService,
    private onlineMeetingsService: OnlineMeetingsService,
    private toastService: MzToastService
  ) { }

  ngOnInit() {
    this.getNextSession();
  }

  getNextSession() {
    this.loading = true;
    let params = new HttpParams();
    params = params.append('page_size', '1');
    params = params.append('user', this.user.id.toString());
    params = params.append('salt', Math.random().toString());

    this.upcomingService.getUpcomings(params).subscribe(upcompings => {
      if (upcompings['results'].length > 0) {
        this.nextSession = upcompings['results'][0];
      } else {
        this.nextSession = null;
      }
      this.loading = false;
    })
  }

  openTeamsLink() {
    window.open(this.nextSession.provider_link, '_blank');
  }

  generateTeamsLink() {
    if (this.nextSession && !this.nextSession.provider_link) {
      this.loaderGenerateLink = true;
      this.onlineMeetingsService.generateOnlineMeetings(this.nextSession).subscribe(occ => {
        this.nextSession = occ;
        this.loaderGenerateLink = false;
      });
    }
  }

  /**
   * méthode pour copier lien Teams
   * @param val
   */
  copyLink() {
    if (this.nextSession && this.nextSession.provider_link) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.nextSession.provider_link;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastService.show('Le lien a été ajouté à votre press papier', 2000, 'green');
    } else {
      this.toastService.show('Il n\'y a pas de lien pour le prochain cour', 2000, 'red');
    }
  }
}
