<div *ngIf="formation" id="training-detail-screen">
  <div class="row training-header">
    <div class="col l7">
      <div class="valign-wrapper">
        <div class="header-avatar-block" *ngIf="formation.student.user.photo_profile" [ngClass]="{'photo_profile_validation_requested': formation.student.user.photo_profile.status=='validation_requested', 'photo_profile_validated': formation.student.user.photo_profile.status=='validated', 'photo_profile_refused': formation.student.user.photo_profile.status=='refused'}">
          <img class="user-avatar" src="{{formation.student.user.photo_profile.thumbnail}}" (error)="this.formationService.errorImgHandler($event)">
        </div>
        <div class="header-avatar-block" *ngIf="formation.student.user.photo_profile==null">
            <img class="user-avatar" src="{{defaultAvatar}}">
        </div>
        <div class="header-data" *ngIf="formation.student">
          <h6 class="training-and-student-name left"> <a href="{{studentUrl}}" target="_bkank" *ngIf="formation.student.user">{{formation.student.user.first_name}} {{formation.student.user.last_name}}</a>: {{formation.name}}</h6>
          <h6 class="currentID left">ID Student {{formation.student.user.id}} - ID Formation : {{getId()}} - {{formation.language_detail.label}}</h6>
        </div>
      </div>
    </div>
    <app-open-session [formation]="formation" [site]="mySite" [user]="formation.student.user"></app-open-session>
    <ng-container *ngIf="cacheLoader; else elseLoadCache">
      <div class="preloader-wrapper small active" style="margin-left: 15rem; width: 1.5rem; height: 1.5rem;">
        <div class="spinner-layer spinner-teal-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #elseLoadCache>
      <button class="action-button" mz-button [flat]=true (click)="cleanCache()" style="margin-right: 1rem;">
        <i class="material-icons">cached</i>
        <span>cache</span>
      </button>
    </ng-template>

  </div>
  <div class="row">
    <div class="col l5 information-bloc">
      <app-informations [formation]="formation" (handleUpdateFormation)="handleUpdateFormation($event)"></app-informations>
      <app-sessions [formation]="formation" (formationUpdater)="handleUpdateFormation()"></app-sessions>
      <app-progression [formation]="formation"></app-progression>
    </div>
    <div class="col l7 information-bloc">
      <app-recurrences [formation]="formation" (handleUpdateOccurenceList)="handleUpdateOccurenceList()" (handleUpdateFormation)="handleUpdateFormation($event)"></app-recurrences>
      <app-booking *ngIf="formation.teachers.length > 0" [formation]="formation" [teachers]="formation.teachers" class="booking"
        (handleUpdateFormation)="handleUpdateFormation($event)" [cacheLoader]="cacheLoader"></app-booking>
    </div>
  </div>
</div>
