import { PreviousRouteService } from './../../../../service/previous-route.service';
import { OccurrenceService } from './../../../../service/occurrence.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Occurrence } from '../../../../model/occurrence.model';
import { ExerciceSheet } from '../../../../model/exerciceSheet.model';

@Component({
  selector: 'app-report-sheet-assign',
  templateUrl: './report-sheet-assign.component.html',
  styleUrls: ['./report-sheet-assign.component.scss']
})
export class ReportSheetAssignComponent implements OnInit {
  occurrence: Occurrence = null;
  sheet: ExerciceSheet = null;
  previousUrl
  constructor(
    private route: ActivatedRoute,
    private occurrenceService: OccurrenceService,
    private router: Router,
    private previousRouteService: PreviousRouteService,
  ) { }

  ngOnInit() {
    this.previousUrl = this.previousRouteService.getPreviousUrl();
    const occurrenceId = this.route.snapshot.params.occurence_id;
    this.occurrenceService.getObservableOccurenceById(occurrenceId).subscribe(data => {
      if (data) {
        this.occurrence = data;
      }
    })
  }

  showSheet(object) {
    if (object.mode === 'mobile') {
      this.router.navigate(['/sheets/' + object.sheet.id]);
    } else {
      this.sheet = object.sheet
    }
  }

  reportSheetAdded(isSheetAdded) {
    if (isSheetAdded) {
    }
  }

}
