import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NewUser } from '../model/new-user.model';
import { User } from '../model/user.model';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Student } from '../model/student.model';
import { StudentService } from './student.service';
import { StaffService } from './staff.service';

import { TeacherService } from './teacher.service';
import { catchError } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { MzToastService } from 'ng2-materialize';
import * as async from 'async';

@Injectable()
export class UserService extends KiwixiService {

  apiUsers = environment.server + 'users/';

  public listStatusPhotosProfile = [
    { 'value': 'validation_requested', 'name': 'Validation requise' },
    { 'value': 'refused', 'name': 'Refusé' },
    { 'value': 'validated', 'name': 'Confirmée' }
  ]

  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService,
    private studentService: StudentService,
    private staffService: StaffService,
    private teacherService: TeacherService,
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getUser(id): Promise<User> {
    const urlUser = this.apiUsers + id + '/';
    return this.getUserWithUrl(urlUser);
  }

  getUserWithUrl(urlUser): Promise<User> {
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(urlUser, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        console.error('error user service', error);
        this.logout();
      })
  }

  public getUsers(_filterParams): Promise<Object[]> {
    let params = new HttpParams();
    // ordering:
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params = params.set(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params = params.set(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      params = params.set('page', _filterParams['page']);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiUsers, { headers: headers, params: params })
      .toPromise()
      .then(
        res => {
          const body = res;
          return body;
        })
      .catch(this.handleError);
  }

  postNewUser(user): Promise<NewUser> {
    const body = user;
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post(this.apiUsers, body, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        const errors = error.error;
        let _myErrStr = '';
        for (const _error of Object.keys(errors)) {
          _myErrStr += _error + ': ';
          for (const my_error of errors[_error]) {
            _myErrStr += my_error + '<br />';
          }
        }
        this.toastService.show('Erreur sauvegarde utilisateur <br +>' + _myErrStr, 4000, 'red');
        return Promise.reject(error.message || error.error || error);
      });
  }

  postUser(user): Promise<User> {
    const body = user;
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.post(this.apiUsers, body, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  patchUser(userUrl, param): Promise<NewUser> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(userUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(res => {
        const error = res;
        if (error && error.error && error.error[0]) {
          this.toastService.show('Erreur' + error.error[0], 4000, 'red');
        } else {
          this.toastService.show('Erreur patchUser', 4000, 'red');
        }
      })
  }

  patchObservableUser(userUrl, params) {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch<User>(userUrl, params, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<User>(`Mise à jour d'un utilisateur`)),
      );
  }

  patchFile(userUrl, param, file): Promise<User> {
    const self = this;
    const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    headers.delete('Content-Type');
    _formData.append('avatar', file);
    _formData.append('name', file.name);
    _formData.append('user', param);
    return this._http.patch(userUrl, _formData, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(error => {
        const errors = error.json();
        let _myErrStr = '';
        errors.forEach(_error => {
          _myErrStr += _error;
          errors[_error].forEach(my_error => {
            _myErrStr += '<br />' + errors[_error][my_error];
            this.toastService.show('Erreur sauvegarde avatar utilisateur <br +>' + _myErrStr, 4000, 'red');
          })
        })
      });
  }

  getStudents(MyUser): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const _that = this;
      const _students = [];
      const q = async.queue(function (args, callback) {
        _that.studentService.getStudentWithUrl(args.url).then(function (student) {
          _students.push(student);
          callback();
        })
      }, 10);
      for (const student of MyUser.students) {
        q.push({ url: student }, function (err) { });
      }
      // assign a callback
      q.drain = function () {
        resolve(_students);
      };
    });
    return promise;
  }

  getObservableUserById(id): Observable<User> {
    const url = this.apiUsers + id + '/';
    return this.getObservableUserByUrl(url);
  }

  getObservableUserByUrl(_url): Observable<User> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<User>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<User>(`getObservableUserByUrl`))
      );
  }

  getObservableUsers(_filterParams): Observable<User> {
    let params = new HttpParams();
    if (_filterParams['ordering']) {
      const value = _filterParams['ordering']['value'];
      params = params.append('ordering', value);
    }
    if (_filterParams['filtering'] && _filterParams['filtering']['is_student']) {
      params = params.append('is_student', 'true');
    }
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<User>(this.apiUsers, {
      headers: header,
      params: params
    })
      .pipe(
        catchError(this.handleErrorObservable<User>(`getObservableUsers`))
      );
  }

  activateStatus(user, _status) {
    const header = this.createHttpClientAuthorizationHeader();
    const obx = [];
    for (const _student of user.students) {
      const activateStatusStudent = this.activateStatusStudent(_student, _status)
      obx.push(activateStatusStudent)
    }
    return forkJoin<Student>(obx)
  }

  activateStatusStudent(_studentUrl, _status): Observable<Student> {
    const deactivateUrl = _studentUrl.concat(_status);
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Student>(deactivateUrl, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Student>(`activateStatusStudent`))
      );
  }

}
