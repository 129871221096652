/**
 * Composant pour la création d'un teacher.
 */

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Site } from '../../../../../model/site.model';
import { Language } from '../../../../../model/language.model';
import { NewUser } from '../../../../../model/new-user.model';
import { TeacherService } from '../../../../../service/teacher.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Timezone } from '../../../../../model/timezone.model';
import { TimezoneService } from '../../../../../service/timezone.service';
import { MzToastService } from 'ng2-materialize';
import { UserService } from '../../../../../service/user.service';

/**
 * Object Teacher local
 */
export class MyTeacher {
  avatar: string;
  firstname: string;
  lastname: string;
  email: string;
  id_skype: string;
  timezone: string;
  password: string;
  site: string;
  auto_pwd: boolean;
  user: NewUser;
  constructor() { }
}
@Component({
  selector: 'app-create-teacher',
  templateUrl: './create-teacher.component.html',
  styleUrls: ['./create-teacher.component.scss', './create-teacher.ux.scss', '../../mainscreen.ux.scss'],
  providers: [TimezoneService]
})
export class CreateTeacherComponent implements OnInit {
  newTeacher: MyTeacher;
  defaultAvatar = environment.static + '/avatar.png';
  public genders = [
    {
      fr: 'Homme',
      en: 'male'
    },
    {
      fr: 'Femme',
      en: 'female'
    },
    {
      fr: 'Non spécifié',
      en: 'none'
    }
  ];
  sites: Site[]
  languages: Language[]
  public filteredLanguagesList = [];
  public selectedLanguage = ''
  countPagesLanguages = 1;
  now: any;
  zones: Timezone[];
  timezoneControl: FormControl = new FormControl();
  filteredTimezones: Observable<Timezone[]>;
  myTimezone: string;
  fileAvatar = null;
  myavatar = null;
  excludeRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  errors = {
    last_name :false,
    first_name :false
  }

  constructor(
    private router: Router,
    private timezoneService: TimezoneService,
    private teacherService: TeacherService,
    private userService: UserService,
    private toastService: MzToastService) { }

  ngOnInit() {

    // On crée un object local teacher pour lequel on surveillera l'état.
    this.newTeacher = new MyTeacher();
    this.newTeacher.user = new NewUser();
    this.newTeacher.user.first_name = null;
    this.newTeacher.user.last_name = null;
    this.newTeacher.user.email = null;
    this.newTeacher.user.id_skype = null;
    this.newTeacher.user.timezone = null;
    this.newTeacher.user.password = null;
    this.newTeacher.user.auto_pwd = false;
    this.now = new Date(Date.now());
    this.zones = [];
    this.getAllTimezones(1);
    this.filteredTimezones = this.timezoneControl.valueChanges
      .startWith(null)
      .map(tz => tz && typeof tz === 'object' ? tz.label : tz)
      .map(val => val ? this.filterTimezone(val) : this.zones);
  }

  // ---- gestion des timezone ----

  /**
   * Filtrer la liste des timezones.
   * @param val
   */
  filterTimezone(val: string): Timezone[] {
    return this.zones.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) >= 0);
  }

  /**
   * Afficher le label du timezone.
   * @param tz
   */
  displayTimezoneFn(tz) {
    return tz ? tz.label : tz;
  }

  /**
   * Récupérer la liste des timezones.
   * @param _countPagesSites
   */
  getAllTimezones(_countPagesSites) {
    this.timezoneService.getTimezones({})
      .then(_timezonesData => {
        for (const timezone of _timezonesData) {
          this.zones.push(timezone)
        }
      });
  }

  /**
   * Mettre à jour le timezone du user.
   */
  updateUserTimezone() {
    this.newTeacher.user.timezone = this.myTimezone ? this.myTimezone['label'] : null;
  }

  // ---- gestion des langues ----

  /**
   * Filtrer la liste des languages.
   */
  filterLanguagesList() {
    if (this.selectedLanguage !== '') {
      this.filteredLanguagesList = this.languages.filter(function (el) {
        return el.label.toLowerCase().indexOf(this.selectedLanguage.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredLanguagesList = [];
    }
  }

  /**
   * Sélectionner la langue principale.
   * @param item
   * @param teacher
   */
  selectMainLanguage(item, teacher) {
    this.selectedLanguage = item.label
    teacher.main_language = item;
    this.filteredLanguagesList = [];
  }

  /**
   * Methode pour vérifier si l'email est valide.
   * @param e
   */
  checkValidEmail(e) {
    if (document.getElementById('email').className.match('invalid')) {
      this.newTeacher.email = null
    }
  }

  /**
   * Méthode pour créer un nouveau compte.
   */
  createNewAccount() {
    this.errors.first_name = false;
    this.errors.last_name = false;
    if (this.excludeRegExp.test(this.newTeacher.user.first_name)) {
      this.errors.first_name = true;
    }
    if (this.excludeRegExp.test(this.newTeacher.user.last_name)) {
      this.errors.last_name = true;      
    }
    if (!this.errors.first_name && !this.errors.last_name) {
    // #1 create User
    this.newTeacher.user.username = this.newTeacher.user.first_name + this.newTeacher.user.last_name;
    // set default teacher site
    this.newTeacher.site = environment.default_teacher_site;
    this.teacherService.postTeacher(this.newTeacher)
      .then(newTeacher => {
        if (this.fileAvatar) {
          this.userService.patchFile(newTeacher.user.url, newTeacher.user, this.fileAvatar)
            .then(_ => {
              this.router.navigate(['/teachers/admin', newTeacher.url.split('/')[6]]);
            });
        } else {
          this.router.navigate(['/teachers/admin', newTeacher.url.split('/')[6]]);
        }
      }).catch(res => {
        const error = res;
        const keyError = Object.keys(error)[0];
        this.toastService.show('Erreur sauvegarde professeur <br +>' + error[keyError], 4000, 'red');
      })
    }
  }

  /**
   * On active le bouton s'il ya des changements.
   */
  checkFields() {
    if (this.newTeacher && this.newTeacher.user) {
      if (this.newTeacher.user.first_name === null || this.newTeacher.user.first_name.length < 1) {
        return true
      }
      if (this.newTeacher.user.last_name === null || this.newTeacher.user.last_name.length < 1) {
        return true
      }
      if (this.newTeacher.user.email === null || this.newTeacher.user.email.length < 1) {
        return true
      }
      if (this.newTeacher.user.id_skype === null || this.newTeacher.user.id_skype.length < 1) {
        return true
      }
      if (this.newTeacher.user.timezone === null) {
        return true
      }
      if (this.newTeacher.user.password === null && !this.newTeacher.user.auto_pwd) {
        return true
      }
      if (this.newTeacher.user.password && this.newTeacher.user.password !== null) {
        if (this.newTeacher.user.password.length < 1 && !this.newTeacher.user.auto_pwd) {
          return true
        }
      }
      return false
    } else {
      return false;
    }
  }

  /**
   * On upload et affiche l'image avant de le poster.
   * @param _event
   */
  uploadAvatar(_event) {
    for (const file of _event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.myavatar = event.target['result'];
        this.fileAvatar = file;
      }
    }
  }
}
