import { Component, OnInit, Input } from '@angular/core';
import { InvoiceService } from '../../../../../service/invoice.service';
import * as moment from 'moment';
import { MzToastService } from 'ng2-materialize';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @Input() invoice;
  @Input() myTeacher;
  showInvoice: boolean;
  showDetail: boolean;
  loadUpload: boolean
  constructor(
    private invoiceService: InvoiceService,
    private toastService: MzToastService) {
    this.showInvoice = false;
    this.showDetail = false;
    this.loadUpload = false;
  }

  ngOnInit() {
    if (this.invoice) {
      this.showInvoice = true;
    }
  }

  /**
   * upload invoice
   */
  uploadInvoice($event) {
    this.loadUpload = true;
    for (const file of $event.target.files) {
      const param = {};
      param['invoice'] = this.invoice['invoice'];
      this.invoiceService.patchFile(this.invoice.url, param, 'invoice', file)
        .then(_invoice => {
          if (_invoice) {
            const _param = {
              status: 'received',
              uploaded_at: moment()
            };
            this.invoiceService.patchInvoice(_invoice.url, _param)
              .then(res => {
                this.invoice = res;
                this.toastService.show('Upload is valid', 1000, 'green');
                this.loadUpload = false;
              })
          } else {
            this.loadUpload = false;
          }
        })
        .catch(error => {
          const _error = error;
          this.invoiceService.handleError(_error['invoice'][0]);
        })
    }
  }

  getDetail() {
    if (!this.invoice.occurrences && this.invoice.id) {
      this.invoiceService.getInvoiceById(this.invoice.id)
        .subscribe(_invoice => {
          if (_invoice && _invoice['occurrences']) {
            this.invoice.occurrences = _invoice['occurrences'].sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
          }
        })
    }
  }

}
