/**
 * Sous-composant pour les factures du teacher
 * qui contiendra le composant datatable permettant l'affichage du listing des invoices.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from '../../../../../../../model/invoice.model';
import { InvoiceService } from '../../../../../../../service/invoice.service';
import { KiwixiGlobals } from '../../../../../../../kiwixi/kiwixi.globals';
import * as moment from 'moment';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: [
    './invoices.component.scss',
    './invoices.ux.scss',
    '../../edit-teacher.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [InvoiceService]
})
export class InvoicesComponent implements OnInit {
  @Input() myTeacher;
  invoices: Invoice[];
  currentIdTeacher: number;
  source: string;
  pageSize: number;
  dataInvoices: Object;
  totalData: number;
  countPages = 1;
  currentFilterParams;
  errorMessage;
  showInvoices = false;

  /**
   * Colonnes du tableau
   */
  public dataHeaders = [
    {
      name: 'id',
      title: 'Ref',
      type: 'string',
      ascending: false,
      filter_key: 'id',
      ordering_key: 'id',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'clean_date',
      title: 'Période',
      type: 'string',
      ascending: false,
      filter_key: 'periodicity__month',
      ordering_key: 'periodicity',
      filter_type: 'calendar-month',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'amount',
      title: 'Montant',
      type: 'string',
      ascending: false,
      filter_key: 'amount__range',
      ordering_key: 'amount',
      filter_type: 'interval',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'status',
      title: 'Statut',
      type: 'string',
      ascending: false,
      filter_key: 'status__icontains',
      ordering_key: 'status',
      filter_type: 'select',
      list: this.invoiceService.getInvoicesStatus(),
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'uploaded_at',
      title: 'Upload Facture',
      type: 'uploaded_at',
      ascending: false,
      filter_key: 'uploaded_at__range',
      ordering_key: 'uploaded_at',
      filter_type: 'calendar',
      cell_edit: false,
      isEdit: false
    },
  ]

  constructor(private invoiceService: InvoiceService, private kiwixiGlobals: KiwixiGlobals) { }

  ngOnInit() {
    this.source = 'teacher-invoices'
    this.pageSize = 10;
    this.showInvoices = true;
    this.dataInvoices = {
      data: [],
      headers: this.dataHeaders
    }
  }

  /**
   * Méthode pour récupérer la list des factures
   * @param _filterParams
   * @param _countPages
   */
  getInvoices(_filterParams, _countPages) {
    if (_countPages) {
      _filterParams['page'] = {
        key: 'page',
        value: _countPages
      }
    }
    this.invoiceService.getInvoices(_filterParams)
      .then(
        res => {
          if (res) {
            if (_countPages === 1) {
              this.dataInvoices['data'] = [];
              this.invoices = [];
            }
            this.totalData = res['count'];
            for (const _invoice of res['results']) {
              this.invoices.push(_invoice);
              this.setRowsData(_invoice);
            }
            if (res['next']) {
              _countPages++;
              this.getInvoices(_filterParams, _countPages)
            } else {
              this.showInvoices = true;
            }
          }

        },
        error => this.errorMessage = <any>error
      )
      .catch(this.invoiceService.handleError);
  }

  /**
   * Méthode pour récupérer la data pour afficher le tableau des factures.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {
    if (this.myTeacher) {
      _filterParams['teacher'] = {
        key: 'teacher',
        value: this.myTeacher.id
      }
    }
    this.currentFilterParams = _filterParams;
    if (!_filterParams['filtering']) {
      _filterParams['filtering'] = [];
    }
    if (!_filterParams['ordering']) {
      _filterParams['ordering'] = {
        key: 'ordering',
        value: '-id',
        page: _countPages
      }
    }
    if (_countPages) {
      _filterParams['page'] = {
        key: 'page',
        value: _countPages
      }
    }
    this.invoiceService.getInvoices(_filterParams)
      .then(
        res => {
          if (_countPages === 1) {
            this.dataInvoices['data'] = [];
            this.invoices = [];
          }
          this.totalData = res['count'];
          // this.totalAmount = res['total'];

          for (const _invoice of res['results']) {
            this.invoices.push(_invoice);
            this.setRowsData(_invoice);
          }

        },
        error => this.errorMessage = <any>error
      );
    return this.dataInvoices['data'];
  }

  /**
   * Methode pour construire les lignes du tableau pour chaque invoice.
   * @param invoice
   */
  setRowsData(invoice) {
    const row = {
      url: invoice.url,
      id: invoice.url.split('/')[6],
      id__range: Number(invoice.url.split('/')[6]),
      clean_date: invoice.clean_date,
      date__range: moment(invoice.clean_date).format('YYYY-MM-DD'),
      uploaded_at: invoice.uploaded_at,
      uploaded_at__range: this.setUploadRange(invoice.uploaded_at),
      amount: invoice.amount,
      teacher: invoice.teacher,
      payment_method: invoice.payment_method,
      invoice: null,
      status: this.setStatus(invoice.status)
    };
    this.dataInvoices['data'].push(row);
  }

  /**
   * méthode pour afficher la date d'upload
   * @param _uploaded_at
   */
  setUploadRange(_uploaded_at) {
    if (!_uploaded_at) {
      return null
    }
    return moment(_uploaded_at).format('YYYY-MM-DD');
  }

  /**
   * méthode pour afficher le label du status.
   * @param _invoiceStatus
   */
  setStatus(_invoiceStatus) {
    const _status = this.invoiceService.getInvoicesStatus();
    for (const _state of _status) {
      if (_state.code === _invoiceStatus) {
        return _state.label;
      }
    }
    return null;
  }

}
