<div class="training-menu-type" *ngIf="formations.length > 0">
  <div [ngSwitch]="status">
    <div *ngSwitchCase="'pending'">
      <h6><b translate>{{translatePath}}.waiting-formation</b></h6>
    </div>
    <div *ngSwitchCase="'active'">
      <h6><b translate>{{translatePath}}.current-formation</b></h6>
    </div>
    <div *ngSwitchCase="'done'">
      <h6><b translate>{{translatePath}}.done-formation</b></h6>
    </div>
  </div>
</div>
<div class="training-menu-row" *ngFor="let formation of formations">
  <app-formation-item-menu [formation]="formation" [translatePath]="translatePath"
    (updateInitCurrentFormation)="initCurrentFormation($event)">
  </app-formation-item-menu>
</div>
