import { HttpParams } from '@angular/common/http';
import { RatingTeachersService } from './../../service/rating-teachers.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-student-feedback',
  templateUrl: './student-feedback.component.html',
  styleUrls: ['./student-feedback.component.scss']
})
export class StudentFeedbackComponent implements OnInit {
  @Input() teacher;
  showReport = false;
  ratings: any[] = [];
  translatePath = 'teacher.account';
  countPages = 1;
  totalData = 0;
  throttle = 1000; // temps accordé avant de fire l'event de scoll
  scrollDistance = 1; // % restant à partir duquel on fire l'event 1 = 10 %.

  constructor(
    private ratingTeacherService: RatingTeachersService,
  ) { }


  ngOnInit() {
    this.ratings = [];
    this.showReport = false;
    this.getRatingTeachers();
  }
  getCurrentLanguage() {
    return this.ratingTeacherService.getCurrentLanguage();
  }

  getRatingTeachers() {
    let params = new HttpParams();
    params = params.append('teacher', this.teacher.id);
    params = params.append('page', this.countPages.toString());
    this.ratingTeacherService.getObservableRatingTeachers(params)
      .subscribe(_ratings => {
        if (_ratings) {
          this.totalData = _ratings['count'];
          _ratings['results'].forEach(_rate => {
            this.ratings.push(_rate)
          });
        }
        this.showReport = true;
      })
  }


  onScrollDown() {
    if (this.lazyIsAvailable()) {
      this.countPages++;
      this.getRatingTeachers();
    }
  }

  lazyIsAvailable() {
    if ((this.countPages * 10) < this.totalData) {
      return true;
    }
    return false;
  }


}
