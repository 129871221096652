import { Component, OnInit, Input } from '@angular/core';

// Services
import { RatingTeachersService } from '../../service/rating-teachers.service';

// Models
import { RatingTeacher } from '../../model/rating-teacher.model';
import { EventService } from '../../service/event.service';
import { SessionService } from '../../service/session.service';

@Component({
  selector: 'app-edit-teacher-evaluation',
  templateUrl: './edit-teacher-evaluation.component.html',
  styleUrls: ['./edit-teacher-evaluation.component.scss'],
  providers: [RatingTeachersService]
})
export class EditTeacherEvaluationComponent implements OnInit {
  @Input() occurrence;
  @Input() teacher;
  @Input() formation;
  newValue = 0;
  translatePath = 'lingueopro.dashboard.past_sessions';
  showEval = false;

  constructor(
    private ratingTeachersService: RatingTeachersService,
    private eventService: EventService,
    private sessionService: SessionService) { }

  ngOnInit() { }

  /**
   * Methode pour verifier si les conditions sont remplies pour le partage d'evaluation
   * @param newValue
   */
  shareIsValid(newValue) {
    return (newValue !== null && newValue !== undefined) ? true : false;
  }

  /**
   * Methode pour partager une evaluation
   * @param newValue
   * @param newShortDescription
   * @param newMessage
   */
  shareEvaluation(newValue, newShortDescription, newMessage) {
    const newRatingTeacher = new RatingTeacher();
    newRatingTeacher.student = this.formation.student.url;
    newRatingTeacher.value = newValue;
    newRatingTeacher.short_description = newShortDescription;
    newRatingTeacher.message = newMessage;

    // on verifie s'il existe une session pour cette occurrence.
    if (this.occurrence.session) {
      newRatingTeacher.session = this.occurrence.session.url;
      this.eventService.getEvent(this.occurrence.event)
        .then(_event => {
          newRatingTeacher.teacher = this.occurrence.teacher.url;
          this.ratingTeachersService.postRatingTeacher(newRatingTeacher)
            .then(res => {
              this.occurrence.session.rate_student = newRatingTeacher;
            })
        })
    } else {
      const body = {
        formation: this.formation.url,
        occurrence: this.occurrence.url
      }
      this.sessionService.createSession(body)
        .subscribe(_newSession => {
          newRatingTeacher.session = _newSession['url'];
          this.eventService.getEvent(this.occurrence.event)
            .then(_event => {
              newRatingTeacher.teacher = this.occurrence.teacher.url;
              this.ratingTeachersService.postRatingTeacher(newRatingTeacher)
                .then(_ratingTeacher => {
                  this.occurrence.session = _newSession;
                  this.occurrence.session.rate_student = newRatingTeacher;
                })
            })
        })
    }
  }

}
