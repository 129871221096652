<ng-container *ngIf="showInvoice">
  <div class="border-upcoming">

    <div class="invoice-subwrap">
    
      <div class="invoice-date">
        <b class="invoice-current-tag">Ref: {{ invoice.id}}</b>
        <b>{{invoice.clean_date}}</b>
      </div>
      
        <div class="invoice-amount">
          <b>{{invoice.amount | number : '1.0-0' }}€</b>
          <b class="invoice-status">{{invoice.status}}</b>
        </div>
    
       
      <!-- See Detail desktop-->
      <div class="detail-btn mobile-nodisplay" (click)="showDetail = !showDetail; getDetail()">
        <ng-container *ngIf="showDetail; else elseDetail">
          <span translate>teacher.invoices.hide-detail</span>
        </ng-container>
        <ng-template #elseDetail>
          <span translate>teacher.invoices.see-detail</span>
        </ng-template>
      </div>

      <!-- See Detail mobile-->
      <div class="detail-btn nomobile-nodisplay" [routerLink]="['detail/', invoice.id]">
        <span translate>teacher.invoices.see-detail</span>
      </div>

    </div>

    <div class="upload-subwrap">
      <ng-container *ngIf="invoice.status !== 'ongoing'">
        <ng-container *ngIf="invoice.status !== 'pending'; else elseInvoice">
        
            <button class="see-invoice-btn" (click)="file.click();">
              <span translate>teacher.invoices.view-invoice-btn</span>
              <!-- <span translate>View Invoice</span> -->
            </button>
            <a #file [hidden]="true" appDownloadLingueo [invoice]="invoice" [isInvoice]=true></a>
     
          <div class="invoice-upload-text">
            
            <i class="material-icons invoice-file-icon">description</i>
          
            <div>
              <span translate>teacher.invoices.invoice-uploaded</span>
              <div>{{invoice.uploaded_at | amLocale: 'en' | amDateFormat:'DD, MMM, YYYY HH:mm'}}</div>
            </div>
          </div>
        </ng-container>
        
        <ng-template #elseInvoice>
          <div>
            <ng-container *ngIf="loadUpload; else elseUpload">
              <i>uploading...</i>
            </ng-container>
            <ng-template #elseUpload>
              <button class="add-invoice-btn" (click)="uploadInvoices.click();">
                <span translate>teacher.invoices.upload-invoice-btn</span>
                <!-- <span translate>Upload Invoice</span> -->
              </button>
              <input #uploadInvoices type="file" (change)="uploadInvoice($event)" [hidden]="true" />
            </ng-template>
          </div>
          <div class="invoice-upload-text">
            
              <i class="material-icons upload-file-icon">insert_drive_file</i>
            
            <div>
              <span translate>teacher.invoices.please-upload-invoice</span>
              <!-- <span class="left">Please upload your invoice</span> -->
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>

     <!-- DetailS desktop-->
     <ng-container *ngIf="showDetail">
      <div class="mobile-nodisplay invoice-details">
        <ng-container *ngIf="invoice.occurrences; else elseOcc">
          <ng-container *ngIf="invoice.occurrences.length > 0; else elseLengthh">
            <div class="class-container">
              <div  *ngFor="let occurrence of invoice.occurrences">
                <app-class-details [occurrence]="occurrence"></app-class-details>
              </div>
            </div>
          </ng-container>
          <ng-template #elseLengthh>
            <div>
              <div>
                <span>
                  <span translate>teacher.invoices.no-classes</span>
                </span>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #elseOcc>
          <div>
            <span>
              <span translate>teacher.invoices.details-loading</span>
            </span>
            <!-- Chargement du détail... -->
          </div>
        </ng-template>
      </div>
    </ng-container>
    
  </div>
</ng-container>