import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentification.service';
import { Login } from '../../model/login.model';
import { Auth } from '../../model/auth.model';
import { MzToastService } from 'ng2-materialize';
import { Observable } from 'rxjs';
import { HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KiwixiGlobals } from '../../kiwixi/kiwixi.globals';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { InitAppService } from '../../service/initapp.service';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ENTER_KEY = 13
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './login.ux.scss'],
  providers: []
})

export class LoginComponent implements OnInit {

  public login = new Login();
  loading = false;
  returnUrl: string;
  auth: Auth;
  site = '';
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher;
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  userForm: FormGroup = this.builder.group({
    email: this.emailFormControl,
    password: this.passwordFormControl
  });
  isLoggedIn: Observable<boolean>;
  translatePath = 'login'

  constructor(
    private builder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastService: MzToastService,
    private kiwixiGlobals: KiwixiGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private initAppService: InitAppService,
  ) {
    this.isLoggedIn = authenticationService.isLoggedIn();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER_KEY) {
      this.checkAuth();
    }
  }

  ngOnInit() {
    this.authenticationService.logout();
    this.kiwixiGlobals.setLoginStatus(false);
    this.site = this.route.snapshot.data['site'];
    this.onChanges();
    if (this.authenticationService.getCurrentPersona()) {
      this.checkAuth();
    }
    this.authenticationService.currentUserSubject.subscribe(user => {
      this.auth = user;
    })

  }

  onChanges(): void {
    this.userForm.valueChanges.subscribe(val => {
      this.login.username = val.email;
      this.login.password = val.password;
    });
  }

  checkAuth() {
    this.loading = true;
    this.authenticationService.newLogin(this.login).subscribe(res => {
      this.authenticationService.checkCredentialObservable().subscribe(
        res2 => {
          this.loading = false;
          const pers = this.authenticationService.getCurrentPersona();
          if (pers) {
            this.kiwixiGlobals.setLoginStatus(true);
            this.initAppService.initApp(false, null)
          }
        },
        error => {
          this.loading = false;
          this.kiwixiGlobals.setLoginStatus(false);
          this.toastService.show('Erreur login !!!', 4000, 'red');
        });
    },
      error => {
        this.loading = false;
        this.toastService.show('Erreur login !!!', 4000, 'red');
      });
  }

  lostPassword() {
    this.router.navigate(['lost-password']);
  }

}
