<div class="reportPanel" *ngIf="occurrence" [formGroup]="reportForm">
      <div class="axe">
        <div class="axe_title">Grammar</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="grammarLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">% :</div>
          <div>
            <mat-radio-group  [formControl]="grammarPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Expression</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="expressionLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">% :</div>
          <div>
            <mat-radio-group  [formControl]="expressionPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Comprehension</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="comprehensionLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">% :</div>
          <div>
            <mat-radio-group  [formControl]="comprehensionPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Vocabulary</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="vocabularyLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">% :</div>
          <div>
            <mat-radio-group  [formControl]="vocabularyPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Accent</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="accentLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="axe_data">
          <div class="axe_data_title">% :</div>
          <div>
            <mat-radio-group  [formControl]="accentPercentCtrl">
              <mat-radio-button *ngFor="let percent of percentList" class="radioLevel" [value]="percent">
                <span class="radio">{{ percent }} %</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Oral général</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="oralLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="axe">
        <div class="axe_title">Ecrit général</div>
        <div class="axe_data">
          <div class="axe_data_title">Level :</div>
          <div>
            <mat-radio-group  [formControl]="writtenLevelCtrl">
              <mat-radio-button *ngFor="let level of cecrl_LEVEL"  class="radioLevel" [value]="level">
                <span >{{ level }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

  <div class="row information-subcontainer center" >
    <div class="container" style="display: block">
      <div class="row" style="margin-bottom: 0" >
        <button class="lilate-btn"  (click)="postRating()" [disabled]="!reportForm.valid">
          <span translate>Save</span>
        </button>      
      </div>
    </div>
  </div>

  
</div>

