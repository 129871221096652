import { RatingStudent } from './../../../../model/rating-student.model';
import { Occurrence } from './../../../../model/occurrence.model';
/**
 * Composant pour la gestion d'un rapport.
 */

import { Component, OnInit, Input } from '@angular/core';
import { StudentService } from '../../../../service/student.service';
import { environment } from '../../../../../environments/environment';
import { OccurrenceService } from '../../../../service/occurrence.service';
import { SessionService } from '../../../../service/session.service';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @Input() occurrence: Occurrence;
  @Input() ratingStudent: RatingStudent;
  @Input() showStudentName;
  @Input() showTeacherName;
  myObject: any;
  translatePath = 'teacher.reports';
  defaultAvatar = environment.static + '/avatar.png';
  constructor(
    private studentService: StudentService,
    private sessionService: SessionService,
    private occurrenceService: OccurrenceService
  ) { }

  ngOnInit() {
    if (this.occurrence) {
      this.myObject = this.occurrence;
    } else {
      this.myObject = this.ratingStudent;
    }
  }

}
