<div class="container usual-container" *ngIf="newFormation">
  <div class="center decorative-border">
    <div class="text-intro row" translate>
      {{translatePath}}.resume
    </div>
    <div class="row">
      <div class="col s12">
        <mat-select  placeholder="Précisez votre niveau" floatPlaceholder="never"
          [(ngModel)]="newFormation.niveau">
          <mat-option *ngFor="let level of levels" [value]="level.value">
            {{ level.label }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row"></div>
    <div class="row">
      <button class="waves-effect waves-light nextstep-btn" (click)="next()" [disabled]="!newFormation.niveau">
        <b translate>{{translatePath}}.step_button</b>
      </button>
    </div>
  </div>
</div>
