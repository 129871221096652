import { ExerciceMediaService } from './../../service/exerciceMedia.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-manage-exercices-medias',
  templateUrl: './manage-exercices-medias.component.html',
  styleUrls: ['./manage-exercices-medias.component.scss']
})
export class ManageExercicesMediasComponent implements OnInit {
  @Input() object;
  @Input() mediaType: string;
  plateformType = new FormControl('', [Validators.required]);
  videoUrl = new FormControl('', [Validators.required]);
  showLingueoMediaForm = false;
  iframe_video;
  plateformTypeList = ['', 'youtube', 'vimeo', 'dailymotion'];

  constructor(
    private exerciceMediaService: ExerciceMediaService,
    private embedService: EmbedVideoService
  ) { }

  ngOnInit() {
    if (this.object.media) {
      if (this.object.media.media_type === 'video' && this.object.media.source_type === 'url') {
        this.loadVideo();
      }
    }
  }

  loadVideo() {
    this.iframe_video = this.embedService.embed(this.object.media.url);
  }

  deleteMedia() {
    this.exerciceMediaService.deleteMedia(this.object.media.id).subscribe(result => {
      this.object.media = null;
    })
  }


  saveVideoEmbeded() {
    if (this.plateformType.valid && this.videoUrl.valid) {
      const _params = {
        media_type: 'video',
        source_type: 'url',
        media_type_to: this.mediaType,
        entity_id: this.object.id,
        url: this.videoUrl.value,
        plateform_type: this.plateformType.value
      };
      this.exerciceMediaService.postEmbededdMedia(_params)
        .then(result => {
          this.object.media = result;
          this.loadVideo();
        });
    } else {

    }
  }

  uploadFile($event, media_type) {
    for (const file of $event.target.files) {
      const _params = {
        media_type: media_type,
        source_type: 'stored',
        media_type_to: this.mediaType,
        entity_id: this.object.id,
      };
      this.exerciceMediaService.postStoredMedia(file, _params)
        .then(result => {
          this.object.media = result;
        });
    }
  }

}
