<div id="ponctual-booking" class="row information-container">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Réservation ponctuelle</h6>
    </div>
    <ng-container *ngIf="countRemaining > 0; else elseTemplate">
        <div class="left warning-message">Heures restantes : {{countRemaining}}</div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="left warning-message" [hidden]="countRemaining > 0">{{warningMessage}}</div>
    </ng-template>

  </div>

  <div class="row">
    <div class="col l6 panel-left panel-size">
      <div class="list-title">Réserver un cours :</div>
      <div class="valign-wrapper pickteach-row">
        <span>
          Avec
        </span>
        <div class="select-teacher">
            <mat-select [(ngModel)]="booking.teacher" (ngModelChange)="selectTeacher()" [disabled]="!(countRemaining > 0)">
              <mat-option *ngFor="let teacher of teachers" [value]="teacher">
                {{ teacher.user.first_name}} {{ teacher.user.last_name}} 
              </mat-option>
            </mat-select>
        </div>
        <div class="add-booking" >
          <button class="action-button" mz-button [flat]=true (click)="postBooking()" [disabled]="!(listNewEvents.length > 0)">
            Réserver ce cours
          </button>
        </div>
      </div>
      <div class="row center" *ngIf="loadDisponibilities" style="height: 40px;">
        <b>Chargement des disponibilités du professeur...</b>
      </div>

      <!-- ******* SELECT TIME PICKER ******* -->
      <div *ngIf="!loadDisponibilities">
        <app-select-time-picker
          [myStart]="myStart"
          [myEnd]="myEnd"
          [availablesDays]="availablesDays"
          [condition]="booking.teacher"
          (updateCalendar)="handleFilterCalendar($event)">
        </app-select-time-picker>
      </div>
      <!-- ******* CALENDAR ******* -->
      <div class="row calendar-container">
        <app-calendar
          (updateDateSelect)= "handleUpdateDateSelect($event)"
          (updateAvailables)= "handleUpdateAvailables($event)"
          [myStart]="myStart"
          [myEnd]="myEnd"
          [availablesDays]="availablesDays"
          [loadDisponibilities]="loadDisponibilities"
          [myTeacher]="booking.teacher">
        </app-calendar>
      </div>

    </div>
    <div class="col l5 panel-right">
      <div class="schedule-title" *ngIf="booking.teacher && dateSelect">
        Disponibilités de {{booking.teacher.user.first_name}} {{booking.teacher.user.last_name}}<br>
        le {{dateSelect | amDateFormat:'ddd DD MMM YYYY'}}
      </div>
      <div class="dailyschedule-container">
          <div class="row table-time" *ngIf="availableDay && dateSelect">
            <app-booking-time-row
              [formation]="formation"
              (schedulesSelectedOutput)= "handleSchedulesSelected($event)"
              (updateEventOutput)= "handleUpdateEvent($event)"
              (reinitsEventsOutput)= "handleReinitEvents()"
              (addEventOutput)= "handleAddEvent($event)"
              [availableDay]="availableDay"
              [dateSelect]="dateSelect"
              [booking]="booking">
            </app-booking-time-row>
          </div>
      </div>
    </div>
  </div>
</div>
