<div class="timezoneinput-wrapper">
  <div *ngIf="!isBackoffice"><span translate>{{translatePath}}.timezone-lbl</span>:</div>
  <mat-form-field>
    <input matInput class="dropdown" aria-label="Number" [matAutocomplete]="auto" [formControl]="timezoneControl"
      (focusout)="updateTimezone('timezone', $event)">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone.label" (onSelectionChange)="selectTimezone('timezone', timezone.label)">
        <b translate>{{ timezone.label }}</b> ({{ timezone.value }})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>