import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportService } from '../../application/teacher/reports/report.service';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss']
})
export class LevelComponent implements OnInit {
  @Input() subject;
  @Input() newValue;
  @Input() editMode;
  @Input() isFirst;
  @Input() previousReport;
  @Output() updateLevel = new EventEmitter();
  showGauge = false;
  initValue = null;
  levels = [
    { label: 'A1', value: 10, start_label: 'A1', end_label: 'A2' },
    { label: 'A2', value: 10, start_label: 'A2', end_label: 'B1' },
    { label: 'B1', value: 10, start_label: 'B1', end_label: 'B2' },
    { label: 'B2', value: 10, start_label: 'B2', end_label: 'C1' },
    { label: 'C1', value: 10, start_label: 'C1', end_label: 'C2' },
    { label: 'C2', value: 100, start_label: 'C1', end_label: 'C2' }
  ]
  constructor(private reportService: ReportService) { }

  ngOnInit() {
    if (!this.previousReport) {
      this.initValue = this.subject.level.value;
    } else {
      this.previousReport.forEach(element => {
        if (element.name === this.subject.name) {
          this.initValue = element.level.value
        }
      });
    }
    if (!this.editMode) {
      this.showGauge = true;
    } else {
      if (this.subject.level.value > 0) {
        this.showGauge = true;
      }
    }
  }

  selectLevel(_level) {
    this.showGauge = true;
    this.subject.level.value = 10;
    if (_level.label === 'C2') {
      this.subject.level.value = 100;
    }
    this.subject.level.start_label = this.reportService.setStartLabel(_level.label);
    this.subject.level.end_label = this.reportService.setEndLabel(_level.label);
    this.updateLevel.emit(this.subject);
  }

  /**
   * Methode pour augmenter le niveau.
   */
  subjectLevelUp() {
    this.subject.level.value = this.subject.level.value + this.newValue;
    this.updateLevel.emit(this.subject);
  }

  /**
   * Methode pour diminuer le niveau.
   */
  subjectLevelDown() {
    this.subject.level.value = this.subject.level.value - this.newValue;
    this.subject.level.levelUp = false;
    this.updateLevel.emit(this.subject);
  }

  gaugeValue() {
    return this.initValue + Number(this.subject.level.value - this.initValue)
  }

  getGaugeWidth() {
    if (this.subject.level.value < this.initValue) {
      return this.subject.level.value
    }
    return this.initValue;
  }

  styleGauge() {
    if (this.initValue > this.subject.level.value) {
      return 'blue darken-4 white-text'
    }
    return 'blue'
  }

}
