import { RoutePermissionService } from './../service/routePermission.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/authentification.service';

@Injectable()
export class AuthGuard implements CanActivate {
  environment

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private routePermissionService: RoutePermissionService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const persona = this.authenticationService.getCurrentPersona();

    if (!persona) {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      return false;
    }
    this.routePermissionService.routeFeaturePermissions.subscribe(routes => {
      if (routes) {
        const routesAllowedList = this.authenticationService.getRoutesAllowed(state.url);
        if (routesAllowedList.length > 0) {
          const checkRoutesPermission = this.authenticationService.checkGroupPermission(routesAllowedList[0].group);
          if (checkRoutesPermission === false) {
            this.authenticationService.toastService.show('Vous ne possédez pas les droits pour accéder à cette rubrique !', 4000, 'red');
            this.router.navigate([this.router.url]);
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
    return true;
  }
}
