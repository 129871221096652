<div class="container create-account" *ngIf="sites !== null">
  <div class="row"></div>
  <div class="information-container">
    <div class="row">
      <div class="col l3">
        <h6 class="center grey darken-1 white-text title-information">Creation Élève</h6>
      </div>
    </div>
    <div class="row">
      <div class="col l3 left avatar-wrapper">
        <div class="avatar-block">
          <img class="user-avatar" src="{{newStudent.avatar}}" *ngIf="newStudent.avatar !==null; else elseStudentAvatar" (error)="this.formationService.errorImgHandler($event)">
          <ng-template #elseStudentAvatar>
            <img class="user-avatar" src="{{defaultAvatar}}">
          </ng-template>
        </div>
      </div>
      <div class="col l8">
        <div class="row valign-wrapper form-row">
          <div class="col offset-l1 l3">
            <b class="left">Prénom :</b>
          </div>
          <div class="col l8 left">
            <input type="text" [(ngModel)]="newUser.first_name" class="filter-col textfield">
          </div>
        </div>
        <div class="row valign-wrapper form-row">
          <div class="col offset-l1 l3">
            <b class="left">Nom :</b>
          </div>
          <div class="col l8 left">
            <input type="text" [(ngModel)]="newUser.last_name" class="filter-col textfield">
          </div>
        </div>
      </div>
    </div>

    <div class="row valign-wrapper form-row">
      <div class="col l3 left">
        <b class="left">Email :</b>
      </div>
      <div class="col l8 left">
        <input id="email" type="email" [(ngModel)]="newUser.email" class="filter-col textfield left validate">
      </div>
    </div>

    <div class="row valign-wrapper form-row">
      <div class="col l3 left">
        <b class="left">Date de naissance :</b>
      </div>
      <div class="col l8 left">
        <input mdInput [matDatepicker]="birthDate" (click)="birthDate.open()" class="filter-col textfield left validate" [(ngModel)]="newUser.birthdate"
          (ngModelChange)="checkFields()">
        <mat-datepicker #birthDate></mat-datepicker>
      </div>
    </div>

    <div class="row valign-wrapper form-row">
      <div class="col l3 left">
        <b class="left">Contact ID Salesforce :</b>
      </div>
      <div class="col l8 left">
        <input id="sf_id" type="text" [(ngModel)]="newUser.sf_id" class="filter-col textfield left validate">
      </div>
    </div>

    <div class="row valign-wrapper">
      <div class="col l3 left">
        <b class="left">Genre :</b>
      </div>
      <div class="col l4 left">
        <mat-select placeholder="Choisir" floatPlaceholder="never" [(ngModel)]="newUser.gender">
          <mat-option *ngFor="let gender of genders" [value]="gender.en">
            {{ gender.fr }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="row valign-wrapper">
      <div class="col l3 left">
        <b class="left">Client sur :</b>
      </div>
      <div class="col l8 left">
        <mat-select multiple [(ngModel)]="newStudent.sites" multiple>
          <mat-option *ngFor="let site of sites" [value]="site">
            {{ site.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="row valign-wrapper form-row timezone-row">
      <div class="col l3 left">
        <b class="left">Fuseau :</b>
      </div>
      <div class="col l9 left">
        <app-timezone-autocomplete [myUser]="newUser" [isBackoffice]="true" [isCreate]="true"></app-timezone-autocomplete>
        <!-- <form>
          <input type="text" class="dropdown" aria-label="Number" mdInput [formControl]="timezoneControl" [matAutocomplete]="auto"
            [(ngModel)]="myTimezone" (ngModelChange)="updateUserTimezone()">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTimezoneFn">
            <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone">
              <b translate>{{ timezone.code }}</b> ({{ timezone.value }})
            </mat-option>
          </mat-autocomplete>
        </form> -->
      </div>
    </div>
    <div class="row">
      <div class="col l11 left right-text grey-text">
        heure actuelle : {{now | amLocal | amDateFormat: 'HH:mm:ss'}}
      </div>
    </div>

    <div class="row valign-wrapper">
      <div class="col l3 left">
        <b class="left">Langue principale :</b>
      </div>
      <div class="col l8 left">
        <form>
          <input type="text" class="dropdown" aria-label="Number" mdInput [formControl]="languageControl" [matAutocomplete]="auto2">
          <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayLanguageFn">
            <mat-option *ngFor="let language of filteredLanguages | async" [value]="language">
              {{ language.code | translate }}
            </mat-option>
          </mat-autocomplete>
        </form>
      </div>
    </div>

    <div class="row valign-wrapper">
      <div class="col l3 left">
        <b class="left">Mot de passe :</b>
      </div>
      <div class="col l8 left">
        <input type="password" [disabled]="newUser.auto_pwd" [(ngModel)]="newUser.password" class="filter-col left textfield">
      </div>
    </div>
    <div class="row password-cbx">
      <mat-checkbox class="col l9 offset-l3" [(ngModel)]="newUser.auto_pwd" (ngModelChange)="newUser.password = null">Mot de passe auto-généré</mat-checkbox>
    </div>

    <div class="row center">
      <button mz-button class="action-button final-button" (click)="createNewAccount()" [disabled]="checkFields()">
        Créer ce compte
      </button>
    </div>
  </div>
</div>