import { SheetDetailComponent } from './sheet-detail/sheet-detail.component';
import { SheetsComponent } from './sheets.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterializeModule } from 'ng2-materialize';
import { MaterialModule } from '../../../material.module';
import { TimezoneService } from '../../../service/timezone.service';
import { DirectiveModule } from '../../../directive/directive.module';
import { MomentModule } from 'ngx-moment';
import { SheetsRoutingModule } from './sheets-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureModule } from '../../../feature/feature.module';
import { SheetListComponent } from './sheet-list/sheet-list.component';

/**
 * Module pour mon Compte/Profil
 */
@NgModule({
    imports: [
        CommonModule,
        SheetsRoutingModule,
        MaterializeModule.forRoot(),
        FormsModule,
        FeatureModule,
        MaterialModule,
        ReactiveFormsModule,
        MomentModule,
        DirectiveModule,
        TranslateModule.forRoot(),
    ],
    declarations: [SheetsComponent, SheetDetailComponent, SheetListComponent],
    providers: [TimezoneService],
    exports: [
        SheetDetailComponent,
        SheetListComponent
    ]
})
export class SheetsModule { }
