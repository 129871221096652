export class Login {
  username: string;
  password: string;
  constructor() {

  }
  toSign() {
    return this.username + this.password
  }
}
