import { Teacher } from './../../../../model/teacher.model';
import { HttpParams } from '@angular/common/http';
import { ConversationService } from './../../../../service/conversation.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../service/authentification.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '../../../../../../node_modules/@angular/router';
@Component({
  selector: 'app-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss']
})
export class DesktopMenuComponent implements OnInit, OnDestroy {
  myUser: Teacher;
  defaultAvatar = environment.static + '/avatar.png';
  nb_unreadMessages = 0;
  @Output() goToLogout: EventEmitter<any> = new EventEmitter();
  constructor(
    private authenticationService: AuthenticationService,
    private conversationService: ConversationService,
  ) { }

  ngOnInit() {
    this.myUser = this.authenticationService.getCurrentPersona();
    this.conversationService.conversationList.subscribe(conversationList => {
      if (conversationList.length > 0) {
        this.nb_unreadMessages = conversationList.map(c => c.unread_teacher).reduce((acc, value) => Number(acc) + Number(value), 0);
      }
    })
  }
  ngOnDestroy() {
    this.nb_unreadMessages = 0;
  }



  logout() {
    this.goToLogout.emit();
  }

  checkAvailableLanguageSheets() {
    return this.authenticationService.checkAvailableLanguageSheets(this.myUser.teach_language.id);
  }

  checkFeaturePermission(groupName) {
    return this.authenticationService.checkFeaturePermission(groupName);
  }

}
