/**
 * sous-composant pour l'affichage d'une formation/training.
 */

import { Component, OnInit, Input } from '@angular/core';
import { StudentService } from '../../../../service/student.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  @Input() training;
  student: any;
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.trainings';

  constructor(private studentService: StudentService) { }

  ngOnInit() {
    if (this.training && this.training.student) {
      this.studentService.getObservableStudent(this.training.student)
        .subscribe(_student => {
          if (_student) {
            this.student = _student;
          }
        })
    }
  }

}
