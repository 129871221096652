<div translate class="page-title">teacher.invoices.title</div>
<div class="myaccount-wrapper">

  <ng-container *ngIf="pageIsReady; else elseTemplate">
    <div class="billinginfo-btn action-btn" [routerLink]="['./infos']">
      <b translate>teacher.invoices.billing-info-btn</b>
    </div>
    <!-- General Information -->
    <div class="generalinfo-wrapper">

      <div class="generalinfo-subwrapper">

        <div class="billing-explaination-wrapper">
          <!-- <span>For faster payments, please upload your invoices between the 1st and the 5th of each month.</span> -->
          <span translate>teacher.invoices.billing-explaination</span>
        </div>

        <div class="templates-wrapper">
          
            <span translate>teacher.invoices.select-invoice-template</span>
          
          
            <button (click)="downloadInvoiceTemmplate('Modele_Facture.pdf');">
              <span>FR</span>
            </button>
          
            <button (click)="downloadInvoiceTemmplate('Invoice_Model.pdf');">
              <span>EN</span>
            </button>
          
        </div>

        <!-- Current invoice -->
        <app-current-invoice [myTeacher]=myTeacher></app-current-invoice>

        <!-- Invoices list -->
        <div class="list-invoices" 
            infiniteScroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollThrottle]="throttle"
            [scrollWindow]="false"
            (scrolled)="onScrollDown()">
          <div *ngFor="let invoice of invoices; let i=index">
            <app-invoice [invoice]="invoice" [myTeacher]=myTeacher></app-invoice>
          </div>
        </div>

      </div>

    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="row center loading pageload-message">
      <span translate>teacher.invoices.billing-loading</span>
    </div>
  </ng-template>

</div>