import { TaskList } from './../model/taskList.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { Task } from '../model/task.model';


@Injectable()
export class TaskService extends KiwixiService {

    apiTask = environment.server + 'tasks/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService,
    ) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getTaskById(_id): Observable<Task> {
        const url = this.apiTask + _id + '/';
        return this.getTaskByUrl(url);
    }

    getTaskByUrl(_url): Observable<Task> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<Task>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getTask`))
            );
    }

    getTasks(params: HttpParams): Observable<TaskList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<TaskList>(this.apiTask, {
            headers: header,
            params: params
        })
            .map(_Tasks => _Tasks)
            .pipe(
                catchError(this.handleErrorObservable<TaskList>(`getObservableTasks`))
            );
    }

    postTask(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<Task>(this.apiTask, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Task>(`Création d'une tache`)),
            );
    }

    deleteTask(_id) {
        const headers = this.createHttpClientAuthorizationHeader();
        const url = this.apiTask + _id + '/';
        return this._http.delete<Task>(url, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Task>(`Suppression d'une tache`)),
            );
    }

    patchTask(_id, params) {
        const url = this.apiTask + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<Task>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Task>(`Création d'une tache`)),
            );
    }
}