import { Occurrence } from './../../model/occurrence.model';
import { Event } from './../../model/event.model';
import { Component, OnChanges, SimpleChanges, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

// Services
import { FormationService } from '../../service/formation.service';
import { SiteService } from '../../service/site.service';
import { OccurrenceService } from '../../service/occurrence.service';
import { EventService } from '../../service/event.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-select-time-row',
  templateUrl: './select-time-row.component.html',
  styleUrls: ['./select-time-row.component.scss'],
  providers: [FormationService, SiteService, OccurrenceService]
})
export class SelectTimeRowComponent implements OnChanges {
  @Input() dateSelect;
  @Input() myTeacher;

  rows: any[];
  events_indispo = [];
  events_dispo = [];
  occurrences = [];
  lastRowIndex = 48;
  loader = true;
  // ?
  position = 'before';

  constructor(
    private formationService: FormationService,
    private occurrenceService: OccurrenceService,
    private eventService: EventService,
    private siteService: SiteService) {
    this.rows = [];
  }

  /**
   * create the rows array for eache half hour
   */
  createTimePlanner() {
    for (let _i = 0; _i < 25; _i++) {
      if (_i < 24) {
        const _myTime1 = moment({ years: moment().year(), months: moment().month(), date: moment().date(), hours: _i, minutes: 0, seconds: 0, milliseconds: 0 });
        const _row1 = { state: '', time: _myTime1, label: '' };
        this.rows.push(_row1);
        const _myTime2 = moment({ years: moment().year(), months: moment().month(), date: moment().date(), hours: _i, minutes: 30, seconds: 0, milliseconds: 0 });
        const _row2 = { state: '', time: _myTime2, label: '' };
        this.rows.push(_row2);
      } else {
        const _myTime3 = moment({ years: moment().year(), months: moment().month(), date: moment().date(), hours: 24, minutes: 0, seconds: 0, milliseconds: 0 });
        const _row3 = { state: '', time: _myTime3, label: '' };
        this.rows.push(_row3);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createTimePlanner();
    let id_calendar;
    let id_unavaibility_calendar;
    // mise à jour à partir du teacher
    if (this.myTeacher && this.myTeacher.calendar) {
      id_calendar = this.eventService.extractIdFromUrl(this.myTeacher.calendar);
    }
    if (this.myTeacher && this.myTeacher.unavaibility_calendar) {
      id_unavaibility_calendar = this.eventService.extractIdFromUrl(this.myTeacher.unavaibility_calendar);
    }
    this.resetRows()

    let filterParams = [
      {
        key: 'calendar',
        value: id_calendar
      }
    ]
    const a$ = this.eventService.getEvents(filterParams)
    filterParams = [
      {
        key: 'calendar',
        value: id_unavaibility_calendar
      }
    ]
    const b$ = this.eventService.getEvents(filterParams)

    forkJoin([a$, b$]).subscribe(
      events => {
        // dispo
        this.events_dispo = events[0]['results'];
        // indispo
        this.events_indispo = events[1]['results'];
        if (this.dateSelect) {
          this.getLessonForDate();
        }
      }
    )
  }

  /**
   * vide les rows de leur etats pour re initialisation
   */
  resetRows() {
    this.rows = this.rows.map(item => {
      return { state: '', time: item.time, label: '' };
    })
  }

  /**
   * Liste des cours du jour
   */
  getLessonForDate() {
    this.loader = true;
    const date = moment([moment(this.dateSelect).year(), moment(this.dateSelect).month(), moment(this.dateSelect).date(), 0, 0, 0, 0]);
    const date_range = date.format('YYYY-MM-DD');

    const filterParams = [
      {
        key: 'date__range',
        value: date_range
      },
      {
        key: 'teacher',
        value: this.myTeacher.id
      },
      {
        key: 'page_size',
        value: 20
      }
    ]
    this.occurrenceService.getOccurrences(filterParams)
      .then(_occurrences => {
        this.occurrences = _occurrences['results'].filter(_occ =>
          _occ.status === 'booked'                        // On affiche les occurrences si le cours a été planifié
        );
        this.updateRowsForDate()
      })

  }

  normalizeRecHoursEvent(event: Event) {
    let new_start = moment(event.start)
    let new_end = moment(event.end)

    let start_hour = moment(event.start).hours()
    let end_hour = moment(event.end).hours()
    let start_minute = moment(event.start).minutes()
    let end_minute = moment(event.end).minutes()
    if (moment(event.start).day() !== moment(this.dateSelect).day()) {
      start_minute = 0;
      start_hour = 0;
    }
    if (moment(event.end).day() !== moment(this.dateSelect).day()) {
      end_minute = 59;
      end_hour = 23;
    }

    new_start = moment([moment(this.dateSelect).year(), moment(this.dateSelect).month(), moment(this.dateSelect).date(), start_hour, start_minute, 0, 0]);
    new_end = moment([moment(this.dateSelect).year(), moment(this.dateSelect).month(), moment(this.dateSelect).date(), end_hour, end_minute, 0, 0]);
    return { start: new_start, end: new_end }
  }

  /**
   * get avaibility and unavaibility for a date in list events
   * remplit les rows et leur etats à partir des occurences.
   */
  updateRowsForDate() {
    this.loader = true;
    // quel jour est le dateSelect pour trouver les bons events ?
    const current_day = moment(this.dateSelect);
    current_day.hour(0);
    current_day.minute(0);
    current_day.second(0);
    current_day.millisecond(0);

    this.events_dispo.forEach(dispo => {
      // Pour chaque dispo recurrente, on verifie que l'un des deux jours intersect le jour selectionné
      if (dispo.event_type === 'availability' && ((moment(dispo.start).day() === moment(this.dateSelect).day()) || (moment(dispo.end).day() === moment(this.dateSelect).day()))) {
        const normalizedHours = this.normalizeRecHoursEvent(dispo)
        const my_start = normalizedHours.start;
        const my_end = normalizedHours.end;
        const myStartEnd = this.extractRowsIdx(my_start.format(), my_end.format())
        // same days => apply dispo
        if (myStartEnd.start > myStartEnd.end) {
          myStartEnd.end = this.lastRowIndex;
        }
        for (let i = myStartEnd.start; i < myStartEnd.end; i++) {
          this.rows[i].state = 'dispo'
        }
      }
    });

    this.events_dispo.forEach(dispo => {
      if (dispo.event_type === 'ponctual_availability' && (moment(dispo.start).isSame(moment(this.dateSelect), 'day') || (moment(dispo.end).isSame(moment(this.dateSelect), 'day')))) {
        // dispo sans recurrence
        const dispo_date = moment(dispo.start);
        dispo_date.hour(0);
        dispo_date.minute(0);
        dispo_date.second(0);
        dispo_date.millisecond(0);
        const myStartEnd = this.extractRowsIdx(dispo.start, dispo.end)
        if (dispo_date.isSame(current_day) && !dispo.rule) {
          for (let i = myStartEnd.start; i < myStartEnd.end; i++) {
            this.rows[i].state = 'dispo'
          }
        }
      }
    });

    this.events_indispo.forEach(dispo => {
      if (dispo.event_type === 'ponctual_unavailability' && (moment(dispo.start).isSame(moment(this.dateSelect), 'day') || (moment(dispo.end).isSame(moment(this.dateSelect), 'day')))) {
        const normalizedHours = this.normalizeRecHoursEvent(dispo)
        const my_start = normalizedHours.start;
        const my_end = normalizedHours.end;

        // same days => apply indispo
        const myStartEnd = this.extractRowsIdx(my_start.format(), my_end.format())
        for (let i = myStartEnd.start; i < myStartEnd.end; i++) {
          this.rows[i].state = 'indispo'
        }
      }
    });

    // ajout de mes occurences de cours
    this.occurrences.forEach(cours => {
      const myStartEnd = this.extractRowsIdx(cours.start, cours.end);
      // update rows from start to end
      for (let i = myStartEnd.start; i < myStartEnd.end; i++) {
        this.rows[i].state = 'cours'
        this.rows[i]['occurence'] = cours;
      }
      // update label and resume
    });

    // on update les labels =>
    for (let _i = 0; _i < 48; _i++) {
      const init_state = this.rows[_i].state;
      const j = _i;
      _i = _i + 1;
      let state = this.rows[_i].state;
      while (state === init_state && _i < 48 && _i++) {
        state = this.rows[_i].state;
      }
      _i--;
      switch (init_state) {
        case 'cours':
          this.rows[j].label = 'cours de ' + this.rows[j].time.format('HH:mm') + ' a ' + this.rows[_i + 1].time.format('HH:mm');
          // update resume
          const occ: Occurrence = this.rows[j]['occurence'];
          const _label = 'Cours avec ' + occ.student.user.first_name.charAt(0) + '. '
            + occ.student.user.last_name + ' : '
            + this.rows[j].time.format('HH:mm') + ' - ' + this.rows[_i + 1].time.format('HH:mm');
          for (let k = j; k <= _i; k++) {
            this.rows[k]['resume'] = _label;
          }
          break;
        case 'indispo':
          this.rows[j].label = 'indispo de ' + this.rows[j].time.format('HH:mm') + ' a ' + this.rows[_i + 1].time.format('HH:mm');
          break;
        case 'dispo':
          this.rows[j].label = 'dispo de ' + this.rows[j].time.format('HH:mm') + ' a ' + this.rows[_i + 1].time.format('HH:mm');
          break;
        default:
          // this.rows[j].label = 'plop';
          break;
      }
    }
    this.loader = false;
  }

  extractRowsIdx(start, end) {
    let dstart = moment(start).get('hours') * 60 + moment(start).get('minutes');
    dstart = Math.ceil(dstart / 30);
    let dend = moment(end).get('hours') * 60 + moment(end).get('minutes');
    dend = Math.ceil(dend / 30);
    return { start: dstart, end: dend }
  }

}
