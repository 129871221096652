import { Conversation } from './../../../../model/conversation.model';
import { Message } from './../../../../model/message.model';
import { HttpParams } from '@angular/common/http';
import { ConversationService } from './../../../../service/conversation.service';
import { Occurrence } from './../../../../model/occurrence.model';
import { Session } from './../../../../model/session.model';
import { Teacher } from './../../../../model/teacher.model';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { UpcomingService } from '../../../../service/upcoming.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../../../service/authentification.service';
import { SessionService } from '../../../../service/session.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

/**
 * Composant d'affichage d'un upcoming.
 */
@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss']
})
export class UpcomingComponent implements OnInit, OnDestroy {
  @Input() upcoming: Occurrence;
  @Input() myIndex;
  @Input() isTeacherInterface;
  student = null;
  formationId = null;
  nextSession = null;
  prevSession: Session = null;
  showUpcoming = false;
  editMessage = false;
  myUser: Teacher = null;
  conversation: Conversation = null;
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.upcoming';
  myMessageCtrl = new FormControl();
  sheetPermission = false;
  isSheetLanguageAvailable = false;
  @Output() displayUpcoming = new EventEmitter();
  messageForm: FormGroup = this.builder.group({
    message: this.myMessageCtrl
  });
  formChanges = false;
  showInfos = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private upcomingService: UpcomingService,
    private sessionService: SessionService,
    private authenticationService: AuthenticationService,
    private conversationService: ConversationService,
    private builder: FormBuilder,
  ) { }

  ngOnInit() {
    this.checkSheetsPermission();
    this.myUser = this.authenticationService.getCurrentPersona();
    if (this.myUser) {
      if (this.upcoming && this.upcoming.teacher) {
        this.formationId = this.upcomingService.extractIdFromUrl(this.upcoming.formation)
        this.nextSession = this.getNextSession();
        this.student = this.upcoming.student;
        this.checkAvailableLanguageSheets();
        if (this.messageForm) {
          this.messageForm.patchValue(this.upcoming)
          this.subcribeToFormChanges();
        }
        this.displayUpcoming.emit();
        this.getConversationInformation();
        this.showUpcoming = true;
      }
    }

    if (this.myIndex === 0 && this.upcoming.is_next && !this.isTeacherInterface) {
      this.showInfos = true;
      this.getPreviousSessionDetail();
    }
  }

  ngOnDestroy() {
  }

  // écouteur si le message change.
  subcribeToFormChanges() {
    this.messageForm.valueChanges.subscribe(data => {
      this.formChanges = true;
    })
  }

  getNextSession() {
    if (this.upcoming.is_next === true) {
      return this.upcoming.session;
    } else {
      return null;
    }
  }

  /**
   * mise à jour du prochain cours
   * @param field
   */
  updateNextSession(field) {
    const param = {};
    if (this.formChanges) {
      param[field] = this.messageForm.value.message;
      if (this.nextSession) {
        this.sessionService.patchSession(this.nextSession.url, param)
          .then(res => {
            this.nextSession.message = res['message'];
            this.editMessage = false;
            this.formChanges = false;
            this.upcomingService.toastService.show(
              `Message Sauvegardé`,
              1000,
              'green'
            )
          });
      }
    } else {
      this.editMessage = false;
      this.upcomingService.toastService.show(
        `Pas de message Sauvegardé`,
        1000,
        'red'
      )
    }
  }

  // navigation vers reschedule.
  goToReschedule() {
    const lessOneDay = moment(this.upcoming.start).diff(moment(Date.now()))
    const duration = moment.duration(lessOneDay);
    if (duration.asHours() > 24) {
      this.router.navigate(['upcoming', 'reschedule', this.upcoming.id]);
    } else {
      this.upcomingService.toastService.show('Il est impossible d\'annuler un cours moins de 24 h avant le début du cours', 3000, 'red');
    }
  }

  showSheet(object) {
    this.router.navigate(['./sheets/sheet/' + object.sheetId + '/student/' + this.student.id], { relativeTo: this.route });
  }

  checkAvailableLanguageSheets() {
    this.isSheetLanguageAvailable = this.authenticationService.checkAvailableLanguageSheets(this.myUser.teach_language.id);
  }

  checkSheetsPermission() {
    this.sheetPermission = this.authenticationService.checkFeaturePermission('sheets');
  }

  getPreviousSessionDetail() {
    if (!this.prevSession && this.upcoming.session) {
      if (this.upcoming.session.previous_session) {
        this.sessionService.getObservableSessionByUrl(this.upcoming.session.previous_session).subscribe(result => {
          if (result) {
            this.prevSession = result;
          }
        });
      }
    }
  }

  getConversationInformation() {
    let params = new HttpParams()
    params = params.append('teacher', this.myUser.id.toString())
    params = params.append('student', this.upcoming.student.id.toString())

    this.conversationService.getConversations(params).subscribe(conversations => {
      if (conversations.results.length > 0) {
        this.conversation = conversations.results[0];
      }
    });
  }

  goToConversation() {
    if (this.conversation) {
      this.router.navigate(['/messages/conversation/' + this.conversation.id + '/student/' + this.student.id]);
    }
  }

  showInfo() {
    if (this.upcoming.is_next) {
      this.getPreviousSessionDetail();
      this.showInfos = !this.showInfos;
    }
  }

}
