<div class="past-container">
  <div class="mobile-nodisplay section-title" translate>lingueopro.title.skype</div>
</div>
<div id="create-plan" *ngIf="showScreen" class="setup-training-wrap create-container container recurrencies-pick-block">
  <div class="decorative-border">
    <div class="inner-container">
      <div class="center">
        <div class="text-intro-plan-formation">
          <div translate *ngIf="formation.mode_formation=='standard'">lingueopro.plan-formation.information.title</div>
          <div translate *ngIf="formation.mode_formation=='lilate'">lingueopro.plan-formation.information.title-lilate</div>
          <div *ngIf="!student.user.id_skype" translate>lingueopro.plan-formation.information.no-skype-loaded</div>
          <div *ngIf="student.user.id_skype"><br />
            <span translate *ngIf="formation.mode_formation=='standard'">lingueopro.plan-formation.information.skype-loaded</span>
            <span translate *ngIf="formation.mode_formation=='lilate'">lingueopro.plan-formation.information.skype-loaded-lilate</span>
          </div>
        </div>
        <form >
            <input class="skypeid-input" matInput [formControl]="skypeFormControl">
            <mat-error *ngIf="skypeFormControl.touched && (skypeFormControl.hasError('required') || skypeFormControl.hasError('whitespace'))">
              <strong translate>lingueopro.plan-formation.information.skype_error</strong>
            </mat-error>
        </form>

        <div class="setupskype-help">
          <b translate>lingueopro.plan-formation.information.aide</b>
          <div class="row text-intro-plan-formation" translate>lingueopro.plan-formation.information</div>
          <a href="https://lingueocustomers.crisp.help/fr/article/comment-telecharger-et-creer-un-compte-skype-1luq4u2/" target="_blank" translate>lingueopro.plan-formation.information.Comment installer Skype ?</a>
          <a href="https://lingueocustomers.crisp.help/fr/article/comment-trouver-mon-identifiant-skype-15pw9lo/?bust=1629797524979" target="_blank" translate>lingueopro.plan-formation.information.Comment vérifier mon identifiant Skype ?</a>
        </div>


      </div>
    </div>
  </div>
</div>