import { ReportTeacherBillingExpected } from './../model/reportTeacherBillingExpected.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import * as moment from 'moment';
import { ReportTeacherAvailabilities } from '../model/reportTeacherAvailabilities.model';

@Injectable()
export class ReportService extends KiwixiService {
    apiReport = environment.server + 'reports/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    getReportTeacherAvailabilities(params: HttpParams): Observable<ReportTeacherAvailabilities[]> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ReportTeacherAvailabilities[]>(this.apiReport + 'teachers_availabilities/', {
            headers: header,
            params: params
        })
            .map(reports => reports)
            .pipe(
                catchError(this.handleErrorObservable<ReportTeacherAvailabilities[]>(`getReportTeacherAvailabilities`))
            );
    }

    getReportTeacherBillingExpected(params: HttpParams): Observable<ReportTeacherBillingExpected[]> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ReportTeacherBillingExpected[]>(this.apiReport + 'teachers_billing_expected/', {
            headers: header,
            params: params
        })
            .map(reports => reports)
            .pipe(
                catchError(this.handleErrorObservable<ReportTeacherBillingExpected[]>(`getReportTeacherBillingExpected`))
            );
    }

    postExport(params, languageLabel) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post(this.apiReport + 'teachers_availabilities/export/', params, { headers: headers, responseType: 'arraybuffer' })
            .map((file) => {
                const myFile = new Uint8Array(file);
                const blob = new Blob([myFile], { type: 'application/vnd.ms-excel' });
                const url = window.URL.createObjectURL(blob);
                const $a = $('<a />', {
                    'href': url,
                    'download': 'export-dispos-' + languageLabel,
                    'text': 'click'
                }).hide().appendTo('body')[0].click();
            },
                error => this.toastService.show('Erreur postExport !!!', 4000, 'red'))
            .toPromise();
    }
}
