import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sheets',
  templateUrl: './sheets.component.html',
  styleUrls: ['./sheets.component.scss']
})
export class SheetsComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
  }
}
