import { FormationService } from './../../../../service/formation.service';
import { ProfilePhotosService } from './../../../../service/profilePhotos.service';
import { InitAppService } from './../../../../service/initapp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Student } from './../../../../model/student.model';
import { Formation } from './../../../../model/formation.model';
import { Component, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-photos-profile-update',
  templateUrl: './photos-profile-update.component.html',
  styleUrls: ['./photos-profile-update.component.scss',
    '../../plan-formation/schedule/schedule.component.scss',
    '../../plan-formation/plan-formation.component.scss',
    '../../plan-formation/schedule/schedule.ux.scss',
    '../../plan-formation/plan-formation.ux.scss']
})
export class PhotosProfileUpdateComponent implements OnInit, OnChanges {
  formation_id;
  formation: Formation
  student: Student = null;
  showScreen = false; s
  showWebcamModule = false;
  showWarningPhotoProfile = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private initAppService: InitAppService,
    private profilePhotosService: ProfilePhotosService,
    private formationService: FormationService,
  ) { }

  ngOnInit() {
    this.formation_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.initAppService.initApp(true, this.formation_id);
    this.checkPhotoProfile();
    this.formationService.currentFormationSubject.subscribe(formation => {
      if (formation) {
        this.student = formation.student;
        this.formation = formation;
        this.showScreen = true;
      }
    });

  }

  ngOnChanges() {
    this.checkPhotoProfile();
  }

  checkPhotoProfile() {
    if (history.state.photoProfileRequired) {
      this.showWarningPhotoProfile = true;
    }
  }

  uploadFile($event) {
    for (const file of $event.target.files) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      if (this.profilePhotosService.authorizedExtension.indexOf(ext.toLowerCase()) !== -1) {
        this.sendFile(file);
      } else {
        this.profilePhotosService.toastService.show('Fomat de fichier invalide, JPG ou PNG autorisé !', 4000, 'red');
      }
    }
  }

  sendFile(file) {
    this.profilePhotosService.postProfilePhoto(this.student.user, file, null).subscribe(photoProfile => {
      this.student.user.photo_profile = photoProfile;
      this.student.user.photo_profile_validation_requested = true;
    })
  }

}
