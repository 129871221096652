import { AssignSheetComponent } from './detail/next/assign-sheet/assign-sheet.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
import { TrainingsComponent } from './trainings.component';
import { DetailComponent } from './detail/detail.component';
import { InfoComponent } from './detail/info/info.component';
import { NextComponent } from './detail/next/next.component';
import { PastComponent } from './detail/past/past.component';
import { ListComponent } from './list/list.component';
import { TeacherProfilComponent } from './teacher-profil/teacher-profil.component';
import { ConversationComponent } from '../messages/conversation/conversation.component';
import { DocumentsComponent } from '../documents/documents.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';
// from module

export const routes: Routes = [
  {
    path: 'trainings',
    component: TrainingsComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: '', component: ListComponent, canActivate: [AuthGuard] },
      { path: 'teacher-profil/:id', component: TeacherProfilComponent },
      {
        path: 'detail/:id_formation',
        component: DetailComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'info', component: InfoComponent },
          {
            path: 'next', component: NextComponent,
          },
          { path: 'past', component: PastComponent },
          { path: 'conversation/:id_conversation/student/:id_student', component: ConversationComponent, data: { 'persona': 'student', 'training': true } },
          {
            path: 'conversation/:id_conversation/student/:id_student/documents',
            component: DocumentsComponent, canActivate: [AuthGuard], data: { 'persona': 'student', 'sharing': true, 'training': true }
          },

        ]
      },
      {
        path: 'assign-study-sheet/occurrence/:id', component: AssignSheetComponent,
      },
    ]
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TrainingsRoutingModule { }
