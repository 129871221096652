import { InvoiceService } from './../../../../../../service/invoice.service';
import { InvoiceLogExportService } from './../../../../../../service/invoiceLogExport.service';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { invoiceLogExport } from './../../../../../../model/invoiceLogExport.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-invoice-export-list',
  templateUrl: './invoice-export-list.component.html',
  styleUrls: ['./invoice-export-list.component.scss']
})
export class InvoiceExportListComponent implements OnInit {
  displayedColumns: string[] = ['exportID', 'nbInvoice', 'dateExport', 'bundleStatus']
  dataSource: MatTableDataSource<invoiceLogExport> = new MatTableDataSource([]);
  isLoadingResults = false;
  noData = this.dataSource.connect().pipe(map(data => data.length === 0));
  my_timeout = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private invoiceLogExportService: InvoiceLogExportService,
    private invoiceService: InvoiceService,
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getData()
    this.my_timeout = Observable.interval(60000)          // TODO voir si perte de memoire?
      .subscribe(i => {
        this.checkInProgressBundle();
      })

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getData() {
    this.isLoadingResults = true;
    let params = new HttpParams();
    params = params.append('is_paid', 'true');
    params = params.append('salt', Math.random().toString());
    this.invoiceLogExportService.getInvoiceLogExport(params).subscribe(data => {
      if (data['results']) {
        this.dataSource = new MatTableDataSource(data['results']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoadingResults = false;
      }
    });
  }

  getExportLogById(invoiceLogExport) {
    this.invoiceLogExportService.getInvoiceLogExportById(invoiceLogExport).subscribe(invoiceLogExport => {
      this.dataSource.data.map(i => {
        if (i.id == invoiceLogExport.id) {
          i.bundle_creation_status = invoiceLogExport.bundle_creation_status;
          i.bundle_created_at = invoiceLogExport.bundle_created_at;
          i.bundle_filename = invoiceLogExport.bundle_filename;
        }
      })

    })
  }

  checkInProgressBundle() {
    this.dataSource.data.map(invoiceLogExport => {
      if (invoiceLogExport.bundle_creation_status === 'in_progress') {
        this.getExportLogById(invoiceLogExport);
      }
    })
  }



  createBundle(invoiceLogExport: invoiceLogExport) {
    this.invoiceLogExportService.createBundle(invoiceLogExport).subscribe(invoiceLogExport => {
      this.dataSource.data.map(i => {
        if (i.id == invoiceLogExport.id) {
          i.bundle_creation_status = 'in_progress';
        }
      })
    });
  }

  downloadBundle(invoiceLogExport: invoiceLogExport) {
    const url = invoiceLogExport.url + "download_bundle_file/"
    this.invoiceLogExportService.downloadBundle(url, invoiceLogExport.bundle_filename, 'application/zip').then(res => {
    })
  }

}
