/**
 * Composant pour l'édition d'un teacher.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Language } from '../../../../../model/language.model';
import { Site } from '../../../../../model/site.model';
import { Teacher } from '../../../../../model/teacher.model';
import { TimezoneService } from '../../../../../service/timezone.service';
import { TeacherService } from '../../../../../service/teacher.service';
import { LanguageService } from '../../../../../service/language.service';
import { SiteService } from '../../../../../service/site.service';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-teacher',
  templateUrl: './edit-teacher.component.html',
  styleUrls: ['./edit-teacher.component.scss', './edit-teacher.ux.scss', '../../mainscreen.ux.scss'],
  providers: [TimezoneService]
})
export class EditTeacherComponent implements OnInit, OnDestroy {
  languages: Language[];                                    // Liste des languages.
  sites: Site[];                                            // Liste des sites.
  sessionSites: Site[];                                     // Liste des domaines.
  myTeacher: Teacher;                                       // teacher à éditer.
  teaching = false;                                         // Boolean pour afficher le volet 'Enseignement'.
  defaultAvatar = environment.static + '/avatar.png';       // avatar par défaut.
  filteredLanguagesList = [];                               // liste filtrée des languages.
  selectedLanguage = '';                                    // language selectionnée.
  filteredSpokenLanguagesList = [];                         // liste filtrée des languages parlés.
  selectedSpokenLanguage = '';                              // langue parlée selectionnée.
  countPagesLanguages = 1;                                  // paginateur des languages.
  myTeacherService;                                         // variable pour le service teacher.

  constructor(
    private timezoneService: TimezoneService,
    private route: ActivatedRoute,
    private teacherService: TeacherService,
    private languageService: LanguageService,
    private siteService: SiteService
  ) { }

  ngOnInit() {
    this.sites = [];
    this.sessionSites = [];
    this.languages = [];
    this.route.params.subscribe(params => {
      this.myTeacherService = this.teacherService.getObservableTeacherById(params['id'])
        .subscribe(_teacher => {
          if (_teacher) {
            this.myTeacher = _teacher;
            if (this.myTeacher.status === 'accepted') {
              this.teaching = true;
            }
            this.getAllLanguages(1);
            this.getAllSites(1, this.myTeacher.teach_on);
            this.timezoneService.getTimezones({});
          }
        })
    });
  }

  ngOnDestroy() {
    if (this.myTeacherService) {
      this.myTeacherService.unsubscribe();
    }
  }

  /**
   * methode pour construire la liste des domaines.
   * @param site
   * @param sites
   */
  setDomainList(site, sites) {
    for (let i = 0; i < sites.length; i++) {
      if (site.url === sites[i].url) {
        this.sessionSites.push(site);
      }
    }
  }

  /**
   * méthode pour récuperer tous les languages.
   * @param _countPagesLanguages
   */
  getAllLanguages(_countPagesLanguages) {
    const _filterParams = [
      {
        key: 'page',
        value: _countPagesLanguages.toString()
      }
    ]
    this.languageService.getLanguages(_filterParams)
      .then(_languageData => {
        for (const language of _languageData['results']) {
          this.languages.push(language)
        }
      });
  }

  /**
   * Méthode pour récupérer tous les sites.
   * @param _countPagesSites
   * @param _sites
   */
  getAllSites(_countPagesSites, _sites) {
    this.siteService.getSites()
      .then(_siteData => {
        for (const site of _siteData['results']) {
          if (site.available_formation) {
            this.sites.push(site)
            this.setDomainList(site, _sites)
          }
        }
      })
      .catch(this.siteService.handleError);
  }

}
