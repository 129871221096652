<div class="row information-container test-main">
  <div class="row">
    <div class="left">
      <h6 class="center grey darken-1 white-text title-information">Question {{ position + 1 }}</h6>
    </div>
    
    <div class="quizz-deletebtn" (click)="deleteQuestion(question.id)" mz-button>
    </div>
    
  </div>
  <div class="row information-subcontainer">
    <div class="row flexparent">
      <span class="quizz-fieldname flexchild nogrow"> Enoncé : </span>
      <textarea 
          [formControl]="label"
          (focusout)="patchAnswer('label')"
          rows="8" cols="80" placeholder="Enoncé">
      </textarea>  
      <mat-error *ngIf="label.touched && label.hasError('required')">Ce champs est requis</mat-error>
    </div>

  <div class="quizzmedia flexparent centeralign">
    <span class="quizz-fieldname flexchild nogrow"> Média : </span><app-manage-exercices-medias [mediaType]="'exercicequestion'" [object]="question"></app-manage-exercices-medias>
  </div>

    <ng-template #goodanswercontainer></ng-template>

    <ng-template #badanswercontainer></ng-template>

    <div class="row">
      <div class="action-button addfalse-button" (click)="addAnwser(false)" mz-button>
        Ajouter une mauvaise réponse
        <i mz-icon [icon]="'control_point'" [align]="'left'" [size]="'tiny'"></i>
      </div>
    </div>
  </div>
</div>