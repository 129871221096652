import { PreviousRouteService } from './../../../../service/previous-route.service';
import { OccurrenceService } from './../../../../service/occurrence.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Occurrence } from './../../../../model/occurrence.model';
import { Component, OnInit } from '@angular/core';
import { ExerciceSheet } from '../../../../model/exerciceSheet.model';

@Component({
  selector: 'app-assign-sheet',
  templateUrl: './assign-sheet.component.html',
  styleUrls: ['./assign-sheet.component.scss']
})
export class AssignSheetComponent implements OnInit {
  occurrence: Occurrence = null;
  sheet: ExerciceSheet = null;
  previousUrl
  constructor(
    private route: ActivatedRoute,
    private occurrenceService: OccurrenceService,
    private router: Router,
    private previousRouteService: PreviousRouteService,

  ) { }

  ngOnInit() {
    this.previousUrl = this.previousRouteService.getPreviousUrl();
    const occurrenceId = this.route.snapshot.params.id;
    this.occurrenceService.getObservableOccurenceById(occurrenceId).subscribe(data => {
      if (data) {
        this.occurrence = data;
      }
    })
  }

  showSheet(object) {
    if (object.mode === 'mobile') {
      this.router.navigate(['./sheets/sheet/' + object.sheet.id], { relativeTo: this.route });
    } else {
      this.sheet = object.sheet
    }
  }
}
