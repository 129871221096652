<b class="page-title" translate>{{translatePath}}.reports-title</b>
<div class="reports-wrapper">
  <div class="pending-counter" *ngIf="pendings.length == 0" translate></div>
  <ng-container>
    <!-- title -->
    <div *ngIf="loading_pending==true" class="pending-counter">
      Loading Pending Reports                   
      <mat-spinner class="loader"></mat-spinner>
    </div>
    <div *ngIf="loading_pending==false"class="pending-counter">
      {{countPendings}}
      <span translate>{{translatePath}}.count-pending</span>
      {{lastUpcomingDate}}
    </div>
    
    <!-- Pendings -->
    <div class="pendinglist-wrapper" class="pending-wrapper" >
      <div *ngFor="let pending of pendings">
          <app-report [showStudentName]="true" [occurrence]="pending"></app-report>
      </div>
      <div *ngIf="loading_pending==true" class="loading-text">
          Loading Pending Reports                   
          <mat-spinner class="loader"></mat-spinner>
        </div>
      <div *ngIf="nextPagesPending" (click)="getPendings()" class="more">more</div>
    </div>
  </ng-container>  

  <!-- search completed reports -->
  <div class="done-reports-space">
      <div class="page-title" translate>{{translatePath}}.completed-reports</div>
      <div class="searchbar-block">
          <input class="docs-searchbar" type="text" [formControl]="searchControl">
          <div class="delete-icon" (click)="resetSearch()"></div>
      </div>

      <div class="donelist-wrapper">
      <!--{{ ratingStudents.length }}-->
        <div *ngFor="let ratingStudent of ratingStudents">
          <div (click)="goToInfo(ratingStudent)" class="done-report">
            <app-report [showStudentName]="true" [ratingStudent]="ratingStudent"></app-report>
          </div>
        </div>
        <div *ngIf="loadingCompletedReport==true" class="loading-text">
          Loading Completed Reports                   
          <mat-spinner class="loader"></mat-spinner>
        </div>
        <div *ngIf="nextPagesCompleted"(click)="getFilterCompletedReports()" class="more">More</div>
      </div>
    </div>
</div>