import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExerciceSheet } from '../../model/exerciceSheet.model';

@Component({
  selector: 'feature-sheet-related',
  templateUrl: './sheet-related.component.html',
  styleUrls: ['./sheet-related.component.scss']
})
export class SheetRelatedComponent implements OnInit {
  @Input() sheet: ExerciceSheet;
  @Output() sheetToShow = new EventEmitter();
  
  constructor(
    ) { }

  ngOnInit() {
  }

  emitChangeSheet(sheetId: number): void {
    this.sheetToShow.emit(sheetId);
  }
}
