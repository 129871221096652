import { ExerciceSheetList } from './../model/exerciceSheetList.model';
import { ExerciceSheet } from './../model/exerciceSheet.model';
import { ExerciceSheetListFilter } from '../model/exerciceSheetListFilter.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class ExerciceSheetService extends KiwixiService {

    apiExerciceSheet = environment.server + 'exercices_sheets/';
    apiExerciceSheetResult = environment.server + 'exercices_sheets_results/';

    public filterExerciceSheetListing: BehaviorSubject<ExerciceSheetListFilter> = new BehaviorSubject<ExerciceSheetListFilter>(null);

    cecrl_LEVEL = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    setFilterListing(data) {
        const exercicesFilter: ExerciceSheetListFilter = new ExerciceSheetListFilter;
        exercicesFilter.id = data.id;
        exercicesFilter.title_fr = data.title_fr;
        exercicesFilter.language = data.language;
        exercicesFilter.cecrl_level = data.cecrl_level;
        exercicesFilter.category = data.category;
        exercicesFilter.specific_content = data.specific_content;
        exercicesFilter.status = data.status;
        this.filterExerciceSheetListing.next(exercicesFilter);
    }
    getcecrlLevel() {
        return this.cecrl_LEVEL;
    }

    getExerciceSheetById(_id, studentId = null): Observable<ExerciceSheet> {
        let url = this.apiExerciceSheet + _id + '/?salt=' + Math.random();
        if (studentId) {
            url += '&is_validate_by_student=' + studentId;
        }
        return this.getExerciceSheetByUrl(url);
    }

    getExeciceSheetByIdWithStudentsResults(sheetId, studentId): Observable<ExerciceSheet> {
        const url = this.apiExerciceSheet + sheetId + '/?is_validate_by_student=' + studentId + '&is_todo_by_student=' + studentId + '&salt=' + Math.random();
        return this.getExerciceSheetByUrl(url);
    }

    getExerciceSheetByUrl(_url): Observable<ExerciceSheet> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceSheet>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getExerciceSheet`))
            );
    }

    getExerciceSheets(_filterParams): Promise<ExerciceSheet[]> {
        let params = new HttpParams();
        params = params.set('salt', Math.random().toString());
        // ordering:
        if (_filterParams['ordering']) {
            const f = _filterParams['ordering'];
            params = params.set(f.key, f.value);
        }
        if (_filterParams['filtering']) {
            for (const filterParam of Object.keys(_filterParams['filtering'])) {
                params = params.set(filterParam, _filterParams['filtering'][filterParam]);
            }
        }
        if (_filterParams['page']) {
            const _page = _filterParams['page'];
            params = params.set(_page.key, _page.value);
        }
        const headers = this.createHttpClientAuthorizationHeader();

        return this._http.get(this.apiExerciceSheet, { headers: headers, params: params })
            .toPromise()
            .then((res: any) => res)
            .catch(this.handleError);
    }

    getObservableExerciceSheets(params: HttpParams): Observable<ExerciceSheetList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<ExerciceSheetList>(this.apiExerciceSheet, {
            headers: header,
            params: params
        })
            .map(_ExerciceSheets => _ExerciceSheets)
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSheetList>(`getObservableExerciceSheets`))
            );
    }

    getActivatedFilter(page_size, page, orderingField, orderingDirection) {
        let _params = new HttpParams();
        _params = _params.set('salt', Math.random().toString());

        if (this.filterExerciceSheetListing.value) {
            if (this.filterExerciceSheetListing.value.id) {
                _params = _params.set('id', this.filterExerciceSheetListing.value.id.toString());
            }
            if (this.filterExerciceSheetListing.value.title_fr) {
                _params = _params.set('title_fr__icontains', this.filterExerciceSheetListing.value.title_fr);
            }
            if (this.filterExerciceSheetListing.value.language) {
                _params = _params.set('language', this.filterExerciceSheetListing.value.language.toString());
            }
            if (this.filterExerciceSheetListing.value.cecrl_level) {
                _params = _params.set('cecrl_level', this.filterExerciceSheetListing.value.cecrl_level);
            }
            if (this.filterExerciceSheetListing.value.category) {
                _params = _params.set('category', this.filterExerciceSheetListing.value.category.toString());
            }
            if (this.filterExerciceSheetListing.value.specific_content) {
                _params = _params.set('group_name', this.filterExerciceSheetListing.value.specific_content.toString());
            }
            if (this.filterExerciceSheetListing.value.status) {
                _params = _params.set('status', this.filterExerciceSheetListing.value.status);
            }
        }
        _params = _params.set('page', page + 1);
        if (orderingDirection === 'desc') {
            orderingField = '-' + orderingField;
        }
        _params = _params.set('ordering', orderingField);

        _params = _params.set('page_size', page_size);
        return _params;
    }

    getExerciceSheetsByFilter(page_size, page, orderingField, orderingDirection): Observable<ExerciceSheetList> {
        const _params = this.getActivatedFilter(page_size, page, orderingField, orderingDirection);
        return this.getObservableExerciceSheets(_params);
    }

    getExerciceSheetsStatus() {
        return [
            {
                label: 'Brouillon',
                code: 'draft',
                valid: true
            },
            {
                label: 'A valider',
                code: 'moderate',
                valid: true
            },
            {
                label: 'Publiée',
                code: 'published',
                valid: true
            },
            {
                label: 'Désactivée',
                code: 'disabled',
                valid: true
            }
        ];
    }
    bulkUpdate(new_status, isModeAllPage, allIds, selectedSheets): Observable<any> {
        const params = {}
        params['selected_sheets'] = selectedSheets;
        params['new_status'] = new_status;
        return this.postBulkExerciceSheet(params);
    }
    postBulkExerciceSheet(body) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post(this.apiExerciceSheet + 'bulkupdate/', body, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable(`Modification par lot des factures`)),
            );
    }

    postExerciceSheet(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<ExerciceSheet>(this.apiExerciceSheet, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSheet>(`Création d'une fiche`)),
            );
    }

    patchExerciceSheet(_id, params) {
        const url = this.apiExerciceSheet + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<ExerciceSheet>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSheet>(`Création d'une fiche`)),
            );
    }

    patchFile(exerciceSheet: ExerciceSheet, file: File) {
        const url = this.apiExerciceSheet + exerciceSheet.id + '/';
        const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
        headers.delete('Content-Type');
        const _formData: FormData = new FormData();
        _formData.append('enctype', 'multipart/form-data');
        _formData.append('pdf', file);
        _formData.append('name', file.name);
        return this._http.patch<ExerciceSheet>(url, _formData, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<ExerciceSheet>(`Création d'une photo de profile`)),
            );
    }
}
