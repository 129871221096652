import { Site } from './site.model';
import { Language } from './language.model';

export class NewStudent {
  avatar: string;
  firstname: string;
  lastname: string;
  email: string;
  id_skype: string;
  gender: string;
  timezone: string;
  site: string;
  main_language: Language;
  password: string;
  password_generate: boolean;
  user: string;
  sites: Site[];
}
