import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { FormationService } from '../../../../../service/formation.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ExportCdcCertificationLog } from '../../../../../model/exportCdcCertificationLog.model';

@Component({
  selector: 'app-export-formation-detail-error',
  templateUrl: './export-formation-detail-error.component.html',
  styleUrls: ['./export-formation-detail-error.component.scss', '../../mainscreen.ux.scss']
})
export class ExportCdcCertificationDetailErrorComponent implements OnInit {
  export: ExportCdcCertificationLog;
  environment = environment;
  displayedColumns: string[] = ['formationID', 'student', 'formation', 'errors'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  nbErrors = 0;
  formationLogExport;
  isLoadingCheck = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private foramtionService: FormationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!history.state.export) {
      this.router.navigate(['export/cdc/list']);
      return;
    }
    this.export = history.state.export;
    this.getExport();
  }



  getExport() {
    this.nbErrors = 0;
    this.foramtionService.getExportById(this.export.id).subscribe(data => {
      this.isLoadingCheck = false;
      if (data.errors.length > 0) {
        this.dataSource = new MatTableDataSource(data.errors.filter(obj => obj.is_valid_export_certifications_cdc.valid === false));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        data.errors.map(formation => this.nbErrors += formation.is_valid_export_certifications_cdc.errors.length)
      }
    });
  }
}
