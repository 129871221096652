import { Achievement } from './achievement.model';
import { Event } from './event.model';
import { ExerciceSpecificContent } from './exerciceSpecificContent.model';
import { User } from './user.model';
import { Message } from './message.model';
import { Language } from './language.model';
import { Invoice } from './invoice.model';

export class Teacher {
  id: number;
  show: boolean;
  messages: Message[];
  new_messages: number;
  url: string;
  user: User;
  avatar: string;
  teach_language: Language;
  specific_content: ExerciceSpecificContent[];
  languages: Language[];
  teach_on: Language[];
  score: number;
  rank: number;
  updated_at: Date;
  created_at: Date;
  address: string
  tax_status_number: string
  accept_students: boolean
  description_fr: string;
  description_vo: string;
  id_skype_pro: string;
  id_card: string;
  tax_proof: any;
  service_contract: string;
  paypal_email: string;
  iban: string;
  bic: string;
  preferred_payment: string;
  status: string;
  generic_hourly_rate: number;
  professionnal_hourly_rate: number;
  site: string;
  calendar: string;
  unavaibility_calendar: string;
  phone: string;
  mobile_phone: string;
  edit_delete: boolean;
  events: Event[];
  missing_invoices: Invoice[];
  _cache_status: string;
  _last_update_availability: Date;
  _last_update_cache: Date;
  average_score_rating: number;
  achievements: Achievement[];
  cv: string;
  constructor() { }
}
