import { AuthenticationService } from './authentification.service';
import { Achievement } from './../model/achievement.model';
import { AchievementList } from './../model/achievementList.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { AchievementManagerService } from './achievementManager.service';


@Injectable()
export class AchievementService extends KiwixiService {

    apiAchievement = environment.server + 'achievements/';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        kiwixiGlobals: KiwixiGlobals,
        public toastService: MzToastService,
        private authenticationService: AuthenticationService,
        private achievementManagerService: AchievementManagerService,
    ) {
        super(_http, _router, kiwixiGlobals, toastService);
    }

    updatePersonaAchievement(persona: any, achievement: Achievement) {
        persona.achievements.map(a => a.id === achievement.id ? a = achievement : null)
        this.setCurrentUserAchievements(persona);
        this.achievementManagerService.setAchievementsToFire(persona);
    }

    setCurrentUserAchievements(persona) {
        this.authenticationService.currentUserSubject.next(persona);
        this.authenticationService.setLocalPersonaObj(persona);
    }

    getAchievementById(_id): Observable<Achievement> {
        const url = this.apiAchievement + _id + '/';
        return this.getAchievementByUrl(url);
    }

    getAchievementByUrl(_url): Observable<Achievement> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<Achievement>(_url, {
            headers: header
        })
            .pipe(
                catchError(this.handleErrorObservable<any>(`getAchievement`))
            );
    }

    getAchievements(params: HttpParams): Observable<AchievementList> {
        const header = this.createHttpClientAuthorizationHeader();
        return this._http.get<AchievementList>(this.apiAchievement, {
            headers: header,
            params: params
        })
            .map(_Achievements => _Achievements)
            .pipe(
                catchError(this.handleErrorObservable<AchievementList>(`getObservableAchievements`))
            );
    }
    patchFile(AchievementUrl, param, field, file): Promise<Achievement> {
        const self = this;
        const headers = this.createHttpClientAuthorizationHeaderNoEnctype();
        const _formData = new FormData();
        _formData.append('enctype', 'multipart/form-data');
        self.headers.delete('Content-Type');
        _formData.append(field, file);
        _formData.append('name', file.name);
        return this._http.patch(AchievementUrl, _formData, { headers: headers })
            .toPromise()
            .then((res: any) => res)
    }

    postAchievement(params) {
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.post<Achievement>(this.apiAchievement, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Achievement>(`Création d'une fiche`)),
            );
    }

    patchAchievement(_id, params) {
        const url = this.apiAchievement + _id + '/';
        const headers = this.createHttpClientAuthorizationHeader();
        return this._http.patch<Achievement>(url, params, { headers: headers })
            .pipe(
                catchError(this.handleErrorObservable<Achievement>(`Création d'une fiche`)),
            );
    }
}
