import { GroupFormations } from './group_formations.model';
import { Language } from './language.model';
import { Corporate } from './corporate.model';
export class FormationSuiviListFilter {
    id: number;
    corporate: Corporate[];
    company: string;
    formation: string;
    sf_id: string;
    group_formations: GroupFormations;
    language: Language;
    first_name: string;
    last_name: string;
    email: string;
    hours_total: number;
    hours_to_plan: number;
    hours_to_plan_percent: number;
    last_occ: Date;
    validity_period_to: Date;
    status: string;
    is_stall: string;
    upload_date: Date;
}
