<mz-modal [fixedFooter]="true" [options]="modalOptions" class="modal-size">
  <mz-modal-content>
  <div class="row center">
    <div class="col l8 push-l2">
      <b>Validation de l'administrateur</b>
      <input type="password" class="filter-col left" [(ngModel)]="login.password">
    </div>
  </div>
  </mz-modal-content>
  <mz-modal-footer>
    <button mz-button [flat]="true" (click)="validAdmin()">Valider</button>
  </mz-modal-footer>
</mz-modal>
