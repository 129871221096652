import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { helpNotifToFire } from '../../model/helpNotifToFire';
import { HelpNotifManagerService } from '../../service/helpNotifManagerService';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() slug: String;
  @Input() position: String;
  @Output() post = new EventEmitter();

  help: helpNotifToFire = null;
  isLast = false;
  injectCSS = false;

  constructor(
    private helpNotifManagerService: HelpNotifManagerService
  ) { }

  ngOnInit() {
  }

  canFire(): boolean {
    if (this.helpNotifManagerService.getActiveTooltip() && this.slug === this.helpNotifManagerService.getActiveTooltip().slug) {
      this.help = this.helpNotifManagerService.getActiveTooltip();
      this.injectCss(this.help);    
      return true;
    } else {
      return false;
    }
  }

  injectCss(help) {
    if (document.getElementById(help.slug) && !this.injectCSS) {
      this.injectCSS = true;
      if (this.position === 'top') {
        document.getElementById(help.slug).style.top = 'calc(0% - ' + document.getElementById(help.slug).offsetHeight + 'px)'
      }
      if (this.position === 'left' || this.position === 'right') {
        document.getElementById(help.slug).style.top = 'calc(75% - (' + document.getElementById(help.slug).offsetHeight + 'px / 2))'
      }
    }
  }

  next() {
    if (this.help.is_last) {
      this.post.emit();
    }
    this.helpNotifManagerService.next();
  }
}
