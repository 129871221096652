/**
 * Affichage des rapports liés au cours passés.
 */

import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { RateStudentService } from '../../../../../service/rate-student.service';
import { PendingService } from '../../../../../service/pending.service';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../../service/authentification.service';
@Component({
  selector: 'app-past',
  templateUrl: './past.component.html',
  styleUrls: ['./past.component.scss']
})
export class PastComponent implements OnInit {
  loading_pending = false;
  loadingCompletedReport = false;
  nextPagesPending = null;
  nextPagesCompleted = null;
  ratingStudents: any[] = [];
  pendings: any[] = [];
  formation_id: any;
  myUser: any;
  countPendings = 0;
  translatePath = 'teacher.reports';
  defaultAvatar = environment.static + '/avatar.png';
  constructor(
    private ratingStudentsService: RateStudentService,
    private pendingService: PendingService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
        }
      }
    )
    this.formation_id = this.route.parent.snapshot.params.id_formation;
    this.getPendings();
    this.getFilterCompletedReports();
  }

  getFilterCompletedReports() {
    this.loadingCompletedReport = true;

    const params = {
      'page': 1,
      'session__formation': this.formation_id,
      'ordering': '-session__occurrence__start'
    }
    let url = this.ratingStudentsService.setUrlWithParams('rating_students', params);
    if (this.nextPagesCompleted) {
      url = this.nextPagesCompleted;
    }
    this.ratingStudentsService.getRatingStudentsByUrl(url)
      .subscribe(_ratingStudents => {
        if (_ratingStudents) {
          if (_ratingStudents['next']) {
            this.nextPagesCompleted = _ratingStudents['next'];
          } else {
            this.nextPagesCompleted = null;
          }
          // On incremente les données
          if (_ratingStudents['results']) {
            for (const _rating of _ratingStudents['results']) {
              this.ratingStudents.push(_rating)
            }
          }
        }
        else {
          this.nextPagesCompleted = null;
        }
        this.loadingCompletedReport = false;
      });

  }

  getPendings() {
    this.loading_pending = true;
    const params = {
      'page': 1,
      'formation': this.formation_id,
      'salt': Math.random().toString(),
      'teacher': this.myUser.id,
    }
    let url = this.pendingService.setUrlWithParams('pendings', params);
    if (this.nextPagesCompleted) {
      url = this.nextPagesCompleted;
    }
    this.pendingService.getPendingsByUrl(url)
      .subscribe(_pendings => {
        if (_pendings) {
          if (_pendings['next']) {
            this.nextPagesPending = _pendings['next'];
          } else {
            this.nextPagesPending = null;
          }
          if (_pendings['results']) {
            _pendings['results'].forEach(_pending => {
              let exist = false;
              this.pendings.forEach(_existing => {
                if (_pending.url === _existing.url) {
                  exist = true;
                }
              });
              if (!exist) {
                if (_pending.teacher.url === this.myUser.url) {
                  this.countPendings += 1;
                  this.pendings.push(_pending)
                }
              }
            });
            this.loading_pending = false;
          }
        } else {
          this.nextPagesPending = null;
        }
      });
  }

  goToInfo(_ratingStudent) {
    const idSession = this.ratingStudentsService.extractIdFromUrl(_ratingStudent.session);
    this.router.navigate(['reports', 'info', idSession]);
  }

}
