import { Formation } from './../model/formation.model';
import { FormationService } from './../service/formation.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class PlanFormationGuard implements CanActivate {
    environment
    formation: Formation;
    constructor(
        private router: Router,
        private formationService: FormationService,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const currentFormationId = next.paramMap.get('id');
        this.formationService.getObservableFormationById(currentFormationId).subscribe(formation => {
            if (formation.status !== 'pending') {
                this.router.navigate(['/formation/' + formation.id]);
                return false;
            }
            return true;
        })
        return true;
    }
}
