<div class="border-pending" *ngIf="showCurrent">
  
    
      
    <div class="invoice-subwrap">
    
      <div class="invoice-date">
        <b class="invoice-current-tag" translate>teacher.invoices.current-invoices</b>
        <b>{{current_invoice.clean_date}}</b>
      </div>
      
        <div class="invoice-amount">
          <b>{{current_invoice.amount| number : '1.0-1'}}€</b>
        </div>
    
   
    
      <!-- See Detail desktop-->
      <div class="detail-btn mobile-nodisplay" (click)="showDetail = !showDetail">
        <ng-container *ngIf="showDetail; else elseDetail">
          <span translate>teacher.invoices.hide-detail</span>
        </ng-container>
        <ng-template #elseDetail>
          <span translate>teacher.invoices.see-detail</span>
        </ng-template>
      </div>

      <!-- See Detail mobile-->
      <div class="detail-btn nomobile-nodisplay">
        <span translate>teacher.invoices.see-detail</span>
      </div>

    </div>

    <!-- DetailS desktop-->
    <ng-container *ngIf="showDetail">
      <div class="mobile-nodisplay invoice-details">
        
        <ng-container *ngIf="current_invoice.occurrences.length > 0; else elseLengthh">
          <div class="class-container">
            <div  *ngFor="let occurrence of current_invoice.occurrences">
              <app-class-details [occurrence]="occurrence"></app-class-details>
            </div>
          </div>
        </ng-container>
        <ng-template #elseLengthh>
          <div>
            <div>
              <span>
                <span translate>teacher.invoices.no-classes</span>
              </span>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  
</div>