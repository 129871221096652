export class InvoiceListFilter {
    id: number;
    invoice_id: number;
    date_from: Date;
    date_to: Date;
    teacher: string;
    amount_from: number;
    amount_to: number;
    status: string;
    upload_date_from: Date;
    upload_date_to: Date;
    paiement: string;
}
