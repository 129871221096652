<div class="setup-training-wrap container usual-container">
  <div class="steps-wrapper">
        <div class="onboarding-step step-birthdate complete-step"><div class="step-icon"></div></div>
        <div *ngIf="formation.student.user.video_provider=='skype'" class="onboarding-step step1 complete-step"><div class="step-icon"></div></div>
        <div class="onboarding-step step2 complete-step"><div class="step-icon"></div></div>
        <div class="onboarding-step step3 current-step half-step"><div class="step-icon"></div></div>
        <div class="onboarding-step step4"><div class="step-icon"></div></div>
  </div>
    <div class="text-intro-plan-formation" >
      <div class="onboarding-subheading" translate>lingueopro.bookingmode.title</div>
      <div translate>lingueopro.bookingmode.Préférez-vous réserver vos cours à des horaires récurrents toutes les semaines, ou juste réserver un premier cours pour commencer ?</div>
      <div class="bookmode-explain" translate>lingueopro.bookingmode.(Quelle que soit l’option que vous choisissez maintenant, vous pourrez librement modifier votre planning de cours par la suite.)</div>
    </div>
    <div class="bookmode-options-wrap">
      <div class="bookmode-option bookmode-ponctual" (click)="goToSchedule('ponctual')">
        <div class="bookmode-option-illu"></div>
        <div class="bookmode-option-copy">
          <p class="bookmode-option-title" translate>lingueopro.bookingmode.Réservation ponctuelle</p>
          <p class="bookmode-option-subcopy" translate [innerHtml]="'lingueopro.bookingmode.Réservez un premier cours jusqu’à 7 jours en avance.' | translate"></p>
        </div>
      </div>
      <div class="bookmode-options-or" translate>lingueopro.bookingmode.OU</div>
      <div class="bookmode-option bookmode-rec" (click)="goToSchedule('rec')">
        <div class="bookmode-option-illu"></div>
        <div class="bookmode-option-copy">
          <p class="bookmode-option-title" translate>lingueopro.bookingmode.Réservation hebdo</p>
          <p class="bookmode-option-subcopy" translate [innerHtml]="'lingueopro.bookingmode.Prenez vos cours aux mêmes horaires toutes les semaines.' | translate"></p>
        </div>
      </div>
    </div>
  
</div>