/**
 * composant pour l'edition d'un invoice.
 * qui contiendra le composant datatable permettant l'affichage du tableau des occurrences.
 */

import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../../../../../service/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherService } from '../../../../../service/teacher.service';
import { environment } from '../../../../../../environments/environment';
import * as moment from 'moment';
import { FormationService } from '../../../../../service/formation.service';
import { SessionService } from '../../../../../service/session.service';
import { SiteService } from '../../../../../service/site.service';
import { MzToastService } from 'ng2-materialize';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../../mainscreen.ux.scss']
})
export class EditComponent implements OnInit {

  // ---- services
  defaultAvatar = environment.static + '/avatar.png';
  myInvoiceService;
  private errorMessage: any = '';
  loadUpload = false;

  // ---- variables principales :
  invoice;
  formation;
  teacher;
  sites;
  showInvoice = false;

  // ---- invoice details :
  statusList: any[];
  recalculate = false;
  isLoading = false;
  hourly_volume: any;

  // ---- datatables :
  count_volume = 0;
  currentFilterParams = null;
  dataInvoices: any;
  datasize: number;
  totalInvoices: number;
  pageSize: number;
  showDataInvoice = false;
  public dataHeaders = [
    {
      name: 'date',
      title: 'Date',
      type: 'string',
      ascending: false,
      filter_key: 'date__range',
      ordering_key: 'date',
      filter_type: 'calendar',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'schedule',
      title: 'Horaire',
      type: 'string',
      ascending: false,
      filter_key: 'schedule',
      ordering_key: 'schedule',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false,
    },
    {
      name: 'duration',
      title: 'Durée',
      type: 'string',
      ascending: false,
      filter_key: 'duration',
      ordering_key: 'duration_count',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'student',
      title: 'Eleve',
      type: 'student',
      ascending: false,
      filter_key: 'student',
      ordering_key: 'student_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'status_text',
      title: 'Statut',
      type: 'status_string',
      ascending: false,
      filter_key: 'status_text',
      ordering_key: 'status_text',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'site_text',
      title: 'Site',
      type: 'string',
      ascending: false,
      filter_key: 'site_text',
      ordering_key: 'site_text',
      filter_type: 'text',
      list: null,
      cell_edit: false,
      isEdit: false
    }
  ]
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private formationService: FormationService,
    private sessionService: SessionService,
    private siteService: SiteService,
    private toastService: MzToastService,
    private teacherService: TeacherService) {
    this.pageSize = 10;
    this.dataInvoices = {
      data: [],
      headers: this.dataHeaders
    }
    this.statusList = this.invoiceService.getInvoicesStatus();
  }

  ngOnInit() {
    // on récupere l'id invoice sur la route.
    this.route.params.subscribe(params => {
      this.getInvoice(params['id']);
    });
  }

  /**
   * récupération de l'invoice
   * @param id
   */
  getInvoice(id) {
    this.myInvoiceService = this.invoiceService.getInvoiceById(id)
      .subscribe(
        _invoice => {
          this.invoice = _invoice;
          if (this.invoice) {
            this.invoice.amount = this.invoice.amount;                      // on affiche le montant.
            // let _countOcc = 0;
            this.teacherService.getObservableTeacherByUrl(this.invoice.teacher)
              .subscribe(_teacher => {
                this.teacher = _teacher;
                this.showInvoice = true;
              })
            if (_invoice.occurrences.length > 0) {
              this.setRowsData(_invoice.occurrences);
              this.showDataInvoice = true;
              this.isLoading = true;
              this.hourly_volume = this.setCountdown(this.count_volume) + 'h';
            }
          } else {
            this.showInvoice = true;
          }
        },
        error => this.errorMessage = <any>error
      );

  }

  // methode pour afficher le volume.
  setCountdown(_ms) {
    const sessionDuration = moment.duration(_ms);
    const _hours = sessionDuration.asMilliseconds() / 3600000;
    return String(_hours)
  }

  /**
   * methode pour que le tableau récupère la data invoices.
   * @param _filterParams
   * @param _countPages
   */
  public getData(_filterParams, _countPages) {
    let result = this.dataInvoices['data'];

    // *** Filtres
    let _filterInput;
    if (_filterParams['filtering']['student']) {
      _filterInput = _filterParams['filtering']['student'];
      result = result.filter(_row => _row.student_name.toUpperCase().indexOf(_filterInput.toUpperCase()) !== -1
        || _row.student_firstname.toUpperCase().indexOf(_filterInput.toUpperCase()) !== -1)
    } else if (_filterParams['filtering']['date__range']) {
      _filterInput = _filterParams['filtering']['date__range'];
      result = result.filter(_row => _row.date__range === _filterInput);
    } else if (_filterParams['filtering']['status_text']) {
      _filterInput = _filterParams['filtering']['status_text'];
      result = result.filter(_row => _row.status_text.toUpperCase().indexOf(_filterInput.toUpperCase()) !== -1)
    } else if (_filterParams['filtering']['site_text']) {
      _filterInput = _filterParams['filtering']['site_text'];
      result = result.filter(_row => _row.site_text.toUpperCase().indexOf(_filterInput.toUpperCase()) !== -1)
    } else if (_filterParams['filtering']['duration']) {
      _filterInput = _filterParams['filtering']['duration'];
      result = result.filter(_row => _row.duration.toUpperCase().indexOf(_filterInput.toUpperCase()) !== -1)
    } else if (_filterParams['filtering']['schedule']) {
      _filterInput = _filterParams['filtering']['schedule'];
      result = result.filter(_row => _row.schedule.toUpperCase().indexOf(_filterInput.toUpperCase()) !== -1)
    }

    // *** Tris
    if (_filterParams['ordering'] && _filterParams['ordering']['value']) {
      if (_filterParams['ordering']['key'] === 'ordering') {
        if (_filterParams['ordering']['value'].charAt(0) === '-') {
          _filterParams['ordering']['value'] = _filterParams['ordering']['value'].substring(1);
          if (_filterParams['ordering']['value'] === 'date') {
            this.disordering(result, 'date__range');
          } else {
            this.disordering(result, _filterParams['ordering']['value']);
          }
        } else {
          if (_filterParams['ordering']['value'] === 'date') {
            this.ordering(result, 'date__range');
          } else {
            this.ordering(result, _filterParams['ordering']['value']);
          }
        }
      }
    }

    if (!_filterParams['ordering']) {
      this.disordering(result, 'date__range');
    }
    return result;
  }

  ordering(_data, _field) {
    _data.sort(function (a, b) {
      if (a[_field] < b[_field]) {
        return -1;
      }
      if (a[_field] > b[_field]) {
        return 1;
      }
      return 0;
    });
  }

  disordering(_data, _field) {
    _data.sort(function (a, b) {
      if (a[_field] < b[_field]) {
        return 1;
      }
      if (a[_field] > b[_field]) {
        return -1;
      }
      return 0;
    });
  }

  /**
   * Methode pour construire les lignes du tableau
   */
  setRowsData(occurrences) {
    occurrences.forEach(_occ => {
      const row = {
        date: moment(_occ.start).format('DD MMM YYYY'),
        date__range: moment(_occ.start).format('YYYY-MM-DD'),
        real__date: moment(_occ.start).format('YY/MM/DD'),
        schedule: this.setSchedule(_occ.start, _occ.end),
        duration: this.setDuration(_occ),
        duration_count: this.invoiceService.getDuration(_occ),
        student: _occ.student,
        student_name: _occ.student.user.last_name,
        student_firstname: _occ.student.user.first_name,
        status_text: this.sessionService.setStatusToSession(_occ.status, this.sessionService),
        site_text: 'lingueopro'
      };
      this.dataInvoices['data'].push(row);
    });

  }

  // Afficher le status de l'occurrence
  setStatusToSession(session_status, _sessionsService) {
    const status = _sessionsService.getSessionsStatus();
    const idx = _sessionsService.getSessionsStatus().map(val => val.code).indexOf(session_status);
    if (idx >= 0) {
      return status[idx]['label'];
    } else {
      return null;
    }
  }

  // Afficher la durée du cours
  setDuration(_occ) {
    const _ms = this.invoiceService.getDuration(_occ);
    this.count_volume = this.count_volume + Number(_ms);
    return this.setCountdown(_ms);
  }

  // Afficher le créneau
  setSchedule(_start, _end) {
    return moment(_start).format('HH:mm') + ' à ' + moment(_end).format('HH:mm')
  }

  /**
   * mettre à jour l'invoice
   */
  updateInvoice(invoice, field) {
    const param = {};
    param[field] = invoice[field];
    if (field == 'amount') {
      param['skip_calc'] = true;
    }
    this.invoiceService.patchInvoice(invoice.url, param)
  }

  /**
   * methode pour recalculer le montant
   */
  recalculatation() {
    this.recalculate = true;
    this.invoiceService.getRecalculation(this.invoice.url)
      .subscribe(res => {
        this.invoice.amount = res.new_amount + '€';
        this.recalculate = false;
      })
  }

  /**
   * upload invoice
   */
  uploadInvoice($event) {
    this.loadUpload = true;
    for (const file of $event.target.files) {
      const param = {};
      param['invoice'] = this.invoice['invoice'];
      this.invoiceService.patchFile(this.invoice.url, param, 'invoice', file)
        .then(_invoice => {
          if (_invoice) {
            const _param = {
              status: 'received',
              uploaded_at: moment()
            };
            this.invoiceService.patchInvoice(_invoice.url, _param)
              .then(res => {
                this.invoice = res;
                this.toastService.show('Upload is valid', 1000, 'green');
                this.loadUpload = false;
              })
          } else {
            this.loadUpload = false;
          }
        })
        .catch(error => {
          const _error = error;
          this.invoiceService.handleError(_error['invoice'][0]);
          console.error('error EditComponent upload', error);
          this.loadUpload = false;
        })
    }
  }

  /**
   * navigation vers un teacher.
   * @param _teacherId
   */
  public showDetail(_teacherId) {
    this.router.navigate(['teachers', 'admin', _teacherId]);
  }

  /**
   * méthode pour copier l'email
   * @param val
   */
  copyEmail(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
