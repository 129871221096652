
<div class="information-container" *ngIf="showAdmin">
  <div class="row form-row">
    <div class="col l3">
      <h6 class="center grey darken-1 white-text title-information">Infos Enseignement</h6>
    </div>
  </div>

  <!-- Langue enseignée -->
  <div class="row valign-wrapper">
    <div class="col l4">
      <p class="left">Enseigne :</p>
    </div>
    <div class="col l8">
      <form>
        <input class= "dropdown" type="text" matInput [formControl]="languageControl"
          [matAutocomplete]="auto2" >
        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayLanguageFn">
          <mat-option *ngFor="let language of filteredLanguages | async"
            [value]="language" (focusout)="selectLanguage(language)">
              {{ language.label }}
          </mat-option>
        </mat-autocomplete>
      </form>
    </div>
  </div>
  <div class="subsec-divider"></div>

  <!-- Langues parlées -->
  <div class="valign-wrapper teaching-chips">
    <div class="col l4">
      <p>Parle :</p>
    </div>
    <div class="col l8">
      <app-chips
        [parent]=this
        [chipsList]="myTeacher.languages"
        [choices]="filteredSpokenLanguages"
        [data]="languages"
        [label]="'label'"
        [myObject]="'languages'"
        [removableCondition]="teachingSelect">
      </app-chips>
    </div>
  </div>
  <div class="subsec-divider"></div>
   <!-- Contenu spécific -->
  <div class="valign-wrapper teaching-chips">
    <div class="col l4">
      <p>Contenu spécifique :</p>
    </div>
    <div class="col l8">
     <app-chips
        [parent]=this
        [chipsList]="selectedGroupSpecificList"
        [choices]="selectedGroupSpecificList"
        [data]="groupSpecificList"
        [label]="'name'"
        [myObject]="'specific_content'"
        [removableCondition]="true">
      </app-chips>
    </div>
  </div>
  <div class="subsec-divider"></div>
  <!-- Skype -->
  <div class="row valign-wrapper">
    <div class="col l4">
      <span class="left">Skype pro :</span>
    </div>
    <div class="col l8">
      <input class="textfield" type="text" [(ngModel)]="myTeacher.id_skype_pro"
      (focusout)="updateTeacher(myTeacher, 'id_skype_pro')">
    </div>
  </div>
  <div class="subsec-divider"></div>
  <!-- Sites exercés -->
  <div class="valign-wrapper teaching-chips">
    <div class="col l4">
      <p>Professeur sur :</p>
    </div>
    <div class="col l8">
      <app-chips
        [parent]=this
        [chipsList]="sessionSites"
        [choices]="filteredSites"
        [data]="sites"
        [label]="'name'"
        [myObject]="'sites'">
      </app-chips>
    </div>
  </div>
  <div class="subsec-divider"></div>
  <!-- Description -->
  <div class="row valign-wrapper teaching-description">
    <div class="">
      <p class="left">Description :</p>
    </div>
    <div class="">
      <button class="dualbutton" mz-button (click)="vo = !vo; vf = !vf" [ngClass]="isActive(vf)"><b>VF</b></button>
    </div>
    <div class="">
      <button class="dualbutton" mz-button (click)="vf = !vf; vo = !vo" [ngClass]="isActive(vo)">VO</button>
    </div>
  </div>
  <div class="row">
    <div class="col l12">
      <textarea class="largetextfield" name="description_fr" rows="8" cols="80" *ngIf="vf"
        [(ngModel)]="myTeacher.description_fr"
        (focusout)="updateTeacher(myTeacher, 'description_fr')">
          {{myTeacher.description_fr}}
      </textarea>
      <textarea class="largetextfield" name="description_vo" rows="8" cols="80" *ngIf="vo"
        [(ngModel)]="myTeacher.description_vo"
        (focusout)="updateTeacher(myTeacher, 'description_vo')">
          {{myTeacher.description_vo}}
      </textarea>
    </div>
  </div>

</div>
