import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Occurrence } from '../model/occurrence.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { MzToastService } from 'ng2-materialize';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PendingService extends KiwixiService {
  apiPendings = environment.server + 'pendings/';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getPendings(params): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Occurrence[]>(this.apiPendings, {
      headers: header,
      params: params,
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getPendings`))
      );
  }
  getPendingsByUrl(url): Observable<Occurrence[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Occurrence[]>(url, {
      headers: header,
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence[]>(`getPendingsByUrl`))
      );
  }

  getPending(id): Observable<Occurrence> {
    const urlPending = this.apiPendings + id + '/';
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Occurrence>(urlPending, {
      headers: header
    }).
      pipe(
        catchError(this.handleErrorObservable<Occurrence>(`getPending`))
      );
  }
}
