<ng-container *ngIf="showPage; else elseTemplate">
  <div class="billingdetail-wrapper">
    <!-- Header -->
    <div class="billingdetail-header">
      <i class="material-icons back-btn" [routerLink]="['../../billing']">arrow_back</i>
      <div class="billing-label">
        <span translate>teacher.invoices.billing-info-btn</span>
        <!-- My Billing Info -->
      </div>
    </div>

    <!-- Main -->
    <div class="billingdetail-mainwrapper">
      <div class="">
       <div class="row valign-wrapper breathing-row">
          <div class="col left">
            <span translate>teacher.invoices.contact-to-modify</span> <a href="mailto:teacher@lingueo.com">teacher@lingueo.com</a>
          </div>
        </div>
        <div class="row valign-wrapper breathing-row">
          <div class="col left">
            <span class="data-title" translate>teacher.invoices.preferred-payment-lbl</span> {{ myTeacher.preferred_payment }}
            <!-- Preferred Payment Type : -->
          </div>
        </div>
        <form [formGroup]="invoiceForm">
          <div class="row valign-wrapper breathing-row">
            <div class="col left">
              <span class="data-title" translate>teacher.invoices.paypal-email-lbl</span> {{ myTeacher.paypal_email }}
              <!-- Paypal Email : -->
            </div>
          </div>
          <div class="row valign-wrapper breathing-row">
            <div class="col">
              <span class="data-title" translate>teacher.invoices.iban-number-lbl</span> {{ myTeacher.iban }}
              <!-- IBAN Number : -->
            </div>
          </div>
          <div class="row valign-wrapper breathing-row">
            <div class="col left">
              <span class="data-title" translate>teacher.invoices.bic-number-lbl</span> {{ myTeacher.bic }}
              <!-- BIC Number : -->
            </div>
          </div>
        </form>
        <div *ngIf="formNotValid" class="row warn-message">
          <div class="col s12 m12 l12 red-text"><span translate>teacher.invoices.info-warning-message</span></div>
        </div>
        <div class="row valign-wrapper breathing-row">
          <div class="col left">
            <span class="data-title" translate>teacher.invoices.tax-number-lbl</span> {{ myTeacher.tax_status_number }}
            <!-- Tax Number : -->
          </div>
        </div>
        <div class="row valign-wrapper form-row">
          <div class="col left">
            <span class="data-title" translate>teacher.invoices.street-lbl</span> : {{ myTeacher.address }}
          </div>
        </div>
        <div class="row valign-wrapper breathing-row">
            <div class="col left">
              <span class="data-title" translate>teacher.invoices.cp-lbl</span> : {{ myTeacher.cp }}
              <!-- Tax Number : -->
            </div>
          </div>
          <div class="row valign-wrapper breathing-row">
              <div class="col left">
                <span class="data-title" translate>teacher.invoices.city-lbl</span> : {{ myTeacher.city }}
              </div>
            </div>
      </div>
    </div>

  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row center loading pageload-message">
    <b translate>teacher.billing.info-loading</b>
  </div>
</ng-template>
