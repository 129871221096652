import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-cell-date-range',
  templateUrl: './datatable-cell-date-range.component.html',
  styleUrls: ['./datatable-cell-date-range.component.scss']
})
export class DatatableCellDateRangeComponent implements OnInit {

  @Input() date1;
  @Input() date2;

  constructor() { }

  ngOnInit() {
  }

}
