/**
 * Service local pour les sessions.
 */

import { Injectable } from '@angular/core';
import { KiwixiService } from '../../../../../kiwixi/kiwixi.service';
import { environment } from '../../../../../../environments/environment';

@Injectable()
export class SessionsService extends KiwixiService {

  apiSession = environment.server + 'sessions/';

  // colonnes du tableau.
  public sessionHeaders = [
    {
      name: 'date',
      title: 'Date',
      type: 'string',
      ascending: false,
      filter_key: 'date__range',
      ordering_key: 'start',
      filter_type: 'calendar',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'initdate',
      title: 'Horaire',
      type: 'hour',
      ascending: false,
      filter_key: 'start',
      ordering_key: 'by_hour',
      filter_type: 'hour',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'date'
    },
    {
      name: 'teacher',
      title: 'Professeur',
      type: 'teacher',
      ascending: false,
      filter_key: 'teachers_name',
      ordering_key: 'event__teacher__user__last_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'status',
      title: 'Statut',
      type: 'string',
      ascending: false,
      filter_key: 'status',
      ordering_key: 'status',
      filter_type: 'select',
      list: this.getSessionsStatus(),
      cell_edit: true,
      isEdit: false
    }
  ]

  setTeacher(session, formation) {
    for (let _i = 0; _i < formation.teachers.length; _i++) {
      const initTeacher = formation.teachers[_i];
      if (session.teacher === initTeacher.url) {
        return {
          avatar: formation.teachers[_i].avatar,
          label: formation.teachers[_i].user.username
        }
      }
    }
  }

  setStatusToSession(session_status) {
    for (const status of this.getSessionsStatus()) {
      if (status.code === session_status) {
        return status;
      }
    }
    return null;
  }

  getSessionsStatus() {
    return [
      {
        code: 'done',
        label: 'Pris',
        valid: true
      },
      {
        code: 'booked',
        label: 'À venir',
        valid: true
      },
      {
        code: 'postponed_by_student',
        label: 'Reporté par l\'élève',
        valid: true
      },
      {
        code: 'postponed_by_teacher',
        label: 'Reporté par le professeur',
        valid: true
      },
      {
        code: 'abs_teacher_without_justification',
        label: 'Absence du prof',
        valid: true
      },
      {
        code: 'abs_student_without_justification',
        label: 'Absence de l\'étudiant',
        valid: true
      },
      {
        code: 'cancelled_by_admin',
        label: 'Administrateur a annulé',
        valid: true
      },
      {
        code: 'old_report',
        label: 'Anciens processus d\'annulation',
        valid: true
      },
      {
        code: 'suspended',
        label: 'Cours suspendus',
        valid: true
      },
    ]
  }

}
