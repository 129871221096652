import { User } from './user.model';

export class Staff {
  url: string;
  site: string;
  poste: string;
  avatar: string;
  user: User;
  constructor() { }
}
