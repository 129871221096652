import { Event } from './../../../../../../model/event.model';
import { Teacher } from './../../../../../../model/teacher.model';
import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../../../../service/authentification.service';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit {
  @Input() events: Event[];
  teacher: Teacher;
  isLoaded = false;

  translatePathTrainings = 'teacher.trainings';
  constructor(
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.teacher = this.authenticationService.currentUserSubject.value;
    this.filterReccurringEvents();
    this.isLoaded = true;
  }

  filterReccurringEvents() {
    this.events = this.events.filter(e => e.event_type === 'session')
  }

}
