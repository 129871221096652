import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-select-time-picker',
  templateUrl: './select-time-picker.component.html',
  styleUrls: ['./select-time-picker.component.scss']
})
export class SelectTimePickerComponent implements OnInit, OnChanges {
  @Input() condition;
  @Input() parent;
  startPicker;
  endPicker;
  @Input() myEnd;
  @Input() myStart;
  @Input() availablesDays;
  @Input() listNewEvents;
  @Output() updateCalendar = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.startPicker = this.setTimePicker();
    this.endPicker = this.setTimePicker();
    this.initPicker(this.startPicker, true)
    if (this.myStart && this.myEnd) {
      this.selectEnd();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.myStart && changes.myEnd) {
      if (!changes.myStart.firstChange && !changes.myEnd.firstChange) {
        if (changes.myStart.previousValue && changes.myEnd.previousValue) {
          this.startPicker.forEach(_start => {
            if (_start.str === changes.myStart.currentValue.str) {
              this.myStart = _start;
            }
          });
          this.endPicker.forEach(_end => {
            if (_end.str === changes.myEnd.currentValue.str) {
              this.myEnd = _end;
            }
          });
        }
      }
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.local_str === o2.local_str;
    }
    return false;
  }

  /**
   * Initialiser les variables Start & End
   */
  initModels() {
    for (const myendPicker of this.endPicker) {
      if (this.myStart.datetime.isSameOrAfter(moment(myendPicker.datetime))) {
        myendPicker.enable = false;
      }
      if (this.myStart.datetime.local().hour() === myendPicker.datetime.local().hour()
        && this.myStart.datetime.local().minute() === myendPicker.datetime.local().minute()) {
        this.myEnd = this.endPicker[this.endPicker.indexOf(myendPicker) + 1];
        break;
      }
    }
  }

  setTimePicker() {
    const myTimeList = [];
    for (let _i = 0; _i < 25; _i++) {
      if (_i < 24) {
        const _myDate1 = moment([moment().year(), moment().month(), moment().date(), _i, 0, 0, 0]);
        const my_obj1 = { id: _i + 1, enable: false, datetime: _myDate1, str: _myDate1.toISOString(), local_str: _myDate1.format('HH:mm') }
        myTimeList.push(my_obj1);
        const _myDate2 = moment([moment().year(), moment().month(), moment().date(), _i, 30, 0, 0]);
        const my_obj2 = { id: _i + 1, enable: false, datetime: _myDate2, str: _myDate2.toISOString(), local_str: _myDate2.format('HH:mm') }
        myTimeList.push(my_obj2);
      } else {
        const _myDate3 = moment([moment().year(), moment().month(), moment().date(), _i, 0, 0, 0]);
        const my_obj3 = { id: _i + 1, enable: false, datetime: _myDate3, str: _myDate3.toISOString(), local_str: _myDate3.format('HH:mm') }
        myTimeList.push(my_obj3);
      }
    }
    return myTimeList
  }

  /**
   * Initialiser les variables Start & End
   */
  selectStart() {
    this.initPicker(this.endPicker, false);
    this.initModels();
    this.selectEnd();
  }

  /**
   * Evenement au select end
   */
  selectEnd() {
    const _obj = {
      start: this.myStart,
      end: this.myEnd
    }
    this.updateCalendar.emit(_obj);
  }

  /**
   * Methode pour initialiser le picker avec les dispos
   */
  initPicker(_mySelectList, isStart) {
    if (this.availablesDays) {
      for (let _i = 0; _i < this.availablesDays.length; _i++) {
        for (let _j = 0; _j < this.availablesDays[_i]['dispo'].length; _j++) {
          this.setDisponibilitiesToSelectTime(this.availablesDays[_i]['dispo'][_j]['start'], this.availablesDays[_i]['dispo'][_j]['end'], _mySelectList, isStart);
        }
      }
    }
  }

  /**
   * Methode locale pour checker les dispos
   */
  setDisponibilitiesToSelectTime(_start, _end, _mySelectList, isStart) {
    const myAvailableStart = moment({
      years: moment().year(), months: moment().month(), date: moment().date(),
      hours: moment(_start).hour(), minutes: moment(_start).minute(),
      seconds: 0, milliseconds: 0
    });
    let myAvailableEnd = moment({
      years: moment().year(), months: moment().month(), date: moment().date(),
      hours: moment(_end).hour(), minutes: moment(_end).minute(),
      seconds: 0, milliseconds: 0
    });
    if (myAvailableEnd.format('HH:mm') === '00:00') {
      myAvailableEnd = moment({
        years: moment().year(), months: moment().month(), date: moment().date(),
        hours: 24, minutes: 0,
        seconds: 0, milliseconds: 0
      });
    }
    for (let _i = 0; _i < _mySelectList.length; _i++) {
      const mySelect = _mySelectList[_i];
      let mySelectTime = null;
      mySelectTime = moment({
        years: moment().year(), months: moment().month(), date: moment().date(),
        hours: moment(mySelect.datetime).hour(), minutes: moment(mySelect.datetime).minute(),
        seconds: 0, milliseconds: 0
      });
      if (!mySelect.enable) {
        if (isStart) {
          mySelect.enable = checkIfStartHourIsBetween(myAvailableStart, myAvailableEnd, mySelectTime);
          if (mySelect.id === 25) {
            mySelect.enable = false;
          }
        } else {
          mySelect.enable = checkIfEndHourIsBetween(myAvailableStart, myAvailableEnd, mySelectTime);
        }
      }
    }
    function checkIfStartHourIsBetween(start, end, value) {
      if (start <= value && value < end) {
        return true;
      }
      return false;
    }

    function checkIfEndHourIsBetween(start, end, value) {
      if (start < value && value <= end) {
        return true;
      }
      return false;
    }
  }

}
