<div id="basemodal-next-session" class="basemodal" *ngIf="nextSession" [ngStyle]="{'display': timeToGoInClass ? 'block' : 'none' }">
  <div class="modalcontent">
    <div class="modalbody">
      <div>
        <div class="popup-title">C'est l'heure de votre cours !</div>
        <div class="popup-illu">
          <div class="popup-illu-inner"></div>
        </div>
        <div class="popup-copy">Votre cours de {{nextSession.start | date :'HH:mm'}} à {{nextSession.end | date :'HH:mm'}} avec {{ nextSession.teacher.user.first_name}} peut commencer ! <br/>Cliquez sur le bouton ci-dessous pour rejoindre le cours.</div>
        <button class="teamscall-btn" (click)="joinClass()" [disabled]="!isValidProvider">
          <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMjguMyAxMzguOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjI4LjMgMTM4Ljk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTQzLjUsMTM4LjlIMTkuNUM4LjcsMTM4LjksMCwxMzAuMiwwLDExOS40di0xMDBDMCw4LjcsOC43LDAsMTkuNSwwaDEyNC4xQzE1NC4zLDAsMTYzLDguNywxNjMsMTkuNXYxMDAKCQlDMTYzLDEzMC4yLDE1NC4zLDEzOC45LDE0My41LDEzOC45eiIvPgo8L2c+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTIxMywxMjkuN2wtODYtNTEuN2MtNi41LTMuOS02LjUtMTMuMywwLTE3LjJMMjEzLDljNi43LTQsMTUuMywwLjgsMTUuMyw4LjZ2MTAzLjUKCQlDMjI4LjMsMTI4LjksMjE5LjcsMTMzLjcsMjEzLDEyOS43eiIvPgo8L2c+Cjwvc3ZnPgo="><span>Rejoindre le cours</span>
        </button>
        <button class="closepopup-btn" (click)="close()">Fermer cette fenêtre</button>
      </div>
    </div>
  </div>
  <div class="modalbackground"></div>
</div>
