import { FormationOptions } from './../model/formationOptions.model';
import { Formation } from './../model/formation.model';
import { FormationList } from './../model/formationList.model';
import { FormationHoursInfo } from './../model/formationHoursInfo.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';

import { OccurrenceService } from './occurrence.service';
import { EventService } from './event.service';
import { Occurrence } from '../model/occurrence.model';
import { Router } from '@angular/router';
//
import { Observable, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ExportCdcCertificationLog } from '../model/exportCdcCertificationLog.model';
import { ExportCdcCertificationValidity } from '../model/exportCdcCertificationValidity.model';
import { ExportCdcCertificationList } from '../model/exportCdcCertificationsList.model';


@Injectable()
export class FormationService extends KiwixiService {

  apiFormations = environment.server + 'formations/';
  public currentFormationSubject: BehaviorSubject<Formation> = new BehaviorSubject<Formation>(null);
  public nextOccurrence: BehaviorSubject<Occurrence> = new BehaviorSubject<Occurrence>(null);
  public listeFormations: BehaviorSubject<Formation[]> = new BehaviorSubject<Formation[]>(null);


  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    private eventService: EventService, private occurrenceService: OccurrenceService,
    public toastService: MzToastService
  ) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  public getMaxReportPerFormation(formation: Formation) {
    return 999;
    // if (formation.hours_total >= 60) {
    //   return 16;
    // } else if (formation.hours_total >= 40 && formation.hours_total < 60) {
    //   return 12;
    // } else if (formation.hours_total >= 20 && formation.hours_total < 40) {
    //   return 8;
    // } else {
    //   return 4;
    // }
  }

  public getFormations(_filterParams): Promise<Object[]> {
    let params = new HttpParams();
    console.log(_filterParams);
    if (_filterParams['ordering']) {
      const f = _filterParams['ordering'];
      params = params.set(f.key, f.value);
    }
    if (_filterParams['filtering']) {
      for (const filterParam of Object.keys(_filterParams['filtering'])) {
        params = params.set(filterParam, _filterParams['filtering'][filterParam]);
      }
    }
    if (_filterParams['page']) {
      params = params.set('page', _filterParams['page']);
    }
    const headers = this.createHttpClientAuthorizationHeader();

    return this._http.get(this.apiFormations, { headers: headers, params: params })
      .toPromise()
      .then((res: any) => res)
      .catch(this.handleError);
  }

  getFormation(id): Promise<Formation> {
    const url = this.apiFormations + id;
    return this.getFormationWithUrl(url);
  }

  getFormationWithUrl(url): Promise<Formation> {
    const headers = this.createHttpClientAuthorizationHeader();
    if (!url.endsWith('/')) {
      url = url + '/';
    }
    this.checkCredential();
    return this._http.get(url, { headers: headers })
      .toPromise()
      .then((res: any) => {
        const body = res;
        return body;
      })
      .catch(error => {
        console.error('Formation error', error);
        this.logout();
      })
  }

  getFormationsFromStudent(urlStudent): Observable<Formation[]> {
    const header = this.createHttpClientAuthorizationHeader();
    const student_id = urlStudent.split('/')[urlStudent.split('/').length - 2]
    return this._http.get<Formation[]>(this.apiFormations, {
      headers: header,
      params: new HttpParams().set('student_id', student_id),
    }).pipe(
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
      }),
      catchError(this.handleErrorObservable<Formation[]>(`getFormationsFromStudent id_student=${urlStudent}`))
    ).map(result => <Formation[]>result['results'])
  }

  patchFormation(formationUrl, param): Promise<Formation> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(formationUrl, JSON.stringify(param), { headers: headers })
      .toPromise()
      .then((res: any) => {
        const body = res;
        return body;
      })
      .catch(error => {
        this.toastService.show(`La sauvegarde de la formation a échouée`, 2000, 'red')
        console.error('error patch Event service', error);
      })
  }

  patchObservableFormation(formationUrl, params) {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch<Formation>(formationUrl, params, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<Formation>(`Mise à jour d'une formation`)),
      );
  }

  postFormation(formation): Promise<Formation> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post(this.apiFormations, formation, {
      headers: headers
    })
      .toPromise()
      .then((res: any) => {
        const body = res;
        return body;
      })
      .catch(error => {
        this.toastService.show(`La sauvegarde de la formation a échouée`, 2000, 'red')
        console.error('error postFormation service', error);
      })
  }

  getStatus() {
    return [
      {
        label: 'Activée',
        code: 'active',
        valid: true
      },
      {
        label: 'En attente',
        code: 'pending',
        valid: true
      },
      {
        label: 'Terminée',
        code: 'done',
        valid: true
      },
      {
        label: 'Annulée',
        code: 'cancelled',
        valid: true
      },
      {
        label: 'Suspendue',
        code: 'suspended',
        valid: true
      }
    ];
  }

  getExportStatus() {
    return [
      {
        label: 'A exporter',
        code: 'to_export',
      },
      {
        label: 'En attente',
        code: 'pending',
      },
      {
        label: 'Exporté',
        code: 'exported',
      },
    ];
  }

  getModeFinancement() {
    return [
      {
        label: 'FNE',
        code: 'fne',
      },
      {
        label: 'CPF',
        code: 'cpf',
      },
      {
        label: 'Autres',
        code: 'autres',
      },
    ];
  }

  getBookingMode() {
    return [
      {
        label: 'Recurrent',
        code: 'rec',
      },
      {
        label: 'Ponctuel',
        code: 'ponctual',
      },
      {
        label: 'Les deux',
        code: 'all',
      },
    ];
  }

  getOccurrences(studentId, formationId, count, salt = false): Promise<Occurrence[]> {
    let filterParams = [
      {
        key: 'student',
        value: studentId
      },
      {
        key: 'formation',
        value: formationId
      },
      {
        key: 'ordering',
        value: 'by_hour'
      },
      {
        key: 'page',
        value: count
      }
    ]
    if (salt) {
      filterParams.push({ key: 'salt', value: Math.random().toString() })
    }
    return this.occurrenceService.getOccurrences(filterParams);
  }

  saveEvents(formation): Observable<any> {
    const obx = [];
    for (const event of formation.events) {
      if (event.start && event.end) {
        obx.push(this.eventService.saveEvent(event));
      }
    }
    return forkJoin(obx)
      .map(responses => {
        formation.events = responses;
      })
  }

  saveEvents2(formation): Observable<any> {
    const obx = [];
    for (const event of formation.events) {
      if (event.start && event.end) {
        obx.push(this.eventService.saveEvent(event));
      }
    }
    return forkJoin(obx)
    // .map(responses => responses[0])
  }

  saveFormation(formation): Observable<any> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(formation.url, JSON.stringify(formation), { headers: headers }).map(
      res => res
    ).pipe(
      catchError(this.handleErrorObservable('La sauvegarde de formation a échouée', formation))
    )
  }

  searchTrainings(_teacher, term, _countPages, _status): Observable<Formation[]> {
    const params = new HttpParams()
      .set('teachers', _teacher.id)
      .set('status', _status)
      .set('student_name', term)
      .set('page', _countPages.toString())
      .set('ordering', 'student__user__last_name')
    return this.getObservableFormations(params)
  }

  getCountTrainings(_teacher, _status): Observable<Number> {
    const params = new HttpParams()
      .set('teachers', _teacher.id)
      .set('status', _status)
    return this.getObservableCountFormations(params)
  }

  getObservableFormations(params: HttpParams): Observable<Formation[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Formation[]>(this.apiFormations, {
      headers: header,
      params: params
    })
      .pipe(
        catchError(this.handleErrorObservable<Formation[]>(`getFormations`))
      );
  }

  getObservableFormation(idFormation): Observable<Formation> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Formation>(this.apiFormations + idFormation + '/', {
      headers: header
    })
      .map(_trainings => _trainings)
      .pipe(
        catchError(this.handleErrorObservable<Formation>(`getFormations`))
      );
  }

  getFormationHoursInfo(idFormation): Observable<FormationHoursInfo> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Formation>(this.apiFormations + idFormation + '/hours_info/', {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<FormationHoursInfo>(`getFormationsHoursInfo`))
      );
  }

  getObservableCountFormations(params: HttpParams): Observable<Number> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Number>(this.apiFormations, {
      headers: header,
      params: params
    })
      .map(_trainings => _trainings['count'])
      .pipe(
        catchError(this.handleErrorObservable<Number>(`getObservableCountFormations`))
      );
  }

  getObservableDataFormations(params: HttpParams): Observable<any> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<any>(this.apiFormations, {
      headers: header,
      params: params
    })
      .pipe(
        catchError(this.handleErrorObservable<any>(`getObservableDataFormations`))
      );
  }

  getObservableFormationById(id): Observable<Formation> {
    const url = this.apiFormations + id + '/';
    return this.getObservableFormationByUrl(url);
  }

  getObservableFormationByUrl(_url): Observable<Formation> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Formation>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Formation>(` `))
      );
  }

  getFormationsByUrl(_url): Observable<FormationList> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<FormationList>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<FormationList>(`getFormationsListByUrl`))
      );
  }


  getMultipleFormations(formationsUrl) {
    const obx = [];
    for (const url of formationsUrl) {
      obx.push(this.getObservableFormationByUrl(url))
    }
    return forkJoin<Formation>(obx)
  }

  cleanCache(idFormation): Observable<any> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<any>(this.apiFormations + 'cache/update/' + idFormation + '/', {
      headers: header
    })
    // .pipe(
    //   catchError(this.handleErrorObservable<any>(`cleanCache`))
    // );
  }


  getDefaultFormation(): Formation {
    if (this.listeFormations.value) {
      const formations = this.listeFormations.value;
      const _formations_active = formations.filter(_formation => _formation['status'] === 'active');
      if (_formations_active.length > 0) {
        return _formations_active[0];
      } else {
        return formations[0];
      }
    }

  }

  checkFormationSessionForceDurationExist(formation) {
    const findForceDuration = formation.options.filter(option => option.option_type === 'force_duration').length;
    if (findForceDuration > 0) {
      return true
    }
    return false
  }

  getFormationSessionForceDuration(formation) {
    const findForceDuration: FormationOptions[] = formation.options.filter(option => option.option_type === 'force_duration');
    if (findForceDuration.length > 0) {
      return findForceDuration[0];
    } else {
      return null;
    }
  }

  getExports(params: HttpParams): Observable<ExportCdcCertificationList> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get<ExportCdcCertificationList>(environment.server + 'export_cdc_certifications/', { headers: headers, params: params });
  }

  getExportById(exportId): Observable<ExportCdcCertificationLog> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.get<ExportCdcCertificationLog>(environment.server + 'export_cdc_certifications/' + exportId + '/', { headers: headers });
  }

  checkValidityFormations(selectedFormations): Observable<ExportCdcCertificationValidity[]> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post<ExportCdcCertificationValidity[]>(environment.server + 'export_cdc_certifications/export/check_formations_validity/', selectedFormations, { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<ExportCdcCertificationValidity[]>(`Verification de la validité des formations`)),
      );
  }

  postExport(params) {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.post<ExportCdcCertificationLog>(environment.server + 'export_cdc_certifications/export/create/', params,
      { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<ExportCdcCertificationLog>(`Création d'une réponse type choice`)),
      );
  }

  patchExport(exportId, params) {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch<ExportCdcCertificationLog>(environment.server + 'export_cdc_certifications/' + exportId + '/', params,
      { headers: headers })
      .pipe(
        catchError(this.handleErrorObservable<ExportCdcCertificationLog>(`Création d'une réponse type choice`)),
      );
  }

  downloadExport(_url, _filename, _contentType) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get(_url, { headers: header, responseType: 'arraybuffer' })
      .map((file) => {
        const myFile = new Uint8Array(file);
        const blob = new Blob([myFile], { type: _contentType });
        const url = window.URL.createObjectURL(blob);
        const $a = $('<a />', {
          'href': url,
          'download': _filename,
          'text': 'click'
        }).hide().appendTo('body')[0].click();
      });
  }
}
