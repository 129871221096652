import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KiwixiService } from '../kiwixi/kiwixi.service';
import { Router } from '@angular/router';
import { KiwixiGlobals } from '../kiwixi/kiwixi.globals';
import { Session } from '../model/session.model';
//
import { Observable, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MzToastService } from 'ng2-materialize';

@Injectable()
export class SessionService extends KiwixiService {
  apiSession = environment.server + 'sessions/';

  constructor(private _http: HttpClient,
    private _router: Router,
    kiwixiGlobals: KiwixiGlobals,
    public toastService: MzToastService) {
    super(_http, _router, kiwixiGlobals, toastService);
  }

  getSession(urlSession): Observable<Session> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Session>(urlSession, {
      headers: header
    }).pipe(
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
      }),
      catchError(this.handleErrorObservable<Session[]>(`getSession=${urlSession}`))
    ).map(result => <Session>result)
  }

  postSession(formation_url, occurrence_url, teacher_url) {
    const header = this.createHttpClientAuthorizationHeader();
    const body = {
      formation: formation_url,
      occurrence: occurrence_url,
      teacher: teacher_url,
    }
    return this._http.post<Session>(this.apiSession, body, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Session>(`Envoi de Session`))
    );
  }

  patchSession(sessionUrl, param): Promise<Session> {
    const headers = this.createHttpClientAuthorizationHeader();
    return this._http.patch(sessionUrl, param, { headers: headers })
      .toPromise()
      .then((res: any) => res)
      .catch(err => {
        this.toastService.show(`La mise à jour a échouée`, 2000, 'red');
      })
  }

  private sendDocument<Document>(file, session, from, to) {
    const apiDocument = environment.server + 'documents/';
    const header = this.createHttpClientAuthorizationHeaderNoEnctype();
    const _formData = new FormData();
    _formData.append('enctype', 'multipart/form-data');
    header.delete('Content-Type');
    _formData.append('source', file);
    _formData.append('name', file.name);
    _formData.append('session', session);
    _formData.append('from', from);
    _formData.append('to', to);
    return this._http.post(apiDocument, _formData, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Document>(`Envoi de fichier`))
    )
  }

  public sendDocuments<Message>(files, session, from, to) {
    const obx = [];
    for (const file of files) {
      obx.push(this.sendDocument(file, session, from, to))
    }
    return forkJoin<Document>(obx)
  }

  getObservableSessionById(id): Observable<Session> {
    const url = this.apiSession + id + '/';
    return this.getObservableSessionByUrl(url);
  }

  getObservableSessionByUrl(_url): Observable<Session> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Session>(_url, {
      headers: header
    })
      .pipe(
        catchError(this.handleErrorObservable<Session>(`getObservableSessionByUrl`))
      );
  }

  getObservableSessions(params: HttpParams): Observable<Session[]> {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.get<Session[]>(this.apiSession, {
      headers: header,
      params: params
    })
      .map(_trainings => _trainings['results'])
      .pipe(
        catchError(this.handleErrorObservable<Session[]>(`getSessions`))
      );
  }

  createSession(body) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.post(this.apiSession, body, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Session>(`Envoi de Session`))
    );
  }

  // TODO to clean!
  postNewSession(formation_url, body) {
    const header = this.createHttpClientAuthorizationHeader();
    return this._http.post<Session>(this.apiSession, body, {
      headers: header
    }).pipe(
      catchError(this.handleErrorObservable<Session>(`Envoi de Session`))
    );
  }

  getSessionsStatus() {
    return [
      {
        code: 'done',
        label: 'Pris',
        valid: true
      },
      {
        code: 'booked',
        label: 'À venir',
        valid: true
      },
      {
        code: 'postponed_by_student',
        label: 'Reporté par l\'élève',
        valid: true
      },
      {
        code: 'postponed_by_teacher',
        label: 'Reporté par le professeur',
        valid: true
      },
      {
        code: 'abs_teacher_without_justification',
        label: 'Absence du prof',
        valid: true
      },
      {
        code: 'abs_student_without_justification',
        label: 'Absence de l\'étudiant',
        valid: true
      },
      {
        code: 'cancelled_by_admin',
        label: 'Administrateur a annulé',
        valid: true
      },
      {
        code: 'old_report',
        label: 'Anciens processus d\'annulation',
        valid: true
      },
      {
        code: 'suspended',
        label: 'Cours suspendus',
        valid: true
      },
    ]
  }

  setStatusToSession(session_status, _sessionsService) {
    const status = this.getSessionsStatus();
    const idx = this.getSessionsStatus().map(val => val.code).indexOf(session_status);
    if (idx >= 0) {
      return status[idx]['label'];
    } else {
      return null;
    }
  }

}
