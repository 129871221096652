/**
 * Composant pour gérer le calendar.
 */

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-daily-calendar',
  templateUrl: './daily-calendar.component.html',
  styleUrls: ['./daily-calendar.component.scss']
})
export class DailyCalendarComponent implements OnInit {
  @Input() dateSelected;
  @Input() from;
  @Output() updateDateSelect = new EventEmitter();
  dateNow = new Date();
  month = this.dateNow.getMonth() + 1;
  day = this.dateNow.getDate();
  year = this.dateNow.getFullYear();
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];
  FebNumberOfDays = '28';
  dayPerMonth = ['31', '' + this.FebNumberOfDays + '', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
  calendarData = [];
  constructor() { }

  ngOnInit() {
    this.setCalendarData();
  }

  setDisableDate() { }

  /* affichage du mois precedent */
  setPreviousDay() {
    if (this.month === 1) {
      this.month = 12;
      this.year--;
    } else {
      this.month--;
    }
    this.setCalendarData();
  }

  /* affichage du mois suivant */
  setNextDay() {
    if (this.month === 12) {
      this.month = 1;
      this.year++;
    } else {
      this.month++;
    }
    this.setCalendarData();
  }

  /* méthode pour construire le calendar */
  setCalendarData() {
    const isLeapYear = moment([this.year]).isLeapYear();
    let numOfDays = Number(this.dayPerMonth[this.month - 1]);
    if (isLeapYear && this.month === 2) { // Si le mois courant est février dans une année bissexctile
      numOfDays = 29;
    }
    this.calendarData = []
    const myFirstDay = new Date(this.year, this.month - 1, 1);
    let countDay = 1;
    for (let k = 0; k < 6; k++) {
      this.calendarData.push({ cols: [] });
      for (let i = 0; i < 7; i++) {
        if (k === 0) {
          if (i < myFirstDay.getDay()) {
            this.calendarData[0]['cols'].push(0);
          } else {
            this.calendarData[0]['cols'].push(countDay);
            countDay++;
          }
        } else {
          if (countDay <= numOfDays) {
            this.calendarData[k]['cols'].push(countDay);
            countDay++;
          } else {
            this.calendarData[k]['cols'].push(0);
          }
        }
      }
    }
  }

  /* output du jour selectionnée vers le component parent */
  selectDay(_counter) {
    const myMonth = this.month - 1;
    const myDate = new Date(this.year, myMonth, _counter);
    this.updateDateSelect.emit(myDate);
  }

  setStyle(_item) {
    const _date = new Date(this.year, this.month - 1, _item, 12, 0, 0, 0);
    const _dateSel = moment([moment(this.dateSelected).year(), moment(this.dateSelected).month(), moment(this.dateSelected).date(), 12, 0, 0, 0]);
    const _current_date = new Date(moment().year(), moment().month(), moment().date(), 12, 0, 0, 0);
    if (this.from) {
      if (moment(_date).isBefore(moment(this.from))) {
        return 'disable-day';
      }
    }
    if (moment(_current_date).isSame(moment(_date)) && _dateSel.isSame(moment(_date))) {
      return 'current-day-select';
    } else {
      if (moment(_current_date).isSame(moment(_date))) {
        return 'current-day';
      }
      if (_dateSel.isSame(moment(_date))) {
        return 'day-select';
      }
    }
    return false
  }


}
