import { Component, OnInit, Input } from '@angular/core';
import { TeacherService } from '../../../service/teacher.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-datatable-cell-teacher',
  templateUrl: './datatable-cell-teacher.component.html',
  styleUrls: ['./datatable-cell-teacher.component.scss']
})
export class DatatableCellTeacherComponent implements OnInit {

  @Input() teacher;
  my_teacher: Object;
  defaultAvatar = environment.static + '/avatar.png';
  errorMessage = '';
  loading = true;

  constructor(private teacherService: TeacherService) { }

  ngOnInit() {
    if (!(typeof (this.teacher) === 'object')) {
      this.teacherService.getObservableTeacherByUrl(this.teacher)
        .subscribe(_teacher => {
          this.my_teacher = _teacher;
          this.loading = false;
        },
          error => {
            this.errorMessage = <any>error;
          })
    } else {
      this.loading = false;
      this.my_teacher = this.teacher;
    }
  }

}
