import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  showMenu = false;
  defaultAvatar = environment.static + '/avatar.png';
  constructor() { }

  ngOnInit() { }

  /**
   * event au clic en dehors du menu
   */
  handleOutside() {
    if (this.showMenu) {
      this.showMenu = false;
    }
  }
}
