<ng-container >
<div [ngClass]="{'blocker': noMoreTime==true}">

  <div class="blocker-veil"></div>
  <ng-container *ngIf="chooseTeacher">
    <div class="substep-title" translate>① <span translate>feature.ponctual-schedule.Calendrier de :</span></div>
    <div>  
      <mat-select class="book-select-teacher" [formControl]="teacher_FormControl" >
        <mat-option *ngFor="let teacher of formation.teachers" [value]="teacher" >
          <span>{{teacher.user.first_name}}</span>
        </mat-option>
        <mat-option value="new" >
          <span translate>feature.ponctual-schedule.Nouveau Professeur</span>
        </mat-option>
      </mat-select>
    </div>
  </ng-container>

  <div class="substep-title" *ngIf="!chooseTeacher">
    <span>①</span>
    <span translate>feature.ponctual-schedule.Date :</span>
  </div>

  <div class="row calendar-container" *ngIf="modeCalendar">
      <app-calendar
        (updateDateSelect)= "handleUpdateDateSelect($event)"
        (updateAvailables)= "handleUpdateAvailables($event)"
        [availablesDays]="availablesDays"
        [loadDisponibilities]="loadDisponibilities"
        [myTeacher]="teacher">
      </app-calendar>
  </div>

  <div class="row radio-container" *ngIf="!modeCalendar">
      <app-radio-availabilities
        (updateDateSelect)= "handleUpdateDateSelect($event)"
        [availablesDays]="availablesDays">
      </app-radio-availabilities>
  </div>

  <div class="row center" *ngIf="loadDisponibilities">
      Loading
  </div>

  <div class="timepicker-wrap" [ngClass]="{'disabled': startTime.length==0}"> 
      <div class="substep-title">
        <span>②</span> 
        <span translate>feature.ponctual-schedule.Horaire :</span>
      </div>
      <div class="picktime-row">
      <div class="picktime-text"><span translate>common.from</span> :</div>
      <mat-select class="picktime-select" [formControl]="slotStart_FormControl" [disabled]="startTime.length==0"  >
        <mat-option *ngFor="let start of startTime" (click)="initEnd(start.slot)" [value]="start.date" [disabled]="!start.enable" >
          <span translate>{{start.str }}</span>
        </mat-option>
      </mat-select>
      <div class="picktime-text"><span translate>common.to</span> :</div>
      <mat-select class="picktime-select" [formControl]="slotEnd_FormControl" [disabled]="endTime.length==0 || !slotStart_FormControl.value">
        <mat-option *ngFor="let end of endTime" [value]="end.date" [disabled]="!end.enable">
          <span translate>{{end.str }}</span>
        </mat-option>
      </mat-select>

    <button (click)="chooseSlot()" [ngx-scroll-to]="'#confirm-block'"  *ngIf="slotEnd_FormControl.value && multiSlot" translate>feature.ponctual-schedule.Ajouter</button>
    </div>
  </div>
</div><!--End Blocker-->

<div class="selection-wrap" [ngClass]="{'disabled': classToCreate.length==0}">
  <ng-container *ngIf="multiSlot">
    <div class="substep-title">③ <span translate>feature.ponctual-schedule.Votre sélection :</span></div>
    <div class="confirmslot-row" *ngFor="let slot of classToCreate">
      <span class="agenda-icon"></span>
      <span class="confirmslot-date" *ngIf="formation.mode_formation=='standard'" translate [translateParams]="{day: slot.start | amLocale: getCurrentLanguage() | amDateFormat:'dddd DD MMMM', start: slot.start | date :'HH:mm' , end: slot.end | date :'HH:mm' }">lingueopro.plan-formation.schedule.resume-slot</span>
      <span class="confirmslot-date" *ngIf="formation.mode_formation=='lilate'" translate [translateParams]="{day: slot.start | amLocale: getCurrentLanguage() | amDateFormat:'dddd DD MMMM', start: slot.start | date :'HH:mm' , end: slot.end | date :'HH:mm' }">lingueopro.plan-formation.schedule.resume-slot-lilate</span>

      <button class="delete-btn" (click)="deleteClassToCreate(slot)" [disabled]="loaderWritePlanning" *ngIf="multiSlot"></button>
    </div>
  </ng-container>
  <button id="confirm-block" *ngIf="formation.hours_to_plan>0" [disabled]="classToCreate.length==0 || loaderWritePlanning==true" class="major-btn" (click)="saveBooking()" translate>
    <div *ngIf="loaderWritePlanning">
      <span class="loader-wheel"></span>
      <span translate>feature.ponctual-schedule.Réservation en cours...</span>
    </div>
    <div *ngIf="!loaderWritePlanning" translate>
      <ng-container *ngIf="formation.mode_formation=='standard'">
        <span translate>feature.ponctual-schedule.Réserver</span>
        <span *ngIf="classToCreate.length==1" translate>feature.ponctual-schedule.ce cours</span>
        <span *ngIf="classToCreate.length==0" translate>feature.ponctual-schedule.un cours</span>
        <span *ngIf="classToCreate.length>1" translate>feature.ponctual-schedule.ces cours</span>
      </ng-container>
      <ng-container *ngIf="formation.mode_formation=='lilate'">
        <span translate>feature.ponctual-schedule.Réserver votre LILATE</span>
      </ng-container>
    </div>
  </button>

</div>

  <div class="help-link" (click)="showHelp=!showHelp" translate>feature.ponctual-schedule.Je ne suis pas disponible à ces dates. Que faire ?</div>

  <div class="help-popup" *ngIf="showHelp">
    <div class="popupcontent">
      <div class="popupbody">
        <button class="closepopup" (click)="showHelp=!showHelp"></button>
        <ng-container *ngIf="formation.mode_formation=='standard'">
          <div class="popup-title" *ngIf="firstClassTraining" translate>feature.ponctual-schedule.Réserver votre premier cours - Aide</div>
          <div class="popup-title" *ngIf="!firstClassTraining" translate>feature.ponctual-schedule.Réserver vos cours - Aide</div>

          <div class="popup-copy">
            <span *ngIf="firstClassTraining" translate>feature.ponctual-schedule.Pour réserver votre premier cours</span>
            <span *ngIf="!firstClassTraining" translate>feature.ponctual-schedule.Pour réserver vos cours</span>
            <span translate>feature.ponctual-schedule.nous vous affichons les disponibilités actuelles de l'ensemble de nos formateurs dans les 7 prochains jours. Par conséquent, ces disponibilités peuvent varier d'un jour à l'autre. Si aucune des propositions ne vous conviennent, nous vous invitons à réessayer demain.</span>
            
            <div class="popup-highlight" translate>feature.ponctual-schedule.Si la difficulté persiste, merci de contacter notre équipe.</div>
          </div>
        </ng-container>

        <ng-container *ngIf="formation.mode_formation=='lilate'">
          <div class="popup-title" translate>feature.ponctual-schedule.Réserver votre passage LILATE - Aide</div>
          <div class="popup-copy">
            <span [innerHtml]="'feature.ponctual-schedule.help-lilate-text' | translate"></span>
            <div class="popup-highlight" translate>feature.ponctual-schedule.Si la difficulté persiste, merci de contacter notre équipe.</div>
          </div>
        </ng-container>
        <button class="popup-btn" (click)="showHelp=!showHelp" translate>common.OK</button>
      </div>
    </div>
    <div class="popupbackground"></div>
  </div>

