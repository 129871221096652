import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule, MatDatepickerModule, MatProgressSpinnerModule } from '@angular/material';
import { MaterializeModule } from 'ng2-materialize';
import { FeatureModule } from '../../../../feature/feature.module';
import { DailyCalendarComponent } from './daily-calendar/daily-calendar.component';
import { DailyComponent } from './daily.component';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { TimelineEventComponent } from './timeline-event/timeline-event.component';
import { TimelineRowComponent } from './timeline-event/timeline-row/timeline-row.component';
import { TimelineRowAvailableComponent } from './timeline-event/timeline-row-available/timeline-row-available.component';
import { TimelineRowSessionComponent } from './timeline-event/timeline-row-session/timeline-row-session.component';
import { TimelineRowUnavailableComponent } from './timeline-event/timeline-row-unavailable/timeline-row-unavailable.component';
import { TimelineRowTeacherFirstnameComponent } from './timeline-event/timeline-row-teacher-firstname/timeline-row-teacher-firstname.component';
import { EventStudentNameComponent } from './timeline-event/timeline-row-session/event-student-name/event-student-name.component';
import { DirectiveModule } from '../../../../directive/directive.module';

import {
  GoogleApiModule,
  GoogleApiService,
  GoogleAuthService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig
} from 'ng-gapi';
const gapiClientConfig: NgGapiClientConfig = {
  // client_id: '166968030463-hi0cat4v0kgbv0ikkfl3lj8b1u4d14n8.apps.googleusercontent.com',
  client_id: '269136951987-6jlp384c34l6qnp2kaj56geok436ship.apps.googleusercontent.com',
  discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],
  scope: [
    'https://www.googleapis.com/auth/calendar',
  ].join(' ')
};
@NgModule({
  imports: [
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    CommonModule,
    MatSelectModule,
    FeatureModule,
    MatDatepickerModule,
    MomentModule,
    MatProgressSpinnerModule,
    MaterializeModule.forRoot(),
    TranslateModule.forChild(),
    DirectiveModule
  ],
  declarations: [
    DailyComponent,
    DailyCalendarComponent,
    TimelineEventComponent,
    TimelineRowComponent,
    TimelineRowAvailableComponent,
    TimelineRowSessionComponent,
    TimelineRowUnavailableComponent,
    TimelineRowTeacherFirstnameComponent,
    EventStudentNameComponent
  ],
  exports: [
    DailyCalendarComponent
  ]
})
export class DailyModule { }
