import { Student } from './../../model/student.model';
import { HttpParams } from '@angular/common/http';
import { ExerciceService } from './../../service/exercice.service';
import { Exercice } from './../../model/exercice.model';
import { ExerciceSheetService } from './../../service/exerciceSheet.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ExerciceSheet } from '../../model/exerciceSheet.model';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-sheet-view',
  templateUrl: './sheet-view.component.html',
  styleUrls: ['./sheet-view.component.scss']
})
export class SheetViewComponent implements OnChanges {
  @Input() sheet: ExerciceSheet;
  @Input() showExercices: Boolean;
  @Input() showExamination: Boolean;
  @Input() filterByStudent: Student;
  @Output() exerciceToShow = new EventEmitter();
  @Output() examinationToShow = new EventEmitter();
  iframe_video;
  content;
  to_remember;
  student: Student;
  exercices: Exercice[];
  easyExercice: Exercice = null;
  mediumExercice: Exercice = null;
  hardExercice: Exercice = null;
  examinationExercices: Exercice[] = [];
  examinationDuration = 0;
  constructor(
    private embedService: EmbedVideoService,
    private sanitizer: DomSanitizer,
    private exerciceSheetService: ExerciceSheetService,
    private exerciceService: ExerciceService,
  ) { }


  ngOnChanges() {
    this.student = this.filterByStudent;
    if (this.sheet) {
      if (this.sheet.media) {
        if (this.sheet.media.media_type === 'video' && this.sheet.media.source_type === 'url') {
          this.loadVideo();
        }
      }
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.sheet.content);
      this.to_remember = this.sanitizer.bypassSecurityTrustHtml(this.sheet.to_remember);
      if (this.showExercices || this.showExamination) {
        this.getExercices();
      }
    }

  }

  getExaminationDuration() {
    this.examinationDuration = this.examinationExercices.map(e => e.duration).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  loadVideo() {
    this.iframe_video = this.embedService.embed(this.sheet.media.url);
  }

  changeSheet(sheetId) {
    this.exerciceSheetService.getExerciceSheetById(sheetId).subscribe(sheet => {
      if (sheet) {
        this.sheet = sheet;
      }
    })
  }

  getExercices() {
    let params = new HttpParams()
    params = params.append('status', 'published');
    params = params.append('sheets', this.sheet.id.toString());
    params = params.append('salt', Math.random().toString());
    if (this.student) {
      params = params.append('is_validate_by_student', this.student.id.toString());
    }
    this.exerciceService.getExercices(params).subscribe(exercices => {
      this.exercices = exercices.results;
      if (this.showExercices) {
        this.initExercice();
      }
    })
  }

  getRandomExaminationExercices() {
    let list = this.exercices.filter(exercice => exercice.difficulty === 'hard')
    if (list.length >= 1 && list.length <= 3) {
      this.examinationExercices = list;
    }
    if (list.length > 3) {
      while (this.examinationExercices.length < 3) {
        let index = Math.floor(Math.random() * list.length);
        let exist = this.examinationExercices.filter(exercice => exercice.id === list[index].id)
        if (exist.length === 0) {
          this.examinationExercices.push(list[index])
        }
      }
    }
    this.getExaminationDuration();
  }

  initExercice() {
    this.getRandomExaminationExercices();

    let list = this.exercices.filter(exercice => exercice.difficulty === 'easy' && exercice.is_validate_by_student === false)
    if (list.length > 0) {
      this.easyExercice = list[Math.floor(Math.random() * list.length)];
    }

    list = this.exercices.filter(exercice => exercice.difficulty === 'medium' && exercice.is_validate_by_student === false)
    if (list.length > 0) {
      this.mediumExercice = list[Math.floor(Math.random() * list.length)];
    }

    list = this.exercices.filter(exercice => exercice.difficulty === 'hard' && exercice.is_validate_by_student === false)
    if (list.length > 0) {
      this.hardExercice = list[Math.floor(Math.random() * list.length)];
    }
  }

  gotoExamination() {
    this.examinationToShow.emit();
  }

  goToExercice(exercice) {
    this.emitExerciceToShow([exercice.id]);
  }

  emitExerciceToShow(exerciceList) {
    this.exerciceToShow.emit(exerciceList);
  }
  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

}
