import { NewUser } from './new-user.model';
import { Language } from './language.model';

export class NewTeacher {
  url: string;
  avatar: string;
  accept_students: boolean;
  rank: number;
  user: NewUser;
  description_fr: string;
  description_vo: string;
  teach_language: Language;
  languages: Language[];
  teach_on: Object[];
  score: number;
}
