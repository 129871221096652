/**
 * Service local pour la liste des formations
 */

import { Injectable } from '@angular/core';
import { FormationService } from '../../../../service/formation.service';


@Injectable()
export class ListeFormationsService {

  // Colonnes du tableau
  public dataHeaders = [
    {
      name: 'id',
      title: 'ID Form.',
      type: 'string',
      ascending: false,
      filter_key: 'id',
      ordering_key: 'id',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'status',
      title: 'Statut',
      type: 'string',
      ascending: false,
      filter_key: 'status__icontains',
      ordering_key: 'status',
      filter_type: 'select',
      list: this.formationService.getStatus(),
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'student',
      title: 'Eleve',
      type: 'student',
      ascending: false,
      filter_key: 'student_name',
      ordering_key: 'student__user__last_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'student_email',
      title: 'Email Eleve',
      type: 'student_email',
      ascending: false,
      filter_key: 'student__user__email__icontains',
      ordering_key: 'student__user__email',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'formation',
      title: 'Formation',
      type: 'formation',
      ascending: false,
      filter_key: 'name__icontains',
      ordering_key: 'name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'teachers',
      title: 'Professeurs',
      type: 'teachers',
      ascending: false,
      filter_key: 'teachers_name',
      ordering_key: 'teachers__user__last_name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'site',
      title: 'Site',
      type: 'site',
      ascending: false,
      filter_key: 'site__name__icontains',
      ordering_key: 'site__name',
      filter_type: 'text',
      cell_edit: false,
      isEdit: false
    },
    {
      name: 'valide',
      title: 'Valide',
      type: 'date_range',
      ascending: false,
      filter_key: 'validity_date',
      ordering_key: 'validity_date',
      filter_type: 'calendar',
      cell_edit: false,
      isEdit: false,
      type_filtrage: 'date'
    }
  ]

  constructor(private formationService: FormationService) { }
}
