import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../../service/language.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-datatable-cell-language',
  templateUrl: './datatable-cell-language.component.html',
  styleUrls: ['./datatable-cell-language.component.scss']
})
export class DatatableCellLanguageComponent implements OnInit {

  @Input() language;
  my_language: Object;
  defaultAvatar = environment.static + '/avatar.png';
  errorMessage = '';
  loading = true;

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    if (!(typeof (this.language) === 'object')) {

      this.languageService.getObservableLanguageWithUrl(this.language)
        .subscribe(_site => {
          this.my_language = _site;
          this.loading = false;
        },
          // handle the error, otherwise will break the Observable
          error => {
            this.errorMessage = <any>error;
          });
    } else {
      this.loading = false;
      this.my_language = this.language;
    }
  }

}
