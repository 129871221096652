import { DownloadLingueoService } from './../../../../../../../directive/download-lingueo/download-lingueo.service';
/**
 * Sous-composant pour les informations administratifs.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Teacher } from '../../../../../../../model/teacher.model';
import { DocumentService } from '../../../../../../../service/document.service';
import { TeacherService } from '../../../../../../../service/teacher.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CountryService } from '../../../../../../../service/country.service';
import { HttpParams } from '@angular/common/http';
import { EditTeacherService } from '../../edit-teacher.service';
import { Country } from '../../../../../../../model/country.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: [
    './admin.component.scss',
    './admin.ux.scss',
    '../../edit-teacher.ux.scss',
    '../../../../mainscreen.ux.scss'
  ],
  providers: [DocumentService, CountryService, EditTeacherService]
})
export class AdminComponent implements OnInit {
  @Input() myTeacher: Teacher;
  @Input() parentComponent;
  originalTeacher: Teacher;
  documents: any[];
  listStatus = [
    {
      label: 'Validé',
      code: 'accepted',
      valid: true
    },
    {
      label: 'Rejeté',
      code: 'refused',
      valid: true
    },
    {
      label: 'En attente',
      code: 'pending',
      valid: true
    }
  ];
  listPayments = [
    {
      label: 'Iban',
      code: 'iban',
      valid: true
    },
    {
      label: 'Paypal',
      code: 'paypal',
      valid: true
    }
  ];
  requestCountriesUrl = null;
  searchLabel = '';
  countPages = 1;
  countrySelect = null;
  countries: Country[];
  myControl: FormControl = new FormControl();
  myForm: FormGroup = this.builder.group({
    country: this.myControl
  });

  excludeRegExp = /[!@#$%^&°*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  errors = {
    address: false,
    city: false
  }

  filteredOptions: Observable<Country[]>;

  constructor(
    private builder: FormBuilder,
    private teacherService: TeacherService,
    private countryService: CountryService,
    private downloadService: DownloadLingueoService) { }

  ngOnInit() {
    this.countries = [];
    this.getAllCountries();
    this.originalTeacher = new Teacher();
    for (const key in this.myTeacher) {
      if (this.myTeacher.hasOwnProperty(key) && typeof key !== 'function') {
        this.originalTeacher[key] = this.myTeacher[key]
      }
    }
  }

  /**
   * Méthode pour récupérer la liste des pays.
   */
  getAllCountries() {
    const params = new HttpParams()
      .set('label__icontains', this.searchLabel)
      .set('page', this.countPages.toString())
    this.countryService.getCountries(params)
      .subscribe(_countries => {
        for (const country of _countries['results']) {
          if (this.myTeacher['country'] === country.url) {
            this.countrySelect = country;
            this.myControl.setValue(country);
          }
          this.countries.push(country)
        }
        if (_countries['next'] !== null) {
          this.countPages++;
          this.getAllCountries();
        } else {
          this.countPages = 1;
          this.filteredOptions = this.myControl.valueChanges
            .startWith('')
            .map(val => this.filterCountries(val))
        }
      });
  }

  /**
   * Méthode pour filtrer la liste des pays.
   * @param val
   */
  filterCountries(val): Country[] {
    if (val.label) {
      return this.countries.filter(option =>
        option.label.toLowerCase().indexOf(val.label.toLowerCase()) === 0);
    } else {
      return this.countries.filter(option =>
        option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
    }
  }

  /**
   * Évenement au select du pays.
   * @param country
   */
  selectCountry(country) {
    this.myTeacher['country'] = country.url;
    this.updateTeacher(this.myTeacher, 'country');
    this.countrySelect = country;
  }

  /**
   * Méthode pour afficher le label du pays.
   * @param country
   */
  displayCountryFn(country) {
    return country ? country.label : country;
  }

  /**
   * Évenement au focus out sur l'input de l'adresse.
   */
  focusOutAddress() {
    const param = {
      address: this.myTeacher.address
    }
    this.teacherService.patchTeacher(this.myTeacher.url, param)
  }

  checkMandatoryFields(field, fieldValue) {
    if (field === 'address' || field === 'cp' || field === 'city') {
      if (fieldValue === '' || fieldValue === undefined) {
        this.teacherService.toastService.show('Le champ suivant est obligatoire : ' + field, 4000, 'red');

        return false;
      }
      if ((field === 'address' || field === 'city') && this.excludeRegExp.test(fieldValue)) {
        field === 'address' ? this.errors.address = true : this.errors.city = true;
        return false;
      }
      if ((field === 'address' || field === 'city') && !this.excludeRegExp.test(fieldValue)) {
        field === 'address' ? this.errors.address = false : this.errors.city = false;
        return true;
      }
    }
    return true;
  }
  /**
   * méthode pour mettre à jour un prof.
   * @param teacher
   * @param field
   */
  updateTeacher(teacher, field) {
    if (!this.checkMandatoryFields(field, teacher[field])) {
      return
    }
    const param = {};
    param[field] = teacher[field];
    if (this.originalTeacher[field] !== param[field]) {
      this.teacherService.patchTeacher(teacher.url, param).then(
        _teacher => {
          this.originalTeacher[field] = _teacher[field];
          // this.parentComponent.refreshLogs();
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    }
  }

  /**
   * Méthode pour upalod un fichier.
   * @param $event
   * @param field
   */
  uploadFile($event, field) {
    for (const file of $event.target.files) {
      const param = {};
      param[field] = this.myTeacher[field];
      this.teacherService.patchFile(this.myTeacher.url, param, field, file)
        .then(_teacher => {
          this.myTeacher = _teacher;
          // this.parentComponent.refreshLogs();
        });
    }
  }

  /**
   * vérifier si l'iban est valid.
   * @param _iban
   */
  ibanIsValid(_iban) {
    if (_iban !== null && _iban !== '') {
      if (_iban.length === 27) {
        return 'valid';
      }
      return 'invalid';
    } else {
      return '';
    }
  }
}
