<form class="noclass-forms" [formGroup]="noSessionForm" (ngSubmit)="sendReport()">
  <p translate>{{translatePath}}.reason-label</p>
  <mat-form-field>
    <mat-select [formControl]="reasonCtrl" name="reason">
      <mat-option *ngFor="let reason of reasons" [value]="reason" >
        <span translate>{{translatePath}}.{{reason.keyvalue}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

   <div class="abs_warning_msg" *ngIf="reasonCtrl.value.status=='abs_teacher_without_justification'"><span translate>{{translatePath}}.abs_teacher_warning_msg</span></div>
   <div class="abs_warning_msg" *ngIf="reasonCtrl.value.status=='abs_student_without_justification'"><span translate>{{translatePath}}.abs_student_warning_msg</span></div>
        
  <span translate>{{translatePath}}.additional-label</span>
  
    <textarea matInput [formControl]="detailsCtrl" name="details"></textarea>

  <div class="report-buttons-row">
    <button class="action-btn suboption" routerLink="../../../reports">
      <span translate>{{translatePath}}.complete-later-btn</span>
    </button>
    <button class="action-btn" type="submit" [disabled]="!reasonChange">
      <span translate>{{translatePath}}.send-report-btn</span>
    </button>
  </div>
</form>
  