import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Formation } from '../../model/formation.model';
import { Occurrence } from '../../model/occurrence.model';
import { FormationService } from '../../service/formation.service';

@Component({
  selector: 'app-previous-occurences',
  templateUrl: './previous-occurences.component.html',
  styleUrls: ['./previous-occurences.component.scss']
})
export class PreviousOccurencesComponent implements OnInit {
  @Input() pastOccurrences: Occurrence[];
  @Input() occurrencesResult: Occurrence[];
  @Input() formation: Formation;
  @Output() showMoreEmitter = new EventEmitter();
  componentToLoad: string;
  modalParams: Object;

  translatePath = 'lingueopro.dashboard.past_sessions';

  constructor(
    private formationService: FormationService,
  ) { }

  ngOnInit() {
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

  /**
   * Méthode pour afficher ou non le message du teacher.
   * @param occurrence
   */
  checkTeacherReportMessage(occurrence) {
    if (occurrence.session && occurrence.session.rate_teacher) {
      return true;
    }
    return false;
  }

  showModalTeacherProfil(teacher) {
    this.componentToLoad = 'teacherProfil';
    this.modalParams = { teacher: teacher, showReviews: false }
  }

  showMore() {
    this.showMoreEmitter.emit(true);
  }

}
