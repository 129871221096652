<div class="login-screen" id="myCheck">
  <div class="login-wrapper">
    <form>
      <div class="row">
        <div class="login-logo"></div>
      </div>
      <div class="lostpassword-illu"></div>
      <div class="lostpassword-help" translate>{{translatePath}}.title</div>

      <mat-form-field class="form-field-position">
        <input matInput id="email-input" emailInput type="email" name="email" placeholder="Email"
          [formControl]="emailFormControl" >
          <mat-error *ngIf="emailFormControl.dirty &&  emailFormControl.hasError('required')" translate>{{translatePath}}.Email is required</mat-error>
          <mat-error *ngIf="emailFormControl.dirty &&  emailFormControl.hasError('email')"translate>{{translatePath}}.Email is invalid</mat-error>
      </mat-form-field>


      <div>
        <button [disabled]="!emailFormControl.valid" type="input" (click)="sendMail()" class="login-btn" translate>{{translatePath}}.Envoyer lien</button>
      </div>
    </form>
  </div>
</div>