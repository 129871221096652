/**
 * Module des Rapports.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportComponent } from './report/report.component';
import { Step1Component } from './report/step1/step1.component';
import { Step2Component } from './report/step2/step2.component';
import { Step2firstComponent } from './report/step2first/step2first.component';
import { InfoComponent } from './report/info/info.component';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureModule } from '../../../feature/feature.module';
import { MomentModule } from 'ngx-moment';
import { ListReportComponent } from './list/list.component';
import { MatProgressSpinnerModule, MatSelectModule, MatInputModule, MatRadioModule, MatCheckboxModule } from '@angular/material';
import { MaterialModule } from '../../../material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ReportService } from './report.service';
import { ValidSessionComponent } from './report/step1/valid-session/valid-session.component';
import { CancelSessionComponent } from './report/step1/cancel-session/cancel-session.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReportSheetAssignComponent } from './report-sheet-assign/report-sheet-assign.component';
import { ConfirmIdentityComponent } from './report/step1/confirm-identity/confirm-identity.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    FeatureModule,
    ReportsRoutingModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MaterialModule,
    MatInputModule,
    MomentModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
  ],
  declarations: [
    ReportsComponent,
    ReportComponent,
    Step1Component,
    Step2Component,
    Step2firstComponent,
    InfoComponent,
    ListReportComponent,
    ValidSessionComponent,
    CancelSessionComponent,
    ReportSheetAssignComponent,
    ConfirmIdentityComponent,
  ],
  exports: [
    ReportComponent,
    InfiniteScrollModule,
  ],
  providers: [ReportService]
})
export class ReportsModule { }
