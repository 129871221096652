<div class="setup-training-wrap container usual-container">
  <div class="steps-wrapper">
      <div class="onboarding-step step-birthdate complete-step"><div class="step-icon"></div></div>
      <div *ngIf="formation.student.user.video_provider=='skype'" class="onboarding-step step1 complete-step"><div class="step-icon"></div></div>
      <div class="onboarding-step step2 complete-step"><div class="step-icon"></div></div>
      <div class="onboarding-step step3 current-step"><div class="step-icon"></div></div>
      <div class="onboarding-step step4"><div class="step-icon"></div></div>
  </div>

  <div *ngIf="!showScreen">Loading</div>
  <div *ngIf="showScreen">
    
    <div class="onboarding-subheading" translate *ngIf="formation.mode_formation=='standard'">lingueopro.init-ponctual-booking.Réserver votre premier cours</div>
    <div class="onboarding-subheading" translate *ngIf="formation.mode_formation=='lilate'">lingueopro.init-ponctual-booking.Réserver votre LILATE</div>

    <div translate *ngIf="formation.mode_formation=='standard'">lingueopro.init-ponctual-booking.Choisissez la date et l’heure de votre premier cours parmi les disponibilités des prochains jours :</div>
    <div translate *ngIf="formation.mode_formation=='lilate'">lingueopro.init-ponctual-booking.Choisissez la date et l'heure de passage de votre LILATE parmi les disponibilités des prochains jours :</div>

    <app-ponctual-schedule 
      [formation]="formation" 
      [chooseTeacher]="false" 
      [maxDate]="maxDate"
      [maxDateFullDispo]="maxDateFullDispo" 
      [multiSlot]="false" 
      [modeCalendar]="true"
      [firstClassTraining]="true"
      (classCreated)="showCreated($event)"
    >
    </app-ponctual-schedule>

  </div>
  <app-modal [componentToLoad]="componentToLoad" [modalParams]="modalParams" [allowClose]="false" (emitEvent)="showDemo()"></app-modal>

</div>