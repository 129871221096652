import { LostPasswordComponent } from './../../feature/lost-password/lost-password.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// from lingueo
import { PageNotFoundComponent } from '../../feature/page-not-found/page-not-found.component';
import { LoginComponent } from '../../feature/login/login.component';

// from Lingueopro
import { ResetPasswordComponent } from '../../feature/reset-password/reset-password.component';
import { MaintenanceGuard } from '../../guard/maintenance.guard';
import { MaintenanceComponent } from '../../feature/maintenance/maintenance.component';

export const routes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'login', component: LoginComponent, canActivate: [MaintenanceGuard] },
  { path: 'lost-password', component: LostPasswordComponent, canActivate: [MaintenanceGuard] },
  { path: 'reset-password/:uid/:token', component: ResetPasswordComponent, data: { site: 'lingueopro' }, canActivate: [MaintenanceGuard] },
  { path: '', component: LoginComponent, canActivate: [MaintenanceGuard] },
  { path: '**', component: PageNotFoundComponent, canActivate: [MaintenanceGuard] },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })
    // { enableTracing: true })
  ],
  exports: [RouterModule]
})
export class LingueoproRoutingModule { }
