import { Component, OnInit, Input } from '@angular/core';
import { StudentService } from '../../../service/student.service';

@Component({
  selector: 'app-datatable-cell-student-email',
  templateUrl: './datatable-cell-student-email.component.html',
  styleUrls: ['./datatable-cell-student-email.component.scss'],
  providers: [StudentService]
})
export class DatatableCellStudentEmailComponent implements OnInit {
  @Input() student;
  my_student: Object;
  errorMessage = '';
  loading = true;

  constructor(private studentService: StudentService) { }

  ngOnInit() {

    if (!(typeof (this.student) === 'object')) {
      this.studentService.getObservableStudent(this.student)
        .subscribe(_student => {
          this.my_student = _student;
          this.loading = false;
        },
          error => {
            this.errorMessage = <any>error;
          })
      //   this.studentService.getStudentWithUrl(this.student).then(
      //    requestData => {
      //      // name or object ?
      //      this.my_student = requestData;
      //      this.loading = false;
      //    },
      //    // handle the error, otherwise will break the Observable
      //    error => {
      //      this.errorMessage = <any>error;
      //    }
      //  );
    } else {
      this.loading = false;
      this.my_student = this.student;
    }
  }
}
