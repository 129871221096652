/**
 * Composant pour l'affichage du dashboard.
 * Contient le details du prochain cours & des notifications.
 */

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss']
})
export class RightMenuComponent implements OnInit {
  defaultAvatar = environment.static + '/avatar.png';
  translatePath = 'teacher.dashboard-teacher';
  constructor() { }

  ngOnInit() { }
}
