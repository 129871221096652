/**
 * Composant pour l'affichage de l'evolution d'un rapport.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../../report.service';
import { SessionService } from '../../../../../service/session.service';
import { FormationService } from '../../../../../service/formation.service';
import { Location } from '@angular/common';
import { TeacherService } from '../../../../../service/teacher.service';
import { AuthenticationService } from '../../../../../service/authentification.service';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  ratingStudent_id: any;
  session_id: any;
  ratingStudent: any;
  myReport: any;
  myReportTeacher: any;
  previousReport: any;
  formation: any;
  session: any;
  sessionTeacher: any;
  nextMessage: any;
  translatePath = 'teacher.reports';
  myUser: any;
  callServiceFormation;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private formationService: FormationService,
    private _location: Location,
    private reportService: ReportService,
    private teacherService: TeacherService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.session_id = this.route.snapshot.params.id;
    this.authenticationService.currentUserSubject.subscribe(
      user => {
        if (user && user.hasOwnProperty('user')) {
          this.myUser = user;
          this.sessionService.getObservableSessionById(this.session_id)
            .subscribe(_session => {
              this.session = _session;
              if (this.session) {
                if (this.session.next_session) {
                  this.sessionService.getObservableSessionByUrl(this.session.next_session)
                    .subscribe(_next_session => {
                      this.nextMessage = _next_session['message'];
                    })
                }
                if (this.session.previous_session) {
                  this.sessionService.getObservableSessionByUrl(this.session.previous_session)
                    .subscribe(_previous_session => {
                      if (this.session.rate_teacher) {
                        this.myReport = this.reportService.getLastReportData(this.session.rate_teacher);
                      }
                      if (_previous_session.rate_teacher) {
                        this.previousReport = this.reportService.getLastReportData(_previous_session.rate_teacher);
                      }
                      this.checkEvolution();
                    })
                } else {
                  if (this.session.rate_teacher) {
                    this.myReport = this.reportService.getLastReportData(this.session.rate_teacher);
                  }
                }
                this.callServiceFormation = this.formationService.getObservableFormationByUrl(this.session.formation)
                  .subscribe(
                    _formation => {
                      this.formation = _formation;
                    }
                  );
                this.teacherService.getObservableTeacherByUrl(this.session.teacher)
                  .subscribe(_teacher => {
                    this.myReportTeacher = _teacher
                  })
              }
            });
        }
      }
    )
  }

  /**
   * Check evolution actual report vs previous
   */
  checkEvolution() {
    if (this.previousReport) {
      this.previousReport.forEach(_previous => {
        for (const _actual of this.myReport) {
          if (_previous.name === _actual.name) {
            if (_actual.level.value === 100 && _previous.level.start_label > _actual.level.start_label) { // #1 - regression
              _previous.level.value = 101;
            } else if (_actual.level.value === 10 && _previous.level.start_label < _actual.level.start_label) {  // #2 - progression
              _previous.level.value = 0;
            }
          }
        }
      });
    }
  }

  previousStep() {
    this._location.back();
  }

  ngOnDestroy() {
    if (this.callServiceFormation) {
      this.callServiceFormation.unsubscribe()
    }
  }

}
