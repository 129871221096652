<p *ngIf="loading; else student">
  Chargement ...
</p>
<ng-template #student>
  <div class="center truncate" *ngIf="my_student.user.email !== null">
    {{ my_student.user.email }}
  </div>
  <div *ngIf="my_student.user.email === null">
    --
  </div>
</ng-template>
