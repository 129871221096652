import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as moment from 'moment';
import { eventType } from "../../service/calendar.service";

@Component({
    selector: 'modal-event',
    templateUrl: 'modal-event.html',
    styleUrls: ['./modal-event.component.scss']
  })
  export class ModalEvent {
    allSlots = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30'
      , '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30'
      , '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59']
  
    availablesStartSlots = null;
    availablesEndSlots = null;
    allEndSlots = null;
    event;
    eventEnd: string;
    eventStart: string;
    validity = true;
    type = '';

    constructor(public dialogRef: MatDialogRef<ModalEvent>, @Inject(MAT_DIALOG_DATA) public data) {
      data.type && data.type === eventType.ponctual_unavailability ? this.type = 'unavailability' : this.type = 'availability';
      if (data.maxRange) {
        this.allEndSlots = this.allSlots.slice(this.allSlots.indexOf(moment(data.event.end).format('HH:mm')), this.allSlots.indexOf(data.maxRange) + 1);
      }
      this.event = data.event;
      
      this.eventEnd = moment(data.event.end).format('HH:mm');
      this.eventStart = moment(data.event.start).format('HH:mm');
  
      console.log(this.event);
      if (data.availablesStartSlots) {
        this.availablesStartSlots = data.availablesStartSlots;
        this.availablesEndSlots = data.availablesEndSlots;
        this.allEndSlots = data.allEndSlots;
        this.getNextUnavailableSlot();
      }
    }
  
    getNextUnavailableSlot() {
      this.allEndSlots = [];
      const time = moment(this.eventStart, 'HH:mm');
      this.event.start = moment(this.event.start).set({
        'hours': time.get('hours'),
        'minutes': time.get('minutes')
      }).toISOString();
      for (let index = this.availablesEndSlots.indexOf(this.allSlots[this.allSlots.indexOf(time.format('HH:mm')) + 1]); index + 1 <= this.availablesEndSlots.length; index++) {
        const current = moment().set({
          'hours': moment(this.availablesEndSlots[index], 'HH:mm').get('hours'),
          'minutes': moment(this.availablesEndSlots[index], 'HH:mm').get('minutes')
        });
  
        const next = moment().set({
          'hours': moment(this.availablesEndSlots[index + 1], 'HH:mm').get('hours'),
          'minutes': moment(this.availablesEndSlots[index + 1], 'HH:mm').get('minutes')
        });
  
        if (next.diff(current, 'minutes') === 30) {
          this.allEndSlots.push(this.availablesEndSlots[index])
        } else {
          this.allEndSlots.push(this.availablesEndSlots[index])
          // si c'est le dernier item on l'ajoute
          if (this.availablesEndSlots[index + 1] === '23:59' && this.availablesEndSlots[index] === '23:30') {
            this.allEndSlots.push(this.availablesEndSlots[index + 1])
          }
          index = this.availablesEndSlots.length;
        }
      }
      this.checkValidity();
    }
  
    updateEnd() {
      const time = moment(this.eventEnd, 'HH:mm');
      time.format('HH:mm') === '00:00' 
      ? this.event.end = moment(this.event.start).startOf('day').add(1, 'day').toISOString()
      : this.event.end = moment(this.event.start).set({
        'hours': time.get('hours'),
        'minutes': time.get('minutes')
      }).toISOString();
      this.event.start = moment(this.event.start).toISOString();
      this.checkValidity();
    }
  
    checkValidity() {
      this.validity = (this.allEndSlots.indexOf(moment(this.event.end).format('HH:mm')) >= 0) ? true : false;
    }
  
    delete() {
      if (this.event.url) {
        this.dialogRef.close({
          deleted: true,
          url: this.event.url
        });
      } else {
        this.dialogRef.close();
      }
    }
  
    closeModal(): void {
      if (moment(this.event.end).format('HH:mm:ss') === '23:59:00') {
        this.event.end = moment(this.event.end).add(1, 'minute').toISOString();
      }
      this.dialogRef.close(this.event);
    }
  }