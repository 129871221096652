<div class="timeslot-wrapper">
  
  <div class="timeslot-row">
      <div class="select-schedule day-picker">
      <mat-select [formControl]="day_FormControl"  placeholder="{{'common.jour' | translate}}" *ngIf="showDays">
        <mat-option *ngFor="let day of daysList" (click)="initStart()" [value]="day" [disabled]="!day.enable">
          <span translate>{{day.str}}</span>
        </mat-option>
      </mat-select>
      </div>

    
      <span class="timeslot-text" translate>common.from</span>
      <div class="select-schedule time-picker">
      <mat-select [formControl]="start_FormControl" *ngIf="day_FormControl.value">
        <mat-option *ngFor="let start of startList" (click)="initEnd()"[value]="start.date" [disabled]="!start.enable">
          {{ start.str }}
        </mat-option>
      </mat-select>
      </div>

    
      <span class="timeslot-text" translate>common.to</span>
      <div class="select-schedule time-picker">
      <mat-select [formControl]="end_FormControl" *ngIf="start_FormControl.value">
        <mat-option *ngFor="let end of endList" (click)="setEvent()" [value]="end.date" [disabled]="!end.enable" >
          {{ end.str }}
        </mat-option>
      </mat-select>
      </div>
    <button class="remove-timeslot" [disabled]="loaderSearchSolution" (click)="removeTimeSlot.next('removeTimeSlot')"></button>
  </div>
</div>