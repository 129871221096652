<ng-container *ngIf="showContact;">
  <div class="contact" routerLink="{{myLink}}">
    <div class="contact-avatar-wrapper">
      <div class="unread-counter"*ngIf="nb_unread_message > 0">{{nb_unread_message}}</div>
      <div class="avatar-inset-shadow"></div>
      <div class="contact-avatar">
        <img class="user-avatar" src="{{defaultAvatar}}" *ngIf="contact.user.photo_profile === null">
        <img class="user-avatar" src="{{contact.user.photo_profile.thumbnail}}" *ngIf="contact.user.photo_profile !== null">
      </div>
    </div>
    <div class="contact-detail">
        <b>{{contact.user.first_name}} {{contact.user.last_name}}</b>
    </div>
  </div>
</ng-container>
