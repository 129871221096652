import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// from lingueo
import { AuthGuard } from '../../../guard/auth.guard';
// from module
import { ListComponent } from './list/list.component';
import { BillingComponent } from './billing.component';
import { DetailComponent } from './detail/detail.component';
import { InfoComponent } from './info/info.component';
import { MaintenanceGuard } from '../../../guard/maintenance.guard';

export const routes: Routes = [
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [MaintenanceGuard],
    children: [
      { path: '', component: ListComponent, canActivate: [AuthGuard] },
      { path: 'detail/:id', component: DetailComponent, canActivate: [AuthGuard] },
      { path: 'infos', component: InfoComponent, canActivate: [AuthGuard] },
    ]
  }
  // { path: '**', component: PageNotFoundComponent }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
