import { TeacherProfilComponent } from './../../../../feature/teacher-profil/teacher-profil.component';
/**
 * Etape 5 plan-formation : Conclusion après planninfication.
 */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainscreenGlobals } from '../../../../kiwixi/mainscreen.globals';
import { FormationService } from '../../../../service/formation.service';
import { Formation } from '../../../../model/formation.model';
import { Teacher } from '../../../../model/teacher.model';
import { environment } from '../../../../../environments/environment';
import { OccurrenceService } from '../../../../service/occurrence.service';
import { InitAppService } from '../../../../service/initapp.service';

@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.scss', '../plan-formation.component.scss']
})
export class ConclusionComponent implements OnInit {

  formation: Formation;
  teachers: Teacher[];
  defaultAvatar: string;
  current_formation_id: any;
  translatePath = 'lingueopro.plan-formation.conclusion';
  next_occurrence: any;
  componentToLoad;
  modalParams;

  constructor(
    private mainscreenGlobals: MainscreenGlobals,
    private formationService: FormationService,
    private occurrenceService: OccurrenceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private initAppService: InitAppService,

  ) {
    mainscreenGlobals.currentFormation.subscribe(value => this.formation = value)
  }

  ngOnInit() {
    this.teachers = []
    this.defaultAvatar = environment.static + '/avatar.png';

    const currentFormationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.current_formation_id = this.activatedRoute.snapshot.paramMap.get('id');

    this.initAppService.initApp(true, currentFormationId);
    this.formationService.currentFormationSubject.subscribe(formation => {
      this.formation = formation;
      if (formation) {
        this.formation = formation;
        this.init();
      }
    });
  }

  getCurrentLanguage() {
    return this.formationService.getCurrentLanguage();
  }

  init() {
    if (this.formation) {
      this.matchTeacherDetails();
      // Affichage du premiers cours.
      if (this.formation['next_occurrence']) {
        this.occurrenceService.getObservableOccurence(this.formation['next_occurrence'])
          .subscribe(_occ => {
            this.next_occurrence = _occ;
          });
      }
    }
  }

  showModalTeacherProfil(teacher) {
    this.componentToLoad = 'teacherProfil';
    this.modalParams = { teacher: teacher, showReviews: false }
  }

  matchTeacherDetails() {
    this.formation.hours_by_teachers.map(teacher => teacher.teacher_detail = this.formation.teachers.filter(_teacher => _teacher.id === teacher.teacher_id).shift())
  }

  gotoFormationSpace() {
    this.router.navigate(['formation', this.current_formation_id])
  }

}
