import { AchievementManagerService } from './../../service/achievementManager.service';
import { Student } from './../../model/student.model';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../service/authentification.service';
import { DictionnaryService } from '../../service/dictionnary.service';
import { Router } from '@angular/router';

/**
 * Composant parent pour le site lingueopro.
 * Initialisation du user student & de la traduction
 */
@Component({
  selector: 'app-lingueopro',
  templateUrl: './lingueopro.component.html',
  styleUrls: ['./lingueopro.component.scss']
})

export class LingueoproComponent implements OnInit {

  translate: any;
  showHeader = false;
  showLingueoApp = false;
  student: Student;
  formation_id;
  formationListSubscribed = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private dictionnaryService: DictionnaryService,
    private translateService: TranslateService,
    private achievementManagerService: AchievementManagerService,
  ) {
  }

  ngOnInit() {
    this.setTranslation();
    const that = this
    this.authenticationService.currentUserSubject.subscribe(user => {
      if (user) {
        that.student = user
        if (that.achievementManagerService.achievementToFire.value.length == 0) {
          that.achievementManagerService.setAchievementsToFire(that.student);
        }
      }
    });
    setTimeout(() => {
      const my_observable = this.authenticationService.initAuthPage('student', 'lingueopro');
      if (my_observable) {
        my_observable.subscribe((_student) => {
          if (_student) {
            this.formation_id = _student.formation;
          }
        });
      }
    }, 300);
  }

  /**
   * Lancement de la traduction.
   */
  setTranslation() {
    const _lang = localStorage.getItem('language');
    this.translateService.setDefaultLang(_lang);
    this.translateService.use(_lang);
    this.dictionnaryService.getDictionnary2(_lang)
      .subscribe(res => {
        if (res) {
          this.translateService.setTranslation(_lang, res);
          this.showLingueoApp = true;
        }
      });
  }

}
