import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { Achievement } from '../model/achievement.model';
import { helpNotifToFire } from '../model/helpNotifToFire';
import { Teacher } from '../model/teacher.model';
import { AchievementService } from './achievement.service';
import { AchievementDoneservice } from './achievementDone.service';
import { AchievementManagerService } from './achievementManager.service';
import { AuthenticationService } from './authentification.service';
import { HelpNotifService } from './helpNotif.service';

@Injectable({
  providedIn: 'root'
})
export class HelpNotifManagerService {
  achievement: Achievement = null;
  index = 0;
  helpNotifToFire: helpNotifToFire[] = [];

  public helps: BehaviorSubject<helpNotifToFire[]> = new BehaviorSubject<helpNotifToFire[]>([]);

  constructor(
    private achievementDoneservice: AchievementDoneservice,
    private achievementService: AchievementService,
    private achievementManagerService: AchievementManagerService,
    private helpNotifservice: HelpNotifService,
    private authenticationService: AuthenticationService
  ) { }

  setHelpNotif(achievement) {
    this.achievement = achievement
    this.getHelpNotif(0);
  }

  getHelpNotif(index) {
    if (this.achievement.help_notif.length > 0 && index < this.achievement.help_notif.length) {
      this.helpNotifservice.getHelpNotifByUrl(this.achievement.help_notif[index]).subscribe(help => {
        if (help) {
          const helpNotif: helpNotifToFire = {
            title: help['title_' + this.getCurrentLanguage()],
            content: help['content_' + this.getCurrentLanguage()],
            illustration_file: help['illustration_file_' + this.getCurrentLanguage()],
            priority: help.priority,
            css: help.additional_css ? JSON.parse(help.additional_css) : null,
            slug: help.slug,
            is_last: index + 1 === this.achievement.help_notif.length ? true : false,
          }
          this.helpNotifToFire.push(helpNotif)
          this.helpNotifToFire.sort((a, b) => a.priority - b.priority)
          this.helps.next(this.helpNotifToFire);
          this.getHelpNotif(index + 1);
        }
      })
    } else {
      console.log(this.helps.value);
      this.helpNotifToFire = [];
    }
  }

  getActiveTooltip() {
    return this.helps.value[this.index];
  }

  getCurrentLanguage() {
    return this.achievementService.getCurrentLanguage();
  }

  next() {
    this.index = this.index + 1;
  }
}
